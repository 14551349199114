import { getTime, parseISO } from "date-fns";
import React, { useEffect, useState } from "react";
import { CustomButton } from "../../../../components/Buttons";
import {
  BackHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../../components/CardModal";
import { FullDateFormatter } from "../../../../components/tools";
import {
  CountDownTopUpModal,
  WrapperModalButtons,
} from "../../../../pageComponents/homeComponents/topup/components";
import { ActivityDetailsHeader } from "../../activityModalComponents";
import { usePaymentMethod } from "../paymentMethodModal/logic";
import { BankDetailsSection } from "./components";
import { SmartUploadReceiptModal } from "../../../../modals/smartUploadReceiptModal/parent";
import { useModalHook } from "../../../../components/Modals";
import { bankLists } from "../../../../assets/data";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import { GothamMedium } from "../../../../components/Text";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import FloatingTooltip from "../../../../pageComponents/productDemoComponents/components/FloatingTooltip";

const timeFormatter = (created_at) => {
  const createdAtTime = getTime(parseISO(created_at || 0)) || 0;
  // const oneHour = 60 * 60 * 1000;
  // const addedTime = oneHour * 3;
  // const time = createdAtTime + addedTime;
  const time = createdAtTime;
  return time;
};

export const DirectPaymentInstructionModal = ({
  isOpen,
  toggle,
  close,
  data,
}) => {
  const [bank, selectedBank] = useState(bankLists[0]);

  const { isOpen: isOpenUpload, toggle: toggleUpload } = useModalHook();

  const { payment_expiry_at, id } = data || {};

  const urlData = { type: "single_payment", id };

  const time = timeFormatter(payment_expiry_at);
  const expiredIn = FullDateFormatter(payment_expiry_at || "");
  const dataForCountDown = { expiredIn };

  const onClick = (val) => {
    const isOpenModal = val === "open_modal";
    const isBackUpload = val === "back_upload";

    if (isOpenModal) return toggleUpload();
    if (isBackUpload) return toggleUpload();
  };

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) return;
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted]);

  const components = [
    <WrapperModalButtons
      childrenButton={
        <span
          style={{ width: "100%", marginRight: 16 }}
          ref={(e) => {
            if (!e) return;
            setIsMounted(true);
          }}
        >
          <FloatingTooltip
            isOpen={isHoveredInfo}
            close={() => setIsHoveredInfo(false)}
            title="Make sure to click this!"
            body="We just need you to click this button and upload the receipt to verify your transfer."
            tooltipStyle={{
              translate: "60px -200px",
              width: 260,
            }}
            translateTriangle={`130px 139px`}
            position="top"
          >
            <ButtonJack
              style={{ width: "100%", marginRight: 16 }}
              onClick={() => onClick("open_modal")}
            >
              I have paid
            </ButtonJack>
          </FloatingTooltip>
        </span>
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        Payment Instruction
      </GothamMedium>
      <CountDownTopUpModal time={time} data={dataForCountDown} />
      <BankDetailsSection data={data} bank={bank} selectedBank={selectedBank} />
    </WrapperModalButtons>,
  ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        width={[420]}
      />
      <SmartUploadReceiptModal
        isOpen={isOpenUpload}
        toggle={() => onClick("back_upload")}
        bank={bank?.bank}
        urlData={urlData}
      />
    </>
  );
};
