import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { localFetch } from "../../../tools/api";
import { getUserRole, localUserStatusBoolean } from "../../AuthContext";

import { ToasterContext } from "../../ToasterContext";
import { logoutEvents } from "../../../universalFunctions/events";
import * as Sentry from "@sentry/nextjs";

export const loginNotRequiredPathnames = [
  "/login-demo",
  "/create-password",
  "/kyb/basis",
  "/login",
  "/forgot-password",
  "/register",
  "/email-redirect",
  "/tnc-login",
  "/tnc-subscription",
  "/terms-and-conditions/employee",
  "/terms-and-conditions/admin",
  "/reset-pin",
  "/",
];

const pathnameFormatter = (pathname) => {
  const splittedPathname = String(pathname).split("/");
  const pathnameText = "/" + String(splittedPathname[1]);
  return pathnameText;
};

const toTncDecider = async (user, isEmployee) => {
  const { all_tncs, tnc_configs } = user || {};
  const {
    data: { isToTnc },
  } = await localFetch("to_tnc", {
    user: {
      all_tncs,
      tnc_configs,
      isEmployee,
    },
  });
  return isToTnc;
};

export const useRedirectAfterLogin = () => {
  const { push, query } = useRouter();
  const { isEmployee } = getUserRole();

  const {
    isFirstpayroll,
    isCardRequest,
    isCardPayBills,
    isCardReviseDocs,
    isCardRequestLimit,
    isCardViewStatement,
    create_password,
  } = query;

  const redirectAfterLogin = async (user) => {
    const { isCompleted: isCompletedKYC } = localUserStatusBoolean(user);
    const {
      pin,
      activePathnames,
      all_tncs,
      tnc_configs,
      partner: { is_expired } = {},
    } = user ?? {};

    if (create_password) return push("/change-password");

    if (!isCompletedKYC) return push("/kyb-jack");

    // const isToTnc = await toTncDecider(user, isEmployee);

    if (!pin) return push("/account/create-pin/");

    // dimatiin for demo purpose
    // if (isToTnc) return push("/terms-and-conditions");

    if (isFirstpayroll) return push({ pathname: "/payroll", query });

    if (isCardRequest || isCardPayBills || isCardRequestLimit)
      return push({ pathname: "/cards", query });

    if (isCardViewStatement)
      return push({ pathname: "/cards/statement", query });

    if (isCardReviseDocs)
      return push({
        pathname: "/cards/onboard",
        query: { condition: "upload_document" },
      });

    const hasAutoOpen = localStorage.getItem("autoOpenTrx");
    const pathnameStorage = localStorage.getItem("pathnameStorage");

    if (hasAutoOpen) return push("/transactions");

    const isNotSuccessPage = !String(pathnameStorage).includes("success");

    const canAccessPathnameStorage =
      pathnameStorage &&
      !loginNotRequiredPathnames.includes(pathnameStorage) &&
      activePathnames.includes(pathnameStorage) &&
      isNotSuccessPage;

    if (canAccessPathnameStorage) return push(pathnameStorage);

    push("/home");
  };

  return { redirectAfterLogin };
};

export const usePageRedirectMaster = ({ user }) => {
  const { warningToaster } = useContext(ToasterContext);
  const { push, pathname, query, replace } = useRouter();
  const { redirectAfterLogin } = useRedirectAfterLogin();
  const { isEmployee } = getUserRole();
  const { partner: { is_expired } = {} } = user ?? {};

  const { isCompleted: isCompletedKYC } = localUserStatusBoolean(user);

  const [isFirstRender, setIsFirstRender] = useState(true);

  const pageRedirect = async (unauthorize) => {
    const woRedirectPathnames = ["/change-password"];

    const excludedUrlPin = [
      "/account/create-pin",
      "/account/invite-users",
      "/login",
      "/register",
      "create-password",
      "/terms-and-conditions",
      "/demo-expired",
    ];

    if (woRedirectPathnames.includes(pathname)) return;

    const isAfterKYC =
      !isEmpty(user) && !excludedUrlPin.includes(pathname) && isCompletedKYC;

    if (isAfterKYC) {
      const { pin } = user;
      // const isToTnc = await toTncDecider(user, isEmployee);

      if (!pin) return push("/account/create-pin/");

      // if (isToTnc && pathname.includes("/terms-and-conditions")) return;

      // if (isToTnc) return push("/terms-and-conditions");
    }

    const localStorageToken = localStorage.getItem("token");
    const isExpired = user?.partner?.is_expired;
    const unauthorizedUser = !localStorageToken;

    const isLoginPage = pathname === "/login";

    const authorizedUser = !unauthorizedUser;

    const isAuthorizedUserAtLogin = authorizedUser && isLoginPage;

    const isAccountDetails = pathname.includes("/account-details");

    const isKYB = pathname.includes("/kyb-jack");
    const isAccount = pathname.includes("/account");

    const {
      name,
      email,
      mobile,
      partner: { name: business_name } = {},
    } = user ?? {};

    const user_details = {
      name,
      business_name,
      email,
      mobile,
    };

    if (isExpired) {
      // localStorage.setItem(user_details)
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      Sentry.configureScope((scope) => scope.setUser(null));
      logoutEvents();
      window.CommandBar.shutdown();
      return push("/demo-expired");
    }

    if (pathname === "/demo-expired" && !isEmpty(user)) {
      if (!isExpired) return push("/home");
    }

    if (pathname == "/forgot-password") return;

    if (unauthorizedUser) {
      const loginNotRequiredPathnames = [
        "/login-demo",
        "/create-password",
        "/kyb/basis",
        "/login",
        "/forgot-password",
        "/register",
        "/email-redirect",
        "/tnc-login",
        "/tnc-subscription",
        "/terms-and-conditions/employee",
        "/terms-and-conditions/admin",
        "/reset-pin",
        "/register/second",
        "/register/success",
        "/demo-expired",
      ];
      // setTimeout to solve racing conditions when user refreshes the page
      if (loginNotRequiredPathnames.includes(pathname)) return;

      unauthorize && unauthorize();
      warningToaster({ msg: "Your session is expired, please login again" });
      // if (isExpired) return push("/demo-expired");
      return push("/login");
    }

    if (isAccountDetails) return;

    if (isAuthorizedUserAtLogin && !isEmpty(user))
      return redirectAfterLogin(user);

    // KYC rules
    if (!isCompletedKYC && !isEmpty(user)) {
      if (isKYB) return;
      if (isAccount) return;
      push("/kyb-jack");
    }
    // KYC rules

    // role redirect
    if (authorizedUser) {
      const { activePathnames = [], buttonBooleans } = user || {};
      const formattedPathname = pathnameFormatter(pathname);

      // not ready yet
      if (!activePathnames.length) return;
      // not ready yet

      // exact precision
      const requiresExactPrecision = [
        "/payroll/create",
        "/payroll/invite",
        "/cards/create",
        "/cards/[id]/edit",
        "/cards/onboard",
        "/account/topup",
        "/account/withdraw",
        "/account/allocate",
        "/account/reallocate",
        "/account/create-pin",
        "/account/invite-users",
      ];

      if (requiresExactPrecision.includes(pathname)) {
        if (activePathnames.includes(pathname)) return;
        return push("/home");
      }
      // exact precision

      // special case
      const isEditPayroll = pathname == "/payroll/create/[id]";

      if (isEditPayroll) {
        const { canCreatePayroll, canViewPayroll } = buttonBooleans || {};
        const isSuccess = query?.state == "success";
        if (canCreatePayroll) return;
        if (canViewPayroll && isSuccess) return;
        return push("/home");
      }

      // special case

      // default case
      if (activePathnames.includes(formattedPathname)) return;
      if (formattedPathname === "/users" || pathname === "/plans/showcase")
        return;

      push("/home");
      // default case
    }

    // role redirect
  };

  // redirect page master
  useEffect(() => {
    if (isFirstRender) return setTimeout(() => setIsFirstRender(false), 200);
    pageRedirect();
  }, [pathname, isFirstRender, isEmpty(query), is_expired]);
  // redirect page master

  return { pageRedirect };
};
