import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { LOCAL_FIRST_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${LOCAL_FIRST_TARGET}`,
    content: (
      <TooltipContent
        title="Fill in Batch Name & Category"
        description="This will help your company to track and organize all expenses."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_local_transfer",
      guided_tour_activity: "fill_name_and_category",
    },
  },
];

const batchNameProps = { steps };

export default batchNameProps;
