import { addDays, parseISO } from "date-fns";

const calculateDateBeforeDue = (time, dueDate, beforeDueDate) => {
  const dueDateObject = parseISO(dueDate);
  const calculatedDate = addDays(dueDateObject, -beforeDueDate);

  const timeObject = parseISO(time);
  calculatedDate.setHours(timeObject.getHours());
  calculatedDate.setMinutes(timeObject.getMinutes());
  calculatedDate.setSeconds(timeObject.getSeconds());

  return calculatedDate.toISOString();
};

const sortByDefault = (data) =>
  data.sort((a, b) => (b.is_default ? 1 : a.is_default ? -1 : 0));

export const optionsFormatter = (rawData, dueDate) => {
  if (dueDate) {
    const data = rawData?.map((p_cycle) => {
      const { recurrence } = p_cycle;
      const isDueDate = recurrence == "due_date";

      if (isDueDate) {
        const {
          nearest_date_from_today: oldDay,
          days_before_due: beforeDue,
          ...rest
        } = p_cycle;
        const nearest_date_from_today = calculateDateBeforeDue(
          oldDay,
          dueDate,
          beforeDue
        );

        return { nearest_date_from_today, days_before_due: beforeDue, ...rest };
      }

      return p_cycle;
    });
    return sortByDefault(data);
  }

  return sortByDefault(rawData);
};
