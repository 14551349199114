import { title, titleCase } from "change-case";
import { colors } from "../../../../../assets/colors";
import { Banner } from "../../../../../components/Banner";
import ParserRichTextArea from "../../../../../components/ParserRichTextArea";
import { SeeMore } from "../../../../../components/SeeMore";
import { StatusLabelJack } from "../../../../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../../../components/tools";
import { getUserRole } from "../../../../../contexts/AuthContext";
import { customStatusColors } from "../../../constants";
import {
  cardStateDecider,
  cardStateFormatter,
  cardTypeDecider,
  limitTypeDecider,
} from "../../../tools";
import { useMemo } from "react";

const LeftRightList = ({ list, bottomComponent }) => {
  const containerStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  const innerContainerStyle = {
    display: "flex",
  };

  const leftStyle = {
    width: "50%",
  };

  const leftTextStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    color: colors.neutral700,
  };

  const rightTextStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    color: colors.neutral900,
  };

  const rightStyle = {
    width: "50%",
    display: "flex",
  };

  return (
    <div style={containerStyle}>
      {list.map((row, index) => {
        const { left, right } = row || {};
        if (!left && !right) return null;
        const isRightString = typeof right === "string";
        return (
          <div style={innerContainerStyle} key={index}>
            <div style={leftStyle}>
              <GothamRegular style={leftTextStyle}>{left}</GothamRegular>
            </div>
            {isRightString ? (
              <div style={rightStyle}>
                <GothamRegular style={rightTextStyle}>{right}</GothamRegular>
              </div>
            ) : (
              <div style={rightStyle}>{right}</div>
            )}
          </div>
        );
      })}
      {bottomComponent}
    </div>
  );
};

const CardInformation = ({ cardDetail }) => {
  const {
    card_type: cardType,
    created_at: createdAt,
    description,
    is_adjusting_limit,
    limit_type: limitType,
    limit_value: limitValue,
    name,
    nickname,
    state,
    user: { name: userName, email: userEmail } = {},
  } = cardDetail || {};
  const limitPeriod = limitTypeDecider(limitType);
  const { isNotActive, isActivating } = cardStateDecider(state);
  const { isPrepaid } = cardTypeDecider(cardType);
  const status = cardStateFormatter(cardDetail);

  const nameOrNickname = nickname || name;
  const cardTypeText = isPrepaid ? "Prepaid" : titleCase(cardType);

  const descriptionStyle = {
    fontSize: "12px",
  };

  const containerStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    border: `1px solid ${colors.neutral500}`,
    padding: "12px",
    gap: "16px",
  };

  const limitValueStyle = {
    fontSize: "12px",
    color: colors.neutral900,
  };

  const limitTypeStyle = {
    fontSize: "10px",
    color: colors.neutral700,
    marginTop: "-4px",
  };

  const onIssuanceInformations = [
    {
      left: "Issued on",
      right: customDateFormatter(createdAt),
    },
    !isPrepaid && {
      left: "Card limit",
      right: (
        <div>
          <GothamRegular style={limitValueStyle}>
            IDR {formatCurrencyNoDecimal(limitValue)}
          </GothamRegular>
          <GothamRegular style={limitTypeStyle}>{limitPeriod}</GothamRegular>
        </div>
      ),
    },
  ];

  const descriptionComponent = useMemo(() => {
    return (
      <GothamRegular style={descriptionStyle}>
        <SeeMore height={64} width={178}>
          <ParserRichTextArea text={description} />
        </SeeMore>
      </GothamRegular>
    );
  }, [description]);

  const list = [
    {
      left: "Card type",
      right: `${cardTypeText} Card`,
    },
    {
      left: "Cardholder",
      right: userName || userEmail,
    },
    {
      left: "Card name",
      right: nameOrNickname,
    },
    {
      left: "Status",
      right: (
        <StatusLabelJack
          status={status}
          customStatusColors={customStatusColors}
          isOutline
          woTextFormatter
        />
      ),
    },
    ...(isNotActive || isActivating ? onIssuanceInformations : []),
    description && {
      left: "Description",
      right: descriptionComponent,
    },
  ];

  return (
    <div style={containerStyle}>
      <GothamMedium>Card Information</GothamMedium>
      <LeftRightList list={list} />
    </div>
  );
};

export default CardInformation;
