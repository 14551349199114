import React, { Fragment, useRef, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { useModalHook } from "../../../../components/Modals";
import Profile from "../../../../components/Profile";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import { SidebarList } from "./list";
import SidebarAdvertisement from "../../../../pageComponents/subscriptionsJackComponents/general-components/SidebarAdvertisement/parent";
import { useSubscriptionUsage } from "../../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { Pulse } from "../../../../pageComponents/categoryComponents/general/components";
// import { useCategoryFlag } from "../../../../pageComponents/categoryComponents/general/hooks";

export const SidebarDropdown = ({ array, onClick, hasSidebar }) => {
  const { isOpen, toggle, close } = useModalHook();

  const { isSeedPlan, loadingSubscriptionUsage } = useSubscriptionUsage();

  // const { hasSeenCategory } = useCategoryFlag();

  const showAds = isSeedPlan && !loadingSubscriptionUsage;

  const accountHeight = 64;
  const adsHeight = showAds ? 168 : 0;

  const hasSomethingNew = false;

  const [height, setHeight] = useState(0);
  const ref = useRef();

  useClickOutside({ ref, clickOutside: close });

  return (
    <div
      style={{
        transition: "all 0.4s linear",
        flexDirection: "column",
        height: isOpen ? height + accountHeight + adsHeight : accountHeight,
        width: isOpen ? 256 : 240,
        border: isOpen
          ? `1px solid ${jackColors.greyE6}`
          : "1px solid transparent",
        borderRadius: isOpen ? 8 : 0,
        boxShadow: isOpen
          ? "0px 8px 12px rgba(9, 30, 66, 0.15), 0px 0px 1px rgba(9, 30, 66, 0.31)"
          : "",
        position: "fixed",
        translate: isOpen ? "20px -20px" : "",
        bottom: 0,
        marginLeft: hasSidebar ? -1 : -600,
        backgroundColor: "white",
        zIndex: 10,
      }}
      ref={ref}
      className="d-flex"
    >
      <div
        style={{
          height: accountHeight,
          paddingLeft: isOpen ? 16 : 24,
          paddingRight: isOpen ? 8 : 24,
          borderTop: isOpen
            ? "1px solid transparent"
            : `1px solid ${jackColors.greyE6}`,
          transition: "all 0.4s linear",
        }}
        className="d-flex hover"
        onClick={toggle}
      >
        <Profile withRole={isOpen} />
        <Pulse
          isActive={hasSomethingNew}
          style={{
            marginTop: 14,
            opacity: isOpen ? 0 : 1,
            transition: "all 0.4s linear",
          }}
        >
          <JackIcons
            name="chevron-down"
            fill={jackColors.black34}
            style={{
              width: 16,
              height: 16,
            }}
          />
        </Pulse>
      </div>
      {isOpen && (
        <Fragment>
          {showAds && <SidebarAdvertisement />}
          <div
            ref={(e) => {
              if (!e) return;
              setHeight((p) => {
                if (e.clientHeight == p) return p;
                return e.clientHeight;
              });
            }}
          >
            <SidebarList array={array} onClick={onClick} isDropdown />
          </div>
        </Fragment>
      )}
    </div>
  );
};
