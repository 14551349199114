import {
  BILL_MODULE,
  BILL_FIRST_PAGE,
  BILL_SECOND_PAGE,
  BILL_THIRD_PAGE,
  BILL_FOURTH_PAGE,
  BILL_FIFTH_PAGE,
  REIMBURSEMENT_MODULE,
  REIMBURSEMENT_FIRST_PAGE,
  REIMBURSEMENT_SECOND_PAGE,
  REIMBURSEMENT_THIRD_PAGE,
  REIMBURSEMENT_FOURTH_PAGE,
  REIMBURSEMENT_FIFTH_PAGE,
  REIMBURSEMENT_SIXTH_PAGE,
  INTERNATIONAL_MODULE,
  INTERNATIONAL_FIRST_PAGE,
  INTERNATIONAL_SECOND_PAGE,
  INTERNATIONAL_THIRD_PAGE,
  LOCAL_MODULE,
  LOCAL_FIRST_PAGE,
  LOCAL_SECOND_PAGE,
  LOCAL_THIRD_PAGE,
  LOCAL_FOURTH_PAGE,
  PAYROLL_MODULE,
  PAYROLL_FIRST_PAGE,
  PAYROLL_SECOND_PAGE,
  PAYROLL_THIRD_PAGE,
  PAYROLL_FOURTH_PAGE,
  VA_MODULE,
  VA_FIRST_PAGE,
  VA_SECOND_PAGE,
  VA_THIRD_PAGE,
  DRAFT_MODULE,
  WORKFLOW_MODULE,
  TRANSACTIONS_MODULE,
  TASK_MODULE,
  APPROVAL_MODULE,
  DEMO_MODULE,
} from "../constants";
import billFirstPageProps from "./bill/billFirstPage";
import batchNameProps from "./local/batchName";
import inputRecipientProps from "./local/inputRecipient";
import inputRecipientCsvProps from "./local/inputRecipientCSV";
import useTransferDetailsProps from "./local/transferDetails";
import payrollNameProps from "./payroll/payrollName";
import inputRecipientCsvPayrollProps from "./payroll/inputRecipientCSV";
import reimbursementFirstPageProps from "./reimbursement/reimbursementFirstPage";
import reimbursementSecondPageProps from "./reimbursement/reimbursementSecondPage";
import reimbursementThirdPageProps from "./reimbursement/reimbursementThirdPage";
import reimbursementFourthPageProps from "./reimbursement/reimbursementFourthPage";
import reimbursementFifthPageProps from "./reimbursement/reimbursementFifthPage";
import reimbursementSixthPageProps from "./reimbursement/reimbursementSixthPage";
import internationalFirstPageProps from "./crossborder/firstPage";
import internationalSecondPageProps from "./crossborder/secondPage";
import internationalThirdPageProps from "./crossborder/thirdPage";
import inputRecipientPayrollProps from "./payroll/inputRecipient";
import billSecondPageProps from "./bill/billSecondPage";
import billThirdPageProps from "./bill/billThirdPage";
import vaFirstPageProps from "./va/firstPage";
import useVaThirdPageProps from "./va/thirdPage";
import vaSecondPageProps from "./va/secondPage";
import draftProps from "./draft";
import workflowProps from "./workflow";
import useTransactionProps from "./transactions";
import useTaskProps from "./task";
import useApprovalProps from "./approval";
import useDemoProps from "./demo";

const useGuidedTourBuilder = ({
  module = "",
  page = "",
  methods = "",
  isEmployee = false,
}) => {
  // guided tour
  const transferDetailsPageProps = useTransferDetailsProps({ module, methods });
  const vaThirdPageProps = useVaThirdPageProps({ methods });

  // tooltip
  const transactionsProps = useTransactionProps({ isEmployee });
  const taskProps = useTaskProps({ methods });
  const approvalProps = useApprovalProps({ methods, page });

  // demo guidance
  const demoGuidanceProps = useDemoProps({ page });

  switch (module) {
    case DEMO_MODULE:
      return demoGuidanceProps;
    case LOCAL_MODULE:
      switch (page) {
        case LOCAL_FIRST_PAGE:
          return batchNameProps;
        case LOCAL_SECOND_PAGE:
          return inputRecipientProps;
        case LOCAL_THIRD_PAGE:
          return inputRecipientCsvProps;
        case LOCAL_FOURTH_PAGE:
          return transferDetailsPageProps;
      }
    case PAYROLL_MODULE:
      switch (page) {
        case PAYROLL_FIRST_PAGE:
          return payrollNameProps;
        case PAYROLL_SECOND_PAGE:
          return inputRecipientPayrollProps;
        case PAYROLL_THIRD_PAGE:
          return inputRecipientCsvPayrollProps;
        case PAYROLL_FOURTH_PAGE:
          return transferDetailsPageProps;
      }
    case INTERNATIONAL_MODULE:
      switch (page) {
        case INTERNATIONAL_FIRST_PAGE:
          return internationalFirstPageProps;
        case INTERNATIONAL_SECOND_PAGE:
          return internationalSecondPageProps;
        case INTERNATIONAL_THIRD_PAGE:
          return internationalThirdPageProps;
      }
    case BILL_MODULE:
      switch (page) {
        case BILL_FIRST_PAGE:
          return billFirstPageProps;
        case BILL_SECOND_PAGE:
          return billSecondPageProps;
        case BILL_THIRD_PAGE:
          return billThirdPageProps;
        case BILL_FOURTH_PAGE:
          return;
        case BILL_FIFTH_PAGE:
          return;
        default:
          return;
      }
    case REIMBURSEMENT_MODULE:
      switch (page) {
        case REIMBURSEMENT_FIRST_PAGE:
          return reimbursementFirstPageProps;
        case REIMBURSEMENT_SECOND_PAGE:
          return reimbursementSecondPageProps;
        case REIMBURSEMENT_THIRD_PAGE:
          return reimbursementThirdPageProps;
        case REIMBURSEMENT_FOURTH_PAGE:
          return reimbursementFourthPageProps;
        case REIMBURSEMENT_FIFTH_PAGE:
          return reimbursementFifthPageProps;
        case REIMBURSEMENT_SIXTH_PAGE:
          return reimbursementSixthPageProps;
        default:
          return;
      }
    case VA_MODULE:
      switch (page) {
        case VA_FIRST_PAGE:
          return vaFirstPageProps;
        case VA_SECOND_PAGE:
          return vaSecondPageProps;
        case VA_THIRD_PAGE:
          return vaThirdPageProps;
        default:
          return;
      }
    case DRAFT_MODULE:
      return draftProps;
    case WORKFLOW_MODULE:
      return workflowProps;
    case TRANSACTIONS_MODULE:
      return transactionsProps;
    case TASK_MODULE:
      return taskProps;
    case APPROVAL_MODULE:
      return approvalProps;

    default:
      return;
  }
};

export default useGuidedTourBuilder;
