import CardHeader from "../components/CardHeader";
import CardDisplay from "../components/CardDisplay";
import CardButtons from "../components/CardButtons";
import CardInformation from "../components/CardInformation";
import CardSpending from "../components/CardSpending";
import CardBillingAddress from "../components/CardBillingAddress";
import CardAdjustLimitInfo from "../components/CardAdjustLimitInfo";
import CardBottomButtons from "../components/CardBottomButtons";
import CardUpdatesTracker from "../components/CardUpdatesTracker";
import CardNotifications from "../components/CardNotifications";
import CardPrepaidBanner from "../components/CardPrepaidBanner";
import CardMoveBalanceInfo from "../components/CardMoveBalanceInfo";
import ExpiryCardInfo from "../components/ExpiryCardInfo";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const DetailIndex = () => {
  const {
    cardDetail,
    openTransactionList,
    closeTransactionList,
    isOpenTransactionList,
    unmaskedDataDetail,
    setCurrentScreen,
    refetchCardDetail,
    toggleConfirmDelete,
    toggleTopUp,
    openMerchantLinked,
    isTask,
    refetchBalance,
  } = useCardDetailContext();

  const styles = {
    container: {
      height: "fit-content",
      position: "relative",
      marginBottom: "24px",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      width: "380px",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <CardHeader cardDetail={cardDetail} isTask={isTask} />
        <CardDisplay />
        <ExpiryCardInfo
          cardDetail={cardDetail}
          openMerchantLinked={openMerchantLinked}
        />
        <CardNotifications
          cardDetail={cardDetail}
          unmaskedDataDetail={unmaskedDataDetail}
        />
        <CardButtons
          cardDetail={cardDetail}
          openTransactionList={openTransactionList}
          closeTransactionList={closeTransactionList}
          isOpenTransactionList={isOpenTransactionList}
          setCurrentScreen={setCurrentScreen}
          refetchCardDetail={refetchCardDetail}
          toggleConfirmDelete={toggleConfirmDelete}
        />
        <CardPrepaidBanner
          cardDetail={cardDetail}
          toggleTopUp={toggleTopUp}
          closeTransactionList={closeTransactionList}
          setCurrentScreen={setCurrentScreen}
          refetchBalance={refetchBalance}
        />
        <CardAdjustLimitInfo cardDetail={cardDetail} />
        <CardMoveBalanceInfo cardDetail={cardDetail} />
        <CardUpdatesTracker cardDetail={cardDetail} />
        <CardInformation cardDetail={cardDetail} />
        <CardSpending cardDetail={cardDetail} />
        <CardBillingAddress cardDetail={cardDetail} />
      </div>
      <CardBottomButtons />
    </div>
  );
};

export default DetailIndex;
