import { useState, useEffect, useRef } from "react";
import { useRouter } from "next/router";
import { fetch } from "../../../../../../tools/api";
import { useClickOutside } from "../../../../../../universalFunctions/useClickOutside";
import { colors } from "../../../../../../assets/colors";
import { GothamRegular } from "../../../../../../components/Text";
import { isEmpty } from "lodash";
import { formatCurrencyNoDecimal } from "../../../../../../components/tools";
import { TransactionFiltersSearchbox } from "../../../../../transactionsComponents/transactions/filters/searchbox/parent";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";

const commonStyles = {
  rowText: {
    fontSize: "12px",
    letterSpacing: "0.2px",
    lineHeight: "16px",
  },
  rowNumber: {
    fontSize: "10px",
    color: colors.neutral700,
  },
  rowSelectedText: {
    color: colors.neutral100,
  },
};

const CardsList = ({
  cards,
  result,
  setResult,
  setIsExpanded,
  forwardRef: expandedRef,
}) => {
  const [indexHovered, setIndexHovered] = useState(null);

  const styles = {
    expandedContainer: {
      width: "100%",
    },
    searchContainer: {
      backgroundColor: colors.neutral100,
      margin: "-12px 0px 0px -12px",
      width: "calc(100% + 24px)",
      height: "56px",
    },
    optionsContainer: {
      maxHeight: "224px",
      overflowY: "auto",
    },
    search: {
      width: "calc(100% - 24px)",
      margin: "0px 12px",
    },
    row: {
      padding: "12px",
      height: "56px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      transition: "background-color 0.2s ease-in-out",
      backgroundColor: colors.neutral100,
    },
    rowHovered: {
      backgroundColor: colors.neutral400,
    },
    rowSelected: {
      backgroundColor: colors.neutral900,
    },
  };

  const cardRows = cards.map((card) => {
    const {
      balance,
      masked_card_number: maskedCardNumber,
      nickname,
      user,
    } = card;
    const { name, email } = user || {};

    return {
      raw: card,
      name: name || email,
      number: `**${maskedCardNumber?.substr(-4)} - ${nickname}`,
      balance: `IDR ${formatCurrencyNoDecimal(balance)}`,
    };
  });

  return (
    <div style={styles.expandedContainer} ref={expandedRef}>
      <div style={styles.searchContainer}>
        <TransactionFiltersSearchbox
          style={styles.search}
          queryName="search_card_move"
          placeholder="Search cardholder or card name"
          runQueryName="runQueryMove"
        />
      </div>
      <div style={styles.optionsContainer}>
        {cardRows.map((card, index) => {
          const { name, number, balance, raw } = card;
          const isHovered = indexHovered === index;
          const isSelected = result?.recipient?.raw?.id === raw?.id;

          return (
            <div
              style={{
                ...styles.row,
                ...(isHovered && styles.rowHovered),
                ...(isSelected && styles.rowSelected),
                borderTop: index ? `1px solid ${colors.neutral500}` : "none",
              }}
              onMouseEnter={() => setIndexHovered(index)}
              onMouseLeave={() => setIndexHovered(null)}
              onClick={() => {
                setResult((prev) => ({ ...prev, recipient: card }));
                setIsExpanded(false);
              }}
            >
              <div>
                <GothamRegular
                  style={{
                    ...commonStyles.rowText,
                    ...(isSelected && commonStyles.rowSelectedText),
                  }}
                >
                  {name}
                </GothamRegular>
                <GothamRegular
                  style={{
                    ...commonStyles.rowNumber,
                    ...(isSelected && commonStyles.rowSelectedText),
                  }}
                >
                  {number}
                </GothamRegular>
              </div>
              <GothamRegular
                style={{
                  ...commonStyles.rowText,
                  ...(isSelected && commonStyles.rowSelectedText),
                }}
              >
                {balance}
              </GothamRegular>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const SelectionInput = ({
  cards,
  isExpanded,
  setIsExpanded,
  result,
  setResult,
}) => {
  const expandedRef = useRef(null);
  useClickOutside({
    ref: expandedRef,
    clickOutside: () => isExpanded && setIsExpanded(false),
    dependency: isExpanded,
  });

  const isEmptyInput = isEmpty(result?.recipient);

  const styles = {
    unexpandedContainer: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
      alignItems: "center",
      height: "56px",
    },
  };

  if (!isExpanded) {
    return (
      <div
        style={styles.unexpandedContainer}
        onClick={() => setIsExpanded(true)}
      >
        {isEmptyInput ? (
          <GothamRegular>Choose card</GothamRegular>
        ) : (
          <>
            <div>
              <GothamRegular style={commonStyles.rowText}>
                {result?.recipient?.name}
              </GothamRegular>
              <GothamRegular style={commonStyles.rowNumber}>
                {result?.recipient?.number}
              </GothamRegular>
            </div>
            <div>
              <GothamRegular
                style={{
                  ...commonStyles.rowText,
                  textAlign: "right",
                  width: "172px",
                }}
              >
                {result?.recipient?.balance}
              </GothamRegular>
            </div>
          </>
        )}
        <JackIcons name="chevron-down" fill={colors.neutral900} />
      </div>
    );
  }

  return (
    <CardsList
      cards={cards}
      result={result}
      setResult={setResult}
      setIsExpanded={setIsExpanded}
      forwardRef={expandedRef}
    />
  );
};

export const CardSelection = ({ cardDetail, result, setResult }) => {
  const [url, setUrl] = useState(`/cards/${cardDetail?.id}/prepaid_cards`);
  const [isExpanded, setIsExpanded] = useState(false);
  const { query, push } = useRouter();
  const { search_card_move: searchCardMove, runQueryMove } = query;

  useEffect(() => {
    if (searchCardMove) push({ query: { ...query, search_card_move: null } });
  }, []);

  const {
    data: cards,
    refetch,
    setData: setCards,
  } = fetch({
    url,
    formatter: (data) => data?.data,
  });

  useEffect(() => {
    if (runQueryMove === "false") return;
    setCards([]);
    if (!searchCardMove)
      return setUrl(`/cards/${cardDetail?.id}/prepaid_cards`);
    const searchQuery = `?q[user_name_or_nickname_or_masked_card_number_cont]=${searchCardMove}`;
    setUrl(`/cards/${cardDetail?.id}/prepaid_cards${searchQuery}`);
  }, [runQueryMove]);

  useEffect(() => {
    refetch();
  }, [url]);

  const styles = {
    container: {
      position: "relative",
      marginBottom: "52px",
    },
    inputContainer: {
      width: "100%",
      padding: "12px",
      borderRadius: "4px",
      border: `1px solid ${colors.neutral500}`,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      zIndex: "100",
      backgroundColor: colors.neutral100,
      cursor: "pointer",
      position: "absolute",
      height: isExpanded ? "fit-content" : "56px",
      maxHeight: isExpanded ? "358px" : "auto",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.inputContainer}>
        <SelectionInput
          cards={cards}
          isExpanded={isExpanded}
          setIsExpanded={setIsExpanded}
          result={result}
          setResult={setResult}
        />
      </div>
    </div>
  );
};
