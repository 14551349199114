import { TooltipContent } from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { VA_FIFTH_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${VA_FIFTH_TARGET}`,
    content: (
      <TooltipContent
        title="Follow this instruction then..."
        description="Make sure you've got all the virtual accounts details right and filled out completely."
      />
    ),
    disableScrolling: false,
    eventPayload: {
      activity_type: "create_virtual_account",
      guided_tour_activity: "upload_virtual_account_excel",
    },
  },
];

const vaSecondPageProps = { steps };

export default vaSecondPageProps;
