export const SINGLE = "single";
export const BATCH = "batch";

export const DATA_ERROR = "data_error";
export const SYSTEM_ERROR = "system_error";
export const MIXED_ERROR = "mixed_error";

export const DEFAULT_TEXT =
  "the failed transaction(s) by clicking the button below.";
export const RETRY_TEXT = `retry ${DEFAULT_TEXT}`;
export const RESUBMIT_TEXT = `resubmit ${DEFAULT_TEXT}`;

export const DATA_ERROR_TEXT = "failed due to data inaccuracies";
export const SYSTEM_ERROR_TEXT = "failed due to a system issue";
export const MIXED_ERROR_TEXT = "failed due to some reason(s)";
export const SINGULAR_DATA_ERROR = `transaction ${DATA_ERROR_TEXT}`;
export const SINGULAR_SYSTEM_ERROR = `transaction ${SYSTEM_ERROR_TEXT}`;
export const SINGULAR_MIXED_ERROR = `transaction ${MIXED_ERROR_TEXT}`;
export const PLURAL_DATA_ERROR = `transactions ${DATA_ERROR_TEXT}`;
export const PLURAL_SYSTEM_ERROR = `transactions ${SYSTEM_ERROR_TEXT}`;
export const PLURAL_MIXED_ERROR = `transactions ${MIXED_ERROR_TEXT}`;

export const RETRIABLE = "retriable";
export const RESUBMITABLE = "resubmitable";
export const MIXED_RESUBMITABLE = "retry_resubmit";

// trx
export const REIMBURSEMENT = "reimbursement";
export const BILL = "bill";
export const PAYROLL = "payroll";
export const LOCAL = "local";

// summary types
export const EXPENSE_NUMBER = "expense_number";
export const VAT = "vat";
export const HANDLING_FEE = "handling_fee";
export const TOTAL_AMOUNT = "total_amount";
export const TOTAL_PAYMENT = "total_payment";
export const SUBTOTAL = "subtotal";
export const DIVIDER = "divider";

export const TRX_TEMPLATE = {
  id: null,
  type: REIMBURSEMENT,
  name: "",
  numOfExpense: 0,
  subtotal: 0,
  totalAmount: 0,
  totalPayment: 0,
  vat: 0,
  fee: 0,
};
