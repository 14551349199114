import Link from "next/link";
import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  GothamBold,
  GothamRegular,
  TextInlineMedium,
} from "../../../../components/Text";
import styles from "./styles.module.css";
import { Fragment } from "react";
import ReactPlayer from "react-player";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_LOGIN_FROM_POPUP,
  DEMO_REGISTER_FROM_POPUP,
} from "../../constants/events";
import { useGetAuth } from "../../../../contexts/AuthContext";

const DemoGeneralModal = ({
  isOpen,
  toggle,
  type = "", // "users", "workflow"
  pageTitle: page_title = "",
}) => {
  const titleDecider = () => {
    switch (type) {
      case "workflow":
      case "users":
        return "You’ve nailed the demo!";
      default:
        return "Register your Jack account first.";
    }
  };
  const title = titleDecider();

  const mainTextDecider = () => {
    switch (type) {
      case "workflow":
        return `Curious about optimizing your approval workflow? Customize it to your liking once you're onboarded with Jack!`;
      case "users":
        return `Did you know? You can invite employees and place them into
        designated teams for improved budget control — all customizable once
        you’re onboarded with Jack!`;
      default:
        return "";
    }
  };
  const mainText = mainTextDecider();

  const bodyDecider = () => {
    let upperPart = null;
    const bottomPart = (
      <Fragment>
        <br />

        <GothamRegular>
          For further information and assistance,{" "}
          <a
            target="_blank"
            href="https://api.whatsapp.com/send/?phone=6285171590117&text&type=phone_number&app_absent=0"
          >
            <TextInlineMedium className={styles["link"]}>
              contact our team
            </TextInlineMedium>
          </a>
        </GothamRegular>
      </Fragment>
    );
    switch (type) {
      case "workflow":
      case "users":
        upperPart = (
          <GothamRegular>
            {mainText}
            <br />
            <br />
            Take the next step and register with Jack by clicking the button
            below.
          </GothamRegular>
        );
      default:
        upperPart = (
          <GothamRegular>
            Start using this feature by registering your business with Jack.
            Take your business operations to the next level with our integrated
            banking platform!
          </GothamRegular>
        );
    }
    return (
      <Fragment>
        {upperPart}
        {bottomPart}
      </Fragment>
    );
  };
  const body = bodyDecider();

  const mediaDecider = () => {
    const url = {
      workflow: "/videos/workflow-motion.mov",
      users: "/videos/user_and_teams-motion.mov",
    };
    switch (type) {
      case "workflow":
      case "users":
        return (
          // <div className={styles["player-container"]} style={{display: "flex", alignItems: "center"}}>
          <ReactPlayer
            loop
            muted
            playing
            width="100%"
            height="100%"
            url={url[type]}
            style={{
              overflow: "hidden",
              borderRadius: "8px",
              marginTop: "16px",
              marginBottom: "32px",
              overflow: "hidden",
              borderRadius: "8px",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          />
          // </div>
        );
      default:
        return <JackIcons name="BlockAccess" className={styles["icon"]} />;
    }
  };
  const media = mediaDecider();

  // const { user } = useGetAuth();
  // const { name, partner, email, mobile } = user ?? {};
  // const { name: business_name, id: business_id } = partner ?? {};

  // fix ambil dr localStorage
  const name = localStorage.getItem("demo_name")
  const email = localStorage.getItem("demo_email")
  const mobile = localStorage.getItem("demo_mobile")
  const business_name = localStorage.getItem("demo_business_name")
  const business_id = localStorage.getItem("demo_business_id")

  const user_details = { name, email, mobile, business_name, business_id };
  let queries = "";
  Object.keys(user_details).forEach((key, idx) => {
    queries += `${idx === 0 ? "" : "&"}${key}=${user_details[key]}`;
  });

  const hrefDecider = (params) => {
    let path = "https://business.itsjack.com";
    switch (params) {
      case "login":
        path += `/login?${queries}`;
        break;
      case "register":
        path += `/register?${queries}`;
        break;
    }
    return path;
  };

  return (
    <Modal
      toggle={toggle}
      isOpen={isOpen}
      unmountOnClose
      centered
      contentClassName={styles["modal"]}
    >
      <div className={styles["modal-container"]}>
        <div className={styles["modal-header"]}>
          <div />
          <GothamBold className={styles["title"]} style={{ fontSize: 20 }}>
            {title}
          </GothamBold>
          <button className={styles["close-btn-container"]} onClick={toggle}>
            <JackIcons
              name="close-outline"
              fill={jackColors.neutral900}
              style={{ height: 20 }}
            />
          </button>
        </div>

        {media}
        {body}
      </div>

      <div className={styles["modal-bottom"]}>
        <a target="_blank" href={hrefDecider("register")}>
          <ButtonJack
            style={{ width: "100%" }}
            rightIcon={<JackIcons name="ArrowForward" fill="black" />}
            onClick={() => {
              eventsTracker(DEMO_REGISTER_FROM_POPUP, { page_title });
            }}
          >
            Register to Jack
          </ButtonJack>
        </a>
        <GothamRegular className={styles["bottom-link"]}>
          Already with us?{" "}
          <a target="_blank" href={hrefDecider("login")}>
            <TextInlineMedium
              className={styles["link"]}
              onClick={() => {
                eventsTracker(DEMO_LOGIN_FROM_POPUP, { page_title });
              }}
            >
              Log in to Jack
            </TextInlineMedium>
          </a>
        </GothamRegular>
      </div>
    </Modal>
  );
};

export default DemoGeneralModal;
