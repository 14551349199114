import { useState } from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "../../../tools/api";
import useSendEmailAndDateTable from "./useSendEmailAndDateTable";

const useRegisterDemoForm = ({
  enableLoading = () => {},
  disableLoading = () => {},
  goToSuccess = () => {},
  verifyToken = "",
  session_id = "",
}) => {
  const [formValues, setFormValues] = useState({
    fullName: "",
    workEmail: "",
    businessName: "",
    phoneNumber: "",
    "cf-turnstile-response": "",
  });

  const { storeSendEmailAndDateTable } = useSendEmailAndDateTable();

  const { mutation: sendDemoEmail, loading: loadingSendDemoEmail } =
    useMutation({
      method: "post",
      url: "/business_partners",
      handleError: () => {
        disableLoading();
      },
      afterSuccess: () => {
        setTimeout(() => {
          disableLoading();
          goToSuccess();
          storeSendEmailAndDateTable(formValues.workEmail);
        }, 2000);
      },
    });

  const countryCodeForm = useForm();
  const { watch } = countryCodeForm;

  const countryCode = watch("country_code");

  const isValidFormValues =
    formValues.fullName.length > 0 &&
    formValues.workEmail.length > 0 &&
    formValues.businessName.length > 0 &&
    formValues.phoneNumber.length > 0 &&
    countryCode.value.length > 0;

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isValidFormValues) return;

    const { fullName, workEmail, businessName, phoneNumber } = formValues;
    const isLocalhost = process.env.NODE_ENV === "development";
    // const verifyToken = isLocalhost ? "temporary_bypass" : verifyTokenRaw;

    const payload = {
      user: {
        name: fullName,
        email: workEmail,
        mobile: phoneNumber,
        mobile_country_iso: countryCode?.value ?? "ID",
      },
      business: {
        name: businessName,
      },
      "cf-turnstile-response": verifyToken,
      session_id,
    };

    enableLoading();
    sendDemoEmail(payload);
  };

  return {
    formValues,
    countryCodeForm,
    isValidFormValues,
    isLoadingRegisterDemo: loadingSendDemoEmail,
    setFormValues,
    registerDemo: handleSubmit,
  };
};

export default useRegisterDemoForm;
