import { useRouter } from "next/router";
import { Fragment, useEffect } from "react";
import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../components/Text";
import { getUserRole } from "../../../../contexts/AuthContext";
import styles from "./styles.module.css";
import { eventsTracker } from "../../../../universalFunctions/events";
import { DEMO_PAGE_VIEW, DEMO_START_GUIDANCE } from "../../constants/events";
import { getUserRoleDemo } from "../../constants/role";

const SwitchRoleModal = ({
  isOpen,
  toggle,
  type = "", // "users", "workflow"
}) => {
  if (!isOpen) return null;
  const { push, isReady } = useRouter();
  const { roleName } = getUserRole();

  const rolesData = {
    Admin: {
      iconName: "AccountAdmin",
      desc: "Admin can access nearly all of Jack’s capabilities and controls, which includes approving and releasing transactions.",
      items: [
        "create_transactions",
        "transactions_page",
        "approve",
        "workflow",
        "users",
      ],
    },
    Bookkeeper: {
      iconName: "AccountBook",
      desc: "Bookkeeper can view, change category, and download all transactional data in Jack.",
      items: ["create_transactions", "transactions_page", "users"],
    },
    Employee: {
      iconName: "AccountEmployee",
      desc: "Employee can create or request any transaction and view their submitted transactions.",
      items: ["create_transactions", "transactions_page", "approve", "users"],
    },
    "Business Owner": {
      iconName: "AccountBO",
      desc: "Business Owner has access to all of Jack’s full capabilities and controls, which includes approving and releasing transactions.",
      items: [
        "create_transactions",
        "transactions_page",
        "approve",
        "workflow",
        "users",
      ],
    },
  };
  const { page_title } = getUserRoleDemo(roleName);

  const todoList = [
    {
      id: "create_transactions",
      label: "Create and request transactions",
      icon: "FileAddOutline",
      onClick: () => {
        // ke dashboard, buka button create
        eventsTracker(DEMO_START_GUIDANCE, { page_title, activity_type: "create_trx" });
        toggle();
        push({ query: { openCreate: true } });
      },
    },
    {
      id: "transactions_page",
      label: "Understanding Transaction page",
      icon: "TableView",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, { page_title, activity_type: "trx_page" });
        push("/transactions");
      },
    },
    {
      id: "approve",
      label: "Approve transaction request",
      icon: "FactCheck",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, {
          page_title,
          activity_type: "approve_trx",
        });
        push("/task");
      },
    },
    {
      id: "workflow",
      label: "Create approval workflow",
      icon: "Workflow",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, { page_title, activity_type: "workflow" });
        push("/workflow");
      },
    },
    {
      id: "users",
      label: "Manage users & teams",
      icon: "PaperPlaneOutline",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, {
          page_title,
          activity_type: "user_and_teams",
        });
        push("/people-management");
      },
    },
  ];

  const iconName = rolesData?.[roleName]?.["iconName"];
  const desc = rolesData?.[roleName]?.["desc"];
  const todos = rolesData?.[roleName]?.["items"];

  const title = `Role switched to ${roleName}!`;
  const header = (
    <div className={styles["modal-header"]}>
      <div />
      <GothamBold className={styles["title"]} style={{ fontSize: 20 }}>
        {title}
      </GothamBold>
      <button className={styles["close-btn-container"]} onClick={toggle}>
        <JackIcons
          name="close-outline"
          fill={jackColors.neutral900}
          style={{ height: 20 }}
        />
      </button>
    </div>
  );

  const upperBody = (
    <Fragment>
      <JackIcons name={iconName} className={styles["icon"]} />

      <GothamRegular style={{ marginBottom: 32 }}>{desc}</GothamRegular>
    </Fragment>
  );

  const text = `You can explore guides related to ${roleName}:`;
  const bottomBody = (
    <Fragment>
      <GothamRegular style={{ marginBottom: 8 }}>{text}</GothamRegular>
      <div className={styles["todo-card-container"]}>
        {todos?.map((todo, i) => {
          const item = todoList.find((item) => item.id === todo);
          return <TodoItem item={item} key={i} />;
        })}
      </div>
    </Fragment>
  );

  const mainContent = (
    <div className={styles["modal-container"]}>
      {header}
      {upperBody}
      {bottomBody}
    </div>
  );

  const bottomButton = (
    <div className={styles["modal-bottom"]}>
      <ButtonJack
        onClick={toggle}
        rightIcon={<JackIcons name="ArrowForward" fill="black" />}
      >
        I'll explore by myself
      </ButtonJack>
    </div>
  );

  useEffect(() => {
    const { page_title } = getUserRoleDemo(roleName);
    eventsTracker(DEMO_PAGE_VIEW, { page_title });
  }, []);

  return (
    <Modal
      centered
      toggle={toggle}
      isOpen={isOpen}
      unmountOnClose
      contentClassName={styles["modal"]}
    >
      {mainContent}
      {bottomButton}
    </Modal>
  );
};

export default SwitchRoleModal;

export const TodoItem = ({ item = {} }) => {
  const { icon, label, onClick } = item ?? {};
  return (
    <div className={styles["todo-card"]}>
      <div className={styles["todo-card-left"]}>
        <div className={styles["todo-card-icon"]}>
          <JackIcons
            name={icon}
            fill={jackColors.black34}
            style={{ height: "16px" }}
          />
        </div>
        <GothamRegular style={{ textAlign: "start" }}>{label}</GothamRegular>
      </div>

      <ButtonJack
        isBullet
        onClick={onClick}
        rightIcon={
          <JackIcons name={"PlayCircleOutline"} fill={jackColors.black34} />
        }
        className={styles["showme-btn"]}
      >
        <GothamMedium style={{ fontSize: "12px", lineHeight: "16px" }}>
          Show me
        </GothamMedium>
      </ButtonJack>
    </div>
  );
};
