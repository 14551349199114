import { useModalWidth } from "../../../components/Modals";
import { Modal } from "reactstrap";
import { jackColors } from "../../../assets/colors";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../components/Text";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import ModalImage from "../../../assets/images/notif-modal-mask-mobile.png";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { eventsTracker } from "../../../universalFunctions/events";
import { useDemo } from "../../../contexts/DemoContext";
import ReactPlayer from "react-player";
import { urlDecider } from "../../../tools/api";
import {
  BILL_MODULE,
  INTERNATIONAL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  VA_MODULE,
} from "../../productDemoComponents/constants";

export const FinishedTourModal = ({
  isOpen,
  toggle,
  handleClick,
  onClosed,
}) => {
  const handleClickArticle = () => {
    window.open(
      "https://support.itsjack.com/en/support/solutions/articles/150000167868-how-to-set-up-my-notification-preferences",
      "_blank"
    );
  };
  return (
    <TemplateModal
      isOpen={isOpen}
      toggle={toggle}
      onClosed={onClosed}
      width={476}
      imgSrc={ModalImage}
      withCloseButton
      title={"You’ve explored Notification! 🎉"}
      bodyComponent={
        <div>
          <GothamRegular
            style={{
              color: jackColors.grey6C,
              marginBottom: 16,
              textAlign: "center",
            }}
          >
            Make sure you’ve enable the notification permissions on your device
            and browser.{" "}
            <span onClick={handleClickArticle}>
              <TextInlineMedium
                style={{ textDecoration: "underline", cursor: "pointer" }}
              >
                {" "}
                Learn more via this article{" "}
              </TextInlineMedium>
            </span>
          </GothamRegular>
        </div>
      }
      bottomComponent={
        <div className="d-flex flex-column" style={{ gap: 16 }}>
          <ButtonJack onClick={handleClick} style={{ width: "100%" }}>
            Manage Notification
          </ButtonJack>
          <ButtonJack
            type="borderless"
            onClick={() => {
              toggle();
              eventsTracker("notif_tourguide_skip_manage_notif");
            }}
            style={{ width: "100%" }}
          >
            I'll do this later
          </ButtonJack>
        </div>
      }
    />
  );
};

export const InitTourModal = ({ isOpen, toggle, handleClick, onClosed }) => {
  return (
    <TemplateModal
      withCloseButton
      isOpen={isOpen}
      toggle={toggle}
      width={476}
      imgSrc={ModalImage}
      title={"Introducing new feature: Notifications"}
      onClosed={onClosed}
      bodyComponent={
        <div>
          <GothamMedium
            className="font20 text-center"
            style={{
              marginBottom: 32,
            }}
          >
            A better way to stay updated!
          </GothamMedium>
          <GothamRegular
            style={{
              color: jackColors.grey6C,
              marginBottom: 16,
              textAlign: "center",
            }}
          >
            Stay effortlessly informed and never miss a beat with our enhanced
            <TextInlineMedium> notification system </TextInlineMedium>. Get
            real-time updates, important reminders, and valuable insights
            delivered directly to you.
          </GothamRegular>
        </div>
      }
      bottomComponent={
        <ButtonJack onClick={handleClick} style={{ width: "100%" }}>
          Explore Notifications
        </ButtonJack>
      }
    />
  );
};

export const TemplateModal = ({
  isOpen,
  toggle,
  onClosed = () => {},
  width,
  title,
  titleStyle = {},
  titleContainerStyle = {},
  img,
  imgSrc,
  imgStyle = {},
  bodyComponent,
  bottomComponent,
  withCloseButton,
}) => {
  if (!isOpen) return null;
  const modalWidth = useModalWidth(width);

  return (
    <Modal
      // onClosed={() => onClosed()}
      fullscreen={"false"}
      fade={!isOpen}
      centered
      toggle={toggle}
      isOpen={isOpen}
      className={`my-0 ${isOpen ? "modal-slide-down" : ""}`}
      contentClassName={"modal-transfez"}
      backdrop={"static"}
      keyboard={false}
    >
      <div
        style={{
          position: "relative",
          width: modalWidth,
          borderRadius: 8,
          flexDirection: "column",
          justifyContent: "start",
        }}
        className="d-flex"
      >
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
            display: "flex",
            justifyContent: withCloseButton ? "space-between" : "start",
            alignItems: "center",
            ...titleContainerStyle,
          }}
        >
          <GothamMedium className="font20" style={titleStyle}>
            {title}
          </GothamMedium>
          {withCloseButton && (
            <div
              onClick={() => {
                onClosed();
                toggle();
              }}
              style={{
                padding: 10,
                borderRadius: 4,
                border: `1px solid var(--neutral-500, #E6E6E8)`,
                cursor: "pointer",
              }}
            >
              <JackIcons
                name="Close"
                fill={jackColors.black34}
                style={{ height: 20, width: 20 }}
              />
            </div>
          )}
        </div>

        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
          }}
        >
          {img ? (
            img
          ) : (
            <img
              src={imgSrc}
              style={{
                maxWidth: modalWidth - 40,
                marginBottom: 16,
                ...imgStyle,
              }}
            />
          )}
          {bodyComponent ? bodyComponent : null}
        </div>
        <div
          style={{
            padding: 20,
            borderBottom: `1px solid ${jackColors.greyE6}`,
          }}
        >
          {bottomComponent ? bottomComponent : null}
        </div>
      </div>
    </Modal>
  );
};

export const TooltipGuidedTour = ({
  continuous,
  index,
  step,
  backProps,
  closeProps,
  primaryProps,
  tooltipProps,
  isLastStep,
  size,
  skipProps: skipPropsRaw,
}) => {
  const {
    title,
    content,
    showSkipButton = false,
    disableButton = false,
    module = null,
  } = step || {};
  const { onClick: onClickRaw } = skipPropsRaw ?? {};
  const { setGuidanceState } = useDemo();
  const onClick = (e) => {
    setGuidanceState((prev) => ({ ...prev, isTourRunning: false }));
    onClickRaw(e);
  };

  const skipProps = { ...skipPropsRaw, onClick };

  const url = (function () {
    switch (module) {
      case BILL_MODULE:
        return "/videos/guided-tour-bill-payment.mov";
      case PAYROLL_MODULE:
        return "/videos/guided-tour-payroll.mov";
      case INTERNATIONAL_MODULE:
        return "/videos/guided-tour-international-transfer.mov";
      case VA_MODULE:
        return "/videos/guided-tour-virtual-account.mov";
      case LOCAL_MODULE:
        return "/videos/guided-tour-local-transfer.mov";
      case REIMBURSEMENT_MODULE:
        return "/videos/guided-tour-reimbursement.mov";
      default:
        return "";
    }
  })();

  return (
    <div
      className={"guided-tour-inner"}
      innerclassname={"guided-tour-inner"}
      arrowclassname={"guided-tour-arrow"}
      {...tooltipProps}
    >
      <div
        className="d-flex flex-column"
        style={{
          textAlign: "left",
          gap: 12,
        }}
      >
        {!!module && (
          <div
            style={{
              borderRadius: "8px",
              backgroundColor: "white",
            }}
          >
            <ReactPlayer
              loop
              muted
              playing={true}
              width="100%"
              height="100%"
              url={url}
              style={{ overflow: "hidden", borderRadius: "8px" }}
            />
          </div>
        )}
        {title && (
          <GothamMedium className="font14 text-start">{title}</GothamMedium>
        )}
        {typeof content === "string" ? (
          <GothamRegular className="font12 text-start text-white">
            {content}
          </GothamRegular>
        ) : (
          content
        )}
        {!disableButton && (
          <div className="d-flex justify-content-between align-items-center">
            <GothamRegular className="font12 text-start text-white">
              {index + 1} of {size}
            </GothamRegular>
            <div className="d-flex" style={{ gap: 8 }}>
              {showSkipButton && !isLastStep && (
                <div style={{ padding: "6px" }}>
                  <GothamMedium {...skipProps} id="skip">
                    {/* <GothamMedium {...closeProps} id="skip"> */}
                    Dismiss
                  </GothamMedium>
                </div>
              )}
              {!showSkipButton && index > 0 && (
                <div style={{ padding: "6px" }}>
                  <GothamMedium {...backProps} id="back">
                    Back
                  </GothamMedium>
                </div>
              )}
              {continuous && (
                <div className="guided-tour-next-btn">
                  <GothamMedium
                    {...primaryProps}
                    id="next"
                    style={{ color: jackColors.greyE6 }}
                  >
                    {isLastStep ? "Got it" : "Next"}
                  </GothamMedium>
                </div>
              )}
              {!continuous && (
                <GothamMedium {...closeProps} id="close">
                  Close
                </GothamMedium>
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
