import { eventsTracker } from "../../../../universalFunctions/events";
import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  INTERNATIONAL_FIRST_TARGET,
  INTERNATIONAL_SECOND_TARGET,
} from "../../constants";
import { DEMO_GUIDED_TOUR_BACK, DEMO_GUIDED_TOUR_NEXT } from "../../constants/events";

const locale = {
  last: <NextText>Got it</NextText>,
  close: <NextText>Got it</NextText>,
  back: <NextText>Back</NextText>,
  next: <NextText>Next</NextText>,
};

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${INTERNATIONAL_FIRST_TARGET}`,
    content: (
      <TooltipContent
        title="Here’s your transfer breakdown"
        description="Type the desired amount in “You pay” or “Recipient gets” section. The calculation will adjust automatically."
      />
    ),
    placement: "right-start",
    disableScrolling: false,
    locale: {
      ...locale,
      close: <NextText>Next</NextText>,
    },
    eventPayload: {
      activity_type: "create_international_transfer",
      guided_tour_activity: "transfer_breakdown",
    },
  },
  {
    ...DEFAULT_PROPERTIES,
    target: `#${INTERNATIONAL_SECOND_TARGET}`,
    content: (
      <TooltipContent
      title="Adjust your transfer details"
      description="Fill these fields according to your transfer details."
      />
    ),
    disableScrolling: false,
    locale,
    eventPayload: {
      activity_type: "create_international_transfer",
        guided_tour_activity: "adjust_transfer_details",
    },
    // disableBeacon: false, // kl pake callback apuy hrs true
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle, step } = data ?? {};
  const {eventPayload = {}} = step ?? {}

  const stepCount = steps.length - 1;

  const isComplete = lifecycle === "complete";

  if (!isComplete) return;

  switch (action) {
    case "close":
      eventsTracker(DEMO_GUIDED_TOUR_NEXT, eventPayload)
      if (index < stepCount) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex + 1,
        }));
        break;
      }
      if (index === stepCount) {
        setGuidedTourState(defaultState);
        break;
      }
      break;

    case "prev":
      eventsTracker(DEMO_GUIDED_TOUR_BACK, eventPayload)
      if (index > 0) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex - 1,
        }));
        break;
      }
      break;

    default:
      break;
  }
};

const internationalFirstPageProps = { steps, callback };

export default internationalFirstPageProps;
