import { CustomRichTextArea } from "../../../../../components/CustomRichTextArea";
import CardDisplay from "../components/CardDisplay";
import BottomContainer from "../components/BottomContainer";
import { useForm } from "react-hook-form";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { colors } from "../../../../../assets/colors";
import { GothamMedium } from "../../../../../components/Text";
import { useMutation } from "../../../../../tools/api";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { useRef } from "react";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const PhysicalEditInfo = () => {
  const { cardDetail, setCurrentScreen, refetchCardDetail } =
    useCardDetailContext();
  const { id, description: currentDescription } = cardDetail || {};
  const backHandler = () => setCurrentScreen("index");
  const { successSnackBar } = ToasterHook();
  const useFormObj = useForm();
  const { handleSubmit, watch } = useFormObj;
  const descriptionWatch = watch("description");
  const refRichText = useRef(null);
  const richTextInputHeight = refRichText?.current?.clientHeight;

  const { mutation: mutationPhysicalUpdate, loading: loadingPhysicalUpdate } =
    useMutation({
      url: `/cards/physical_card/${id}`,
      afterSuccess: async () => {
        await refetchCardDetail();
        backHandler();
        successSnackBar({ msg: "Card description has been changed!" });
      },
    });

  const onSubmit = (values) => {
    const { description } = values;
    mutationPhysicalUpdate({
      description,
    });
  };

  const isButtonDisabled =
    descriptionWatch === currentDescription ||
    descriptionWatch === `<p>${currentDescription}</p>`;

  const styles = {
    container: {
      position: "relative",
      height: "100%",
      marginBottom: `${richTextInputHeight - 250}px`,
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    title: {
      color: colors.neutral900,
      fontSize: "24px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "100%",
    },
  };

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <GothamMedium style={styles.title}>Edit Card Description</GothamMedium>
        <CardDisplay cardDetail={cardDetail} isSmall woReveal />
        <CustomRichTextArea
          name="description"
          useFormObj={useFormObj}
          helperText="Please write a different description from the current one."
          defaultValue={currentDescription}
          forwardRef={refRichText}
        />
      </div>
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack type="outline" onClick={backHandler} style={styles.button}>
          Cancel
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          disabled={isButtonDisabled}
          onClick={handleSubmit(onSubmit)}
          isLoading={loadingPhysicalUpdate}
        >
          Save Change
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default PhysicalEditInfo;
