import { GothamRegular } from "../../../components/Text";
import { colors } from "../../../assets/colors";
import { Controller } from "react-hook-form";
import { useEffect } from "react";

const OPTIONS = [
  {
    value: "MONTHLY_AMOUNT_LIMIT",
    label: "Monthly",
  },
  {
    label: "One Time",
    value: "ONE_TIME_USE",
  },
];

const LimitTypeButton = ({ value, onChange, children, isSelected }) => {
  const circleContainerStyle = {
    borderRadius: "50%",
    border: `2px solid #505050`,
    padding: "2px",
  };

  const circleStyle = {
    borderRadius: "50%",
    width: "8px",
    height: "8px",
    transition: "all 0.1s ease-in-out",
  };

  const inputContainerStyle = {
    display: "flex",
    gap: "10px",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    height: "40px",
    border: `1px solid ${colors.neutral500}`,
    borderRadius: "4px",
    cursor: "pointer",
  };

  const availableLimitLabelStyle = {
    fontSize: "12px",
    color: colors.neutral900,
    letterSpacing: "0.2px",
  };

  return (
    <div
      style={inputContainerStyle}
      onClick={() => {
        onChange(value);
      }}
    >
      <div style={circleContainerStyle}>
        <div
          style={{
            ...circleStyle,
            backgroundColor: isSelected ? "#505050" : "transparent",
          }}
        />
      </div>
      <GothamRegular>{children}</GothamRegular>
    </div>
  );
};

const LimitTypeRadioButtons = ({
  useFormObj: { register, control },
  name,
  label,
}) => {
  useEffect(() => {
    register(name);
  }, []);

  const labelStyle = {
    marginBottom: "8px",
  };

  return (
    <div>
      {label && (
        <GothamRegular className="font12" style={labelStyle}>
          {label}
        </GothamRegular>
      )}
      <Controller
        control={control}
        name={name}
        render={({ value: currentValue, onChange }) => {
          return (
            <div style={{ display: "flex", gap: "16px" }}>
              {OPTIONS.map(({ value, label }) => {
                const isSelected = currentValue === value;
                const buttonProps = { value, onChange, isSelected };
                return (
                  <LimitTypeButton {...buttonProps} key={value}>
                    {label}
                  </LimitTypeButton>
                );
              })}
            </div>
          );
        }}
      />
    </div>
  );
};

export default LimitTypeRadioButtons;
