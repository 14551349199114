import { format } from "date-fns";
import { useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { SubTextTableComponent } from "../../../components/Table/components";
import { GothamRegular } from "../../../components/Text";
import CustomTooltip from "../../../components/Tooltip";
import { TransitionTr } from "../../../components/Transition";
import {
  digitDateFormatterReversed,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { EditVaModal } from "../details/parents";
import styles from "../va.module.css";
import { useBankDetails } from "./hooks";
import {
  MenuDropdownLayout,
  font12,
  icon20
} from "./utils";

export const OverflowTd = ({
  children,
  tdStyle,
  style,
  aboveComponent,
  belowComponent,
  useOwnComponent = false,
}) => {
  return (
    <td>
      <div style={tdStyle}>
        {aboveComponent}
        {useOwnComponent ? (
          children
        ) : (
          <GothamRegular
            style={{
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
              ...style,
            }}
          >
            {children}
          </GothamRegular>
        )}
        {belowComponent}
      </div>
    </td>
  );
};

export const expiryDateDecider = (type, rawData) => {
  if (type === "never") return "Never Expires";
  return time(rawData);
};

export const TypeLabel = ({ isOpen, isOutline, customContainerStyle }) => {
  const textLabel = isOpen ? "Open Amount" : "Closed Amount";
  const icon = (
    <JackIcons
      name={isOpen ? "wifi-protected-setup" : "lock"}
      fill={jackColors.neutral700}
      style={{ height: 12, width: 12 }}
    />
  );

  const containerStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
    padding: "6px 8px",
    border: isOutline ? `1px solid ${jackColors.neutral500}` : "none",
    borderRadius: "4px",
    width: "fit-content",
    ...customContainerStyle,
  };

  const textStyle = {
    color: jackColors.neutral900,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  };

  return (
    <div style={containerStyle}>
      {icon}
      <GothamRegular style={textStyle} className={"font10"}>
        {textLabel}
      </GothamRegular>
    </div>
  );
};

export const time = (rawDate = new Date(), type) => {
  const date = format(new Date(rawDate), "d/MM/yyyy");
  const hour = format(new Date(rawDate), "HH:mm");
  if (type === "dateOnly") return date;
  return (
    <SubTextTableComponent
      text={date}
      subText={`${hour} WIB`}
      subTextStyleAux={font12}
    />
  );
};

export const VATableItem = ({
  item,
  rawItem = {},
  onClick = () => {},
  index,
  isLast,
  isArchived,
  isDetails,
  isAPI = false,
  isTrxPage = false,
  refetch = () => {},
  setDeactivateId = () => {},
}) => {
  const {
    expiryDate,
    referenceId,
    displayName,
    vaNumber,
    bankName,
    status,
    isOpen,
    // TRANSACTION DATA
    amount,
    fee: adminFee,
    bank_name,
    created_at,
    reference_id,
    state,
  } = item;

  const [hovered, setHovered] = useState();
  const { successSnackBar } = ToasterHook();

  const expiryType = !!Date.parse(expiryDate) ? "date" : "never";
  const amountReceived = amount - adminFee || 0;
  const vaId = item?.id;
  const isDeactivated = status?.toLowerCase() === "deactivated";

  const { isOpen: isEditModalOpen, toggle } = useModalHook();
  const { icon, iconSize } = useBankDetails(bankName);

  const handleCopy = (e) => {
    e.preventDefault();
    e.stopPropagation();
    navigator.clipboard.writeText(vaNumber);
    successSnackBar({ msg: "VA Number copied" });
  };

  const hideEdit = status === "Expired";

  const moreButtons = [
    !hideEdit && {
      label: "Edit",
      logo: "edit-outline",
      logoFill: jackColors.neutral900,
      onClickFunction: () => {
        toggle();
      },
    },
    {
      label: "Archive",
      logo: "archive-outline",
      logoFill: "#E73126",
      onClickFunction: () => {
        setDeactivateId(vaId);
      },
    },
  ];

  const moreButtonsComponent = moreButtons.map((button) => {
    if (!button) return null;
    return (
      <ButtonJack
        style={{ padding: 0, width: "100%", justifyContent: "flex-start" }}
        type="borderless"
        leftIcon={
          <JackIcons name={button.logo} fill={button.logoFill} style={icon20} />
        }
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          button.onClickFunction();
        }}
      >
        {button.label}
      </ButtonJack>
    );
  });

  if (isDetails)
    return (
      <>
        <TransitionTr
          counter={index}
          isQuick
          perPage={15}
          key={index}
          onClick={() => onClick({ ...item, category: "va" })}
          style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
        >
          <td>{digitDateFormatterReversed(created_at)}</td>
          <OverflowTd>{reference_id}</OverflowTd>
          <OverflowTd>
            <StatusLabelJack status={state} isOutline woTextFormatter />
          </OverflowTd>
          <OverflowTd
            style={{ textAlign: "left" }}
          >{`IDR ${formatCurrencyNoDecimal(amount)}`}</OverflowTd>
          <OverflowTd
            style={{ textAlign: "left" }}
          >{`IDR ${formatCurrencyNoDecimal(adminFee)}`}</OverflowTd>
          <OverflowTd
            style={{ textAlign: "right" }}
          >{`IDR ${formatCurrencyNoDecimal(amountReceived)}`}</OverflowTd>
        </TransitionTr>
      </>
    );

  if (isTrxPage)
    return (
      <>
        <TransitionTr
          counter={index}
          isQuick
          perPage={15}
          key={index}
          onClick={() => onClick({ ...item, category: "va" })}
          style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
        >
          <div />
          <td>{digitDateFormatterReversed(created_at)}</td>
          <td>
            <div className="d-flex align-items-center" style={{ gap: 12 }}>
              <StatusAccountIconJack
                state={"collection"}
                source={"virtual_account"}
              />
              <GothamRegular
                style={{
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  width: "calc(100% - 36px)",
                }}
              >
                {`Payment received on VA: ${displayName}`}
              </GothamRegular>
            </div>
          </td>
          <OverflowTd tdStyle={{ width: 134 }}>
            <StatusLabelJack status={status} isOutline woTextFormatter />
          </OverflowTd>
          <td>Virtual Account</td>
          <OverflowTd
            style={{ textAlign: "right" }}
          >{`IDR ${formatCurrencyNoDecimal(amount)}`}</OverflowTd>
        </TransitionTr>
        <EditVaModal
          rawData={rawItem}
          modalControl={{ isOpen: isEditModalOpen, toggle }}
          refetch={refetch}
        />
      </>
    );

  return (
    <>
      <TransitionTr
        counter={index}
        isQuick
        perPage={15}
        key={index}
        onClick={() => onClick({ ...item, category: "va" })}
        style={{ borderWidth: isLast ? 0 : 1, height: 65 }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        {!isAPI && (
          <OverflowTd tdStyle={{ width: 104 }}>
            {expiryDateDecider(expiryType, expiryDate)}
          </OverflowTd>
        )}
        <OverflowTd tdStyle={{ width: 104 }}>{referenceId}</OverflowTd>
        <OverflowTd tdStyle={{ width: 144 }}>{displayName}</OverflowTd>
        <OverflowTd
          tdStyle={{
            display: "flex",
            columnGap: 8,
            width: 195,
          }}
          style={{ width: "fit-content" }}
          hovered={hovered == index}
          setHovered={setHovered}
          belowComponent={
            !!vaNumber &&
            hovered && (
              <HoverComponent
                index={index}
                hoverText={`Copy ${vaNumber}`}
                hoverComponent={
                  <div onClick={handleCopy}>
                    <JackIcons
                      name="file-copy"
                      fill={jackColors.neutral700}
                      style={{ width: 20, height: 20 }}
                    />
                  </div>
                }
              />
            )
          }
        >
          {vaNumber ? vaNumber : "-"}
        </OverflowTd>
        <OverflowTd
          aboveComponent={<img src={icon} alt="bank-icon" style={iconSize} />}
          tdStyle={{
            display: "flex",
            alignItems: "center",
            gap: 8,
            width: 160,
          }}
        >
          {bankName}
        </OverflowTd>
        <OverflowTd tdStyle={{ width: 134 }}>
          <StatusLabelJack status={status} isOutline woTextFormatter />
        </OverflowTd>
        <OverflowTd tdStyle={{ width: 119 }}>
          <TypeLabel isOpen={isOpen} isOutline />
        </OverflowTd>
        {!isArchived && !isAPI && (
          <OverflowTd
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <MenuDropdownLayout
              content={<>{moreButtonsComponent}</>}
              contentDivStyle={{ rowGap: 0, padding: "0px 0px" }}
              moreDropdownStyle={{ cursor: "default" }}
              clickComponent={
                <JackIcons
                  className={styles["more-icon"]}
                  name="more-horizontal"
                  fill={jackColors.neutral700}
                />
              }
            />
          </OverflowTd>
        )}
      </TransitionTr>
      <EditVaModal
        rawData={rawItem}
        modalControl={{ isOpen: isEditModalOpen, toggle }}
        refetch={refetch}
      />
    </>
  );
};

export const HoverComponent = ({
  hoverComponent = <></>,
  hoverText = "Hovered!",
  hoverPlacement = "top",
  textAlign = "center",
  index,
  style,
  ...props
}) => {
  return (
    <CustomTooltip
      customId={`tooltip-${index}`}
      innerClassName="editorTooltip"
      popperClassName="white-arrow"
      placement={hoverPlacement}
      text={
        <div
          style={{
            width: "fit-content",
            height: "auto",
            backgroundColor: "rgba(255,255,255,1) !important",
            opacity: "1 !important",
            zIndex: 200,
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            textAlign: "center",
            padding: 0,
            ...style,
          }}
        >
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.neutral800,
              lineHeight: "16px",
              textAlign: textAlign,
            }}
            id={`tooltip-${index}`}
          >
            {hoverText}
          </GothamRegular>
        </div>
      }
      {...props}
    >
      {hoverComponent}
    </CustomTooltip>
  );
};
