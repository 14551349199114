import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import { colors } from "../../../../../../assets/colors";
import { FileInputJack } from "../../../../../../components/inputs/fileInputs/fileInput";
import { useForm } from "react-hook-form";
import { useMutation } from "../../../../../../tools/api";
import { urlDecider } from "../../../../../../tools/api";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { useEffect, useState } from "react";
import { fileSizeString } from "../../../../../../components/tools";
import { useFileViewer } from "../../../../../../contexts/FilePreviewContext/parent";
import { fetch } from "../../../../../../tools/api";
import { FaSpinner } from "react-icons/fa";
import BottomContainer from "../../components/BottomContainer";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../../../universalFunctions/events";

const UPLOAD_QUOTA = 3;

const ReceiptErrorText = () => (
  <>
    <TextInlineMedium>
      You’ve reached max {UPLOAD_QUOTA} Receipts.
    </TextInlineMedium>{" "}
    Please remove any extra file(s) to continue.
  </>
);

const AddReceipt = ({
  handleBack,
  transactionDetail,
  refetchTransactionDetail: refetchTransactionDetailParent,
  onUploadDocument = () => {},
  isCanManageCard,
}) => {
  const [isShowFileInput, setIsShowFileInput] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const { id } = transactionDetail || {};

  const { successSnackBar } = ToasterHook();

  const useFormObj = useForm();
  const { setValue, watch } = useFormObj;

  const { data: transactionDetailLatest, refetch } = fetch({
    url: `/card_transactions/${id}`,
    formatter: (res) => {
      const transactionDetail = res?.data;
      transactionDetail.file_details = transactionDetail.file_details?.map(
        (file) => ({
          ...file,
          url: `${urlDecider(
            process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL
          )}${file.url}`,
        })
      );
      return transactionDetail;
    },
  });

  const { file_details: fileDetails } = transactionDetailLatest || {};

  const { mutation: mutationUpload, loading: loadingUpload } = useMutation({
    url: `/card_transactions/${transactionDetail?.id}/update`,
    afterSuccess: async () => {
      eventsTracker("card_transaction_receipt_upload", {
        card_transaction_id: transactionDetail?.id,
      });
      await refetch();
      setValue("upload_file", null);
      successSnackBar({ msg: "Transaction receipt has been updated" });
      await refetchTransactionDetailParent();
      onUploadDocument();
    },
  });

  const { mutation: mutationDelete, loading: loadingDelete } = useMutation({
    url: `/card_transactions/${transactionDetail?.id}/delete`,
    afterSuccess: async () => {
      eventsTracker("card_transaction_receipt_delete", {
        card_transaction_id: transactionDetail?.id,
      });
      setDeleteId(null);
      await refetch();
      successSnackBar({ msg: "Transaction receipt has been updated" });
      await refetchTransactionDetailParent();
      onUploadDocument();
    },
  });

  const fileWatch = watch("upload_file");
  const numberOfFile = fileDetails?.length;
  const isMaxFile = Boolean(fileDetails?.length >= UPLOAD_QUOTA);

  const { show } = useFileViewer();

  useEffect(() => {
    if (fileDetails?.length && !isMaxFile) return setIsShowFileInput(false);
    if (isMaxFile) return setIsShowFileInput(false);
    setIsShowFileInput(true);
  }, [numberOfFile, isMaxFile]);

  useEffect(async () => {
    if (fileWatch) {
      const formData = new FormData();
      formData.append("files[]", fileWatch);
      await mutationUpload(formData);
    }
  }, [fileWatch]);

  const containerStyle = {
    position: "relative",
    height: "100%",
  };

  const titleStyle = {
    marginBottom: "8px",
    color: colors.neutral900,
    fontSize: "24px",
  };

  const helpertextStyle = {
    color: colors.neutral800,
    letterSpacing: "0.1px",
    marginBottom: "32px",
  };

  const labelStyle = {
    marginBottom: "8px",
  };

  const fileContainerStyle = {
    padding: 12,
    border: `1px solid ${colors.neutral500}`,
    borderRadius: 4,
    marginBottom: 8,
    width: "100%",
    cursor: "pointer",
  };

  const fileNameStyle = {
    color: colors.neutral900,
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    width: "200px",
  };

  const bottomContainerStyle = {
    padding: "20px 20px 0px",
  };

  const buttonStyle = {
    width: "100%",
  };

  const confirmDeleteContainerStyle = {
    display: "flex",
    height: "56px",
    margin: "-12px -12px -12px 0px",
  };

  const confirmContainerStyle = {
    height: "100%",
    display: "grid",
    placeItems: "center",
    padding: "20px 10px",
  };

  const deleteContainerStyle = {
    ...confirmContainerStyle,
    backgroundColor: colors.redE7,
  };

  const deleteStyle = {
    color: colors.neutral100,
  };

  return (
    <div style={containerStyle}>
      <GothamMedium style={titleStyle}>Receipt</GothamMedium>
      <GothamRegular style={helpertextStyle}>
        You can upload max. {UPLOAD_QUOTA} receipts
      </GothamRegular>
      <GothamRegular style={labelStyle}>
        {numberOfFile ? `${numberOfFile} file(s) uploaded` : "Upload file"}
      </GothamRegular>

      {fileDetails?.map((file) => {
        const { id, name, file_size: fileSize, url } = file;
        const sizeLabel = fileSizeString(fileSize);
        const isSameDeleteFile = deleteId === id;
        const isFileLoadingDelete = loadingDelete && isSameDeleteFile;

        const onClick = () => {
          show({ fileUrl: url });
          eventsTracker("card_transaction_receipt_view", {
            card_transaction_id: transactionDetail?.id,
          });
        };

        return (
          <div
            className="d-flex align-items-center justify-content-between"
            style={fileContainerStyle}
            onClick={onClick}
          >
            <div className="d-flex align-items-center">
              <JackIcons
                name="Uploaded_File"
                fill={colors.green06}
                style={{ marginRight: 8 }}
              />
              <div>
                <GothamRegular className="font12" style={fileNameStyle}>
                  {name}
                </GothamRegular>
                <GothamRegular
                  className="font12"
                  style={{ color: colors.neutral700 }}
                >
                  {sizeLabel}
                </GothamRegular>
              </div>
            </div>
            {isCanManageCard && (
              <>
                {isFileLoadingDelete ? (
                  <FaSpinner className="icon-spin" />
                ) : isSameDeleteFile ? (
                  <div style={confirmDeleteContainerStyle}>
                    <div
                      style={deleteContainerStyle}
                      onClick={(event) => {
                        event.stopPropagation();
                        mutationDelete({ file_id: id });
                      }}
                    >
                      <GothamRegular style={deleteStyle}>Delete</GothamRegular>
                    </div>
                    <div
                      style={confirmContainerStyle}
                      onClick={(event) => {
                        event.stopPropagation();
                        setDeleteId(null);
                      }}
                    >
                      <GothamRegular>Cancel</GothamRegular>
                    </div>
                  </div>
                ) : (
                  <JackIcons
                    name="delete-outline"
                    fill={colors.neutral700}
                    onClick={(event) => {
                      event.stopPropagation();
                      setDeleteId(id);
                    }}
                  />
                )}
              </>
            )}
          </div>
        );
      })}
      {!isMaxFile && isCanManageCard && (
        <>
          {isShowFileInput ? (
            <FileInputJack
              name="upload_file"
              fileTypes={[
                "image/jpg",
                "image/jpeg",
                "image/png",
                "application/pdf",
              ]}
              firstCopy="Browse or drag file here"
              useFormObj={useFormObj}
              loading={loadingUpload}
              containerMaxHeight={isMaxFile ? 140 : 174}
              customError={isMaxFile && <ReceiptErrorText />}
              showLabel={false}
              formData
              enablePreview
            />
          ) : (
            <ButtonJack
              leftIcon={
                <JackIcons name="plus-outline" fill={colors.neutral900} />
              }
              onClick={() => setIsShowFileInput(true)}
              type="outline"
            >
              Add Receipt
            </ButtonJack>
          )}
        </>
      )}

      <BottomContainer style={bottomContainerStyle}>
        <ButtonJack onClick={handleBack} style={buttonStyle}>
          Close
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default AddReceipt;
