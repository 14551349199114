import { useEffect } from "react";

export const useClickOutside = ({
  ref,
  clickOutside,
  dependency = false,
  isOldMethod = false,
}) => {
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (ref.current && !ref.current.contains(event.target)) {
        clickOutside();
      }
    };

    if (isOldMethod) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.addEventListener("click", handleClickOutside, true);
    }
    return () => {
      if (isOldMethod) {
        document.removeEventListener("mousedown", handleClickOutside);
      } else {
        document.removeEventListener("click", handleClickOutside, true);
      }
    };
  }, [ref, dependency]);
};
