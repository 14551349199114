import { isEmpty, pickBy, wrap } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { arraySplitter, containsOnlyDigits } from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness, apiBusinessLong } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { payrollEditFormatter, payrollSubmitFormatter } from "./formatter";
import { getEmails } from "../../localTransferComponents/localTransfer/parent";
import { TrimNotesLocalPayroll } from "../../localTransferComponents/localTransfer/logics";

const createFormatter = (array, partner_id) =>
  array.map((item) => {
    const { local_recipient_attributes } = item || {};
    return {
      ...item,
      local_recipient_attributes: {
        ...local_recipient_attributes,
        partner_id,
      },
      partner_id,
    };
  });

export const usePayrollLogics = () => {
  const [loading, setLoading] = useState(false);
  const { user } = useGetAuth();
  const { push, query } = useRouter();

  const { id, action, ancestor_id } = query;
  const partner_id = user?.partner?.id;

  const isResubmit = action === "resubmit";

  const { errorToasterApi } = ToasterHook();

  const changeState = async (id, state) =>
    await apiBusinessLong.put(`/local_transaction_batches/${id}/change_state`, {
      state,
    });

  const create = async ({ values, firstValues, isValidate, ancestor_id }) => {
    // in a nutshell: post it then update the remaining values

    // let me explain:
    // we need for loop to avoid status 504 (timeout from BE)
    // the work around method: just loop it in `for loop`.

    // method:
    // 1. split the array.
    // 2. we need `batch_id` in order to updates payroll. So first thing first: post it to BE
    // 3. if it's done, we need to update it based by `batch_id` we get from post.
    // 4. format it to the correct data. Then just send it with update.
    // 5. WELL DONE, now you can say goodbye to 504

    try {
      setLoading(true);

      const result = payrollSubmitFormatter(values, firstValues);

      const {
        local_transactions_attributes: local_transactions_attributesRaw,
      } = result || {};

      const [
        local_transactions_attributes,
        ...local_transactions_attributesCreate
      ] = arraySplitter(local_transactions_attributesRaw, 50);

      const payload = {
        ...result,
        local_transactions_attributes,
        payment_method: "indonesian_wallet",
      };

      if (ancestor_id) payload.ancestor_id = ancestor_id;

      const { data } = await apiBusinessLong.post(
        "/local_transaction_batches",
        payload
      );

      const { batch_id, id } = data?.data?.payroll || {};

      for (let i = 0; i < local_transactions_attributesCreate.length; i++) {
        const local_transactions_attributes = createFormatter(
          local_transactions_attributesCreate[i],
          partner_id
        );
        const createPayload = { local_transactions_attributes, partner_id };

        await apiBusinessLong.put(
          `/local_transaction_batches/${batch_id}`,
          createPayload
        );
      }

      if (isValidate) {
        await changeState(batch_id, "validate_recipient");
        eventsTracker("payroll_validate", { id });
        push({
          pathname: `/payroll/create/${id}`,
          query: { ...query, state: "validating" },
        });
      } else {
        eventsTracker("payroll_save_as_draft", { id });
        push("/draft/?menu=payroll");
      }
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const update = async ({
    firstValues: name,
    values,
    defaultValues,
    isValidate,
    isDraft,
    revalidate,
    batchValues,
  }) => {
    try {
      setLoading(true);

      const { batch_id } = defaultValues;
      const { category } = batchValues ?? {};

      await changeState(batch_id, "draft");

      const {
        createPayload,
        deletePayload,
        updatePayload,
        isCreate,
        isDelete,
        isUpdate,
      } = payrollEditFormatter({ values, defaultValues, partner_id, name });
      const { transactions } = updatePayload || {};

      if (isDelete)
        await apiBusinessLong.put(
          `/local_transaction_batches/${batch_id}/bulk_trx_destroy`,
          deletePayload
        );

      if (isUpdate) {
        // we need for loop to avoid status 504 (timeout from BE)
        // const { transactions = [] } = updatePayload || {};
        // const splittedTransactions = arraySplitter(transactions, 50);

        // for (let i = 0; i < splittedTransactions.length; i++) {
        //   const transactions = splittedTransactions[i];
        //   await apiBusinessLong.put(
        //     `/local_transaction_batches/${batch_id}/update_transaction`,
        //     { ...updatePayload, transactions }
        //   );
        // }

        for (let i = 0; i < transactions.length; i++) {
          const transaction = transactions[i];
          const { local_transaction_id: trx_id } = transaction;
          const value = {
            mismatched_action: "replace",
            transaction,
          };

          const url = `/local_transaction_batches/${batch_id}/update_single_transaction/${trx_id}`;
          await apiBusiness.put(url, value);
        }
      }

      if (isCreate) {
        // we need for loop to avoid status 504 (timeout from BE)
        const { local_transactions_attributes = [] } = createPayload || {};

        const isLooped = (local_transactions_attributes || []).length;

        if (isLooped) {
          const splittedTransactions = arraySplitter(
            local_transactions_attributes,
            50
          );

          for (let i = 0; i < splittedTransactions.length; i++) {
            const local_transactions_attributes = splittedTransactions[i];
            await apiBusinessLong.put(
              `/local_transaction_batches/${batch_id}`,
              {
                ...createPayload,
                name,
                local_transactions_attributes,
              }
            );
          }
        } else {
          await apiBusinessLong.put(`/local_transaction_batches/${batch_id}`, {
            ...createPayload,
            name,
          });
        }
      }

      const decider = async () => {
        if (isDraft) {
          await changeState(batch_id, "draft");
          eventsTracker("payroll_save_as_draft", { id });
          return push({ pathname: "/draft", query: { menu: "payroll" } });
          // return push(`/payroll/create/${id}`);
        }

        await changeState(batch_id, "validate_recipient");

        if (isValidate) {
          eventsTracker("payroll_data_validation_continue", { id });
          return push({
            pathname: `/payroll/create/${id}`,
            query: { state: "validating", revalidate },
          });
        }

        eventsTracker("payroll_validate", { id });
        push({ pathname: "/payroll", query: { id } });
      };

      const _ = await decider();
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const deletes = async ({ ids = [], defaultValues, isValidate, isReset }) => {
    try {
      const { batch_id } = defaultValues;
      setLoading(true);

      if (ids.length)
        await apiBusinessLong.put(
          `/local_transaction_batches/${batch_id}/bulk_trx_destroy`,
          { ids }
        );

      const decider = async () => {
        if (isReset) {
          await changeState(batch_id, "draft");
          eventsTracker("payroll_delete_all_transaction", { id });
          return push(`/payroll/create/${id}`);
        }
        if (isValidate) {
          await apiBusiness.put(`/local_transaction_batches/${batch_id}`, {
            ancestor_id: Number(ancestor_id),
          });
          await changeState(batch_id, "waiting_approval");
          eventsTracker("payroll_submit", {
            activity_type: "create_payroll",
            page_title: "confirm_to_submit",
            id,
          });
          return push(`/success/?type=payroll&id=${id}`);
          // return push({ query: { state: "success", id } });
        }
        eventsTracker("payroll_confirmation_save_as_draft", { id });
        push({ pathname: "/payroll", query: { id } });
      };

      const _ = await decider();
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { create, update, deletes, loading };
};

const registers = ({ useFormObj, obj }) => {
  const { setValue, register } = useFormObj;
  useEffect(() => {
    if (isEmpty(obj)) return;

    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      register(key);
      setValue(key, value);
    });
  }, [isEmpty(obj)]);
};

export const usePayrollRegistrations = ({ defaultValues, useFormObj }) => {
  const idsObj = pickBy(defaultValues, (_, key) => {
    if (String(key).includes("id_")) {
      const splittedKey = key.split("_");
      const is2Digit = splittedKey.length == 2;
      const isId = splittedKey[0] == "id";
      if (is2Digit && isId) return true;
    }
  });
  const localRecipientIdsObj = pickBy(defaultValues, (_, key) =>
    key.includes("local_recipient_id")
  );

  registers({ obj: idsObj, useFormObj });
  registers({ obj: localRecipientIdsObj, useFormObj });
};

export const defaultValuesFormatter = (defaultValuesRaw) => {
  const isArray = Array.isArray(defaultValuesRaw);

  if (!isArray) return defaultValuesRaw;

  const defaultValues = defaultValuesRaw.map((arrayOrObj) => {
    const isArray = Array.isArray(arrayOrObj);

    if (!isArray) {
      const {
        email: emailRaw,
        account_number: accNumberRaw,
        ["net_salary"]: netSalaryRaw, // ulah ridho
        notes: notesRaw,
      } = arrayOrObj || {};
      const minimalAmount = +netSalaryRaw >= 10000;
      const isOnlyNumber = (value) => containsOnlyDigits(value);

      const net_salary =
        isOnlyNumber(netSalaryRaw) && minimalAmount ? netSalaryRaw : null;
      const account_number = isOnlyNumber(accNumberRaw) ? accNumberRaw : null;
      const email = getEmails(emailRaw);
      const notes = TrimNotesLocalPayroll(notesRaw);

      return {
        ...arrayOrObj,
        email,
        ["net_salary"]: net_salary, // ulah ridho
        account_number,
        notes,
      };
    }

    const newArray = arrayOrObj.map((item) => {
      const { name, value } = item || {};

      const isEmail = name == "email";

      if (!isEmail) return item;

      if (!value) return item;

      const correctEmails = getEmails(value);

      return { ...item, value: correctEmails };
    });
    return newArray;
  });

  return defaultValues;
};
