import { noCase } from "change-case";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import { colors, jackColors } from "../../../../assets/colors";
import imgBack from "../../../../assets/images/back-previous-page.svg";
import imgDelete from "../../../../assets/images/delete-trash-grey.svg";
import { Avatar } from "../../../../components/Avatar";
import { ConfirmationModalJack } from "../../../../components/ButtonsJack/confirmation";
import { useIdsTable } from "../../../../components/Checkbox";
import { useModalHook } from "../../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
  stringCutter,
  windowDimension,
} from "../../../../components/tools";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { getDefaultValuesArray } from "../../../localTransferComponents/localTransfer/formatter";
import {
  EmailInfoLocalTransfer,
  EmailTextLocalTransfer,
} from "../../../localTransferEnhancement/create/table/inputs/email/parent";
import { FloatingButtonPayroll } from "../../../PayrollCreateEnhancement/create/components";
import { TransactionFiltersSearchbox } from "../../../transactionsComponents/transactions/filters/searchbox/parent";
import { useChangeStatePayroll } from "../validation/defaultPage/logic";
import { PayrollConfirmationTable } from "./table/parent";
import { useHeader } from "../../../../contexts/Layout/parent";
import {
  EmptyScreenSearchImages,
  EmptyStateSearchImages,
} from "../../../crossBorder/components";
import { ProgressBarJack } from "../../../../components/Stepper";
import { stepArrayFunc } from "../validation/parent";
import { stepArrayLocalFunc } from "../../../localTransferEnhancement/create/parent";
import { DeleteStringDecider } from "../../../localTransferEnhancement/create/table/logic";
import { ModalConfirmationCloseLocal } from "../../../localTransferEnhancement/create/modal";
import { apiBusiness } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_CANCEL_CLOSE_PAGE,
  DEMO_CLICK_BACK,
  DEMO_CLOSE_PAGE,
  DEMO_CONFIRM_CLOSE_PAGE,
  DEMO_PAGE_VIEW,
} from "../../../productDemoComponents/constants/events";

const useDefaultArray = (defaultValues) => {
  const { localTransferBanks } = useConstants();
  const array = getDefaultValuesArray(defaultValues);

  const result = array.map((item) => {
    const { bank: bank_id, net_salary: net_salaryRaw } = item;
    const bank = localTransferBanks.filter(({ value }) => value == bank_id)[0]
      ?.label;
    const net_salary = `IDR ${formatCurrency(net_salaryRaw)}`;
    return { ...item, bank, net_salary };
  });

  return result;
};

export const ConfirmationPayroll = ({
  onSubmit: onSubmitProps,
  defaultValues,
  loading,
  handleBack: handleBackProps,
  title = "Confirm to submit",
  subTitle = "Make sure you have provided accurate information by checking it twice.",
  headerArr = [
    { label: "Name", value: "name", width: 229 },
    { label: "Bank", value: "bank" },
    { label: "Notes", value: "notes" },
    // { label: "Department", value: "department" },
    { label: "Email", value: "formatted_email" },
    { label: "Amount", value: "formatted_amount" },
  ],
  hideNav,
  isLocalTransfer,
}) => {
  const { push, query } = useRouter();
  const { successSnackBar, errorSnackBar } = ToasterHook();
  const { id, action, payroll_id, ancestor_id, search_box } = query;
  const { batch } = defaultValues || {};
  const { id: batch_id } = batch || {};
  const { setHeader } = useHeader();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();

  const isResubmit = action === "resubmit";

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => {
        eventsTracker(DEMO_CLOSE_PAGE, {
          activity_type: isLocalTransfer
            ? "create_local_transfer"
            : "create_payroll",
          page_title: "confirm_to_submit",
        });
        toggleClose();
      },
      middleComponent: (
        <ProgressBarJack
          arrayStep={
            isLocalTransfer
              ? stepArrayLocalFunc(isResubmit ? 2 : 4, isResubmit)
              : stepArrayFunc(isResubmit ? 2 : 4, isResubmit)
          }
        />
      ),
    });
  }, []);

  const data = useMemo(() => useDefaultArray(defaultValues), []);

  const [deletedIds, setDeletedIds] = useState([]);

  const onSubmit = ({ type: state }) => {
    onSubmitProps({ type: "confirmation", values: deletedIds, state });
  };

  const onClick = ({ type }) => {
    const isToggleDelete = type === "toggle_delete";
    const isBack = type === "back";
    const isBackModal = type === "back_modal";
    const isDelete = type === "open_modal_delete";
    const isValidate = type === "validate";

    if (isValidate) return onSubmit({ type });
    if (isDelete) return handleSetDeleteIds();
    if (isBack || isBackModal) {
      if (isBackModal)
        eventsTracker(DEMO_CLICK_BACK, {
          activity_type: isLocalTransfer
            ? "create_local_transfer"
            : "create_payroll",
          page_title: "confirm_to_submit",
        });
      return toggle();
    }
    if (isToggleDelete) return toggleDelete();
  };

  const { isOpen, toggle } = useModalHook();

  const toEditDraft = () => push(`/payroll/create/${id}`);

  const { changeState } = useChangeStatePayroll(batch_id, toEditDraft);

  const handleBack = async () => {
    if (handleBackProps) return await handleBackProps();

    if (isResubmit) {
      await apiBusiness.delete(`/payrolls/${id}`);
      return await push({
        pathname: `/payroll/create/${payroll_id}`,
        query: { action, ancestor_id, payroll_id },
      });
    }

    await changeState({ state: "draft" });
  };

  // const styleCustom = {
  //   maxWidth: 150,
  //   overflow: "hidden",
  //   textOverflow: "ellipsis",
  //   whiteSpace: "nowrap",
  // };

  const itemArrRaw = data.map((item) => {
    const {
      name: nameProps,
      amount: amountRaw,
      role,
      department,
      bank: bankProps,
      beneficiary_bank_name,
      account_number,
      recipient,
      formatted_amount: formattedAmountRaw,
    } = item;

    const amount = isLocalTransfer
      ? formattedAmountRaw
      : `IDR ${formatCurrencyNoDecimal(amountRaw)}`;

    const styleCustom = {
      maxWidth: 160,
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    };

    const { email, additional_emails: additionalEmailRaw } = recipient || {};
    const additional_emails = additionalEmailRaw?.split(",") || [];
    const roleAndDepartment = `${role || ""} ${role && department ? "-" : ""} ${
      department || ""
    }`;
    const name = (
      <>
        <div className="w-100 d-flex">
          <Avatar
            size="medium"
            name={nameProps}
            style={{ minWidth: 32, minHeight: 32 }}
          />
          <div
            className="d-flex flex-column justify-content-center"
            style={{ marginLeft: 12 }}
          >
            <GothamMedium style={{ ...styleCustom }}>{nameProps}</GothamMedium>
            {!isLocalTransfer && (
              <GothamRegular
                style={{
                  color: colors.grey6c,
                  fontSize: 12,
                  lineHeight: "14px",
                }}
              >
                {roleAndDepartment}
              </GothamRegular>
            )}
          </div>
        </div>
      </>
    );

    const bank = (
      <div>
        <GothamRegular>{bankProps}</GothamRegular>
        <GothamRegular
          className="font12"
          style={{
            color: jackColors.neutral700,
            ...styleCustom,
            maxWidth: 230,
          }}
        >
          {account_number}
        </GothamRegular>
      </div>
    );

    const formatted_email = (
      <GothamRegular>
        {!email ? (
          "-"
        ) : (
          <EmailInfoLocalTransfer
            additional_emails={additional_emails}
            email={email}
          >
            <EmailTextLocalTransfer
              additional_emails={additional_emails}
              email={stringCutter(email)}
            />
          </EmailInfoLocalTransfer>
        )}
      </GothamRegular>
    );

    const formatted_amount = (
      <GothamRegular style={{ textAlign: "end" }}>{amount}</GothamRegular>
    );

    const bankSearch = bankProps + account_number + beneficiary_bank_name;
    const emailSearch = email + additionalEmailRaw;

    return {
      ...item,
      name,
      bank,
      bankSearch,
      emailSearch,
      formatted_email,
      formatted_amount,
      realName: nameProps,
    };
  });

  const itemArr = itemArrRaw.filter(({ id }) => !deletedIds.includes(id));

  const filteredItemArr = itemArr.filter((item) => {
    if (!search_box) return true;
    const { realName, notes, amount, bankSearch, emailSearch } = item || {};
    const boolean = (string) => noCase(string).includes(noCase(search_box));
    if (boolean(realName)) return true;
    if (boolean(notes)) return true;
    if (boolean(amount)) return true;
    if (boolean(bankSearch)) return true;
    if (boolean(emailSearch)) return true;
    return false;
  });

  const isEmptyData = !filteredItemArr.length;

  const selectionTable = useIdsTable(itemArr);

  const { selectedIds, handleRemoveAllId, isAllChecked } = selectionTable;
  const isThereChecked = !!selectedIds?.length;

  const { screenRealHeight } = windowDimension();

  const [tablePage, setTablePage] = useState(1);

  const total = (name) =>
    itemArr
      .map((item) => Number(item[name]))
      .reduce((prev, curr) => Number(prev) + Number(curr), 0);

  const totalAmount = total("amount");
  const totalFee = total("default_fee");

  const {
    isOpen: isShowDelete,
    toggle: toggleDelete,
    close: closeDelete,
  } = useModalHook();

  const onDelete = (ids) => {
    setDeletedIds((prev) => [...prev, ...ids]);
    if (isAllChecked)
      return errorSnackBar({
        msg: "You can't delete all of your transaction(s)",
        showClose: true,
      });
    successSnackBar({
      msg: DeleteStringDecider(selectedIds?.length),
      showClose: true,
    });
    handleRemoveAllId();
    closeDelete();
  };

  useEffect(() => {
    if (isShowDelete) return;
    handleRemoveAllId();
  }, [isShowDelete]);

  useEffect(() => {
    if (itemArr.length) return;

    push(isLocalTransfer ? "/local-transfer" : "/payroll");
    // onSubmitProps({
    //   type: "confirmation",
    //   values: deletedIds,
    //   state: "draft",
    //   isReset: true,
    // });
  }, [itemArr.length]);

  const [tempDeletedIds, setTempDeletedIds] = useState([]);

  const handleSetDeleteIds = () => {
    if (isAllChecked)
      return errorSnackBar({
        msg: "You can't delete all of your transaction(s)",
        showClose: true,
      });
    setTempDeletedIds(selectedIds);
  };

  return (
    <div
      style={{
        position: "relative",
        margin: "0px 32px",
      }}
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        {title}
      </GothamMedium>
      <div
        style={{ position: "relative", marginBottom: 24 }}
        className="d-flex justify-content-between align-items-center"
      >
        <TransactionFiltersSearchbox
          queryName="search_box"
          placeholder="Search keywords"
        />
      </div>
      {isEmptyData && <EmptyStateSearchImages />}
      <PayrollConfirmationTable
        page={tablePage}
        headerArr={headerArr}
        itemArr={filteredItemArr}
        isEmptyData={isEmptyData}
        selectionTable={selectionTable}
        onClick={(values) => {
          const { type, item } = values || {};
          const isDelete = type == "delete";
          if (isDelete) return setTempDeletedIds([item?.id]);
        }}
        isDelete={isShowDelete}
        isLocalTransfer={isLocalTransfer}
      />
      <div style={{ height: 150 }} />
      <ConfirmationModalJack
        onClick={handleBack}
        modal={isOpen}
        toggle={toggle}
        text="Going back will erase your progress and you have to validate the bank details again. "
        title="Go back to the previous page?"
        buttonTextRight="Yes"
        img={imgBack}
      />
      <ConfirmationModalJack
        modal={Boolean(tempDeletedIds.length)}
        toggle={() => setTempDeletedIds([])}
        onClick={() => onDelete(tempDeletedIds)}
        title="Delete selected transactions?"
        img={imgDelete}
        buttonTextRight="Delete"
        buttonTextLeft="Back"
        text="You will delete selected transaction from the list."
      />
      <FloatingButtonPayroll
        loading={loading}
        isThereChecked={isThereChecked}
        selectedIds={selectedIds}
        funcPropsTable={selectionTable}
        array={[]}
        data={{ totalTrx: itemArr.length, totalAmount, totalFee }}
        isConfirmationPage
        onClick={onClick}
        handleSubmit={(type) => onSubmit({ type })}
        isFirstScreenTable={tablePage == 1}
        onClickOtherFields={() =>
          setTablePage((prev) => {
            if (prev == 2) return 1;
            return 2;
          })
        }
        hideNav={hideNav}
      />
      <ModalConfirmationCloseLocal
        isOpen={isOpenClose}
        product={isLocalTransfer ? "local" : "payroll"}
        isAfterDraft
        batchId={id}
        onClick={() => {
          eventsTracker(DEMO_CONFIRM_CLOSE_PAGE, {
            activity_type: isLocalTransfer
              ? "create_local_transfer"
              : "create_payroll",
            page_title: "confirm_to_submit",
          });
        }}
        toggle={toggleClose}
        onClickLeft={() => {
          if (isOpenClose)
            eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
              activity_type: isLocalTransfer
                ? "create_local_transfer"
                : "create_payroll",
              page_title: "confirm_to_submit",
            });
          toggleClose();
        }}
        onClickClose={() => {
          eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
            activity_type: isLocalTransfer
              ? "create_local_transfer"
              : "create_payroll",
            page_title: "confirm_to_submit",
          });
        }}
      />
    </div>
  );
};
