import { JackIcons } from "../../assets/jackIcons/parent";
import { Avatar } from "../Avatar";
import { booleanState } from "../Status";
import CrossborderAvatar from "../../assets/images/crossborder-single-avatar.svg";

export const StatusAccountIconJack = ({ state, source }) => {
  const {
    isCardTransaction,
    isIncomingPayment,
    isTopUp,
    isApiTransaction,
    isConversion,
    isAllocate,
    isConvert,
    isRefund,
    isWithdraw,
    isReAllocate,
    isPayroll,
    isReimbursement,
    isInvoice,
    isCrossBorder,
    isLocalDisbursements,
    isEWallet,
    isCard,
    isMoneyMovement,
    isCardBillPayment,
    isCollection,
    isCardMoveBalance: isCardMoveBalanceRaw,
    isCardCreditTopUp: isCardCreditTopUpRaw,
    isJackTransfer,
  } = booleanState(state);

  const isCardMoveBalance = isCardMoveBalanceRaw && source == "main_balance";
  const isCardCreditTopUp = isCardCreditTopUpRaw && source == "main_balance";

  const nameDecider = () => {
    if (isReimbursement) return "Reimbursement";
    if (isInvoice) return "Invoice Payment";
    if (isPayroll) return "Payroll";
    if (isCrossBorder) return CrossborderAvatar;
    if (isLocalDisbursements) return "Send Money";
    if (isEWallet) return "wallet";
    if (isCard) return "Corporate card";
    if (isMoneyMovement) return "Money Movement";
    if (isTopUp || isIncomingPayment) return "top up";
    if (isWithdraw) return "withdraw";
    if (isCardMoveBalance || isCardCreditTopUp) return "Money Movement";
    if (isCardMoveBalanceRaw || isCardCreditTopUpRaw) return "Corporate card";
    if (isCardBillPayment) return "card payment";
    if (isRefund) return "refund";
    if (isCollection) return "coin";

    if (isCardTransaction) return "Corporate card";
    if (isApiTransaction) return "API";
    if (isAllocate || isReAllocate) return "Money Movement";
    if (isConversion || isConvert) return "Money Movement";
    if (isJackTransfer) return "swap-outline";
  };

  const name = nameDecider();
  const isExternal = isCrossBorder;

  if (isExternal) {
    return (
      <div style={{ width: 32 }}>
        <img src={name} />
      </div>
    );
  }

  return (
    <div style={{ width: 32 }}>
      <Avatar
        size="medium"
        mainIcon={
          <JackIcons
            name={name}
            fill="white"
            style={{ width: 20, height: 20 }}
          />
        }
      />
    </div>
  );
};
