import { parseISO } from "date-fns";
import { isEmpty } from "lodash";
import moment from "moment";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";

export const dateFormatStripDMY = (dateRaw) => {
  const date = dateRaw || "";
  if (!date) return date;
  const isoDate = new Date(date).toISOString();
  const splittedDate = isoDate.split("-");
  const year = splittedDate[0] || "";
  const month = splittedDate[1] || "";
  const dirtyDay = splittedDate[2] || "";
  const day = dirtyDay.split("T")[0] || "";

  return `${day}/${month}/${year}`;
};
const normal = (date) => moment(date).format("D MMMM YYYY");
const shortMonth = (date) => moment(date).format("D MMM YYYY");
const shortStrip = (date) => moment(date).format("DD/MM/YYYY");
export const invoiceFormatter = (array = []) => {
  if (!array || !Array.isArray(array)) return [];

  return array?.map((item) => {
    const {
      id,
      state: status,
      invoice_file,
      invoice_number: invoiceNumber,
      recipient_name: billTo,
      due_date,
      issued_date,
      total_amount,
      description: descriptionHTML,
      thumbnail_invoice,
      created_at,
      drafted_at,
      activity_state,
    } = item || {};

    const createdDate = normal(created_at);
    const dueDate = due_date ? normal(due_date) : "-";
    const draftedAt = drafted_at ? shortMonth(drafted_at) : "-";
    const issuedDate = issued_date ? normal(issued_date) : "-";
    const createdDateShort = created_at ? shortStrip(created_at) : "-";
    const dueDateShort = due_date ? shortStrip(due_date) : "-";
    const draftedAtShort = drafted_at ? shortStrip(drafted_at) : "-";
    const getUrl = (url, woVersion = true) => {
      if (!url) return "";
      if (woVersion)
        return `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL}${url}`;
      return `${process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL}${url}`;
    };
    const pdf = getUrl(thumbnail_invoice || invoice_file);
    const file_url = pdf;
    const totalAmount = `IDR ${formatCurrencyNoDecimal(total_amount)}`;
    const description = descriptionHTML
      ? `${descriptionHTML}`
          .replace(/<[^>]*>?/gm, "")
          .replace(/(\r\n|\n|\r)/gm, "")
      : description;

    const pdfDownloadLink = getUrl(
      `/invoice_transactions/${id}/show_transaction.pdf`,
      false
    );
    return {
      ...item,
      type: "invoice_payment",
      status: status == activity_state ? status : activity_state,
      pdf,
      file_url,
      invoiceNumber,
      billTo,
      dueDate,
      issuedDate,
      totalAmount,
      description,
      createdDate,
      draftedAt,
      createdDateShort,
      dueDateShort,
      draftedAtShort,
      descriptionHTML,
      pdfDownloadLink,
    };
  });
};

export const dateToIsoDateFormatter = (date) => {
  if (!date) return;
  const isoDate = new Date(date);
  return isoDate ? parseISO(isoDate?.toISOString()) : null;
};

export const attachmentDataFormatter = (array) => {
  return array?.map((file) => {
    const { blob } = file;
    const { content_type: type, filename: name, byte_size: size } = blob;
    return { ...file, type, name, size };
  });
};

export const pluralize = (count, noun) => {
  if (count == 0) return noun;
  return `${noun}${count !== 1 ? "s" : ""}`;
};

export const detailFormatterInvoiceReimbursement = (transactionDetail) => {
  if (!transactionDetail) return [];
  const {
    invoice_number,
    created_at,
    amount,
    total_amount,
    state,
    rejected_by_id,
    merchant,
  } = transactionDetail;

  const isInvoice = Boolean(invoice_number);
  const statusFinal = () => {
    if (state === "cancelled") {
      if (Boolean(rejected_by_id)) return "rejected";
      return "cancelled";
    }
    if (state === "completed") return "success";
    return "in_progress";
  };
  const detail = [
    {
      category: isInvoice ? "invoice" : "reimbursement",
      leftBottom: customDateFormatter(created_at),
      leftStatus: statusFinal(),
      leftTop: isInvoice
        ? `Invoice Payment ${invoice_number}`
        : `Reimbursement ${merchant}`,
      rightTop: `IDR ${formatCurrencyNoDecimal(
        isInvoice ? total_amount : amount
      )}`,
    },
  ];

  return detail;
};

export const getTotalAmountSummary = (data, keyProperty = "total_amount") => {
  if (isEmpty(data)) return { totalAmount: 0, totalAmountString: "IDR 0" };
  const [obj] = data;
  if (!data || !obj) return { totalAmount: 0, totalAmountString: "IDR 0" };

  const totalAmountArray = data?.map((item) => {
    const totalAmountItem = item[keyProperty];
    if (totalAmountItem) return totalAmountItem;
    return 0;
  });
  const totalAmount = (totalAmountArray || [])?.reduce(
    (a, b) => Number(a) + Number(b),
    0
  );
  const totalAmountString = `IDR ${formatCurrencyNoDecimal(totalAmount)}`;

  return { totalAmount, totalAmountString };
};

export const createQueryIds = (
  ids = [],
  singleMode = false,
  pathname = "/invoice_transactions"
) => {
  if (ids?.length === 0) return `${pathname}/filtered/createx`;

  const arrayIds = Array.isArray(ids) ? ids : [ids];
  if (arrayIds?.length == 1 && singleMode) return `${pathname}/${ids}`;
  let queryIds = `${pathname}?`;
  [...new Set(arrayIds)].forEach((id) => (queryIds += `q[id_eq_any][]=${id}&`));
  return queryIds;
};

export const stringysort = (array = []) =>
  JSON.stringify(array?.sort((a, b) => a - b));

export const invoicePageTypeDecider = (rejection, isLast) => {
  const isCancel = rejection == "cancelation";
  const isReject = rejection == "rejection";

  if (isCancel) return "cancel";
  if (isReject) return "reject";
  return isLast ? "pay" : "approve";
};

export const idrStringFormatter = (num) =>
  `IDR ${formatCurrencyNoDecimal(Number(num))}`;

export const billPaymentDetailDecider = (data, isReleaser, woSpacer) => {
  const { total_amount, tax, amount, fee } = data ?? {};

  const subTotalObj = {
    title: "Subtotal",
    value: idrStringFormatter(amount),
  };

  const vatObj = {
    title: "VAT",
    value: idrStringFormatter(tax),
    customIcon: "plus_circle",
  };

  const invoiceAmountObj = {
    title: "Invoice amount",
    value: idrStringFormatter(Number(total_amount)),
  };

  const feeObj = {
    title: "Handling fee",
    value: idrStringFormatter(Number(fee)),
    customIcon: "plus_circle",
  };

  const totalPaymentObj = {
    title: "Total payment",
    value: idrStringFormatter(Number(total_amount) + Number(fee)),
  };
  const spacer = { spacer: true };

  if (isReleaser) {
    return [
      !woSpacer && spacer,
      subTotalObj,
      vatObj,
      spacer,
      invoiceAmountObj,
      feeObj,
      spacer,
      totalPaymentObj,
    ];
  }
  return [!woSpacer && spacer, subTotalObj, vatObj, spacer, invoiceAmountObj];
};
