import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { useEffect, useMemo, useState } from "react";
import LoadingSpinner from "../../../components/Loading";
import { removeDuplicates } from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { fetch } from "../../../tools/api";
import { CreatePayroll } from "../../PayrollCreateEnhancement/create/parent";
import { ConfirmationPayroll } from "./confirmation/parent";
import {
  defaultValuesCleaner,
  payrollDefaultValuesFormatter,
} from "./create/formatter";
// import { CreatePayroll } from "./create/parent";
import { usePayrollLogics } from "./logics";
import { SuccessPayroll } from "./success/parent";
import { ValidatingPayroll } from "./validating";
import { payrollValidationFormatter } from "./validation/formatter";
import { ValidationPayroll } from "./validation/parent";
import { eventsTracker } from "../../../universalFunctions/events";
import { DEMO_PAGE_VIEW } from "../../productDemoComponents/constants/events";

const isDraftDecider = (defaultValues, values) => {
  const { local_transactions = [] } = defaultValues?.batch || {};
  const keys = removeDuplicates(
    Object.keys(defaultValues)
      .map((key) => {
        const splittedKey = key.split("_");
        const number = splittedKey.pop();
        if (isNaN(number)) return false;
        return number;
      })
      .filter((key) => key)
  );

  const isAllInvalid = keys.length == local_transactions.length;
  const isDeleteAll = !values.length;
  const isDraft = isAllInvalid && isDeleteAll;

  return isDraft;
};

export const localTransferStates = (state) => {
  const isValidating = state == "validating";
  const isValidation = state == "validation";
  const isConfirmation = state == "confirmation";
  const isSuccess = state == "success";
  const isDetailsScreen = !state;
  return {
    isValidating,
    isValidation,
    isConfirmation,
    isSuccess,
    isDetailsScreen,
  };
};

export const PayrollFormLoop = ({
  firstValues,
  defaultValues: defaultValuesProps = {},
  isEmptyTable,
}) => {
  const [isFirstRender, setIsFirstRender] = useState(true);
  const { query, push, pathname } = useRouter();
  const { state, id } = query;
  const { loadingTransferBanks } = useConstants();

  const {
    isConfirmation,
    isSuccess,
    isValidating,
    isValidation,
    isDetailsScreen,
  } = localTransferStates(state);

  const pageTitleDecider = () => {
    if (isValidating || isValidation) return "";
    if (isSuccess) return "success_submission";
    if (isConfirmation) return "confirm_to_submit";
    return "transfer_details";
  };
  const page_title = pageTitleDecider();

  useEffect(() => {
    if (isValidating || isValidation) return;
    eventsTracker(DEMO_PAGE_VIEW, {
      activity_type: "create_payroll",
      page_title,
    });
  }, [state]);

  const isEdit = pathname == "/payroll/create/[id]";
  const isResubmit = query.action === "resubmit";

  const {
    refetch: refetchDefaultValues,
    data: defaultValues,
    setData: setDefaultValues,
  } = fetch({
    url: `/payrolls/${id}`,
    woInit: true,
    defaultValue: defaultValuesProps,
    formatter: (res) => {
      return payrollDefaultValuesFormatter(
        res,
        isValidation,
        isResubmit,
        isDetailsScreen
      );
    },
  });

  const cleanDefaultValues = defaultValuesCleaner(defaultValues);
  const { state: trxState } = cleanDefaultValues?.batch || {};

  const memorizedTrxState = useMemo(() => trxState, [trxState]);

  const isValidate = memorizedTrxState == "validate_recipient";
  const isDraft = memorizedTrxState == "draft";

  // redirector
  const nav = (state) => push({ query: { ...query, state } });

  useEffect(() => {
    if (!memorizedTrxState) return;
    if (!isEdit) return;
    // redirector

    if ((state == "create" || !state) && isValidate) return nav("validating");

    if (isDraft) return nav("create");
    // redirector

    // kicker
    if (isSuccess) return;
    if (isValidate) return;
    if (isDraft) return;
    if (isResubmit) return;
    push("/home");

    // kicker
  }, [memorizedTrxState]);

  // redirector for excel
  useEffect(() => {
    if (isFirstRender) return;
    if (id) return;
    if (state != "create") return;
    if (!isEmpty(defaultValues)) return;
    nav("");
  }, [isFirstRender]);
  // redirector for excel
  // redirector

  // refetcher
  useEffect(() => {
    if (!id) return;
    // if (state == "success") return;
    setDefaultValues({});
    refetchDefaultValues();
  }, [id, state]);
  // refetcher

  useEffect(() => {
    if (!isFirstRender) return;
    setTimeout(() => setIsFirstRender(false), 200);
  }, [isFirstRender]);

  const { create, update, deletes, loading } = usePayrollLogics();

  const onSubmit = ({ type, state, values, isReset, batchValues }) => {
    const { payroll_name } = batchValues || {};
    const isDraft = state == "draft";
    const isCreate = type == "create";
    const isValidate = state == "validate";
    const isValidation = type == "validation";
    const isConfirmation = type == "confirmation";

    if (isConfirmation)
      return deletes({ ids: values, defaultValues, isValidate, isReset });

    if (isValidation) {
      const isDraft = isDraftDecider(defaultValues, values);
      return update({
        values,
        defaultValues,
        firstValues: payroll_name,
        isDraft,
        isValidate: true,
        revalidate: true,
      });
    }

    if (isEdit && !isResubmit) {
      return update({
        values,
        defaultValues,
        firstValues: payroll_name,
        isValidate,
        isDraft,
      });
    }

    create({
      values,
      firstValues: batchValues,
      isValidate,
      // ancestor_id: trxId,
    });
  };

  const hideComponent =
    isFirstRender || loadingTransferBanks || isEmpty(defaultValues);

  if (hideComponent) return <LoadingSpinner />;

  if (isSuccess)
    return <SuccessPayroll onSubmit={onSubmit} loading={loading} />;

  if (isConfirmation)
    return (
      <ConfirmationPayroll
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        loading={loading}
      />
    );

  if (isValidation)
    return (
      <ValidationPayroll
        onSubmit={onSubmit}
        defaultValues={defaultValues}
        loading={loading}
      />
    );

  if (isValidating) return <ValidatingPayroll />;

  return (
    <CreatePayroll
      defaultValues={defaultValues}
      isEmptyTable={isEmptyTable}
      firstValues={firstValues}
      onSubmit={onSubmit}
      loading={loading}
    />
  );
};
