import { useRouter } from "next/router";
import { Fragment, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { useForgotPin } from "../../pageComponents/resetPin/logics";
import { ButtonJack } from "../ButtonsJack/parent";
import { RightModal, useSmoothRightModal } from "../Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../Text";
import { PinInput } from "./pin";
import styles from "./styles.module.css";
import { Banner } from "../Banner";

export const PinContent = ({
  isLoading,
  onSubmit: onSubmitProps,
  onBack,
  ctaText = "Input your Jack PIN",
  isResetOnSubmit = false,
  isSuccess = false,
  isForPage = false,
  isError = false,
  setIsError,
  isErrorText,
}) => {
  const [pin, setPin] = useState("");
  const currentPinLength = pin.length;

  const isButtonDisabled = currentPinLength < 6;
  const onSubmit = () => {
    onSubmitProps({ pin }, "pin");
    if (isResetOnSubmit) setPin("");
  };

  const { reset } = useForgotPin(true);

  const handleBack = () => {
    setPin("");
    !!onBack && onBack();
  };

  const success = (
    <Fragment>
      <JackIcons
        name="checkmark-circle-2"
        fill={jackColors.neutral900}
        style={{ width: "72px", height: "72px" }}
      />
      <div className={styles["success-text-container"]}>
        <GothamMedium
          className="font24"
          style={{ color: jackColors.neutral900 }}
        >
          Success!
        </GothamMedium>
        <GothamRegular woFontColor style={{ color: jackColors.neutral700 }}>
          Your PIN has been changed!
        </GothamRegular>
      </div>
    </Fragment>
  );

  const input = (
    <PinInput
      onChange={(pin) => {
        setPin(pin);
        setIsError && setIsError(false);
      }}
      value={pin}
      onSubmit={onSubmit}
      isError={isError}
      setValue={setPin}
      isErrorText={isErrorText}
    />
  );

  if (isForPage) {
    return (
      <div className={styles["container"]}>
        <div className={styles["content-container"]}>
          {isSuccess ? (
            success
          ) : (
            <Fragment>
              <GothamMedium className={styles["title"]}>{ctaText}</GothamMedium>
              {input}
            </Fragment>
          )}
        </div>
        <div className={styles["bottom-control-container_on-page"]}>
          {isSuccess ? (
            <Fragment>
              <ButtonJack
                onClick={onSubmit}
                className={styles["button_on-page"]}
              >
                Close
              </ButtonJack>
            </Fragment>
          ) : (
            <Fragment>
              <ButtonJack
                isLoading={isLoading}
                disabled={isButtonDisabled}
                onClick={onSubmit}
                className={styles["button_on-page"]}
              >
                Confirm
              </ButtonJack>
              <ButtonJack
                onClick={handleBack}
                className={styles["button_on-page"]}
                type="outline"
                disabled={isLoading}
              >
                Cancel
              </ButtonJack>
            </Fragment>
          )}
        </div>
      </div>
    );
  }

  return (
    <div className={styles["container"]}>
      <JackIcons name="jack-horizontal" />
      <div className={styles["content-container"]}>
        {isSuccess ? (
          success
        ) : (
          <Fragment>
            <GothamMedium
              style={{ fontSize: "24px", lineHeight: "32px" }}
              className={styles["title"]}
            >
              {ctaText}
            </GothamMedium>
            <Banner
              style={{
                padding: "4px 12px",
                width: "240px",
                borderRadius: "8px",
                border: "1px solid var(--Neutral-500, #E6E6E8)",
                background: "var(--Neutral-400, #F1F1F1)",
              }}
              msg={"Your Jack Demo PIN: 123456"}
            />
            {input}
            {/* <GothamMedium
              className={styles["underline-medium"]}
              onClick={reset}
            >
              Forgot your PIN?
            </GothamMedium> */}
          </Fragment>
        )}
      </div>
      <div className={styles["bottom-control-container"]}>
        {isSuccess ? (
          <ButtonJack onClick={onSubmit} className={styles["button"]}>
            Close
          </ButtonJack>
        ) : (
          <Fragment>
            <ButtonJack
              isLoading={isLoading}
              disabled={isButtonDisabled}
              onClick={onSubmit}
              className={styles["button"]}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  onSubmit();
                }
              }}
            >
              Confirm
            </ButtonJack>
            {onBack && (
              <ButtonJack
                onClick={handleBack}
                className={styles["button"]}
                type="outline"
                disabled={isLoading}
              >
                Back
              </ButtonJack>
            )}
          </Fragment>
        )}
      </div>
    </div>
  );
};

const PINModal = ({
  isOpen,
  toggle,
  isLoading,
  onSubmit,
  onBack,
  ctaText = "Enter your Jack PIN",
  isResetOnSubmit = false,
  isSuccess = false,
  isError,
  setIsError,
  woClose = false,
  isErrorText = "",
}) => {
  const { toToggle, toListener } = useSmoothRightModal();
  return (
    <RightModal
      listener={toListener}
      isOpen={isOpen}
      toggle={() => {
        setIsError && setIsError(false);
        toggle();
      }}
      woClose={woClose}
      array={[
        <PinContent
          isLoading={isLoading}
          onSubmit={(values) => onSubmit(values, toToggle)}
          onBack={onBack}
          ctaText={ctaText}
          isResetOnSubmit={isResetOnSubmit}
          isSuccess={isSuccess}
          isError={isError}
          setIsError={setIsError}
          isErrorText={isErrorText}
        />,
      ]}
      width={[420]}
    />
  );
};

export default PINModal;
