import { useRouter } from "next/dist/client/router";
import { useEffect, useState } from "react";
import { useDebounce } from "../../../../../components/tools";
import { NakedTransactionFiltersSearchbox } from "./component";

export const TransactionFiltersSearchbox = ({
  placeholder,
  queryName = "searchbox",
  runQueryName = "runQuery",
  style,
  loading,
}) => {
  const { query, push } = useRouter();

  const [text, setText] = useState("");

  useEffect(() => {
    const value = query[queryName];
    if (!value) return;
    setText(value);
  }, []);

  const debouncedText = useDebounce(text, 800);

  const onChange = (text) => {
    setText(text);
  };

  const [isFirstRender, setIsFirstRender] = useState(true);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
    push({ query: { ...query, [runQueryName]: false, [queryName]: text } });
    push({ query: { ...query, [runQueryName]: true, [queryName]: text } });
  }, [debouncedText]);

  return (
    <NakedTransactionFiltersSearchbox
      onChange={onChange}
      placeholder={placeholder}
      value={text}
      style={style}
      loading={loading}
    />
  );
};
