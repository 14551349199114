import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  REIMBURSEMENT_FOURTH_TARGET,
} from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${REIMBURSEMENT_FOURTH_TARGET}`,
    placement: "bottom",
    content: (
      <TooltipContent
        title="Complete missing details"
        description="Make sure every details is filled out before submitting."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_reimbursement",
      guided_tour_activity: "complete_missing_details"
    },
  },
];

const reimbursementThirdPageProps = { steps };

export default reimbursementThirdPageProps;
