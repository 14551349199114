import { useEffect, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../../components/Text";
import BlockingBlurModal from "../../../../modals/BlockingBlurModal";
import styles from "./styles.module.css";
import NextImage from "../../../../components/NextImage";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import {
  CardBenefit,
  CustomizableBenefit,
  DiagonalArrow,
  Ellipse,
  LimitlessBenefit,
  MasterCard,
} from "./components";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_CARDS_TO_WA,
  DEMO_CLICK_DOWN_ARROW,
  DEMO_CLICK_DOWN_BUTTON,
  DEMO_CLICK_UP_ARROW,
  DEMO_PAGE_VIEW,
} from "../../constants/events";

const CardsDemoModal = ({ isOpen = false, close = () => {} }) => {
  if (!isOpen) return null;
  const BASE_ANIMATIONS = {
    scroll: "",
    otherCard: "",
    jackCorporate: "",
    otherCard: "",
    cardSample: "",
    prepaid: "",
    credit: "",
    benefit: "",
    button: "",
  };

  const [isClosing, setIsClosing] = useState(false);
  const [isLoadingScroll, setIsLoadingScroll] = useState(false);
  const [isEnabledScrolling, setIsEnabledScrolling] = useState(false);
  const [layerIndex, setLayerIndex] = useState(0);
  const [animationClassNames, setAnimationClassNames] =
    useState(BASE_ANIMATIONS);

  const {
    scroll: scrollAnimationClassName,
    jackCorporate: jackCorporateCardAnimationClassName,
    otherCard: otherCardAnimationClassName,
    cardSample: cardSampleAnimationClassName,
    prepaid: prepaidAnimationClassName,
    credit: creditAnimationClassName,
    benefit: benefitAnimationClassName,
    button: buttonAnimationClassName,
  } = animationClassNames;

  const resetStates = () => {
    setIsClosing(false);
    setIsLoadingScroll(false);
    setIsEnabledScrolling(false);
    setLayerIndex(0);
    setAnimationClassNames(BASE_ANIMATIONS);
  };

  const cardsDemoClassNameDecider = () => {
    if (isClosing) {
      return `${styles.cardsDemoModal} ${styles.cardsDemoModalCloseAnimation}`;
    }
    return `${styles.cardsDemoModal} ${styles.cardsDemoModalOpenAnimation}`;
  };

  const handleClose = () => {
    setIsClosing(true);
    return;
  };

  const handleClickScrollButton = (direction = "") => {
    if (isLoadingScroll) return;
    if (!isEnabledScrolling) return;
    setIsLoadingScroll(true);
    switch (direction) {
      case "up":
        if (layerIndex <= 0) break;
        setLayerIndex((prev) => prev - 1);
        if (layerIndex === 2) {
          eventsTracker(DEMO_CLICK_UP_ARROW, { page_title: "cards_detail" });
          setAnimationClassNames((prev) => ({
            ...prev,
            scroll: styles.scrollUpToSecondContent,
            jackCorporate: styles.jackCorporateCardEnterFromBottom,
            otherCard: styles.otherCardEnterFromBottom,
            cardSample: styles.cardSampleEnterFromBottom,
            prepaid: styles.prepaidExitToTop,
            credit: styles.creditExitToTop,
            benefit: styles.benefitExitToTop,
            button: styles.buttonExitToTop,
          }));
          break;
        }
        if (layerIndex === 1) {
          eventsTracker(DEMO_CLICK_UP_ARROW, { page_title: "cards_compare" });
          setAnimationClassNames((prev) => ({
            ...prev,
            scroll: styles.scrollUpToFirstContent,
            jackCorporate: styles.jackCorporateCardExitToTop,
            otherCard: styles.otherCardExitToTop,
            cardSample: styles.cardSampleExitToTop,
          }));
          break;
        }
        break;
      case "down":
        if (layerIndex >= 2) break;
        setLayerIndex((prev) => prev + 1);
        if (layerIndex === 0) {
          eventsTracker(DEMO_CLICK_DOWN_ARROW, { page_title: "cards_intro" });
          setAnimationClassNames((prev) => ({
            ...prev,
            scroll: styles.scrollDownToSecondContent,
            jackCorporate: styles.jackCorporateCardEnterFromTop,
            otherCard: styles.otherCardEnterFromTop,
            cardSample: styles.cardSampleEnterFromTop,
          }));
          break;
        }
        if (layerIndex === 1) {
          eventsTracker(DEMO_CLICK_DOWN_ARROW, { page_title: "cards_compare" });
          setAnimationClassNames((prev) => ({
            ...prev,
            scroll: styles.scrollDownToThirdContent,
            jackCorporate: styles.jackCorporateCardExitToBottom,
            otherCard: styles.otherCardExitToBottom,
            cardSample: styles.cardSampleExitToBottom,
            prepaid: styles.prepaidEnterFromTop,
            credit: styles.creditEnterFromTop,
            benefit: styles.benefitEnterFromTop,
            button: styles.buttonEnterFromTop,
          }));
          break;
        }
        break;
      default:
        break;
    }
  };

  const handleClickKnowMoreButton = () => {
    eventsTracker(DEMO_CLICK_DOWN_BUTTON, { page_title: "cards_intro" });
    if (layerIndex !== 0) return;
    setAnimationClassNames((prev) => ({
      ...prev,
      scroll: styles.scrollDownToSecondContent,
      jackCorporate: styles.jackCorporateCardEnterFromTop,
      otherCard: styles.otherCardEnterFromTop,
      cardSample: styles.cardSampleEnterFromTop,
    }));
    // handleClickScrollButton("down");
  };

  const handleScroll = (event) => {
    const deltaY = event.deltaY;
    const isValidScroll = Math.abs(deltaY) > 20;
    if (isValidScroll) {
      const isScrollUp = deltaY < 0;
      const isScrollDown = deltaY > 0;
      if (isScrollUp) {
        handleClickScrollButton("up");
        return;
      }
      if (isScrollDown) {
        handleClickScrollButton("down");
        return;
      }
    }
  };

  useEffect(() => {
    if (!isClosing) return;
    const timeout = setTimeout(() => {
      resetStates();
      close();
    }, 400); // the delay should match animation duration
    return () => clearTimeout(timeout);
  }, [isClosing]);

  useEffect(() => {
    if (!isLoadingScroll) return;
    const timeout = setTimeout(() => {
      setIsLoadingScroll(false);
    }, 1000); // the delay should match animation duration
    return () => clearTimeout(timeout);
  }, [isLoadingScroll]);

  useEffect(() => {
    if (isEnabledScrolling || !isOpen) return;
    const timeout = setTimeout(() => {
      setIsEnabledScrolling(true);
    }, 3300); // this is the duration for the initial animation to end
    return () => clearTimeout(timeout);
  }, [isEnabledScrolling, isOpen]);

  const jackCorporateCardPoints = [
    "Up-to-the-minute spending tracking",
    "Issuing cards in <7 days",
    "Able to instantly issue unlimited amount of virtual cards",
    "Keep budgets in control with flexible & adjustable limits",
    "No monthly or annual fee",
    "Available in Prepaid & Credit Card",
  ];

  const otherCardPoints = [
    "Limited time to track spending",
    "Excessive wait for card issuance, 3-4 months",
    "Obligatory collateral requirement for corporate cards",
    "Limited card allocation per company",
    "Monthly or annual fees applied",
  ];

  const page_title = (function () {
    switch (layerIndex) {
      case 0:
        return "cards_intro";
      case 1:
        return "cards_compare";
      case 2:
        return "cards_detail";
    }
  })();

  const onClickContactUs = () => {
    eventsTracker(DEMO_CARDS_TO_WA, { page_title });
  };

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, { page_title });
  }, [layerIndex]);

  return (
    <BlockingBlurModal isOpen={isOpen}>
      <div onWheel={handleScroll} className={cardsDemoClassNameDecider()}>
        <div className={styles.backButtonContainer} onClick={handleClose}>
          <JackIcons name="arrow-back" fill="white" style={{ scale: "0.8" }} />
          <GothamMedium style={{ color: jackColors.neutral500 }}>
            Back
          </GothamMedium>
        </div>

        <div className={styles.firstLayer}>
          <div className={styles.twoCardsImageContainer}>
            <NextImage
              width={570}
              height={556}
              src="/images/two-cards-demo.png"
              alt="Two Cards Demo Image"
            />
          </div>
        </div>

        <div className={`${styles.secondLayer} ${scrollAnimationClassName}`}>
          {/* First content */}
          <div className={styles.contentsContainer}>
            <div className={styles.firstContentContainer}>
              <GothamLight
                style={{
                  textAlign: "center",
                  color: "white",
                  fontSize: "56px",
                  lineHeight: "72px",
                }}
              >
                Corporate Cards That Finally Gives You Control
              </GothamLight>
              <GothamLight
                style={{
                  textAlign: "center",
                  color: "white",
                  marginTop: "32px",
                  fontSize: "20px",
                  lineHeight: "32px",
                }}
              >
                Get your corporate cards in less than 7 days and enjoy real-time
                expense tracking with no more monthly statement delays
              </GothamLight>

              <div
                className={styles.moreButton}
                onClick={handleClickKnowMoreButton}
              >
                <JackIcons
                  name="arrowhead-down"
                  fill="white"
                  style={{ scale: "0.8" }}
                />
                <GothamRegular woFontColor style={{ color: "white" }}>
                  Get to know more
                </GothamRegular>
                <JackIcons
                  name="arrowhead-down"
                  fill="white"
                  style={{ scale: "0.8" }}
                />
              </div>
            </div>
          </div>

          {/* Second content */}
          <div className={styles.contentsContainer}>
            <div className={styles.secondContentContainer}>
              <div
                className={`${styles.jackCorporateCardContainer} ${jackCorporateCardAnimationClassName}`}
              >
                <GothamMedium
                  style={{
                    color: "white",
                    fontSize: "36px",
                    lineHeight: "36px",
                  }}
                >
                  Jack
                  <br /> Corporate's Card
                </GothamMedium>
                <div className={styles.jackCorporateCard}>
                  <ul className={styles.list}>
                    {jackCorporateCardPoints.map((point) => {
                      return (
                        <li key={point}>
                          <GothamLight
                            woFontColor
                            className="font16"
                            style={{ color: "white" }}
                          >
                            {point}
                          </GothamLight>
                        </li>
                      );
                    })}
                  </ul>
                  <a
                    target="_blank"
                    href="https://api.whatsapp.com/send/?phone=6285171590117&text&type=phone_number&app_absent=0"
                  >
                    <ButtonJack
                      rightIcon={<DiagonalArrow />}
                      style={{ width: "305px", marginLeft: "32px" }}
                      onClick={onClickContactUs}
                    >
                      Get To Know More
                    </ButtonJack>
                  </a>
                </div>
              </div>
              <div
                className={`${styles.otherCardContainer} ${otherCardAnimationClassName}`}
              >
                <GothamLight
                  style={{
                    color: "white",
                    fontSize: "36px",
                    lineHeight: "36px",
                    textAlign: "right",
                  }}
                >
                  Other
                  <br /> Card
                </GothamLight>
                <div className={styles.otherCard}>
                  <ul className={styles.list}>
                    {otherCardPoints.map((point) => {
                      return (
                        <li key={point}>
                          <GothamLight
                            woFontColor
                            className="font16"
                            style={{ color: "white" }}
                          >
                            {point}
                          </GothamLight>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              </div>
              <div
                className={`${styles.cardSampleContainer} ${cardSampleAnimationClassName}`}
              >
                <NextImage
                  width={310}
                  height={200}
                  src="/images/card-demo-sample.png"
                  alt="Card Demo Sample"
                />
              </div>
            </div>
          </div>

          {/* Third content */}
          <div className={styles.contentsContainer}>
            <div className={styles.thirdContentContainer}>
              <GothamMedium
                style={{
                  color: "white",
                  fontSize: "36px",
                  lineHeight: "56px",
                  textAlign: "center",
                }}
              >
                Corporate Card Type
              </GothamMedium>
              <div className={styles.cardTypesContainer}>
                <div
                  className={`${styles.cardTypeContainer} ${prepaidAnimationClassName}`}
                >
                  <div className={styles.ellipseContainer}>
                    <Ellipse />
                  </div>
                  <div className={styles.cardTypeTitleContainer}>
                    <div
                      className={styles.cardTypeCapsule}
                      style={{ backgroundColor: "black" }}
                    >
                      <GothamMedium
                        className="font16"
                        style={{ color: jackColors.greenB9 }}
                      >
                        PREPAID CARD
                      </GothamMedium>
                    </div>
                    <GothamMedium className="font16" style={{ color: "white" }}>
                      Virtual
                    </GothamMedium>
                  </div>
                  <div className={styles.cardTypeImageContainer}>
                    <NextImage
                      width={373.5}
                      height={200}
                      src="/images/card-type-prepaid-demo.png"
                      alt="Card Type Credit"
                    />
                  </div>
                </div>
                <div
                  className={`${styles.cardTypeContainer} ${creditAnimationClassName}`}
                >
                  <div className={styles.cardTypeTitleContainer}>
                    <div
                      className={styles.cardTypeCapsule}
                      style={{ backgroundColor: jackColors.neutral500 }}
                    >
                      <GothamMedium
                        className="font16"
                        style={{ color: jackColors.neutral900 }}
                      >
                        CREDIT CARD
                      </GothamMedium>
                    </div>
                    <GothamMedium className="font16" style={{ color: "white" }}>
                      Virtual
                    </GothamMedium>
                  </div>
                  <div className={styles.cardTypeImageContainer}>
                    <NextImage
                      width={373.5}
                      height={200}
                      src="/images/card-type-credit-demo.png"
                      alt="Card Type Credit"
                    />
                  </div>
                  <div className={styles.masterCardContainer}>
                    <GothamRegular
                      woFontColor
                      className="font10"
                      style={{ color: jackColors.neutral600 }}
                    >
                      Supported by
                    </GothamRegular>
                    <MasterCard />
                  </div>
                </div>
              </div>
              <div
                className={`${styles.benefitsContainer} ${benefitAnimationClassName}`}
              >
                <div className={styles.benefitContainer}>
                  <LimitlessBenefit />
                  <GothamRegular
                    woFontColor
                    style={{ color: jackColors.neutral600 }}
                  >
                    Limitless access to credit and prepaid cards.
                  </GothamRegular>
                </div>
                <div className={styles.benefitContainer}>
                  <CardBenefit />
                  <GothamRegular
                    woFontColor
                    style={{ color: jackColors.neutral600 }}
                  >
                    Get your cards whenever you need it.
                  </GothamRegular>
                </div>
                <div className={styles.benefitContainer}>
                  <CustomizableBenefit />
                  <GothamRegular
                    woFontColor
                    style={{ color: jackColors.neutral600 }}
                  >
                    Customizable spending limits, you're in control.
                  </GothamRegular>
                </div>
              </div>
              <div
                className={`${styles.benefitButtonContainer} ${buttonAnimationClassName}`}
              >
                <a
                  target="_blank"
                  href="https://api.whatsapp.com/send/?phone=6285171590117&text&type=phone_number&app_absent=0"
                >
                  <ButtonJack
                    rightIcon={<DiagonalArrow />}
                    style={{ width: "305px" }}
                    onClick={onClickContactUs}
                  >
                    Contact Us for More
                  </ButtonJack>
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.scrollButtonsContainer}>
          <div
            className={styles.scrollButton}
            style={{ opacity: layerIndex <= 0 ? "0.4" : "1" }}
            onClick={() => handleClickScrollButton("up")}
          >
            <JackIcons name="chevron-up" fill="white" />
          </div>
          <div
            className={styles.scrollButton}
            style={{ rotate: "180deg", opacity: layerIndex >= 2 ? "0.4" : "1" }}
            onClick={() => handleClickScrollButton("down")}
          >
            <JackIcons name="chevron-up" fill="white" />
          </div>
        </div>
      </div>
    </BlockingBlurModal>
  );
};

export default CardsDemoModal;
