import { useEffect, useState } from "react";
import { isEmpty, times } from "lodash";
import { upperCase } from "change-case";
import cardPhysicalImage from "../../../../../assets/jackImages/card-physical.svg";
import cardVirtual01Image from "../../../../../assets/jackImages/card-virtual-01.svg";
import cardVirtual02Image from "../../../../../assets/jackImages/card-virtual-02.svg";
import cardVirtual03Image from "../../../../../assets/jackImages/card-virtual-03.svg";
import cardVirtual04Image from "../../../../../assets/jackImages/card-virtual-04.svg";
import cardPrepaidImage from "../../../../../assets/jackImages/card-prepaid.svg";
import { GothamRegular, GothamMedium } from "../../../../../components/Text";
import { colors } from "../../../../../assets/colors";
import { cardTypeDecider, cardStateDecider } from "../../../tools";
import Tooltip from "../../Tooltip";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { stringCutter } from "../../../../../components/tools";
import PINModal from "../../../../../components/PINModal/parent";
import { useModalHook } from "../../../../../components/Modals";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";
import { apiBusiness } from "../../../../../tools/api";

const commonStyles = {
  maskedValue: {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.neutral100,
    letterSpacing: "1px",
  },
  value: {
    fontSize: "16px",
    lineHeight: "24px",
    color: colors.neutral100,
  },
  cardDataHovered: {
    boxShadow: `inset 0px -1px 0px ${colors.neutral100}`,
  },
};

const CardNumberDisplay = ({
  cardNumber,
  lastFourNumber,
  isSmall,
  isCardDataHovered,
  woReveal,
  isCanReveal,
  isPhysical,
}) => {
  const [isCopied, setIsCopied] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const formattedCardNumber =
    cardNumber && cardNumber.replace(/(.{4})/g, "$1 ");

  const isDefaultCursor = woReveal || !isCanReveal;
  const isCardNumberHovered = isCardDataHovered || isHovered;
  const styles = {
    cardNumberContainer: {
      display: "flex",
      gap: "8px",
      alignItems: "center",
      transition: "box-shadow 0.2s ease-in-out",
      paddingBottom: "4px",
      cursor: isDefaultCursor ? "default" : "pointer",
      width: "fit-content",
      position: "relative",
      ...(isCardNumberHovered ? commonStyles.cardDataHovered : {}),
    },
    maskedNumber: {
      fontSize: isSmall ? "16px" : "24px",
      color: colors.neutral100,
      marginTop: "8px",
      letterSpacing: "1px",
    },
    cardNumber: {
      fontSize: isSmall ? "16px" : "24px",
      color: colors.neutral100,
    },
    tooltipContent: {
      width: isPhysical ? "232px" : "fit-content",
      whiteSpace: isPhysical ? "normal" : "nowrap",
    },
  };

  useEffect(() => {
    if (isCopied) setTimeout(() => setIsCopied(false), 500);
  }, [isHovered]);

  const hoverProps = {
    onMouseEnter: () => cardNumber && setIsHovered(true),
    onMouseLeave: () => cardNumber && setIsHovered(false),
    onClick: () => {
      if (cardNumber) {
        eventsTracker("card_number_copy");
        navigator.clipboard.writeText(cardNumber);
        setIsCopied(true);
      }
    },
  };

  if (cardNumber)
    return (
      <div>
        <Tooltip
          text={isCopied ? "Copied" : "Click to copy"}
          arrowPlacement="right"
          isShownByProps={isHovered}
          isShown
        >
          <div style={styles.cardNumberContainer} {...hoverProps}>
            <GothamRegular style={styles.cardNumber}>
              {formattedCardNumber}
            </GothamRegular>
          </div>
        </Tooltip>
      </div>
    );

  return (
    <div>
      <Tooltip
        text={
          isPhysical
            ? "See your card number, expiry date, and CVV on your physical card"
            : "Click to reveal"
        }
        arrowPlacement={isPhysical ? "top" : "right"}
        isShown={isCardDataHovered}
        isShownByProps
        tooltipContentStyles={styles.tooltipContent}
      >
        <div style={styles.cardNumberContainer}>
          {times(3, (index) => (
            <GothamRegular index={index} style={styles.maskedNumber}>
              ****
            </GothamRegular>
          ))}
          <GothamRegular style={styles.cardNumber}>
            {lastFourNumber}
          </GothamRegular>
        </div>
      </Tooltip>
    </div>
  );
};

const ExpiryDateDisplay = ({
  expiryDate,
  isCardDataHovered,
  expCvvValueContainerStyle,
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  let [year, month] = expiryDate?.split("-") || [];
  year = year?.slice(2);

  const isExpDateHovered = isCardDataHovered || isHovered;

  const styles = {
    container: {
      ...expCvvValueContainerStyle,
      ...(isExpDateHovered ? commonStyles.cardDataHovered : {}),
    },
  };

  useEffect(() => {
    if (isCopied) setTimeout(() => setIsCopied(false), 500);
  }, [isHovered]);

  const hoverProps = {
    onMouseEnter: () => expiryDate && setIsHovered(true),
    onMouseLeave: () => expiryDate && setIsHovered(false),
    onClick: () => {
      if (expiryDate) {
        eventsTracker("card_expiry_date_copy");
        navigator.clipboard.writeText(month + "/" + year);
        setIsCopied(true);
      }
    },
  };

  return (
    <div>
      <Tooltip
        text={isCopied ? "Copied" : "Click to copy"}
        arrowPlacement="bottom"
        isShown={isHovered}
        isShownByProps
      >
        <div style={styles.container} {...hoverProps}>
          {expiryDate ? (
            <>
              <GothamRegular style={commonStyles.value}>{month}</GothamRegular>
              <GothamMedium style={commonStyles.value}>/</GothamMedium>
              <GothamRegular style={commonStyles.value}>{year}</GothamRegular>
            </>
          ) : (
            <>
              <GothamRegular style={commonStyles.maskedValue}>**</GothamRegular>
              <GothamMedium style={commonStyles.value}>/</GothamMedium>
              <GothamRegular style={commonStyles.maskedValue}>**</GothamRegular>
            </>
          )}
        </div>
      </Tooltip>
    </div>
  );
};

const CvvDisplay = ({ cvv, isCardDataHovered, expCvvValueContainerStyle }) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isCopied, setIsCopied] = useState(false);

  const isCvvHovered = isCardDataHovered || isHovered;
  const styles = {
    container: {
      ...expCvvValueContainerStyle,
      ...(isCvvHovered ? commonStyles.cardDataHovered : {}),
    },
  };

  useEffect(() => {
    if (isCopied) setTimeout(() => setIsCopied(false), 500);
  }, [isHovered]);

  const hoverProps = {
    onMouseEnter: () => cvv && setIsHovered(true),
    onMouseLeave: () => cvv && setIsHovered(false),
    onClick: () => {
      if (cvv) {
        eventsTracker("card_cvv_copy");
        navigator.clipboard.writeText(cvv);
        setIsCopied(true);
      }
    },
  };

  return (
    <Tooltip
      text={isCopied ? "Copied" : "Click to copy"}
      arrowPlacement="bottom"
      isShown={isHovered}
      isShownByProps
    >
      <div style={styles.container} {...hoverProps}>
        {cvv ? (
          <GothamRegular style={commonStyles.value}>{cvv}</GothamRegular>
        ) : (
          <GothamRegular style={commonStyles.maskedValue}>***</GothamRegular>
        )}
      </div>
    </Tooltip>
  );
};

const CardDisplay = ({
  cardDetail: cardDetailProps,
  isSmall,
  woReveal,
  woCardNumber,
}) => {
  const {
    cardDetail: cardDetailContext,
    unmaskedDataDetail,
    setUnmaskedDataDetail,
    setHideClose,
    isReveal,
    rightModalToggle,
    isHideModal,
    setIsHideModal,
  } = useCardDetailContext() || {};
  const [isCardDataHovered, setIsCardDataHovered] = useState(false);
  const [isPinError, setIsPinError] = useState(false);
  const [loadingReveal, setLoadingReveal] = useState(false);
  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();

  const cardDetail = cardDetailContext || cardDetailProps;
  const {
    card_type: cardType,
    color_code: colorCode,
    is_suspended: isSuspended,
    issuance_status: issuanceStatus,
    masked_card_number: maskedCardNumber,
    state,
    user: { name: userName, email: userEmail, id: userId } = {},
  } = cardDetail || {};
  const {
    card_number: unmaskedCardNumber,
    cvv,
    expiry_date: expiryDate,
  } = unmaskedDataDetail || {};
  const { isPhysical, isVirtual, isPrepaid } = cardTypeDecider(cardType);
  const { isPermanentBlock, isTempBlock, isRejected, isNotActive } =
    cardStateDecider(state, issuanceStatus);
  const { user: { id: loggedInUserId } = {} } = useGetAuth();
  const isOwnCard = userId === loggedInUserId;
  const isCanReveal =
    !isSuspended &&
    !isPermanentBlock &&
    !isTempBlock &&
    !isRejected &&
    !isNotActive &&
    isOwnCard &&
    !isSmall;

  const onSubmitPin = async ({ pin }) => {
    try {
      setLoadingReveal(true);
      const url = `/cards/${cardDetail?.id}/card_detail?pin=${pin}`;
      const { data } = await apiBusiness.get(url);
      const dataDetail = data?.data;
      setUnmaskedDataDetail(dataDetail);
      togglePin();
      setHideClose(false);
      if (isHideModal) setIsHideModal(false);
    } catch (error) {
      setIsPinError(true);
    } finally {
      setLoadingReveal(false);
    }
  };

  const cardImageDecider = () => {
    if (isPhysical) return cardPhysicalImage;
    if (isPrepaid) return cardPrepaidImage;
    if (colorCode === "green") return cardVirtual02Image;
    if (colorCode === "orange") return cardVirtual03Image;
    if (colorCode === "red") return cardVirtual04Image;
    return cardVirtual01Image;
  };

  const cardImage = cardImageDecider();
  const lastFourNumber = maskedCardNumber && maskedCardNumber?.substr(-4);
  const displayName = stringCutter(upperCase(userName || userEmail), 20);

  useEffect(() => {
    if (unmaskedDataDetail) setIsCardDataHovered(false);
  }, [unmaskedDataDetail]);

  useEffect(() => {
    if (isReveal) {
      setHideClose(true);
      togglePin();
    }
  }, [isReveal]);

  const isContainerCursorDefault =
    woReveal || !isEmpty(unmaskedDataDetail) || !isCanReveal;
  const styles = {
    container: {
      position: "relative",
      margin: isSmall ? "auto" : "0",
      width: "fit-content",
      cursor: isContainerCursorDefault ? "default" : "pointer",
    },
    cardImage: {
      pointerEvents: "none",
    },
    textContainer: {
      position: "absolute",
      left: "18.5px",
      bottom: "18px",
      display: "flex",
      flexDirection: "column",
      gap: isSmall ? "6px" : "12px",
    },
    cardDataHovered: {
      boxShadow: `inset 0px -1px 0px ${colors.neutral100}`,
    },
    expCvvContainer: {
      display: "flex",
      gap: "20px",
    },
    labelValue: {
      display: "flex",
      gap: "12px",
    },
    label: {
      color: colors.neutral600,
      fontSize: isSmall ? "10px" : "14px",
      marginTop: isSmall ? "0px" : "1.5px",
    },
    value: {
      fontSize: isSmall ? "12px" : "16px",
      lineHeight: "24px",
      color: colors.neutral100,
    },
    maskedValue: {
      fontSize: "16px",
      lineHeight: "24px",
      color: colors.neutral100,
      letterSpacing: "1px",
    },
    name: {
      fontSize: isSmall ? "12px" : "16px",
      lineHeight: "24px",
      color: colors.neutral100,
      letterSpacing: "0.5px",
    },
  };

  const expCvvValueContainerStyle = {
    display: "flex",
    gap: "4px",
    transition: "box-shadow 0.2s ease-in-out",
    paddingBottom: "2px",
    cursor: woReveal || !isCanReveal ? "default" : "pointer",
  };

  const cardDataHoverProps = {
    onMouseEnter: () => isCanReveal && setIsCardDataHovered(true),
    onMouseLeave: () => isCanReveal && setIsCardDataHovered(false),
    onClick: () => {
      if (!isCanReveal || isPhysical) return;
      eventsTracker("card_data_reveal", {
        card_id: cardDetail?.id,
      });
      togglePin();
      setHideClose(true);
    },
  };

  const smallCardProps = {
    width: "278px",
    height: "180px",
  };

  const defaultCardProps = {
    width: "100%",
  };

  return (
    <>
      <div
        style={styles.container}
        {...(woReveal || !isEmpty(unmaskedDataDetail)
          ? {}
          : cardDataHoverProps)}
      >
        <img
          src={cardImage}
          style={styles.cardImage}
          {...(isSmall ? smallCardProps : defaultCardProps)}
        />
        <div style={styles.textContainer}>
          {maskedCardNumber && !woCardNumber && (
            <>
              <CardNumberDisplay
                cardNumber={unmaskedCardNumber}
                lastFourNumber={lastFourNumber}
                cardDataHoverProps={cardDataHoverProps}
                isCardDataHovered={isCardDataHovered}
                isSmall={isSmall}
                woReveal={woReveal}
                isCanReveal={isCanReveal}
                isPhysical={isPhysical}
              />
              {(isVirtual || isPrepaid) && (
                <div style={styles.expCvvContainer}>
                  <div style={styles.labelValue}>
                    <GothamMedium style={styles.label}>EXP</GothamMedium>
                    <ExpiryDateDisplay
                      expiryDate={expiryDate}
                      isCardDataHovered={isCardDataHovered}
                      expCvvValueContainerStyle={expCvvValueContainerStyle}
                    />
                  </div>
                  <div style={styles.labelValue}>
                    <GothamMedium style={styles.label}>CVV</GothamMedium>
                    <CvvDisplay
                      cvv={cvv}
                      isCardDataHovered={isCardDataHovered}
                      expCvvValueContainerStyle={expCvvValueContainerStyle}
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <GothamRegular style={styles.name}>{displayName}</GothamRegular>
        </div>
      </div>
      {isCanReveal && (
        <PINModal
          isOpen={isOpenPin}
          toggle={isReveal ? rightModalToggle : togglePin}
          isError={isPinError}
          setIsError={setIsPinError}
          onSubmit={onSubmitPin}
          isLoading={loadingReveal}
          onBack={
            isReveal
              ? undefined
              : () => {
                  setIsPinError(false);
                  togglePin();
                  setHideClose(false);
                }
          }
          woClose={!isReveal}
        />
      )}
    </>
  );
};

export default CardDisplay;
