import { sentenceCase } from "change-case";
import React from "react";
import snakeCase from "snake-case";
import titleCase from "title-case";
import { colors } from "../assets/colors";
import allocate from "../assets/images/account-allocate3.svg";
import allocationRequestIcon from "../assets/images/account-icon-allocation-request.svg";
import apiAccIcon from "../assets/images/account-icon-api.svg";
import batchAccIcon from "../assets/images/account-icon-batch.svg";
import conversionAccIcon from "../assets/images/account-icon-conversion.svg";
import incomingAccIcon from "../assets/images/account-icon-incoming.svg";
import LocalDisbSingleIcon from "../assets/images/account-icon-local-disbursement-single.svg";
import LocalDisbIcon from "../assets/images/account-icon-local-disbursement.svg";
import payrollIcon from "../assets/images/account-icon-payroll.svg";
import singleAccIcon from "../assets/images/account-icon-single.svg";
import topupAccIcon from "../assets/images/account-icon-topup.svg";
import cardBillPaymentIcon from "../assets/images/card-bill-payment-icon.svg";
import {
  default as cardIcon,
  default as mailchimpAccIcon,
} from "../assets/images/card-icon-32.svg";
import checkIcon from "../assets/images/check_icon.svg";
import crossBorderIcon from "../assets/images/cross-border-icon.svg";
import eWalletIcon from "../assets/images/e-wallet-icon.svg";
import invoiceIcon from "../assets/images/invoice-icon.svg";
import localIcon from "../assets/images/local-icon.svg";
import moneyMovementIcon from "../assets/images/money-movement-icon.svg";
import onprogressIcon from "../assets/images/on_progress.svg";
import refundIconBlue from "../assets/images/refund-icon-blue.svg";
import reimbursementIcon from "../assets/images/reimbursement-icon.svg";
import tag19 from "../assets/images/tag19.svg";
import tag28 from "../assets/images/tag28.svg";
import tag7B from "../assets/images/tag7B.svg";
import tagB8 from "../assets/images/tagB8.svg";
import tagC2 from "../assets/images/tagC2.svg";
import tagEE from "../assets/images/tagEE.svg";
import topUpIcon from "../assets/images/top-up-icon.svg";
import withdrawIcon from "../assets/images/withdraw-small.svg";
import withdrawalIcon from "../assets/images/withdrawal-icon.svg";
import xIcon from "../assets/images/x_icon.svg";
import { GothamMedium, OldGothamRegular } from "./Text";

export const StatusBadge = ({
  state,
  attachment = false,
  style,
  className = "",
  shortBg,
  woIcon,
}) => {
  const {
    isAwaitingRefund,
    isConfirmed,
    isCompleted,
    isOnProgress,
    isWaitingForPayment,
    isCancelled,
    isRefund,
    isValidatingPayment,
    isPaid,
    isError,
    isVerified,
    isSubmitted,
    isDeclined,
    isWaitingPayment,
    isPaymentDetected,
    isComplete,
    isSuccess,
    isStaticExpired,
    isActive,
    isBlock,
    isInvited,
    isBlocked,
    isPending,
    isWaitingVerification,
  } = booleanState(state);

  // const isWaitingForDocument = !attachment && isInvited;

  const colorDecider = () => {
    const isLightTeal =
      isWaitingForPayment ||
      isValidatingPayment ||
      isAwaitingRefund ||
      isConfirmed ||
      isWaitingPayment;
    const isDarkBlue = isOnProgress || isPaid || isSubmitted;
    const isTeal = isRefund || isVerified || isPaymentDetected;
    const isGreen = isCompleted || isComplete || isSuccess;
    const isOrange = isPending;
    const isNavyBlue = isWaitingVerification;
    const isActiveBlue = isActive;

    if (isNavyBlue) return colors.primaryBlue;
    if (isOrange) return "#E95757";
    if (isDarkBlue) return colors.blue19;
    if (isActiveBlue) return colors.bluee1;
    if (isLightTeal) return "#C1EFF4";
    if (isTeal) return colors.teal;
    if (isError || isDeclined || isStaticExpired) return colors.redEB;
    if (isGreen) return "#28AB7C";
    if (isBlocked) return "#F8F8F8";
    return colors.greyC2;
  };

  const titleDecider = () => {
    const formatText = (string) => titleCase(string);
    if (isPending) return formatText("Pending Verification");
    // if (isWaitingForDocument) return formatText("Waiting for Document");
    if (isWaitingForPayment) return formatText("waiting_payment");
    if (isCancelled) return formatText("cancelled");
    if (isWaitingVerification) return formatText("in_review");
    return formatText(state || "");
  };

  const iconDecider = () => {
    if (isError || isDeclined) return xIcon;
    if (isOnProgress) return onprogressIcon;
    if (isCompleted) return checkIcon;
    if (isRefund) return refundIconBlue;
  };

  const textColorDecider = () => {
    const isLightTeal = "#C1EFF4" == colorDecider();
    const isActiveBlue = colors.bluee1 === colorDecider();
    if (isActiveBlue) return colors.blue10;
    if (isLightTeal) return colors.teal21;
    if (isBlocked) return "#C2C2C2";
    return "white";
  };

  const getIcon = iconDecider();
  const getColor = colorDecider();
  const getTitle = titleDecider();
  const getTextColor = textColorDecider();

  const showIcon = Boolean(getIcon) && !woIcon;
  return (
    <button
      style={{
        boxShadow: "0 0 0",
        backgroundColor: getColor,
        borderWidth: 0,
        height: 24,
        minWidth: shortBg ? 0 : 175,
        color: getTextColor,
        fontSize: 12,
        lineHeight: 16,
        letterSpacing: "0.15px",
        ...style,
      }}
      className={`badge px-3 d-flex align-items-center justify-content-center ${className}`}
    >
      {showIcon && <img src={getIcon} style={{ marginRight: 15 }} />}
      {getTitle}
    </button>
  );
};

export const booleanState = (state, payment_method) => {
  const status = snakeCase(state);
  const isAwaitingRefund = status == "awaiting_refund";
  const isRejected = status == "rejected" || status == "approval_rejected";
  const isCreated = status == "created";
  const isCompleted = status === "completed" || status == "approved";
  const isOnProgress = status === "on_progress" || status == "in_progress";
  const isWaitingForPayment = status === "waiting_for_payment";
  const isCancelled = status === "canceled" || status == "cancelled";
  const isRefund = status === "refunded" || status == "refund_transaction";
  const isValidatingPayment =
    status == "validating_payment" || status == "waiting_for_confirmation";
  const isNotSubmitted = status == "not_submitted";
  const isPaid = status == "paid";
  const isVerified = status == "verified";
  const isConfirmed = status == "confirmed";
  const isSubmitted = status == "submitted";
  const isDeclined = status == "declined";
  const isWaitingPayment = status == "waiting_payment";
  const isPaymentDetected = status == "payment_detected";
  const isExpired = status == "expired";
  const isStaticExpired = status == "static_trx_expired";
  const isComplete = status == "complete";
  const isSuccess = status == "success";
  const isPending = status == "pending" || status == "draft";
  const isWaitingVerification = status == "waiting_verification";
  const isFailed = status == "failed";
  const isError = Boolean(
    status === "failed" || isCancelled || status === "rejected" || isExpired
  );
  const isWithdraw = status == "withdrawal" || status == "withdraw";
  const isReAllocate = status == "reallocate" || status == "wallet_refund";
  const isInitial = status == "initial";
  const isLocalTransfer = status == "local_transfer";
  const isPartialFailed = status == "partial_failed";

  // STATUS ACCOUNT ICON
  const isCardMoveBalance = status == "card_move_balance";
  const isCardCreditTopUp = status == "card_topup_payment";
  const isCardTransaction = status == "card_transaction";
  const isSingleTransaction =
    status == "single_transaction" ||
    status == "single_cross_border" ||
    status == "single";
  const isBatchTransaction =
    status == "batch_transaction" ||
    status == "batch_cross_border" ||
    status == "batch";
  const isIncomingPayment = status == "incoming_payment";
  const isTopUp =
    status == "top_up" ||
    status == "topup_transaction" ||
    status == "topup_request";
  const isApiTransaction = status == "api_transaction" || status == "api";
  const isConversion = status == "conversion";
  const isAllocate = status == "allocate_fund";
  const isConvert = status == "convert";
  const isLocalDisbursement =
    status == "local_disbursement" || status == "local_single";
  const isLocalDisbursementBatch =
    status == "local_disbursement_batch" || status === "local_batch";
  const isPayroll = status == "payroll";
  const isLocalTransferSingle = status == "local_transfer_single";
  const isCollection = status == "collection";

  // STATUS ACCOUNT TAG
  const isMarketing = status == "marketing";
  const isDeveloper = status == "developer" || status === "it";
  const isCompliance = status == "compliance";
  const isOperations = status == "operations";
  const isHr = status == "hr" || status === "hr_ga";

  // STATUS TAG
  const isActive = status == "active";
  const isBlock = status == "block";
  const isBlocked = status == "blocked";
  const isInvited = status == "invited";
  // const isWaitingForDocument = status == "waiting_for_document";

  // PAYROLL STATUS
  const isWaitingApprovalTask =
    status === "waiting_for_approval_tasks" ||
    status === "pending_approval" ||
    status === "waiting_for_approval";
  const isDraft =
    status === "draft" ||
    status === "drafted" ||
    status === "validate_recipient" ||
    status === "created";
  const isRejectedByApproval =
    status === "rejected_by_approver" || status === "rejected_by_approval";
  const isWaitingForRelease =
    status === "waiting_for_release" || status === "waiting_payment";
  const isRejectedByAdmin = status === "rejected_by_admin";
  const isCancelledPayroll =
    isRejected ||
    isRejectedByAdmin ||
    isExpired ||
    isCancelled ||
    isRejectedByApproval;

  // e-wallet
  const isWalletReturn = status == "wallet_return";
  const isWalletHistory = status == "wallet_history";
  const isAllocationRequest = status == "allocation_request";
  const isReturnRequest = status == "return_request";
  const isWalletAllocation = status == "wallet_allocation";
  // e-wallet

  // new-activity-props
  const isReimbursement = status == "reimbursement";
  const isInvoice = status == "invoice";
  const isCrossBorder =
    isSingleTransaction || isBatchTransaction || isApiTransaction;
  const isLocalDisbursements = isLocalDisbursement || isLocalDisbursementBatch;
  const isMainWalletAllocation = status == "main_wallet_allocation";

  const isEWallet =
    isWalletAllocation ||
    isAllocationRequest ||
    isWalletReturn ||
    isReturnRequest ||
    isWalletHistory ||
    isMainWalletAllocation;
  const isCard = status == "card" || status === "expired_card";
  const isCardGeneral =
    status === "card" ||
    status === "card_transaction" ||
    status === "CardTransaction";
  const isCardReveal = status === "card_reveal";
  const isMoneyMovement = status == "money-movement";
  const isCardBillPayment = status == "card_payment";
  const isUserAndTeams = status == "user";
  // new-activity-props

  const isInvoicePayment = status == "invoice_payment" || isInvoice;

  const isJackTransfer = status === "balance_transfer";

  const isMainBalance = payment_method == "main_balance";
  const isMoveBalance = payment_method == "move_balance";

  return {
    isCardGeneral,
    isCardReveal,
    isInvoicePayment,
    isMainWalletAllocation,
    isReimbursement,
    isInvoice,
    isCrossBorder,
    isLocalDisbursements,
    isEWallet,
    isCard,
    isMoneyMovement,
    isCardBillPayment,
    isWalletHistory,
    isReturnRequest,
    isAllocationRequest,
    isWalletAllocation,
    isWalletReturn,
    isUserAndTeams,
    isAwaitingRefund,
    isPending,
    isVerified,
    isRejected,
    isCreated,
    isCompleted,
    isOnProgress,
    isWaitingForPayment,
    isCancelled,
    isRefund,
    isValidatingPayment,
    isSubmitted,
    isPaid,
    isError,
    isNotSubmitted,
    isConfirmed,
    isDeclined,
    isWaitingPayment,
    isPaymentDetected,
    isComplete,
    isStaticExpired,
    isSuccess,
    isCardMoveBalance,
    isCardCreditTopUp,
    isCardTransaction,
    isSingleTransaction,
    isBatchTransaction,
    isIncomingPayment,
    isTopUp,
    isApiTransaction,
    isConversion,
    isMarketing,
    isDeveloper,
    isCompliance,
    isOperations,
    isHr,
    isActive,
    isBlock,
    isBlocked,
    isWaitingVerification,
    isAllocate,
    isConvert,
    isWithdraw,
    isReAllocate,
    isInitial,
    isLocalTransfer,
    isLocalDisbursement,
    isLocalDisbursementBatch,
    isPayroll,
    isInvited,
    isWaitingApprovalTask,
    isDraft,
    isRejectedByApproval,
    isWaitingForRelease,
    isRejectedByAdmin,
    isCancelledPayroll,
    isFailed,
    isPartialFailed,
    isLocalTransferSingle,
    isCollection,
    isMainBalance,
    isMoveBalance,
    isJackTransfer,
  };
};

export const StatusAccountIcon = ({ state }) => {
  const {
    isCardTransaction,
    isSingleTransaction,
    isBatchTransaction,
    isIncomingPayment,
    isTopUp,
    isApiTransaction,
    isConversion,
    isAllocate,
    isConvert,
    isRefund,
    isWithdraw,
    isReAllocate,
    isLocalDisbursement,
    isLocalDisbursementBatch,
    isPayroll,
    isAllocationRequest,
    isReimbursement,
    isInvoice,
    isCrossBorder,
    isLocalDisbursements,
    isEWallet,
    isCard,
    isMoneyMovement,
    isCardBillPayment,
  } = booleanState(state);

  const iconDecider = () => {
    // new activity
    if (isReimbursement) return reimbursementIcon;
    if (isInvoice) return invoiceIcon;
    if (isPayroll) return payrollIcon;
    if (isCrossBorder) return crossBorderIcon;
    if (isLocalDisbursements) return localIcon;
    if (isEWallet) return eWalletIcon;
    if (isCard) return cardIcon;
    if (isMoneyMovement) return moneyMovementIcon;
    if (isTopUp || isIncomingPayment) return topUpIcon;
    if (isWithdraw) return withdrawalIcon;
    if (isCardBillPayment) return cardBillPaymentIcon;
    if (isRefund) return refundIconBlue;
    // new activity

    if (isCardTransaction) return mailchimpAccIcon;
    if (isSingleTransaction) return singleAccIcon;
    if (isBatchTransaction) return batchAccIcon;
    if (isIncomingPayment) return incomingAccIcon;
    if (isTopUp || isRefund) return topupAccIcon;
    if (isApiTransaction) return apiAccIcon;
    if (isAllocate || isReAllocate) return allocate;
    if (isConversion || isConvert) return conversionAccIcon;
    if (isWithdraw) return withdrawIcon;
    if (isLocalDisbursement) return LocalDisbIcon;
    if (isLocalDisbursementBatch) return LocalDisbSingleIcon;
    if (isAllocationRequest) return allocationRequestIcon;
  };

  const getIcon = iconDecider();

  if (isCrossBorder)
    return (
      <div style={{ position: "relative", top: "6px" }}>
        <img src={getIcon} />
      </div>
    );

  return <img src={getIcon} />;
};

const AtomicStatusTag = ({
  text,
  color,
  style,
  fontSize = 10,
  textStyle,
  src,
  imgStyle,
}) => (
  <div className="d-flex align-items-center" style={style}>
    {text && src && (
      <img src={src} style={{ marginRight: 3, width: 12, ...imgStyle }} />
    )}
    <OldGothamRegular
      style={{
        color,
        fontSize,
        marginBottom: 0,
        ...textStyle,
      }}
    >
      {text ? text : "-"}
    </OldGothamRegular>
  </div>
);

export const StatusAccountTag = ({
  state,
  style,
  fromModal,
  textStyle,
  imgStyle,
}) => {
  const { isMarketing, isDeveloper, isCompliance, isOperations, isHr } =
    booleanState(state);

  const propsDecider = () => {
    if (!state) return { color: "#000000" };
    if (isMarketing) return { color: "#EEAB47", tag: tagEE };
    if (isDeveloper) return { color: "#196E97", tag: tag19 };
    if (isCompliance) return { color: "#28AB7C", tag: tag28 };
    if (isOperations) return { color: "#B849B4", tag: tagB8 };
    if (isHr) return { color: "#7B61FF", tag: tag7B };
    return { color: colors.greyC2, tag: tagC2 };
  };

  const { color, tag } = propsDecider();

  return (
    <AtomicStatusTag
      imgStyle={imgStyle}
      src={tag}
      color={color}
      style={style}
      text={state}
      textStyle={textStyle}
      fontSize={fromModal ? 10 : 12}
    />
  );
};

export const BlueStatus = ({ text, style, isBlue = false }) => {
  if (!text) return null;
  return (
    <button
      className="fake-button no-hover"
      style={{
        backgroundColor: isBlue ? colors.bluee1 : colors.pink,
        borderRadius: 4,
        paddingLeft: 8,
        paddingRight: 8,
        paddingTop: 2,
        paddingBottom: 2,
        color: isBlue ? colors.blue10 : colors.whiteFC,
        minWidth: 20,
        textAlign: "center",
        fontFamily: "GothamMedium",
        fontSize: 10,
        ...style,
      }}
    >
      {titleCase(text)}
    </button>
  );
};

export const labelStatusDecider = (state) => {
  const {
    isWaitingForPayment,
    isCompleted,
    isInitial,
    isConfirmed,
    isSubmitted,
    isPending,
  } = booleanState(state);
  if (isWaitingForPayment) return "Waiting Payment";
  if (isCompleted) return "Completed";
  if (isInitial) return "Created";
  if (isConfirmed || isSubmitted) return "On Progress";
  if (isPending) return "Draft";
  return sentenceCase(state);
};

export const StatusActivities = ({
  state,
  style,
  className,
  containerStyle,
  color: colorProps,
  woStateFormatter,
}) => {
  const {
    isWaitingForPayment,
    isValidatingPayment,
    isCancelled,
    isRejected,
    isOnProgress,
    isComplete,
    isCompleted,
    isSuccess,
    isFailed,
    isAwaitingRefund,
    isRefund,
    isPaid,
    isCreated,
    isDeclined,
    isInitial,
    isConfirmed,
    isSubmitted,
    isPending,
    isWaitingApprovalTask,
    isWaitingForRelease,
  } = booleanState(state);

  const colorDecider = () => {
    if (
      isWaitingForPayment ||
      isValidatingPayment ||
      isAwaitingRefund ||
      isCreated ||
      isInitial ||
      isWaitingApprovalTask ||
      isWaitingForRelease
    )
      return colors.teal;
    if (isOnProgress || isConfirmed || isSubmitted) return "#2C4A77";
    if (isComplete || isCompleted || isPaid || isSuccess) return colors.green28;
    if (isRefund) return "#7B61FF";
    if (isCancelled || isRejected || isFailed || isDeclined)
      return colors.redE9;
    if (isPending) return "#FCB037";
    return colors.greyC2;
  };

  const color = colorProps || colorDecider();
  const label = woStateFormatter
    ? sentenceCase(state)
    : labelStatusDecider(state);

  const DotColor = () => (
    <div
      style={{
        width: 8,
        height: 8,
        borderRadius: "50%",
        border: `2px solid ${color}`,
        backgroundColor: "transparent",
        marginRight: 3,
      }}
    />
  );

  return (
    <div className="d-flex align-items-center" style={containerStyle}>
      <DotColor />
      <GothamMedium className={className} style={{ color, ...style }}>
        {label}{" "}
      </GothamMedium>
    </div>
  );
};

export const LabelDeciderModal = (state) => {
  const { isCompleted, isDeclined, isInitial } = booleanState(state);
  if (isInitial) return "Created";
  if (isCompleted) return "Completed";
  if (isDeclined) return "Declined";
  return titleCase(state);
};

export const LabelPayrollMasking = (state) => {
  const {
    isWaitingApprovalTask,
    isRejectedByApproval,
    isDraft,
    isWaitingForRelease,
    isRejectedByAdmin,
    isOnProgress,
  } = booleanState(state);
  if (isWaitingApprovalTask) return "pending_approval";
  if (isRejectedByApproval || isRejectedByAdmin) return "rejected";
  if (isDraft) return "draft";
  if (isWaitingForRelease) return `waiting_payment`;
  return state;
};

export const StatusLabel = ({
  status,
  style,
  className = "",
  woTextFormatter,
}) => {
  const successArr = [
    "success",
    "completed",
    "partial_failed",
    "paid",
    "active",
  ];
  const draftArr = ["draft", "created", "drafted"];
  const inProgressArr = [
    "in-progress",
    "in_progress",
    "on_progress",
    "validating_payment",
    "validating payment",
  ];
  const pendingArr = [
    "pending",
    "pending_approval",
    "waiting_for_approval",
    "waiting_for_release",
    "waiting_for_payment",
    "waiting_for_confirmation",
  ];
  const failedArr = [
    "failed",
    "refunded",
    "awaiting_refund",
    "declined",
    "balance_return",
  ];
  const cancellerArr = [
    "cancelled",
    "canceled",
    "rejected",
    "rejected_by_admin",
    "rejected_by_approver",
    "approval_rejected",
  ];

  const isSuccess = successArr.includes(status);
  const isDraft = draftArr.includes(status);
  const isInProgress = inProgressArr.includes(status);
  const isPending = pendingArr.includes(status);
  const isFailed = failedArr.includes(status);
  const isCancelled = cancellerArr.includes(status);

  const textColorDecider = () => {
    if (isSuccess) return "#16A370";
    if (isInProgress) return "#6C6C71";
    if (isPending || isDraft) return "#6C6C71";
    if (isFailed || isCancelled) return "#E95757";
    return "#6C6C71";
  };

  const bgColorDecider = () => {
    if (isSuccess) return "#D3F5EC";
    if (isInProgress) return "#E6E6E8";
    if (isPending || isDraft) return "#E6E6E8";
    if (isFailed || isCancelled) return "#FFD5CD";
    return "#E6E6E8";
  };
  const textDecider = () => {
    if (woTextFormatter) return status;
    if (isSuccess) return "success";
    if (isInProgress) return "in progress";
    if (isPending) return "pending";
    if (isFailed) return "failed";
    if (isCancelled) return "cancelled";
    if (isDraft) return "draft";
    return status;
  };

  let textColor = textColorDecider();
  let backgroundColor = bgColorDecider();
  let text = textDecider();
  return (
    <div style={{ display: "inline-block" }}>
      {text && (
        <GothamMedium
          className={className}
          style={{
            fontSize: 12,
            color: textColor,
            backgroundColor: backgroundColor,
            padding: "2px 8px",
            borderRadius: 8,
            marginBottom: 0,
            lineHeight: "16px",
            ...style,
          }}
        >
          {titleCase(text)}
        </GothamMedium>
      )}
    </div>
  );
};
