import { colors, jackColors } from "../../../../../../assets/colors";
import { StatusLabelJack } from "../../../../../../components/StatusLabel";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../../components/Text";
import { customStatusColorsTransaction } from "../../../../constants";
import CardHeader from "../../components/CardHeader";
import { LeftRightList } from "../../../../../../components/Lists";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { useEffect, useState } from "react";
import {
  cardTransactionStateDecider,
  cardTypeDecider,
  paymentMethodDecider,
  transactionTypeDecider,
} from "../../../../tools";
import { cardTransactionDetailFormatter } from "./formatter";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../../../../components/tools";
import ParserRichTextArea from "../../../../../../components/ParserRichTextArea";
import { SeeMore } from "../../../../../../components/SeeMore";
import { capitalize } from "lodash";
import { Banner } from "../../../../../../components/Banner";
import CategorySelection from "../../../../../categoryComponents/general/components/CategorySelection";
import { useMutation } from "../../../../../../tools/api";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { useConstants } from "../../../../../../contexts/ConstantsContext/parent";

const CardTransactionDetailParent = ({
  transactionDetail,
  setCurrentScreen,
  isCanManageCard,
  refetchTransactionDetail,
  onUploadDocument,
  loadingTransactionDetail,
}) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [transactionInfos, setTransactionInfos] = useState([]);

  const {
    amount,
    file_details: fileDetails,
    has_attachment: hasAttachment,
    merchant_name: merchantName,
    notes,
    payment_method: paymentMethod,
    settlement_status: settlementStatus,
    state,
    transaction_currency: transactionCurrency,
    transaction_amount: transactionAmount,
    transaction_type: transactionType,
    user_card,
  } = transactionDetail || {};
  const { card_type: cardType, user: { name: userName } = {} } =
    user_card || {};

  const { isPrepaid } = cardTypeDecider(cardType);
  const { isPrepaidTopUp, isWithdrawal, isCreditTopUp } =
    transactionTypeDecider(transactionType);

  const { isCancelled, isReturned, isSuccess } =
    cardTransactionStateDecider(state);

  const isInsufficientDeclined =
    settlementStatus === "Insufficient funds/over credit limit";
  const isExpiredDeclined = settlementStatus === "Expired Card";
  const isMerchantDeclined = settlementStatus === "Do not honor";

  const { isMainBalance, isMoveBalance, isBackOffice } =
    paymentMethodDecider(paymentMethod);

  const isShowReceiptNotes = isSuccess && !isBackOffice;
  const isWoReceipt = isMainBalance || isMoveBalance || isCreditTopUp;

  const isNotesEmpty =
    !notes || notes === "" || notes === "<p></p>" || notes === "<p><br></p>";

  const { categories } = useConstants();

  const { successSnackBar } = ToasterHook();

  const { mutation: updateCategory } = useMutation({
    url: `/card_transactions/${transactionDetail?.id}/update`,
    afterSuccess: () => {
      successSnackBar({ msg: "Transaction category has been updated." });
      refetchTransactionDetail();
    },
  });

  const transactionTitleFormatter = (merchantName) => {
    if (!merchantName) return "";
    const words = merchantName.split("-");
    const realMerchantName = words[0];
    const cardType = isPrepaid ? "Prepaid" : capitalize(cardType);
    if (isMoveBalance || isMainBalance) return merchantName;
    return `${cardType} Card: ${realMerchantName}`;
  };

  const formattedTitle = transactionTitleFormatter(merchantName);
  const isDifferentCurrency = transactionCurrency !== "IDR";

  const isTransactionAmountLarger = +transactionAmount > +amount;
  const exchangeRate = isTransactionAmountLarger
    ? transactionAmount / amount
    : amount / transactionAmount;
  const rightCurrency = isTransactionAmountLarger ? transactionCurrency : "IDR";
  const leftCurrency = isTransactionAmountLarger ? "IDR" : transactionCurrency;

  const isNotPrepaidAction = !isPrepaidTopUp && !isWithdrawal && !isCreditTopUp;

  const isCanOpenReceipt = isCanManageCard || hasAttachment;

  const bannerType = isReturned ? "information" : "error";
  const bannerMessageDecider = () => {
    if (isCancelled) return "This transaction has been canceled.";
    if (isReturned) return "This transaction has been returned";
    if (isInsufficientDeclined)
      return (
        <>
          This transaction is failed due to{" "}
          <TextInlineMedium>
            insufficient funds/over the credit limit
          </TextInlineMedium>
        </>
      );
    if (isExpiredDeclined)
      return (
        <>
          This transaction is failed due to{" "}
          <TextInlineMedium>expired card</TextInlineMedium>
        </>
      );
    if (isMerchantDeclined)
      return (
        <>
          This transaction is failed due to{" "}
          <TextInlineMedium>mismatch card details</TextInlineMedium>
        </>
      );
  };
  const bannerMessage = bannerMessageDecider();

  const navigateAddReceipt = () => {
    if (isCanOpenReceipt) setCurrentScreen("addReceipt");
  };
  const navigateAddDescription = () => setCurrentScreen("addDescription");

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  };

  const transactionHeaderStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
  };

  const amountStyle = {
    fontSize: "24px",
  };

  const nameStatusContainerStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  };

  const createdByStyle = {
    fontSize: "12px",
    color: colors.neutral700,
    letterSpacing: "0.2px",
  };

  const infoContainerStyle = {
    padding: "12px",
    border: `1px solid ${colors.neutral500}`,
    borderRadius: "4px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    cursor: isCanOpenReceipt ? "pointer" : "default",
  };

  const leftInfoContainerStyle = {
    display: "flex",
    gap: "16px",
    alignItems: "center",
  };

  const backgroundIconStyle = {
    backgroundColor: colors.neutral900,
    borderRadius: "50%",
    width: "32px",
    height: "32px",
    display: "grid",
    placeItems: "center",
  };

  const squareContainerStyle = {
    width: "32px",
    height: "32px",
    borderRadius: "4px",
    border: `1px solid ${colors.neutral500}`,
    display: "grid",
    placeItems: "center",
    cursor: "pointer",
  };

  const textContainerStyle = {
    width: "240px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  };

  const primaryTextStyle = {
    color: colors.neutral900,
    fontSize: "12px",
  };

  const secondaryTextStyle = {
    color: colors.neutral700,
    fontSize: "12px",
  };

  const valueTextStyle = {
    color: colors.neutral900,
    fontSize: "12px",
  };

  const amountContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  };

  const totalPaymentContainerStyle = {
    ...infoContainerStyle,
    cursor: "default",
    flexDirection: "column",
    gap: "16px",
  };

  const totalPaymentRowStyle = {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  };

  const notesContainerStyle = {
    padding: "12px",
    border: `1px solid ${colors.neutral500}`,
    borderRadius: "0 0 4px 4px",
    marginTop: "-1px",
  };

  const notesStyle = {
    color: colors.neutral700,
    letterSpacing: "0.2px",
    fontSize: "12px",
  };

  useEffect(() => {
    if (transactionDetail)
      setTransactionInfos(
        cardTransactionDetailFormatter({
          transactionDetail,
        })
      );
  }, [transactionDetail]);

  useEffect(() => {
    const category = (categories ?? []).find(
      ({ id }) => id === transactionDetail?.category_id
    );
    setSelectedCategory(category);
  }, [categories, transactionDetail?.category_id]);

  if (loadingTransactionDetail) return null;

  return (
    <div style={containerStyle}>
      <CardHeader
        transactionDetail={transactionDetail}
        isTransactionSuccess={isSuccess}
      />
      <div style={transactionHeaderStyle}>
        <GothamRegular>{formattedTitle}</GothamRegular>
        <GothamBold style={amountStyle}>
          IDR {formatCurrencyNoDecimal(amount)}
        </GothamBold>
        <div style={nameStatusContainerStyle}>
          {isNotPrepaidAction ? (
            <GothamRegular style={createdByStyle}>
              Created by {userName}
            </GothamRegular>
          ) : (
            <div />
          )}
          <StatusLabelJack
            customStatusColors={customStatusColorsTransaction}
            isOutline
            woTextFormatter
            status={state}
          />
        </div>
      </div>

      {!isSuccess && <Banner type={bannerType} msg={bannerMessage} />}

      <LeftRightList array={transactionInfos} gap={16} />

      {isNotPrepaidAction && (
        <div style={totalPaymentContainerStyle}>
          <div style={totalPaymentRowStyle}>
            <GothamRegular style={secondaryTextStyle}>
              Total payment
            </GothamRegular>
            <div style={amountContainerStyle}>
              <GothamRegular style={valueTextStyle}>
                IDR {formatCurrencyNoDecimal(amount)}
              </GothamRegular>
              {isDifferentCurrency && (
                <GothamRegular style={secondaryTextStyle}>
                  {transactionCurrency} {formatCurrency(transactionAmount)}
                </GothamRegular>
              )}
            </div>
          </div>
          {isDifferentCurrency && (
            <div style={totalPaymentRowStyle}>
              <GothamRegular style={secondaryTextStyle}>
                Exchange rate
              </GothamRegular>
              <GothamRegular style={valueTextStyle}>
                1 {leftCurrency} = {formatCurrencyNoDecimal(exchangeRate)}{" "}
                {rightCurrency}
              </GothamRegular>
            </div>
          )}
        </div>
      )}

      <CategorySelection
        isForTrxDetails
        style={{ marginBottom: 0 }}
        selectedCategory={selectedCategory ?? {}}
        onSelect={(category) => {
          setSelectedCategory(category);
          updateCategory({ category_id: category?.id });
          onUploadDocument();
        }}
      />

      {isShowReceiptNotes && !isWoReceipt && (
        <div style={infoContainerStyle} onClick={navigateAddReceipt}>
          <div style={leftInfoContainerStyle}>
            <div style={backgroundIconStyle}>
              <JackIcons name="layers-outline" fill={colors.neutral100} />
            </div>
            <div style={textContainerStyle}>
              <div
                style={{ display: "flex", alignItems: "center", gap: "2px" }}
              >
                <GothamMedium style={primaryTextStyle}>
                  {hasAttachment || !isCanManageCard
                    ? `${fileDetails?.length} Receipt(s)`
                    : "Add Receipt"}
                </GothamMedium>
                {!hasAttachment && (
                  <JackIcons
                    name="alert-circle-outline"
                    fill={jackColors.yellowFC}
                    style={{ scale: "0.8", marginTop: "-4px" }}
                  />
                )}
              </div>
              {isCanOpenReceipt && (
                <GothamRegular style={secondaryTextStyle}>
                  {hasAttachment
                    ? "See all receipts"
                    : "Your uploaded receipt will be shown here"}
                </GothamRegular>
              )}
            </div>
          </div>
          {isCanOpenReceipt && (
            <JackIcons name="chevron-right" fill={colors.neutral900} />
          )}
        </div>
      )}

      {isShowReceiptNotes &&
        (isNotesEmpty && isCanManageCard ? (
          <div style={infoContainerStyle} onClick={navigateAddDescription}>
            <div style={leftInfoContainerStyle}>
              <div style={backgroundIconStyle}>
                <JackIcons name="file-text-outline" fill={colors.neutral100} />
              </div>
              <div style={textContainerStyle}>
                <div
                  style={{ display: "flex", alignItems: "center", gap: "2px" }}
                >
                  <GothamMedium style={primaryTextStyle}>
                    Add description
                  </GothamMedium>
                  {isNotesEmpty && (
                    <JackIcons
                      name="alert-circle-outline"
                      fill={jackColors.yellowFC}
                      style={{ scale: "0.8", marginTop: "-4px" }}
                    />
                  )}
                </div>
                <GothamRegular style={secondaryTextStyle}>
                  Explain more about this transaction
                </GothamRegular>
              </div>
            </div>
            {isCanManageCard && (
              <JackIcons name="chevron-right" fill={colors.neutral900} />
            )}
          </div>
        ) : (
          <div>
            <div
              style={{
                ...infoContainerStyle,
                cursor: "default",
                borderRadius: "4px 4px 0 0",
              }}
            >
              <div
                style={{
                  ...leftInfoContainerStyle,
                  alignItems: "center",
                }}
              >
                <div style={backgroundIconStyle}>
                  <JackIcons
                    name="file-text-outline"
                    fill={colors.neutral100}
                  />
                </div>
                <GothamMedium style={primaryTextStyle}>
                  Description
                </GothamMedium>
              </div>
              {isCanManageCard && (
                <div
                  style={squareContainerStyle}
                  onClick={navigateAddDescription}
                >
                  <JackIcons name="edit" fill={colors.neutral900} />
                </div>
              )}
            </div>
            <div style={notesContainerStyle}>
              <SeeMore height={64}>
                {isNotesEmpty ? (
                  <GothamRegular style={notesStyle}>
                    No description.
                  </GothamRegular>
                ) : (
                  <ParserRichTextArea text={notes} textStyle={notesStyle} />
                )}
              </SeeMore>
            </div>
          </div>
        ))}
    </div>
  );
};

export default CardTransactionDetailParent;
