import { upperCase } from "change-case";
import { useRef } from "react";
import { colors } from "../../../assets/colors";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { gothamizedText } from "../../draftComponents/tools/helpers";
import { useFetchBankAccount } from "./useFetchBankAccount";

const tableTotalWidth = 1136;
const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;

export const bankAccountTableFormat = (row) => {
  const { local_recipient, payer_name } = row || {};

  const bankNameFormatted = upperCase(payer_name);
  const accountNumberFormatted = gothamizedText(
    local_recipient?.account_number
  );
  const accountNameFormatted = gothamizedText(local_recipient?.registered_name);

  return [bankNameFormatted, accountNumberFormatted, accountNameFormatted];
};

export const useBankAccountTableFormer = () => {
  // const { data: rawDataOnly, nextPage, loading } = useFetchRow(menu)
  const {
    data: rawDataOnly,
    loading,
    nextPage,
    refetch,
  } = useFetchBankAccount();

  const tbodyRef = useRef(null);
  const headerArr = [
    {
      label: "Bank Name",
      width: tableWidth(324),
    },
    {
      label: "Bank account number",
      width: tableWidth(324),
    },
    {
      label: "Bank account name",
      width: tableWidth(324),
    },
    {
      label: "",
      width: tableWidth(148),
    },
  ];

  let bodyTable = rawDataOnly.map((item) => {
    return {
      rawData: item,
      tableRow: item ? bankAccountTableFormat(item) : [],
    };
  });

  return { headerArr, tbodyRef, bodyTable, refetch, loading, nextPage };
};

export const SubTextComponent = ({
  text,
  subText,
  textAlign = "left",
  isNotBold = false,
  className = "",
  customTextStyle = {},
  customSubStyle = {},
  divStyle,
  isOwnComponent = false,
}) => {
  const textStyle = {
    color: colors.neutral900,
    letterSpacing: "0.1px",
    textAlign,
    fontWeight: "normal",
    ...customTextStyle,
  };

  const subTextStyle = {
    color: colors.neutral700,
    letterSpacing: "0.2px",
    textAlign,
    ...customSubStyle,
  };

  return (
    <div style={divStyle}>
      {!isNotBold ? (
        <GothamMedium style={textStyle}>{text}</GothamMedium>
      ) : !isOwnComponent ? (
        <GothamRegular className={className} style={textStyle}>
          {text}
        </GothamRegular>
      ) : (
        text
      )}
      <GothamLight style={subTextStyle}>{subText}</GothamLight>
    </div>
  );
};

export const capitalizeFirstLetterMultiple = (words) => {
  const splitted = words?.split(" ");
  const capitalized = splitted
    ?.map((el) => capitalizeFirstLetter(el))
    ?.join(" ");
  return capitalized;
};

export const capitalizeFirstLetter = (string) => {
  return string[0].toUpperCase() + string.slice(1);
};
