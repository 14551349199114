// get access
export const DEMO_PAGE_VIEW = "page_view";
export const DEMO_MEDIA_VIEW = "media_view";
export const DEMO_PLAY_VIDEO = "play_video";
export const DEMO_CLICK_RESEND_ACCESS = "click_resend_access";
export const DEMO_CLICK_RESEND_EMAIL = "click_resend_email";
export const DEMO_CLICK_GET_ACCESS = "click_get_access";

// switch role
export const DEMO_SWITCH_ROLE = "switch_role";
export const DEMO_REGISTER_FROM_RIBBON = "register_from_ribbon";
export const DEMO_START_GUIDANCE = "start_guidance";
export const DEMO_SWITCH_ROLE_TO_BO = "switch_role_to_bo";
export const DEMO_SWITCH_ROLE_TO_ADMIN = "switch_role_to_admin";
export const DEMO_SWITCH_ROLE_TO_EMPLOYEE = "switch_role_to_employee";
export const DEMO_SWITCH_ROLE_TO_BOOKKEEPER = "switch_role_to_bookkeeper";

// cards page
export const DEMO_ENTER_CARD = "enter_card";
export const DEMO_CARDS_TO_WA = "cards_to_wa";
export const DEMO_CLICK_DOWN_BUTTON = "click_down_button";
export const DEMO_CLICK_DOWN_ARROW = "click_down_arrow";
export const DEMO_CLICK_UP_ARROW = "click_up_arrow";

// demo guidance
export const DEMO_CLICK_DEMO_GUIDANCE = "click_demo_guidance";
export const DEMO_PLAY_DEMO_GUIDANCE = "play_demo_guidance";

// tooltip: workflow
export const DEMO_CLICK_CLOSE = "click_close";
export const DEMO_TOOLTIP_CLOSE = "tooltip_close";
export const DEMO_TOOLTIP_NEXT = "tooltip_next";
export const DEMO_CLICK_INFO = "click_info";
export const DEMO_EDIT_DRAFTER = "edit_drafter";
export const DEMO_ADD_WORKFLOW_CASE = "add_workflow_case";
export const DEMO_LOGIN_FROM_POPUP = "login_from_popup";
export const DEMO_REGISTER_FROM_POPUP = "register_from_popup";

// tooltip: user & teams
export const DEMO_ENTER_USER_AND_TEAMS = "enter_user_and_teams";
export const DEMO_INVITE_USER = "invite_user";
export const DEMO_CLICK_CANCEL = "click_cancel";
export const DEMO_POPUP_REGISTER = "popup_register";

// tooltip: draft
export const DEMO_ENTER_DRAFT = "enter_draft";
export const DEMO_DELETE_DRAFT = "delete_draft";
export const DEMO_CLICK_CONTINUE_EDIT = "click_continue_edit";

// tooltip: transactions
export const DEMO_ENTER_TRANSACTION = "enter_transaction";
export const DEMO_CLICK_EXPORT_REPORT = "click_export_report";
export const DEMO_CHOOSE_EXPORT_REPORT = "choose_export_report";

// TASK
// release transaction
export const DEMO_ENTER_TASK = "enter_task";
export const DEMO_RELEASE_TASK = "release_task";
export const DEMO_CLOSE_RELEASE_TASK = "close_release_task";
export const DEMO_REJECT_RELEASE_TASK = "reject_release_task";
export const DEMO_BACK_RELEASE_TASK = "back_release_task";
export const DEMO_MULTI_RELEASE_TASK = "multi_release_task";
export const DEMO_BACK_MULTI_RELEASE_TASK = "back_multi_release_task";
export const DEMO_CLOSE_MULTI_RELEASE_TASK = "close_multi_release_task";

// approve transaction
export const DEMO_APPROVE_TASK = "approve_task";
export const DEMO_CLOSE_APPROVE_TASK = "close_approve_task";
export const DEMO_REJECT_APPROVE_TASK = "reject_approve_task";
export const DEMO_BACK_APPROVE_TASK = "back_approve_task";
export const DEMO_MULTI_APPROVE_TASK = "multi_approve_task";
export const DEMO_BACK_MULTI_APPROVE_TASK = "back_multi_approve_task";
export const DEMO_CLOSE_MULTI_APPROVE_TASK = "close_multi_approve_task";

// TRANSACTIONS
// release
export const DEMO_RELEASE_TRANSACTION = "release_transaction";
export const DEMO_CLOSE_RELEASE_TRANSACTION = "close_release_transaction";
export const DEMO_REJECT_RELEASE_TRANSACTION = "reject_release_transaction";
export const DEMO_BACK_RELEASE_TRANSACTION = "back_release_transaction";
export const DEMO_MULTI_RELEASE_TRANSACTION = "multi_release_transaction";
export const DEMO_BACK_MULTI_RELEASE_TRANSACTION =
  "back_multi_release_transaction";
export const DEMO_CLOSE_MULTI_RELEASE_TRANSACTION =
  "close_multi_release_transaction";
// approve
export const DEMO_APPROVE_TRANSACTION = "approve_transaction";
export const DEMO_CLOSE_APPROVE_TRANSACTION = "close_approve_transaction";
export const DEMO_REJECT_APPROVE_TRANSACTION = "reject_approve_transaction";
export const DEMO_BACK_APPROVE_TRANSACTION = "back_approve_transaction";
export const DEMO_MULTI_APPROVE_TRANSACTION = "multi_approve_transaction";
export const DEMO_CLOSE_MULTI_APPROVE_TRANSACTION =
  "close_multi_approve_transaction";
export const DEMO_BACK_MULTI_APPROVE_TRANSACTION =
  "back_multi_approve_transaction";

// expired page
export const DEMO_LOGIN_FROM_EXPIRED = "login_from_expired";
export const DEMO_REGISTER_FROM_EXPIRED = "register_from_expired";

// topup
export const DEMO_CLICK_CONTINUE = "click_continue";
export const DEMO_CLICK_BACK = "click_back";

// guided tour
export const DEMO_GUIDE_ME_GUIDANCE = "guide_me_guidance";
export const DEMO_EXPLORE_BY_MYSELF = "explore_by_myself";
// export const DEMO_GUIDED_TOUR_NEXT = "guided_tour_next";
export const DEMO_PAUSE_GUIDED_TOUR = "pause_guided_tour";
export const DEMO_CLICK_NEXT = "click_next";
export const DEMO_PLAY_GUIDED_TOUR = "play_guided_tour";
export const DEMO_GUIDED_TOUR_BACK = "guided_tour_back";
export const DEMO_GUIDED_TOUR_NEXT = "guided_tour_next";
export const DEMO_CHOOSE_EXCEL_TEMPLATE = "choose_excel_template";
export const DEMO_CHOOSE_EMPTY_TABLE = "choose_empty_table";
export const DEMO_CLOSE_PAGE = "close_page";
// export const DEMO_CLICK_BACK = "click_back";
export const DEMO_CANCEL_CLOSE_PAGE = "cancel_close_page";
export const DEMO_CONFIRM_CLOSE_PAGE = "confirm_close_page";
export const DEMO_DOWNLOAD_TEMPLATE = "download_template";
export const DEMO_CREATE_ANOTHER = "create_another";
export const DEMO_ADD_INVOICE = "add_invoice";
export const DEMO_CREATE_VIRTUAL_ACCOUNT = "create_virtual_account";
export const DEMO_IMPORT_EXCEL = "import_excel";
export const DEMO_ADD_EMPTY_ROW = "add_empty_row";
export const DEMO_UPLOAD_FILE = "upload_file";
export const DEMO_ADD_BANK_ACCOUNT = "add_bank_account";
export const DEMO_MOVE_TO_DRAFT = "move_to_draft";

