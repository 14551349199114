import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { fetch, useMutation } from "../../../tools/api";
import { rowDuplicateFilter } from "../../draftComponents/tools/helpers";
import { isArray, isEmpty } from "lodash";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";

export const useFetchVA = ({
  type,
  menu,
  detailUrl,
  resultFormatter = () => {},
}) => {
  const isIndex = type === "index";
  const isDetails = type === "details";
  const isArchived = isIndex && menu === "archived";
  const isVaBanks = type === "va_banks";
  const isAPI = menu === "api";
  const isAPITrx = menu === "api-trx";

  const PER_PAGE = 15;
  const [page, setPage] = useState(1);
  const [data, setData] = useState([]);
  const [isMaxedOut, setIsMaxedOut] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const currentPage = `&page=${page}`;

  const { query } = useRouter();
  const { vaBankOptions } = useConstants();
  const { searchbox, from_date, to_date, va_status, bank_name, runQuery } =
    query;

  const ransackDecider = () => {
    if (isAPI)
      return {
        status: "[va_status_eq_any]",
        searchbox: "[va_number_or_username_display_cont]",
        fromDate: "",
        toDate: "",
      };

    if (isAPITrx)
      return {
        status: "[settlement_status_eq_any]",
        searchbox: "[va_number_username_display_cont]",
        fromDate: "[created_at_gteq]",
        toDate: "[created_at_lteq]",
      };

    return {
      status: "[status_eq_any]",
      searchbox: "[bank_or_reference_id_or_username_display_or_number_cont]",
      fromDate: "[expiration_date_gteq]",
      toDate: "[expiration_date_lteq]",
    };
  };

  const multiselectQueryDecider = (type, queryArray, queryRansack) => {
    const isBank = type === "bank";
    const isStatus = type === "status";
    const isQueryArray = isArray(queryArray);

    let array = [];
    let query = "";
    if (isQueryArray) {
      array = queryArray;
    } else {
      array = [queryArray];
    }

    if (isBank) {
      array?.forEach((item) => {
        if (isAPI) {
          const code =
            vaBankOptions.find(({ label }) => label === item)?.code || "";

          query += `&q[bank_code_cont_any][]=${code}`;
        } else {
          query += `&q[${queryRansack}][]=${item}`;
        }
      });
    }

    if (isStatus) {
      array?.forEach((item) => {
        const itemLC = item.toLowerCase() || "";
        if (itemLC === "expired") {
          query += `&q[${queryRansack}][]=${isAPI ? "EXPIRED" : "expired"}`;
          query += `&q[${queryRansack}][]=${isAPI ? "COMPLETE" : "expired"}`;
        }

        if (itemLC === "need update") {
          query += `&q[${queryRansack}][]=${
            isAPI ? "LIMIT_REACHED" : "limit_reached"
          }`;
          query += `&q[${queryRansack}][]=${
            isAPI ? "STATIC_TRX_EXPIRED" : "static_trx_expired"
          }`;
        }

        if (itemLC === "active") {
          query += `&q[${queryRansack}][]=${
            isAPI ? "WAITING_PAYMENT" : "waiting_payment"
          }`;
          query += `&q[${queryRansack}][]=${
            isAPI ? "PAYMENT_DETECTED" : "payment_detected"
          }`;
        }

        if (itemLC === "deactivated") {
          query += `&q[${queryRansack}][]=${isAPI ? "ARCHIVED" : "archived"}`;
        }

        if (itemLC === "success") {
          query += `&q[${queryRansack}][]=SUCCESS`;
        }

        if (itemLC === "waiting") {
          query += `&q[${queryRansack}][]=WAITING`;
        }
      });
    }

    return query;
  };

  const queryHandler = () => {
    const searchQuery = searchbox
      ? `&q${ransackDecider().searchbox}=${searchbox}`
      : "";
    const fromDateQuery = from_date
      ? `&q${ransackDecider().fromDate}=${from_date}`
      : "";
    const toDateQuery = to_date
      ? `&q${ransackDecider().toDate}=${to_date}`
      : "";
    const statusQuery = !isEmpty(va_status)
      ? multiselectQueryDecider("status", va_status, ransackDecider().status)
      : "";
    const bankNameQuery = !isEmpty(bank_name)
      ? multiselectQueryDecider("bank", bank_name, "bank_cont_any")
      : "";
    const queryUrl = `${searchQuery}${fromDateQuery}${toDateQuery}${statusQuery}${bankNameQuery}`;
    return queryUrl;
  };

  const urlDecider = () => {
    if (isAPI)
      return `/collection_virtual_accounts/index_va_api?per_page=${PER_PAGE}`;
    if (isAPITrx)
      return `/collection_va_trxes/index_va_api?per_page=${PER_PAGE}`;
    if (isIndex)
      return `/collection_virtual_accounts?per_page=${PER_PAGE}&${
        isArchived ? "q[status_eq]=archived" : "q[status_not_eq]=archived"
      }`;
    if (isVaBanks) return `/collection_va_banks?per_page=${PER_PAGE}`;
  };

  const filterQuery = queryHandler();
  const [url, setUrl] = useState(`${urlDecider()}` + filterQuery + currentPage);

  const {
    data: result,
    loading,
    refetch,
  } = fetch({
    url: isDetails ? detailUrl : url,
    formatter: resultFormatter,
    afterSuccess: (res) => {
      if (res.data.length < PER_PAGE) setIsMaxedOut(true);
      setData((prev) => {
        let newArray;
        if (page == 1) {
          newArray = res.data;
        } else {
          newArray = [...prev, ...res.data];
        }

        if (isDetails) return newArray;
        const uniqueArray = rowDuplicateFilter(newArray);
        return uniqueArray;
      });
    },
  });

  const nextPage = () => {
    if (isMaxedOut) return;
    if (loading) return;
    if (isDeleting) return;
    setPage((p) => p + 1);
  };

  const afterDelete = () => {
    setIsDeleting(true);
    setData([]);
    setPage(1);
    setUrl(urlDecider() + "&page=1");
  };

  useEffect(() => {
    if (!isDeleting) return;
    refetch();
    setIsDeleting(false);
  }, [isDeleting]);

  useEffect(() => {
    if (page == 1) return;
    setUrl(urlDecider() + filterQuery + currentPage);
  }, [page]);

  useEffect(() => {
    if (runQuery === false || runQuery == undefined) return;
    setData([]);
    setPage(1);
    setUrl(`${urlDecider()}${queryHandler()}&page=1`);
    setIsMaxedOut(false);
  }, [runQuery]);

  useEffect(() => {
    refetch();
  }, [url]);

  useEffect(() => {
    setData([]);
    setIsMaxedOut(false);
    setPage(1);
    setUrl(urlDecider());
  }, [menu]);

  return { data, loading, nextPage, refetch, afterDelete, setData };
};

export const useDeactivateVa = ({ vaId, afterSuccess = () => {} }) => {
  const { mutation, result, loading } = useMutation({
    url: `/collection_virtual_accounts/${vaId}/archive`,
    method: "put",
    afterSuccess,
  });

  return { mutation, result, loading };
};

export const useEditVa = ({ vaId, afterSuccess = () => {} }) => {
  const { mutation, result, loading } = useMutation({
    url: `/collection_virtual_accounts/${vaId}`,
    method: "put",
    afterSuccess,
  });

  return { mutation, result, loading };
};

export const useVaAccess = () => {
  const { isManagingTeams, isAdminOrSuperAdmin, isEmployee, isBookKeeper } =
    getUserRole();

  const isAllowed = isAdminOrSuperAdmin || isBookKeeper;

  // useEffect(() => {
  //   if (isAllowed) return;

  //   push("/dashboard");
  // }, [isAdminOrSuperAdmin, isBookKeeper]);

  return { isAllowed };
};

export const useVaFlag = () => {
  const { user, refetchUser } = useGetAuth();
  const { all_flags } = user ?? {};

  const flags = all_flags ?? [];

  const { mutation } = useMutation({
    method: "post",
    url: `/business_users/${user?.id}/create_user_flag`,
    afterSuccess: () => {
      refetchUser();
    },
  });

  const vaFlags = {
    hasSeenVaPage: "SEEN_VA_PAGE",
    hasIntroducedVa: "SHOWN_VA_INTRO",
  };

  const hasSeenVaPage = flags.includes(vaFlags.hasSeenVaPage);
  const hasIntroducedVa = flags.includes(vaFlags.hasIntroducedVa);

  const addVaFlag = async (flag) => {
    const isExist = flags.includes(flag);
    if (isExist) return;
    await mutation({ flags: flag });
  };

  return {
    hasIntroducedVa,
    hasSeenVaPage,
    vaFlags,
    addVaFlag,
  };
};

export const useBankDetails = (bankName) => {
  const { vaBankOptions } = useConstants();
  const {
    label,
    code,
    value,
    closed_min_amount,
    closed_max_amount,
    payment_config,
    icon,
    iconSize,
  } = vaBankOptions?.find(({ label }) => label === bankName) || {};

  return {
    label,
    code,
    value,
    closed_min_amount,
    closed_max_amount,
    payment_config,
    icon,
    iconSize,
  };
};
