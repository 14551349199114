import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import {
  formatCurrency,
  unformatCurrency,
  useDebounce,
} from "../../../../components/tools";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { formatTransactionItems } from "../../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
import { apiBusiness, fetch } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import { gmt7 } from "../../../reimbursementComponents/data";
import { uploadFilesFormatter } from "../../components";
import {
  attachmentDataFormatter,
  getTotalAmountSummary,
  idrStringFormatter,
  invoiceFormatter,
} from "../../data/formatter";
import { invoicesValidation } from "../../data/parent";

export const useInvoiceSingle = ({ detailId, reset, register, setValue }) => {
  const {
    data: invoice,
    loading: invoiceLoading,
    refetch,
    setData,
  } = fetch({
    url: `/invoice_transactions/${detailId}`,
    formatter: ({ data, attachment_data, ...rest }) => {
      const [newData] = invoicesValidation(invoiceFormatter([data]));
      const newAttachmentData = attachmentDataFormatter(
        (attachment_data || []).map((item, index) => {
          const url = (newData?.supporting_documents_files || [])[index];
          return { ...item, url };
        })
      );

      const invoiceData = { ...newData, attachment_data: newAttachmentData };
      //reset(defaultValues);
      return invoiceData;
    },
    woInit: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (invoice?.id) {
      const {
        local_recipient,
        invoice_number,
        issued_date: issuedDateRaw,
        due_date: dueDateRaw,
        // description,
        amount,
        tax,
        total_amount,
        transfer_notes,
        attachment_data,
        transaction_items,
        transaction_taxes,
        recipient_detail_id,
      } = invoice;

      const formattedTransactionItems =
        formatTransactionItems(transaction_items);

      const formattedTaxItems = transaction_taxes?.map((item) => ({
        ...item,
        _destroy: false,
      }));

      const { description, category_id, name } = (function () {
        if (invoice_number === "INV123456") {
          return {
            description: "Office Rent Vista Mar 2024",
            category_id: 462,
            name: "Rent Expenses",
          };
        }
        return {
          description: "Office Stationery",
          category_id: 463,
          name: "Office Supplies",
        };
      })();

      const defaultValues = {
        invoice_number,
        issued_date: gmt7(issuedDateRaw),
        due_date: gmt7(dueDateRaw),
        name: local_recipient?.name,
        amount: `IDR ${formatCurrency(amount)}`,
        tax: `IDR ${formatCurrency(tax)}`,
        initial_amount: amount,
        transfer_notes,
        upload_file: attachment_data,
        transaction_items: formattedTransactionItems,
        transaction_taxes: formattedTaxItems,
        recipient_detail_id,
        description,
        transfer_notes: description,
        category: name,
      };

      reset(defaultValues);
      register("initial_amount");
    }
  }, [invoice]);

  return { invoice, invoiceLoading, refetch, setData };
};

export const useSubmitInvoice = ({
  invoiceData: invoice,
  afterSuccess,
  isMissingRequiredCategory,
}) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();
  const {
    id: invoiceId,
    local_recipient: localRecipient,
    attachment_data,
  } = invoice;
  const submit = async (val) => {
    try {
      if (isMissingRequiredCategory) return;
      setUpdateLoading(true);
      const {
        invoice_number,
        due_date,
        issued_date,
        amount: amountRaw,
        description,
        tax: taxRaw,
        upload_file = [],
        transaction_items: ti_raw,
        transaction_taxes: tt_raw,
        transfer_notes,
        recipient_detail_id,
        initial_amount,
        category,
      } = val;

      const amount = unformatCurrency(amountRaw);
      const tax = unformatCurrency(taxRaw);
      const transaction_items = ti_raw?.map(
        ({
          itemID: raw_id,
          item_quantity: quantity,
          price_per_item: price,
          total_price: totalPrice,
          item_name,
          _destroy: destroy,
        }) => {
          const newVal = {
            id: typeof raw_id == "number" ? raw_id : null,
            item_quantity: Number(quantity),
            price_per_item: Number(unformatCurrency(price)),
            total_price: Number(unformatCurrency(totalPrice)),
            _destroy: destroy ?? false,
            item_name,
          };

          return newVal;
        }
      );

      const transaction_taxes = tt_raw
        ?.map((tax) => {
          const idTypeInt = typeof tax?.id == "number";
          const idTypeStr = typeof tax?.id == "string";
          const isEdited = tax?.isEdited;
          const destroyTrue = tax?._destroy;
          if (idTypeInt && !destroyTrue && !isEdited) return {};
          if (idTypeStr && destroyTrue) return {};

          const {
            fee_tax_type,
            fee_tax_name,
            fee_tax_element,
            add_or_deduct,
            calculate_from,
            fee_tax_value: taxVal,
            _destroy,
          } = tax;

          const fee_tax_value =
            typeof taxVal == "number"
              ? taxVal
              : Number(unformatCurrency(taxVal));
          const payloadTax = {
            fee_tax_type,
            fee_tax_name,
            fee_tax_element,
            add_or_deduct,
            calculate_from,
            fee_tax_value,
            _destroy: _destroy ?? false,
          };
          if (idTypeInt) payloadTax.id = tax?.id;
          return payloadTax;
        })
        ?.filter((item) => !isEmpty(item));

      const mainPayload = {
        invoice_number,
        issued_date,
        due_date,
        description,
        recipient_detail_id,
        transaction_items_attributes: transaction_items,
        transaction_taxes_attributes: transaction_taxes,
        transfer_notes,
        // amount,
      };

      const updateInvoice = async (data) =>
        await apiBusiness.put(`/invoice_transactions/${invoiceId}`, data);
      // const verifyLocalRecipient = async (data) =>
      //   await apiBusiness.put(`/local_recipients/${localRecipient?.id}`, data);

      // await verifyLocalRecipient(payLoadLr);

      // const payload = {
      //   recipient_name: vaName,
      //   invoice_number,
      //   due_date,
      //   issued_date,
      //   total_amount,
      //   description: descriptionRaw,
      //   amount,
      //   tax,
      //   transfer_notes,
      // };
      if (category) mainPayload.category_id = category?.id;

      //no need to send amount if initial amount equal to amount
      const isAmountMissmatch = amountMissMatchChecker(
        transaction_items,
        amount
      );

      if (Number(amount) != initial_amount || isAmountMissmatch)
        mainPayload.amount = amount;

      const uploadFile = upload_file ?? [];

      await updateInvoice(mainPayload);
      const { deletedFileIds, uploadFileNew } = uploadFilesFormatter(
        attachment_data,
        uploadFile
      );
      for (let i = 0; i < uploadFileNew.length; i++) {
        const file = uploadFileNew[i];
        const formData = new FormData();
        formData.append("supporting_documents", file);
        await updateInvoice(formData);
      }

      for (let i = 0; i < deletedFileIds.length; i++) {
        const deletedId = deletedFileIds[i];
        await apiBusiness.delete(
          `invoice_transactions/${invoiceId}/attachment/${deletedId}`
        );
      }
      eventsTracker("edit_invoice_page_save_edited_invoice");
      // await refetchMain();
      afterSuccess && afterSuccess();
    } catch (error) {
      setUpdateLoading(false);
      errorToasterApi(error);
      console.log(error);
    } finally {
      if (isMissingRequiredCategory) return;
      setUpdateLoading(false);
    }
  };

  return { submit, loading: updateLoading };
};

export const useInvoiceFormDebounce = (useFormObj) => {
  const { setValue, setError, clearErrors, getValues, errors, watch } =
    useFormObj;
  const totalAmountError = errors["total_amount"];
  const { errorSnackBar } = ToasterHook();
  const {
    amount: amountOnForm,
    tax: taxOnForm,
    total_amount: totalAmountOnForm,
  } = getValues();
  const getAmount = useDebounce(amountOnForm, 50) ?? 0;
  // const getTax = useDebounce(taxOnForm, 50) ?? 0;
  // const getTotalAmount = useDebounce(totalAmountOnForm, 50) ?? 0;

  const format = (val) => Number(unformatCurrency(val));
  const formatAmount = format(amountOnForm);
  const formatTax = format(taxOnForm);
  const formatTA = format(totalAmountOnForm);
  const totalOnForm = formatTax + formatAmount;

  useEffect(() => {
    if (getAmount <= 0) return;
    setValue("amount", `IDR ${formatCurrency(getAmount)}`);
  }, [getAmount]);
  // useEffect(() => setValue("tax", `IDR ${formatCurrency(getTax)}`), [getTax]);
  // useEffect(
  //   () => setValue("total_amount", `IDR ${formatCurrency(getTotalAmount)}`),
  //   [getTotalAmount]
  // );

  // useEffect(() => {
  //   if (isafter) return errorSnackBar({ msg: "due date below" });
  // }, [issuedDateonForm, dueDateonForm]);

  // useEffect(() => {
  //   const errorEmpty = totalAmountError?.type == "is-ok";
  //   const errorBelow10k = totalAmountError?.type == "is-above-10k";

  //   const isSafe = !errorEmpty && !errorBelow10k;
  //   // if (totalOnForm === formatTA && isSafe) return clearErrors("total_amount");
  //   if (totalOnForm != formatTA)
  //     return setError("total_amount", {
  //       message: `The total amount should be IDR ${formatCurrency(
  //         totalOnForm
  //       )} (Subtotal + VAT)`,
  //     });
  // }, [totalOnForm, totalAmountOnForm]);
};

export const useAutoFormatRow = ({ useFormObj, index, parentName }) => {
  const { setValue, watch, getValues } = useFormObj;

  const createName = (childName) => `${parentName}.${index}.${childName}`;

  const totalPriceKey = createName("total_price");

  const customGetValue = (key) =>
    getValues([createName(key)])?.[parentName]?.[0]?.[key];

  const pricePerItemOnForm = customGetValue("price_per_item");
  const itemQuantityOnForm = customGetValue("item_quantity");

  const getPriceDebounce = useDebounce(pricePerItemOnForm, 500) ?? "IDR 0";

  // useEffect(() => {
  //   // console.log("gpd triggered");
  //   setValue(pricePerItemKey, `IDR ${formatCurrency(getPriceDebounce)}`);
  // }, [getPriceDebounce]);

  // const getTotalPriceDebounce = useDebounce(totalPriceOnForm, 500) ?? "IDR 0";

  // useEffect(() => {
  //   // console.log("gtpd triggered");
  //   setValue(totalPriceKey, `IDR ${formatCurrency(getTotalPriceDebounce)}`);
  // }, [getTotalPriceDebounce]);

  const priceOnRowValInt = unformatCurrency(getPriceDebounce);
  const itemQuantity = Number(itemQuantityOnForm);
  const autoCalcTotalPrice = useMemo(
    () => Number(priceOnRowValInt * itemQuantity),
    [priceOnRowValInt, itemQuantity]
  );
  console.log({
    autoCalcTotalPrice,
    index,
    priceOnRowValInt,
    itemQuantityOnForm,
  });
  useEffect(() => {
    setValue(totalPriceKey, idrStringFormatter(autoCalcTotalPrice));
    // const parentValueOnForm = getValues()?.[parentName] ?? [];
    const parentValueOnForm = watch(parentName);
    const newAmountValue = totalAmountOnFields(parentValueOnForm);

    setValue("amount", newAmountValue);
    setValue(totalPriceKey, idrStringFormatter(total));
  }, []);

  const getQtyDebounce = useDebounce(itemQuantityOnForm, 500) ?? "IDR 0";

  useEffect(() => {
    setsTotal();
  }, [getPriceDebounce, getQtyDebounce]);
};

export const totalAmountOnFields = (array) => {
  if (!array) return "IDR 0";
  const unformatArray = array?.map(
    ({ total_price: rawTotalPrice, _destroy, ...rest }) => {
      return {
        total_price: _destroy ? 0 : unformatCurrency(rawTotalPrice),
        ...rest,
      };
    }
  );
  return getTotalAmountSummary(unformatArray, "total_price")?.totalAmountString;
};

export const calculateTaxDetails = (fields, itemSubtotal) => {
  let arrayOfAmount = [];

  fields?.forEach(
    (
      {
        fee_tax_element,
        fee_tax_type,
        fee_tax_name,
        fee_tax_value,
        calculate_from,
        amount,
        add_or_deduct,
      },
      index
    ) => {
      //kalo amount exist pake amount
      // if (amount > 0)
      //   return arrayOfAmount.push({
      //     name: fee_tax_name,
      //     value: amount,
      //   });

      const isPercentage = fee_tax_element == "percentage";
      const constant = add_or_deduct == "add" ? 1 : -1;
      //jika dia fix amont maka langsung push
      if (!isPercentage)
        return arrayOfAmount.push({
          name: fee_tax_name,
          value: Number(fee_tax_value) * constant,
        });

      const percentage = Number(fee_tax_value) / 100;
      const amountcalc = calculate_from?.map(({ name, is_custom }) => {
        if (name == "items_subtotal" && !is_custom) {
          return Number(unformatCurrency(itemSubtotal)) * percentage;
        }

        const amountExist = arrayOfAmount?.find(
          ({ name: arrName }) => arrName == name
        );

        const existValue = arrayOfAmount
          ?.filter(({ name: arrName }) => arrName == name)
          ?.pop()?.value;
        if (amountExist) {
          return Number(existValue) * percentage;
        }

        const isItemSubtotal = name == "items_subtotal";
        if (isItemSubtotal)
          return Number(unformatCurrency(itemSubtotal)) * percentage;

        return existValue * percentage;
      });
      arrayOfAmount?.push({
        name: fee_tax_name,
        value: amountcalc?.reduce((a, b) => a + b, 0) * constant,
      });
    }
  );
  return arrayOfAmount;
};

export const useLineItemPagination = ({ fields }) => {
  const arrayOfIndex = fields
    ?.map(({ _destroy }, index) => ({ _destroy, index }))
    ?.filter(({ _destroy }) => !_destroy)
    ?.map(({ index }) => index);

  const [page, setPage] = useState(1);
  const perPage = 5;
  const totalPage = Math.ceil(arrayOfIndex?.length / perPage);

  const paginateIndices = () => {
    const startIndex = (page - 1) * perPage;
    const endIndex = startIndex + perPage;
    return arrayOfIndex.slice(startIndex, endIndex);
  };

  const canClickNext = page + 1 <= totalPage;
  const canClickPrev = page != 1;

  const handleNext = () => {
    setPage((p) => {
      if (p + 1 > totalPage) return p;
      return p + 1;
    });
  };
  const handlePrev = () => {
    setPage((p) => {
      if (p - 1 <= 0) return p;
      return p - 1;
    });
  };

  const handleAddNewItems = () => {
    const newItemIndex = arrayOfIndex?.length;
    const newItemPage = Math.ceil((newItemIndex + 1) / 5);
    if (page != newItemPage) setPage(newItemPage);
  };

  const afterDelete = () => {
    const currentArray = paginateIndices();
    const currentLength = currentArray?.length - 1;
    if (currentLength == 0) return handlePrev();
  };

  return {
    shownIndex: paginateIndices(),
    page,
    setPage,
    handleNext,
    handlePrev,
    canClickNext,
    canClickPrev,
    handleAddNewItems,
    afterDelete,
  };
};

export const createtaxDescription = (tax) => {
  const {
    id,
    fee_tax_name,
    fee_tax_value,
    fee_tax_element,
    add_or_deduct,
    calculate_from,
  } = tax;
  const isAdd = add_or_deduct == "add";
  const isPercentage = fee_tax_element == "percentage";
  if (!isPercentage) return "(Fixed amount)";

  const addOrDeduct = titleCase(add_or_deduct);
  const percentageText = `${fee_tax_value}%`;
  let source = "";

  const singleSource = calculate_from?.length == 1;

  const lengthGTEQthree = calculate_from?.length >= 3;
  calculate_from?.forEach(({ name }, index) => {
    const isLast = index == calculate_from?.length - 1;
    let text = "";
    if (isLast && !singleSource) text += " and ";
    const capitalName = name?.toUpperCase();
    name == "items_subtotal"
      ? (text += "Subtotal Items")
      : (text += capitalName);

    if (lengthGTEQthree && !isLast) text += ", ";

    source += text;
  });

  return `(${addOrDeduct} ${percentageText} from ${source})`;
};

export const amountMissMatchChecker = (items, amount) => {
  if (isEmpty(items)) return !(0 == Number(amount));
  const itemWoDestroy = items?.filter(({ _destroy }) => !_destroy);
  const itemAmount = getTotalAmountSummary(
    itemWoDestroy,
    "total_price"
  )?.totalAmount;

  return !(Number(itemAmount) == Number(amount));
};

export const formatSubmitItems = (items) =>
  items?.map(
    ({
      itemID: raw_id,
      item_quantity: quantity,
      price_per_item: price,
      total_price: totalPrice,
      item_name,
      _destroy: destroy,
    }) => {
      const newVal = {
        id: typeof raw_id == "number" ? raw_id : null,
        item_quantity: Number(quantity),
        price_per_item: Number(unformatCurrency(price)),
        total_price: Number(unformatCurrency(totalPrice)),
        _destroy: destroy ?? false,
        item_name,
      };

      return newVal;
    }
  );

export const formatSubmitTax = (taxes) =>
  taxes
    ?.map((tax) => {
      const idTypeInt = typeof tax?.id == "number";
      const idTypeStr = typeof tax?.id == "string";
      const isEdited = tax?.isEdited;
      const destroyTrue = tax?._destroy;
      if (idTypeInt && !destroyTrue && !isEdited) return {};
      if (idTypeStr && destroyTrue) return {};

      const {
        fee_tax_type,
        fee_tax_name,
        fee_tax_element,
        add_or_deduct,
        calculate_from,
        fee_tax_value: taxVal,
        _destroy,
      } = tax;

      const fee_tax_value =
        typeof taxVal == "number" ? taxVal : Number(unformatCurrency(taxVal));
      const payloadTax = {
        fee_tax_type,
        fee_tax_name,
        fee_tax_element,
        add_or_deduct,
        calculate_from,
        fee_tax_value,
        _destroy: _destroy ?? false,
      };
      if (idTypeInt) payloadTax.id = tax?.id;
      return payloadTax;
    })
    ?.filter((item) => !isEmpty(item));
