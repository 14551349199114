import { TooltipContent } from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { VA_FIRST_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${VA_FIRST_TARGET}`,
    content: (
      <TooltipContent
        title="Pick your preferred method"
        description="You can bulk upload virtual account details with Excel file or input it one by one in an empty table."
      />
    ),
    placement: "right",
    disableScrolling: false,
    eventPayload: {
      activity_type: "create_virtual_account",
      guided_tour_activity: "select_method",
    },
  },
];

const vaFirstPageProps = { steps };

export default vaFirstPageProps;
