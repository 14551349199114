import { useEffect } from "react";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { formatTransactionItems } from "../../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
import { fetch } from "../../../../tools/api";
import {
  attachmentDataFormatter,
  idrStringFormatter,
} from "../../../invoiceComponents/data/formatter";
import { gmt7 } from "../../data";
import { reimbursementFormatter } from "../../data/formatter";

export const useReimbursementSingle = ({
  detailId,
  reset,
  register,
  setValue,
  noReceipt = false,
}) => {
  const {
    data: reimbursement,
    loading: fetchLoading,
    refetch,
    setData: setDataRmb,
  } = fetch({
    url: `/reimbursement_receipts/${detailId}`,
    formatter: ({
      data,
      attachment_data = [],
      attachment_data_reimbursement_doc,
      ...rest
    }) => {
      const fileId = attachment_data_reimbursement_doc?.[0]?.id;
      const [newData] = reimbursementFormatter([data]);
      const newAttachmentData = attachmentDataFormatter(
        (attachment_data || []).map((item, index) => {
          const url = (newData?.supporting_documents_files || [])[index];
          return { ...item, url };
        })
      );

      const reimbursementData = {
        ...newData,
        fileId,
        attachment_data: newAttachmentData,
      };
      //reset(defaultValues);
      return reimbursementData;
    },
    woInit: true,
    defaultValue: {},
  });

  const { categories } = useConstants();
  useEffect(() => {
    if (reimbursement?.id) {
      const {
        merchant,
        purchased_date,
        amount,
        memo_text,
        attachment_data,
        transaction_taxes: tt,
        transaction_items: ti,
        // category_id,
        template_type,
        tax,
      } = reimbursement;

      const formattedTransactionItems = formatTransactionItems(ti);

      const formattedTaxItems = tt?.map((item) => ({
        ...item,
        _destroy: false,
      }));

      const {
        description,
        category_id,
        name: category_name,
      } = (function () {
        if (template_type === "transportation") {
          return {
            description:
              "Meeting with client 20 Mar 2024. Please approve, thanks!",
            category_id: 464,
            name: "Travel Expense",
          };
        }
        return {
          description:
            "Meeting with client 20 Mar 2024. Please approve, thanks!          ",
          category_id: 465,
          name: "Client Entertainment",
        };
      })();

      const defaultValues = {
        merchant,
        purchased_date: gmt7(purchased_date),
        amount: idrStringFormatter(amount),
        upload_file: attachment_data,
        // transaction_items: formattedTransactionItems,
        // transaction_taxes: formattedTaxItems,
        initial_amount: amount,
        description,
        // category: name
        // tax: idrStringFormatter(tax),
      };

      // if (memo_text) defaultValues.description = memo_text;
      if (category_id) {
        defaultValues.category = (categories ?? []).find(
          // ({ id }) => id === category_id
          ({ name }) => name === category_name
        );
      }
      if (noReceipt) {
        const keys = Object?.keys(defaultValues);
        keys?.forEach((key) => {
          // if (key === "description") {
          //   reset({description})
          // }
          register(key);
          setValue(key, defaultValues[key]);
        });
      } else {
        register("description");
        setValue("description", description);
        reset(defaultValues);
      }
      register("initial_amount");
      // register("amount");
      // register("merchant");
      // register("description");
      register("category");
    }
  }, [reimbursement]);

  return {
    reimbursement,
    reimbursementFetchLoading: fetchLoading,
    refetch,
    setDataRmb,
  };
};
