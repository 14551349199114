import { useRouter } from "next/router";
import { useMutation } from "../../tools/api";

export const useForgotPin = (isPush) => {
  const { push } = useRouter();

  const { mutation } = useMutation({
    method: "post",
    url: "/pin/otp_forgot",
    afterSuccess: () => isPush && push("reset-pin"),
  });

  return { reset: () => mutation({}) };
};
