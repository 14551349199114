import { noCase } from "change-case";
import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../../../assets/colors";
import { GothamRegular, TextInlineRegular } from "../../../../components/Text";

const DefaultButton = ({ backgroundColor, style }) => (
  <div
    style={{
      width: 8,
      height: 8,
      borderRadius: 4,
      backgroundColor,
      position: "absolute",
      left: "calc(50% - 4px)",
      top: 7,
      ...style,
    }}
  />
);

const DefaultLine = ({
  className = "",
  height = 0,
  lineHeight = 0,
  isActive,
  isPinkButton,
  isRedButton,
  isGreenButton,
  style,
}) => {
  const DotDecider = () => {
    if (isRedButton || isPinkButton || isGreenButton) {
      const colorDecider = () => {
        if (isRedButton) return colors.redE7;
        if (isGreenButton) return colors.green06;
        return colors.pink;
      };
      const color = colorDecider();
      return (
        <DefaultButton
          backgroundColor="white"
          style={{
            border: `2px solid ${colorDecider()}`,
            width: 12,
            height: 12,
            borderRadius: 8,
            left: "calc(50% - 6px)",
            top: 5,
            ...style,
          }}
        />
      );
    }
    if (isActive) return <DefaultButton backgroundColor={colors.blue10} />;

    return <DefaultButton backgroundColor="#BBBBC0" />;
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        style={{
          width: 36,
          position: "absolute",
          height,
        }}
        className={`d-flex justify-content-center ${className}`}
      >
        <DotDecider />
        <div
          style={{
            width: 2,
            backgroundColor: "#d9d9d9",
            height: lineHeight,
            ...style,
          }}
        />
      </div>
      <div style={{ width: 36 }}></div>
    </div>
  );
};

const Line = ({
  isFirst,
  isLast,
  isActive,
  isPinkButton,
  isRedButton,
  isGreenButton,
}) => {
  const height = "calc(100% + 16px)";
  const defaultProps = { isActive, isPinkButton, isRedButton, isGreenButton };

  if (isFirst)
    return (
      <DefaultLine
        {...defaultProps}
        height={height}
        className="align-items-end"
        lineHeight={"calc(100% - 8px)"}
      />
    );
  if (isLast)
    return (
      <DefaultLine
        {...defaultProps}
        lineHeight={isRedButton && "calc(100% + 14px)"}
      />
    );
  return (
    <DefaultLine
      {...defaultProps}
      height={height}
      lineHeight={"calc(100% + 14px)"}
    />
  );
};

const Container = ({ children, style }) => (
  <div
    className="d-flex w-100"
    style={{ paddingBottom: 16, position: "relative", ...style }}
  >
    {children}
  </div>
);

export const ReasonNotes = ({
  text = "",
  style = { margin: "-12px 0px 16px 36px" },
}) => {
  return (
    <div style={style}>
      <GothamRegular
        className="font12"
        style={{ color: jackColors.neutral900 }}
      >
        Reason: <b />
      </GothamRegular>
      <GothamRegular
        className="font12"
        style={{
          color: jackColors.neutral900,
          maxWidth: "330px",
          // whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {text}
      </GothamRegular>
    </div>
  );
};

export const StatusPayrollLine = ({
  array = [],
  style,
  isModal = false,
  loading,
}) => (
  <div style={style}>
    {array.map((item, index) => {
      const isFirst = index == 0;
      const isLast = index == array.length - 1;

      const {
        text,
        isActive,
        date,
        orangeText,
        data,
        isRedButton,
        isGreenButton,
        isReleased,
        color = "",
        styleCustom,
        customComponents,
      } = item;

      if (loading)
        return (
          <Skeleton style={{ width: "100%", height: 32, marginBottom: 12 }} />
        );

      const isPinkButton =
        array
          .map(({ isActive }, index) => {
            if (isActive) return index;
            return null;
          })
          .filter((index) => index)
          .pop() == index;

      const isArray = data;

      if (!text && !data) return null;

      const Text = ({ item, children }) => {
        const { isRed, isOrange, isGreen, isApproverLine } = item || {};
        const splittedText = String(children).split(" ");

        if (color) {
          return (
            <GothamRegular style={{ color: color, lineHeight: "20px" }}>
              {children}
            </GothamRegular>
          );
        }

        if (isRed) {
          const redText = splittedText.shift();
          const redRegularText = splittedText.join(" ");
          return (
            <GothamRegular>
              <TextInlineRegular style={{ color: colors.redE7 }}>
                {redText}{" "}
              </TextInlineRegular>
              {redRegularText}
            </GothamRegular>
          );
        }

        if (isOrange || orangeText) {
          const orangeText = splittedText.shift();
          const regularText = splittedText.join(" ");
          return (
            <GothamRegular>
              <TextInlineRegular style={{ color: colors.orangeFF }}>
                {orangeText}{" "}
              </TextInlineRegular>
              {regularText}
            </GothamRegular>
          );
        }

        if (isGreen) {
          const orangeText = splittedText.shift();
          const regularText = splittedText.join(" ");
          return (
            <GothamRegular>
              <TextInlineRegular style={{ color: colors.green06 }}>
                {orangeText}{" "}
              </TextInlineRegular>
              {regularText}
            </GothamRegular>
          );
        }

        if (isReleased) {
          const splittedReleased = String(children).split("released");
          return (
            <GothamRegular>
              {splittedReleased[0]}
              <TextInlineRegular style={{ color: colors.green06 }}>
                released
              </TextInlineRegular>
              {splittedReleased[1]}
            </GothamRegular>
          );
        }

        if (isActive || isApproverLine)
          return <GothamRegular>{children}</GothamRegular>;

        return (
          <GothamRegular style={{ color: "#BBBBC0" }}>{children}</GothamRegular>
        );
      };

      const lineProps = {
        isFirst,
        isLast,
        isActive,
        isPinkButton,
        isRedButton,
        isGreenButton,
      };

      if (isArray) {
        if (!data.length) return null;
        return (
          <Container key={index}>
            <Line {...lineProps} />
            <div style={{ width: "100%" }}>
              {data.map((item, index) => {
                const { text, date } = item;
                const isLast = index == data.length - 1;
                return (
                  <div
                    key={index}
                    className={
                      isModal
                        ? "w-100"
                        : `d-flex justify-content-between w-100 align-items-stretch`
                    }
                    style={{ marginBottom: isLast ? 0 : 8 }}
                  >
                    <Text item={item}>{text}</Text>
                    <div style={{ whiteSpace: "nowrap" }}>
                      <GothamRegular
                        className="font12"
                        style={{ lineHeight: "20px", color: colors.grey6c }}
                      >
                        {date}
                      </GothamRegular>
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>
        );
      }

      return (
        <Container key={index} style={styleCustom}>
          <Line {...lineProps} />
          <div
            className={
              isModal
                ? "w-100"
                : `d-flex justify-content-between w-100 align-items-center`
            }
          >
            {customComponents ? customComponents : <Text>{text}</Text>}
            <div style={{ whiteSpace: "nowrap" }}>
              <GothamRegular
                className="font12"
                style={{ lineHeight: "20px", color: colors.grey6c }}
              >
                {date}
              </GothamRegular>
            </div>
          </div>
        </Container>
      );
    })}
  </div>
);
