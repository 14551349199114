import React, { memo, useState, useEffect } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { GothamMedium, GothamRegular, TypeFezNeueLight } from "./Text";
import left from "../assets/images/another-arrow-left.svg";
import right from "../assets/images/another-arrow-right.svg";
import download from "../assets/images/another-download.svg";
import { downloadApi } from "../tools/api";
import { CenteredModal } from "../modals/centeredComponents";
import { useModalHook } from "./Modals";
import { JackIcons } from "../assets/jackIcons/parent";
import { FaSpinner } from "react-icons/fa";
import { stringCutter, toBase64 } from "./tools";
import { decryptToken } from "../tools/api";
import { useGdrivehook } from "../contexts/GoogleDrivePreviewContext/parent";
import { DeleteReceiptButton } from "../pageComponents/reimbursementComponents/createPage/uploadPage/receiptForm";
import useEventsTracker from "../tools/hooks/useEventsTracker";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const Header = ({
  fileName,
  url,
  pdfPageNav,
  onClickReimbursement,
  onBack,
  isFullScreen,
  enablePreview,
  enableDownload = true,
  handlePreview,
  woHeader,
  leftHeaderComponent,
}) => {
  const trackEvent = useEventsTracker("PDFPreview", true);

  return (
    <div
      style={{
        backgroundColor: isFullScreen ? "none" : "#333639",
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
        padding: 13,
        height: 44,
        zIndex: 6000,
        display: "flex",
        position: "relative",
        justifyContent: "end",
        width: "100%",
      }}
    >
      {leftHeaderComponent && (
        <div style={{ width: "40%" }}>{leftHeaderComponent}</div>
      )}

      {isFullScreen && (
        <div
          style={{
            display: "flex",
            gap: "13px",
          }}
        >
          <JackIcons
            name="corner-up-left-outline"
            fill="white"
            onClick={onBack}
            style={{ cursor: "pointer" }}
          />
          <GothamMedium
            style={{
              color: "white",
              width: "300px",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {stringCutter(fileName, 30)}
          </GothamMedium>
        </div>
      )}
      {pdfPageNav && !isFullScreen && (
        <div
          style={{
            position: "absolute",
            left: "50%",
            bottom: 8,
            transform: "translate(-50%,0)",
          }}
        >
          {pdfPageNav}
        </div>
      )}
      <div
        style={{
          width: `100%`,
        }}
        className="d-flex justify-content-end align-items-center"
      >
        {enablePreview && (
          <div style={{ marginRight: 12 }}>
            <JackIcons
              style={{ height: 20, width: "auto" }}
              name="expand_outline"
              className="darkhover"
              onClick={handlePreview}
            />
          </div>
        )}
        {enableDownload && (
          <div style={{ marginRight: onClickReimbursement && 12 }}>
            <JackIcons
              style={{ height: 20, width: "auto" }}
              name="download_outline"
              className="darkhover"
              onClick={() => {
                downloadApi({
                  url,
                  fileName,
                  isAxios: woHeader,
                });
                trackEvent("download", { url, fileName });
              }}
            />
          </div>
        )}
        {onClickReimbursement && (
          <DeleteReceiptButton onClick={onClickReimbursement} />
        )}
      </div>
    </div>
  );
};

export const PDFOrImageViewer = memo(
  ({
    url,
    file: fileProps = null,
    noZoom = false,
    onClickReimbursement,
    isFullScreen = false,
    onBack,
    woHeader = true,
    customDocStyle,
    enablePreview,
    leftHeaderComponent = null,
    fileName: fileNameProps,
  }) => {
    const docStyle = {
      width: "100%",
      height: "calc(100% - 44px)",
      overflowX: "scroll",
      overflowY: "scroll",
      display: "grid",
      placeItems: "center",
      ...customDocStyle,
    };

    const fileName =
      fileProps?.name || String(url).split("/").pop().split("?").shift();
    const isFileRaw = !!fileProps?.name;
    const fileNameformatted = fileName.split("%20").join(" ");

    const fileType = String(fileName).split(".").pop();

    const isPDF = String(fileName).split(".").pop().includes("pdf");

    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [numPages, setNumPages] = useState(0);
    const [file, setFile] = useState(fileProps);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [scale, setScale] = useState(1);
    const disableZoomIn = scale == 1.5;
    const disableZoomOut = scale == 1;

    const documentWidth = width - 58 * 2;
    const documentHeight = isPDF ? 1.4142 * documentWidth : 0;

    useEffect(async () => {
      try {
        if (url) {
          setLoading(true);
          const getToken = localStorage.getItem("token");
          const decryptedToken = getToken ? decryptToken(getToken) : "";
          const headers = {
            Authorization: decryptedToken,
          };
          let blob = await fetch(url, {
            ...(woHeader ? {} : { headers }),
          }).then((r) => r.blob());
          const file = await toBase64(blob);
          setFile(file);
          setLoading(false);
        }
      } catch (error) {
        console.log("error:", error);
      }
    }, [url]);

    const { show } = useGdrivehook();

    const handlePreview = () => {
      const [https, _, apiType, ...urls] = url.split("/");
      const rawUrl = "/" + urls.join("/");

      const files = [{ name: fileNameformatted, type: fileType, url: rawUrl }];
      return show({ files });
    };

    const headerProps = {
      fileName: fileNameProps || fileNameformatted || fileName,
      url,
      noZoom,
      enablePreview,
      onClickReimbursement,
      enableDownload: !isFileRaw,
      handlePreview,
      onBack: () => {
        onBack();
        setFile(null);
      },
      woHeader,
      leftHeaderComponent,
    };

    const Container = ({ children }) => (
      <div
        className="w-100 d-flex align-items-center preventblueblock"
        style={{
          flexDirection: "column",
          backgroundColor: isFullScreen ? "rgba(0, 0, 0, 0.8)" : "#f4f4f4",
          height: "100%",
        }}
        ref={(e) => {
          if (!e) return;
          if (width) return;
          setWidth(e.clientWidth);
          setHeight(e.clientHeight);
        }}
      >
        {children}
      </div>
    );

    if (isPDF)
      return (
        <Container>
          <Header
            {...headerProps}
            pdfPageNav={
              <div className="d-flex align-items-center">
                <JackIcons
                  name="chevron_Left_Outline"
                  fill="white"
                  onClick={() =>
                    setPage((prev) => {
                      if (prev <= 1) return 1;
                      return prev - 1;
                    })
                  }
                  style={{ opacity: numPages == 1 && 0.5 }}
                />
                <GothamRegular
                  style={{
                    marginLeft: 20,
                    marginRight: 20,
                    color: "white",
                    opacity: numPages == 1 && 0.5,
                  }}
                >
                  Page {page} / {numPages}
                </GothamRegular>
                <JackIcons
                  name="chevron_Right_Outline"
                  fill="white"
                  onClick={() =>
                    setPage((prev) => {
                      if (prev >= numPages) return numPages;
                      return prev + 1;
                    })
                  }
                  style={{ opacity: numPages == 1 && 0.5 }}
                />
              </div>
            }
            isFullScreen={isFullScreen}
            woHeader={woHeader}
          />
          <div style={docStyle} className="hideScrollbar">
            <DocumentMemoize
              url={url}
              page={page}
              documentHeight={(height - 44) * 0.685}
              scale={scale}
              numPages={numPages}
              setNumPages={setNumPages}
              isFullScreen={isFullScreen}
              file={file}
              isFileRaw={isFileRaw}
              isLoading={loading}
            />
          </div>
          {!noZoom && (
            <ZoomBar
              setScale={setScale}
              disableZoomIn={disableZoomIn}
              disableZoomOut={disableZoomOut}
            />
          )}
        </Container>
      );

    return (
      <Container>
        <Header {...headerProps} isFullScreen={isFullScreen} />
        <div style={docStyle} className="hideScrollbar">
          <img
            src={url}
            style={{
              zIndex: 2,
              transform: `scale(${scale})`,
              width: "auto",
              height: "auto",
              maxWidth: "87%",
              maxHeight: (height - 44) * 0.685,
              objectFit: "contain",
              transition: "transform .2s",
              // position: "absolute",
              // bottom: "50%",
              // left: "50%",
            }}
          />
        </div>
        {!noZoom && (
          <ZoomBar
            setScale={setScale}
            disableZoomIn={disableZoomIn}
            disableZoomOut={disableZoomOut}
          />
        )}
      </Container>
    );
  }
);

const DocumentMemoize = React.memo(
  ({
    page,
    documentWidth,
    documentHeight,
    scale,
    numPages,
    setNumPages,
    isFullScreen,
    file,
    isFileRaw,
    isLoading,
  }) => {
    const isMultiplePages = numPages > 1;
    return (
      <>
        <Document
          loading={
            isLoading && (
              <FaSpinner className="icon-spin" color="white" size={24} />
            )
          }
          file={
            isFileRaw
              ? file
              : {
                  url: file,
                }
          }
          onLoadSuccess={({ numPages: numPagesProps }) => {
            // if (numPages) return;
            setNumPages(numPagesProps);
          }}
        >
          {isFullScreen && isMultiplePages ? (
            <div
              style={{
                margin: "100px 0",
                display: "flex",
                flexDirection: "column",
                gap: "24px",
                height: "calc(100vh - 100px)",
              }}
            >
              {Array.from(new Array(numPages), (el, index) => (
                <Page
                  key={`page_${index + 1}`}
                  scale={scale}
                  loading={false}
                  height={documentHeight}
                  pageNumber={index + 1}
                />
              ))}
            </div>
          ) : (
            <Page
              scale={scale}
              pageNumber={page}
              loading={false}
              height={documentHeight}
              // width={documentWidth}
              className
            />
          )}
        </Document>
      </>
    );
  }
);

const ZoomBar = ({ setScale, disableZoomIn, disableZoomOut }) => (
  <div
    className="zoom-container"
    style={{
      position: "absolute",
      bottom: 24,
    }}
  >
    <JackIcons
      name="plus_Outline"
      onClick={() => {
        if (disableZoomIn) return;
        setScale((p) => p + 0.1);
      }}
      fill={disableZoomIn ? "#BBBBC0" : "white"}
      style={{ cursor: "pointer" }}
    />
    <JackIcons
      name="minus"
      onClick={() => {
        if (disableZoomOut) return;
        setScale((p) => p - 0.1);
      }}
      style={{ cursor: "pointer" }}
      fill={disableZoomOut ? "#BBBBC0" : "white"}
    />
    <TypeFezNeueLight
      className="font12"
      style={{ color: "white", cursor: "pointer" }}
      onClick={() => setScale(1)}
    >
      Reset
    </TypeFezNeueLight>
  </div>
);
