import { titleCase } from "change-case";
import React, { useEffect } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { useFileViewer } from "../../../contexts/FilePreviewContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import { showPDFOrJPEGHooks } from "../../inputs";
import { GothamMedium, GothamRegular } from "../../Text";
import { Files, SpanError } from "./components";
import {
  fileTypesTranslatorArrayToString,
  useSetsValueFileInput,
} from "./logics";
import {
  BILL_MODULE,
  REIMBURSEMENT_MODULE,
} from "../../../pageComponents/productDemoComponents/constants";
import { useModalHook } from "../../Modals";
import DemoGeneralModal from "../../../pageComponents/productDemoComponents/components/DemoGeneralModal";

const fileDecider = (module) => {
  switch (module) {
    case BILL_MODULE:
      return {
        dataTransfer: {
          files: [
            {
              name: "bill_payment_supporting.png",
              size: 1200000,
              type: "image/png",
              template_type: "rent",
            },
          ],
        },
      };
    case REIMBURSEMENT_MODULE:
      return {
        dataTransfer: {
          files: [
            {
              name: "reimbursement_supporting_meal.png",
              size: 1200000,
              type: "image/png",
              template_type: "transportation",
            },
            {
              name: "reimbursement_supporting_transportation.png",
              size: 1200000,
              type: "image/png",
              template_type: "meal",
            },
          ],
        },
      };
    default:
      return { files: [] };
  }
};

export const FileInputButtonJack = ({
  useFormObj,
  name,
  label: labelProps,
  multiple = false,
  maxFiles = 5,
  maxSizeMB = 10,
  fileTypes = ["image/png", "application/pdf"],
  showLabel = true,
  enablePreview = false,
  module = REIMBURSEMENT_MODULE,
}) => {
  const fileTypeString = fileTypesTranslatorArrayToString(fileTypes);
  const label = labelProps || titleCase(name);
  const { register, watch, setValue } = useFormObj;
  const { setsValue, ref } = useSetsValueFileInput({
    fileTypes,
    maxFiles,
    maxSizeMB,
    multiple,
    name,
    useFormObj,
  });

  useEffect(() => {
    register({ name });
  }, []);

  const currValue = watch(name);
  const { show } = useGdrivehook();
  const filesDecider = () => {
    if (Array.isArray(currValue)) return currValue;
    if (currValue) return [currValue];
    return [];
  };
  const files = filesDecider();
  const handleShow = (index = 0) => show({ files, index });

  // demo purpose
  const { isOpen: isOpenRestrict, toggle: toggleRestrict } = useModalHook();
  const handleClickAddDocuments = () => {
    if (files?.length === 0) return setsValue(fileDecider(module));
    return toggleRestrict();
  };

  return (
    <div>
      {showLabel && <GothamMedium className="mb-3">{label}</GothamMedium>}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          marginBottom: 0,
        }}
        onClick={handleClickAddDocuments}
      >
        <label
          className="d-flex align-items-center justify-content-between hover"
          style={{
            border: `1px solid ${jackColors.greyE6}`,
            padding: "10px 0px",
            borderRadius: 4,
            width: 154,
            height: 40,
            marginBottom: 0,
          }}
        >
          <JackIcons
            name="plus-outline"
            fill={jackColors.black34}
            style={{ width: 18, height: "auto", marginLeft: 13.33 }}
          />
          <GothamMedium className="font14" style={{ marginRight: 10 }}>
            Add Documents
          </GothamMedium>
          {/* <input
            type="file"
            style={{ display: "none" }}
            multiple={multiple}
            onChange={(e) => {
              if (!e) return;
              setsValue(e);
            }}
          /> */}
        </label>
        <GothamRegular
          className="font10 ml-2"
          style={{ color: jackColors.grey90 }}
        >
          <SpanError>File types: {fileTypeString}</SpanError> <br />
          <SpanError>Max. upload: {maxFiles} files</SpanError>,{" "}
          <SpanError>{maxSizeMB}MB each</SpanError>
        </GothamRegular>
      </div>
      <Files
        files={files}
        multiple
        name={name}
        setValue={setValue}
        enablePreview={enablePreview}
        handleShow={handleShow}
        inputRef={ref}
      />
      <DemoGeneralModal isOpen={isOpenRestrict} toggle={toggleRestrict} />
    </div>
  );
};
