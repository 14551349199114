import "@material/react-text-field/dist/text-field.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/css/bootstrap.min.css";
import Script from "next/script";
import { useEffect } from "react";
import "react-datepicker/dist/react-datepicker.css";
import TagManager from "react-gtm-module";
import "react-toastify/dist/ReactToastify.css";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { isProduction } from "../components/tools";
import { AlertAttentionProvider } from "../contexts/AlertAttentionContext";
import { AlertContextProvider } from "../contexts/AlertContext/parent";
import { AuthProvider } from "../contexts/AuthContext";
import { DemoProvider } from "../contexts/DemoContext";
import GuidedTourProvider from "../contexts/GuidedTourContext";
import { ConstantsProvider } from "../contexts/ConstantsContext/parent";
import { DimensionProvider } from "../contexts/DimensionContext";
import { FilePreviewProvider } from "../contexts/FilePreviewContext/parent";
import { GoogleDrivePreviewProvider } from "../contexts/GoogleDrivePreviewContext/parent";
import { InvoiceProvider } from "../contexts/InvoiceContext";
import { LayoutProvider } from "../contexts/Layout/parent";
import TaskProvider from "../contexts/TaskContext/parent";
import { TimerProvider } from "../contexts/TimerContext";
import { ToasterProvider } from "../contexts/ToasterContext";
import ErrorBoundary from "../errorBoundaries";
import SEOComponents from "../pageComponents/script/seo";
import "../styles/badge.css";
import "../styles/button.css";
import "../styles/draftjs.css";
import "../styles/globals.css";
import "../styles/icon.css";
import "../styles/input.css";
import "../styles/modals.css";
import "../styles/sidebar.css";
import "../styles/slick.css";
import "../styles/summarydetails.css";
import "../styles/table.scss";
import "../styles/text.css";
import "../styles/toaster.css";
import "../styles/tooltip.css";
import "../styles/transition.css";
import { initEvents } from "../universalFunctions/events";

const MyApp = ({ Component, pageProps }) => {
  useSessionId();

  useEffect(initEvents, []);
  const __html = `(function(i, s, o, g, r, a, m, n) {
    i.moengage_object = r;
    t = {};
    q = function(f) {
      return function() {
        (i.moengage_q = i.moengage_q || []).push({ f: f, a: arguments });
      };
    };
    (f = [
      "track_event",
      "Web_Start_your_application",
      "Web_Signup",
      "Web_Want_BRE1_limitincrease_click",
      "Web_Happy_with_BRE1limit_click",
      "Web_Complete_Profile",
      "Web_On_bank_statement",
      "Web_Bank statement click_perfios",
      "Web_Bank statement click_manual",
      "Web_Suspend_bank_statement_perfios",
      "Web_Suspend_bank_statement_manual",
      "Web_Suspend_salaryslip",
      "Web_Smart_Repay_Click",
      "Web_downloadapp",
      "destroy_session",
      "add_unique_user_id",
      "moe_events",
      "call_web_push",
      "track",
      "location_type_attribute"
    ]),
      (h = { onsite: ["getData"] });
    for (k in f) {
      t[f[k]] = q(f[k]);
    }
    a = s.createElement(o);
    m = s.getElementsByTagName(o)[0];
    a.async = 1;
    a.src = g;
    m.parentNode.insertBefore(a, m);
    i.moe =
      i.moe ||
      function() {
        n = arguments[0];
        return t;
      };
    a.onload = function() {
      if (n) {
        i[r] = moe(n);
      }
    };
  })(
    window,
    document,
    "script",
    "https://cdn.moengage.com/webpush/moe_webSdk.min.latest.js",
    "Moengage"
  );
  Moengage = moe({
    app_id: ${process.env.MOENGAGE_APP_ID},
    debug_logs: 1,
    swPath: "/serviceworker.js",
    swScope: '/'
  });`;

  const tagManagerArgs = { gtmId: "GTM-567HLBD" };

  useEffect(() => {
    TagManager.initialize(tagManagerArgs);
  }, []);

  // useEffect(() => {
  //   if ('serviceWorker' in navigator) {
  //     navigator.serviceWorker
  //       .register('/serviceworker.js')
  //       .then((registration) => console.log('scope is: ', registration.scope));
  //   }
  // }, []);

  return (
    <DimensionProvider>
      <ToasterProvider>
        <AuthProvider>
          <AlertContextProvider>
            <InvoiceProvider>
              <AlertAttentionProvider>
                {/* dicopot for demo purpose */}
                {/* <TimerProvider> */}
                <ConstantsProvider>
                  <DemoProvider>
                    <GoogleDrivePreviewProvider>
                      <TaskProvider>
                        <FilePreviewProvider>
                          <SEOComponents />
                          {isProduction && (
                            <Script
                              strategy="afterInteractive"
                              dangerouslySetInnerHTML={{
                                __html,
                              }}
                            />
                          )}
                          <LayoutProvider>
                            <ErrorBoundary>
                              <GuidedTourProvider>
                                <Component {...pageProps} />
                              </GuidedTourProvider>
                            </ErrorBoundary>
                          </LayoutProvider>
                        </FilePreviewProvider>
                      </TaskProvider>
                    </GoogleDrivePreviewProvider>
                  </DemoProvider>
                </ConstantsProvider>
                {/* </TimerProvider> */}
              </AlertAttentionProvider>
            </InvoiceProvider>
          </AlertContextProvider>
        </AuthProvider>
      </ToasterProvider>
    </DimensionProvider>
  );
};

export default MyApp;

const useSessionId = () => {
  if (typeof window !== "undefined") {
    const sessionId = localStorage.getItem("session_id");
    if (!sessionId) {
      const newSessionId = crypto.randomUUID();
      localStorage.setItem("session_id", newSessionId);
    }
  }
};
