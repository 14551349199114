import { useForm } from "react-hook-form";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { GothamRegular } from "../../../../../../components/Text";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import CardInfoBalance from "../../components/CardInfoBalance";
import {
  TextFieldJack,
  CurrencyFieldJack,
} from "../../../../../../components/inputs/textfield";
import BottomContainer from "../../components/BottomContainer";
import { getUserRole } from "../../../../../../contexts/AuthContext";
import TitleIcon from "../../components/TitleIcon";
import { colors } from "../../../../../../assets/colors";
import { formatCurrencyNoDecimal } from "../../../../../../components/tools";

const PrepaidTopUpByCompanyForm = ({
  cardDetail,
  setCurrentScreen,
  setTopUpCompanyScreen,
  setResult,
  mainBalance,
}) => {
  const useFormObj = useForm();

  const { isAdminOrSuperAdmin } = getUserRole();
  const { getValues, handleSubmit } = useFormObj;
  const values = getValues();
  const { requested_amount: requestedAmount, reason } = values;
  const isButtonDisabled = !requestedAmount || !reason;

  const backToDetailsHandler = () => setCurrentScreen("index");
  const backHandler = () => setCurrentScreen("prepaidTopUpMethod");
  const onSubmit = (data) => {
    setResult(data);
    setTopUpCompanyScreen("summary");
  };

  const styles = {
    backButton: {
      width: "fit-content",
    },
    textInput: {
      marginBottom: "0px",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    topUpAmountContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    },
    companyBalance: {
      color: colors.neutral800,
      fontSize: "12px",
      letterSpacing: "0.2px",
    },
    reasonInput: {
      height: "60px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  return (
    <>
      <ButtonJack
        leftIcon={
          <JackIcons name="cornerUpLeftOutline" fill={colors.neutral900} />
        }
        type="outline"
        style={styles.backButton}
        onClick={backToDetailsHandler}
      >
        Go to Card Details
      </ButtonJack>
      <TitleIcon
        title="Top up from Company Balance"
        icon="plus-circle-outline"
      />
      <CardInfoBalance cardDetail={cardDetail} text="Top up to:" />
      <div style={styles.topUpAmountContainer}>
        <CurrencyFieldJack
          name="requested_amount"
          label="Top Up Amount"
          useFormObj={useFormObj}
          style={styles.textInput}
          icon={
            <GothamRegular
              style={{
                color: colors.neutral600,
              }}
            >
              IDR
            </GothamRegular>
          }
          required
        />
        {isAdminOrSuperAdmin && (
          <GothamRegular style={styles.companyBalance}>
            Company balance: IDR {formatCurrencyNoDecimal(mainBalance)}
          </GothamRegular>
        )}
      </div>
      <TextFieldJack
        name="reason"
        useFormObj={useFormObj}
        style={styles.textInput}
        textFieldStyle={styles.reasonInput}
        required
      />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          onClick={handleSubmit(onSubmit)}
          disabled={isButtonDisabled}
        >
          Continue
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidTopUpByCompanyForm;
