import {
  BILL_MODULE,
  DEMO_FIFTH_TARGET,
  DEMO_FIRST_TARGET,
  DEMO_FOURTH_TARGET,
  DEMO_GUIDANCE_PROPERTIES,
  DEMO_SECOND_TARGET,
  DEMO_SEVENTH_TARGET,
  DEMO_SIXTH_TARGET,
  DEMO_THIRD_TARGET,
  INTERNATIONAL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  VA_MODULE
} from "../../constants";

const stepsDecider = (page) => {
  const secondProps = (function () {
    switch (page) {
      case INTERNATIONAL_MODULE:
        return {
          secondTarget: DEMO_SECOND_TARGET,
          secondTitle: "Start an international transfer",
          secondContent:
            "Choose this to start creating an international transfer.",
        };
      case LOCAL_MODULE:
        return {
          secondTarget: DEMO_THIRD_TARGET,
          secondTitle: "Start a local transfer",
          secondContent: "Choose this to start creating a local transfer.",
        };
      case REIMBURSEMENT_MODULE:
        return {
          secondTarget: DEMO_FOURTH_TARGET,
          secondTitle: "Let’s request a reimbursement!",
          secondContent:
            "Choose this to start requesting your first reimbursement.",
        };
      case BILL_MODULE:
        return {
          secondTarget: DEMO_FIFTH_TARGET,
          secondTitle: "Start a bill payment",
          secondContent:
            "Choose this to start requesting your first bill payment.",
        };
      case PAYROLL_MODULE:
        return {
          secondTarget: DEMO_SIXTH_TARGET,
          secondTitle: "Start create a payroll",
          secondContent: "Choose this to start creating a payroll",
        };
      case VA_MODULE:
        return {
          secondTarget: DEMO_SEVENTH_TARGET,
          secondTitle: "Create virtual account",
          secondContent:
            "Choose this to start creating your first virtual account!",
        };
      default:
        return {};
    }
  })();

  const { secondTarget, secondTitle, secondContent } = secondProps ?? {};

  const defaultSteps = [
    {
      target: `.${DEMO_FIRST_TARGET}`,
      ...DEMO_GUIDANCE_PROPERTIES,
      title: "Click ‘Create’ button",
      content:
        "This button is a gateway to Jack's features where you can choose the one you need.",
      placement: "bottom",
      module: page,
      disableScrolling: false
    },
    {
      ...DEMO_GUIDANCE_PROPERTIES,
      target: `#${secondTarget}`,
      title: secondTitle,
      content: secondContent,
      placement: "left",
    },
  ];

  return defaultSteps;
};

const useDemoProps = ({ page }) => {
  const callback = ({ data, defaultState, setGuidedTourState }) => {
    const createBtn = document.querySelector(`.${DEMO_FIRST_TARGET}`);

    if (!!createBtn) {
      createBtn.addEventListener("click", () => {
        setGuidedTourState((prev) => ({
          ...prev,
          isRunning: false,
          stepIndex: 1,
        }));
        setTimeout(() => {
          setGuidedTourState((prev) => ({
            ...prev,
            isRunning: true,
            stepIndex: 1,
          }));
        }, 500);
      });
      return;
    }
  };

  const steps = stepsDecider(page);

  return {
    steps,
    callback,
  };
};

export default useDemoProps;
