import { useState } from "react";
import { useCardDetailContext } from "../../../../../../contexts/Cards/CardDetailContext";
import PrepaidTopUpByCompanyForm from "./form";
import PrepaidTopUpByCompanySummary from "./summary";
import { getUserRole } from "../../../../../../contexts/AuthContext";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { useModalHook } from "../../../../../../components/Modals";
import PINModal from "../../../../../../components/PINModal/parent";
import { useMutation } from "../../../../../../tools/api";
import { eventsTracker } from "../../../../../../universalFunctions/events";

const PrepaidTopUpByCompany = () => {
  const {
    cardDetail,
    setCurrentScreen,
    mainBalance,
    refetchBalance,
    refetchMainBalance,
    setHideClose,
  } = useCardDetailContext();
  const [topUpCompanyScreen, setTopUpCompanyScreen] = useState("form");
  const [result, setResult] = useState({});
  const [payload, setPayload] = useState({});
  const [isPinError, setIsPinError] = useState(false);

  const {
    isOpen: isOpenPin,
    toggle: togglePin,
    close: closePin,
  } = useModalHook();

  const { successSnackBar } = ToasterHook();
  const { isAdminOrSuperAdmin } = getUserRole();

  const { loading: loadingTopUp, mutation: topUpBalanceMutation } = useMutation(
    {
      url: `/cards/${cardDetail?.id}/move_main_balance`,
      method: "post",
      afterSuccess: () => {
        eventsTracker(`card_move_balance_deposit`, {
          amount: payload?.amount,
          reason: payload?.reason,
          card_id: cardDetail?.id,
        });
        if (isAdminOrSuperAdmin) {
          refetchMainBalance();
          setHideClose(false);
          closePin();
          successSnackBar({ msg: "Prepaid card top up success!" });
        } else {
          successSnackBar({
            msg: "Prepaid card top up has been requested",
          });
        }
        setCurrentScreen("index");
        refetchBalance();
      },
    }
  );

  const onSubmitPin = async ({ pin }) => {
    await topUpBalanceMutation({ pin, action_type: "deposit", ...payload });
  };

  const topUpBalanceHandler = async (payload) => {
    setPayload(payload);
    if (isAdminOrSuperAdmin) {
      setHideClose(true);
      return togglePin();
    }
    await topUpBalanceMutation(payload);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      paddingBottom: "100px",
      overflowY: "auto",
    },
  };

  const isSummary = topUpCompanyScreen === "summary";

  return (
    <>
      <div style={styles.container}>
        {isSummary ? (
          <PrepaidTopUpByCompanySummary
            cardDetail={cardDetail}
            result={result}
            setTopUpCompanyScreen={setTopUpCompanyScreen}
            onSubmit={topUpBalanceHandler}
            isLoading={loadingTopUp}
            mainBalance={mainBalance}
          />
        ) : (
          <PrepaidTopUpByCompanyForm
            cardDetail={cardDetail}
            setCurrentScreen={setCurrentScreen}
            setResult={setResult}
            setTopUpCompanyScreen={setTopUpCompanyScreen}
            result={result}
            mainBalance={mainBalance}
          />
        )}
      </div>
      <PINModal
        isOpen={isOpenPin}
        toggle={togglePin}
        isError={isPinError}
        setIsError={setIsPinError}
        isLoading={loadingTopUp}
        onSubmit={onSubmitPin}
        onBack={() => {
          setIsPinError(false);
          setHideClose(false);
          togglePin();
        }}
        woClose
      />
    </>
  );
};

export default PrepaidTopUpByCompany;
