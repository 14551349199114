import { Fragment } from "react";
import { jackColors } from "../../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";

const TooltipContent = ({
  title = null,
  description = null,
  progress = null,
  onSkip = () => {},
  showSkip = false
}) => {
  return (
    <div style={{paddingBottom: 6}}>
      <GothamMedium style={{ color: jackColors.neutral100 }}>
        {title}
      </GothamMedium>
      <GothamRegular
        woFontColor
        className="font12"
        style={{ color: jackColors.neutral100, marginTop: "8px" }}
      >
        {description}
      </GothamRegular>
      <div
        style={{
          display: "flex",
          width: "100%",
        }}
      >
        <GothamRegular
          className="font12"
          style={{
            color: "white",
            cursor: "default",
            position: "absolute",
            bottom: "-32px",
            left: 0,
          }}
          onClick={(e) => e.stopPropagation()}
        >
          {progress}
        </GothamRegular>
        {showSkip && <GothamMedium
          style={{
            position: "absolute",
            bottom: "-32px",
            right: 50,
            fontSize: 12,
            color: "white",
            cursor: "pointer"
          }}
          onClick={onSkip}
        >
          Dismiss
        </GothamMedium>}
      </div>
    </div>
  );
};

export default TooltipContent;
