import { CalendarContainer } from "react-datepicker";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { GothamMedium } from "../../Text";
import { useEffect } from "react";

export const DateContainer = ({ className, children }) => {
  const onClick = (index = 0) => {
    children[index]?.props?.onClick();
  };
  const textDate =
    children[2][0]?.props?.children[0]?.props?.children[0]?.props?.children ||
    "";

  const prevOnClick = () => onClick();
  const nextOnClick = () => onClick(1);

  return (
    <div>
      <CalendarContainer className={className}>
        <div
          className="d-flex justify-content-between"
          style={{
            padding: 10,
            paddingLeft: 16,
            paddingRight: 16,
          }}
        >
          <GothamMedium>{textDate}</GothamMedium>
          <div className="d-flex">
            <JackIcons
              name="chevron-left"
              fill={jackColors.black34}
              onClick={prevOnClick}
              className="darkhover"
            />
            <JackIcons
              name="chevron-right"
              fill={jackColors.black34}
              onClick={nextOnClick}
              className="darkhover"
            />
          </div>
        </div>
        <div style={{ position: "relative" }}>{[children[2]]}</div>
      </CalendarContainer>
    </div>
  );
};

export const DateContainerMonthYearDropdown = ({ className, children }) => {
  useEffect(() => {
    const arrowElementYear = document.querySelector(
      ".react-datepicker__year-read-view--down-arrow"
    );
    const arrowElementMonth = document.querySelector(
      ".react-datepicker__month-read-view--down-arrow"
    );

    const elementsToReplace = [arrowElementYear, arrowElementMonth];

    elementsToReplace.forEach((element) => {
      if (!element) return;
      const newElement = document.createElement("div");
      newElement.innerHTML = `
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M11.9999 15.5C11.8683 15.5008 11.7379 15.4755 11.616 15.4258C11.4942 15.376 11.3834 15.3027 11.2899 15.21L7.28994 11.21C7.1967 11.1168 7.12274 11.0061 7.07228 10.8843C7.02182 10.7624 6.99585 10.6319 6.99585 10.5C6.99585 10.3681 7.02182 10.2376 7.07228 10.1158C7.12274 9.99393 7.1967 9.88324 7.28994 9.79C7.38318 9.69676 7.49387 9.6228 7.61569 9.57234C7.73751 9.52188 7.86808 9.49591 7.99994 9.49591C8.1318 9.49591 8.26237 9.52188 8.38419 9.57234C8.50601 9.6228 8.6167 9.69676 8.70994 9.79L11.9999 13.1L15.2999 9.92C15.3919 9.81771 15.504 9.7355 15.6293 9.67852C15.7545 9.62153 15.8901 9.59099 16.0276 9.58881C16.1652 9.58664 16.3017 9.61286 16.4287 9.66585C16.5556 9.71884 16.6703 9.79746 16.7655 9.89678C16.8607 9.99611 16.9343 10.114 16.9819 10.2431C17.0294 10.3722 17.0498 10.5097 17.0418 10.647C17.0338 10.7844 16.9975 10.9186 16.9352 11.0413C16.873 11.1639 16.7861 11.2724 16.6799 11.36L12.6799 15.22C12.4971 15.3963 12.2539 15.4964 11.9999 15.5Z"
            fill=${jackColors.black34}
          />
        </svg>
      `;
      element.replaceWith(newElement);
      const monthContainer = document.querySelector(
        ".react-datepicker__month-read-view"
      );
      const yearContainer = document.querySelector(
        ".react-datepicker__year-read-view"
      );
      monthContainer.classList.add("darkhover");
      yearContainer.classList.add("darkhover");
    });
  }, []);

  return (
    <div>
      <CalendarContainer className={className}>
        <div
          className="d-flex justify-content-between"
          style={{
            paddingLeft: 16,
            paddingRight: 16,
          }}
        ></div>
        <div style={{ position: "relative" }}>{[children[2]]}</div>
      </CalendarContainer>
    </div>
  );
};
