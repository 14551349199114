import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import { useMutation } from "../../../tools/api";
import { isProduction } from "../../../components/tools";

const useDemoLogin = ({
  isLoading = false,
  enableLoading = () => {},
  disableLoading = () => {},
  verifyToken: verifyTokenRaw = "",
  session_id = "",
}) => {
  const [hasTriedLoggingIn, setHasTriedLoggingIn] = useState(false);

  const declareHasTriedLoggingIn = () => setHasTriedLoggingIn(true);

  const { query, isReady, push } = useRouter();
  const { email, password } = query ?? {};

  const isLocalhost = process.env.NODE_ENV === "development";
  const verifyToken = isLocalhost ? "temporary_bypass" : verifyTokenRaw;

  const { authorize, unauthorize } = useContext(AuthContext);

  const { mutation: login } = useMutation({
    method: "post",
    url: "/authenticate",
    handleError: () => {
      declareHasTriedLoggingIn();
      disableLoading();
    },
    afterSuccess: (res, payload) => {
      const { data } = res ?? {};
      const { auth_token } = data ?? {};
      const { password } = payload ?? {};
      authorize({
        token: auth_token,
        tempPassword: password,
        handleRedirect: () => push("/dashboard"),
      });
      declareHasTriedLoggingIn();
    },
  });

  useEffect(() => {
    if (!isReady) return;
    if (hasTriedLoggingIn) return;
    if (!verifyToken && isProduction) return;
    if (typeof email !== "string" || typeof password !== "string") return;

    if (!isLoading && !hasTriedLoggingIn) enableLoading();

    // unauthorize()
    // const emailFromLocalStorage = JSON.parse(localStorage.getItem("user"))?.email

    const timeout = setTimeout(() => {
      login({
        email,
        password,
        "cf-turnstile-response": verifyToken,
        session_id,
      });
    }, 3000);

    return () => clearTimeout(timeout);
  }, [email, password, isReady, isLoading, hasTriedLoggingIn, verifyToken]);
};

export default useDemoLogin;
