import { format, parseISO } from "date-fns";
import { isEmpty } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import Skeleton from "react-loading-skeleton";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import {
  GothamBold,
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { fetch } from "../../../tools/api";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { changeHour, pcDescription, spesificDateFormat } from "../formatter";
import {
  ChangeButton,
  DefaultOption,
  DefaultTag,
  OptionWithButton,
} from "./components";
import { optionsFormatter } from "./formatter";
import { AddPaymentCycleModal, SpesificDateModal } from "./modals";
import styles from "./style.module.css";
import emptyPC from "../../../assets/images/schedule_dashboard_empty.svg";
import DemoGeneralModal from "../../productDemoComponents/components/DemoGeneralModal";
const formatNextDate = (date) =>
  format(parseISO(date), "'Next : 'cccc, d MMMM yyyy 'at' HH:mm");

const SpesificDateComponent = ({
  label,
  handleSave,
  defaultValue,
  children,
  onClick,
}) => {
  const isValidDate = defaultValue?.payload?.date_and_time instanceof Date;
  const labelDecider = isValidDate
    ? spesificDateFormat(defaultValue?.payload?.date_and_time)
    : label;

  return (
    <div
      className={styles.option}
      onClick={() => {
        if (isValidDate) return;
        onClick();
      }}
      style={{
        backgroundColor: isValidDate && "#e6e6e8",
        height: isValidDate && 44,
        justifyContent: "space-between",
      }}
    >
      <GothamRegular
        style={{ color: isValidDate ? "#343434" : "#6C6C71" }}
        className="font12"
      >
        {labelDecider}
      </GothamRegular>
      {isValidDate && <ChangeButton onClick={onClick} />}
      {/* <SpesificDateModal
        isOpen={open == "open"}
        toggle={() => set("close")}
        useFormObj={useFormObj}
        onSubmit={handleSave}
      /> */}
      {children}
    </div>
  );
};

const PaymentCycleComponent = ({ label, payment, toggle }) => {
  const isPaymentCycle = payment?.type == "payment_cycle";
  const paymentCycleValue = payment?.payload;
  const description = payment?.description;
  const nearestDate = paymentCycleValue?.date_and_time;
  // const newLabel = formatNextDate(paymentCycleValue?.nearest_date_from_today);
  const labelDecider = isPaymentCycle ? formatNextDate(nearestDate) : label;

  if (isPaymentCycle) {
    const { date_and_time, is_default } = paymentCycleValue;
    const formattedNextDate = formatNextDate(date_and_time);
    return (
      <OptionWithButton
        topText={description}
        bottomText={formattedNextDate}
        onClickButton={toggle}
      />
    );
  }

  return (
    <DefaultOption
      onClick={toggle}
      flag={isPaymentCycle}
      label={labelDecider}
    />
  );
};

const PaymentCycleList = ({
  toggleComponent,
  product,
  setPayment,
  loading,
  list,
  refetch,
  canCreate = false,
}) => {
  const { isOpen, toggle } = useModalHook();

  return (
    <div
      style={{
        width: "100%",
        backgroundColor: "white",
        position: "absolute",
        top: 50,
        left: 0,
        borderRadius: 8,
        padding: 8,
        boxShadow:
          "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
      }}
    >
      <div
        style={{
          display: "flex",
          padding: "0px 8px",
          alignItems: "center",
          height: 40,
          borderRadius: 4,
        }}
      >
        <JackIcons
          name="arrow_back_outline"
          fill="#6C6C71"
          style={{
            width: 16,
            height: 16,
            marginRight: 8,
            cursor: "pointer",
          }}
          onClick={toggleComponent}
        />
        <GothamRegular className="font12" style={{ color: "#6C6C71" }}>
          Back
        </GothamRegular>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          height: "auto",
          maxHeight: 140,
          overflow: "auto",
        }}
      >
        {isEmpty(list) ? (
          <div
            style={{
              padding: 16,
              height: 136,
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <img src={emptyPC} style={{ height: 80, width: 100 }} />
            <GothamMedium>There's no payment cycle</GothamMedium>
          </div>
        ) : (
          list?.map((data) => {
            const { nearest_date_from_today, is_default } = data;

            const formattedNextDate = formatNextDate(nearest_date_from_today);
            return (
              <div
                style={{
                  minHeight: 44,
                  height: "auto",
                  width: "100%",
                  padding: "1px 8px",
                  display: "flex",
                  justifyContent: "space-between",
                }}
                className={styles.option}
                onClick={() => setPayment(data)}
              >
                <div className="d-flex-column font12">
                  <GothamBold style={{ fontSize: 12, color: "#343434" }}>
                    {pcDescription(data)}
                  </GothamBold>
                  <GothamRegular className="font12">
                    {formattedNextDate}
                  </GothamRegular>
                </div>
                {is_default && <DefaultTag />}
              </div>
            );
          })
        )}
        {loading && <Skeleton height={44} width="100%" />}
      </div>
      {canCreate && (
        <ButtonJack
          leftIcon={
            <JackIcons
              name="plus_outline"
              fill="#6C6C71"
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
          }
          style={{ width: "100%", backgroundColor: "#F1F1F1", marginTop: 4 }}
          onClick={toggle}
        >
          Add Payment Cycle
        </ButtonJack>
      )}
      <AddPaymentCycleModal
        isOpen={isOpen}
        toggle={toggle}
        product={product}
        refetch={refetch}
        setPayment={setPayment}
      />
    </div>
  );
};

export const ScheduledPaymentOptions = ({
  product,
  setPayment,
  payment,
  invoiceDueDate,
}) => {
  const { toggle, isOpen, close } = useModalHook();
  const { isAdmin, isSuperAdmin } = getUserRole();
  const canCreate = isAdmin || isSuperAdmin;
  const {
    toggle: togglePaymentCycleList,
    isOpen: isOpenPCL,
    close: closePCL,
  } = useModalHook();

  const paymentType = payment?.type;
  const isDefault = paymentType == "default";
  const isBlue = !isDefault && Boolean(paymentType);
  const selectedDateAndTime = payment?.payload?.date_and_time;
  const labelDate = spesificDateFormat(selectedDateAndTime);
  const defaultLabel = isOpen ? "Select Payment Schedule" : "Pay Immediately";

  const {
    data: list,
    loading,
    refetch,
  } = fetch({
    url: `/predefined_schedules?q[transaction_type_eq]=${product}`,
    formatter: ({ data: rawData }) => optionsFormatter(rawData, invoiceDueDate),
    afterSuccess: ({ data: rawData }) => {
      const data = optionsFormatter(rawData, invoiceDueDate);
      const defaultPaymentCycle = data
        ?.filter(({ is_default }) => is_default)
        ?.pop();
      if (!isEmpty(defaultPaymentCycle)) {
        const { nearest_date_from_today, id } = defaultPaymentCycle;

        const payload = {
          is_scheduled: true,
          date_and_time: nearest_date_from_today,
          predefined_schedule_id: id,
        };
        isDefault && setPayment({ type: "payment_cycle", payload });
      }
    },
  });

  const useFormSpesificDate = useForm();
  const { isOpen: isOpenSpesificModal, toggle: toggleSpesificModal } =
    useModalHook();
  return (
    <div className="d-flex-column" style={{ marginTop: 32 }}>
      <GothamRegular className="font12" style={{ marginBottom: 8 }}>
        Payment Schedule
      </GothamRegular>
      <div
        style={{
          width: "100%",
          borderRadius: 4,
          border: `1px solid ${
            isOpen ? (isBlue ? "#6087E7" : "#343434") : "#E6E6E8"
          }`,
          padding: "10px 12px",
          display: "flex",
          justifyContent: "space-between",
          backgroundColor: isBlue && "#F4F9FF",
          alignItems: "center",
          position: "relative",
          zIndex: 10,
          cursor: "pointer",
        }}
        onClick={(e) => {
          e.stopPropagation();
          toggle();
        }}
      >
        <div style={{ display: "flex", gap: 4, alignItems: "center" }}>
          <JackIcons
            name="calendar"
            fill="#343434"
            style={{ width: 20, height: 20 }}
          />
          <GothamRegular
            className="font14"
            style={{
              height: 20,
              color: isOpen && !labelDate ? "#BBBBC0" : "#343434",
            }}
          >
            {labelDate ?? defaultLabel}
          </GothamRegular>
        </div>
        <JackIcons
          name="chevron_down"
          fill="#BBBBC0"
          onClick={(e) => {
            e.stopPropagation();
            toggle();
          }}
          style={{ cursor: "pointer" }}
        />
        {isOpen && !isOpenPCL && (
          <div
            style={{
              padding: 8,
              borderRadius: 8,
              boxShadow:
                "0px 0px 1px 0px rgba(9, 30, 66, 0.31), 0px 8px 12px 0px rgba(9, 30, 66, 0.15)",
              display: "flex",
              flexDirection: "column",
              gap: 4,
              position: "absolute",
              top: 50,
              left: 0,
              width: "100%",
              backgroundColor: "white",
            }}
          >
            <DefaultOption
              onClick={(e) => {
                e.stopPropagation();
                setPayment({ type: "default" });
                toggle();
              }}
              flag={isDefault}
              label="Pay Immediately"
            />
            <SpesificDateComponent
              label="Specific Date & Time"
              setPayment={setPayment}
              defaultValue={payment}
              onClick={toggleSpesificModal}
            />
            <PaymentCycleComponent
              label="Payment Cycle"
              toggle={togglePaymentCycleList}
              product={product}
              payment={payment}
            />
          </div>
        )}
        {/* DEMO PURPOSE */}
        {/* payment cycle direstrict */}
        {/* {isOpenPCL && (
          <PaymentCycleList
            isOpen={isOpenPCL}
            toggleComponent={togglePaymentCycleList}
            product={product}
            canCreate={canCreate}
            list={list}
            loading={loading}
            refetch={refetch}
            setPayment={(v) => {
              const { nearest_date_from_today, id } = v;
              const payload = {
                is_scheduled: true,
                date_and_time: nearest_date_from_today,
                predefined_schedule_id: id,
              };
              setPayment({
                type: "payment_cycle",
                payload,
                description: pcDescription(v),
              });
              togglePaymentCycleList();
              toggle();
            }}
          />
        )} */}
        <SpesificDateModal
          isOpen={isOpenSpesificModal}
          toggle={toggleSpesificModal}
          useFormObj={useFormSpesificDate}
          onSubmit={(v) => {
            const payload = {
              is_scheduled: true,
              date_and_time: v,
              predefined_schedule_id: null,
            };
            setPayment({ type: "spesific_date", payload });
            toggleSpesificModal();
          }}
        />
        <DemoGeneralModal isOpen={isOpenPCL} toggle={togglePaymentCycleList} />
      </div>
    </div>
  );
};
