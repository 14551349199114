import React, { useState } from "react";
import { colors } from "../../../assets/colors";
import { GothamRegular } from "../../../components/Text";

const Tooltip = ({
  text,
  arrowPlacement,
  children,
  isShown,
  isShownByProps,
  tooltipContentStyles: tooltipContentStylesProps,
}) => {
  const arrowStyles = {
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    position: "absolute",
    width: "0",
    height: "0",
    border: "8px solid transparent",
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
  };

  const tooltipStyles = {
    position: "relative",
    display: "inline-block",
  };

  const tooltipContentStyles = {
    display: "block",
    position: "absolute",
    zIndex: 1,
    backgroundColor: colors.neutral100,
    color: "#fff",
    borderRadius: "4px",
    whiteSpace: "nowrap",
    opacity: 0,
    visibility: "hidden",
    transition: "opacity 0.3s ease-in-out, visibility 0.3s ease-in-out",
    padding: "8px",
    ...tooltipContentStylesProps,
  };

  const textStyles = {
    color: colors.neutral800,
    letterSpacing: "0.2px",
    fontSize: "12px",
  };

  const [isTooltipVisible, setTooltipVisible] = useState(false);

  switch (arrowPlacement) {
    case "top":
      arrowStyles.transform = "translateY(-20px)";
      arrowStyles.borderTopColor = colors.neutral100;
      tooltipContentStyles.top = "-60px";
      tooltipContentStyles.left = "50%";
      tooltipContentStyles.transform = "translateX(-45%)";
      break;
    case "right":
      arrowStyles.left = "100%";
      arrowStyles.top = "50%";
      arrowStyles.transform = "translateY(-50%)";
      arrowStyles.borderRightColor = colors.neutral100;
      tooltipContentStyles.top = "50%";
      tooltipContentStyles.left = "calc(100% + 15px)";
      tooltipContentStyles.transform = "translateY(-50%)";
      break;
    case "left":
      arrowStyles.right = "100%";
      arrowStyles.top = "50%";
      arrowStyles.transform = "translateY(-50%)";
      arrowStyles.borderLeftColor = colors.neutral100;
      tooltipContentStyles.top = "50%";
      tooltipContentStyles.right = "calc(100% + 8px)";
      tooltipContentStyles.transform = "translateY(-50%)";
      break;
    case "bottom":
      arrowStyles.top = "calc(100% + 2px)";
      arrowStyles.borderBottomColor = colors.neutral100;
      tooltipContentStyles.bottom = "-175%";
      tooltipContentStyles.left = "50%";
      tooltipContentStyles.transform = "translateX(-50%)";
      break;
    default:
      arrowStyles.display = "none";
      break;
  }

  const showTooltip = isShownByProps ? isShown : isTooltipVisible;

  return (
    <div
      style={tooltipStyles}
      onMouseEnter={() => setTooltipVisible(true)}
      onMouseLeave={() => setTooltipVisible(false)}
    >
      {children}
      <div
        style={{
          ...arrowStyles,
          visibility: showTooltip ? "visible" : "hidden",
          opacity: showTooltip ? 1 : 0,
        }}
      />
      <div
        style={{
          ...tooltipContentStyles,
          visibility: showTooltip ? "visible" : "hidden",
          opacity: showTooltip ? 1 : 0,
        }}
      >
        <GothamRegular style={textStyles}>{text}</GothamRegular>
      </div>
    </div>
  );
};

export default Tooltip;
