import {
  GothamMedium,
  GothamRegular,
  GothamLight,
} from "../../../../../../components/Text";
import {
  stringCutter,
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../../../../components/tools";
import { MissingDetailWarning } from "../../../../../categoryComponents/general/components";
import { StatusLabelJack } from "../../../../../../components/StatusLabel";
import { customStatusColorsTransaction } from "../../../../constants";
import {
  cardTransactionStateDecider,
  paymentMethodDecider,
  transactionTypeDecider,
} from "../../../../tools";

export const cardTransactionBodyTableFormatter = (
  transaction,
  shallCheckMissingCategory
) => {
  const {
    amount,
    date,
    has_attachment: hasAttachment,
    id,
    merchant_name: transactionName,
    notes,
    payment_method: paymentMethod,
    state,
    transaction_currency: transactionCurrency,
    transaction_amount: transactionAmount,
    transaction_type: transactionType,
    category_id,
  } = transaction || {};
  const isForeignCurrency = transactionCurrency !== "IDR";
  const { isPrepaidTopUp, isCreditTopUp } =
    transactionTypeDecider(transactionType);
  const isIncomingTransaction = isPrepaidTopUp || isReturned || isCreditTopUp;
  const { isCancelled, isReturned, isFailed } =
    cardTransactionStateDecider(state);
  const { isMoveBalance, isMainBalance } = paymentMethodDecider(paymentMethod);
  const isWoReceipt = isMainBalance || isMoveBalance;
  const isMissingReceipt = !isWoReceipt && !hasAttachment;
  const isWoIcons = isCancelled || isFailed || isReturned;

  const isMissingCategory = !category_id && shallCheckMissingCategory;

  const isMissingDetails =
    (!isWoIcons && (isMissingReceipt || !notes)) || isMissingCategory;

  return {
    rawData: transaction,
    tableRow: [
      <div key={id}>
        <GothamMedium>{stringCutter(transactionName, 22)}</GothamMedium>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "4px",
          }}
        >
          <GothamLight
            style={{
              width: "80px",
            }}
          >
            {customDateFormatter(date, true, true)}
          </GothamLight>
          <StatusLabelJack
            customStatusColors={customStatusColorsTransaction}
            status={state}
            woTextFormatter
          />
        </div>
      </div>,
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          alignItems: "flex-end",
        }}
      >
        <GothamRegular>
          {isIncomingTransaction ? "+" : "-"} IDR{" "}
          {formatCurrencyNoDecimal(amount)}
        </GothamRegular>
        {isForeignCurrency && (
          <GothamLight>
            {transactionCurrency} {formatCurrency(transactionAmount)}
          </GothamLight>
        )}
      </div>,
      <div
        style={{
          display: "flex",
          gap: "8px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {isMissingDetails && <MissingDetailWarning />}
      </div>,
    ],
  };
};
