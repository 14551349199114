const MasterCard = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.09972C9.09314 6.2275 8.19231 7.96047 8.19231 9.90385C8.19231 11.8472 9.09314 13.5802 10.5 14.708C9.44647 15.5525 8.10915 16.0577 6.65385 16.0577C3.25517 16.0577 0.5 13.3025 0.5 9.90385C0.5 6.50517 3.25517 3.75 6.65385 3.75C8.10915 3.75 9.44647 4.25517 10.5 5.09972Z"
        fill="#EB001B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5 5.09972C11.9069 6.2275 12.8077 7.96047 12.8077 9.90385C12.8077 11.8472 11.9069 13.5802 10.5 14.708C11.5535 15.5525 12.8909 16.0577 14.3462 16.0577C17.7448 16.0577 20.5 13.3025 20.5 9.90385C20.5 6.50517 17.7448 3.75 14.3462 3.75C12.8909 3.75 11.5535 4.25517 10.5 5.09972Z"
        fill="#F79E1B"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5001 14.7079C11.9069 13.5801 12.8078 11.8471 12.8078 9.90374C12.8078 7.96037 11.9069 6.22739 10.5001 5.09961C9.09321 6.22739 8.19238 7.96037 8.19238 9.90374C8.19238 11.8471 9.09321 13.5801 10.5001 14.7079Z"
        fill="#FF5F00"
      />
    </svg>
  );
};

export default MasterCard;
