import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../../assets/colors";
import bigImg from "../../../assets/images/balance-dashboard-big.svg";
import rightArrow from "../../../assets/images/caret-right-white-icon.svg";
import crossBorderIcon from "../../../assets/images/crossborder-icon-40.svg";
import refreshIcon from "../../../assets/images/replace-card-icon-pink.svg";
import warningCircleIcon from "../../../assets/images/warning-circle-red.svg";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { CustomButton } from "../../../components/Buttons";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import {
  MainCardHeaderMolecule,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../components/CardModal";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import {
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { WrapperModalButtons } from "../../../pageComponents/homeComponents/topup/components";
import FloatingTooltip from "../../../pageComponents/productDemoComponents/components/FloatingTooltip";
import { ScheduledPaymentOptions } from "../../../pageComponents/scheduledPaymentComponents/paymentOptions";
import { CenterendIconLocalTransfer } from "../localDisbursementBatchModal/transactionModal";
import { ModalCardLeftRight } from "../payroll/rejectModal";
import {
  DEMO_BACK_RELEASE_TASK,
  DEMO_CLOSE_RELEASE_TASK,
  DEMO_RELEASE_TASK,
} from "../../../pageComponents/productDemoComponents/constants/events";
import { eventsTracker } from "../../../universalFunctions/events";

export const ReleasePaymentModal = ({
  isOpen,
  toggle,
  data,
  onClick,
  onClickTopUp,
}) => {
  if (!isOpen) return null;
  const { user, refetchUser } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;

  const { isEmployee } = getUserRole();

  const {
    total_trx_amount: transferAmount,
    batch,
    total_amount: total_amountParent,
    total_fee: total_feeParent,
    fee: feeParent,
    amount,
    local_transactions: local_transactionsParent = [],
    local_transaction,
    transaction_type: transaction_typeParent,
    sent_amount,
  } = data;

  const {
    transaction_type,
    total_amount,
    total_fee,
    local_transactions = [],
  } = batch || {};

  const originatorName = local_transaction?.originator_name ?? "";

  const isPayroll = transaction_type === "payroll";
  const isLocalTransfer = transaction_typeParent === "transfer";
  const isCrossBorderSingle = transaction_typeParent === "Single";
  const isInvoice = originatorName === "InvoiceTransaction";
  const isReimbursement = originatorName === "Reimbursement";

  const amountDecider = () => {
    if (batch && transaction_type === "payroll")
      return Number(total_amount || total_amountParent);

    if (isCrossBorderSingle) return Number(sent_amount);
    if (transferAmount) return transferAmount;

    const defaultAmount = Number(total_amountParent);

    return defaultAmount || amount;
  };

  const feeDecider = () => {
    if (isPayroll) return total_fee;
    if (isLocalTransfer) return total_feeParent;
    if (isCrossBorderSingle) return Number(feeParent);
    if (isInvoice) return Number(local_transaction?.fee);
    if (isReimbursement) return Number(local_transaction?.fee);

    if (total_feeParent) return total_feeParent;
    if (local_transactionsParent?.fee) return local_transactionsParent.fee;
  };

  const isBatchTransfer =
    local_transactionsParent.length > 0 || local_transactions.length > 0;

  const individualDefaultFeeDecider = () => {
    if (isPayroll) return local_transactions[0]?.default_fee;
    if (isLocalTransfer) return local_transactionsParent[0]?.default_fee;
    if (isInvoice) return local_transaction.default_fee;
    if (isReimbursement) return local_transaction.default_fee;

    return 0;
  };

  const individualDefaultFee = Number(individualDefaultFeeDecider());

  const totalTrxDecider = () => {
    if (isPayroll) return local_transactions.length;
    if (isLocalTransfer) return local_transactionsParent.length;

    return 0;
  };

  const totalTrx = Number(totalTrxDecider());

  const totalFeeDecider = () => {
    if (isPayroll) return total_fee;
    if (isLocalTransfer) return total_feeParent;
  };

  const totalFee = Number(totalFeeDecider());

  const allTrxFeeAreFree = totalFee === 0;
  const someTrxFeeAreFree =
    totalFee < individualDefaultFee * totalTrx && !allTrxFeeAreFree;
  const allTrxFeeAreNotFree = totalFee === individualDefaultFee * totalTrx;

  const isFreeFee = Number(feeDecider()) === 0;
  const fee = isFreeFee ? "Free" : `IDR ${formatCurrency(feeDecider())}`;

  const totalPayment = Number(feeDecider()) + Number(amountDecider());

  const remainingBalance =
    balance - Number(amountDecider()) - Number(feeDecider());
  const isSufficient = remainingBalance >= 0 ? true : false;

  const showTopup = !isSufficient && !isEmployee;

  const [middleHeight, setMiddleHeight] = useState(53);

  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };

  const feeSectionDecider = () => {
    if (!isBatchTransfer)
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>Total fee</GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            {isFreeFee ? (
              <GothamMedium style={{ color: colors.pink }}>
                {fee}{" "}
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "2px",
                    }}
                  >
                    IDR {formatCurrencyNoDecimal(Number(individualDefaultFee))}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            ) : (
              <GothamRegular>{fee}</GothamRegular>
            )}
          </td>
        </tr>
      );

    if (allTrxFeeAreFree) {
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>
              Total fee{" "}
              {individualDefaultFee > 0
                ? `= IDR ${formatCurrencyNoDecimal(
                    Number(individualDefaultFee)
                  )} x ${totalTrx}`
                : ""}
            </GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            {isFreeFee ? (
              <GothamMedium style={{ color: colors.pink }}>
                {fee}{" "}
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "2px",
                    }}
                  >
                    IDR{" "}
                    {formatCurrencyNoDecimal(
                      Number(individualDefaultFee * totalTrx)
                    )}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            ) : (
              <GothamRegular>{fee}</GothamRegular>
            )}
          </td>
        </tr>
      );
    }

    if (someTrxFeeAreFree) {
      const totalFreeFeeAmount =
        Number(individualDefaultFee) * Number(totalTrx) - Number(totalFee);
      const totalFreeFeeTrx = totalFreeFeeAmount / Number(individualDefaultFee);
      const totalPaidFeeTrx = Number(totalTrx) - totalFreeFeeTrx;

      return (
        <>
          <tr>
            <td className="pb-2">
              <GothamMedium style={{ color: colors.grey25 }}>
                Transfez fee
              </GothamMedium>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamMedium style={{ color: colors.grey25 }}>
                IDR {formatCurrencyNoDecimal(Number(totalFee))}
              </GothamMedium>
            </td>
          </tr>
          <tr>
            <td className="pb-2">
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
                className="ml-4"
              >
                {totalFreeFeeTrx} Transaction(s)
              </GothamRegular>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamMedium
                style={{
                  color: colors.pink,
                }}
              >
                Free
                {individualDefaultFee > 0 ? (
                  <span
                    style={{
                      fontFamily: "GothamBook",
                      color: colors.greyd1,
                      textDecoration: "line-through",
                      marginLeft: "4px",
                    }}
                  >
                    IDR{" "}
                    {formatCurrencyNoDecimal(
                      Number(individualDefaultFee * totalTrx)
                    )}
                  </span>
                ) : (
                  ""
                )}
              </GothamMedium>
            </td>
          </tr>
          <tr>
            <td className="pb-2">
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
                className="ml-4"
              >
                {totalPaidFeeTrx} Transaction{totalPaidFeeTrx > 1 ? "s" : ""}
              </GothamRegular>
            </td>
            <td className="pb-2" style={{ textAlign: "right" }}>
              <GothamRegular
                style={{
                  color: colors.grey6c,
                }}
              >
                IDR {formatCurrencyNoDecimal(Number(totalFee))}
              </GothamRegular>
            </td>
          </tr>
        </>
      );
    }

    if (allTrxFeeAreNotFree) {
      return (
        <tr>
          <td className="pb-2">
            <GothamRegular>
              Total fee{" "}
              {`= IDR ${formatCurrencyNoDecimal(
                Number(individualDefaultFee)
              )} x ${totalTrx}`}
            </GothamRegular>
          </td>
          <td className="pb-2" style={{ textAlign: "right" }}>
            <GothamRegular>{fee}</GothamRegular>
          </td>
        </tr>
      );
    }
  };

  return (
    <RightCardModalAtomic
      isOpen={isOpen}
      toggle={toggle}
      middleHeight={middleHeight}
      header={
        <MainCardHeaderMolecule
          isClose={false}
          toggle={toggle}
          middleHeight={middleHeight}
          title={"Release payment"}
          alignLeft
        />
      }
      middle={
        <MiddleWrapper height={middleHeight} setHeight={setMiddleHeight}>
          <CenterendIconLocalTransfer icon={crossBorderIcon} />
        </MiddleWrapper>
      }
      body={
        <>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
            }}
          >
            {showTopup && (
              <div
                style={{
                  display: "flex",
                  backgroundColor: colors.lightYellowFF,
                  padding: 12,
                  borderRadius: 8,
                  border: `1px solid ${colors.yellowFC}`,
                  marginBottom: 12,
                  alignItems: "center",
                }}
              >
                <GothamRegular style={{ color: colors.grey6c }}>
                  You don't have enough amount
                </GothamRegular>
                <CustomButton
                  style={{
                    width: 80,
                    marginLeft: "auto",
                    height: 24,
                    fontSize: 12,
                  }}
                  onClick={onClickTopUp}
                >
                  Top Up
                </CustomButton>
              </div>
            )}
            <GothamMedium className="font16">Payment Summary</GothamMedium>
            <table
              className="font14"
              style={{
                color: colors.grey72,
                marginTop: 12,
              }}
            >
              {!isSufficient ? (
                <tr>
                  <td className="pb-2">
                    <GothamRegular className="d-inline-flex">
                      Available balance
                    </GothamRegular>
                    <img
                      className="d-inline-flex"
                      src={warningCircleIcon}
                      style={{ marginLeft: 6 }}
                    />
                  </td>
                  <td className="pb-2" style={{ textAlign: "right" }}>
                    <GothamRegular className="d-inline-flex">
                      IDR {formatCurrency(balance)}
                    </GothamRegular>
                    <img
                      className="d-inline-flex"
                      src={refreshIcon}
                      style={{ cursor: "pointer", marginLeft: 6 }}
                      onClick={refetchUser}
                    />
                  </td>
                </tr>
              ) : (
                <tr>
                  <td className="pb-2">
                    <GothamRegular>Available balance</GothamRegular>
                  </td>
                  <td className="pb-2" style={{ textAlign: "right" }}>
                    <GothamRegular>IDR {formatCurrency(balance)}</GothamRegular>
                  </td>
                </tr>
              )}
              <tr>
                <td className="pb-2">
                  <GothamRegular>Total amount</GothamRegular>
                </td>
                <td className="pb-2" style={{ textAlign: "right" }}>
                  <GothamRegular>
                    IDR {formatCurrency(amountDecider())}
                  </GothamRegular>
                </td>
              </tr>
              {feeSectionDecider()}
              <tr
                style={{
                  borderTop: "1.5px dashed",
                  borderColor: colors.greyC2,
                }}
              >
                <td className="pt-2">
                  <GothamMedium
                    className="font16"
                    style={{ color: colors.grey33 }}
                  >
                    Total Payment
                  </GothamMedium>
                </td>
                <td className="pt-2" style={{ textAlign: "right" }}>
                  <GothamBold style={{ color: colors.grey33 }}>
                    IDR {formatCurrency(totalPayment)}
                  </GothamBold>
                </td>
              </tr>
              <tr>
                <td>
                  <GothamRegular>Remaining balance</GothamRegular>
                </td>
                <td style={{ textAlign: "right" }}>
                  <GothamRegular>
                    IDR {formatCurrency(remainingBalance)}
                  </GothamRegular>
                </td>
              </tr>
            </table>
            {!isSufficient ? (
              <CustomButton
                rightIcon={<img src={rightArrow} />}
                style={{
                  marginTop: 16,
                  width: "100%",
                  ...defaultStyle,
                }}
                disabled
              >
                Continue
              </CustomButton>
            ) : (
              <CustomButton
                rightIcon={<img src={rightArrow} />}
                style={{
                  marginTop: 16,
                  width: "100%",
                  ...defaultStyle,
                }}
                onClick={onClick}
              >
                Continue
              </CustomButton>
            )}
          </div>
        </>
      }
    />
  );
};

export const ApproveBackButton = ({
  onClick,
  onClickBack,
  disableButton,
  isTribeNgerepotin,
  rightIcon,
  rightText,
  hideLeftIcon,
  containerStyle,
}) => {
  const rightButtonText = rightText
    ? rightText
    : isTribeNgerepotin
    ? "Pay"
    : "Approve & Pay";
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, paddingRight: 16, ...containerStyle }}
    >
      <ButtonJack
        style={{ width: "50%", backgroundColor: "transparent" }}
        type="outline"
        onClick={onClickBack}
        leftIcon={
          <JackIcons name="arrow_back_outline" fill={jackColors.black34} />
        }
      >
        Back
      </ButtonJack>
      <ButtonJack
        style={{ width: "50%" }}
        type="filled"
        onClick={onClick}
        disabled={disableButton}
        leftIcon={
          !hideLeftIcon && (
            <JackIcons
              name={"checkmark_circle_2"}
              fill={disableButton ? jackColors.greyBB : jackColors.black34}
            />
          )
        }
        rightIcon={
          rightIcon && (
            <JackIcons
              name={rightIcon}
              fill={disableButton ? jackColors.greyBB : jackColors.black34}
            />
          )
        }
      >
        {rightButtonText}
      </ButtonJack>
    </div>
  );
};

export const CompanyBalanceAndTopUp = ({
  companyBalanceIdr,
  onClickTopUp,
  refresh,
  loading = false,
  style,
}) => {
  return (
    <div
      style={{
        width: "100%",
        top: -64,
        left: -1,
        position: "absolute",
        height: 64,
        padding: "12px 32px 12px 16px",
        backgroundColor: "#000000",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        overflow: "hidden",
        ...style,
      }}
    >
      <div className="d-flex-column" style={{ lineHeight: 1 }}>
        <div style={{ display: "flex", alignItems: "center", marginBottom: 4 }}>
          <GothamRegular className="font12 text-white">
            Company Balance
          </GothamRegular>
          <JackIcons
            name="refresh"
            style={{ height: 14, cursor: "pointer" }}
            className="refreshButton"
            onClick={refresh}
          />
        </div>
        {loading ? (
          <Skeleton width={120} height={20} className="mb-0 p-0" />
        ) : (
          <GothamBold className="font14 text-white mb-0">
            {companyBalanceIdr}
          </GothamBold>
        )}
        <img
          src={bigImg}
          style={{
            position: "absolute",
            right: 0,
            top: 3,
          }}
        />
      </div>
      <ButtonJack
        style={{
          backgroundColor: jackColors.black34,
          height: 32,
          minWidth: 85,
          zIndex: 10,
          color: jackColors.greenB9,
        }}
        isSmall={true}
        classNameText="font12"
        leftIcon={
          <JackIcons
            name="top_up"
            fill={jackColors.greenB9}
            style={{ width: 16.67, height: "auto" }}
          />
        }
        onClick={onClickTopUp}
      >
        Top Up
      </ButtonJack>
    </div>
  );
};

export const ReleasePaymentModalJack = ({
  isOpen,
  toggle,
  data,
  detailData,
  onClick,
  onClickTopUp,
  schedulePaymentState,
}) => {
  const { user, refetchUser, userLoading } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalanceIdr = `IDR ${formatCurrencyNoDecimal(balance)}`;
  const { totalPaymentRaw, titleModalHeader, title, originator } = detailData;
  const titleModal = !!titleModalHeader ? titleModalHeader : "Summary";

  const isPayroll = title === "Payroll";
  const isLocalTransfer = title === "Local Transfer";
  const isTribeNgerepotin = isPayroll || isLocalTransfer;

  const remainingBalance = balance - totalPaymentRaw;
  const isSufficient = remainingBalance >= 0 ? true : false;
  const isEnoughBalance = +balance >= totalPaymentRaw;

  //schedulepaymentrelated
  const spProducts = ["InvoiceTransaction", "LocalTransfer", "Payroll"];
  const canUseSchedulePayment = spProducts?.includes(originator);
  const [payment, setPayment] = schedulePaymentState;
  const { type, payload: schedulePayload } = payment;
  const isPayloadEmpty = isEmpty(schedulePayload);
  const isPayImmediately = type == "pay_immediately";
  const { due_date: invoiceDueDate } = data;

  const disableButton =
    isPayloadEmpty || isPayImmediately ? !isSufficient : false;

  const customButtonText = !(isPayloadEmpty || isPayImmediately)
    ? "Approve & Schedule"
    : null;

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) return;
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted]);

  return (
    <RightModal
      isOpen={isOpen}
      toggle={() => {
        // demo purpose
        if (isOpen) {
          eventsTracker(DEMO_CLOSE_RELEASE_TASK, {
            page_title: "transaction_confirm_pay",
          });
        }
        toggle();
      }}
      noPadding={true}
      width={[420]}
      array={[
        <WrapperModalButtons
          childrenButton={
            <>
              <CompanyBalanceAndTopUp
                companyBalanceIdr={companyBalanceIdr}
                onClickTopUp={onClickTopUp}
                refresh={refetchUser}
                loading={userLoading}
              />
              <ApproveBackButton
                rightText={customButtonText}
                onClick={() => {
                  eventsTracker(DEMO_RELEASE_TASK, {
                    page_title: "transaction_confirm_pay",
                  });
                  onClick();
                }}
                onClickBack={() => {
                  // demo purpose
                  eventsTracker(DEMO_BACK_RELEASE_TASK, {
                    page_title: "transaction_confirm_pay",
                  });
                  toggle();
                }}
                disableButton={disableButton}
                isTribeNgerepotin={isTribeNgerepotin}
              />
            </>
          }
        >
          <div
            style={{
              padding: 16,
              width: "100%",
              minHeight: "calc(100vh - 180px)",
              height: "auto",
              overflowY: "auto",
            }}
          >
            <GothamMedium
              style={{
                fontSize: 24,
                color: "#000000",
                marginBottom: 32,
                marginTop: 0,
              }}
            >
              {titleModal}
            </GothamMedium>
            <ModalCardLeftRight data={detailData} />
            {canUseSchedulePayment && (
              <span
                ref={(e) => {
                  if (!e) return;
                  setIsMounted(true);
                }}
              >
                <FloatingTooltip
                  isOpen={isHoveredInfo}
                  close={() => setIsHoveredInfo(false)}
                  title="Payment schedule"
                  body="You can set this to be paid now or schedule it for a date that suits you best."
                  tooltipStyle={{
                    translate: "-280px -90px",
                    width: 260,
                  }}
                  translateTriangle={`260px 58px`}
                  position="left"
                >
                  <ScheduledPaymentOptions
                    product={originator}
                    payment={payment}
                    setPayment={setPayment}
                    invoiceDueDate={invoiceDueDate}
                  />
                </FloatingTooltip>
              </span>
            )}
          </div>
        </WrapperModalButtons>,
      ]}
    />
  );
};
