import { Tooltip } from "reactstrap";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import styles from "./styles.module.css";

const MovingTooltip = ({ title, text, children }) => {
  return (
    <div className={styles["tooltip-container"]}>
      <div className={styles["tooltip"]}>
        <JackIcons name="CheckmarkCircle2" style={{ height: 20 }} />
        <div className={styles["tooltip-text"]}>
          <GothamMedium className="text-white">{title}</GothamMedium>
          <GothamRegular className="text-white font12">{text}</GothamRegular>
        </div>
      </div>
      {children}
    </div>
  );
};

export default MovingTooltip;