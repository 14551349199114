import { TopUpModal } from "../../../../../components/Modals";
import CardInfoBalance from "../components/CardInfoBalance";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const PrepaidTopUpByVA = () => {
  const { cardDetail, setCurrentScreen, rightModalToggle } =
    useCardDetailContext();
  const toggle = () => {
    setCurrentScreen("prepaidTopUpMethod");
  };

  const styles = {
    header: {
      marginTop: "32px",
    },
  };

  const customHeader = () => {
    return (
      <CardInfoBalance
        cardDetail={cardDetail}
        style={styles.header}
        text="Top up to:"
      />
    );
  };

  return (
    <TopUpModal
      modal={true}
      toggle={toggle}
      customToggle={rightModalToggle}
      url={`/cards/${cardDetail?.id}/virtual_accounts`}
      title="Transfer to Virtual Account"
      customHeader={customHeader}
      customCloseText="Go to Card Details"
      isPrepaid
      woTransition
      woSubtitle
      isShowBack
    />
  );
};

export default PrepaidTopUpByVA;
