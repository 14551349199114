import { jackColors } from "../../../assets/colors";
import { NextText } from "../components/OnboardModalPopup/components";
import { TooltipGuidedTour } from "../../notifications/guided-tour/components";

export const DEFAULT_STYLES = {
  buttonSkip: { padding: 0 },
  buttonNext: {
    padding: 6,
    backgroundColor: "transparent",
    border: `1px solid ${jackColors.greyE6}`,
  },
  options: {
    arrowColor: jackColors.neutral900,
    backgroundColor: jackColors.neutral900,
    textColor: jackColors.neutral100,
    width: "260px",
    zIndex: 10000,
  },
  tooltip: {
    borderRadius: 8,
    zIndex: 10000,
  },
  tooltipContent: {
    padding: 0,
    textAlign: "left",
    position: "relative",
    zIndex: 10000,
  },
  tooltipFooter: { marginTop: "6px" },
  overlay: {
    // zIndex: 10000,
    zIndex: 97, // demo ribbon z index dikecilin biar modal plg login/register plg depan ga ketutupan, tp skrg jd ketutupan sm overlay tourguide mkknya dikeiclin
  }
};

const DEFAULT_PROPERTIES = {
  placement: "right",
  disableBeacon: true,
  hideBackButton: false,
  disableCloseOnEsc: true,
  disableOverlayClose: true,
  disableScrolling: false,
  styles: DEFAULT_STYLES,
  hideCloseButton: true,
  locale: {
    last: <NextText>Got it</NextText>,
    close: <NextText>Got it</NextText>,
    back: <NextText>Back</NextText>,
    next: <NextText>Next</NextText>,
  },
  spotlightClicks: false,
};

export const TOOLTIP_DEFAULT_PROPERTIES = {
  ...DEFAULT_PROPERTIES,
  tooltipComponent: TooltipGuidedTour,
  // disableScrolling: false,
  disableScrolling: true,
  hideBackButton: true,
  showSkipButton: true,
  styles: {
    ...DEFAULT_STYLES,
    overlay: {
      backgroundColor: "transparent",
    },
    floaterStyles: {
      arrow: {
        color: jackColors.black34,
      },
    },
    options: {
      zIndex: 100000,
    },
    tooltip: {
      zIndex: 100000,
    },
    tooltipContent: {
      zIndex: 100000,
    },
  },
};

export const DEMO_GUIDANCE_PROPERTIES = {
  ...DEFAULT_PROPERTIES, 
  tooltipComponent: TooltipGuidedTour,
  disableButton: true,
  disableOverlayClose: true,
  disableScrolling: true,
  spotlightClicks: true,
}

export const REGISTER_EMAIL_STORAGE_NAME = "sent_register_demo_email";
export const SEND_EMAIL_AND_DATE_TABLE_STORAGE_NAME =
  "send_email_and_date_table_storage_name";
export const RESEND_DELAY_SECOND = 60;

// DEMO GUIDANCE
export const DEMO_MODULE = "demo_guidance";
export const USERS_MODULE = "users";

// GUIDED TOUR
export const LOCAL_MODULE = "local";
export const INTERNATIONAL_MODULE = "international";
export const PAYROLL_MODULE = "payroll";
export const BILL_MODULE = "bill";
export const REIMBURSEMENT_MODULE = "reimbursement";
export const VA_MODULE = "va";

// TOOLTIP
export const DRAFT_MODULE = "draft";
export const WORKFLOW_MODULE = "workflow";
export const TRANSACTIONS_MODULE = "transactions";
export const TASK_MODULE = "task";
export const APPROVAL_MODULE = "approval";

// PAGE AND TARGET PER MODULE
export const DEMO_FIRST_TARGET = "demo_first_target";
export const DEMO_SECOND_TARGET = "demo_second_target";
export const DEMO_THIRD_TARGET = "demo_third_target";
export const DEMO_FOURTH_TARGET = "demo_fourth_target";
export const DEMO_FIFTH_TARGET = "demo_fifth_target";
export const DEMO_SIXTH_TARGET = "demo_sixth_target";
export const DEMO_SEVENTH_TARGET = "demo_seventh_target";

export const LOCAL_FIRST_PAGE = "local_first";
export const LOCAL_SECOND_PAGE = "local_second";
export const LOCAL_THIRD_PAGE = "local_third";
export const LOCAL_FOURTH_PAGE = "local_fourth";

export const LOCAL_FIRST_TARGET = "local_first_target";
export const LOCAL_SECOND_TARGET = "local_second_target";
export const LOCAL_THIRD_TARGET = "local_third_target";
export const LOCAL_FOURTH_TARGET = "local_fourth_target";
export const LOCAL_FIFTH_TARGET = "local_fifth_target";
export const LOCAL_SIXTH_TARGET = "local_sixth_target";
export const LOCAL_SEVENTH_TARGET = "local_seventh_target";

export const PAYROLL_FIRST_PAGE = "payroll_first";
export const PAYROLL_SECOND_PAGE = "payroll_second";
export const PAYROLL_THIRD_PAGE = "payroll_third";
export const PAYROLL_FOURTH_PAGE = "payroll_fourth";

export const PAYROLL_FIRST_TARGET = "payroll_first_target";
export const PAYROLL_SECOND_TARGET = "payroll_second_target";
export const PAYROLL_THIRD_TARGET = "payroll_third_target";
export const PAYROLL_FOURTH_TARGET = "payroll_fourth_target";
export const PAYROLL_FIFTH_TARGET = "payroll_fifth_target";
export const PAYROLL_SIXTH_TARGET = "payroll_sixth_target";
export const PAYROLL_SEVENTH_TARGET = "payroll_seventh_target";

export const INTERNATIONAL_FIRST_PAGE = "international_first";
export const INTERNATIONAL_SECOND_PAGE = "international_second";
export const INTERNATIONAL_THIRD_PAGE = "international_third";

export const INTERNATIONAL_FIRST_TARGET = "international_first_target";
export const INTERNATIONAL_SECOND_TARGET = "international_second_target";
export const INTERNATIONAL_THIRD_TARGET = "international_third_target";
export const INTERNATIONAL_FOURTH_TARGET = "international_fourth_target";
export const INTERNATIONAL_FIFTH_TARGET = "international_fifth_target";

export const REIMBURSEMENT_FIRST_PAGE = "reimbursement_first";
export const REIMBURSEMENT_SECOND_PAGE = "reimbursement_second";
export const REIMBURSEMENT_THIRD_PAGE = "reimbursement_third";
export const REIMBURSEMENT_FOURTH_PAGE = "reimbursement_fourth";
export const REIMBURSEMENT_FIFTH_PAGE = "reimbursement_fifth";
export const REIMBURSEMENT_SIXTH_PAGE = "reimbursement_sixth"; // for reimburse with receipt

export const REIMBURSEMENT_FIRST_TARGET = "reimbursement_first_target";
export const REIMBURSEMENT_SECOND_TARGET = "reimbursement_second_target";
export const REIMBURSEMENT_THIRD_TARGET = "reimbursement_third_target";
export const REIMBURSEMENT_FOURTH_TARGET = "reimbursement_fourth_target";
export const REIMBURSEMENT_FIFTH_TARGET = "reimbursement_fifth_target";
export const REIMBURSEMENT_SIXTH_TARGET = "reimbursement_sixth_target";
export const REIMBURSEMENT_SEVENTH_TARGET = "reimbursement_seventh_target";
export const REIMBURSEMENT_EIGHTH_TARGET = "reimbursement_eighth_target";

export const BILL_FIRST_PAGE = "bill_first";
export const BILL_SECOND_PAGE = "bill_second";
export const BILL_THIRD_PAGE = "bill_third";
export const BILL_FOURTH_PAGE = "bill_fourth";
export const BILL_FIFTH_PAGE = "bill_fifth";

export const BILL_FIRST_TARGET = "bill_first_target";
export const BILL_SECOND_TARGET = "bill_second_target";
export const BILL_THIRD_TARGET = "bill_third_target";
export const BILL_FOURTH_TARGET = "bill_fourth_target";
export const BILL_FIFTH_TARGET = "bill_fifth_target";
export const BILL_SIXTH_TARGET = "bill_sixth_target";
export const BILL_SEVENTH_TARGET = "bill_seventh_target";
export const BILL_EIGHTH_TARGET = "bill_eighth_target";
export const BILL_NINTH_TARGET = "bill_ninth_target";

export const VA_FIRST_PAGE = "va_first";
export const VA_SECOND_PAGE = "va_second";
export const VA_THIRD_PAGE = "va_third";

export const VA_FIRST_TARGET = "va_first_target";
export const VA_SECOND_TARGET = "va_second_target";
export const VA_THIRD_TARGET = "va_third_target";
export const VA_FOURTH_TARGET = "va_fourth_target";
export const VA_FIFTH_TARGET = "va_fifth_target";
export const VA_SIXTH_TARGET = "va_sixth_target";
export const VA_SEVENTH_TARGET = "va_seventh_target";

export const DRAFT_FIRST_TARGET = "draft_first_target";
export const DRAFT_SECOND_TARGET = "draft_second_target";

export const WORKFLOW_FIRST_TARGET = "workflow_first_target";
export const WORKFLOW_SECOND_TARGET = "workflow_second_target";
export const WORKFLOW_THIRD_TARGET = "workflow_third_target";

export const TRANSACTIONS_FIRST_TARGET = "transactions_first_target";
export const TRANSACTIONS_SECOND_TARGET = "transactions_second_target";
export const TRANSACTIONS_THIRD_TARGET = "transactions_third_target";
export const TRANSACTIONS_FOURTH_TARGET = "transactions_fourth_target";
export const TRANSACTIONS_FIFTH_TARGET = "transactions_fifth_target";

export const TASK_FIRST_TARGET = "task_first_target";
export const TASK_SECOND_TARGET = "task_second_target";
export const TASK_THIRD_TARGET = "task_third_target";
export const TASK_FOURTH_TARGET = "task_fourth_target";

export const APPROVAL_FIRST_TARGET = "approval_first_target";
export const APPROVAL_SECOND_TARGET = "approval_second_target";
export const APPROVAL_THIRD_TARGET = "approval_third_target";
export const APPROVAL_FOURTH_TARGET = "approval_fourth_target";
export const APPROVAL_FIFTH_TARGET = "approval_fifth_target";
export const APPROVAL_SIXTH_TARGET = "approval_sixth_target";

export default DEFAULT_PROPERTIES;
