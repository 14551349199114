import { Fragment, useState } from "react";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import {
  SINGLE,
  DATA_ERROR,
  BATCH,
  TRX_TEMPLATE,
  PAYROLL,
  LOCAL,
  BILL,
  REIMBURSEMENT,
} from "../../constants";
import {
  retryResubmitBooleans,
  retryResubmitButtonTextDecider,
  retryResubmitValidator,
  summaryFormatter,
} from "../../helpers";
import { ConfirmationModalJack } from "../../../../../components/ButtonsJack/confirmation";
import { isEmpty } from "lodash";
import NextImage from "../../../../../components/NextImage";
import RetryResubmitSummaryModal from "../RetryResubmitSummaryModal";
import { useModalHook } from "../../../../../components/Modals";
import PINModal from "../../../../../components/PINModal/parent";
import CustomHoverInfo from "../../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamRegular } from "../../../../../components/Text";
import { useMutation } from "../../../../../tools/api";
import { useRouter } from "next/router";
import { ToasterHook } from "../../../../../contexts/ToasterContext";

const DrafterReleaserText = ({ name = "", role = "" }) => {
  return (
    <Fragment>
      Once you click the{" "}
      <span style={{ fontFamily: "GothamMedium" }}>Remind</span> button, {name}{" "}
      ({role}) will receive an email notification.
    </Fragment>
  );
};

const RetryResubmitButton = ({
  trx = TRX_TEMPLATE,
  trxType = SINGLE, // single | batch
  trxStatus = "", // failed | partial_failed | declined (should match to render)
  warningType = DATA_ERROR, // data_error | system_error | mixed_error
  failedTrxCount = 0, // should have more than 0 to render
  invalidFailedTrxCount = 0,
  drafter = {}, // should have drafter to render
  releaser = {}, // should have releaser to render
  style = {},
  resubmittedAt = "",
  remindTiming = {
    shouldWaitRemindTiming: false,
    enableRemindTiming: () => {},
    disableRemindTiming: () => {},
  },
}) => {
  const [confirmationData, setConfirmationData] = useState({});
  const [isHoveredWaitRemind, setIsHoveredWaitRemind] = useState(false);
  const [isLoadingRetryTrx, setIsLoadingRetryTrx] = useState(false);

  const { push } = useRouter();

  const { user } = useGetAuth();

  const { enableRemindTiming, shouldWaitRemindTiming } = remindTiming ?? {};

  const isOpenConfirmationModal = !isEmpty(confirmationData);

  const {
    isOpen: isOpenSummaryModal,
    open: openSummaryModal,
    close: closeSummaryModal,
  } = useModalHook();
  const {
    isOpen: isOpenPINModal,
    open: openPINModal,
    close: closePINModal,
  } = useModalHook();

  const {
    isRetry,
    isResubmit,
    isDrafter,
    isReleaser,
    isReminder,
    isBothDrafterAndReleaser,
  } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  const isValidRetryResubmit = retryResubmitValidator({
    drafter,
    releaser,
    trxStatus,
    warningType,
    failedTrxCount,
  });

  const retryUrlDecider = () => {
    switch (trx?.type) {
      case LOCAL:
        return `/local_transaction_batches/${trx?.id}/retry_transaction`;
      case PAYROLL:
        return `/local_transaction_batches/${trx?.batch_id}/retry_transaction`;
      case BILL:
        return `/invoice_transactions/${trx?.id}/retry_transaction`;
      case REIMBURSEMENT:
        return `/reimbursements/${trx?.id}/retry_transaction`;

      default:
        return "";
    }
  };

  const retryAfterSuccessDecider = () => {
    const defaultQuery = { type: "", id: trx?.id };

    switch (trx?.type) {
      case LOCAL:
        defaultQuery.type = "local_transfer";
        break;
      case PAYROLL:
        defaultQuery.type = "payroll";
        break;
      case BILL:
        defaultQuery.type = "invoice";
        break;
      case REIMBURSEMENT:
        defaultQuery.btcid = trx?.id;
        defaultQuery.type = "reimbursement";
        break;

      default:
        break;
    }

    return () => {
      remindTiming.disableRemindTiming();
      push({ pathname: "/success", query: defaultQuery });
    };
  };

  const { mutation: retryTrx } = useMutation({
    method: "post",
    url: retryUrlDecider(),
    afterSuccess: retryAfterSuccessDecider(),
    handleError: () => {
      setIsLoadingRetryTrx(false);
    },
  });

  const remindUrlDecider = () => {
    switch (trx?.type) {
      case LOCAL:
        if (isRetry) {
          return `/local_transaction_batches/${trx?.id}/reminder_mailer?type=retry`;
        }
        return `/local_transaction_batches/${trx?.id}/reminder_mailer`;
      case PAYROLL:
        if (isRetry) {
          return `/local_transaction_batches/${trx?.batch_id}/reminder_mailer?type=retry`;
        }
        return `/local_transaction_batches/${trx?.batch_id}/reminder_mailer`;
      case BILL:
        if (isRetry) {
          return `/invoice_transactions/${trx?.id}/reminder_mailer?type=retry`;
        }
        return `/invoice_transactions/${trx?.id}/reminder_mailer`;
      case REIMBURSEMENT:
        if (isRetry) {
          return `/reimbursements/${trx?.id}/reminder_mailer?type=retry`;
        }
        return `/reimbursements/${trx?.id}/reminder_mailer`;
      default:
        return "";
    }
  };

  const { successSnackBar } = ToasterHook();

  const remindAfterSuccessDecider = () => {
    enableRemindTiming();

    const drafterName = drafter?.name || drafter?.email || "-";
    const releaserName = releaser?.name || releaser?.email || "-";

    if (isRetry) {
      return successSnackBar({
        msg: `We've sent a reminder email to ${releaserName}`,
      });
    }

    if (isResubmit) {
      return successSnackBar({
        msg: `We've sent a reminder email to ${drafterName}`,
      });
    }
  };

  const { mutation: remind, loading: loadingRemind } = useMutation({
    method: "post",
    url: remindUrlDecider(),
    afterSuccess: remindAfterSuccessDecider,
  });

  const shallShowButton =
    isValidRetryResubmit && (isDrafter || isReleaser) && !resubmittedAt;

  if (!shallShowButton) return null;

  const buttonText = retryResubmitButtonTextDecider({
    user,
    drafter,
    releaser,
    warningType,
  });

  const handleOpenConfirmationModal = (confirmationData) => {
    setConfirmationData(confirmationData);
  };

  const handleCloseConfirmationModal = () => {
    setConfirmationData({});
  };

  const handleRemindConfirmation = () => {
    if (!isDrafter && !isReleaser) return;

    const type = () => {
      if (isDrafter) return "retry";
      if (isReleaser) return "resubmit";
      return "-";
    };

    const name = () => {
      const drafterName = drafter?.name || drafter?.email || "-";
      const releaserName = releaser?.name || releaser?.email || "-";

      if (isDrafter) return releaserName;
      if (isReleaser) return drafterName;
      return "-";
    };

    const role = () => {
      if (isDrafter) return "releaser";
      if (isReleaser) return "drafter";
      return "-";
    };

    const data = {
      img: "/images/retry-resubmit-confirmation.svg",
      title: `Remind ${name()} to ${type()} the transaction?`,
      message: <DrafterReleaserText name={name()} role={role()} />,
      buttonTextLeft: "Cancel",
      buttonTextRight: "Remind",
    };

    return handleOpenConfirmationModal(data);
  };

  const handleRetryConfirmation = () => {
    const data = {
      img: "/images/retry-resubmit-confirmation.svg",
      buttonTextLeft: "Back",
      buttonTextRight: "Retry Transaction(s)",
    };

    switch (trxType) {
      case SINGLE:
        return handleOpenConfirmationModal({
          ...data,
          title: "Retry transaction?",
          message: "Make sure you've done a final check before retrying.",
        });
      case BATCH:
        return handleOpenConfirmationModal({
          ...data,
          title: `Retry ${
            failedTrxCount - invalidFailedTrxCount
          } failed transaction(s)?`,
          message: "Make sure you've done a final check before retrying.",
        });
      default:
        return;
    }
  };

  const handleResubmitConfirmation = () => {
    switch (trx?.type) {
      case LOCAL:
        return push({
          pathname: `/local-transfer/create/${trx?.id}`,
          query: { action: "resubmit", ancestor_id: trx?.id },
        });
      case PAYROLL:
        return push({
          pathname: `/payroll/create/${trx?.id}`,
          query: {
            action: "resubmit",
            ancestor_id: trx?.batch_id,
            payroll_id: trx?.id,
          },
        });
      default:
        return;
    }
  };

  const handleClick = () => {
    if (isReminder) return handleRemindConfirmation();

    if (isBothDrafterAndReleaser) {
      if (isRetry) handleRetryConfirmation();
      if (isResubmit) return handleResubmitConfirmation();
      return;
    }

    if (isDrafter) return handleResubmitConfirmation();
    if (isReleaser) return handleRetryConfirmation();
    return;
  };

  const handleRemind = () => {
    const drafterId = Number(drafter?.id);
    const releaserId = Number(releaser?.id);
    if (isRetry) return remind({ user_id: releaserId });
    if (isResubmit) return remind({ user_id: drafterId });
    return;
  };
  const handleRetry = () => {
    openSummaryModal();
  };
  const handleResubmit = () => {};

  const handleConfirm = () => {
    if (isReminder) return handleRemind();

    if (isBothDrafterAndReleaser) {
      if (isRetry) return handleRetry();
      if (isResubmit) return handleResubmit();
      return;
    }

    if (isDrafter) return handleResubmit();
    if (isReleaser) return handleRetry();
    return;
  };

  const isWaitRemind = isReminder && shouldWaitRemindTiming;

  const isDisabled = isWaitRemind;

  const handleHoverWaitRemind = () => {
    if (isWaitRemind) setIsHoveredWaitRemind(true);
  };
  const handleUnhoverWaitRemind = () => {
    if (isWaitRemind) setIsHoveredWaitRemind(false);
  };

  const handleRetryTrx = ({ pin }) => {
    const isValid = pin?.length === 6;
    if (!isValid || isLoadingRetryTrx) return;
    setIsLoadingRetryTrx(true);
    retryTrx({ pin });
  };

  return (
    <Fragment>
      <div
        style={{ position: "relative", width: "100%" }}
        onMouseEnter={handleHoverWaitRemind}
        onMouseLeave={handleUnhoverWaitRemind}
      >
        <ButtonJack
          type="outline"
          style={{ width: "100%", ...style }}
          disabled={isDisabled}
          isLoading={loadingRemind}
          leftIcon={
            !loadingRemind && (
              <JackIcons
                name={isReminder ? "bell-outline" : "retry-resubmit"}
                fill={
                  isDisabled ? jackColors.neutral500 : jackColors.neutral900
                }
              />
            )
          }
          onClick={handleClick}
        >
          {buttonText}
        </ButtonJack>
        <CustomHoverInfo
          show={isHoveredWaitRemind}
          style={{
            width: "320px",
            padding: "12px",
            translate: "30px -120px",
            flexDirection: "column",
            gap: "8px",
          }}
          translateTrianglePoint="155px 54px"
          rotateTrianglePoint="-90deg"
          backgroundColor="white"
        >
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral800 }}
          >
            You're allowed to send a reminder only once within an hour.
          </GothamRegular>
        </CustomHoverInfo>
      </div>

      <ConfirmationModalJack
        isCenteredTitle
        modal={isOpenConfirmationModal}
        title={confirmationData?.title}
        text={confirmationData?.message}
        buttonTextLeft={confirmationData?.buttonTextLeft}
        buttonTextRight={confirmationData?.buttonTextRight}
        image={
          <div style={{ marginBottom: "32px" }}>
            <NextImage
              width={160}
              height={160}
              src={confirmationData?.img}
              alt="Retry Resubmit Image"
            />
          </div>
        }
        onClick={handleConfirm}
        toggle={handleCloseConfirmationModal}
        onClickLeft={handleCloseConfirmationModal}
      />

      <RetryResubmitSummaryModal
        isOpen={isOpenSummaryModal}
        summary={summaryFormatter(trx)}
        onConfirm={openPINModal}
        toggle={closeSummaryModal}
      />

      <PINModal
        isLoading={isLoadingRetryTrx}
        isOpen={isOpenPINModal}
        toggle={closePINModal}
        onSubmit={handleRetryTrx}
      />
    </Fragment>
  );
};

export default RetryResubmitButton;
