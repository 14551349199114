const Ellipse = () => {
  return (
    <svg
      width="423"
      height="352"
      viewBox="0 0 423 352"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.6" filter="url(#filter0_f_2998_190953)">
        <circle cx="190.75" cy="141.5" r="81.5" fill="#B9FC00" />
      </g>
      <defs>
        <filter
          id="filter0_f_2998_190953"
          x="-40.75"
          y="-90"
          width="463"
          height="463"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="BackgroundImageFix"
            result="shape"
          />
          <feGaussianBlur
            stdDeviation="75"
            result="effect1_foregroundBlur_2998_190953"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default Ellipse;
