import { jackColors } from "../../../assets/colors";
import { Avatar } from "../../../components/Avatar";
import { GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrency,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import CrossborderAvatar from "../../../assets/images/crossborder-single-avatar.svg";
import { useAlertDecider } from "../../../pageComponents/crossBorderSingleConfirmation";
import {
  billPaymentDetailDecider,
  pluralize,
} from "../../../pageComponents/invoiceComponents/data/formatter";
import crossborderIcon from "../../../assets/images/crossborder-single-avatar.svg";
import { JackIcons } from "../../../assets/jackIcons/parent";
import JackTransferIcon from "../../../pageComponents/jackTransferComponents/components/JackTransferIcon";

export const useCrossBorderSingleWorkflowApproverLogics = (transaction) => {
  const { user } = useGetAuth();
  const { approval_tasks, workflow_approval_tasks } = transaction || {};

  const currentTaskId = (
    workflow_approval_tasks ||
    approval_tasks ||
    []
  ).filter(
    ({ user_id, state, current_order }) =>
      state == "requested" && user_id == user?.id && current_order
  )[0]?.id;

  const canApproveDecider = () => {
    if (!approval_tasks) return true;

    approval_tasks.sort((prev, curr) => {
      const roleOrderDecider = (item) => item.role.split("-")[2];

      const prevRoleOrder = roleOrderDecider(prev);
      const currRoleOrder = roleOrderDecider(curr);

      if (Number(prevRoleOrder) > Number(currRoleOrder)) return 1;
      if (Number(prevRoleOrder) < Number(currRoleOrder)) return -1;
      return 0;
    });

    // find currentOrder

    // create orderObj
    const orderObj = {};

    approval_tasks.forEach((approval_task) => {
      const { role } = approval_task || {};
      if (!orderObj[role]) return (orderObj[role] = [approval_task]);
      orderObj[role].push(approval_task);
    });
    // create orderObj

    let currentOrder = "";

    const orderObjKeys = Object.keys(orderObj);

    orderObjKeys.forEach((key) => {
      if (currentOrder) return;

      const array = orderObj[key];

      const requestedArr = [];

      array.forEach((approval_task) => {
        if (approval_task.state !== "requested") return;
        requestedArr.push(approval_task.state);
      });

      if (requestedArr.length !== array.length) return;

      currentOrder = key;
    });
    // find currentOrder

    const currentApprovalTasks = approval_tasks.filter(
      ({ role }) => role === currentOrder
    );

    const foundApprover = currentApprovalTasks.find(
      ({ user_id }) => user_id === user?.id
    );

    if (foundApprover) return true;
    return false;
  };

  const canApprove = currentTaskId && canApproveDecider();

  return { canApprove, taskId: currentTaskId };
};

export const getBeneficiaryInternational = (data) => {
  const {
    receiving_registered_name,
    receiving_first_name,
    receiving_last_name,
    registered_name,
    firstname,
    lastname,
    bank_name,
    bank_account_number,
    recipient_email,
    iban,
    swift_bic_code,
  } = data?.beneficiary || {};

  const beneficiaryName =
    receiving_registered_name ||
    registered_name ||
    `${receiving_first_name || firstname || ""} ${
      receiving_last_name || lastname || ""
    }` ||
    recipient_email;

  const beneficiaryBank =
    bank_account_number || `${iban} (Iban)` || `${swift_bic_code} (Swift Code)`;

  return { beneficiaryName, beneficiaryBank };
};

export const useDataInternationalSingle = ({ data }) => {
  const {
    created_at,
    beneficiary,
    reference_id,
    destination_country,
    destination_currency,
    destination_amount,
    source_currency: source_currencyRaw,
    sent_amount,
    bank_fee,
    fee,
    rate,
    state,
    status,
    total_trx_amount,
    payment_channel,
    payment_method,
    approved_by_id,
    user,
  } = data || {};

  const { name: nameDrafter } = user || {};
  const { bank_name } = beneficiary || {};

  //  =========================================

  const source_currency = source_currencyRaw || "IDR";

  const { beneficiaryName, beneficiaryBank } =
    getBeneficiaryInternational(data);
  const transfezFee = Number(fee) - Number(bank_fee);
  const deliveryMethod = useAlertDecider({ data: payment_channel });
  const statusForLabel = state || status;

  //  =========================================

  const headerData = {
    title: `Transfer to ${beneficiaryName}`,
    value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
    icon: <img src={CrossborderAvatar} />,
    status: statusForLabel,
    details: `Created by ${nameDrafter}`,
  };

  const arrayDetails = [
    { title: "Created at", value: customDateFormatter(created_at || "") },
    {
      title: "Trasaction ID",
      value: reference_id,
    },
    {
      title: "Recipient",
      value: (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-2" style={{ maxWidth: 200 }}>
            <GothamRegular
              className="font12"
              style={{
                wordBreak: "break-word",
              }}
            >
              {beneficiaryName}
            </GothamRegular>
            <GothamRegular
              className="font10"
              style={{ color: jackColors.neutral700 }}
            >
              {bank_name} {bank_name && beneficiaryBank && "-"}{" "}
              {beneficiaryBank}
            </GothamRegular>
          </div>
          <div style={{ width: 24 }}>
            <Avatar name={beneficiaryName} size="small" />
          </div>
        </div>
      ),
    },
  ];

  const arrayDetailsAmount = [
    {
      title: "You pay",
      value: `${source_currency} ${formatCurrencyNoDecimal(
        Number(sent_amount) + Number(fee)
      )}`,
    },
    {
      title: "Settlement method",
      value: deliveryMethod,
    },
    {
      title: "Handling fee",
      value: `${source_currency} ${formatCurrencyNoDecimal(transfezFee)}`,
      littleDot: true,
    },
    {
      title: "Bank fee",
      value: `${source_currency} ${formatCurrencyNoDecimal(bank_fee)}`,
      littleDot: true,
    },
    {
      spacerNotFull: true,
    },
    {
      title: "Total fee",
      value: `${source_currency} ${formatCurrencyNoDecimal(fee)}`,
      customIcon: "minus-circle",
    },
    {
      title: "Exchange rate",
      value: `${destination_currency} 1 = ${source_currency} ${formatCurrency(
        rate
      )}`,
      customIcon: "divide-circle",
    },
    {
      spacer: true,
    },
    {
      title: "Recipient gets",
      value: `${destination_currency} ${formatCurrency(destination_amount)}`,
    },
  ];

  return { arrayDetails, headerData, arrayDetailsAmount };
};

export const useDetailsExtractor = (
  transactionDetails,
  isReleaser,
  canApprove
) => {
  const isUserReleaser = isReleaser && canApprove;
  const {
    totalAmount,
    amount,
    batch,
    total_amount,
    total_batch_amount,
    total_fee,
    type,
    merchant,
    recipient_name,
    name,
    fee,
    sent_amount,
    user,
    total_remaining_bill_and_spent,
    card_type,
    requester_name,
    payment_channel,
    total_trx_amount,
    local_transaction,
    local_transactions,
    reimbursement_child,
  } = transactionDetails || {};
  const {
    total_amount: totalAmountBatch,
    total_fee: totalFeeBatch,
    local_transactions: localTrxBatch,
  } = batch || {};
  const totalAmountPayroll = +totalAmountBatch + +totalFeeBatch;
  const paymentChannel = useAlertDecider({ data: payment_channel });
  const { beneficiaryName } = getBeneficiaryInternational(transactionDetails);

  const reimbursementBatchLength = reimbursement_child?.length;

  const defaultValue = {
    iconName: "invoice_payment",
    title: "default title",
    recipient_name: "John Wick",
    customHeader: true,
    totalPaymentRaw: 100000,
    array: [
      { spacer: true },
      { title: "left", value: "right" },
      { spacer: true },
      { title: "left", value: "right", customIcon: "plus_circle" },
    ],
  };

  const detailLibrary = {
    INVOICE_PAYMENT: {
      customHeader: true,
      iconName: "invoice_payment",
      title: "Bill Payment",
      recipient_name: recipient_name,
      totalPaymentRaw: Number(total_amount) + Number(local_transaction?.fee),
      array: billPaymentDetailDecider(transactionDetails, isReleaser),
      originator: "InvoiceTransaction",
    },
    REIMBURSEMENT: {
      customHeader: true,
      iconName: "reimbursement",
      title: "Reimbursement",
      totalPaymentRaw:
        Number(amount) +
        Number(local_transaction?.fee * reimbursementBatchLength),
      recipient_name: name,
      array: [
        {
          spacer: true,
        },
        {
          title: "Number of expense",
          value: `${reimbursementBatchLength} ${pluralize(
            reimbursementBatchLength,
            "Expense"
          )}`,
        },
        {
          title: "Total amount",
          value: `IDR ${formatCurrencyNoDecimal(amount)}`,
        },
        {
          title: "handling fee",
          value: `IDR ${formatCurrencyNoDecimal(
            local_transaction?.fee
          )} x ${reimbursementBatchLength}`,
          customIcon: "plus_circle",
        },
        { spacer: true },
        {
          title: "Final amount",
          value: `IDR ${formatCurrencyNoDecimal(
            Number(amount) +
              Number(local_transaction?.fee * reimbursementBatchLength)
          )}`,
        },
      ],
      originator: "Reimbursement",
    },
    LOCAL_TRANSFER: {
      customHeader: true,
      iconName: "send-money",
      title: "Local Transfer",
      customDesc: `Batch transfer: ${name}`,
      totalPaymentRaw: +total_batch_amount,
      recipient_name: name,
      array: isUserReleaser
        ? [
            {
              spacer: true,
            },
            {
              title: "Total amount",
              value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
            },
            {
              title: "Handling fee",
              value: `IDR ${formatCurrencyNoDecimal(total_fee)}`,
              customIcon: "plus_circle",
            },
            {
              spacer: true,
            },
            {
              title: `Total payment`,
              value: `IDR ${formatCurrencyNoDecimal(total_batch_amount)}`,
              rightBold: true,
            },
          ]
        : [
            {
              spacer: true,
            },
            {
              title: "Number of transaction",
              value: `${local_transactions?.length} ${pluralize(
                local_transactions?.length,
                "Transaction"
              )}`,
            },
            {
              title: `Total amount`,
              value: `IDR ${formatCurrencyNoDecimal(total_amount)}`,
              rightBold: true,
            },
          ],
      originator: "LocalTransfer",
    },
    PAYROLL: {
      customHeader: true,
      iconName: "payroll",
      title: "Payroll",
      customDesc: `Payroll: ${name}`,
      totalPaymentRaw: totalAmountBatch,
      recipient_name: name,
      array: isUserReleaser
        ? [
            {
              spacer: true,
            },
            {
              title: "Total amount",
              value: `IDR ${formatCurrencyNoDecimal(+totalAmountBatch)}`,
            },
            {
              title: "Handling fee",
              value: `IDR ${formatCurrencyNoDecimal(totalFeeBatch)}`,
              customIcon: "plus_circle",
            },
            {
              spacer: true,
            },
            {
              title: `Total payment`,
              value: `IDR ${formatCurrencyNoDecimal(totalAmountPayroll)}`,
              rightBold: true,
            },
          ]
        : [
            {
              spacer: true,
            },
            {
              title: "Number of transaction",
              value: `${localTrxBatch?.length} ${pluralize(
                localTrxBatch?.length,
                "Transaction"
              )}`,
            },
            {
              title: `Total amount`,
              value: `IDR ${formatCurrencyNoDecimal(totalAmountPayroll)}`,
              rightBold: true,
            },
          ],

      originator: "Payroll",
    },
    INTERNATIONAL_TRANSFER: {
      customHeader: true,
      customIcon: <img src={crossborderIcon} className="mr-2" />,
      title: "International Transfer",
      customDesc: `Transfer to ${beneficiaryName}`,
      totalPaymentRaw: +total_trx_amount,
      recipient_name: name,
      array: [
        {
          spacer: true,
        },
        {
          title: "Settlement method",
          value: paymentChannel,
        },
        {
          title: "Final Amount",
          value: `IDR ${formatCurrencyNoDecimal(total_trx_amount)}`,
          rightBold: true,
        },
      ],
    },
    BALANCE_TRANSFER: {
      customHeader: true,
      customIcon: <JackTransferIcon style={{ marginRight: "8px" }} />,
      title: "Balance Transfer",
      totalPaymentRaw: Number(transactionDetails?.amount || 0),
      customDesc: `Transfer balance to ${
        transactionDetails?.destination_business?.name || "-"
      }`,
      array: [
        {
          spacer: true,
        },
        {
          title: "Total Amount",
          value: `IDR ${formatCurrencyNoDecimal(
            Number(transactionDetails?.amount || 0)
          )}`,
        },
      ],
    },
    WITHDRAW: {
      customHeader: true,
      customIcon: (
        <JackTransferIcon name="withdraw" style={{ marginRight: "8px" }} />
      ),
      title: "Withdrawal",
      totalPaymentRaw: Number(transactionDetails?.amount || 0),
      customDesc: "Main balance withdrawal",
      array: [
        {
          spacer: true,
        },
        {
          title: "Total Amount",
          value: `IDR ${formatCurrencyNoDecimal(
            Number(transactionDetails?.amount || 0)
          )}`,
        },
      ],
    },
  };

  const upperCasedType = type?.toUpperCase();
  const data = upperCasedType ? detailLibrary[upperCasedType] : defaultValue;
  return data;
};
