import { CUSTOM, PRIMARY, REASON, SECONDARY } from "..";
import { jackColors } from "../../../assets/colors";
import { GothamRegular } from "../../Text";

const DefaultContent = ({ content = "", color = jackColors.neutral900 }) => {
  return (
    <GothamRegular woNewLine woFontColor className="font12" style={{ color }}>
      {content}
    </GothamRegular>
  );
};

const UpdateContent = ({ type, content }) => {
  switch (type) {
    case PRIMARY:
      return <DefaultContent content={content} />;
    case SECONDARY:
      return <DefaultContent content={content} color={jackColors.neutral700} />;
    case REASON:
      return (
        <div>
          <DefaultContent content="Reason:" />
          <DefaultContent content={content} />
        </div>
      );
    case CUSTOM:
      return content;
    default:
      return null;
  }
};

export default UpdateContent;
