import { colors } from "../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { cardStateDecider } from "../../../tools";

const CardBillingAddress = ({ cardDetail }) => {
  const { state } = cardDetail || {};
  const { isNotActive, isRejected } = cardStateDecider(state);
  if (isNotActive || isRejected) return null;

  const headerStyle = {
    fontSize: "12px",
    color: colors.neutral900,
    marginBottom: "8px",
  };

  const addressStyle = {
    fontSize: "12px",
    color: colors.neutral800,
    letterSpacing: "0.2px",
  };

  return (
    <div>
      <GothamMedium style={headerStyle}>Billing Address</GothamMedium>
      <GothamRegular style={addressStyle}>
        Jl. Casablanca Raya Kav. 88
      </GothamRegular>
      <GothamRegular style={addressStyle}>
        Prudential Centre Lt.22
      </GothamRegular>
      <GothamRegular style={addressStyle}>
        South Jakarta, Indonesia
      </GothamRegular>
    </div>
  );
};

export default CardBillingAddress;
