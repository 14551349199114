import { getUserRole } from "../../../../../../contexts/AuthContext";
import { colors } from "../../../../../../assets/colors";
import { GothamRegular, GothamMedium } from "../../../../../../components/Text";
import CardInfoBalance from "../../components/CardInfoBalance";
import BottomContainer from "../../components/BottomContainer";
import ParserRichTextArea from "../../../../../../components/ParserRichTextArea";
import { SeeMore } from "../../../../../../components/SeeMore";
import { LeftRightList } from "../../../../../../components/Lists";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import {
  formatCurrencyNoDecimal,
  unformatCurrency,
} from "../../../../../../components/tools";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";

const PrepaidTopUpByCompanySummary = ({
  cardDetail,
  result,
  setTopUpCompanyScreen,
  onSubmit,
  isLoading,
  mainBalance,
}) => {
  const { requested_amount: requestedAmount, reason } = result;
  const { isAdminOrSuperAdmin } = getUserRole();

  const backHandler = () => setTopUpCompanyScreen("form");

  const titleStyle = {
    fontSize: "24px",
    color: colors.neutral900,
  };

  const reasonStyle = {
    color: colors.neutral900,
    fontSize: "12px",
    letterSpacing: "0.2px",
  };

  const leftAlignStyle = {
    textAlign: "left",
  };

  const bottomContainerStyle = {
    borderTop: `1px solid ${colors.neutral500}`,
    backgroundColor: colors.neutral100,
    padding: "20px",
    left: "0px",
    width: "100%",
    display: "flex",
    gap: "16px",
    position: "fixed",
  };

  const buttonStyle = {
    width: "50%",
  };

  const list = [
    isAdminOrSuperAdmin && {
      left: "Company Balance",
      right: "IDR " + formatCurrencyNoDecimal(mainBalance),
      styleRight: leftAlignStyle,
    },
    isAdminOrSuperAdmin && {
      spacer: true,
    },
    {
      left: "Amount requested",
      right: "IDR " + formatCurrencyNoDecimal(requestedAmount),
      rightBold: true,
      styleRight: leftAlignStyle,
    },
    {
      left: "Reason",
      right: (
        <GothamRegular style={reasonStyle}>
          <SeeMore buttonAlign="left" height={80} width={170}>
            <ParserRichTextArea text={reason} />
          </SeeMore>
        </GothamRegular>
      ),
      styleRight: leftAlignStyle,
    },
  ];

  const title = isAdminOrSuperAdmin
    ? "Top up summary"
    : "Top up request summary";

  return (
    <>
      <GothamMedium style={titleStyle}>{title}</GothamMedium>
      <CardInfoBalance cardDetail={cardDetail} text="Top up to:" />
      <LeftRightList array={list} />
      <BottomContainer style={bottomContainerStyle}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={buttonStyle}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={buttonStyle}
          onClick={() =>
            onSubmit({
              action_type: "deposit",
              amount: +unformatCurrency(requestedAmount),
              reason,
            })
          }
          isLoading={isLoading}
        >
          {isAdminOrSuperAdmin ? "Top Up Card" : "Submit Request"}
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidTopUpByCompanySummary;
