import { LeftRightList } from "../../../../../components/Lists";
import { colors } from "../../../../../assets/colors";
import { Banner } from "../../../../../components/Banner";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../../../components/tools";
import { SeeMore } from "../../../../../components/SeeMore";
import UpdateTracker from "../../../../../components/UpdateTracker/parent";
import useCardWorkflow from "../useCardWorkflow";
import { isEmpty } from "lodash";

const CardAdjustLimitInfo = ({ cardDetail }) => {
  const {
    current_workflow_process: currentWorkflowProcess,
    get_approval_tasks: getApprovalTasks,
    is_adjusting_limit,
    is_seen: isSeen,
    limit_value: currentLimit,
  } = cardDetail || {};
  const {
    amount: newLimit,
    value: isAdjustingLimit,
    limit_type: limitType,
    reason,
  } = is_adjusting_limit || {};
  const isCurrentWorkflowAdjustLimit =
    currentWorkflowProcess === "request_adjust_limit";
  const formattedLimitType =
    limitType === "ONE_TIME_USE" ? "One time" : "Monthly";

  if ((isSeen || !isCurrentWorkflowAdjustLimit) && !isAdjustingLimit)
    return null;

  const isHaveApprovalTasks = !isEmpty(getApprovalTasks);
  const isShowLimitInfo = isAdjustingLimit && isHaveApprovalTasks;

  const { progressArray, rejectionNote } = useCardWorkflow(cardDetail);

  const titleListStyle = {
    color: colors.neutral900,
    marginBottom: "16px",
  };

  const bannerStyle = {
    marginBottom: "16px",
  };

  const reasonStyle = {
    color: colors.neutral900,
    fontSize: "12px",
    letterSpacing: "0.2px",
  };

  const topComponent = (
    <GothamMedium style={titleListStyle}>
      New limit has been requested!
    </GothamMedium>
  );

  const bottomComponent = (
    <Banner
      type="warning"
      msg="Please note that card limit changes will impact the company's monthly limit. The limit will only be adjusted if the request is approved on all steps."
      style={bannerStyle}
    />
  );

  const adjustLimitInfos = [
    {
      left: "Current limit",
      right: `IDR  ${formatCurrencyNoDecimal(currentLimit)}`,
    },
    {
      left: "New limit",
      right: `IDR  ${formatCurrencyNoDecimal(newLimit)}`,
    },
    {
      left: "Limit Renewal",
      right: formattedLimitType,
    },
    {
      left: "Reason",
      right: (
        <SeeMore buttonAlign="right" height={80}>
          <GothamRegular style={reasonStyle}>{reason || "-"}</GothamRegular>
        </SeeMore>
      ),
    },
  ];

  return (
    <>
      <UpdateTracker updates={progressArray} rejectionNote={rejectionNote} />
      {isShowLimitInfo && (
        <LeftRightList
          topComponent={topComponent}
          array={adjustLimitInfos}
          gap={16}
          bottomComponent={bottomComponent}
        />
      )}
    </>
  );
};

export default CardAdjustLimitInfo;
