import { Avatar } from "./Avatar";
import { GothamMedium, GothamRegular } from "./Text";
import { colors } from "../assets/colors";
import { useGetAuth, getUserRoleBooleans } from "../contexts/AuthContext";
import { BadgeRole } from "./Badge";
import { useDebounce } from "./tools";
import { isEmpty } from "lodash";
import { titleCase } from "change-case";

const Profile = ({ withRole = false }) => {
  const debouncedWithRole = useDebounce(withRole, 400);
  const { user } = useGetAuth();
  const { roleName } = getUserRoleBooleans(user || {});
  const { partner, email, name } = user || {};
  const { name: companyName } = partner || {};

  const nameOrEmail = name || email;

  // const finalName = nameOrEmail
  
  // demo purpose dihardcode jadi demo + rolename
  const finalName = `Demo ${roleName}` 

  const textStyle = {
    color: colors.neutral900,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  };

  return (
    <div
      style={{
        alignItems: "center",
        gap: "12px",
        width: "100%",
      }}
      className="d-flex align-items-center"
    >
      <Avatar name={finalName} size="medium" />
      <div
        style={{
          gap: "4px",
          width: withRole ? "100%" : 132,
          maxWidth: 182,
          flexDirection: "column",
        }}
        className="d-flex"
      >
        <GothamMedium className="font12" style={textStyle}>
          {titleCase(companyName)}
        </GothamMedium>
        <div className="d-flex align-items-center">
          <GothamRegular
            className="font10"
            style={{ ...textStyle, maxWidth: withRole ? 90 : "auto" }}
          >
            {finalName}
          </GothamRegular>
          {debouncedWithRole && withRole && (
            <div
              style={{
                marginLeft: 4,
              }}
              className={withRole ? "fade-in" : ""}
            >
              <BadgeRole role={roleName} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;
