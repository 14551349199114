import { SEND_EMAIL_AND_DATE_TABLE_STORAGE_NAME } from "../constants";

const useSendEmailAndDateTable = () => {
  const getSendEmailAndDateTable = () => {
    return JSON.parse(
      localStorage.getItem(SEND_EMAIL_AND_DATE_TABLE_STORAGE_NAME),
    );
  };
  const storeSendEmailAndDateTable = (email) => {
    const sendEmailAndDateTable = getSendEmailAndDateTable();

    if (sendEmailAndDateTable == null) {
      const payload = {};
      payload[email] = new Date();
      localStorage.setItem(
        SEND_EMAIL_AND_DATE_TABLE_STORAGE_NAME,
        JSON.stringify(payload),
      );
      return;
    }

    const payload = { ...sendEmailAndDateTable };
    payload[email] = new Date();
    localStorage.setItem(
      SEND_EMAIL_AND_DATE_TABLE_STORAGE_NAME,
      JSON.stringify(payload),
    );
    return;
  };

  return {
    getSendEmailAndDateTable,
    storeSendEmailAndDateTable,
  };
};

export default useSendEmailAndDateTable;
