import { useRouter } from "next/router";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDemo } from "../../../../contexts/DemoContext";
import { TooltipContent } from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  LOCAL_FIFTH_TARGET,
  LOCAL_FOURTH_TARGET,
  LOCAL_SEVENTH_TARGET,
  LOCAL_SIXTH_TARGET,
  PAYROLL_FIFTH_TARGET,
  PAYROLL_MODULE,
  PAYROLL_SEVENTH_TARGET,
  PAYROLL_SIXTH_TARGET,
} from "../../constants";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_GUIDED_TOUR_BACK,
  DEMO_GUIDED_TOUR_NEXT,
} from "../../constants/events";

const stepsDecider = (isCsv = false, module = "") => {
  const isPayroll = module === PAYROLL_MODULE;
  const recipient = isPayroll ? "employee" : "recipient";

  const DEFAULT_PROPERTIES_MOD = {
    ...DEFAULT_PROPERTIES,
    styles: {
      ...DEFAULT_PROPERTIES.styles,
      overlay: {
        zIndex: 999
      }
    }
  }

  const defaultSteps = [
    {
      ...DEFAULT_PROPERTIES_MOD,
      // target: `#create-${moduleName}-name`,
      target: `#${LOCAL_FOURTH_TARGET}_name`,
      content: (
        <TooltipContent
          title={
            isPayroll
              ? "Inputting employee name"
              : isCsv
              ? "Inputting recipient name"
              : "Inputting your recipient details"
          }
          description={
            isPayroll
              ? "Ensure the name is identical to the employee's bank account name to prevent errors."
              : isCsv
              ? "Ensure the name is identical to the recipient's bank account name to prevent errors."
              : "Add a new recipient or simply choose from existing recipient list."
          }
        />
      ),
      disableScrolling: true,
      eventPayload: {
        activity_type: isPayroll ? "create_payroll" : "create_local_transfer",
        guided_tour_activity: "input_recipient_detail",
      },
    },
    {
      ...DEFAULT_PROPERTIES_MOD,
      // target: `#${moduleName}-field-focus-name`,
      target: `#${isPayroll ? PAYROLL_FIFTH_TARGET : LOCAL_FIFTH_TARGET}`,
      content: (
        <TooltipContent
          title={`Inputting ${recipient} name`}
          description={`Simply type in the ${recipient}'s name and click ‘Enter’ to save it.`}
        />
      ),
      placement: "bottom",
      disableBeacon: isPayroll,
      disableScrolling: true,
      eventPayload: {
        activity_type: isPayroll ? "create_payroll" : "create_local_transfer",
        guided_tour_activity: "input_recipient_name",
      },
      spotlightClicks: false,
      // styles: {
      //   ...DEFAULT_PROPERTIES.styles,
      //   overlay: {
      //     zIndex: 999
      //   },
      // },
    },
    {
      ...DEFAULT_PROPERTIES_MOD,
      // target: `#${moduleName}-table`,
      target: `#${isPayroll ? PAYROLL_SIXTH_TARGET : LOCAL_SIXTH_TARGET}`,
      content: (
        <TooltipContent
          title="Complete the remaining details"
          description="We’ve pre-filled some details for you. Later, be sure to fill in all required fields (email and transfer note are optional)."
        />
      ),
      placement: "bottom",
      disableBeacon: isPayroll,
      disableScrolling: true,
      eventPayload: {
        activity_type: isPayroll ? "create_payroll" : "create_local_transfer",
        guided_tour_activity: "complete_remaining_details",
      },
    },
    {
      ...DEFAULT_PROPERTIES_MOD,
      // target: `#add-button-${isPayroll ? "payroll" : "local-transfer"}`,
      target: `#${isPayroll ? PAYROLL_SEVENTH_TARGET : LOCAL_SEVENTH_TARGET}`,
      content: (
        <TooltipContent
          title={`Want to add other ${recipient}s?`}
          description={`You can add an empty row or choose from the list of previous ${recipient}s.`}
        />
      ),
      placement: "bottom",
      disableBeacon: isPayroll,
      disableScrolling: true,
      eventPayload: {
        activity_type: isPayroll ? "create_payroll" : "create_local_transfer",
        guided_tour_activity: "add_other_recipients",
      },
    },
  ];

  if (isCsv) {
    defaultSteps.splice(2, 1);
  }
  return defaultSteps;
};

const useTransferDetailsProps = ({ module = "", methods = "" }) => {
  const isCsv = methods === "csv";
  const { push, query } = useRouter();
  const { setGuidanceState } = useDemo();

  const callback = ({ data, setGuidedTourState, defaultState, stepIndex }) => {
    const { action, status, type, index, step } = data ?? {};
    const { eventPayload = {} } = step ?? {};

    const setTourStateObject = (obj) => {
      const { isRunning = true, stepIndex } = obj ?? {};
      setGuidedTourState((prev) => ({
        ...prev,
        isRunning,
        stepIndex,
      }));
    };
    const setStateOnTimeout = (
      obj,
      timeout = 400,
      customFunction = () => {}
    ) => {
      setTimeout(() => {
        setTourStateObject(obj);
        customFunction && customFunction();
      }, timeout);
    };

    const defaultStepIndex = {
      stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
    };

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      eventsTracker(
        action === ACTIONS.PREV ? DEMO_GUIDED_TOUR_BACK : DEMO_GUIDED_TOUR_NEXT,
        eventPayload
      );
      push({
        query: {
          ...query,
          menu: "all_transactions",
          step: stepIndex || index,
          action: action === ACTIONS.PREV ? "prev" : "next",
        },
      });
      setStateOnTimeout(defaultStepIndex, 100);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setGuidedTourState(defaultState);
      setGuidanceState((prev) => ({
        ...prev,
        isTourRunning: false,
      }));
    }
  };

  const steps = stepsDecider(isCsv, module);

  return { steps, callback };
};

export default useTransferDetailsProps;
