import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import { Fragment } from "react";
import { jackColors } from "../../../../assets/colors";
import PatternRight from "../../../../assets/images/demo-ribbon-pattern-2.svg";
import PatternLeft from "../../../../assets/images/demo-ribbon-pattern.svg";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../components/Modals";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
  TextInlineRegular,
} from "../../../../components/Text";
import { getUserRole, useGetAuth } from "../../../../contexts/AuthContext";
import { useDemo } from "../../../../contexts/DemoContext";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DEMO_PAUSE_GUIDED_TOUR,
  DEMO_PLAY_GUIDED_TOUR,
  DEMO_REGISTER_FROM_RIBBON,
  DEMO_SWITCH_ROLE,
} from "../../constants/events";
import SwitchAccountModal, { SetupRolesModal } from "./components";
import styles from "./styles.module.css";

const DemoRibbon = ({}) => {
  const { guidanceState } = useDemo();
  const {
    isTourRunning = false,
    guidanceFunction,
    eventPayload = {},
  } = guidanceState ?? {};
  const { pathname, query } = useRouter();
  const { step, state, type } = query ?? {};

  const isExpiredPage = pathname === "/demo-expired";
  if (isExpiredPage) return null;

  // pathname with guidedtour
  const localPathnames = ["/local-transfer/create", "/local-transfer/[id]"];
  const payrollPathnames = ["/payroll/create", "/payroll/create/[id]"];
  const crossborderPathnames = [
    "/cross-border/single",
    "/cross-border/single/recipient",
    "/cross-border/single/additional",
  ];
  const reimbursementPathnames = ["/reimbursement/create"];
  const billPathnames = ["/invoice-payment/create"];
  const vaPathnames = ["/virtual-account/create"];
  const pathnamesWithGuidedTour = [
    ...crossborderPathnames,
    ...billPathnames,
    ...vaPathnames,
  ];
  const isReimburse = reimbursementPathnames.includes(pathname);
  const isReimburseWithGuidedTourModeOn = isReimburse && step !== "submit";
  const isLocalOrPayroll = [...localPathnames, ...payrollPathnames].includes(
    pathname
  );
  const statesWithoutGuidedTour = ["validating", "confirmation"];
  const isLocalOrPayrollWithGuidedTourModeOn =
    isLocalOrPayroll && !statesWithoutGuidedTour.includes(state);
  const isGuidedTourModeOn =
    pathnamesWithGuidedTour.includes(pathname) ||
    isReimburseWithGuidedTourModeOn ||
    isLocalOrPayrollWithGuidedTourModeOn;

  // for pathnames with special state ribbon
  const isLocalOrPayrollWithoutGuidedTourModeOn =
    isLocalOrPayroll && statesWithoutGuidedTour.includes(state);
  const successPath = ["/success"];
  const successTransactionTypes = [
    "local_transfer",
    "payroll",
    "balance_transfer",
    "reimbursement",
    "invoice",
    "single_transaction",
  ];
  const isSuccessExcluded =
    successPath.includes(pathname) && successTransactionTypes.includes(type);
  const jacktojackPath = ["/jack-transfer"];
  const crossborderExcludedPath = ["/cross-border/single/confirmation"];
  const combinedExcludedPath = [
    ...jacktojackPath,
    ...crossborderExcludedPath,
  ].includes(pathname);
  const reimbursementExcludedSteps = ["submit"];
  const isReimbursementExcludedPath =
    isReimburse && reimbursementExcludedSteps.includes(step);
  const isExcluded =
    isLocalOrPayrollWithoutGuidedTourModeOn ||
    isSuccessExcluded ||
    combinedExcludedPath ||
    isReimbursementExcludedPath;

  // demo lifetime
  const { user, userLoading } = useGetAuth();
  const { partner } = user ?? {};
  const { expired_at } = partner ?? {};

  const endDate = new Date(expired_at);
  const today = new Date();
  const difference = endDate.getTime() - today.getTime(); // in ms
  const daysRemaining = Math.ceil(difference / (1000 * 60 * 60 * 24));
  const isPlural = +daysRemaining > 1;
  const daysRemainingFinalStr = `${daysRemaining} day${isPlural ? "s" : ""}`;
  const showLifetimeBadge = !!daysRemaining;
  const loading = userLoading;

  const subtextDecider = () => {
    if (isExcluded) {
      return (
        <Fragment>
          You‘re currently in <TextInlineMedium>DEMO</TextInlineMedium> mode,
          anything you do here won‘t affect the real Jack environment.
        </Fragment>
      );
    }
    if (isGuidedTourModeOn) {
      if (isTourRunning) {
        return (
          <Fragment>
            Click <TextInlineMedium>‘Pause Guidance’ </TextInlineMedium> to
            explore on your own.
          </Fragment>
        );
      }
      return (
        <Fragment>
          Click <TextInlineMedium>‘Play Guidance’</TextInlineMedium> for helpful
          hints and suggestions.
        </Fragment>
      );
    }
    if (!showLifetimeBadge || loading) return null;
    return (
      <Fragment>
        <TextInlineMedium>
          Demo ends in {daysRemainingFinalStr}.&nbsp;
        </TextInlineMedium>
        &nbsp;Enjoy secure & seamless control over your finance with us!{" "}
        <a target="_blank" href={hrefDecider()}>
          <TextInlineMedium
            onClick={onRegister}
            style={{
              color: jackColors.greenB9,
              textDecoration: "underline",
              cursor: "pointer",
            }}
          >
            Register here
          </TextInlineMedium>
        </a>
      </Fragment>
    );
  };

  // bawa nama, email, business_name, phone number
  const name = localStorage.getItem("demo_name");
  const email = localStorage.getItem("demo_email");
  const mobile = localStorage.getItem("demo_mobile");
  const business_name = localStorage.getItem("demo_business_name");
  const business_id = localStorage.getItem("demo_business_id");
  const user_details = {
    name,
    business_name,
    email,
    mobile,
    business_id,
  };

  const hrefDecider = () => {
    let queries = "?";
    Object.keys(user_details)?.forEach((key, i) => {
      queries += `${i !== 0 ? "&" : ""}${key}=${user_details[key]}`;
    });
    return `https://business.itsjack.com/register${queries}`;
  };

  const payload = payloadDecider(pathname);
  const onRegister = () => {
    eventsTracker(DEMO_REGISTER_FROM_RIBBON, payload);
  };

  const pathnamesWithHigherZIndex = [
    "/local-transfer/create",
    "/local-transfer/create/[id]",
    "/payroll/create",
    "/payroll/create/[id]",
    "/task",
    "/transactions",
  ];
  const shouldLowerZIndex =
    !!query?.showCard || !pathnamesWithHigherZIndex.includes(pathname);

  const zIndex = shouldLowerZIndex ? 99 : 9999;

  return (
    <div
      className="d-flex"
      style={{
        width: "100%",
        height: 40 + 8 + 8,
        background: "black",
        justifyContent: "space-between",
        alignItems: "center",
        padding: "8px 0px",
        position: "fixed",
        top: 0,
        left: 0,
        zIndex, // demo hidden
        background: "#1A1A1A",
        // zIndex: 1000,
      }}
    >
      <div
        style={{
          backgroundImage: `url(${PatternLeft})`,
          height: "56px",
          width: "200px",
          // position: "fixed",
          // left: 0,
        }}
      ></div>
      {!loading && showLifetimeBadge && (
        <div className="d-flex" style={{ gap: "8px", alignItems: "center" }}>
          <JackIcons name="info" fill="white" style={{ height: "20px" }} />
          <GothamRegular style={{ color: "white" }}>
            {subtextDecider()}
          </GothamRegular>
          {isGuidedTourModeOn && (
            <GuidanceActionButton
              isTourRunning={isTourRunning}
              guidanceFunc={guidanceFunction}
              eventPayload={eventPayload}
            />
          )}
        </div>
      )}

      {!isGuidedTourModeOn && !isExcluded && showLifetimeBadge && (
        <GeneralButton user={user} loading={userLoading} />
      )}

      <div
        style={{
          backgroundImage: `url(${PatternRight})`,
          height: "56px",
          width: "200px",
        }}
      ></div>
    </div>
  );
};

export default DemoRibbon;

export const GuidanceActionButton = ({
  isTourRunning = false,
  guidanceFunc = () => {},
  eventPayload = {},
}) => {
  const pause = "Pause";
  const play = "Play";
  const state = isTourRunning ? pause : play;
  const eventName = isTourRunning
    ? DEMO_PAUSE_GUIDED_TOUR
    : DEMO_PLAY_GUIDED_TOUR;

  return (
    <ButtonJack
      onClick={() => {
        guidanceFunc();
        !isEmpty(eventPayload) && eventsTracker(eventName, eventPayload);
      }}
      style={{
        background: "white",
        borderRadius: "40px",
        padding: "0px 18px",
        margin: "8px 0",
        color: jackColors.black34,
        height: "32px",
      }}
      rightIcon={
        <JackIcons
          name={`${state}Circle`}
          fill={isTourRunning ? jackColors.black34 : jackColors.green06}
          style={{ height: 20 }}
        />
      }
    >
      {`${state} Guidance`}
    </ButtonJack>
  );
};

export const GeneralButton = ({ user = {}, loading = false }) => {
  const { isOpen, toggle } = useModalHook();
  const { isOpen: isOpenSetupRoles, toggle: toggleSetupRoles } = useModalHook();
  const { pathname } = useRouter();
  const { partner } = user ?? {};
  const { is_demo_initialized = false } = partner ?? {};
  const { roleName } = getUserRole();

  const payload = payloadDecider(pathname);

  const onSwitchAccount = () => {
    eventsTracker(DEMO_SWITCH_ROLE, !isEmpty(payload) && payload);
    if (!is_demo_initialized) {
      toggleSetupRoles();
      return;
    }
    toggle();
  };

  return (
    <div
      className="d-flex"
      style={{ gap: "10px", position: "fixed", right: "32px", zIndex: 1 }}
    >
      <ButtonJack
        onClick={onSwitchAccount}
        disabled={loading}
        className={styles["role-btn"]}
        style={{
          borderRadius: "40px",
          padding: "6px",
          margin: "8px 0",
          background: "white",
          height: "32px",
        }}
        leftIcon={
          <JackIcons
            name="Person"
            fill={jackColors.black34}
            style={{ height: 20 }}
          />
        }
        rightIcon={
          <JackIcons
            name="ChevronDown"
            fill={jackColors.black34}
            style={{ height: 20 }}
          />
        }
      >
        <div style={{ textAlign: "start" }}>
          <GothamMedium
            style={{
              color: jackColors.black34,
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
            }}
          >
            {roleName}
          </GothamMedium>
        </div>
      </ButtonJack>
      <SwitchAccountModal isOpen={isOpen} toggle={toggle} />
      <SetupRolesModal isOpen={isOpenSetupRoles} toggle={toggleSetupRoles} />
    </div>
  );
};

const payloadDecider = function (pathname) {
  switch (pathname) {
    case "/people-management":
      return { page_title: "user_and_teams" };
    case "/local-transfer/[id]":
      return {
        page_title: "confirm_to_submit",
        activity_type: "create_local_transfer",
      };
    case "/payroll/create/[id]":
      return {
        page_title: "confirm_to_submit",
        activity_type: "create_payroll",
      };
    default:
      return pathname.replace("/", "");
  }
};
