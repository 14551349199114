import { useRouter } from "next/router";
import React, { useContext, useState } from "react";
import {
  getUserButtonBooleans,
  useGetAuth,
  getUserRole,
} from "../../AuthContext";
import { SidebarComponent } from "./component/parent";
import { usePeopleManagementAccess } from "../../../pageComponents/peopleManagementComponents/logics/hooks";
import { isEmpty } from "lodash";
import { TaskContext } from "../../TaskContext/parent";
import { useLayoutSidebars } from "../logics";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import logoutImg from "../../../assets/images/logout-modal.svg";
import { eventsTracker } from "../../../universalFunctions/events";
import { AlertAttentionContext } from "../../AlertAttentionContext";
import { useVaAccess } from "../../../pageComponents/virtualAccountComponents/common/hooks";
import { CardsDemoModal } from "../../../pageComponents/productDemoComponents/components";
import { useModalHook } from "../../../components/Modals";
import DemoGeneralModal from "../../../pageComponents/productDemoComponents/components/DemoGeneralModal";
import { restrictedPath } from "../../../pageComponents/productDemoComponents/constants/pathname";
import { DEMO_ENTER_CARD } from "../../../pageComponents/productDemoComponents/constants/events";
// import { useCategoryFlag } from "../../../pageComponents/categoryComponents/general/hooks";

export const Sidebar = ({ onClick = () => {} }) => {
  const { canViewApiDisbursement, canViewWorkflow, canCustomReceipt } =
    getUserButtonBooleans();
  const { isAllowed: canViewPeopleManagement } = usePeopleManagementAccess();
  const { isAllowed: canViewVaPage } = useVaAccess();
  const { totalTask } = useContext(TaskContext);
  const { alertsAttentionsTotalData } = useContext(AlertAttentionContext);

  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const [openDropdownButton, setOpenDropdownButton] = useState(false);
  const {
    isOpen: isOpenCardsDemoModal,
    open: openCardsDemoModal,
    close: closeCardsDemoModal,
  } = useModalHook();
  const { isOpen: isOpenBlockAccessModal, toggle: toggleBlockAccessModal } =
    useModalHook();

  const { user } = useGetAuth();
  const { card_business: cardBusiness, get_active_bill: billingPayment } =
    user?.partner || {};
  const { purposes, state: cardBusinessState } = cardBusiness || {};
  const isFinishedOnboarding = cardBusinessState === "finished";
  const isOnReviewOnboarding = cardBusinessState === "on_review";
  const isWaitingForCardPayment = !isEmpty(billingPayment);
  const isRedirectToCardOnboarding =
    !isEmpty(purposes) &&
    !isFinishedOnboarding &&
    !isOnReviewOnboarding &&
    false;
  const { isAdminOrSuperAdmin } = getUserRole();
  const canViewCards = isAdminOrSuperAdmin || isFinishedOnboarding;
  const canViewPlans = isAdminOrSuperAdmin;

  // const { categoryFlags, hasSeenCategory, addCategoryFlag } = useCategoryFlag();

  const { push, pathname } = useRouter();

  const { isSetting, settingPathnames } = useLayoutSidebars();

  const array = isSetting
    ? [
        {
          title: "Account",
          array: [
            {
              iconName: "settings_applications",
              text: "General",
              value: "/settings-jack",
            },
            {
              iconName: "account_balance",
              text: "Bank Accounts",
              value: "/bank-account",
            },
            {
              iconName: "BellOutline",
              text: "Notifications",
              value: "/notifications",
            },
          ],
        },
        {
          title: "General",
          array: [
            canViewPlans && {
              iconName: "logogram",
              text: "Plans",
              value: "/plans",
            },
            true && {
              iconName: "category-outline",
              text: "Category",
              value: "/category",
            },
            {
              iconName: "team management",
              value: "/people-management",
              text: "Users & Teams",
              eventName: "enter_user_and_teams",
              payload: {
                page_title: "profile_bar",
              },
            },
            canViewWorkflow && {
              iconName: "Workflow",
              text: "Workflow",
              value: "/workflow",
            },
            canCustomReceipt && {
              iconName: "customizedReciept",
              text: "Customize Receipt",
              value: "/customize-receipt",
            },
            {
              iconName: "code-outline",
              text: "Developer Tools",
              value: "/dev-tools",
            },
            canViewPlans && {
              iconName: "payment_cycleV2",
              text: "Payment Cycle",
              value: "/payment-cycle",
            },
          ],
        },
      ]
    : [
        {
          title: "For You",
          array: [
            {
              iconName: "task",
              text: "Task",
              number: totalTask,
              isOrange: true,
              value: "/task",
            },
            {
              iconName: "attention",
              text: "Alerts & Attention",
              number: alertsAttentionsTotalData,
              value: "/alerts-and-attention",
            },
          ],
        },
        {
          title: "Navigation",
          array: [
            {
              iconName: "Dashboard",
              text: "Dashboard",
              value: "/dashboard",
            },
            {
              iconName: "Transaction",
              text: "Transaction",
              value: "/transactions",
            },
            // canViewCards && {
            //   iconName: "Corporate card",
            //   text: "Corporate Card",
            //   value: isRedirectToCardOnboarding ? "/cards/onboard" : "/cards",
            //   // ...(isWaitingForCardPayment && isAdminOrSuperAdmin
            //   //   ? {
            //   //       number: "!",
            //   //       isOrange: true,
            //   //       counterStyle: {
            //   //         minWidth: "20px",
            //   //       },
            //   //     }
            //   //   : {}),
            // },
            canViewVaPage && {
              iconName: "coin",
              text: "Collect Payment",
              value: "collection",
              isDropdownButton: true,
              openDropdownButton,
            },
            openDropdownButton && {
              iconName: "",
              text: "Virtual Account",
              value: "/virtual-account",
              tag: "new",
            },
            openDropdownButton && {
              iconName: "",
              text: "Payment Link",
              value: "disabled",
              tag: "soon",
            },
            openDropdownButton && {
              iconName: "",
              text: "Generate Invoice",
              value: "disabled",
              tag: "soon",
            },
          ],
        },
      ];

  const accountArray = [
    {
      array: [
        // canViewApiDisbursement && {
        //   iconName: "api",
        //   text: "API History",
        //   value: "/histories-api",
        // },
        true && {
          iconName: "category-outline",
          text: "Category",
          value: "/category",
          // isNew: !hasSeenCategory,
          // onNavigate: () => addCategoryFlag(categoryFlags.hasSeenCategory),
        },
        canViewWorkflow && {
          iconName: "Workflow",
          text: "Workflow",
          value: "/workflow",
        },
        {
          iconName: "people-outline",
          text: "Users & Teams",
          value: "/people-management",
          eventName: "enter_user_and_teams",
          payload: {
            page_title: "profile_bar",
          },
        },
        canViewPlans && {
          iconName: "logogram",
          text: "Plans",
          value: "/plans",
        },
        {
          iconName: "settings-outline",
          text: "Settings",
          value: "/settings-jack",
        },
      ],
    },
    { array: [{ iconName: "log-out", text: "Log Out", value: "logout" }] },
  ];

  const { unauthorize } = useGetAuth();

  const isClosePathnames = ["/histories-api"];

  // const noRecordPathname =

  const handleClick = (value) => {
    const isLogout = value == "logout";
    const isCollection = value == "collection";
    const isDisabled = value == "disabled";
    const isNotifSettings = value == "/notifications";
    const isCards = value === "/cards";

    // DEMO PURPOSE
    const homePathname = ["/dashboard", "/", "/home"];
    const isFromHome = homePathname.includes(pathname);
    const isSettings = value === "/settings-jack";

    if (isDisabled) return null;

    if (isSettings) {
      if (isFromHome) {
        return push({
          pathname: "/people-management",
          // query: { showPopup: true },
        });
      }
    }
    if (restrictedPath.includes(value)) return toggleBlockAccessModal();

    if (isCards) {
      push({ query: { showCard: true } });
      eventsTracker("sidebar", { url: "/cards", page_title: "dashboard" });
      openCardsDemoModal();
      return;
    }

    if (isLogout) {
      eventsTracker("logout");
      return setOpenConfirmationModal(true);
    }

    if (isCollection) {
      return setOpenDropdownButton(!openDropdownButton);
    }

    if (isNotifSettings) {
      eventsTracker("click_notif_settings", { page_title: "settings" });
      return push(value);
    }

    const toPush = (value) => {
      eventsTracker("sidebar", { url: value });
      push(value);
    };

    if (isClosePathnames.includes(value)) return toPush(value);

    // setsLastObj
    ![...settingPathnames, isClosePathnames].includes(value) && onClick(value);
    // setsLastObj

    toPush(value);
  };

  const handleLogout = () => {
    unauthorize();
    push("/login");
  };

  return (
    <>
      <SidebarComponent
        array={array}
        onClick={handleClick}
        accountArray={accountArray}
      />
      <ConfirmationModalJack
        modal={openConfirmationModal}
        isCentered={true}
        img={logoutImg}
        title="Log out from Jack account?"
        text=""
        buttonTextLeft="Cancel"
        buttonTextRight="Log Out"
        toggle={() => setOpenConfirmationModal(false)}
        onClick={() => handleLogout()}
      />
      <CardsDemoModal
        isOpen={isOpenCardsDemoModal}
        close={closeCardsDemoModal}
      />
      <DemoGeneralModal
        isOpen={isOpenBlockAccessModal}
        toggle={toggleBlockAccessModal}
      />
    </>
  );
};
