import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import { TextInlineMedium } from "../../../../components/Text";
import { customDateFormatter } from "../../../../components/tools";
import useCardWorkflow from "../../components/CardModal/useCardWorkflow";
import {
  cardIssuanceStatusDecider,
  cardStateDecider,
  cardTypeDecider,
} from "../../tools";
import { useGetAuth } from "../../../../contexts/AuthContext";

export const limitRenewalDecider = (cardDetail) => {
  const { limit_type: limitType, card_type: cardType } = cardDetail || {};
  const { isPhysical } = cardTypeDecider(cardType);
  const isMonthly = limitType === "MONTHLY_AMOUNT_LIMIT";
  if (isPhysical || isMonthly) return "Monthly";
  return "One time use";
};

export const updatesTrackerDecider = (cardDetail) => {
  const {
    card_type: cardType,
    created_at: createdAt,
    created_by_id: createdById,
    current_workflow_process: currentWorkflowProcess,
    issuance_status: issuanceStatus,
    notes,
    process_exceed_limit: processExceedLimit,
    requester_name: requesterName,
    state,
    user: cardOwner,
    workflow_process: workflowProcess,
  } = cardDetail || {};

  const { user } = useGetAuth();

  const {
    request_active: isRequestActiveExceed,
    request_adjust_limit: isRequestAdjustLimitExceed,
  } = processExceedLimit || {};

  const { id: cardOwnerId, name: cardOwnerName } = cardOwner || {};
  const {
    approved_by_ops_on: approvedByOpsOn,
    rejected_by_ops_on: rejectedByOpsOn,
    rejection_note: rejectionNoteOps,
  } = notes || {};

  const { isPhysical, isVirtual, isPrepaid } = cardTypeDecider(cardType);
  const { isActive, isRequestActive } = cardStateDecider(state);
  const {
    isRejected,
    isOnRequested,
    isOnReview,
    isOnProgress,
    isDelivering,
    isDelivered,
    isOnReviewOps,
    isApproved,
  } = cardIssuanceStatusDecider(issuanceStatus);

  const isRequestActiveCard = currentWorkflowProcess === "request_active";

  const firstLineTextDecider = () => {
    const isCreatedByOtherPerson = cardOwnerId !== createdById;

    const youOrName =
      user?.name == requesterName ? "You" : titleCase(requesterName);

    if (isCreatedByOtherPerson)
      return (
        <>
          <TextInlineMedium>{youOrName}</TextInlineMedium> requested to issue a
          card for{" "}
          <TextInlineMedium>{titleCase(cardOwnerName)}</TextInlineMedium>
        </>
      );

    return (
      <>
        <TextInlineMedium>{youOrName}</TextInlineMedium> requested to issue a
        card
      </>
    );
  };

  const firstLineText = firstLineTextDecider();

  const firstLineObj = (withDate) => ({
    text: firstLineText,
    date: withDate && customDateFormatter(createdAt),
    isActive: true,
    isCustomText: true,
  });

  const underReviewObj = (date, obj) => ({
    text: "Request is under review by our team",
    isCustomText: true,
    isActive: true,
    date,
    ...obj,
  });

  const reviewedObj = {
    text: "Request reviewed",
    isActive: true,
    isCustomText: true,
  };

  const cardCreatedObj = (date, isActive = true) => ({
    text: "Card successfully created",
    date,
    isActive,
    isCustomText: true,
  });

  const cardOnYourHandObj = {
    text: "Card is on your hand",
    isActive: true,
    isCustomText: true,
  };

  const activeFunc = (text, obj) => ({
    text,
    isActive: true,
    isCustomText: true,
    ...obj,
  });

  if (isPhysical) {
    if (isRejected) {
      const { notes } = cardDetail || {};
      const { rejection_note: rejectionNote } = notes || {};

      return {
        updates: [
          firstLineObj(),
          {
            text: "Card request rejected by our team",
            isActive: true,
            isCustomText: true,
            isRejected: true,
          },
        ],
        rejectionNote,
      };
    }

    if (isOnRequested || isOnReview)
      return {
        updates: [
          firstLineObj(),
          underReviewObj(),
          {
            text: "Creating your card",
          },
          {
            text: "Delivering your card",
          },
          {
            text: "Activate your card",
          },
        ],
      };

    const first2Lines = [firstLineObj(), reviewedObj];
    const first3Lines = [...first2Lines, cardCreatedObj()];
    const first4Lines = [...first3Lines, cardOnYourHandObj];

    if (isOnProgress)
      return {
        updates: [
          ...first2Lines,
          activeFunc("Creating your card"),
          {
            text: "Delivering your card",
          },
          {
            text: "Activate your card",
          },
        ],
      };

    if (isDelivering)
      return {
        updates: [
          ...first3Lines,
          activeFunc("Delivering your card"),

          {
            text: "Activate your card",
          },
        ],
      };

    if (isDelivered) {
      if (isRequestActive)
        return {
          updates: [...first4Lines, activeFunc("Activating your card")],
        };

      if (isActive)
        return {
          updates: [
            ...first4Lines,
            activeFunc("Your card is active and ready to be used!", {
              isSuccess: true,
            }),
          ],
        };

      return {
        updates: [...first4Lines, activeFunc("Activate your card now!")],
      };
    }
  }

  const { progressArray, rejectionNote, isWorkflowDone } =
    useCardWorkflow(cardDetail);

  if ((isVirtual || isPrepaid) && isRequestActiveCard) {
    if (isApproved && !isEmpty(workflowProcess)) {
      const array = progressArray.slice(0, progressArray.length - 3);
      const dataArr = array[array.length - 1]?.data || [];

      const { approved_at } = dataArr[dataArr.length - 1] || {};

      return {
        updates: [
          ...array,
          isRequestActiveExceed && {
            text: "Request has been reviewed by our team",
            isCustomText: true,
            isActive: true,
            date: customDateFormatter(approvedByOpsOn),
          },
          {
            text: "Card successfully created",
            isCustomText: true,
            isActive: true,
            isSuccess: true,
            date: customDateFormatter(approvedByOpsOn || approved_at),
          },
        ],
      };
    }

    if (isRejected) {
      const isRejectedByOps = !!rejectedByOpsOn;

      if (isRejectedByOps)
        return {
          updates: [
            firstLineObj(true),
            ...progressArray,
            {
              text: "Card request rejected by our team",
              date: customDateFormatter(rejectedByOpsOn),
              isActive: true,
              isRejected: true,
            },
          ],
          rejectionNote: rejectionNoteOps,
        };

      return { updates: progressArray, rejectionNote };
    }
    const isBypassed = !progressArray.length;

    if (isBypassed) {
      if (approvedByOpsOn) {
        return {
          updates: [
            firstLineObj(true),
            isRequestActiveExceed &&
              underReviewObj(customDateFormatter(approvedByOpsOn)),
            {
              text: "Card successfully created",
              isCustomText: true,
              isActive: true,
              isSuccess: true,
              date: customDateFormatter(approvedByOpsOn),
            },
          ],
        };
      }

      return {
        updates: [
          firstLineObj(true),
          isRequestActiveExceed && underReviewObj(),
          {
            text: "Card successfully created",
            isCustomText: true,
            isActive: !isRequestActiveExceed,
            date: !isRequestActiveExceed && customDateFormatter(createdAt),
          },
        ],
      };
    }

    return {
      updates: [
        ...progressArray,
        isRequestActiveExceed &&
          underReviewObj(null, { isActive: isWorkflowDone }),
        cardCreatedObj(false, false),
      ],
    };
  }

  //issuance status for admin
  if (isRejected && isEmpty(workflowProcess)) {
    return {
      updates: [
        firstLineObj(true),
        {
          text: "Card request rejected by our team",
          date: customDateFormatter(rejectedByOpsOn),
          isActive: true,
          isRejected: true,
        },
      ],
      rejectionNote: rejectionNoteOps,
    };
  }

  if (isOnReviewOps && isEmpty(workflowProcess))
    return {
      updates: [
        firstLineObj(true),
        underReviewObj(),
        cardCreatedObj(false, true),
        {
          text: "Card successfully created",
          isCustomText: true,
        },
      ],
    };

  if (isActive && isEmpty(workflowProcess))
    return {
      updates: [
        firstLineObj(true),
        isRequestActiveExceed &&
          underReviewObj(customDateFormatter(approvedByOpsOn)),
        cardCreatedObj(customDateFormatter(createdAt), true),
      ],
    };

  return {
    updates: progressArray,
    rejectionNote,
    isWorkflowDone,
  };
};
