import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamRegular, GothamMedium } from "../../../../../components/Text";
import { colors } from "../../../../../assets/colors";
import JackPatternBg from "../../../../../assets/jackImages/jack-bg-pattern.svg";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { cardTypeDecider, cardStateDecider } from "../../../tools";
import { getUserRole, useGetAuth } from "../../../../../contexts/AuthContext";
import CardTrackerPrepaidBalance from "./CardTrackerPrepaidBalance";
import { updatesTrackerDecider } from "../../../screens/SuccessScreen/tools";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { ToasterHook } from "../../../../../contexts/ToasterContext";

const CardPrepaidBanner = ({
  cardDetail,
  refetchBalance,
  setCurrentScreen,
  closeTransactionList,
}) => {
  const {
    balance,
    card_type: cardType,
    is_seen: isSeen,
    issuance_status: issuanceStatus,
    state,
    workflow_process: workflowProcess,
    user: { id: ownerId } = {},
  } = cardDetail || {};

  const { isPrepaid } = cardTypeDecider(cardType);
  const { isPermanentBlock, isTempBlock, isRejected, isNotActive } =
    cardStateDecider(state, issuanceStatus);
  const { updates, rejectionNote, isWorkflowDone } =
    updatesTrackerDecider(cardDetail);
  const { isBookKeeper } = getUserRole();
  const { user: { id: userId } = {} } = useGetAuth();
  const isInWorkflow = workflowProcess?.some(
    ({ type }) =>
      type === "request_withdraw_balance_company" ||
      type === "request_topup_balance_company" ||
      type === "request_move_balance"
  );
  const isHideBanner = !isPrepaid || isPermanentBlock || (isSeen && isRejected);
  const isHideButton =
    (isInWorkflow && !isWorkflowDone) ||
    (isBookKeeper && ownerId !== userId) ||
    isNotActive ||
    isTempBlock;
  const isShowRefresh = !isTempBlock && !isNotActive;

  if (isHideBanner) return null;

  const { successSnackBar } = ToasterHook();

  const updateBalance = () => {
    refetchBalance();
    successSnackBar({ msg: "Prepaid card balance has been updated" });
  };

  const onClickMove = () => {
    closeTransactionList();
    eventsTracker("card_to_move_menu_prepaid", {
      card_id: cardDetail?.id,
    });
    setCurrentScreen("prepaidMoveBalanceMethod");
  };

  const onClickTopUp = () => {
    closeTransactionList();
    eventsTracker("card_to_top_menu_prepaid", {
      card_id: cardDetail?.id,
    });
    setCurrentScreen("prepaidTopUpMethod");
  };

  const styles = {
    banner: {
      backgroundImage: `url(${JackPatternBg})`,
      width: "100%",
      height: "64px",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "100% 100%",
      backgroundSize: "contain",
      backgroundColor: "#000",
      display: "flex",
      padding: "12px 16px",
      borderRadius: "4px",
    },
    innerCardBalanceContainer: {
      display: "flex",
      gap: "4px",
    },
    cardBalanceText: {
      fontSize: "12px",
      letterSpacing: "0.2px",
      color: colors.neutral100,
    },
    refresh: {
      width: "12px",
      height: "12px",
      cursor: "pointer",
    },
    balanceText: {
      color: colors.neutral100,
    },
    buttonContainer: {
      margin: "auto 0 auto auto",
      display: "flex",
      gap: "16px",
    },
    button: {
      backgroundColor: colors.neutral900,
      color: colors.primaryStabilowB9,
      minWidth: "67px",
      height: "32px",
    },
    buttonText: {
      fontSize: "12px",
      letterSpacing: "0.2px",
    },
  };

  return (
    <div>
      {!isRejected && (
        <div style={styles.banner}>
          <div>
            <div style={styles.innerCardBalanceContainer}>
              <GothamRegular style={styles.cardBalanceText}>
                Card Balance
              </GothamRegular>
              {isShowRefresh && (
                <JackIcons
                  name="refresh"
                  fill={colors.neutral100}
                  style={styles.refresh}
                  onClick={updateBalance}
                />
              )}
            </div>
            <GothamMedium style={styles.balanceText}>
              IDR {formatCurrencyNoDecimal(balance)}
            </GothamMedium>
          </div>
          {!isHideButton && (
            <div style={styles.buttonContainer}>
              <ButtonJack
                leftIcon={
                  <JackIcons
                    name="resend-callback"
                    fill={colors.primaryStabilowB9}
                  />
                }
                style={styles.button}
                styleText={styles.buttonText}
                onClick={onClickMove}
              >
                Move
              </ButtonJack>
              <ButtonJack
                leftIcon={
                  <JackIcons name="top-up" fill={colors.primaryStabilowB9} />
                }
                style={styles.button}
                styleText={styles.buttonText}
                onClick={onClickTopUp}
              >
                Top up
              </ButtonJack>
            </div>
          )}
        </div>
      )}
      <CardTrackerPrepaidBalance
        cardDetail={cardDetail}
        updates={updates}
        rejectionNote={rejectionNote}
      />
    </div>
  );
};

export default CardPrepaidBanner;
