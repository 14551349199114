import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium } from "../../../../../components/Text";

const TitleIcon = ({ title, icon }) => {
  const containerStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  };

  const iconContainerStyle = {
    width: "32px",
    height: "32px",
    backgroundColor: colors.neutral900,
    borderRadius: "8px",
    display: "grid",
    placeItems: "center",
  };

  const titleStyle = {
    fontSize: "24px",
    color: colors.neutral900,
  };

  return (
    <div style={containerStyle}>
      <div style={iconContainerStyle}>
        <JackIcons name={icon} fill={colors.neutral100} />
      </div>
      <GothamMedium style={titleStyle}>{title}</GothamMedium>
    </div>
  );
};

export default TitleIcon;
