import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import BottomContainer from "../components/BottomContainer";
import CardDisplay from "../components/CardDisplay";
import { colors } from "../../../../../assets/colors";
import { GothamMedium } from "../../../../../components/Text";
import { TextFieldLimitJack } from "../../../../../components/inputs/textfield";
import { useForm } from "react-hook-form";
import { CustomRichTextArea } from "../../../../../components/CustomRichTextArea";
import { useMutation } from "../../../../../tools/api";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useRef } from "react";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const VirtualEditInfo = () => {
  const { cardDetail, setCurrentScreen, refetchCardDetail } =
    useCardDetailContext();
  const { nickname: currentNickname, description: currentDescription } =
    cardDetail || {};

  const { successSnackBar } = ToasterHook();

  const useFormObj = useForm({
    defaultValues: {
      card_name: currentNickname,
      description: currentDescription,
    },
  });
  const { handleSubmit, watch } = useFormObj;

  const cardNameWatch = watch("card_name");
  const descriptionWatch = watch("description");

  const refRichText = useRef(null);
  const richTextInputHeight = refRichText?.current?.clientHeight;

  const isButtonDisabled =
    (cardNameWatch === currentNickname &&
      descriptionWatch === currentDescription) ||
    descriptionWatch === "<p><br></p>";

  const backHandler = () => setCurrentScreen("index");

  const { mutation: mutationVirtualUpdate, loading: loadingVirtualUpdate } =
    useMutation({
      url: `/cards/virtual_card/${cardDetail?.id}`,
      afterSuccess: async () => {
        await refetchCardDetail();
        backHandler();
        successSnackBar({ msg: "Card information has been changed!" });
      },
    });

  const onSubmit = (value) => {
    const { card_name: nickname, description } = value;
    const payload = {
      nickname,
      description,
    };
    mutationVirtualUpdate(payload);
  };

  const containerStyle = {
    position: "relative",
    height: "100%",
    marginBottom: `${richTextInputHeight - 150}px`,
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  };

  const titleStyle = {
    color: colors.neutral900,
    fontSize: "24px",
  };

  const richTextStyle = {
    marginTop: "-48px",
  };

  const bottomContainerStyle = {
    borderTop: `1px solid ${colors.neutral500}`,
    backgroundColor: colors.neutral100,
    padding: "20px",
    left: "0px",
    width: "100%",
    display: "flex",
    gap: "16px",
    position: "fixed",
  };

  const buttonStyle = {
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <GothamMedium style={titleStyle}>Edit Card Information</GothamMedium>
        <CardDisplay cardDetail={cardDetail} isSmall woReveal />
        <TextFieldLimitJack
          name="card_name"
          helperText="Card name max. 20 characters."
          useFormObj={useFormObj}
          maxLength={20}
        />
        <CustomRichTextArea
          name="description"
          useFormObj={useFormObj}
          style={richTextStyle}
          defaultValue={currentDescription}
          forwardRef={refRichText}
        />
      </div>
      <BottomContainer style={bottomContainerStyle}>
        <ButtonJack type="outline" onClick={backHandler} style={buttonStyle}>
          Cancel
        </ButtonJack>
        <ButtonJack
          style={buttonStyle}
          disabled={isButtonDisabled}
          onClick={handleSubmit(onSubmit)}
          isLoading={loadingVirtualUpdate}
        >
          Save Changes
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default VirtualEditInfo;
