import { getUserRole } from "../../../contexts/AuthContext";

export const userRoleDecider = (user) => {
  const roles = user.roles ?? [];

  const finder = (findRole) => roles.find(({ name }) => name === findRole);

  const isBusinessOwner = finder("partner_super_admin");
  const isAdmin = finder("partner_admin");
  const isBookkeeper = finder("partner_viewer");
  const isEmployee = finder("partner_maker");

  if (isBusinessOwner) return "Business Owner";
  if (isAdmin) return "Admin";
  if (isBookkeeper) return "Bookkeeper";
  if (isEmployee) return "Employee";

  return "-";
};

export const rolesDataDecider = ({
  users = [],
  defaultValue = {},
  rolesData = [],
}) => {
  const { isSuperAdmin } = getUserRole();

  const thereIsNoBusinessOwner = !users
    .map(({ roles }) => roles)
    .flat()
    .some(({ name }) => name === "partner_super_admin");

  const isEditingBusinessOwner = defaultValue?.name === "partner_super_admin";

  const filteredRolesDecider = (roles) =>
    rolesData
      .filter(({ label }) => !roles.includes(label))
      .map(({ label, value }) => ({
        label,
        value,
      }));

  const unnecessaryRoles = ["Approver", "Card User"];
  const unavailableRolesForBusinessOwner = [
    ...unnecessaryRoles,
    "Business Owner",
  ];
  const unavailableRolesForOthers = [
    ...unavailableRolesForBusinessOwner,
    "Admin",
  ];

  if (thereIsNoBusinessOwner || isEditingBusinessOwner) {
    return filteredRolesDecider(unnecessaryRoles);
  }

  if (isSuperAdmin) {
    return filteredRolesDecider(unavailableRolesForBusinessOwner);
  }

  return filteredRolesDecider(unavailableRolesForOthers);
};

export const roleDescriptionDecider = (role) => {
  const isBusinessOwner =
    role === "partner_super_admin" || role === "Business Owner";
  const isAdmin = role === "partner_admin" || role === "Admin";
  const isEmployee = role === "partner_maker" || role === "Employee";
  const isBookkeeper = role === "partner_viewer" || role === "Bookkeeper";

  if (isBusinessOwner)
    return "This user can access Jack’s full capabilities and controls.";
  if (isAdmin)
    return "This user can access Jack’s full capabilities and controls except assigning another admin.";
  if (isEmployee)
    return "This user can create or request any transaction in Jack. They can also be assigned as approver for some products’ approval flow.";
  if (isBookkeeper)
    return "This user can view and download all transactional data in Jack, but cannot manage account.";

  return "";
};
