import { useRouter } from "next/router";
import React, { Fragment, useEffect, useState } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Counter } from "../../../../components/Counter";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { capitalizeFirstLetter } from "../../../../pageComponents/bankAccountSettingComponents/tools/helpers";
import { NewFeature } from "../../../../pageComponents/categoryComponents/general/components";
import FloatingTooltip from "../../../../pageComponents/productDemoComponents/components/FloatingTooltip";
import { useDemo } from "../../../DemoContext";

export const SidebarList = ({ array, onClick, isDropdown }) => {
  const { pathname } = useRouter();

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { guidanceState, setGuidanceState } = useDemo();
  const { guided = false, guidedTask = false } = guidanceState ?? {};

  useEffect(() => {
    //  if (!showTooltip) return;
    if (!isMounted) return;
    if (!guidedTask) return;

    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted, guidedTask]);

  return array.map((item, index) => {
    const { array, title } = item;
    const isFirst = index == 0;

    return (
      <div
        style={{
          paddingTop: isDropdown ? 0 : isFirst ? 36 : 16,
          marginLeft: isDropdown ? 8 : 0,
          marginRight: isDropdown ? 8 : 0,
          borderTop: `1px solid ${jackColors.greyE6}`,
        }}
        key={index}
      >
        <GothamRegular
          className="font10"
          style={{ marginLeft: 8 + 24, color: jackColors.grey90 }}
        >
          {title}
        </GothamRegular>
        {array.map((item, index) => {
          if (!item) return null;

          const {
            iconName,
            text,
            number,
            isOrange,
            value,
            counterStyle,
            isDropdownButton,
            openDropdownButton,
            // setOpenDropdownButton,
            tag,
            isNew: isNewProps,
            onNavigate = () => {},
          } = item;

          // DEMO
          const showTooltip =
          // +number === 0 &&
          text == "Task" &&
          pathname === "/dashboard" &&
          guided &&
          guidedTask;

          const isFirst = index == 0;
          const isLast = index == array.length - 1;

          const isActive = pathname.includes(value);

          const canShowNumber = number != undefined;

          const noIcon = !iconName;
          // const isNew = isNewProps || tag === "new"; // demo purpose
          const isNew = false
          const isSoon = tag === "soon";

          // const handleClick = () => {
          //   if (isDropdownButton)
          //     return setOpenDropdownButton(!openDropdownButton);
          //   if (isSoon) return null;
          //   return onClick(value);
          // };

          const tagDecider = (text) => {
            if (!text) return null;

            const style = {
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: 4,
              width: "fit-content",
              height: 20,
              padding: "2px 8px",
              backgroundColor: isNew ? "#B9FC00" : jackColors.neutral400,
            };

            const textStyle = {
              fontSize: 12,
              lineHeight: "16px",
              color: isNew ? jackColors.neutral900 : jackColors.neutral600,
            };

            return (
              <div style={style}>
                <GothamMedium style={textStyle}>
                  {capitalizeFirstLetter(text)}
                </GothamMedium>
              </div>
            );
          };

          const SidebarButton = () => {
            return (
              <div
                key={index}
                className="d-flex darkhover align-items-center justify-content-between"
                onClick={() => {
                  onClick(value);
                  onNavigate();
                }}
                style={{
                  height: 40,
                  marginTop: isFirst ? 8 : 0,
                  marginBottom: isLast ? 16 : 4,
                  borderRadius: 4,
                  backgroundColor: isActive ? jackColors.greyE6 : "",
                  marginRight: isDropdown ? 0 : 24,
                  marginLeft: isDropdown ? 0 : 24,
                  paddingLeft: 8,
                  paddingRight: 8,
                  cursor: isSoon ? "not-allowed" : "pointer",
                }}
              >
                <div className="d-flex align-items-center">
                  <JackIcons
                    name={iconName}
                    fill={isActive ? jackColors.black34 : jackColors.grey6C}
                    style={{ marginRight: 8, height: 20, width: 20 }}
                  />
                  {noIcon && (
                    <div style={{ marginRight: 8, height: 20, width: 20 }} />
                  )}
                  <GothamRegular
                    style={{
                      color: isActive
                        ? jackColors.black34
                        : isSoon
                        ? jackColors.neutral600
                        : jackColors.grey6C,
                      fontFamily: isActive ? "GothamMedium" : "GothamBook",
                    }}
                    className="font12"
                  >
                    {text}
                  </GothamRegular>
                </div>
                {isNew && <NewFeature woHoverInfo />}
                {canShowNumber && (
                  <Counter
                    isOrange={isOrange}
                    number={number}
                    style={counterStyle}
                  />
                )}
                {isDropdownButton && (
                  <JackIcons
                    name={openDropdownButton ? "chevron-up" : "chevron-down"}
                    fill={isActive ? jackColors.black34 : jackColors.grey6C}
                    style={{ marginRight: 0, height: 20, width: 20 }}
                  />
                )}
                {/* {tag && tagDecider(tag)} */}
              </div>
            );
          };

          return (
            <Fragment>
              <span
                ref={(e) => {
                  if (!e) return;
                  setIsMounted(true);
                }}
              >
                {showTooltip ? (
                  <FloatingTooltip
                    isOpen={isHoveredInfo}
                    close={() => {
                      setIsHoveredInfo(false)
                      delete guidanceState?.guidedTask
                    }}
                    title="Let’s get things done!"
                    body="Click here to check out what needs your attention today."
                    tooltipStyle={{
                      translate: "233px -75px",
                      width: 260,
                    }}
                    translateTriangle={`-9px 45px`}
                    position="right"
                    woButton
                  >
                    <SidebarButton />
                  </FloatingTooltip>
                ) : (
                  <SidebarButton />
                )}
              </span>
            </Fragment>
          );
        })}
      </div>
    );
  });
};
