import { isEmpty } from "lodash";
import { useState } from "react";
import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { DropdownButtonJack } from "../../../../../components/ButtonsJack/dropdown";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import {
  cardStateDecider,
  cardTypeDecider,
  isCanManageCardDecider,
} from "../../../tools";
import { useMutation } from "../../../../../tools/api";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { useModalHook } from "../../../../../components/Modals";
import { ConfirmationModalJack } from "../../../../../components/ButtonsJack/confirmation";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useGetAuth } from "../../../../../contexts/AuthContext";

const DropdownButtonItem = ({ iconName, label, onClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "10px 8px",
    cursor: "pointer",
    width: "100%",
    borderRadius: "8px",
    backgroundColor: isHovered ? colors.neutral500 : "transparent",
  };

  const labelStyle = {
    fontSize: "12px",
    color: colors.neutral800,
  };

  return (
    <div
      style={containerStyle}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <JackIcons name={iconName} fill={colors.neutral800} />
      <GothamRegular style={labelStyle}>{label}</GothamRegular>
    </div>
  );
};

const IndividualButton = ({
  label,
  leftIcon,
  rightIcon,
  onClick,
  isLoading,
  array = [],
  isLoadingLeft,
  color,
}) => {
  const leftIconComponent = leftIcon && (
    <JackIcons name={leftIcon} fill={color ? color : colors.neutral900} />
  );

  const rightIconComponent = rightIcon && (
    <JackIcons name={rightIcon} fill={color ? color : colors.neutral900} />
  );

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    cursor: "pointer",
    borderRadius: "16px",
    backgroundColor: "transparent",
    transition: "background-color 0.2s ease-in-out",
    width: "fit-content",
    minWidth: "fit-content",
  };

  const dropdownStyle = {
    width: "190px",
    padding: "8px",
    right: "0px",
    left: "auto",
  };

  const labelStyle = {
    color: color ? color : colors.neutral900,
  };

  if (!isEmpty(array))
    return (
      <DropdownButtonJack
        leftIcon={leftIconComponent}
        rightIcon={rightIconComponent}
        style={containerStyle}
        array={array}
        dropdownStyle={dropdownStyle}
        type="borderless"
        isSmall={false}
      >
        {label}
      </DropdownButtonJack>
    );

  return (
    <ButtonJack
      style={containerStyle}
      leftIcon={leftIconComponent}
      rightIcon={rightIconComponent}
      onClick={onClick}
      isLoading={isLoading}
      isLoadingLeft={isLoadingLeft}
    >
      <GothamMedium style={labelStyle}>{label}</GothamMedium>
    </ButtonJack>
  );
};

const CardButtons = ({
  cardDetail,
  openTransactionList,
  closeTransactionList,
  isOpenTransactionList,
  setCurrentScreen,
  refetchCardDetail,
  toggleConfirmDelete,
}) => {
  if (isEmpty(cardDetail)) return null;
  const {
    card_type: cardType,
    id,
    is_archived: isArchived,
    is_adjusting_limit,
    is_suspended: isSuspended,
    issuance_status: issuanceStatus,
    state,
  } = cardDetail || {};
  const isAdjustingLimit = is_adjusting_limit?.value;
  const { isPhysical, isVirtual } = cardTypeDecider(cardType);
  const {
    isActive,
    isNotActive,
    isActivating,
    isTempBlock,
    isPermanentBlock,
    isRejected,
  } = cardStateDecider(state, issuanceStatus);
  const { user } = useGetAuth();
  const isCanManageCard = isCanManageCardDecider(cardDetail, user);

  if ((isNotActive && !isRejected) || isActivating) return null;
  if (isAdjustingLimit) return null;

  const defaultAfterSuccess = () => {
    refetchCardDetail();
  };

  const { successSnackBar } = ToasterHook();

  const { mutation: mutationReqUnblock, loading: loadingReqUnblock } =
    useMutation({
      url: `/cards/${id}/request_unblock`,
      method: "post",
      afterSuccess: defaultAfterSuccess,
    });

  const { mutation: mutationUnblock, loading: loadingUnblock } = useMutation({
    url: `/cards/virtual_card/${id}/restore`,
    method: "post",
    afterSuccess: defaultAfterSuccess,
  });

  const unblockLoading = loadingReqUnblock || loadingUnblock;

  const { mutation: mutationArchive, loading: loadingArchive } = useMutation({
    url: `/cards/${id}/archive`,
    afterSuccess: () => {
      successSnackBar({
        msg: "Card has been archived",
      });
      refetchCardDetail();
    },
  });

  const unblockHandler = () => {
    if (isPhysical) return mutationReqUnblock();
    mutationUnblock();
  };

  const moreDropdownItemsDecider = () => {
    if (isPhysical) {
      return [
        {
          label: "Change PIN",
          iconName: "pinNumber",
          onClick: () => {
            eventsTracker("card_navigate_to_physical_change_pin", {
              card_id: id,
            });
            setCurrentScreen("physicalChangePin");
          },
        },
        {
          label: "Edit Description",
          iconName: "edit-outline",
          onClick: () => {
            eventsTracker("card_navigate_to_physical_edit_description", {
              card_id: id,
            });
            setCurrentScreen("physicalEditInfo");
          },
        },
        {
          label: "Request New Limit",
          iconName: "limit",
          onClick: () => {
            eventsTracker("card_navigate_to_physical_request_new_limit", {
              card_id: id,
            });
            setCurrentScreen("physicalAdjustLimit");
          },
        },
        {
          label: "Replace Card",
          iconName: "autorenew",
          onClick: () => {
            eventsTracker("card_navigate_to_physical_replace_card", {
              card_id: id,
            });
            setCurrentScreen("physicalReplaceCard");
          },
        },
      ];
    }

    return [
      {
        label: "Edit Card Information",
        iconName: "edit-outline",
        onClick: () => {
          eventsTracker("card_navigate_to_virtual_edit_card_information", {
            card_id: id,
          });
          setCurrentScreen("virtualEditInfo");
        },
      },
      isVirtual && {
        label: "Adjust Limit",
        iconName: "limit",
        onClick: () => {
          eventsTracker("card_navigate_to_virtual_request_new_limit", {
            card_id: id,
          });
          setCurrentScreen("virtualAdjustLimit");
        },
      },
    ];
  };

  const moreDropdownItems = moreDropdownItemsDecider()
    .filter((item) => item !== false)
    .map(({ label, iconName, onClick }, index) => ({
      customComponent: (
        <DropdownButtonItem
          label={label}
          iconName={iconName}
          onClick={onClick}
          key={index}
        />
      ),
    }));

  const blockDropdownItems = [
    {
      customComponent: (
        <DropdownButtonItem
          label="Temporary Block"
          iconName="freeze"
          onClick={() => {
            eventsTracker("card_navigate_to_temporary_block", {
              card_id: id,
            });
            setCurrentScreen("temporaryBlock");
          }}
        />
      ),
    },
    {
      customComponent: (
        <DropdownButtonItem
          label="Permanent Block"
          iconName="minus-circle"
          onClick={() => {
            eventsTracker("card_navigate_to_permanent_block", {
              card_id: id,
            });
            setCurrentScreen("permanentBlock");
          }}
        />
      ),
    },
  ];

  const buttonsDecider = () => {
    let buttons = [];

    if (!isRejected)
      buttons.push({
        label: "Transactions",
        leftIcon: "transaction",
        onClick: isOpenTransactionList
          ? closeTransactionList
          : openTransactionList,
      });

    if ((isPermanentBlock || isRejected) && !isArchived && isCanManageCard)
      buttons.push({
        value: "archive",
        label: "Archive Card",
        leftIcon: "archive-outline",
        onClick: () => {
          eventsTracker("card_archive_card", {
            card_id: id,
          });
          mutationArchive();
        },
        isLoading: loadingArchive,
        isLoadingLeft: true,
      });

    if (isArchived && isCanManageCard)
      buttons.push({
        label: "Delete Card",
        leftIcon: "delete",
        onClick: () => {
          eventsTracker("card_remove_card", {
            card_id: id,
          });
          toggleConfirmDelete();
        },
        color: colors.redE7,
      });

    if (isTempBlock && !isSuspended && isCanManageCard)
      buttons.push({
        label: "Unblock Card",
        leftIcon: "freeze",
        onClick: () => {
          eventsTracker("card_unblock_card", {
            card_id: id,
          });
          unblockHandler();
        },
        isLoading: unblockLoading,
        isLoadingLeft: true,
      });

    if (isActive && !isSuspended && isCanManageCard)
      buttons.push(
        {
          label: "Block",
          leftIcon: "minus-circle-outline",
          rightIcon: "chevron-down",
          array: blockDropdownItems,
        },
        {
          label: "More",
          rightIcon: "more-vertical-outline",
          array: moreDropdownItems,
        }
      );

    return buttons;
  };

  const buttons = buttonsDecider();

  const isButtonsTwoItems = buttons.length === 2;

  const { isOpen, toggle } = useModalHook();

  let onArchive = () => {};

  const modifiedButtons = (buttons || []).map((item) => {
    const { value, onClick } = item;
    if (value !== "archive") return item;
    onArchive = onClick;

    return { ...item, onClick: toggle };
  });

  return (
    <div
      style={{
        display: "flex",
        justifyContent: isButtonsTwoItems ? "space-between" : "space-around",
        marginTop: -32 + 24,
      }}
    >
      <ConfirmationModalJack
        modal={isOpen}
        text="This card shall be archived"
        toggle={toggle}
        onClick={onArchive}
      />
      {modifiedButtons.map((button, index) => (
        <IndividualButton key={index} {...button} />
      ))}
    </div>
  );
};

export default CardButtons;
