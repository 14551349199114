import { times } from "lodash";
import { useEffect, useRef } from "react";
import styles from "./styles.module.css";
import { GothamRegular } from "../Text";

export const PinInput = ({
  onChange: onChangeProps,
  value,
  onSubmit,
  isError,
  setValue,
  isErrorText,
}) => {
  const smarterError = isErrorText && !value;
  const pinInputRef = useRef(null);

  useEffect(() => {
    if (value) return;
    setTimeout(() => pinInputRef?.current?.focus(), 400);
  }, [value, !!pinInputRef?.current]);

  useEffect(() => {
    if (isError) {
      setValue("");
    }
  }, [isError, onChangeProps]);

  const currentPinLength = value?.length;

  const onChange = (event) => {
    const currentValue = event?.target?.value;
    const latestValue = currentValue?.slice(-1);

    const keyValue = event?.nativeEvent?.inputType;

    if (keyValue === "deleteContentBackward") {
      if (currentPinLength === 0) return;
      const newPin = value?.slice(0, -1);
      onChangeProps(newPin);
    } else if (keyValue === "Enter") {
      onSubmit();
    } else {
      if (currentPinLength >= 6) return;
      const newPin = value + latestValue;
      onChangeProps(newPin);
    }
  };

  const onKeyDown = (event) => {
    const keyValue = event?.key;

    if (keyValue === "Enter") {
      onSubmit();
    }
  };

  return (
    <div>
      <input
        className={styles["pin-input"]}
        onChange={(event) => onChange(event)}
        onKeyDown={(event) => onKeyDown(event)}
        tabIndex={0}
        ref={pinInputRef}
        type="number"
        value={value}
      />
      <div className={styles["pin-item-container"]}>
        {times(6, (index) => {
          const pinClassNameDecider = (index, isError) => {
            const isPreviousPin = index < currentPinLength - 1;
            const isCurrentPin = index === currentPinLength - 1;
            const isBlinking = index === currentPinLength;
            const isNextPin = index > currentPinLength - 1;

            if (isError) return styles["error-pin"];
            if (isPreviousPin) return styles["previous-pin"];
            if (isCurrentPin) return styles["current-pin"];
            if (isNextPin) return styles["next-pin"];
            if (isBlinking) return styles["blinking-pin"];
          };

          const pinClassName = pinClassNameDecider(
            index,
            isError || smarterError
          );

          return (
            <div
              key={index}
              className={`${styles["pin-item"]} ${pinClassName}`}
            >
              {value?.charAt(index) && <div className={styles["circle"]} />}
            </div>
          );
        })}
      </div>
      {(isError || smarterError) && (
        <GothamRegular className={styles["error-text"]}>
          {isErrorText || "Incorrect PIN. Please try again."}
        </GothamRegular>
      )}
    </div>
  );
};
