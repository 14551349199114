import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { colors, jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { PDFOrImageViewer } from "../../../components/PDFPreview";
import ParserRichTextArea from "../../../components/ParserRichTextArea";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { StatusLabelJack } from "../../../components/StatusLabel";
import {
  GothamMedium,
  GothamRegular,
  TextInlineRegular,
} from "../../../components/Text";
import { customDateFormatter } from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useGdrivehook } from "../../../contexts/GoogleDrivePreviewContext/parent";
import { useGuidedTour } from "../../../contexts/GuidedTourContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { TooltipError } from "../../../pageComponents/PayrollCreateEnhancement/create/tooltip";
import CategorySelection from "../../../pageComponents/categoryComponents/general/components/CategorySelection";
import { canUpdateCategoryDecider } from "../../../pageComponents/categoryComponents/general/helpers";
import { WrapperModalButtons } from "../../../pageComponents/homeComponents/topup/components";
import { InvoiceFormSummary } from "../../../pageComponents/invoiceComponents/createPage/modals/formComponents/component";
import {
  InvoiceFormEditModal,
  PDFLeftComponent,
} from "../../../pageComponents/invoiceComponents/createPage/modals/formEdit";
import {
  attachmentDataFormatter,
  invoiceFormatter,
  pluralize,
} from "../../../pageComponents/invoiceComponents/data/formatter";
import useGuidedTourBuilder from "../../../pageComponents/productDemoComponents/builders";
import DemoGeneralModal from "../../../pageComponents/productDemoComponents/components/DemoGeneralModal";
import FloatingTooltip from "../../../pageComponents/productDemoComponents/components/FloatingTooltip";
import {
  APPROVAL_FOURTH_TARGET,
  APPROVAL_MODULE,
  APPROVAL_THIRD_TARGET,
  BILL_MODULE,
} from "../../../pageComponents/productDemoComponents/constants";
import { useModalHookData } from "../../../pageComponents/scheduledPaymentComponents/dashboardComponent/hooks";
import DraftSelectionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/DraftSelectionModal/parent";
import PreventionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import { useSubscriptionUsage } from "../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { fetch, useMutation } from "../../../tools/api";
import {
  DetailsModal,
  crossBorderSingleWorkflowComponents,
} from "../crossBorderSingleModal/components";
import {
  RetryResubmitBanner,
  RetryResubmitButton,
  SingleRetryUpdateTracker,
} from "../retry-resubmit/components";
import {
  useRetryResubmitModalPayloads,
  useTrxReleaser,
} from "../retry-resubmit/hooks";
import { InvoiceDetailForm } from "./editDetailModal";
import { useSchedulePaymentButtons } from "./hooks/components";
import {
  DownloadButton,
  EditButton,
  ScheduledBanner,
} from "./hooks/components/buttons";
import { createInvoiceSummary } from "./hooks/logics";
import { InvoicePaymentStatusLine } from "./status";
import { ActionButtonSupportingDoc } from "./supportingDocs";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  DEMO_CLICK_CONTINUE_EDIT,
  DEMO_DELETE_DRAFT,
  DEMO_TOOLTIP_NEXT,
} from "../../../pageComponents/productDemoComponents/constants/events";

export const PreviewComponent = ({
  loading,
  file_url,
  rawUrl,
  is_duplicate,
  product = "invoice",
  setIsMounted,
}) => {
  return (
    <div
      style={{
        height: "100%",
        overflowY: "scroll",
        zIndex: 121,
      }}
      id={APPROVAL_THIRD_TARGET}
      ref={(e) => {
        if (!e) return;
        setIsMounted(true);
      }}
    >
      {loading ? (
        <Skeleton width={"100%"} height={1000} />
      ) : (
        <PDFOrImageViewer
          url={file_url}
          noZoom={true}
          rawUrl={rawUrl}
          enablePreview={true}
          woHeader
          leftHeaderComponent={
            <PDFLeftComponent
              is_duplicate={is_duplicate}
              pdf={file_url}
              product={product}
            />
          }
        />
      )}
    </div>
  );
};

const MainContent = ({
  buttons,
  details,
  arrayDetails,
  totalAmountDetails,
  onClickSupportingDoc,
  isApprover,
  onClickEdit,
  data,
  children,
  listener = () => {},
  onSeeDetails = () => {},
}) => {
  const {
    status,
    user: drafter,
    recipient_name,
    totalAmount,
    newAttachmentData,
    descriptionHTML,
    category_id,
    id,
    scheduled_payment,
    ancestor_id,
    edited_by_user_id,
    notes_from_editing,
    edited_at,
  } = details ?? {};

  const { data: ancestorTrxData, refetch: fetchAncestorTrxData } = fetch({
    url: `/invoice_transactions/${ancestor_id}`,
    woInit: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (!ancestor_id) return;
    const fetchTimeout = setTimeout(() => {
      fetchAncestorTrxData();
    }, 400);

    return () => clearTimeout(fetchTimeout);
  }, [ancestor_id]);

  const { releaser } = useTrxReleaser({ trx: ancestorTrxData?.data });

  const isWaitingForApproval = status == "waiting_for_approval";
  const [selectedCategory, setSelectedCategory] = useState(null);

  const { user } = useGetAuth();

  const { categories } = useConstants();

  useEffect(() => {
    if (selectedCategory) return;

    const category = (categories ?? []).find(({ id }) => id === category_id);
    if (category) setSelectedCategory(category);
  }, [categories, selectedCategory]);

  const { successSnackBar } = ToasterHook();

  const { mutation: updateCategory } = useMutation({
    url: `/invoice_transactions/${id}/update_category`,
    afterSuccess: (_, payload) => {
      const category = categories?.find(
        ({ id }) => id === payload?.category_id
      );
      listener({
        ...data,
        details: {
          ...data?.details,
          category: category?.name,
          category_id: category?.id,
        },
        isChangingCategory: true,
      });
      successSnackBar({ msg: "Transaction category has been updated." });
    },
  });

  const isDrafter = String(user?.id) === String(drafter?.id);

  const canUpdateCategory = canUpdateCategoryDecider({
    deps: { isDrafter, isApprover, state: status },
  });

  const attachmentDataLength = newAttachmentData?.length;

  const isRetriedTrx = !!ancestor_id;
  const hideButton = !Boolean(isApprover) || !isWaitingForApproval;

  return (
    <WrapperModalButtons
      childrenButton={buttons()}
      containerStyle={{
        minHeight: 80,
        height: "auto",
        padding: 0,
        paddingTop: 4,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <StatusAccountIconJack state="Invoice" />
        <div>
          <GothamRegular className="font14 text-black34">
            Bill payment to {recipient_name}
          </GothamRegular>
          <GothamMedium className="font24 text-black34 mb-1">
            {totalAmount}
          </GothamMedium>
          <div className="d-flex justify-content-between align-items-center">
            <GothamRegular className="font12" style={{ color: colors.grey90 }}>
              Created by {drafter?.name ?? "-"}
            </GothamRegular>
            <StatusLabelJack status={status} isOutline={true} />
          </div>
        </div>
        <ScheduledBanner scheduled_payment={scheduled_payment} />
        {children}
        {isRetriedTrx ? (
          <SingleRetryUpdateTracker
            releaser={releaser}
            transaction={details}
            onSeeDetails={onSeeDetails}
          />
        ) : (
          <InvoicePaymentStatusLine transaction={details} />
        )}
        <DetailsModal array={arrayDetails} />
        <InvoiceFormSummary
          data={totalAmountDetails}
          rightHeaderComponent={
            <span id={APPROVAL_FOURTH_TARGET}>
              <EditButton hideButton={hideButton} onClick={onClickEdit} />
            </span>
          }
          isCustomTitle={Boolean(edited_by_user_id)}
          title={
            <EditedTitle
              editBy={edited_by_user_id}
              notes={notes_from_editing}
              editAt={edited_at}
            />
          }
        />
        {canUpdateCategory && (
          <CategorySelection
            isForTrxDetails
            style={{ marginBottom: 0 }}
            showAllCategories={isApprover}
            selectedCategory={selectedCategory ?? {}}
            onSelect={(category) => {
              setSelectedCategory(category);
              updateCategory({ category_id: category?.id });
            }}
          />
        )}
        <DescriptionAccordion description={descriptionHTML} />
        {attachmentDataLength ? (
          <ActionButtonSupportingDoc
            iconName="layers_outline"
            title={`${attachmentDataLength} Supporting ${pluralize(
              attachmentDataLength,
              "Document"
            )}`}
            text="See all supporting documents"
            onClick={() => onClickSupportingDoc(attachmentDataLength)}
          />
        ) : null}
      </div>
    </WrapperModalButtons>
  );
};

export const InvoicePaymentModal = ({
  isOpen,
  toggle,
  data,
  handleDelete,
  listener,
}) => {
  const { id: idRaw, originator_id } = data || {};
  const id = originator_id || idRaw;

  const [invoiceId, setInvoiceId] = useState(id);

  const {
    data: details,
    refetch,
    loading,
  } = fetch({
    url: `/invoice_transactions/${invoiceId}`,
    woInit: true,
    defaultValue: {},
    formatter: (res) => {
      const { attachment_data, data } = res || {};
      if (data) {
        const obj = invoiceFormatter([data])[0] || {};
        const newAttachmentData = attachmentDataFormatter(
          (attachment_data || []).map((item, index) => {
            const url = obj.supporting_documents_files[index];
            return { ...item, url };
          })
        );
        return { ...obj, newAttachmentData };
      }
    },
  });

  const {
    buttons: workflowButton,
    rejectModal,
    isRejection,
    openPinModal,
    isReleaser,
    isApprover,
  } = crossBorderSingleWorkflowComponents({
    transaction: details,
    afterSuccess: refetch,
    cancelUrlFunc: (id) => `/invoice_transactions/${id}/change_state`,
    modelName: "invoice_payment",
  });

  useEffect(() => {
    if (!invoiceId && id) return setInvoiceId(id);
    refetch();
  }, [invoiceId, id]);

  const {
    invoice_number,
    status,
    dueDate,
    created_at,
    local_recipient,
    file_url,
    invoice_file,
    issuedDate,
    newAttachmentData,
    transfer_notes,
    reference_number,
    scheduled_payment,
    recipient_detail,
    state,
    pdfDownloadLink,
    recipient_data,
    is_duplicate,
  } = details ?? {};

  const isScheduledTransaction = !isEmpty(scheduled_payment);

  const { buttons: schedulePaymentButtons } = useSchedulePaymentButtons({
    transaction: details,
    afterSuccess: refetch,
    isReleaser,
  });

  const totalAmountDetails = createInvoiceSummary(details);

  const canEditSummary = Boolean(isApprover) ? isApprover : false;

  const {
    registered_name,
    bank_name: bank,
    account_number,
    vendor_emails,
  } = recipient_data || {};

  const { show } = useGdrivehook();
  const onClickSupportingDoc = (length) => {
    if (!length) return;
    show({ files: newAttachmentData });
  };

  const arrayDetails = [
    { title: "Created at", value: customDateFormatter(created_at || "") },
    {
      title: "Transaction ID",
      value: reference_number,
    },
    {
      title: "Recipient",
      value: (
        <div className="d-flex justify-content-end align-items-center">
          <div className="mr-2">
            <GothamRegular className="font12">{registered_name}</GothamRegular>
            <GothamRegular
              className="font10"
              style={{ color: jackColors.neutral700 }}
            >
              {bank?.toUpperCase()} - {account_number}
            </GothamRegular>
          </div>
          <div style={{ width: 24 }}>
            <Avatar name={registered_name} size="small" />
          </div>
        </div>
      ),
    },
    {
      title: "Email",
      value: vendor_emails?.[0] ?? "-",
    },
    {
      title: "Transfer note",
      value: transfer_notes,
    },
    { spacer: true },
    {
      title: "Invoice number",
      value: invoice_number,
    },
    {
      title: "Issue date",
      value: issuedDate,
    },
    {
      title: "Due date",
      value: dueDate,
    },
  ];
  const [formDetail, setFormDetail] = useState(false);

  const transaction = {
    ...details,
  };

  const { canSeeBanner, canRetryOrResubmit, retryResubmitDefaultProps } =
    useRetryResubmitModalPayloads({
      module: "bill",
      transaction,
      onClickSeeDetails: (detailId) => setInvoiceId(detailId),
    });

  const { onSeeDetails } = retryResubmitDefaultProps;

  const buttonDecider = () => {
    const isCompleted = state == "completed";
    if (isCompleted) return <DownloadButton downloadLink={pdfDownloadLink} />;
    if (isScheduledTransaction) return schedulePaymentButtons();

    if (canRetryOrResubmit) {
      return (
        <div className="d-flex justify-content-between w-100">
          <RetryResubmitButton
            {...retryResubmitDefaultProps}
            style={{ marginTop: "20px" }}
          />
        </div>
      );
    }
    if (status == "drafted")
      return (
        <DraftButtons
          handleContinue={() => setFormDetail(id)}
          handleDelete={() => {
            eventsTracker(DEMO_DELETE_DRAFT, { page_title: "draft" });
            handleDelete(id);
          }}
        />
      );

    return workflowButton();
  };

  const {
    data: formDefaultValue,
    open: openEditDetail,
    close: closeEditDetail,
    isOpen: isOpenEditDetail,
  } = useModalHookData();

  // DEMO PURPOSE
  const { isOpen: isOpenBlockAccess, toggle: toggleBlockAccess } =
    useModalHook();
  const [isMounted, setIsMounted] = useState(false);

  const onClickEdit = () => {
    // dihide for demo
    // openEditDetail(details);

    toggleBlockAccess();
  };

  const guidedTourProps = useGuidedTourBuilder({
    module: APPROVAL_MODULE,
    page: BILL_MODULE,
  });
  const { startTour, stopTour } = useGuidedTour({
    ...guidedTourProps,
    props: {
      scrollOffset: 300,
      continuous: true,
    },
  });

  useEffect(() => {
    if (!isOpen) return stopTour();
    if (!isMounted) return;

    setTimeout(() => {
      startTour();
    }, 500);
  }, [isOpen, isMounted]);

  return (
    <>
      <RightModal
        isOpen={isOpen || !(isRejection || openPinModal)}
        toggle={toggle}
        noPadding={true}
        isLoading={loading}
        array={[
          <MainContent
            data={data}
            isApprover={isApprover}
            buttons={buttonDecider}
            totalAmountDetails={totalAmountDetails}
            arrayDetails={arrayDetails}
            details={details}
            onClickSupportingDoc={onClickSupportingDoc}
            canEditSummay={canEditSummary}
            onClickEdit={onClickEdit}
            listener={listener}
            children={
              canSeeBanner && (
                <RetryResubmitBanner {...retryResubmitDefaultProps} />
              )
            }
            onSeeDetails={onSeeDetails}
          />,
          <PreviewComponent
            file_url={file_url}
            rawUrl={invoice_file}
            is_duplicate={is_duplicate}
            setIsMounted={setIsMounted}
          />,
        ]}
      />
      {rejectModal()}
      <InvoiceFormEditModal
        detailId={formDetail}
        toggle={() => setFormDetail(null)}
        refetchMain={refetch}
        isDraft
      />
      <InvoiceDetailForm
        isOpen={isOpenEditDetail}
        defaultValue={formDefaultValue}
        toggle={closeEditDetail}
        afterSuccessEdit={async () => {
          await refetch();
          closeEditDetail();
        }}
      />
      <DemoGeneralModal isOpen={isOpenBlockAccess} toggle={toggleBlockAccess} />
    </>
  );
};

export const DescriptionAccordion = ({ description = "" }) => {
  if (!description) return null;
  return (
    <div style={{ border: "1px solid #E6E6E8", borderRadius: 4 }}>
      <div
        style={{
          height: 56,
          width: "100%",
          padding: 12,
          display: "flex",
          alignItems: "center",
        }}
      >
        <Avatar
          mainIcon={
            <JackIcons
              name="file_text_outline"
              style={{ height: 19, width: "auto" }}
            />
          }
          size="medium"
        />
        <GothamMedium
          className="font12"
          style={{ color: jackColors.black34, marginLeft: 16 }}
        >
          Description
        </GothamMedium>
      </div>
      <div style={{ padding: 12, borderTop: "1px solid #E6E6E8" }}>
        <GothamRegular
          className="font12"
          style={{
            color: jackColors.neutral700,
            letterSpacing: 0.2,
            alignText: "justify",
          }}
        >
          <ParserRichTextArea text={description} />
        </GothamRegular>
      </div>
    </div>
  );
};

export const DraftButtons = ({
  handleContinue = () => {},
  handleDelete = () => {},
  loading,
  batchId,
  drafts = [],
  type = "invoice",
}) => {
  const isInvoice = type === "invoice";
  const isReimbursement = type === "reimbursement";

  const { push } = useRouter();

  const {
    isReachedInvoiceLimit,
    isReachedReimbursementLimit,
    reimbursement_qty_left,
    loadingSubscriptionUsage,
    isSeedV2Reimbursement,
  } = useSubscriptionUsage();

  const {
    isOpen: isOpenPreventionModal,
    open: openPreventionModal,
    close: closePreventionModal,
  } = useModalHook();
  const {
    isOpen: isOpenDraftSelectionModal,
    open: openDraftSelectionModal,
    close: closeDraftSelectionModal,
  } = useModalHook();

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) return;
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted]);

  const onContinue = () => {
    eventsTracker(DEMO_CLICK_CONTINUE_EDIT, { page_title: "draft" });
    if (isInvoice) {
      if (isReachedInvoiceLimit) return openPreventionModal();
    }

    if (isReimbursement) {
      const canFullyContinueDrafts =
        !isSeedV2Reimbursement || reimbursement_qty_left >= drafts.length;
      if (isReachedReimbursementLimit) return openPreventionModal();
      if (!canFullyContinueDrafts) return openDraftSelectionModal();
    }

    return handleContinue();
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        width: "100%",
        padding: "20px 0px",
      }}
      ref={(e) => {
        if (!e) return;
        setIsMounted(true);
      }}
    >
      <FloatingTooltip
        isOpen={isHoveredInfo}
        close={() => {
          eventsTracker(DEMO_TOOLTIP_NEXT, {
            page_title: "draft",
            tooltip_activity: "continue_edit_draft",
          });
          setIsHoveredInfo(false);
        }}
        title="Continue editing"
        body="Click this to continue editing the draft. You can also submit it right away!"
        tooltipStyle={{
          translate: "50px -195px",
          width: 260,
        }}
        translateTriangle={`130px 140px`}
        position="top"
      >
        <ButtonJack
          style={{ width: "100%" }}
          rightIcon={
            <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
          }
          isLoading={loadingSubscriptionUsage}
          onClick={onContinue}
        >
          Continue Editing
        </ButtonJack>
      </FloatingTooltip>
      <ButtonJack
        style={{ width: "100%" }}
        onClick={handleDelete}
        type="outline"
        isLoading={loading}
      >
        Delete This Draft
      </ButtonJack>

      <DraftSelectionModal
        drafts={drafts}
        batchId={batchId}
        limit={reimbursement_qty_left}
        isOpen={isOpenDraftSelectionModal}
        toggle={closeDraftSelectionModal}
        onContinue={handleContinue}
      />

      <PreventionModal
        type={type}
        withCancel={isInvoice}
        isOpen={isOpenPreventionModal}
        toggle={closePreventionModal}
        onClick={() => push("/plans/showcase")}
      />
    </div>
  );
};
export default InvoicePaymentModal;

export const EditedTitle = ({
  editBy,
  notes,
  editAt,
  label = "Notes to drafter",
}) => {
  const { users } = useConstants();

  if (!editBy) return null;
  const userName = users?.find(({ id }) => editBy == id)?.name;

  return (
    <div className="d-flex" style={{ gap: 4, alignItems: "center" }}>
      <GothamMedium>
        Summary{" "}
        <TextInlineRegular style={{ color: "#909098" }}>
          (edited)
        </TextInlineRegular>
      </GothamMedium>
      <TooltipError
        title={
          <GothamMedium style={{ color: "black" }}>
            Last edited by {userName}
          </GothamMedium>
        }
        desc={
          <div
            style={{
              paddingTop: 8,
              display: "flex",
              flexDirection: "column",
              gap: 8,
            }}
          >
            <GothamRegular>
              {label}: <br /> {notes}
            </GothamRegular>
            <GothamRegular style={{ color: "#909098" }}>
              {customDateFormatter(editAt)}
            </GothamRegular>
          </div>
        }
      >
        <div style={{ width: 18, height: 18 }}>
          <JackIcons
            name="info_outline"
            style={{ width: 18, height: 18 }}
            fill="#343434"
          />
        </div>
      </TooltipError>
    </div>
  );
};
