import { colors } from "../../../../../assets/colors";
import {
  GothamRegular,
  TextInlineMedium,
  GothamMedium,
} from "../../../../../components/Text";
import UnderstandButton from "../components/UnderstandButton";
import adjustCardLimitIllustration from "../../../../../assets/jackImages/adjust-limit-illustration.svg";
import replaceCardIllustration from "../../../../../assets/jackImages/replace-card-illustration.svg";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const PhysicalAM = ({ type }) => {
  const { setCurrentScreen } = useCardDetailContext();
  const isAdjustCardLimit = type === "adjustCardLimit";

  const title = isAdjustCardLimit
    ? "Request New Card Limit"
    : "Replace Physical Card";

  const image = isAdjustCardLimit
    ? adjustCardLimitIllustration
    : replaceCardIllustration;

  const message = isAdjustCardLimit ? "adjust your limit" : "replace your card";

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    title: {
      color: colors.neutral900,
      fontSize: "24px",
    },
    image: {
      width: "240px",
      height: "240px",
      margin: "0 auto",
    },
    text: {
      color: colors.neutral900,
      textAlign: "center",
    },
  };

  return (
    <div style={styles.container}>
      <GothamMedium style={styles.title}>{title}</GothamMedium>
      <img src={image} style={styles.image} />
      <GothamRegular style={styles.text}>
        Please contact{" "}
        <TextInlineMedium>our Sales/Account Manager(AM)</TextInlineMedium> to{" "}
        {message}.
      </GothamRegular>
      <UnderstandButton setCurrentScreen={setCurrentScreen} />
    </div>
  );
};

export default PhysicalAM;
