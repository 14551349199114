import { useGetAuth } from "../../../../contexts/AuthContext";
import { useMutation } from "../../../../tools/api";

const useCategoryFlag = () => {
  const { user, refetchUser } = useGetAuth();
  const { partner, all_flags } = user ?? {};
  const { category_setting, id } = partner ?? {};

  // DEMO PURPOSE
  // dihardcode as if udah ngeliat semua flag biar gak muncul onboarding2nya di demo
  // const flags = all_flags ?? []; //
  const flags = [
    "SEEN_CATEGORY_FEATURE",
    "SHOWN_CATEGORY_INTRO",
    "SHOWN_CATEGORY_WELCOME",
    "SHOWN_CATEGORY_WELCOME_TRX",
    "SHOWN_CATEGORY_WELCOME_TASK",
    "SEEN_NOTIF_GUIDED_TOUR",
  ];

  const { mutation } = useMutation({
    method: "post",
    url: `/business_users/${user?.id}/create_user_flag`,
    afterSuccess: () => {
      refetchUser();
    },
  });

  const { mutation: activateCategoryRaw, loading: loadingActivateCategory } =
    useMutation({
      url: `/business_partners/${id}/trx_category_setting`,
    });

  const categoryFlags = {
    hasSeenCategory: "SEEN_CATEGORY_FEATURE",
    hasIntroducedCategory: "SHOWN_CATEGORY_INTRO",
    hasWelcomedCategory: "SHOWN_CATEGORY_WELCOME",
    hasWelcomedCategoryTrx: "SHOWN_CATEGORY_WELCOME_TRX",
    hasWelcomedCategoryTask: "SHOWN_CATEGORY_WELCOME_TASK",
    hasSeenNotifGuidedTour: "SEEN_NOTIF_GUIDED_TOUR",
  };

  const isActiveCategory = category_setting !== "off";
  const isDeactivatedCategory = category_setting === "deactivated";
  const isRequiredCategory = category_setting === "required";
  const isNotRequiredCategory = category_setting === "not_required";

  const shallCheckMissingCategory = isRequiredCategory;

  const hasSeenCategory = flags.includes(categoryFlags.hasSeenCategory);
  const hasIntroducedCategory = flags.includes(
    categoryFlags.hasIntroducedCategory
  );
  const hasWelcomedCategory = flags.includes(categoryFlags.hasWelcomedCategory);
  const hasWelcomedCategoryTrx = flags.includes(
    categoryFlags.hasWelcomedCategoryTrx
  );
  const hasWelcomedCategoryTask = flags.includes(
    categoryFlags.hasWelcomedCategoryTask
  );
  const hasSeenNotifGuidedTour = flags.includes(
    categoryFlags.hasSeenNotifGuidedTour
  );

  const addCategoryFlag = async (flag) => {
    const isExist = flags.includes(flag);
    if (isExist) return;
    await mutation({ flags: flag });
  };

  const activateCategory = async ({
    setting = "off",
    afterSuccess = () => {},
  }) => {
    try {
      await activateCategoryRaw({ setting });
      afterSuccess();
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return {
    category_setting,
    categoryFlags,
    isActiveCategory,
    isDeactivatedCategory,
    isRequiredCategory,
    isNotRequiredCategory,
    hasSeenCategory,
    hasIntroducedCategory,
    hasWelcomedCategory,
    hasWelcomedCategoryTrx,
    hasWelcomedCategoryTask,
    shallCheckMissingCategory,
    addCategoryFlag,
    activateCategory,
    loadingActivateCategory,
    hasSeenNotifGuidedTour,
  };
};

export default useCategoryFlag;
