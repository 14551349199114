import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { RightModal } from "../../../../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import styles from "./RetryResubmitSummaryModal.module.css";
import CompanyBalanceBanner from "./CompanyBalanceBanner";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";
import {
  BILL,
  DIVIDER,
  EXPENSE_NUMBER,
  HANDLING_FEE,
  LOCAL,
  PAYROLL,
  REIMBURSEMENT,
  SUBTOTAL,
  TOTAL_AMOUNT,
  TOTAL_PAYMENT,
  VAT,
} from "../../constants";
import { useGetAuth } from "../../../../../contexts/AuthContext";

const DefaultLabel = ({ children = null, isDark = false }) => {
  return (
    <GothamRegular
      woFontColor
      className="font12"
      style={{ color: isDark ? jackColors.neutral900 : jackColors.neutral700 }}
    >
      {children}
    </GothamRegular>
  );
};

const PlusLabel = ({ children = null }) => {
  return (
    <div className={styles.label}>
      <JackIcons
        name="plus-circle"
        fill={jackColors.neutral500}
        style={{ scale: "0.8" }}
      />
      <DefaultLabel>{children}</DefaultLabel>
    </div>
  );
};

const summaryTemplate = {
  type: REIMBURSEMENT,
  name: "Outing Developer Q4 2023",
  list: [
    {
      type: "expense_number", // expense_number | vat | handling_fee | total_payment | subtotal | total_amount | divider
      value: 15,
    },
    {
      type: "total_amount",
      value: 5340000,
    },
    {
      type: "handling_fee",
      value: 1500,
    },
    {
      type: "divider",
    },
    {
      type: "total_payment",
      value: 5362500,
    },
  ],
};

const RetryResubmitSummaryModal = ({
  isOpen = false,
  summary = summaryTemplate,
  toggle = () => {},
  onConfirm = () => {},
}) => {
  const title = () => {
    switch (summary.type) {
      case LOCAL:
        return "Local Transfer";
      case PAYROLL:
        return "Payroll";
      case BILL:
        return "Bill Payment";
      case REIMBURSEMENT:
        return "Reimbursement";
      default:
        return "-";
    }
  };

  const titleIcon = () => {
    switch (summary.type) {
      case LOCAL:
        return "send_money";
      case PAYROLL:
        return "payroll";
      case BILL:
        return "invoice_payment";
      case REIMBURSEMENT:
        return "reimbursement";
      default:
        return "-";
    }
  };

  const label = (type = "") => {
    switch (type) {
      case EXPENSE_NUMBER:
        return <DefaultLabel>Number of expense</DefaultLabel>;
      case SUBTOTAL:
        return <DefaultLabel>Subtotal</DefaultLabel>;
      case TOTAL_AMOUNT:
        return <DefaultLabel>Total amount</DefaultLabel>;
      case TOTAL_PAYMENT:
        return <DefaultLabel>Total payment</DefaultLabel>;
      case VAT:
        return <PlusLabel>VAT</PlusLabel>;
      case HANDLING_FEE:
        return <PlusLabel>Handling fee</PlusLabel>;
      default:
        return <DefaultLabel>-</DefaultLabel>;
    }
  };

  const value = (type = "", value = 0) => {
    const convertIDR = (amount) => `IDR ${formatCurrencyNoDecimal(amount)}`;

    switch (type) {
      case EXPENSE_NUMBER:
        return <DefaultLabel isDark>{value} Expenses</DefaultLabel>;
      case SUBTOTAL:
      case TOTAL_AMOUNT:
      case TOTAL_PAYMENT:
      case VAT:
        return <DefaultLabel isDark>{convertIDR(value)}</DefaultLabel>;
      case HANDLING_FEE:
        const numberOfExpense =
          summary?.list?.find(({ type }) => type === EXPENSE_NUMBER)?.value ??
          0;
        const hasNumberOfExpense = numberOfExpense > 0;

        return (
          <DefaultLabel isDark>
            {convertIDR(value)}{" "}
            {hasNumberOfExpense ? `x ${numberOfExpense}` : ""}
          </DefaultLabel>
        );
      default:
        return <DefaultLabel>-</DefaultLabel>;
    }
  };

  const { user } = useGetAuth();

  const totalPayment = summary?.list?.find(
    ({ type }) => type === TOTAL_PAYMENT
  )?.value;

  const isSufficientBalance = user?.mainBalance?.balance >= totalPayment;

  return (
    <RightModal
      noPadding
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <div className={styles.container}>
          <div className={styles.top}>
            <GothamMedium
              className="font24"
              style={{
                color: jackColors.neutral900,
                marginTop: "16px",
                marginLeft: "16px",
              }}
            >
              Summary
            </GothamMedium>
            <div className={styles.summaryContainer}>
              <div className={styles.summary}>
                <div className={styles.summaryTitle}>
                  <div className={styles.trxAvatar}>
                    <JackIcons
                      name={titleIcon()}
                      fill={jackColors.neutral100}
                      style={{ scale: "0.8" }}
                    />
                  </div>

                  <div>
                    <GothamMedium
                      className="font12"
                      style={{
                        color: jackColors.neutral900,
                        marginBottom: "4px",
                      }}
                    >
                      {title()}
                    </GothamMedium>
                    <GothamRegular
                      woFontColor
                      className="font12"
                      style={{ color: jackColors.neutral700 }}
                    >
                      {summary?.name ?? "-"}
                    </GothamRegular>
                  </div>
                </div>

                <div className={styles.divider} />

                {summary?.list?.map(({ type, value: valueRaw }) => {
                  if (type === DIVIDER) {
                    return <div className={styles.divider} />;
                  }

                  return (
                    <div className={styles.summaryItem}>
                      {label(type)}
                      {value(type, valueRaw)}
                    </div>
                  );
                })}
              </div>
            </div>
          </div>

          <div className={styles.bottom}>
            <CompanyBalanceBanner />

            <div className={styles.buttons}>
              <ButtonJack
                type="outline"
                style={{ width: "100%" }}
                leftIcon={
                  <JackIcons
                    name="arrow-back-outline"
                    fill={jackColors.neutral900}
                  />
                }
                onClick={toggle}
              >
                Back
              </ButtonJack>
              <ButtonJack
                style={{ width: "100%" }}
                disabled={!isSufficientBalance}
                leftIcon={
                  <JackIcons
                    name="checkmark-circle-2"
                    fill={
                      isSufficientBalance
                        ? jackColors.neutral900
                        : jackColors.neutral600
                    }
                  />
                }
                onClick={onConfirm}
              >
                Approve & Pay
              </ButtonJack>
            </div>
          </div>
        </div>,
      ]}
    />
  );
};

export default RetryResubmitSummaryModal;
