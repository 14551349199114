import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { BILL_SECOND_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${BILL_SECOND_TARGET}`,
    placement: "bottom",
    content: (
      <TooltipContent
        title="Complete missing details"
        description="Make sure every details is filled out before submitting."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      guided_tour_activity: "complete_invoice_detail",
      activity_type: "create_bill_payment",
    },
  },
];

const billSecondPageProps = { steps };

export default billSecondPageProps;
