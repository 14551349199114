import { isEmpty } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { Banner } from "../../components/Banner";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import { CheckBox } from "../../components/Checkbox";
import {
  SelectionJack,
  SelectionJackState,
} from "../../components/inputs/selection";
import { RightModal } from "../../components/Modals/RightModal/parent";
import { GothamMedium, GothamRegular } from "../../components/Text";
import { ToasterHook } from "../../contexts/ToasterContext";
import { useMutation } from "../../tools/api";
import { WrapperModalButtons } from "../homeComponents/topup/components";
import { redStarLabel } from "../invoiceComponents/createPage/modals/formEdit";
import { pluralize } from "../invoiceComponents/data/formatter";
import {
  generateTimeOptions,
  productsMenuOptions,
  timeRange,
} from "../scheduledPaymentComponents/constant";
import {
  extractTimeForForm,
  payloadMaker,
  setFormDefaultValue,
} from "../scheduledPaymentComponents/formatter";
import {
  AddPaymentCycleForm,
  Counter,
  CustomResultBanner,
  SetDefaultCheckbox,
} from "../scheduledPaymentComponents/paymentOptions/modals";

export const ModalButtons = ({
  loading,
  children,
  onClickLeft,
  onClickRight,
  style,
  isEdit = false,
  rightButtonText,
  disableRightButton = false,
}) => {
  const text = isEdit ? "Save Changes" : "Create";
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16, ...style }}
    >
      <ButtonJack
        style={{ width: "50%" }}
        isLoading={loading}
        type="outline"
        onClick={() => onClickLeft && onClickLeft()}
      >
        Cancel
      </ButtonJack>
      <ButtonJack
        style={{ width: "50%" }}
        isLoading={loading}
        onClick={() => onClickRight && onClickRight()}
        disabled={disableRightButton}
      >
        {rightButtonText ?? text}
      </ButtonJack>
      {children}
    </div>
  );
};

export const RadioOption = ({
  label,
  isActive,
  handleClick,
  isDisabled,
  text,
  containerStyle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        gap: 8,
        height: 40,
        alignItems: "start",
        cursor: "pointer",
        ...containerStyle,
      }}
      onClick={handleClick}
    >
      <CheckBox
        isActive={isActive}
        type="circle"
        onClick={(e) => {
          e.stopPropagation();
          handleClick();
        }}
      />
      <div
        className="d-flex-column justify-between"
        // style={{ backgroundColor: "red" }}
      >
        <GothamRegular style={{ color: isDisabled ? "#BBBBC0" : "#343434" }}>
          {label}
        </GothamRegular>
        <GothamRegular className="font12" style={{ color: "#BBBBC0" }}>
          {text}
        </GothamRegular>
      </div>
    </div>
  );
};

const DueDateForm = ({ useFormObj, defaultValue }) => {
  const { setValue, watch } = useFormObj;

  useEffect(() => {
    setValue("recurrence", { label: "due_date", value: "due_date" });
    setValue("time", null);
    setValue("days_before_due", 0);
  }, []);

  const daysbeforeDueVal = watch("days_before_due");
  const timeVal = watch("time");

  const showBanner = Boolean(daysbeforeDueVal) && Boolean(timeVal);
  const text = useMemo(() => {
    const isOnDueDate = +daysbeforeDueVal > 1;
    const recurrenceText = !isOnDueDate
      ? "on"
      : `${daysbeforeDueVal} ${pluralize(daysbeforeDueVal, "day")} before`;
    const timeText = timeVal?.label;
    return `Payment will be processed ${recurrenceText} the invoice’s due date at ${timeText} WIB.`;
  }, [daysbeforeDueVal, timeVal]);

  useEffect(() => {
    if (defaultValue?.transaction_type) {
      const { days_before_due, date_and_time } = defaultValue;
      setValue("days_before_due", days_before_due);
      setValue("time", extractTimeForForm(date_and_time));
    }
  }, [defaultValue]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 16,
        paddingLeft: 28,
      }}
      className="smoothIn"
    >
      <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
        <GothamRegular>Pay</GothamRegular>
        <Counter useFormObj={useFormObj} name="days_before_due" max={30} />
        <GothamRegular>day before due date</GothamRegular>
      </div>
      <SelectionJack
        label={redStarLabel("Select Time")}
        required
        options={generateTimeOptions(6, 22)}
        useFormObj={useFormObj}
        name="time"
        // defaultValue={dummyOption[0]}
        placeholder="Select time"
        containerStyle={{ margin: 0 }}
      />
      <SetDefaultCheckbox
        value="InvoiceTransaction"
        name="is_default"
        useFormObj={useFormObj}
      />
      {showBanner && <CustomResultBanner text={text} showResult={showBanner} />}
    </div>
  );
};

const InvoiceForm = ({ useFormObj, defaultValue, isEdit }) => {
  const { setValue, register, watch } = useFormObj;
  const recurrenceVal = watch("recurrence")?.value;
  const isDueDate = recurrenceVal == "due_date";
  const isRecurrence = recurrenceVal?.length && !isDueDate;
  const handleClick = (val) => {
    const { value } = val;
    if (value == recurrenceVal) return setValue("recurrence", "");
    return setValue("recurrence", val);
  };

  useEffect(() => {
    register("transaction_type");
    register("recurrence");
    setValue(
      "transaction_type",
      productsMenuOptions.filter((v) => v.value == "InvoiceTransaction").pop()
    );
  }, []);

  useEffect(() => {
    if (Boolean(defaultValue?.transaction_type)) {
      const { recurrence, date_and_time, days_before_due } = defaultValue;

      const isDueDate = recurrence == "due_date";

      if (isDueDate) {
        setValue("recurrence", { label: "due_date", value: "due_date" });
        // setValue("days_before_due", days_before_due);
        // setValue("time", extractTimeForForm(date_and_time));

        return;
      }

      return setValue(
        "recurrence",
        timeRange?.filter(({ value }) => value == recurrence)?.pop()
      );
    }
  }, [defaultValue]);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 16 }}>
      <SelectionJack
        useFormObj={useFormObj}
        options={productsMenuOptions}
        containerStyle={{ margin: 0 }}
        name="transaction_type"
        required
        isDisabled={isEdit}
        helperText={isEdit && "Transaction cannot be changed"}
      />
      <GothamRegular className="mt-2">Set schedule by:</GothamRegular>
      <RadioOption
        label="Invoice due date"
        text="Payment will be processed based on the due date"
        isActive={isDueDate}
        handleClick={() =>
          handleClick({ label: "due_date", value: "due_date" })
        }
      />
      {isDueDate && (
        <DueDateForm useFormObj={useFormObj} defaultValue={defaultValue} />
      )}
      <RadioOption
        label="Recurrence"
        text="Payment will be processed based on recurring schedule"
        isActive={isRecurrence}
        handleClick={() => handleClick({ label: "Day", value: "day" })}
      />
      {isRecurrence && (
        <AddPaymentCycleForm
          useFormObj={useFormObj}
          product="InvoiceTransaction"
          isRightModal
          defaultValue={defaultValue}
        />
      )}
    </div>
  );
};

export const FormDecider = ({ useFormObj, defaultValue, isEdit }) => {
  const { watch } = useFormObj;
  const product = watch("transaction_type")?.value;
  const isInvoice = product == "InvoiceTransaction";
  if (isInvoice) {
    return (
      <InvoiceForm
        useFormObj={useFormObj}
        defaultValue={defaultValue}
        isEdit={isEdit}
      />
    );
  }

  return (
    <AddPaymentCycleForm
      useFormObj={useFormObj}
      product={product}
      defaultValue={defaultValue}
      isEdit={isEdit}
    />
  );
};

const ProductMenu = ({
  toggle,
  useFormObj,
  onSubmit,
  isEdit = false,
  defaultValue,
  loading,
}) => {
  // const [product, setProduct] = useState("");

  const { handleSubmit, watch, setValue } = useFormObj;
  // const handleChangeProduct = (val) => setProduct(val);

  const title = isEdit ? "Edit Payment Cycle" : "Create Payment Cycle";
  const product = watch("transaction_type")?.value;
  useEffect(() => {
    if (Boolean(defaultValue?.transaction_type)) {
      const { transaction_type } = defaultValue;
      // setProduct(transaction_type);
      setValue(
        "transaction_type",
        productsMenuOptions
          ?.filter(({ value }) => value == transaction_type)
          ?.pop()
      );
      return;
    }
    return setValue("transaction_type", null);
  }, [defaultValue]);

  const bannerText = isEdit
    ? "The change will only apply to future transactions. Existing linked transactions will be paid on the initial schedule."
    : "Create a payment schedule template that fits your company's needs.";
  const bannerType = isEdit ? "warning" : "information";

  return (
    <WrapperModalButtons
      childrenButton={
        <ModalButtons
          isEdit={isEdit}
          loading={loading}
          onClickLeft={toggle}
          onClickRight={handleSubmit(onSubmit)}
        />
      }
      containerStyle={{
        minHeight: 80,
        height: "auto",
        padding: 0,
        paddingTop: 4,
        paddingLeft: 20,
        paddingRight: 20,
      }}
    >
      <div
        style={{
          width: "100%",
          padding: 16,
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <GothamMedium className="font24">{title}</GothamMedium>
        <Banner msg={bannerText} type={bannerType} />
        {!product ? (
          // <SelectionJack
          //   options={productsMenuOptions}
          //   onChange={handleChangeProduct}
          //   placeholder="Select transaction type"
          // />
          <SelectionJack
            useFormObj={useFormObj}
            options={productsMenuOptions}
            containerStyle={{ marginBottom: 16 }}
            name="transaction_type"
            placeholder="Select transaction type"
            required
            label={redStarLabel("Transaction Type")}
            isDisabled={isEdit}
            helperText={isEdit && "Transaction cannot be changed"}
          />
        ) : (
          <FormDecider
            product={product}
            useFormObj={useFormObj}
            defaultValue={defaultValue}
            isEdit={isEdit}
          />
        )}
      </div>
    </WrapperModalButtons>
  );
};

export const AddPaymentCycleRightModal = ({
  isOpen,
  toggle,
  refetchProduct,
}) => {
  const useFormObj = useForm();
  const { handleSubmit } = useFormObj;
  const { successSnackBar } = ToasterHook();
  const { mutation: createPaymentCycle, loading } = useMutation({
    url: "/predefined_schedules",
    method: "post",
    afterSuccess: (val) => {
      successSnackBar({
        msg: "You’ve created a payment cycle!",
        showClose: true,
      });
      toggle();
    },
  });

  const onSubmit = async (val) => {
    const payload = payloadMaker(val);
    const productType = payload.transaction_type;
    const refetch = refetchProduct(productType);
    await createPaymentCycle(payload);
    return refetch();
  };

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      noPadding
      array={[
        <ProductMenu
          toggle={toggle}
          useFormObj={useFormObj}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
        />,
      ]}
    />
  );
};
export const EditPaymentCycleRightModal = ({
  isOpen,
  toggle,
  data,
  refetch,
}) => {
  const { successSnackBar } = ToasterHook();
  const useFormObj = useForm();
  const { handleSubmit } = useFormObj;
  const { id } = data;
  const { mutation: editPC, loading } = useMutation({
    url: `/predefined_schedules/${id}`,
    afterSuccess: (res) => {
      refetch();
      successSnackBar({
        msg: "You’ve edited the payment cycle!",
        showClose: true,
      });
      toggle();
    },
  });

  const onSubmit = async (val) => {
    const payload = payloadMaker(val);
    await editPC(payload);
  };

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      noPadding
      array={[
        <ProductMenu
          toggle={toggle}
          useFormObj={useFormObj}
          isEdit
          defaultValue={data}
          onSubmit={handleSubmit(onSubmit)}
          loading={loading}
        />,
      ]}
    />
  );
};
