import { useRouter } from "next/router";
import { useState } from "react";
import { arraySplitter, containsOnlyDigits } from "../../../components/tools";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { apiBusiness } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { getEmails } from "./parent";

export const TrimNotesLocalPayroll = (input) => {
  if (!input) return null;
  // Menghapus simbol dengan hanya menyisakan huruf dan angka
  var cleanedString = input.replace(/[^a-zA-Z0-9 ]/g, "");

  // Memotong string menjadi 50 karakter pertama
  var trimmedString = cleanedString.substring(0, 50);

  return trimmedString;
};

export const useLocalTransferLogics = () => {
  const { query, push } = useRouter();
  const { id } = query;
  const [loading, setLoading] = useState(false);

  const { errorToasterApi } = ToasterHook();

  const successFunc = async (id, isValidate) => {
    if (!isValidate) return push("/draft/?menu=local_transfer");

    await apiBusiness.put(`/local_transaction_batches/${id}/change_state`, {
      state: "validate_recipient",
    });

    push({
      pathname: `/local-transfer/${id}`,
      query: { ...query, state: "validating" },
    });
  };

  const create = async ({ values, isValidate }) => {
    try {
      setLoading(true);
      const { local_transactions_attributes } = values || {};
      const [firstArray, ...restArray] = arraySplitter(
        local_transactions_attributes,
        50
      );
      const createObj = {
        ...values,
        local_transactions_attributes: firstArray,
      };
      const res = await apiBusiness.post(
        "/local_transaction_batches",
        createObj
      );
      const { id, name } = res?.data?.data || {};

      for (let i = 0; i < restArray.length; i++) {
        const local_transactions_attributes = restArray[i];
        const updateObj = { name, local_transactions_attributes };
        await apiBusiness.put(`/local_transaction_batches/${id}`, updateObj);
      }

      const eventName = isValidate
        ? "validate_create_local_transfer"
        : "save_as_draft_create_local_transfer";

      eventsTracker(eventName, values);

      const _ = await successFunc(id, isValidate);
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  const update = async ({
    values,
    isValidate,
    woSuccess,
    beforeSuccess,
    afterSuccess,
  }) => {
    try {
      setLoading(true);
      beforeSuccess && (await beforeSuccess());
      const { deletedIds, updateObj, createObj } = values || {};
      const { local_transactions_attributes, name } = createObj || {};
      const { transactions } = updateObj || {};
      const { ids } = deletedIds || {};
      const isDelete = (ids || []).length;
      const isCreate = (local_transactions_attributes || []).length || name;
      const isUpdate = (transactions || []).length;

      if (isUpdate) {
        for (let i = 0; i < transactions.length; i++) {
          const transaction = transactions[i];
          const { id: trx_id } = transaction;
          const value = {
            mismatched_action: "replace",
            transaction,
          };

          const url = `/local_transaction_batches/${id}/update_single_transaction/${trx_id}`;
          await apiBusiness.put(url, value);
        }
      }

      if (isDelete)
        await apiBusiness.put(
          `/local_transaction_batches/${id}/bulk_trx_destroy`,
          deletedIds
        );

      if (isCreate) {
        const isLooped = (local_transactions_attributes || []).length;

        if (isLooped) {
          const splittedArray = arraySplitter(
            local_transactions_attributes,
            50
          );
          for (let i = 0; i < splittedArray.length; i++) {
            const local_transactions_attributes = splittedArray[i];
            const result = { ...createObj, local_transactions_attributes };
            await apiBusiness.put(`/local_transaction_batches/${id}`, result);
          }
        } else {
          await apiBusiness.put(`/local_transaction_batches/${id}`, createObj);
        }
      }

      const woAnySuccess = !afterSuccess && !beforeSuccess;

      woAnySuccess &&
        eventsTracker(
          isValidate
            ? "validate_update_local_transfer"
            : "save_as_draft_update_local_transfer"
        );

      if (!woSuccess) await successFunc(id, isValidate);

      afterSuccess && (await afterSuccess());
    } catch (error) {
      errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, create, update };
};

export const defaultValuesFormatter = (defaultValuesRaw) => {
  const isArray = Array.isArray(defaultValuesRaw);

  if (!isArray) return defaultValuesRaw;

  const defaultValues = defaultValuesRaw.map((arrayOrObj) => {
    const isArray = Array.isArray(arrayOrObj);

    if (!isArray) {
      const {
        email: emailRaw,
        account_number: accNumberRaw,
        amount: amountRaw,
        notes: notesRaw,
      } = arrayOrObj || {};
      const minimalAmount = +amountRaw >= 10000;
      const isOnlyNumber = (value) => containsOnlyDigits(value);

      const amount =
        isOnlyNumber(amountRaw) && minimalAmount ? amountRaw : null;
      const account_number = isOnlyNumber(accNumberRaw) ? accNumberRaw : null;
      const email = getEmails(emailRaw);
      const notes = TrimNotesLocalPayroll(notesRaw);

      return { ...arrayOrObj, email, amount, account_number, notes };
    }

    const newArray = arrayOrObj.map((item) => {
      const { name, value } = item || {};

      const isEmail = name == "email";

      if (!isEmail) return item;

      if (!value) return item;

      const correctEmails = getEmails(value);

      return { ...item, value: correctEmails };
    });
    return newArray;
  });

  return defaultValues;
};
