import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "reactstrap";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { CheckBox } from "../../../components/Checkbox";
import { DateInputJackUseForm } from "../../../components/inputs/date/parent";
import { SelectionJack } from "../../../components/inputs/selection";
import { TextFieldJack } from "../../../components/inputs/textfield";
import {
  GothamMedium,
  GothamRegular,
  TextInlineRegular,
} from "../../../components/Text";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useMutation } from "../../../tools/api";
import { redStarLabel } from "../../invoiceComponents/createPage/modals/formEdit";
import { FormDecider } from "../../paymentCycleComponents/modal";
import {
  days,
  generateTimeOptions,
  generateTimeOptionsComplex,
  productsMenuOptions,
  timeRange,
} from "../constant";
import {
  changeHour,
  extractTimeForForm,
  formatDateTimeString,
  formatScheduleString,
  getMonthlySummary,
  getNextPaymentDates,
  getYearlySummary,
  payloadMaker,
  setFormDefaultValue,
} from "../formatter";

const dummyOption = generateTimeOptions(6, 22);
const CloseButtonModal = ({ toggle }) => {
  return (
    <div
      className="hover-600"
      style={{
        width: 40,
        height: 40,
        border: "1px solid #E6E6E8",
        borderRadius: 4,
        display: "grid",
        placeItems: "center",
        cursor: "pointer",
      }}
      onClick={toggle}
    >
      <JackIcons
        name="close_Outline"
        fill="#343434"
        style={{ width: 20, height: 20 }}
      />
    </div>
  );
};

const ModalHeader = ({ title, subtitle, handleClose }) => {
  return (
    <div
      style={{
        padding: 20,
        borderBottom: "1px solid #E6E6E8",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <div className="d-flex-column">
        <GothamMedium className="font20 mb-1">{title}</GothamMedium>
        <GothamRegular style={{ color: "#909098" }}>{subtitle}</GothamRegular>
      </div>
      <CloseButtonModal toggle={handleClose} />
    </div>
  );
};

const ModalButtons = ({ onClickSave, onClickCancel }) => {
  return (
    <div
      style={{
        padding: 20,
        borderTop: "1px solid #E6E6E8",
        display: "flex",
        justifyContent: "end",
      }}
    >
      <div style={{ display: "flex", gap: 16 }}>
        <ButtonJack
          type="outline"
          style={{ width: 66 }}
          onClick={onClickCancel}
        >
          Cancel
        </ButtonJack>
        <ButtonJack style={{ width: 52 }} onClick={onClickSave}>
          Save
        </ButtonJack>
      </div>
    </div>
  );
};

export const CustomResultBanner = ({
  showResult = false,
  text,
  helperText,
}) => {
  const Text = showResult ? GothamMedium : GothamRegular;
  return (
    <div>
      <div
        style={{
          minHeight: 44,
          height: "auto",
          width: "100%",
          borderRadius: 4,
          backgroundColor: showResult ? "#F4F9FF" : "#F1F1F1",
          border: `1px solid ${showResult ? "#6087E7" : "#E6E6E8"}`,
          display: "flex",
          gap: 8,
          padding: 12,
          position: "relative",
        }}
      >
        <JackIcons
          name="calendar"
          fill={showResult ? "#343434" : "#909098"}
          style={{ width: 20, height: 20 }}
        />
        <Text
          style={{ minHeight: 20, color: showResult ? "#343434" : "#909098" }}
        >
          {text}
        </Text>
      </div>
      {helperText && (
        <GothamRegular
          className="font10"
          style={{ color: "#909098", marginTop: 4 }}
        >
          {helperText}
        </GothamRegular>
      )}
    </div>
  );
};

export const SpesificDateModal = ({
  isOpen,
  toggle,
  onSubmit: onClick,
  useFormObj,
  defaultValue,
}) => {
  const { handleSubmit, watch, reset, setValue, register } = useFormObj;

  const onSubmit = (val) => {
    const { time: timeRaw, date } = val;
    const date_and_time = changeHour(date, timeRaw?.value);

    return onClick(date_and_time);
  };

  const dateValue = watch("date");
  const timeValue = watch("time");
  const showResult = Boolean(dateValue) && Boolean(timeValue);

  const text = showResult
    ? formatDateTimeString(watch())
    : "Your selected date and time will appear here";

  const handleClose = () => {
    setValue("date", null);
    setValue("time", null);
    toggle();
  };

  // useEffect(() => {
  //   if (defaultValue.type == "spesific_date") {
  //     const options = { hour: "2-digit", minute: "2-digit", hour12: false };
  //     const { date_and_time: value } = defaultValue?.payload;
  //     const formattedTime = value.toLocaleTimeString("en-US", options);
  //     const defaultTimeValue = dummyOption
  //       ?.filter(({ value }) => value == formattedTime)
  //       ?.pop();

  //     console.log(defaultTimeValue, value, "dft");
  //     register("date");
  //     register("time");
  //     setValue("date", value);
  //     setValue("time", defaultTimeValue);
  //   }
  // }, [defaultValue]);

  useEffect(() => {
    if (dateValue && timeValue) return setValue("time", null);
  }, [dateValue]);

  const options = generateTimeOptionsComplex(dateValue);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="my-0"
      centered
      style={{ width: 480 }}
    >
      <div>
        <ModalHeader
          title="Specific Date & Time"
          subtitle="Set the payment date and time based on the transaction’s regulation or your preference."
          handleClose={handleClose}
        />
        <div
          style={{
            padding: 20,
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div className="d-flex justify-content-between">
            <DateInputJackUseForm
              label={redStarLabel("Select Date")}
              style={{ width: 204 }}
              minDate={new Date()}
              useFormObj={useFormObj}
              name="date"
              placeholder="Select date"
              dateFormat="d/MM/yyyy"
            />
            <SelectionJack
              label={redStarLabel("Select Time (WIB)")}
              required
              options={options}
              containerStyle={{ width: 204 }}
              useFormObj={useFormObj}
              name="time"
              // defaultValue={dummyOption[0]}
              placeholder="Select time"
            />
          </div>
          <CustomResultBanner showResult={showResult} text={text} />
        </div>
        <div
          style={{
            padding: 20,
            borderTop: "1px solid #E6E6E8",
            display: "flex",
            justifyContent: "end",
          }}
        >
          <div style={{ display: "flex", gap: 16 }}>
            <ButtonJack
              type="outline"
              style={{ width: 66 }}
              onClick={handleClose}
            >
              Cancel
            </ButtonJack>
            <ButtonJack
              style={{ width: 52 }}
              onClick={handleSubmit(onSubmit)}
              disabled={!showResult}
            >
              Save
            </ButtonJack>
          </div>
        </div>
      </div>
    </Modal>
  );
};

const DaysInput = ({ useFormObj, name, defaultValue = [] }) => {
  const { register, setValue, watch } = useFormObj;
  const currValue = watch(name) ?? [];

  const handleClick = (value) => {
    const isFalsy = isEmpty(currValue);
    const isExist = currValue?.includes(value);
    const isSingle = currValue?.length == 1;

    if (isSingle && isExist) return;
    if (isFalsy) return setValue(name, [value]);
    if (isExist)
      return setValue(
        name,
        currValue.filter((val) => val != value)
      );

    const newValue = [...currValue, value];
    return setValue(name, newValue);
  };

  useEffect(() => {
    register(name);
    //setValue(name, defaultValue);
  }, []);
  return (
    <div style={{ display: "flex", flexDirection: "column", gap: 8 }}>
      <GothamRegular className="font12">
        Select Day(s)
        <TextInlineRegular style={{ color: "red" }}>*</TextInlineRegular>
      </GothamRegular>
      <div style={{ display: "flex", gap: 12 }}>
        {days?.map(({ label, value }) => {
          const isActive = currValue?.includes(value);
          return (
            <div
              className="preventblueblock"
              style={{
                width: 42,
                height: 42,
                borderRadius: 4,
                border: `${isActive ? 2 : 1}px solid ${
                  isActive ? "#343434" : "#E6E6E8"
                }`,
                display: "grid",
                placeItems: "center",
                cursor: "pointer",
              }}
              onClick={() => handleClick(value)}
            >
              <GothamMedium>{label}</GothamMedium>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const SetDefaultCheckbox = ({
  useFormObj,
  name,
  defaultValue = false,
  value,
}) => {
  const { register, watch, setValue } = useFormObj;
  const currValue = watch(name) ?? false;
  const handleClick = () => {
    setValue(name, !currValue);
  };

  const label = productsMenuOptions
    ?.filter(({ value: optionVal }) => optionVal == value)
    ?.pop()?.label;

  useEffect(() => {
    register(name);
    //setValue(name, defaultValue);
  }, []);
  return (
    <div
      style={{
        height: 44,
        display: "flex",
        gap: 8,
        alignItems: "start",
      }}
      className="smoothIn"
    >
      <CheckBox isActive={currValue} onClick={handleClick} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          height: "100%",
        }}
      >
        <GothamRegular>
          Set as {titleCase(label)} default schedule
        </GothamRegular>
        <GothamRegular className="font12" style={{ color: "#909098" }}>
          {label} transactions will follow this schedule automatically. You can
          change it anytime.
        </GothamRegular>
      </div>
    </div>
  );
};

const RadioOption = ({ label, isActive, handleClick, isDisabled }) => {
  return (
    <div style={{ display: "flex", gap: 8, paddingLeft: 28 }}>
      <CheckBox isActive={isActive} type="circle" onClick={handleClick} />
      <GothamRegular style={{ color: isDisabled ? "#BBBBC0" : "#343434" }}>
        {label}
      </GothamRegular>
    </div>
  );
};

export const Counter = ({
  useFormObj,
  name,
  defaultValue = 0,
  max = 30,
  min = 0,
}) => {
  const { watch, setValue, register } = useFormObj;

  const currValue = watch(name);
  const isString = typeof currValue == "string";
  const passLimit = currValue > max;
  const formattedCurrValue = `${currValue}`?.replace(/[^0-9]/g, "");
  const handleCountUp = () => {
    if (Number(currValue) + 1 > max) return;
    return setValue(name, +(Number(currValue) + 1));
  };
  const handleCountDown = () => {
    if (Number(currValue) - 1 < 0) return;
    return setValue(name, +(Number(currValue) - 1));
  };

  useEffect(() => {
    setValue(name, defaultValue);
  }, [defaultValue]);

  useEffect(() => {
    return setValue(name, formattedCurrValue);
  }, [currValue]);

  useEffect(() => {
    if (passLimit) return setValue(name, +Number(max));
  }, [passLimit]);

  return (
    <div
      style={{
        position: "relative",
      }}
      className="preventblueblock"
    >
      <TextFieldJack
        useFormObj={useFormObj}
        woLabel
        name={name}
        style={{
          height: 40,
          width: 84,
          margin: 0,
        }}
      />
      <div
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          paddingRight: 10,
        }}
      >
        <JackIcons
          name="chevron_up"
          fill="#BBBBC0"
          className="iconHover"
          style={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={handleCountUp}
        />
        <JackIcons
          name="chevron_down"
          className="iconHover"
          fill="#BBBBC0"
          style={{ width: 20, height: 20, cursor: "pointer" }}
          onClick={handleCountDown}
        />
      </div>
    </div>
  );
};

const BusinessDayOptions = ({ useFormObj, name, defaultValue = "" }) => {
  const { register, setValue, watch } = useFormObj;
  const currValue = watch(name);
  const isFriday = currValue === "Friday";
  const isMonday = currValue === "Monday";
  const [isActive, setIsActive] = useState(false);
  const handleClick = () =>
    setIsActive((p) => {
      if (p) setValue(name, "");
      return !p;
    });

  const handleClickRadio = (val) => {
    if (!isActive) return;
    return setValue(name, val);
  };

  useEffect(() => {
    register(name);
    //setValue(name, "");
  }, []);

  useEffect(() => {
    if (isFriday || isMonday) return setIsActive(true);
  }, [currValue]);
  return (
    <div
      style={{ display: "flex", flexDirection: "column", gap: 16 }}
      className="preventblueblock smoothIn"
    >
      <div
        style={{
          height: 44,
          display: "flex",
          gap: 8,
          alignItems: "start",
        }}
      >
        <CheckBox isActive={isActive} onClick={handleClick} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
            color: isActive ? "#909098" : "#BBBBC0",
          }}
        >
          <GothamRegular>Only pay on business days</GothamRegular>
          <GothamRegular className="font12" style={{ color: "#909098" }}>
            Adjust payments that occur on weekends
          </GothamRegular>
        </div>
      </div>
      <>
        <RadioOption
          isDisabled={!isActive}
          label="Payment postponed to the next Monday"
          isActive={isMonday}
          handleClick={() => handleClickRadio("Monday")}
        />
        <RadioOption
          isDisabled={!isActive}
          label="Payment advanced to Friday"
          isActive={isFriday}
          handleClick={() => handleClickRadio("Friday")}
        />
      </>
    </div>
  );
};

export const AddPaymentCycleForm = ({
  useFormObj,
  containerStyle,
  product,
  isRightModal = false,
  defaultValue,
  isEdit = false,
}) => {
  const { watch, reset, register, setValue, getValues } = useFormObj;
  const isInvoice = product == "InvoiceTransaction";
  const rangeVal = watch("recurrence");
  const isDayRange = rangeVal?.value == "day";
  const isWeekRange = rangeVal?.value == "week";
  const isMonthRange = rangeVal?.value == "month";
  const isYearRange = rangeVal?.value == "year";

  useEffect(() => {
    resetForm();
  }, []);

  const showResultDecider = () => {
    const timeVal = watch("time");
    const repeatEvery = watch("repeat_every");
    const daysVal = watch("days");
    const dateVal = watch("date");

    if (isDayRange) return timeVal && repeatEvery;
    if (isWeekRange) return timeVal && repeatEvery && daysVal;
    return timeVal && repeatEvery && dateVal;
  };

  const isToggle = showResultDecider();
  const resetForm = (woRecurencce = false) => {
    setValue(
      "transaction_type",
      productsMenuOptions.filter((v) => v.value == product).pop()
    );
    setValue("repeat_every", 1);
    !woRecurencce && setValue("recurrence", timeRange[0]);
    setValue("days", []);
    setValue("date", null);
    setValue("time", null);
  };

  const textDecider = (obj) => {
    const payload = payloadMaker(getValues());
    const { non_business_days_skip_to: skipTo } = payload;
    const isMonday = skipTo == "Monday";
    let text = "";
    let helperText = "";

    if (isDayRange) text = formatScheduleString(payload)?.text;
    if (isWeekRange) text = getNextPaymentDates(payload)?.text;
    if (isMonthRange) text = getMonthlySummary(payload);
    if (isYearRange) text = getYearlySummary(payload);

    if (skipTo) helperText = "";

    return { text, helperText };
  };

  useEffect(() => {
    if (Boolean(defaultValue?.transaction_type))
      return setFormDefaultValue({ defaultValue, useFormObj });
  }, [defaultValue]);
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 24,
        ...containerStyle,
      }}
    >
      {!isRightModal && !isInvoice && (
        <SelectionJack
          useFormObj={useFormObj}
          options={productsMenuOptions}
          containerStyle={{ margin: 0 }}
          name="transaction_type"
          label={redStarLabel("Transaction Type")}
          required
          isDisabled={isEdit}
          helperText={isEdit && "Transaction cannot be changed"}
        />
      )}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 24,
          paddingLeft: isRightModal && isInvoice && 28,
        }}
      >
        <div
          style={{
            display: "flex",
            gap: 16,
            alignItems: "center",
            height: 40,
          }}
        >
          <GothamRegular>Repeat every</GothamRegular>
          <Counter useFormObj={useFormObj} name="repeat_every" />
          <SelectionJack
            noLabel
            required
            options={timeRange}
            useFormObj={useFormObj}
            onChange={() => resetForm(true)}
            name="recurrence"
            containerStyle={{
              marginBottom: 0,
              height: 40,
              position: "relative",
              top: 16,
            }}
            customLabelStyle={{ display: "none" }}
            divStyle={{ margin: 0, height: 40 }}
          />
        </div>
        {isWeekRange && <DaysInput name="days" useFormObj={useFormObj} />}
        {isDayRange || isWeekRange ? (
          <SelectionJack
            label={redStarLabel("Time (WIB)")}
            required
            options={dummyOption}
            useFormObj={useFormObj}
            name="time"
            // defaultValue={dummyOption[0]}
            placeholder="Select time"
            containerStyle={{ margin: 0 }}
          />
        ) : (
          <div
            className="d-flex justify-content-between"
            style={{ width: "100%" }}
          >
            <div style={{ width: "calc(50% - 16px)" }}>
              <DateInputJackUseForm
                label={redStarLabel("Start Date")}
                style={{ margin: 0 }}
                useFormObj={useFormObj}
                containerStyle={{ margin: 0 }}
                name="date"
                minDate={new Date()}
                placeholder="Select date"
                dateFormat="d/MM/yyyy"
              />
            </div>
            <div style={{ width: "calc(50% - 16px)" }}>
              <SelectionJack
                label={redStarLabel("Time (WIB)")}
                required
                options={dummyOption}
                containerStyle={{ margin: 0 }}
                useFormObj={useFormObj}
                name="time"
                // defaultValue={dummyOption[0]}
                placeholder="Select time"
              />
            </div>
          </div>
        )}
      </div>
      {isToggle && (
        <>
          <SetDefaultCheckbox
            value={product}
            name="is_default"
            useFormObj={useFormObj}
          />
          {(isMonthRange || isYearRange) && (
            <BusinessDayOptions
              name="non_business_days_skip_to"
              useFormObj={useFormObj}
            />
          )}
          <CustomResultBanner
            showResult={isToggle}
            text={textDecider().text}
            helperText={textDecider().helperText}
          />
        </>
      )}
    </div>
  );
};

export const AddPaymentCycleModal = ({
  isOpen,
  toggle,
  product,
  refetch,
  setPayment,
}) => {
  const handleClose = () => {
    toggle();
  };
  const useFormObj = useForm();
  const { setValue, register, handleSubmit } = useFormObj;
  const { successSnackBar } = ToasterHook();
  // const onSubmit = (val) => console.log(val);
  const { mutation: createPaymentCycle, loading } = useMutation({
    url: "/predefined_schedules",
    method: "post",
    afterSuccess: ({ data: responseData }) => {
      successSnackBar({
        msg: "You’ve created a payment cycle!",
        showClose: true,
      });
      setPayment(responseData?.data);
      toggle();
    },
  });
  const onSubmit = async (val) => {
    const payload = payloadMaker(val);
    await createPaymentCycle(payload);
    return refetch();
  };
  useEffect(() => {
    if (product) {
      register("transaction_type");
      setValue(
        "transaction_type",
        productsMenuOptions.filter((v) => v.value == product).pop()
      );
    }
  }, [product]);
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className="my-0"
      centered
      style={{ width: 480 }}
    >
      <ModalHeader
        title="Create Payment Cycle"
        subtitle="Create payment schedule template that works best for your company."
        handleClose={handleClose}
      />
      {/* <AddPaymentCycleForm
        useFormObj={useFormObj}
        containerStyle={{ padding: "20px 20px 32px" }}
      /> */}
      <div style={{ padding: "20px 20px 32px" }}>
        <FormDecider useFormObj={useFormObj} isEdit />
      </div>
      <ModalButtons
        onClickCancel={handleClose}
        onClickSave={handleSubmit(onSubmit)}
      />
    </Modal>
  );
};
