import React, { useState } from "react";
import checkIconWhite from "../../../assets/images/check-icon-white.svg";
import xIconPink from "../../../assets/images/x-icon-pink.svg";
import { CustomButton, OutlineButton } from "../../../components/Buttons";
import { TopUpModal, useModalHook } from "../../../components/Modals";
import { useGetAuth } from "../../../contexts/AuthContext";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { fetch, useMutation } from "../../../tools/api";
import { PayrollPinModal } from "../payroll/pinModal";
import { PayrollRejectModal } from "../payroll/rejectModal";
import { useCrossBorderSingleWorkflow } from "./hooks/workflow";
import {
  ReleasePaymentModal,
  ReleasePaymentModalJack,
} from "./ReleasePaymentModal";
import { useRouter } from "next/router";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  useCrossBorderSingleWorkflowApproverLogics,
  useDetailsExtractor,
} from "./logics";
import SuspendModal from "../../../components/SubscriptionComponents/SuspendModal/SuspendModal";
import { PaymentMethodModal } from "./paymentMethodModal/parent";
import { booleanState } from "../../../components/Status";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { LittleDot } from "../../../pageComponents/crossBorderSingleConfirmation";
import PINModal from "../../../components/PINModal/parent";
import { invoicePageTypeDecider } from "../../../pageComponents/invoiceComponents/data/formatter";
import PreventionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import { useSubscriptionUsage } from "../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import {
  DEMO_APPROVE_TASK,
  DEMO_APPROVE_TRANSACTION,
  DEMO_BACK_APPROVE_TASK,
  DEMO_BACK_APPROVE_TRANSACTION,
  DEMO_BACK_RELEASE_TASK,
  DEMO_BACK_RELEASE_TRANSACTION,
  DEMO_CLOSE_APPROVE_TASK,
  DEMO_CLOSE_APPROVE_TRANSACTION,
  DEMO_CLOSE_RELEASE_TASK,
  DEMO_CLOSE_RELEASE_TRANSACTION,
  DEMO_REJECT_APPROVE_TASK,
  DEMO_REJECT_APPROVE_TRANSACTION,
  DEMO_REJECT_RELEASE_TASK,
  DEMO_REJECT_RELEASE_TRANSACTION,
  DEMO_RELEASE_TASK,
  DEMO_RELEASE_TRANSACTION,
} from "../../../pageComponents/productDemoComponents/constants/events";

export const ApproveRejectButtons = ({
  loading,
  children,
  onClickLeft,
  onClickRight,
  buttonRightText = "Approve",
  style,
}) => {
  const defaultStyle = {
    height: 32,
    fontSize: 14,
  };
  return (
    <div
      className="d-flex justify-content-between w-100"
      style={{ gap: 12, marginTop: 16, ...style }}
    >
      <ButtonJack
        style={{ width: "50%" }}
        isLoading={loading}
        type="outline"
        leftIcon={
          <JackIcons name="close-circle" fill={jackColors.neutral900} />
        }
        onClick={() => onClickLeft && onClickLeft()}
      >
        Reject
      </ButtonJack>
      <ButtonJack
        style={{ width: "50%" }}
        isLoading={loading}
        leftIcon={
          <JackIcons name="checkmark-circle-2" fill={jackColors.neutral900} />
        }
        onClick={() => onClickRight && onClickRight()}
      >
        {buttonRightText}
      </ButtonJack>
      {children}
    </div>
  );
};

export const crossBorderSingleWorkflowComponents = ({
  transaction,
  cancelUrlFunc = (id) =>
    `/business_transactions/${id}/cancel_single_transaction`,
  modelName = "transaction",
  bankTransfer = false,
  workflowButtonsContainerStyle = {},
  afterSuccess: afterSuccessProps,
  afterSuccessWithMoreData,
}) => {
  const {
    id,
    approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    workflow_approval_tasks, // DONT REMOVE => used in useCrossBorderSingleWorkflowApproverLogics
    rejection_note,
    state,
    transaction_type, // need attention
    batch, // payroll
    payroll_workflow_summary, // payroll
    reimbursement_workflow_summary, // reimbursement
    invoice_file: isInvoice, // invoice
    payment_method, // crossborder single
  } = transaction || {};
  const { transaction_type: transactionTypeBatch } = batch || {};
  const isReimbursement = reimbursement_workflow_summary;

  const { canApprove, taskId } =
    useCrossBorderSingleWorkflowApproverLogics(transaction);

  const [rejection, setRejection] = useState(false);
  const [payment, setPayment] = useState({ type: "default" });

  const {
    isDrafter,
    isWorkflow,
    isOnProgress,
    isCompleted,
    isRefunded,
    approverLineArray,
  } = useCrossBorderSingleWorkflow(transaction);

  const { isFailed, isDeclined, isValidatingPayment } = booleanState(
    transaction?.state
  );
  const { isJackTransfer, isWithdraw } = booleanState(transaction?.type);

  const { push, pathname } = useRouter();
  const isTask = pathname === "/task";

  const isCanceled = state === "canceled" || state === "cancelled";

  const lastApproverLineArray = approverLineArray[approverLineArray.length - 1];
  const releasers = lastApproverLineArray?.data;
  const isLastApprover = lastApproverLineArray?.isActive;

  const toggleRejection = () => setRejection(false);

  const { successSnackBar } = ToasterHook();

  const isSingleCrossBorder = transaction_type == "Single";
  const isLocalTransfer = transaction_type == "transfer";
  const isPayroll = transactionTypeBatch === "payroll";
  const showingSnackBar = isSingleCrossBorder || isPayroll || isLocalTransfer;

  const transactionTypeDecider = () => {
    const isPayroll = payroll_workflow_summary;
    const isReimbursement = reimbursement_workflow_summary;

    if (isReimbursement) return "reimbursement";
    if (isSingleCrossBorder) return "single_transaction";
    if (isLocalTransfer) return "local_transfer";
    if (isPayroll) return "payroll";
    return "invoice";
  };

  const transactionType = transactionTypeDecider();

  const afterSuccess = async ({ res, payload, msg, type }) => {
    if (afterSuccessWithMoreData) {
      afterSuccessWithMoreData({ res, payload, type });
      return;
    }

    if (isInvoice) {
      const pageType = invoicePageTypeDecider(rejection, isLastApprover);
      await push(`success?type=invoice&id=${id}&pageType=${pageType}`);
    } else {
      // await push(
      //   `/history/success-workflow/?transaction_type=${transactionType}&ids=${id}`
      // );
      await push(`/success/?type=${transactionType}&id=${id}`);
    }
    toggleRejection();
    if (!showingSnackBar) {
      successSnackBar({ msg });
    }
  };

  const { loading: loadingCancel, mutation: cancel } = useMutation({
    url: cancelUrlFunc(id),
    afterSuccess: (res, payload) =>
      afterSuccess({
        res,
        payload,
        msg: "Transaction successfully cancelled",
        type: "cancel",
      }),
  });

  const { isSuspended, loadingSubscriptionUsage } = useSubscriptionUsage();

  const { isOpen, toggle } = useModalHook();
  const {
    isOpen: isOpenReleasePaymentModal,
    toggle: toggleReleasePaymentModal,
  } = useModalHook();
  const { isOpen: isOpenPinModal, toggle: togglePinModal } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const { isOpen: isOpenSuspendModal, toggle: toggleSuspendModal } =
    useModalHook();
  const { isOpen: isOpenPaymentMethod, toggle: togglePaymentMethod } =
    useModalHook();

  const isBankTransfer = payment_method == "bank_transfer" || bankTransfer;
  const isUndecidedPayment = payment_method == "undecided";

  const { loading: loadingApprove, mutation: approve } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: (res, payload) => {
      afterSuccess({
        res,
        payload,
        type: isLastApprover ? "release" : "approve",
        msg: "Transaction successfully approved",
      });
      toggle();
      eventsTracker(`${modelName}_approve`, res);
    },
  });

  const { loading: loadingReject, mutation: reject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess: (res, payload) => {
      afterSuccess({
        res,
        payload,
        type: "reject",
        msg: "Transaction successfully rejected",
      });
      eventsTracker(`${modelName}_reject`, res);
    },
  });

  const onSubmitWorkflow = (values, type) => {
    const { reason: rejection_note, pin } = values || {};
    const { payload } = payment;
    const isCancel = type === "cancel";
    const isReject = type === "reject";
    const isApprove = type == "approve";

    if (isApprove) return approve({ pin, ...payload });

    if (isCancel) {
      const state =
        isInvoice || isReimbursement || isJackTransfer || isWithdraw
          ? "cancel"
          : "cancelled";
      return cancel({
        rejection_note,
        state,
        pin,
      });
    }

    if (isReject) return reject({ rejection_note, pin });
  };

  const loadingButtons = loadingApprove || loadingReject || loadingCancel;

  const detailData = useDetailsExtractor(
    transaction,
    isLastApprover,
    canApprove
  );

  const rejectModal = () => (
    <PayrollRejectModal
      text={rejection}
      loading={loadingButtons}
      isOpen={Boolean(rejection)}
      toggle={toggleRejection}
      onSubmit={onSubmitWorkflow}
      detailData={detailData}
    />
  );

  const buttons = () => {
    if (isCompleted) return null;
    if (isOnProgress && isInvoice) return null;
    if (isOnProgress && isReimbursement) return null;
    if (!isWorkflow) return null;
    if (rejection_note) return null;
    if (isCanceled) return null;
    if (isRefunded) return null;
    if ((isFailed || isDeclined) && isInvoice) return null;

    const defaultStyle = {
      height: 40,
      fontSize: 14,
    };

    // approveProps: () => {
    //   const eventName = eventNameDecider("approve");
    //   eventsTracker(eventName, {
    //     page_title: "transaction_detail",
    //   });
    // },
    // rejectProps: () => {
    //   const eventName = eventNameDecider("reject");
    //   eventsTracker(eventName, {
    //     page_title: "transaction_detail",
    //   });
    // },

    const eventNameDecider = (action = "") => {
      switch (action) {
        case "approve":
          if (isLastApprover) {
            return isTask ? DEMO_RELEASE_TASK : DEMO_RELEASE_TRANSACTION;
          }
          return isTask ? DEMO_APPROVE_TASK : DEMO_APPROVE_TRANSACTION;
        case "reject":
          if (isLastApprover) {
            return isTask
              ? DEMO_REJECT_RELEASE_TASK
              : DEMO_REJECT_RELEASE_TRANSACTION;
          }
          return isTask
            ? DEMO_REJECT_APPROVE_TASK
            : DEMO_REJECT_APPROVE_TRANSACTION;
        case "close":
          if (isLastApprover) {
            return isTask
              ? DEMO_CLOSE_RELEASE_TASK
              : DEMO_CLOSE_RELEASE_TRANSACTION;
          }
          return isTask
            ? DEMO_CLOSE_APPROVE_TASK
            : DEMO_CLOSE_APPROVE_TRANSACTION;
        case "back":
          if (isLastApprover) {
            return isTask
              ? DEMO_BACK_RELEASE_TASK
              : DEMO_BACK_RELEASE_TRANSACTION;
          }
          return isTask
            ? DEMO_BACK_APPROVE_TASK
            : DEMO_BACK_APPROVE_TRANSACTION;
        default:
          break;
      }
    };

    const handleClick = (purpose = "approve") => {
      const isApproving = purpose === "approve";
      const isRejecting = purpose === "reject";
      const isCanceling = purpose === "cancel";

      if (isApproving) {
        // demo purpose
        const eventName = eventNameDecider("approve");
        eventsTracker(eventName, {
          page_title: "transaction_detail",
        });
        if (isSuspended) return toggleSuspendModal();

        toggle();
        if (isSingleCrossBorder && isUndecidedPayment)
          return togglePaymentMethod();
        if (isBankTransfer) return togglePinModal();

        toggleReleasePaymentModal();
      }

      if (isRejecting) {
        // demo purpose
        const eventName = eventNameDecider("reject");
        eventsTracker(eventName, {
          page_title: "transaction_detail",
        });
        if (isSuspended) return toggleSuspendModal();

        setRejection("rejection");
      }

      if (isCanceling) {
        if (isSuspended) return toggleSuspendModal();

        setRejection("cancelation");
      }
    };

    if (canApprove) {
      const buttonRightText =
        isSingleCrossBorder || isInvoice || isLocalTransfer || isPayroll
          ? "Approve & Pay"
          : "Pay";
      if (isLastApprover) {
        return (
          <ApproveRejectButtons
            buttonRightText={buttonRightText}
            loading={loadingButtons || loadingSubscriptionUsage}
            onClickLeft={() => handleClick("reject")}
            onClickRight={() => handleClick("approve")} // disini bikin kondisi handleclick based on crossborder single trx
            style={workflowButtonsContainerStyle}
          >
            <ReleasePaymentModalJack
              loading={loadingButtons}
              isOpen={isOpenReleasePaymentModal}
              toggle={() => {
                toggleReleasePaymentModal();
                toggle();
              }}
              onClick={() => {
                toggleReleasePaymentModal();
                togglePinModal();
              }}
              onClickTopUp={toggleTopUpModal}
              data={transaction}
              detailData={detailData}
              schedulePaymentState={[payment, setPayment]}
            />
            <PINModal
              isLoading={loadingButtons}
              isOpen={isOpenPinModal}
              toggle={() => {
                if (isBankTransfer) {
                  toggle();
                  togglePinModal();
                } else {
                  const eventName = eventNameDecider("close");
                  eventsTracker(eventName, {
                    page_title: "transaction_enter_pin",
                  });
                  togglePinModal();
                  toggleReleasePaymentModal();
                }
              }}
              onBack={() => {
                if (isBankTransfer) {
                  toggle();
                  togglePinModal();
                } else {
                  const eventName = eventNameDecider("back");
                  eventsTracker(eventName, {
                    page_title: "transaction_enter_pin",
                  });
                  togglePinModal();
                  toggleReleasePaymentModal();
                }
              }}
              onSubmit={({ pin }) => {
                const eventName = eventNameDecider("approve");
                eventsTracker(eventName, {
                  page_title: "transaction_enter_pin",
                });
                onSubmitWorkflow({ pin }, "approve");
              }}
            />
            <TopUpModal
              modal={isOpenTopUpModal}
              toggle={toggleTopUpModal}
              dummyPaidButton
              paid
            />
            <PreventionModal
              type="suspended-approve"
              isOpen={isOpenSuspendModal}
              toggle={toggleSuspendModal}
              onClick={toggleSuspendModal}
            />
            <PaymentMethodModal
              isOpen={isOpenPaymentMethod}
              data={transaction}
              toggle={togglePaymentMethod}
            />
          </ApproveRejectButtons>
        );
      }

      return (
        <ApproveRejectButtons
          onClickLeft={() => handleClick("reject")}
          onClickRight={() => handleClick("approve")}
          loading={loadingButtons || loadingSubscriptionUsage}
          buttonRightText="Approve"
        >
          <PINModal
            isLoading={loadingButtons}
            isOpen={isOpen}
            toggle={() => {
              if (isOpen) {
                const eventName = eventNameDecider("close");
                eventsTracker(eventName, {
                  page_title: "transaction_enter_pin",
                });
              }
              toggle();
            }}
            onBack={() => {
              const eventName = eventNameDecider("back");
              eventsTracker(eventName, { page_title: "transaction_enter_pin" });
              toggle();
            }}
            onSubmit={({ pin }) => {
              const eventName = eventNameDecider("approve");
              eventsTracker(eventName, { page_title: "transaction_enter_pin" });
              onSubmitWorkflow({ pin }, "approve")
            }}
          />
          <PreventionModal
            type="suspended-approve"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
            onClick={toggleSuspendModal}
          />
        </ApproveRejectButtons>
      );
    }

    if (isDrafter && !isValidatingPayment) {
      // if (isPayroll) return null;
      return (
        <div
          className="d-flex justify-content-between w-100"
          style={{ gap: 12, marginTop: 16 }}
        >
          <ButtonJack
            style={{
              width: "100%",
              backgroundColor: "transparent",
              ...defaultStyle,
            }}
            type="outline"
            onClick={() => handleClick("cancel")}
            isLoading={loadingSubscriptionUsage}
            leftIcon={
              <JackIcons
                name="close_circle"
                fill="#343434"
                style={{ height: 17, width: 17 }}
              />
            }
          >
            Cancel Request
          </ButtonJack>
          <PreventionModal
            type="suspended-approve"
            isOpen={isOpenSuspendModal}
            toggle={toggleSuspendModal}
            onClick={toggleSuspendModal}
          />
        </div>
      );
    }

    return null;
  };

  return {
    rejectModal,
    buttons,
    isRejection: Boolean(rejection),
    isWorkflow,
    openPinModal: isOpen,
    isApprover: canApprove,
    isReleaser: isLastApprover,
    releasers,
  };
};

export const HeaderModal = ({ data }) => {
  const {
    title,
    icon,
    value = "IDR 10,000",
    details,
    status = "completed",
  } = data || {};

  return (
    <div style={{ marginBottom: 32 }}>
      <div style={{ marginBottom: 32 }}>{icon}</div>
      <GothamRegular
        className="mb-2"
        style={{
          width: "100%",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {title}
      </GothamRegular>
      <GothamMedium
        className="mb-2"
        style={{ fontSize: 24, lineHeight: "32px" }}
      >
        {value}
      </GothamMedium>
      <div className="d-flex justify-content-between align-items-center">
        {details ? (
          <GothamRegular
            className="font12"
            style={{
              color: jackColors.neutral700,
            }}
          >
            {details}
          </GothamRegular>
        ) : (
          <div></div>
        )}
        <StatusLabelJack status={status} isOutline />
      </div>
    </div>
  );
};

export const DetailsModal = ({ array, containerStyle }) => {
  return (
    <div
      className="borderE6"
      style={{
        padding: 12,
        ...containerStyle,
      }}
    >
      {array?.map((item, index) => {
        const {
          title,
          value,
          spacerNotFull,
          spacer,
          littleDot,
          customIcon,
          rightBold,
        } = item || {};
        const isLast = array.length - 1 === index;
        const isTransferNotes = title === "Transfer note";

        const alignCenter = isTransferNotes
          ? "align-items-start"
          : "align-items-center";

        if (spacer || spacerNotFull)
          return (
            <hr
              key={index}
              style={{
                borderTop: `1px solid ${jackColors.neutral500}`,
                marginLeft: spacerNotFull && 24,
              }}
            />
          );
        return (
          <div
            key={index}
            style={{
              marginBottom: !isLast && 12,
              display: "grid",
              gridTemplateColumns: "50% 50%",
            }}
          >
            {littleDot ? (
              <div className="d-flex align-items-center" style={{ gap: 8 }}>
                <LittleDot style={{ backgroundColor: jackColors.neutral500 }} />
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700 }}
                >
                  {title}
                </GothamRegular>
              </div>
            ) : (
              <div className={`d-flex ${alignCenter}`} style={{ gap: 8 }}>
                {customIcon && (
                  <JackIcons
                    name={customIcon}
                    fill={jackColors.neutral500}
                    style={{ width: 16, height: 16 }}
                  />
                )}
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700 }}
                >
                  {title}
                </GothamRegular>
              </div>
            )}
            <GothamRegular
              className="font12"
              style={{
                textAlign: "right",
                wordWrap: "break-word",
                color: jackColors.black34,
                fontFamily: rightBold && "GothamMedium",
              }}
            >
              {value}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};
