import { CgSpinnerAlt } from "react-icons/cg";
import { Modal } from "reactstrap";

const LoadingSpinnerDemo = ({ size = 32, show = false }) => (
  <Modal isOpen={show}>
    <div
      className={`d-flex justify-content-center align-items-center`}
      style={{
        height: 200,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        zIndex: 99999999,
      }}
    >
      <CgSpinnerAlt
        className="icon-spin "
        size={size}
        style={{ animationDuration: "0.8s" }}
      />
      {/* <CgSpinner className="icon-spin " size={size} /> */}
      {/* <ImSpinner8 className="icon-spin " size={size} /> */}
    </div>
  </Modal>
);
export default LoadingSpinnerDemo;
