import { useState } from "react";
import { deepRemoveDuplicates } from "../../../components/tools";
import { apiBusiness } from "../../../tools/api";

export const fetchGetAll = ({
  url,
  additionalQuery = "",
  key = "data",
  formatter = (item) => item,
}) => {
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(false);

  const refetch = async () => {
    try {
      setLoading(true);
      let result = {};
      const { data } = await apiBusiness.get(
        `${url}?page=1&per_page=50${additionalQuery}`
      );
      const { total_page } = data || {};

      result = data;

      for (let i = 1; i < total_page + 1; i++) {
        const { data } = await apiBusiness.get(
          `${url}?page=${i}&per_page=50${additionalQuery}`
        );
        result = {
          ...data,
          data: deepRemoveDuplicates(
            [...(result[key] || []), ...data[key]],
            "id"
          ),
        };
      }

      setData((prev) => {
        const finalRaw = formatter(result, prev);
        const final = {
          ...finalRaw,
          data: deepRemoveDuplicates(finalRaw?.data || [], "id"),
        };
        return final;
      });
    } catch (error) {
      console.log("error:", error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, data, refetch, setData };
};
