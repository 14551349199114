import React from "react";
import { FaSpinner } from "react-icons/fa";
import { jackIconsTo20 } from "../inputs/textfield";
import { GothamMedium } from "../Text";

export const buttonsProps = {
  children: <></>,
  className: "",
  type: "filled",
  isBullet: false,
  leftIcon: null,
  rightIcon: null,
  disabled: false,
  isSmall: false,
  onClick: () => {},
  isLoading: false,
  buttonType: "submit",
  style: {},
  classNameText: "",
  onKeyDown: () => {},
};

export const ButtonJack = (props = buttonsProps) => {
  const {
    children,
    className: classNameProps = "",
    type = "filled",
    isBullet = false,
    leftIcon: leftIconProps = null,
    rightIcon: rightIconProps = null,
    disabled = false,
    isSmall = false,
    onClick = () => {},
    isLoading = false,
    buttonType,
    style,
    classNameText,
    isLoadingLeft = false,
    styleText,
    onKeyDown,
  } = props;
  const [leftIcon, rightIcon] = jackIconsTo20([leftIconProps, rightIconProps]);

  //type = filled || outline || borderless

  const classNameDecider = () => {
    let className = `button`;
    if (isBullet) {
      className += " button-bullet";
      if (isSmall) className += " bullet-small";
    } else {
      className += ` button-${type}`;
      if (isSmall) className += " button-small";
    }
    return className + " " + classNameProps;
  };

  const className = classNameDecider();

  const isDisabled = isLoading || disabled;
  return (
    <button
      className={className}
      onClick={onClick}
      disabled={isDisabled}
      style={{ cursor: isDisabled ? "no-drop" : "pointer", ...style }}
      type={buttonType}
      onKeyDown={onKeyDown}
    >
      {isLoadingLeft && isLoading ? (
        <FaSpinner className="icon-spin" />
      ) : (
        leftIcon && leftIcon
      )}
      <GothamMedium className={classNameText} style={styleText}>
        {children}
      </GothamMedium>
      {isLoading && !isLoadingLeft ? (
        <FaSpinner className="icon-spin" />
      ) : (
        rightIcon && rightIcon
      )}
    </button>
  );
};
