export const vaDummyBankName = "Bank Mandiri";

export const dummyDataVa = [
  {
    reference_id: "12345678910.0",
    bank: "Bank BNI",
    // code: "008",
    username_display: "DP Gathering Vista Event",
    amount: 23000000,
    usage_limit: 1,
    expiration_date: "2024-10-05T12:04:00.000+07:00",
    trx_expiration_date: "2024-10-05T12:04:00.000+07:00",
    is_lifetime: false,
    is_open: false,
    temp_id: "12345678910.0",
  },
//   {
//     reference_id: "11000110.0",
//     bank: "Bank Mandiri",
//     code: "008",
//     username_display: "VA Mandiri 1",
//     amount: 100000,
//     usage_limit: -1,
//     expiration_date: "2024-04-11T12:04:00.000+07:00",
//     trx_expiration_date: "2024-04-11T12:04:00.000+07:00",
//     is_lifetime: false,
//     is_open: false,
//     temp_id: "11000110.057",
//   },
];

export const emptyDummyDataVa = [
  {
    reference_id: "",
    bank: "",
    code: "",
    username_display: "",
    amount: "",
    usage_limit: "",
    expiration_date: "",
    trx_expiration_date: "",
    is_lifetime: "",
    is_open: "",
    temp_id: "",
  },
];

export const emptyDataWithBankVa = [
  {
    reference_id: "",
    bank: "Bank Mandiri",
    code: "008",
    username_display: "",
    amount: "",
    usage_limit: "",
    expiration_date: "",
    trx_expiration_date: "",
    is_lifetime: "",
    is_open: "",
    temp_id: "",
  },
];
