export const memberFinder = ({
  isExact = true,
  searchValue = "",
  member = {},
}) => {
  const { name: nameRaw, email: emailRaw } = member;

  const name = (nameRaw ?? "").toLocaleLowerCase();
  const email = (emailRaw ?? "").toLocaleLowerCase();

  if (isExact) return name === searchValue || email === searchValue;
  return name.includes(searchValue) || email.includes(searchValue);
};
