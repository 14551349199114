import styles from "./StatusDot.module.css";
import {
  ACTIVE,
  COMPLETED,
  FAILED,
  FOLDED,
  INACTIVE,
  PARTIAL_FAILED,
  RETRY,
  SUCCESS,
} from "..";
import { JackIcons } from "../../../assets/jackIcons/parent";

const StatusDot = ({ status = INACTIVE }) => {
  switch (status) {
    case COMPLETED:
      return <JackIcons name="active-dot-tracker" fill="#06AC4E" />;
    case SUCCESS:
      return <JackIcons name="active-dot-tracker" fill="#343434" />;
    case FAILED:
      return <JackIcons name="rejected-dot-tracker" fill="#E73126" />;
    case ACTIVE:
      return <JackIcons name="current-dot-tracker" fill="#B9FC00" />;
    case PARTIAL_FAILED:
      return <JackIcons name="active-dot-tracker" fill="#9AD7D0" />;
    case RETRY:
      return <JackIcons name="retry-dot-tracker" fill="#343434" />;
    case FOLDED:
      return <JackIcons name="three-dots-tracker" fill="#343434" />;
    case INACTIVE:
    default:
      return (
        <div className={styles.inactiveDot}>
          <div className={styles.dot} />
        </div>
      );
  }
};

export default StatusDot;
