import { useEffect } from "react";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../../components/Modals";
import { GothamRegular } from "../../../../components/Text";
import { useUserFlags } from "../../../../contexts/AuthContext";
import { TemplateModal } from "../../../notifications/guided-tour/components";
import { getDemoData } from "../../hooks";
import {
  BILL_MODULE,
  INTERNATIONAL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  VA_MODULE,
} from "../../constants";
import NextImage from "../../../../components/NextImage";
import { onboardModalPopupContentDecider } from "./helpers";
import { useDemo } from "../../../../contexts/DemoContext";
import { eventsTracker } from "../../../../universalFunctions/events";
import { DEMO_EXPLORE_BY_MYSELF, DEMO_GUIDE_ME_GUIDANCE } from "../../constants/events";

const CreateTransactionsOnboardModal = ({
  module = LOCAL_MODULE,
  onClick = () => {},
  onExplore = () => {},
  loading = false,
}) => {
  const { isOpen, open, toggle, close } = useModalHook();
  const { guidanceState } = useDemo();
  const { guided = false } = guidanceState ?? {};

  const { hasUser } = useUserFlags();

  const eventPayload = (function () {
    let activity_type;
    switch (module) {
      case BILL_MODULE:
        activity_type = "create_bill_payment";
        break;
      case PAYROLL_MODULE:
        activity_type = "create_payroll";
        break;
      case VA_MODULE:
        activity_type = "create_virtual_account";
        break;
      case INTERNATIONAL_MODULE:
        activity_type = "create_international_transfer";
        break;
      case LOCAL_MODULE:
        activity_type = "create_local_transfer";
        break;
      case REIMBURSEMENT_MODULE:
        activity_type = "create_reimbursement";
        break;
    }
    return { activity_type };
  })();

  const handleGuideMe = () => {
    eventsTracker(DEMO_GUIDE_ME_GUIDANCE, eventPayload);
    close();
    onClick();
  };

  const handleExplore = () => {
    eventsTracker(DEMO_EXPLORE_BY_MYSELF, eventPayload);
    close();
    onExplore();
  };

  useEffect(() => {
    if (!hasUser) return;
    if (loading) return;
    if (guided) return;
    open();
    // tambah entry point yg disimpen di useDemo
  }, [hasUser, loading, guided]);

  return (
    <TemplateModal
      isOpen={isOpen}
      toggle={toggle}
      width={476}
      img={
        <NextImage
          width={440}
          height={200}
          src={onboardModalPopupContentDecider("image-src", module)}
          alt="Inactive Category Image"
        />
      }
      title={onboardModalPopupContentDecider("title", module)}
      titleContainerStyle={{
        paddingBottom: 0,
        borderWidth: 0,
        justifyContent: "center",
      }}
      onClosed={handleExplore}
      bodyComponent={
        <div>
          <GothamRegular
            className="text-start"
            style={{
              color: jackColors.black34,
              marginTop: "32px",
            }}
          >
            {onboardModalPopupContentDecider("text", module)}
            <br />
            <br />
            You can pause and play the guidance anytime by clicking the button
            in upper-right corner.
          </GothamRegular>
        </div>
      }
      bottomComponent={
        <div style={{ display: "flex", gap: 16 }}>
          <ButtonJack
            type="outline"
            rightIcon={
              <JackIcons
                name={"Globe2"}
                style={{ height: 20 }}
                fill={jackColors.black34}
              />
            }
            onClick={handleExplore}
            style={{ width: "100%" }}
          >
            I’ll explore by myself
          </ButtonJack>
          <ButtonJack
            rightIcon={
              <JackIcons
                name={"PlayCircle"}
                style={{ height: 20 }}
                fill={jackColors.black34}
              />
            }
            onClick={handleGuideMe}
            style={{ width: "100%" }}
          >
            Guide me
          </ButtonJack>
        </div>
      }
    />
  );
};

export default CreateTransactionsOnboardModal;
