import { colors } from "../assets/colors";
import { GothamBold, GothamRegular } from "./Text";

export const BulletListItem = ({ item, number, type, size }) => {
  const isFilled = type === "filled";

  const isItemString = typeof item === "string";

  const sizeDecider = () => {
    switch (size) {
      case "small":
        return { size: "16px", fontSize: "10px" };
      case "medium":
        return { size: "24px", fontSize: "14px" };
      case "large":
        return { size: "32px", fontSize: "16px" };
      default:
        return { size: "24px", fontSize: "14px" };
    }
  };
  const bullet = sizeDecider();

  const containerStyle = {
    display: "flex",
    alignItems: "center",
    marginBottom: "16px",
    gap: "16px",
  };

  const bulletStyle = {
    width: bullet.size,
    height: bullet.size,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    border: isFilled ? "none" : `1px solid ${colors.neutral500}`,
    backgroundColor: isFilled ? colors.neutral500 : "transparent",
  };

  const numberStyle = {
    color: isFilled ? colors.neutral900 : colors.neutral700,
    fontSize: bullet.fontSize,
    margin: "auto",
  };

  return (
    <div style={containerStyle}>
      <div style={bulletStyle}>
        <GothamBold style={numberStyle}>{number}</GothamBold>
      </div>
      {isItemString ? <GothamRegular>{item}</GothamRegular> : item}
    </div>
  );
};

export const BulletList = ({
  list = [],
  type = "outlined",
  size = "medium",
  style,
}) => {
  // type = filled | outline
  // size = small | medium | large
  return (
    <div style={style}>
      {list.map((item, index) => (
        <BulletListItem
          key={index}
          number={index + 1}
          type={type}
          size={size}
          item={item}
        />
      ))}
    </div>
  );
};
