import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { crossBorderSingleWorkflowComponents } from "../../crossBorderSingleModal/components";

const useTrxReleaser = ({ trx = {} }) => {
  const { allUsers } = useConstants();
  const { releasers } = crossBorderSingleWorkflowComponents({
    transaction: trx,
  });
  const releaserData = releasers?.find(
    ({ approved_by_user_id }) => approved_by_user_id
  );
  const releaser =
    allUsers?.find(({ id }) => id === releaserData?.approved_by_user_id) ?? {};
  const releasedAt = releaserData?.approved_at;

  return { releasedAt, releaser };
};

export default useTrxReleaser;
