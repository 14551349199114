import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { BILL_FIRST_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${BILL_FIRST_TARGET}`,
    content: (
      <TooltipContent
        title="Upload your invoice here"
        description="You can upload up to 10 invoices. Each invoice will be processed separately."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    disableScrolling: true,
    eventPayload: {
      page_title: "upload_invoice",
      activity_type: "create_bill_payment",
    },
  },
];

const billFirstPageProps = { steps };

export default billFirstPageProps;
