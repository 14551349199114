import { useContext, useEffect, useMemo, useState } from "react";
import { apiBusiness, fetch, useMutation } from "../../../tools/api";
import { TaskPageContext } from "../TaskPageProvider";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { TaskContext } from "../../../contexts/TaskContext/parent";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { deepRemoveDuplicates } from "../../../components/tools";
import { useGetAuth } from "../../../contexts/AuthContext";
import { useRouter } from "next/router";
import {
  amountEqualPayloadFormatter,
  amountRangePayloadFormatter,
  arrayPayloadFormatter,
  arrayizedFilterFormatter,
  taskResultFormatter,
} from "./formatters";
import { isEmpty } from "lodash";

export const useGeneralFilter = () => {
  const { query } = useRouter();

  const teamQuery = query.team ?? [];
  const createdByQuery = query.created_by ?? [];
  const amountMinQuery = query.minimum_amount ?? "";
  const amountMaxQuery = query.maximum_amount ?? "";
  const amountEqualQuery = query.specific_amount ?? "";

  return {
    query,
    teamQuery,
    createdByQuery,
    amountMinQuery,
    amountMaxQuery,
    amountEqualQuery,
  };
};

export const useTaskFilter = () => {
  const {
    teamQuery,
    createdByQuery,
    amountMinQuery,
    amountMaxQuery,
    amountEqualQuery,
  } = useGeneralFilter();

  let taskFilter = "";

  const teamFilter = arrayizedFilterFormatter(teamQuery);
  const createdByFilter = arrayizedFilterFormatter(createdByQuery);

  const joinedTeamFilter = teamFilter.join(",");
  const joinedCreatedByFilter = createdByFilter.join(",");

  const hasTeamFilter = teamFilter.length > 0;
  const hasCreatedByFilter = createdByFilter.length > 0;
  const hasAmountEqualFilter = amountEqualQuery.length > 0;
  const hasAmounMinFilter = amountMinQuery.length > 0;
  const hasAmountMaxFilter = amountMaxQuery.length > 0;

  if (hasTeamFilter) taskFilter += `&team=${joinedTeamFilter}`;
  if (hasCreatedByFilter) taskFilter += `&created_by=${joinedCreatedByFilter}`;
  if (hasAmountEqualFilter) taskFilter += `&amount_eq=${amountEqualQuery}`;
  if (hasAmounMinFilter) taskFilter += `&amount_min=${amountMinQuery}`;
  if (hasAmountMaxFilter) taskFilter += `&amount_max=${amountMaxQuery}`;

  const hasFilter = taskFilter.length > 0;

  if (hasFilter) return taskFilter;
  return "";
};

export const useMissingReceiptFilter = () => {
  const { query, amountMinQuery, amountMaxQuery, amountEqualQuery } =
    useGeneralFilter();

  const arrayPayloadArguments = { query, name: "team_id", queryName: "team" };
  const rangePayloadArguments = { amountMinQuery, amountMaxQuery };

  const amount_numeric = amountEqualPayloadFormatter(amountEqualQuery);
  const team = arrayPayloadFormatter(arrayPayloadArguments);
  const range = amountRangePayloadFormatter(rangePayloadArguments);

  const missingReceiptFilter = { ...team, ...range, ...amount_numeric };

  return missingReceiptFilter;
};

export const useTask = ({ isApproval = false, setOpenedTable = () => {} }) => {
  const [page, setPage] = useState(1);
  const [enableResetRefetch, setEnabledResetRefetch] = useState(false);

  const { triggerResetTask, setTriggerResetTask } = useContext(TaskPageContext);

  const {
    query: { runQuery },
  } = useRouter();

  const taskFilter = useTaskFilter();

  const defaultQuery = `?page=${page}&per_page=5&q[originator_type_not_eq]=WalletTransaction`;
  const defaultUrl = `/approval_tasks/index_requested${defaultQuery}`;

  const approvalQuery = "&is_approval=true";
  const paymentQuery = "&is_approval=false";

  const filterQuery = isApproval ? approvalQuery : paymentQuery;

  const url = `${defaultUrl}${filterQuery}${taskFilter}`;

  const { data, loading, refetch, setData } = fetch({
    url,
    woInit: true,
    defaultValue: {},
    formatter: taskResultFormatter,
    afterSuccess: () => {
      setTriggerResetTask(0);
      setEnabledResetRefetch(false);
    },
  });

  const seeMore = () => {
    setOpenedTable("task");
    setPage((prev) => prev + 1);
  };

  const seeLess = () => {
    setData({});
    setPage(1);
    setOpenedTable("");
  };

  useEffect(refetch, [page]);

  useEffect(() => {
    const isSeeLess = enableResetRefetch && page === 1;

    if (isSeeLess) refetch();
  }, [enableResetRefetch, page]);

  useEffect(() => {
    const shouldReset = triggerResetTask > 0 || runQuery;

    if (shouldReset) {
      const resetTaskListing = setTimeout(() => {
        seeLess();
        setEnabledResetRefetch(true);
      }, 100);

      return () => clearTimeout(resetTaskListing);
    }
  }, [triggerResetTask, runQuery]);

  return { task: data, loading, seeMore, seeLess, refetch };
};

export const useMissingReceipt = ({
  isContext = false,
  setOpenedTable = () => {},
}) => {
  const [page, setPage] = useState(1);
  const [totalMissingReceipts, setTotalMissingReceipts] = useState(0);
  const [missingReceipts, setMissingReceipts] = useState([]);
  const [isFirstRender, setIsFirstRender] = useState(true);
  const [hasMoreReceipts, setHasMoreReceipts] = useState(true);

  const { isUploadedDocuments, selectedTask, setIsUploadedDocuments } =
    useContext(TaskPageContext);

  const {
    query: {
      runQuery,
      team,
      created_by,
      specific_amount,
      minimum_amount,
      maximum_amount,
    },
  } = useRouter();

  const filter = useMissingReceiptFilter();

  const missingReceiptFilter = useMemo(() => {
    return filter;
  }, [team, created_by, specific_amount, minimum_amount, maximum_amount]);

  const { user } = useGetAuth();

  const userId = user?.id;

  const afterSuccessFetchMissingReceiptTrx = (res) => {
    const missingReceipts = res?.data?.data ?? [];
    const totalMissingReceipts = res?.data?.count ?? 0;

    if (missingReceipts.length < 5) setHasMoreReceipts(false);
    else setHasMoreReceipts(true);

    setMissingReceipts((prev) =>
      deepRemoveDuplicates([...prev, ...missingReceipts], "id")
    );
    setTotalMissingReceipts(totalMissingReceipts);
  };

  const { mutation, loading } = useMutation({
    url: "/activity_search",
    method: "post",
    afterSuccess: afterSuccessFetchMissingReceiptTrx,
  });

  const searchDependencies = useMemo(() => {
    const baseDeps = [page, userId, isFirstRender, isContext];
    if (isContext) return baseDeps;
    return [...baseDeps, runQuery];
  }, [isContext, page, userId, isFirstRender, runQuery]);

  const searchMissingDetails = async (args = {}) => {
    const payload = {
      filters: {
        page: args?.page ?? page,
        per_page: 5,
        category: ["card_payment", "card_transaction"],
        // source_account: ["card"],
        user_id: [userId],
        // has_attachment: false,
        // "details.notes_exists": false,
        missing_details: true,
        // status: ["completed", "complete", "success"],
        status: ["success"],
      },
    };

    payload.filters = { ...payload.filters, ...missingReceiptFilter };

    return mutation(payload);
  };

  const seeMore = () => {
    if (!hasMoreReceipts) return;
    setPage((prev) => prev + 1);
    setOpenedTable("missing_receipt");
  };

  const seeLess = () => {
    setPage(1);
    setMissingReceipts([]);
    setOpenedTable("");
    searchMissingDetails({ page: 1 });
  };

  useEffect(() => {
    const isStillLoadingUser = !userId;

    if (isStillLoadingUser) return;
    if (isFirstRender) return setIsFirstRender(false);

    const searchTimeout = setTimeout(() => {
      if (isContext) return searchMissingDetails();
      if (runQuery) return seeLess();
      return searchMissingDetails();
    }, 200);

    return () => clearTimeout(searchTimeout);
  }, searchDependencies);

  useEffect(() => {
    if (isUploadedDocuments && isEmpty(selectedTask)) {
      seeLess();
      setIsUploadedDocuments(false);
    }
  }, [isUploadedDocuments, isEmpty(selectedTask)]);

  return {
    missingReceipts,
    hasMoreReceipts,
    totalMissingReceipts,
    loadingMissingReceipts: loading,
    seeMore,
    seeLess,
  };
};

export const useMultiApprove = () => {
  const { isPayment, resetTasks } = useContext(TaskPageContext);
  const { data, refetch } = useContext(TaskContext);

  const { successSnackBar } = ToasterHook();

  const { mutation: multiApprove, loading: loadingMultiApprove } = useMutation({
    url: "/approval_tasks/bulk_approve",
    afterSuccess: async (_, payload) => {
      const { ids } = payload || {};

      const formatterCrossBorderIds = (array, ids) => {
        let getIds = [];
        array.map((item) => {
          const compareId = ids.includes(item?.id);
          const isCrossBorder = item?.type === "Crossborder Transaction";
          if (isCrossBorder && compareId) return getIds.push(item?.trx_id);
        });
        return getIds;
      };

      // CROSSBORDER SINGLE ONLY
      const cbIds = formatterCrossBorderIds(data, ids);
      if (cbIds.length && isPayment) {
        const payload = { payment_method: "indonesian_wallet" };
        for (let i = 0; i < cbIds.length; i++) {
          const id = cbIds[i];
          await apiBusiness.put(`/update_single_transaction/${id}`, payload);
        }
      }
      // CROSSBORDER SINGLE ONLY

      resetTasks();

      refetch();

      successSnackBar({
        msg: `${ids.length} Transaction has been approved${
          isPayment ? " & paid" : ""
        }`,
      });
    },
  });

  const handleMultiApprove = async ({
    payload = {},
    afterSuccess = () => {},
  }) => {
    await multiApprove(payload);
    afterSuccess();
  };

  return { multiApprove: handleMultiApprove, loadingMultiApprove };
};

export const useAdjustSelectedTasks = ({ isApproval = false, tasks = [] }) => {
  const { setSelectedTasks } = useContext(TaskPageContext);

  useEffect(() => {
    if (tasks.length === 0) return;

    const taskIds = tasks.map(({ id: taskId }) => Number(taskId));

    setSelectedTasks((prev) => {
      const selectedTasks = isApproval ? prev.approvalTasks : prev.paymentTasks;

      const filteredSelectedTasks = selectedTasks.filter(
        ({ id: selectedTaskId }) => taskIds.includes(Number(selectedTaskId))
      );

      if (isApproval) return { ...prev, approvalTasks: filteredSelectedTasks };
      return { ...prev, paymentTasks: filteredSelectedTasks };
    });
  }, [tasks.length]);
};

export const useTaskFilters = () => {
  const { teamsData, teamsLoading } = useConstants();

  const activeFilters = ["team", "created_by", "amount"];

  const filters = [
    {
      icon: "people",
      label: "Team",
      name: "team",
      search: true,
      type: "status",
      loading: teamsLoading,
      array: [...teamsData],
    },
    {
      icon: "person-outline",
      label: "Created by",
      type: "fetch",
      name: "created_by",
      urlFunc: (page, text) =>
        `business_users?page=${page}&per_page=15&q[email_or_name_cont]=${text}&q[status_not_eq]=invited`,
    },
    {
      label: "Amount",
      type: "amount",
      name: "amount",
      icon: "hash",
      noDirection: true,
      queryNames: [
        "amount_type",
        "specific_amount",
        "minimum_amount",
        "maximum_amount",
      ],
    },
  ].filter((filter) => filter);

  return { activeFilters, filters };
};
