import  {
  APPROVAL_FIRST_TARGET,
  APPROVAL_SECOND_TARGET,
  APPROVAL_THIRD_TARGET,
  APPROVAL_FOURTH_TARGET,
  APPROVAL_FIFTH_TARGET,
  APPROVAL_SIXTH_TARGET,
  BILL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  TOOLTIP_DEFAULT_PROPERTIES,
  DEFAULT_STYLES
} from "../../constants";

const styles = {
  ...TOOLTIP_DEFAULT_PROPERTIES?.styles,
  options: {
    zIndex: 100000,
  },
  tooltip: {
    zIndex: 100000,
  },
  tooltipContent: {
    zIndex: 100000,
  },
};

const stepsDecider = ({ methods, page }) => {
  const isPayroll = page === PAYROLL_MODULE;

  const billSteps = [
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_THIRD_TARGET}`,
      title: "Invoice preview",
      content:
        "Use this to ensure the transaction details align with the actual invoice.",
      placement: "left",
      styles
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_FOURTH_TARGET}`,
      title: "Edit details and calculation",
      content: "As an approver, you can edit these details if needed.",
      placement: "top",
      disableScrolling: false,
      styles
    },
  ];

  const transferRecipients = isPayroll ? "employee" : "recipient";
  const transferDataDetails = isPayroll ? "role, department" : "bank account";
  const transferSteps = [
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_FIRST_TARGET}`,
      title: `Search ${transferRecipients}`,
      content:
        "Use this to quickly find recipient in large batch transactions.",
      placement: "bottom-start",
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_SECOND_TARGET}`,
      title: "Transaction details",
      content: `Each ${transferRecipients}'s transaction is detailed here, showing amount, ${transferDataDetails}, and transfer notes.`,
      placement: "left",
    },
  ];

  const reimbursementSteps = [
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_FIFTH_TARGET}`,
      title: `Receipt preview`,
      content:
        "This preview helps you match the expense details with the actual receipt.",
      placement: "left-start",
      offset: -10
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${APPROVAL_SIXTH_TARGET}`,
      title: "Review each expense...",
      content: `Then make a decision by choosing 'approve' or 'reject' before moving on.`,
      placement: "top",
    },
  ];

  switch (page) {
    case BILL_MODULE:
      return billSteps;
    case REIMBURSEMENT_MODULE:
      return reimbursementSteps;
    case PAYROLL_MODULE:
    case LOCAL_MODULE:
      return transferSteps;
  }
};

const useApprovalProps = ({ page }) => {
  const callback = ({ data, defaultState, setGuidedTourState }) => {
    const { index, action, lifecycle } = data ?? {};

    const stepCount = steps.length - 1;

    const isComplete = lifecycle === "complete";

    if (!isComplete) return;

    switch (action) {
      case "close":
      case "next":
        if (index < stepCount) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex + 1,
          }));
          break;
        }
        if (index === stepCount) {
          setGuidedTourState(defaultState);
          break;
        }
        break;

      case "prev":
        if (index > 0) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex - 1,
          }));
          break;
        }
        break;

      default:
        break;
    }
  };

  const steps = stepsDecider({ page });

  return {
    steps,
    callback,
  };
};

export default useApprovalProps;
