import React, { Fragment, useRef, useState } from "react";
import Countdown from "react-countdown";
import { Tooltip } from "reactstrap";
import { colors, jackColors } from "../../../assets/colors";
import { bankLists } from "../../../assets/data";
import infoIconGrey from "../../../assets/images/info-icon.svg";
import imgSrc from "../../../assets/images/vector-pattern.svg";
import { ProcedureComponent } from "../../../components/Modals";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  formatCurrencyNoDecimal,
  windowDimension,
} from "../../../components/tools";
import bankProcedureDecider from "../../../constants/bankProcedureDecider";

import { JackIcons } from "../../../assets/jackIcons/parent";
import { Banner } from "../../../components/Banner";
import { getStartDifferentIndex, getUniqueCode } from "./logic";
import Skeleton from "react-loading-skeleton";

export const WrapperModalButtons = ({
  hideButtons = false,
  children,
  childrenButton,
  containerStyle,
  customComponentsStyle,
  customComponentsTopButton,
  customWidth,
}) => {
  if (hideButtons || !childrenButton) return <>{children}</>;

  const refContainerButton = useRef();
  const containerButtonHeight =
    refContainerButton?.current?.offsetHeight || 138;

  const bottomDecider = () => {
    if (customComponentsTopButton) return containerButtonHeight + 50;
    return containerButtonHeight;
  };

  // How to use
  // <WrapperModalButtons
  //   hideButtons={true / false}
  //   childrenButton={
  //     <ButtonJack
  //       type="outline"
  //       children="Delete"
  //       onClick={onClick}
  //     />
  //   }
  // >
  //   {CHILDREN}
  // </WrapperModalButtons>;

  const modalWidth = customWidth ? customWidth : 440;

  const { isSmallLaptop } = windowDimension();

  return (
    <div
      style={{
        position: "relative",
        paddingBottom: bottomDecider(),
      }}
    >
      {children}
      <div
        ref={refContainerButton}
        style={{
          position: isSmallLaptop ? "absolute" : "fixed",
          width: modalWidth,
          bottom: isSmallLaptop ? -64 : 0,
          left: isSmallLaptop ? -16 : 0,
          padding: 20,
          border: "1px solid #E6E6E8",
          borderLeftWidth: 0,
          borderRightWidth: 0,
          backgroundColor: "white",
          bottom: "56px",
          ...containerStyle,
        }}
      >
        {customComponentsTopButton && (
          <div
            style={{
              position: "absolute",
              top: -45,
              width: "100%",
              margin: "0px -20px",
              ...customComponentsStyle,
            }}
          >
            {customComponentsTopButton}
          </div>
        )}
        <div className="d-flex" style={{ maxWidth: modalWidth - 40, gap: 16 }}>
          {childrenButton}
        </div>
      </div>
    </div>
  );
};

export const CountDownTopUpModal = ({
  data,
  time = 0,
  fromMainModal = false,
  showAlert = false,
  handlePaymentInstruction,
  style,
}) => {
  const { expiredIn } = data || {};

  return (
    <div
      style={{
        background: "black",
        borderRadius: 4,
        padding: 16,
        marginTop: fromMainModal && 16,
        position: "relative",
        overflow: "hidden",
        ...style,
      }}
    >
      <div
        className="d-flex justify-content-between align-items-center"
        style={{
          marginBottom: showAlert && "16px",
          position: "relative",
          zIndex: 10,
        }}
      >
        <div>
          <GothamRegular
            className="mb-1 font12"
            style={{
              color: colors.whiteFC,
            }}
          >
            Complete your payment before
          </GothamRegular>
          <GothamMedium
            style={{
              fontSize: 12,
              lineHeight: "16px",
              color: "white",
            }}
          >
            {expiredIn}
          </GothamMedium>
          {fromMainModal && (
            <GothamMedium
              className="font12"
              style={{
                color: jackColors.greenB9,
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={handlePaymentInstruction}
            >
              See Payment Instruction
            </GothamMedium>
          )}
        </div>
        <div>
          <Countdown date={time} renderer={CustomCountDownComponent} />
        </div>
      </div>
      <div style={{ position: "relative", zIndex: 10 }}>
        {showAlert && (
          <Banner msg="If the top up is not successful within 10 minutes, please reach out to your account manager for help." />
        )}
      </div>
      <img
        src={imgSrc}
        style={{
          right: 0,
          top: 0,
          position: "absolute",
        }}
      />
    </div>
  );
};

const CustomCountDownComponent = ({
  formatted: { hours, minutes, seconds },
}) => {
  const data = [{ hours }, { minutes }, { seconds }];
  return (
    <div className="d-flex justify-content-center">
      {data?.map((item, index) => {
        const { hours, minutes, seconds } = item;
        const timeFormat = Object.keys(item).pop();
        const isLast = index === data.length - 1;
        return (
          <div key={index} className="d-flex align-items-center">
            <div
              style={{
                background: jackColors.greenB9,
                borderRadius: 4,
                padding: "2px 4px",
                width: 28,
                textAlign: "center",
              }}
            >
              <GothamMedium
                className="font16"
                style={{ color: "black", marginBottom: 0 }}
              >
                {hours || minutes || seconds}
              </GothamMedium>
            </div>
            {!isLast && (
              <GothamMedium
                style={{ margin: "0px 4px", color: "white", marginBottom: 0 }}
              >
                :
              </GothamMedium>
            )}
          </div>
        );
      })}
    </div>
  );
};

const RenderedColorValue = ({ amount, diffIndex }) => {
  return (
    <>
      {amount?.map((item, index) => {
        const isPink = index >= diffIndex;
        const isThreeDigits = (amount.length - index) % 3 === 0;
        const isNotIndexZero = index !== 0;
        const isNeedComma = isThreeDigits && isNotIndexZero;

        const isPinkComma = index === diffIndex || index < diffIndex;

        return (
          <Fragment key={index}>
            {isNeedComma && (
              <span style={{ color: !isPinkComma && jackColors.orangeF8 }}>
                ,
              </span>
            )}
            <span style={{ color: isPink && jackColors.orangeF8 }}>{item}</span>
          </Fragment>
        );
      })}
    </>
  );
};

export const CardDetailsBank = ({ data, vaData, va = false }) => {
  const bankIndex = va
    ? bankLists.findIndex(({ bank }) => bank === "Mandiri")
    : 0;

  const { bank, name, number, icon } = bankLists[bankIndex];

  const { amount_with_code, amount, unique_code } = data || {};

  const amountCodeArr = (amount_with_code || "").toString().split("");
  const amountString = (amount || "").toString();

  const startDiffIndex = getStartDifferentIndex(amountCodeArr, amountString);
  const { lastDigitsPlusUniqueCode } = getUniqueCode(amount, unique_code);

  const valueComponents = RenderedColorValue({
    amount: amountCodeArr,
    diffIndex: startDiffIndex,
  });

  const dataRendered = [
    {
      title: "Account Number",
      value: "5230636399",
      copyValue: "5230636399",
    },
    {
      title: "Total Amount",
      value: <>IDR {valueComponents}</>,
      copyValue: amount_with_code,
      icon: infoIconGrey,
    },
  ];

  const { amount: vaAmount, number: vaNumber } = vaData || {};

  const dataRenderedVa = [
    {
      title: "Account Number",
      value: vaNumber ? (
        vaNumber
      ) : (
        <Skeleton count={1} style={{ width: 200 }} />
      ),
      copyValue: vaNumber,
    },
    {
      title: "Total Payment",
      value: <>IDR {formatCurrencyNoDecimal(vaAmount)}</>,
      copyValue: vaAmount,
    },
  ];

  const vaStyle = { display: "flex", alignItems: "center" };
  const displayedName = va ? "Mandiri Virtual Account" : name;
  const dataDisplayed = va ? dataRenderedVa : dataRendered;

  return (
    <div
      className="borderE6"
      style={{
        background: "white",
        padding: 12,
        marginBottom: 32,
      }}
    >
      <div
        className="d-flex"
        style={{
          paddingBottom: 8,
          borderBottom: `1px solid ${colors.greyea}`,
          marginBottom: 16,
        }}
      >
        <img src={icon} className="mr-2" />
        <div style={va ? vaStyle : {}}>
          {!va && (
            <GothamRegular
              className="font10 mb-1"
              style={{
                color: jackColors.neutral700,
              }}
            >
              {bank}
            </GothamRegular>
          )}
          <GothamMedium>{displayedName}</GothamMedium>
        </div>
      </div>
      <div style={{ marginBottom: va ? 0 : 16 }}>
        {dataDisplayed?.map((item, index) => {
          const [isHover, setIsHover] = useState(false);
          const [tooltipOpen, setTooltipOpen] = useState(false);
          const toggleTooltip = () => setTooltipOpen(!tooltipOpen);
          const { title, value, copyValue, icon } = item || {};
          const showInfoTooltip = isHover === index;
          return (
            <div
              key={index}
              className="d-flex justify-content-between align-items-center"
              style={{
                marginBottom: va && index === dataDisplayed.length - 1 ? 0 : 12,
              }}
            >
              <div>
                <div className="d-flex align-items-center">
                  <GothamRegular
                    className="font12"
                    style={{ color: jackColors.neutral700 }}
                  >
                    {title}
                  </GothamRegular>
                  {icon && (
                    <div
                      style={{ position: "relative", height: 14 }}
                      onMouseEnter={() => setIsHover(index)}
                      onMouseLeave={() => setIsHover(false)}
                    >
                      <img
                        src={icon}
                        style={{ width: 10, marginLeft: 4, marginTop: -12 }}
                      />
                      {showInfoTooltip && (
                        <TooltipTotalAmountUniqueCode data={data} />
                      )}
                    </div>
                  )}
                </div>
                <GothamMedium>{value}</GothamMedium>
              </div>
              <div
                id={"Tooltip-" + (index + 1)}
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.preventDefault();
                  navigator.clipboard.writeText(copyValue);
                }}
              >
                <JackIcons name="file_copy" fill="black" />
              </div>
              <Tooltip
                target={"Tooltip-" + (index + 1)}
                isOpen={tooltipOpen}
                toggle={toggleTooltip}
                style={{ padding: "0px 12px 2px 12px" }}
              >
                <span
                  style={{
                    fontSize: 10,
                    lineHeight: "12px",
                    fontFamily: "TypefezNeueRegular",
                  }}
                >
                  Copy
                </span>
              </Tooltip>
            </div>
          );
        })}
      </div>
      {!va && (
        <Banner
          iconStyle={{ width: 20, height: 20 }}
          type="information"
          msg="Make sure you transfer the exact amount to the very last digit."
        />
      )}
    </div>
  );
};

export const ProcedureInstruction = ({ bankName = "Bank BCA" }) => {
  const [isExpandedIndex, setIsExpandedIndex] = useState(0);

  const stateObj = {
    isExpandedIndex: isExpandedIndex,
    setIsExpandedIndex: setIsExpandedIndex,
  };

  const bank = bankName ? bankName : "Bank BCA";
  const procedure = bankProcedureDecider(bank);
  const procedureKeys = Object.keys(procedure).map((procedure) => procedure);
  const procedureValues = Object.values(procedure).map(
    (procedure) => procedure
  );

  return (
    <div>
      <GothamMedium style={{ marginBottom: 16 }}>
        How to make the payment
      </GothamMedium>
      <div className="borderE6" style={{ padding: 12 }}>
        {procedureKeys.map((procedure, index) => (
          <ProcedureComponent
            key={procedure}
            procedure={procedure}
            procedureKeys={procedureKeys}
            procedures={procedureValues[index]}
            index={index}
            stateObj={stateObj}
          />
        ))}
      </div>
    </div>
  );
};

const TooltipTotalAmountUniqueCode = ({ data }) => {
  const { amount, amount_with_code, unique_code } = data || {};

  const AmountGenerator = (number) => `IDR ${formatCurrencyNoDecimal(number)}`;

  const renderArr = [
    { title: "Top up amount", value: AmountGenerator(amount) },
    { title: "Unique code", value: AmountGenerator(unique_code) },
    { spacer: true },
    {
      title: "Total amount",
      value: AmountGenerator(amount_with_code),
      valueBold: true,
    },
  ];

  return (
    <div
      style={{
        position: "absolute",
        top: -90,
        left: -90,
        backgroundColor: "white",
        borderRadius: 4,
        padding: 12,
        width: 217,
        boxShadow: "0px 8px 20px rgba(88, 88, 88, 0.1)",
      }}
    >
      {renderArr?.map((item, index) => {
        const { title, value, spacer, valueBold } = item || {};
        const isFirst = index === 0;

        if (spacer)
          return (
            <div
              key={index}
              style={{ borderBottom: "2px dashed #C2C2C2", marginTop: 8 }}
            />
          );

        return (
          <div
            key={index}
            className="d-flex justify-content-between align-items-center"
            style={{ marginTop: !isFirst && 8 }}
          >
            <GothamRegular
              className="font10"
              style={{
                color: colors.grey72,
              }}
            >
              {title}
            </GothamRegular>
            <GothamRegular
              className="font10"
              style={{
                color: valueBold ? colors.grey33 : colors.grey72,
                fontFamily: valueBold && "GothamMedium",
              }}
            >
              {value}
            </GothamRegular>
          </div>
        );
      })}
    </div>
  );
};
