import { isEmpty } from "lodash";
import { useRouter } from "next/router";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import { deepRemoveDuplicates, toLabelValue } from "../../components/tools";
import { fetch } from "../../tools/api";
import { getUserRole, useGetAuth } from "../AuthContext";
import { localBanksFormatter } from "./formatter/localBanks";
import { sgBanksFormatter } from "./formatter/sgBanks";
import { jackColors } from "../../assets/colors";
import categoriesFormatter from "./formatter/categories";
import {
  virtualAccountBanksFormatter,
  virtualAccountFormatter,
} from "./formatter/virtualAccount";
import { useModalHook } from "../../components/Modals";
import { useSmoothRightModal } from "../../components/Modals/RightModal/parent";

const ConstantsContext = createContext();

export const useFetcher = ({
  url,
  formatter = (data) => toLabelValue(data?.data || []),
  woAuth,
  canRun = true,
  ...rest
}) => {
  const { pathname } = useRouter();
  const { user } = useGetAuth();
  const { data, loading, setData, refetch } = fetch({
    url,
    formatter,
    woInit: true,
    noToaster: true,
    ...rest,
  });

  useEffect(() => {
    if (isEmpty(user) && url === "/teams") return setData([]);
    if (isEmpty(user) && !woAuth) return setData([]);
    if (data?.length) return;
    if (!canRun) return;
    refetch();
  }, [data?.length, pathname, isEmpty(user), woAuth]);

  return { data, loading, refetch };
};

export const ConstantsProvider = ({ children }) => {
  const { isEmployee } = getUserRole();

  const {
    data: teamsData,
    loading: teamsLoading,
    refetch: refetchTeamsData,
  } = useFetcher({
    url: "/teams",
    woAuth: true,
  });
  const {
    data: users,
    loading: loadingUsers,
    refetch: refetchUsers,
  } = useFetcher({
    url: "/business_users/all_users",
  });

  const activeUsers = (users || []).filter(({ deleted_at }) => !deleted_at);

  const { data: rolesData, loading: rolesLoading } = useFetcher({
    url: "/business_roles?q[name_cont]=partner_",
  });
  const { data: sgBanks, loading: sgBanksLoading } = useFetcher({
    url: "/swift-codes?country_code=SG&_limit=10000",
    type: "strapi",
    formatter: sgBanksFormatter,
  });
  const { data: idBanks, loading: idBanksLoading } = useFetcher({
    url: "/local_banks",
    formatter: localBanksFormatter,
  });
  const { data: localTransferBanks, loading: loadingTransferBanks } =
    useFetcher({
      url: "/local_transaction_batches/payers",
      formatter: localBanksFormatter,
    });
  const {
    data: activeCategories = [],
    loading: loadingActiveCategories,
    refetch: refetchActiveCategories,
  } = useFetcher({
    url: "/transaction_categories",
    formatter: categoriesFormatter,
  });
  const {
    data: deletedCategories = [],
    loading: loadingDeletedCategories,
    refetch: refetchDeletedCategories,
  } = useFetcher({
    url: "/transaction_categories?index=deleted",
    formatter: categoriesFormatter,
  });
  const {
    data: vaReferenceIds,
    loading: loadingVaReferenceIds,
    refetch: refetchVaReferenceIds,
  } = useFetcher({
    url: "/collection_virtual_accounts?per_page=10000",
    formatter: virtualAccountFormatter,
    canRun: !isEmployee,
  });

  const {
    data: vaBankOptions,
    loading: loadingVaBankOptions,
    refetch: refetchVaBankOptions,
  } = useFetcher({
    url: "/collection_va_banks",
    formatter: virtualAccountBanksFormatter,
    canRun: !isEmployee,
  });

  activeCategories?.sort((a, b) =>
    a.created_at > b.created_at ? -1 : a.created_at < b.created_at ? 1 : 0
  );

  const loadingCategories = loadingActiveCategories;
  const categories = [
    ...(activeCategories || []),
    ...deletedCategories?.map((deletedCategory) => ({
      ...(deletedCategory || []),
      isDeleted: true,
    })),
  ];
  const refetchCategories = async () => {
    refetchActiveCategories();
  };

  const teamsDataNames = teamsData.map((teamData) => teamData.label);

  // code review: Rafi
  // can be more simpler. Example: filterer at function `getRolesUserLogin`
  // const directorIndex = teamsDataNames.findIndex((name) =>
  //   name.toLowerCase().includes("director")
  // );
  const financeIndex = teamsDataNames.findIndex((teamDataName) =>
    teamDataName.toLowerCase().includes("financ")
  );
  const teamsDataFinance = teamsData.filter(
    (_, index) => index === financeIndex
  );
  const teamsDataWoFinance = teamsData.filter(
    (_, index) => index !== financeIndex
  );

  const teamsDataOutput = [...teamsDataFinance, ...teamsDataWoFinance];

  const newRolesData = rolesData.map((item) => {
    const { name } = item || {};
    if (name == "partner_super_admin")
      return { ...item, label: "Business Owner" };
    return item;
  });

  const {
    data: counterActivity,
    setData,
    refetch: refetchActivity,
  } = fetch({
    url: "/custom_notifications?per_page=1&q[notification_tab_eq]=activity",
    formatter: (res) => {
      const unread = res?.total_unread_notifications;
      setData(unread);
    },
  });

  const {
    data: counterNews,
    setData: setDataNews,
    refetch: refetchNews,
  } = fetch({
    url: "/custom_notifications?per_page=1&q[notification_tab_eq]=news_and_promo",
    formatter: (res) => {
      const unread = res?.total_unread_notifications;
      setDataNews(unread);
    },
  });

  const {
    data: counterTotal,
    setData: setDataTotal,
    refetch: refetchTotal,
  } = fetch({
    url: "/custom_notifications?per_page=1",
    formatter: (res) => {
      const unread = res?.total_all_unread_notifications;
      setDataTotal(unread);
    },
  });

  const notifModalRef = useRef();
  const [tourRunning, setTourRunning] = useState(false);
  const { isOpen, toggle, close } = useModalHook();
  const { toToggle, toListener } = useSmoothRightModal();

  const [tourRunningDummyData, setTourRunningDummyData] = useState([
    {
      originator_type: "general",
      created_at: "2023-06-13T10:29:51.000+07:00",
      title_eng: "Welcome to Jack! 🎉",
      content_eng:
        "Jack is ready to support every step of your company’s finance management journey",
      is_read: false,
      navigation_type: "transactions",
    },
  ]);

  const [tourState, setTourState] = useState({
    run: false,
    stepIndex: 0,
    completed: false,
  });

  const values = {
    users: activeUsers,
    allUsers: users,
    loadingUsers,
    refetchUsers,
    teamsData: teamsDataOutput,
    teamsLoading,
    refetchTeamsData,
    rolesData: newRolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks: deepRemoveDuplicates(localTransferBanks, "label"),
    loadingTransferBanks,
    categories,
    loadingCategories,
    loadingDeletedCategories,
    refetchCategories,
    refetchDeletedCategories,
    vaReferenceIds,
    loadingVaReferenceIds,
    refetchVaReferenceIds,
    vaBankOptions,
    loadingVaBankOptions,
    refetchVaBankOptions,
    counterActivity: tourRunning ? 0 : counterActivity,
    refetchActivity,
    counterNews: tourRunning ? 1 : counterNews,
    refetchNews,
    counterTotal: tourRunning ? 1 : counterTotal,
    refetchTotal,
    notifModalRef,
    tourRunning,
    setTourRunning,
    isOpen,
    toggle,
    close,
    tourRunningDummyData,
    setTourRunningDummyData,
    toToggle,
    toListener,
    setDataTotal,
    tourState,
    setTourState,
  };

  return (
    <ConstantsContext.Provider value={values}>
      {children}
    </ConstantsContext.Provider>
  );
};

export const useConstants = () => {
  const {
    users,
    allUsers,
    loadingUsers,
    refetchUsers,
    teamsData,
    teamsLoading,
    refetchTeamsData,
    rolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks,
    loadingTransferBanks,
    categories,
    loadingCategories,
    loadingDeletedCategories,
    refetchCategories,
    refetchDeletedCategories,
    vaReferenceIds,
    loadingVaReferenceIds,
    refetchVaReferenceIds,
    vaBankOptions,
    loadingVaBankOptions,
    refetchVaBankOptions,
    counterActivity,
    refetchActivity,
    counterNews,
    refetchNews,
    counterTotal,
    refetchTotal,
    notifModalRef,
    tourRunning,
    setTourRunning,
    isOpen,
    toggle,
    close,
    tourRunningDummyData,
    setTourRunningDummyData,
    toToggle,
    toListener,
    setDataTotal,
    tourState,
    setTourState,
  } = useContext(ConstantsContext) || {};

  return {
    users,
    allUsers,
    loadingUsers,
    refetchUsers,
    teamsData,
    teamsLoading,
    refetchTeamsData,
    rolesData,
    rolesLoading,
    sgBanks,
    sgBanksLoading,
    idBanks,
    idBanksLoading,
    localTransferBanks,
    loadingTransferBanks,
    categories,
    loadingCategories,
    loadingDeletedCategories,
    refetchCategories,
    refetchDeletedCategories,
    vaReferenceIds,
    loadingVaReferenceIds,
    refetchVaReferenceIds,
    vaBankOptions,
    loadingVaBankOptions,
    refetchVaBankOptions,
    counterActivity,
    refetchActivity,
    counterNews,
    refetchNews,
    counterTotal,
    refetchTotal,
    notifModalRef,
    tourRunning,
    setTourRunning,
    isOpen,
    toggle,
    close,
    tourRunningDummyData,
    setTourRunningDummyData,
    toToggle,
    toListener,
    setDataTotal,
    tourState,
    setTourState,
  };
};
