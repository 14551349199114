import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import CardInfoBalance from "../../components/CardInfoBalance";
import { LeftRightList } from "../../../../../../components/Lists";
import BottomContainer from "../../components/BottomContainer";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import ParserRichTextArea from "../../../../../../components/ParserRichTextArea";
import { colors } from "../../../../../../assets/colors";
import { SeeMore } from "../../../../../../components/SeeMore";
import {
  formatCurrencyNoDecimal,
  unformatCurrency,
} from "../../../../../../components/tools";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { getUserRole } from "../../../../../../contexts/AuthContext";

const PrepaidMoveToCardSummary = ({
  cardDetail,
  result,
  setMoveToCardScreen,
  onSubmit,
  isLoading,
}) => {
  const backHandler = () => setMoveToCardScreen("form");
  const { recipient, moved_amount: movedAmount, reason } = result;
  const { name, number, balance } = recipient;

  const { isAdminOrSuperAdmin } = getUserRole();

  const styles = {
    title: {
      fontSize: "24px",
      color: colors.neutral900,
    },
    toText: {
      fontSize: "12px",
      color: colors.neutral900,
      marginBottom: "8px",
    },
    recipientContainer: {
      padding: "12px",
      height: "56px",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      border: `1px solid ${colors.neutral500}`,
      borderRadius: "4px",
    },
    rowText: {
      fontSize: "12px",
      color: colors.neutral900,
      letterSpacing: "0.2px",
      lineHeight: "16px",
    },
    rowNumber: {
      fontSize: "10px",
      color: colors.neutral700,
    },
    reason: {
      color: colors.neutral900,
      fontSize: "12px",
      letterSpacing: "0.2px",
    },
    leftAlign: {
      textAlign: "left",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  const list = [
    {
      left: "Amount to be moved",
      right: `IDR ${formatCurrencyNoDecimal(movedAmount)}`,
      rightBold: true,
      styleRight: styles.leftAlign,
    },
    {
      left: "Reason",
      right: (
        <GothamRegular style={styles.reason}>
          <SeeMore buttonAlign="left" height={80} width={170}>
            <ParserRichTextArea text={reason} />
          </SeeMore>
        </GothamRegular>
      ),
      styleRight: styles.leftAlign,
    },
  ];

  return (
    <>
      <GothamMedium style={styles.title}>Move balance summary</GothamMedium>
      <CardInfoBalance cardDetail={cardDetail} text="From :" />
      <div>
        <GothamMedium style={styles.toText}>To :</GothamMedium>
        <div style={styles.recipientContainer}>
          <div>
            <GothamRegular style={styles.rowText}>{name}</GothamRegular>
            <GothamRegular style={styles.rowNumber}>{number}</GothamRegular>
          </div>
          <GothamRegular style={styles.rowText}>{balance}</GothamRegular>
        </div>
      </div>
      <LeftRightList array={list} />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          onClick={() => {
            onSubmit({
              target_card_id: recipient?.raw?.id,
              amount: +unformatCurrency(movedAmount),
              reason,
            });
          }}
          isLoading={isLoading}
        >
          {isAdminOrSuperAdmin ? "Move balance" : "Submit Request"}
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidMoveToCardSummary;
