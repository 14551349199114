import React, { useEffect } from "react";
import { ImportRecipientCSV } from "../../localTransferEnhancement/create/second";
import { useForm } from "react-hook-form";
import { downloadApi, useMutation } from "../../../tools/api";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import SimpleCenteredModal from "../../../modals/SimpleCenteredModal";
import { GothamBold, GothamRegular } from "../../../components/Text";
import { font20 } from "./utils";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { VA_MODULE } from "../../productDemoComponents/constants";
import { DEMO_CLICK_NEXT } from "../../productDemoComponents/constants/events";
import { eventsTracker } from "../../../universalFunctions/events";
import { useModalHook } from "../../../components/Modals";
import DemoGeneralModal from "../../productDemoComponents/components/DemoGeneralModal";

const UploadCsvModal = ({
  isOpen,
  toggle,
  setCurrentData,
  setCheckDuplicate,
}) => {
  const useFormObj = useForm();
  const { handleSubmit, watch, reset, register } = useFormObj;
  const { successSnackBar } = ToasterHook();

  const arrayUploadDetails = {
    title1: "1. Download this template",
    desc1:
      "Download and fill the virtual account details according to this template.",
    title2: "2. Upload the filled template",
    desc2:
      "Upload the filled Excel/CSV to start creating your virtual account.",
  };

  const file = watch("upload_file");
  const isDisabled = !file;

  const { mutation, loading } = useMutation({
    url: "collection_virtual_accounts/import",
    method: "post",
    defaultValue: [],
    resultFormatter: (data) => data?.data?.data,
    afterSuccess: (res) => {
      eventsTracker(DEMO_CLICK_NEXT, {
        activity_type: "create_virtual_account",
        page_title: "upload_virtual_account_excel",
      });
      setCurrentData((prev) => [...res, ...prev]);
      setCheckDuplicate(true);
      reset();
      toggle();
      // setCheckDuplicate(true);
      // push({ query: { state: "create", type: "import" } });
    },
  });

  const { isOpen: isOpenRestrict, toggle: toggleRestrict } = useModalHook();

  const onClickDownload = () => {
    // downloadApi({
    //   url: "/collection_virtual_accounts/template_va_import?format_template=excel",
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   fileName: "Jack-Template-Virtual-Account.xlsx",
    //   afterSuccess: () => {
    //     successSnackBar({
    //       msg: "Template has been downloaded.",
    //       showClose: true,
    //     });
    //   },
    // });
    toggleRestrict();
  };

  const onSubmit = ({ values, type }) => {
    const isUploadCSV = type == "upload_csv";

    if (isUploadCSV) {
      const { upload_file } = values || {};
      let formData = new FormData();
      formData.append("file", upload_file);
      formData.append("import_type", "not_create");
      return mutation(formData);
    }
  };

  const onSubmitCSV = (values) => onSubmit({ type: "upload_csv", values });

  const handleClose = () => {
    reset();
    toggle();
  };

  useEffect(() => {
    register("upload_file");
  }, []);

  return (
    <SimpleCenteredModal
      isOpen={isOpen}
      toggle={handleClose}
      isLoading={loading}
      leftHeaderComponent={
        <GothamBold style={{ ...font20, margin: 0 }}>
          Upload your virtual accounts
        </GothamBold>
      }
      customButtons={
        <ButtonJack
          disabled={isDisabled}
          onClick={handleSubmit(onSubmitCSV)}
          style={{ width: "fit-content", marginLeft: "auto" }}
          rightIcon={
            <JackIcons name="arrow-forward" fill={jackColors.neutral900} />
          }
        >
          Continue
        </ButtonJack>
      }
    >
      <ImportRecipientCSV
        wrapperStyle={{
          padding: "0px 20px",
          margin: 0,
          maxWidth: 500,
          marginBottom: 100,
        }}
        title=""
        useFormObj={useFormObj}
        onClickDownload={onClickDownload}
        isDisabled={isDisabled}
        arrayDetails={arrayUploadDetails}
        moreFileTypes={["text/csv"]}
        loading={loading}
        woButton
        module={VA_MODULE}
      />
      <DemoGeneralModal isOpen={isOpenRestrict} toggle={toggleRestrict} />
    </SimpleCenteredModal>
  );
};

export default UploadCsvModal;
