import CardDisplay from "../components/CardDisplay";
import CardInformation from "../components/CardInformation";
import BottomButton from "../components/BottomButton";
import BottomContainer from "../components/BottomContainer";
import { Banner } from "../../../../../components/Banner";
import { useMutation } from "../../../../../tools/api";
import { cardTypeDecider } from "../../../tools";
import { colors } from "../../../../../assets/colors";
import {
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import PINModal from "../../../../../components/PINModal/parent";
import { useModalHook } from "../../../../../components/Modals";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const DetailBlock = ({ type }) => {
  const { cardDetail, setCurrentScreen, refetchCardDetail, setHideClose } =
    useCardDetailContext();
  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();
  const { successSnackBar } = ToasterHook();

  const { balance, card_type: cardType, id } = cardDetail || {};
  const { isPhysical, isPrepaid } = cardTypeDecider(cardType);
  const isTemporary = type === "temporary";
  const blockTypeText = isTemporary ? "Temporarily" : "Permanently";

  const isZeroBalance = +balance == 0;

  const backHandler = () => setCurrentScreen("index");

  const showSuccessToaster = () => {
    if (isPhysical) {
      if (isTemporary) {
        eventsTracker("card_freeze_requested", { card_id: id });
        return successSnackBar({ msg: "Temporary card block is on process!" });
      }
      eventsTracker("card_permanent_block_requested", { card_id: id });
      return successSnackBar({ msg: "Permanent card block is on process!" });
    }
    if (isTemporary) {
      eventsTracker("card_freeze", { card_id: id });
      return successSnackBar({ msg: "Card has been temporarily blocked" });
    }
    eventsTracker("card_permanent_block", { card_id: id });
    return successSnackBar({ msg: "Card has been permanently blocked" });
  };

  const defaultAfterSuccess = () => {
    refetchCardDetail();
    backHandler();
    setHideClose(false);
    showSuccessToaster();
  };

  const confirmHandler = () => {
    setHideClose(true);
    togglePin();
  };

  const togglePinHandler = () => {
    setHideClose(false);
    togglePin();
  };

  const { mutation: mutationReqFreeze, loading: loadingReqFreeze } =
    useMutation({
      url: `/cards/${id}/request_freeze`,
      method: "post",
      afterSuccess: defaultAfterSuccess,
    });

  const { mutation: mutationFreeze, loading: loadingFreeze } = useMutation({
    url: `/cards/virtual_card/${id}/freeze`,
    method: "post",
    afterSuccess: defaultAfterSuccess,
  });

  const { mutation: mutationReqBlock, loading: loadingReqBlock } = useMutation({
    url: `/cards/${id}/request_block`,
    method: "post",
    afterSuccess: defaultAfterSuccess,
  });

  const { mutation: mutationBlock, loading: loadingBlock } = useMutation({
    url: `/cards/virtual_card/${id}/block`,
    method: "post",
    afterSuccess: defaultAfterSuccess,
  });

  const loadingMutation =
    loadingReqFreeze || loadingFreeze || loadingReqBlock || loadingBlock;

  const blockHandler = ({ pin }) => {
    if (isPhysical) {
      if (isTemporary) return mutationReqFreeze({ pin });
      return mutationReqBlock({ pin });
    }
    if (isTemporary) return mutationFreeze({ pin });
    return mutationBlock({ pin });
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      paddingBottom: "240px",
    },
    title: {
      color: colors.neutral900,
      fontSize: "24px",
    },
    subtitlePhysical: {
      color: colors.neutral800,
      letterSpacing: "0.1px",
      marginTop: "8px",
    },
    banner: {
      width: "calc(100% - 32px)",
      margin: "0 16px",
    },
    iconStyleBanner: {
      marginTop: "-10px",
    },
    bottomContainer: {
      paddingTop: "16px",
      backgroundColor: colors.neutral100,
      marginLeft: "16px",
      width: "100%",
      borderTop: `1px solid ${colors.neutral500}`,
    },
    message: {
      color: colors.neutral800,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
    },
  };

  const messageDecider = () => {
    if (isTemporary)
      return "By temporarily blocking, you cannot do any transaction using this card until you reactivate it.";
    if (isPrepaid && !isZeroBalance)
      return "To block this card, please withdraw all of its balance by contacting your AM.";
    return (
      <GothamRegular style={styles.message}>
        Permanently blocking this card will prevent all future transactions and
        hide its details.
        <TextInlineMedium> This action cannot be undone.</TextInlineMedium>
      </GothamRegular>
    );
  };

  const messageText = messageDecider();
  const isButtonDisabled = !isTemporary && isPrepaid && !isZeroBalance;

  return (
    <>
      <div style={styles.container}>
        <div>
          <GothamMedium style={styles.title}>
            {blockTypeText} block this card?
          </GothamMedium>
          {isPhysical && (
            <GothamRegular style={styles.subtitlePhysical}>
              This process will take 3 to 5 hours to complete
            </GothamRegular>
          )}
        </div>
        <CardDisplay cardDetail={cardDetail} isSmall woReveal />
        <CardInformation cardDetail={cardDetail} />
        <BottomContainer style={styles.bottomContainer}>
          <Banner
            type="warning"
            style={styles.banner}
            msg={messageText}
            iconStyle={styles.iconStyleBanner}
          />
          <BottomButton
            confirmText="Block Card"
            onConfirm={confirmHandler}
            onCancel={backHandler}
            isConfirmDisabled={isButtonDisabled}
            woBorder
          />
        </BottomContainer>
      </div>
      <PINModal
        isLoading={loadingMutation}
        isOpen={isOpenPin}
        toggle={togglePinHandler}
        onSubmit={blockHandler}
        onBack={togglePinHandler}
        woClose
      />
    </>
  );
};

export default DetailBlock;
