import { NextText, TooltipContent } from "../../components/OnboardModalPopup/components"
import DEFAULT_PROPERTIES, { LOCAL_SECOND_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${LOCAL_SECOND_TARGET}`,
    content: (
      <TooltipContent
        title="Pick a method to input details"
        description="You can bulk upload employee details with Excel file or input it one by one in an empty table."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_payroll",
      guided_tour_activity: "method_to_input_recipient_details"
    }
  },
];

const inputRecipientPayrollProps = { steps };

export default inputRecipientPayrollProps;
