import { useEffect, useState } from "react";
import BottomContainer from "./BottomContainer";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { colors } from "../../../../../assets/colors";
import {
  cardIssuanceStatusDecider,
  cardStateDecider,
  cardTypeDecider,
  expiryStatusDecider,
} from "../../../tools";
import { useMutation } from "../../../../../tools/api";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { useModalHook } from "../../../../../components/Modals";
import { getUserRole } from "../../../../../contexts/AuthContext";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";
import PINModal from "../../../../../components/PINModal/parent";
import RejectModal from "../../RejectModal";

const commonStyles = {
  button: {
    width: "100%",
  },
};

const WorkflowButtons = ({ onApprove, onReject }) => {
  const buttonLeftIcon = (
    <JackIcons name="close-circle" fill={colors.neutral900} />
  );
  const buttonRightIcon = (
    <JackIcons name="checkmark-circle-2" fill={colors.neutral900} />
  );

  return (
    <>
      <ButtonJack
        type="outline"
        style={commonStyles.button}
        leftIcon={buttonLeftIcon}
        onClick={onReject}
      >
        Reject
      </ButtonJack>
      <ButtonJack
        style={commonStyles.button}
        leftIcon={buttonRightIcon}
        isLoadingLeft
        onClick={onApprove}
      >
        Approve
      </ButtonJack>
    </>
  );
};

const ActivatePhysicalCardButton = ({ cardDetail, refetchCardDetail }) => {
  const { successSnackBar } = ToasterHook();
  const { mutation: mutationReqActivate, loading: loadingActivate } =
    useMutation({
      url: `/cards/${cardDetail?.id}/request_activate`,
      method: "post",
      afterSuccess: () => {
        successSnackBar({ msg: "Activating your card" });
        refetchCardDetail();
        eventsTracker("card_physical_request_active", {
          card_id: cardDetail?.id,
        });
      },
    });

  return (
    <ButtonJack
      style={commonStyles.button}
      isLoadingLeft
      isLoading={loadingActivate}
      onClick={() => mutationReqActivate()}
    >
      Activate Card
    </ButtonJack>
  );
};

const RenewCardButton = ({ onClick }) => {
  return (
    <ButtonJack onClick={onClick} style={commonStyles.button} isLoadingLeft>
      Renew Card
    </ButtonJack>
  );
};

const CardBottomButtons = () => {
  const {
    cardDetail,
    refetchCardDetail,
    refetchBalance,
    refetchMainBalance,
    setHideClose,
    setCurrentScreen,
    setUnmaskedDataDetail,
    isTask,
  } = useCardDetailContext();
  const {
    card_type: cardType,
    expiry_status: expiryStatusObj,
    get_approval_tasks: getApprovalTasks,
    issuance_status: issuanceStatus,
    state,
    user_id: ownerId,
  } = cardDetail || {};
  const { user } = useGetAuth();
  const isOwner = user?.id === ownerId;

  // Workflow
  const taskId = getApprovalTasks?.find((task) => task.current_order)?.id;
  const isShowWorkflowButtons = !!taskId;

  // Card Expiry
  const { notification_id: notifId, status: expiryStatus } =
    expiryStatusObj || {};
  const { isNeedRenewal } = expiryStatusDecider(expiryStatus);
  const isShowRenewalButton = isNeedRenewal && isOwner;

  // Physical Card Activation
  const { isPhysical, isPrepaid } = cardTypeDecider(cardType);
  const { isNotActive } = cardStateDecider(state);
  const { isDelivered } = cardIssuanceStatusDecider(issuanceStatus);
  const isShowActivatePhysicalCard = isPhysical && isNotActive && isDelivered;

  const [isPinError, setIsPinError] = useState(false);

  const { successSnackBar } = ToasterHook();
  const { isOpen: isOpenPin, toggle: togglePin } = useModalHook();
  const { isOpen: isOpenReject, toggle: toggleReject } = useModalHook();

  const { isAdminOrSuperAdmin } = getUserRole();

  const { mutation: approveMutation, loading: loadingApprove } = useMutation({
    url: `/approval_tasks/${taskId}/approve`,
    afterSuccess: () => {
      togglePin();
      refetchCardDetail();
      if (isPrepaid) {
        isAdminOrSuperAdmin && refetchMainBalance();
        refetchBalance();
      }
    },
  });

  const { mutation: rejectMutation, loading: loadingReject } = useMutation({
    url: `/approval_tasks/${taskId}/reject`,
    afterSuccess: () => {
      toggleReject();
      refetchCardDetail();
    },
  });

  const { mutation: renewMutation, loading: loadingRenew } = useMutation({
    url: `/cards/virtual_card/${cardDetail?.id}/renew`,
    afterSuccess: () => {
      setUnmaskedDataDetail({});
      refetchCardDetail();
      togglePin();
      successSnackBar({
        msg: "Your card has been renewed!",
      });
      eventsTracker("card_renewed", {
        card_id: cardDetail?.id,
      });
      setCurrentScreen("index");
    },
  });

  const onSubmitPin = ({ pin }) => {
    if (isShowWorkflowButtons && isTask) return approveMutation({ pin });
    if (isShowRenewalButton) return renewMutation({ pin });
    if (isShowWorkflowButtons) return approveMutation({ pin });
  };

  const onSubmitReject = (reason) => {
    const payload = {
      rejection_note: reason,
    };
    rejectMutation(payload);
  };

  useEffect(() => {
    if (isOpenPin || isOpenReject) setHideClose(true);
    else setHideClose(false);
  }, [isOpenPin, isOpenReject]);

  const loadingPin = loadingApprove || loadingRenew;

  const styles = {
    bottomContainer: {
      display: "flex",
      gap: "16px",
      zIndex: 100,
      padding: "20px",
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      position: "fixed",
      width: "420px",
      left: "0px",
    },
    filler: {
      height: "100px",
    },
  };

  const workflowButtonsComponent = () => (
    <WorkflowButtons onApprove={togglePin} onReject={toggleReject} />
  );

  const renewCardButtonComponent = () => (
    <RenewCardButton notifId={notifId} onClick={togglePin} />
  );

  const activatePhysicalCardButtonComponent = () => (
    <ActivatePhysicalCardButton
      cardDetail={cardDetail}
      refetchCardDetail={refetchCardDetail}
    />
  );

  const buttonsDecider = () => {
    if (isShowWorkflowButtons && isTask) return workflowButtonsComponent();
    if (isShowRenewalButton) return renewCardButtonComponent();
    if (isShowWorkflowButtons) return workflowButtonsComponent();
    if (isShowActivatePhysicalCard)
      return activatePhysicalCardButtonComponent();
    return null;
  };
  const buttons = buttonsDecider();
  if (!buttons) return null;

  return (
    <>
      <BottomContainer style={styles.bottomContainer}>
        {buttons}
      </BottomContainer>
      <div style={styles.filler} />
      <PINModal
        isOpen={isOpenPin}
        isLoading={loadingPin}
        toggle={togglePin}
        onSubmit={onSubmitPin}
        isError={isPinError}
        setIsError={setIsPinError}
        onBack={() => {
          setIsPinError(false);
          togglePin();
        }}
        woClose
      />
      <RejectModal
        isOpen={isOpenReject}
        toggle={toggleReject}
        isLoading={loadingReject}
        onSubmit={onSubmitReject}
      />
    </>
  );
};

export default CardBottomButtons;
