import styles from "./UpdateTrackerV2.module.css";
import StatusDot from "./StatusDot";
import UpdateContent from "./UpdateContent";
import { useState } from "react";
import { GothamMedium } from "../Text";
import { jackColors } from "../../assets/colors";

export const COMPLETED = "completed";
export const SUCCESS = "success";
export const FAILED = "failed";
export const ACTIVE = "active";
export const INACTIVE = "inactive";
export const PARTIAL_FAILED = "partial_failed";
export const RETRY = "retry";
export const FOLDED = "folded";

export const PRIMARY = "primary";
export const SECONDARY = "secondary";
export const REASON = "reason";
export const CUSTOM = "custom";

const foldedUpdatesFormatter = (updates) => {
  const activeUpdateIndex = updates.findIndex(
    ({ status }) => status === ACTIVE
  );

  const upperFoldedUpdatesCount = activeUpdateIndex - 1;
  const lowerFoldedUpdatesCount = updates.length - 1 - activeUpdateIndex;

  const hasUpperFoldedUpdates = upperFoldedUpdatesCount > 0;
  const hasLowerFoldedUpdates = lowerFoldedUpdatesCount > 0;

  const foldedUpdates = (count) => ({
    status: FOLDED,
    contents: [
      {
        type: SECONDARY,
        content: `${count} more ${count > 1 ? "steps" : "step"}`,
      },
    ],
  });

  const upperFoldedUpdates =
    hasUpperFoldedUpdates && foldedUpdates(upperFoldedUpdatesCount);
  const lowerFoldedUpdates =
    hasLowerFoldedUpdates && foldedUpdates(lowerFoldedUpdatesCount);

  return [
    updates[0],
    upperFoldedUpdates,
    updates[activeUpdateIndex],
    lowerFoldedUpdates,
  ].filter((value) => value);
};

const updatesTemplate = [
  {
    status: INACTIVE, // completed | success | failed | active | inactive (default) | partial_failed | retry
    contents: [
      {
        type: PRIMARY, // primary | secondary | reason | custom
        content: "Content",
      },
    ],
  },
];

const UpdateTrackerV2 = ({
  updates: updatesRaw = updatesTemplate,
  style = {},
}) => {
  const [showAll, setShowAll] = useState(false);

  const hasManyUpdates = updatesRaw.length > 4;
  const showFoldedUpdates = hasManyUpdates && !showAll;

  const handleShowToggle = () => setShowAll((prev) => !prev);

  const foldedUpdates = foldedUpdatesFormatter(updatesRaw);

  const updates = showFoldedUpdates ? foldedUpdates : updatesRaw;

  return (
    <div className={styles.mainContainer} style={style}>
      {updates.map(({ status, contents }, index) => {
        const isLast = index === updates.length - 1;
        const isFolded = status === FOLDED;
        const isFoldedNextUpdate = updates[index + 1]?.status === FOLDED;

        const hideProgressLine = isLast || isFolded || isFoldedNextUpdate;

        return (
          <div key={`${status}-${index}`} className={styles.update}>
            <div className={styles.iconContainer}>
              <StatusDot status={status} />
            </div>

            {!hideProgressLine && <div className={styles.progressLine} />}

            <div className={styles.contentContainer}>
              {contents.map(({ type, content }, index) => {
                return (
                  <UpdateContent
                    key={`${type}-${index}`}
                    type={type}
                    content={content}
                  />
                );
              })}
            </div>
          </div>
        );
      })}

      {hasManyUpdates && (
        <div className={styles.seeContainer}>
          <GothamMedium
            className="font12"
            style={{
              color: jackColors.neutral900,
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={handleShowToggle}
          >
            {showAll ? "See less" : "See all updates"}
          </GothamMedium>
        </div>
      )}
    </div>
  );
};

export default UpdateTrackerV2;
