import { useState, useEffect } from "react";
import { GothamRegular, GothamMedium } from "../../../../../components/Text";
import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";

const RowRadioButton = ({ list, name, useFormObj }) => {
  const { register, setValue, watch } = useFormObj;
  const selected = watch(name);
  const [hovered, setHovered] = useState(null);

  useEffect(() => {
    register(name);
  }, []);

  const containerStyle = {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    gap: "16px",
  };

  const buttonStyle = {
    borderRadius: "4px",
    border: `1px solid ${colors.neutral500}`,
    backgroundColor: colors.neutral100,
    padding: "12px",
    display: "flex",
    gap: "16px",
    transition: "all 0.1s linear",
  };

  const hoveredButtonStyle = {
    border: `1px solid ${colors.neutral600}`,
  };

  const selectedButtonStyle = {
    border: `1px solid ${colors.neutral900}`,
  };

  const iconContainerStyle = {
    width: "32px",
    height: "32px",
    borderRadius: "8px",
    backgroundColor: colors.neutral900,
    display: "grid",
    placeItems: "center",
    margin: "auto 0",
  };

  const textContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
    height: "32px",
  };

  const titleStyle = {
    fontSize: "13px",
    color: colors.neutral900,
    lineHeight: "16px",
    marginBottom: "0px",
  };

  const subtitleStyle = {
    fontSize: "12px",
    letterSpacing: "0.2px",
    color: colors.neutral700,
  };

  const outerCircleStyle = {
    width: "20px",
    height: "20px",
    borderRadius: "50%",
    border: `2px solid ${colors.neutral500}`,
    margin: "auto 0 auto auto",
    display: "grid",
    placeItems: "center",
    transition: "all 0.1s linear",
  };

  const selectedOuterCircleStyle = {
    border: `2px solid ${colors.neutral900}`,
  };

  const circleStyle = {
    width: "10px",
    height: "10px",
    borderRadius: "50%",
    backgroundColor: colors.neutral100,
    margin: "auto",
    transition: "all 0.1s linear",
  };

  const hoveredCircleStyle = {
    backgroundColor: colors.neutral500,
  };

  const selectedCircleStyle = {
    backgroundColor: colors.neutral900,
  };

  return (
    <div style={containerStyle}>
      {list.map(({ title, subtitle, icon, value }) => {
        const isSelected = selected === value;
        const isHovered = hovered === value;
        return (
          <button
            key={title}
            style={{
              ...buttonStyle,
              ...(isHovered && hoveredButtonStyle),
              ...(isSelected && selectedButtonStyle),
            }}
            onClick={() => setValue(name, value)}
            onMouseEnter={() => setHovered(value)}
            onMouseLeave={() => setHovered(null)}
          >
            <div style={iconContainerStyle}>
              <JackIcons name={icon} fill={colors.neutral100} />
            </div>
            <div style={textContainerStyle}>
              <GothamMedium style={titleStyle}>{title}</GothamMedium>
              <GothamRegular style={subtitleStyle}>{subtitle}</GothamRegular>
            </div>
            <div
              style={{
                ...outerCircleStyle,
                ...(isSelected && selectedOuterCircleStyle),
              }}
            >
              <div
                style={{
                  ...circleStyle,
                  ...(isHovered && hoveredCircleStyle),
                  ...(isSelected && selectedCircleStyle),
                }}
              />
            </div>
          </button>
        );
      })}
    </div>
  );
};

export default RowRadioButton;
