import { Fragment, useState } from "react";
import { jackColors } from "../../assets/colors";
import { JackIcons } from "../../assets/jackIcons/parent";
import { useClickOutside } from "../../universalFunctions/useClickOutside";
import { useModalHook } from "../Modals";
import { GothamRegular, TextInlineRegular } from "../Text";
import { ButtonJack, buttonsProps } from "./parent";
import { isFunction } from "lodash";

export const DropdownButtonJack = (props = { ...buttonsProps, array: [] }) => {
  const {
    onClick,
    array,
    style,
    dropdownStyle,
    type = "outline",
    isSmall = true,
    customFunction = () => {},
  } = props;
  const { isOpen, toggle, close } = useModalHook();

  const [ref, setRef] = useState(null);
  const [clientHeight, setClientHeight] = useState(0);

  useClickOutside({ ref: { current: ref }, clickOutside: close });

  const dropdownHeight = clientHeight + 16;

  return (
    <div
      style={{ position: "relative" }}
      ref={(e) => setRef(e)}
      onClick={() => {
        if (!isOpen) {
          isFunction(customFunction) && customFunction();
        }
        toggle();
      }}
    >
      <ButtonJack
        type={type}
        style={{ paddingLeft: 6, paddingRight: 6, ...style }}
        isSmall={isSmall}
        rightIcon={<JackIcons name="chevron-down" fill={jackColors.black34} />}
        {...props}
        onClick={() => {}}
      />
      <div
        style={{
          position: "absolute",
          top: (ref?.clientHeight || 0) + 8,
          left: 0,
          padding: 8,
          backgroundColor: "white",
          boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
          width: 256,
          zIndex: 2,
          borderRadius: 8,
          opacity: isOpen ? 1 : 0,
          transition: "all 0.4s linear",
          visibility: isOpen ? "initial" : "hidden",
          height: isOpen ? dropdownHeight : 0,
          overflow: "hidden",
          ...dropdownStyle,
        }}
      >
        <div
          ref={(e) => {
            if (!e) return;
            setClientHeight((prev) => {
              if (prev == e.clientHeight) return prev;
              return e.clientHeight;
            });
          }}
        >
          {array.map((item, index) => {
            const { label, value, icon, customComponent, msg } = item;
            const isLast = index == array.length - 1;
            if (customComponent) return customComponent;
            return (
              <div
                key={index}
                style={{
                  padding: 8,
                  paddingTop: 12,
                  paddingBottom: 12,
                  marginBottom: isLast ? 0 : 4,
                }}
                onClick={() => onClick(value)}
                className="hover"
              >
                <GothamRegular
                  className="font12 d-flex align-items-center"
                  style={{
                    color: jackColors.black34,
                  }}
                >
                  <JackIcons
                    name={icon}
                    fill={jackColors.grey6C}
                    style={{ height: 20, width: 20, marginBottom: 2 }}
                    className="mr-2"
                  />
                  {label}
                </GothamRegular>
                {!!msg && (
                  <GothamRegular
                    className="font12 mt-1"
                    style={{ color: jackColors.grey90 }}
                  >
                    {msg}
                  </GothamRegular>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
