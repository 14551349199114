import { createContext, useContext, useEffect, useState } from "react";
import ReactJoyride, { STATUS, ACTIONS, EVENTS } from "react-joyride";
import { isArray, isEmpty, isFunction, isObject } from "lodash";
import { useRouter } from "next/router";
import { useDemo } from "../DemoContext";
import { eventsTracker } from "../../universalFunctions/events";
import { DEMO_GUIDED_TOUR_NEXT } from "../../pageComponents/productDemoComponents/constants/events";

export const GuidedTourContext = createContext({
  startTour: () => {},
  stopTour: () => {},
});

const defaultState = {
  isRunning: false,
  steps: [],
  props: {},
  callback: null,
  stepIndex: 0,
};

const GuidedTourProvider = ({ children = null }) => {
  const [{ isRunning, steps, props, callback, stepIndex }, setGuidedTourState] =
  useState(defaultState);

  const { setGuidanceState } = useDemo();
  const { events, asPath } = useRouter();

  const stopTour = () => {
    setGuidedTourState(defaultState);
    setGuidanceState((prev) => ({
      ...prev,
      isTourRunning: false,
      // isGuidanceMode: false
    }));
  };

  const startTour = ({ steps, props, callback, stepIndex }) => {
    const hasSteps = isArray(steps) && steps?.length > 0;
    const hasProps = isObject(props) && !isEmpty(props);

    if (!hasSteps) return;

    const state = {
      isRunning: true,
      steps,
      props: {},
      callback,
      stepIndex,
    };

    if (hasProps) state.props = props;

    setGuidedTourState(state);
    setGuidanceState(prev => ({
      ...prev,
      guidanceFunction: stopTour, // to switch the function inside the play/pause button
      isTourRunning: true,
      // isGuidanceMode: true,
    }));
  };

  const handleCallback = (data) => {
    if (isFunction(callback)) {
      return callback({
        data,
        defaultState,
        setGuidedTourState,
        stepIndex,
      });
    }

    const { action, status, lifecycle, step } = data ?? {};
    // console.log('action:', action)
    // console.log('data:', data)
    const {eventPayload} = step ?? {}
    const isComplete = lifecycle === "complete"
    
    if (isComplete) {
      switch (action) {
        case "close":
          eventsTracker(DEMO_GUIDED_TOUR_NEXT, eventPayload)
        break;
      default:
        break;
      }
    }

    const isClosed = action === ACTIONS.CLOSE;
    const isFinished = status === STATUS.FINISHED;
    if (isFinished || isClosed) stopTour();
  };

  useEffect(() => {
    const handleRouteChange = (url) => {
      // console.log('asPath:', asPath)
      // console.log('pathname:', pathname)
      // console.log('url:', url)

      const newPath = url?.split("/")[1];
      const currentPath = asPath?.split("/")[1];

      if (newPath != currentPath) {
        // stopTour();
      }
    };

    events.on("routeChangeStart", handleRouteChange);

    return () => {
      // stopTour();
      events.off("routeChangeStart", handleRouteChange);
    };
  }, [events]);

  const providerValue = { startTour, stopTour };

  return (
    <GuidedTourContext.Provider value={providerValue}>
      {stepIndex == 0 || !!stepIndex ? (
        <ReactJoyride
          steps={steps}
          run={isRunning}
          callback={handleCallback}
          stepIndex={stepIndex}
          {...props}
        />
      ) : (
        <ReactJoyride
          steps={steps}
          run={isRunning}
          callback={handleCallback}
          continuous
          {...props}
        />
      )}
      {children}
    </GuidedTourContext.Provider>
  );
};

export const useGuidedTour = (
  args = { steps: [], props: {}, callback: null, stepIndex: 0 },
) => {
  const { steps = [], props = {}, callback = null, stepIndex = 0 } = args ?? {};

  const { startTour, stopTour } = useContext(GuidedTourContext);

  const handleStartTour = () => {
    startTour({ steps, props, callback, stepIndex });
  };

  return { startTour: handleStartTour, stopTour };
};

export default GuidedTourProvider;
