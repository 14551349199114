import UpdateTracker from "../../../../../components/UpdateTracker/parent";
import { updatesTrackerDecider } from "../../../screens/SuccessScreen/tools";

const CardTrackerPrepaidBalance = ({ cardDetail, updates, rejectionNote }) => {
  const { is_seen: isSeen, current_workflow_process: currentWorkflowProcess } =
    cardDetail || {};

  const isRequestTopUpMainBalance =
    currentWorkflowProcess === "request_withdraw_balance_company";
  const isRequestWithdrawMainBalance =
    currentWorkflowProcess === "request_topup_balance_company";
  const isRequestMoveBalanceToCard =
    currentWorkflowProcess === "request_move_balance";
  const isRequestActiveCard = currentWorkflowProcess === "request_active";

  const isPrepaidProcess =
    isRequestTopUpMainBalance ||
    isRequestWithdrawMainBalance ||
    isRequestMoveBalanceToCard ||
    isRequestActiveCard;

  if (isSeen || !isPrepaidProcess) return null;

  return <UpdateTracker updates={updates} rejectionNote={rejectionNote} />;
};

export default CardTrackerPrepaidBalance;
