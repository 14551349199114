import { universeFunc } from "../../components/RecipientDetailsModal";

export const UniverseDataGenerator = ({ data }) => {
  const bankName = universeFunc("bank_name", data);
  const receivingRegisteredName = universeFunc(
    "receiving_registered_name",
    data
  );
  const registeredName = universeFunc("registered_name", data);
  const address = universeFunc("receiving_address", data);
  const phoneNumber = universeFunc("MSISDN", data);
  const receivingFirstName = universeFunc("receiving_first_name", data);
  const receivingLastName = universeFunc("receiving_last_name", data);
  const firstName = universeFunc("firstname", data);
  const lastName = universeFunc("lastname", data);
  const bankAccountName = universeFunc("bank_account_name", data);
  const bankAccountNumber = universeFunc("bank_account_number", data);
  const ibanNumber = universeFunc("iban", data);
  const swiftCode = universeFunc("swift_bic_code", data);
  const recipientEmail = universeFunc("recipient_email", data);
  const abaRoutingNumber = universeFunc("aba_routing_number", data);
  const userName =
    receivingRegisteredName ||
    registeredName ||
    `${receivingFirstName || firstName} ${receivingLastName || lastName}`;

  const personalInformation = [
    ...(userName
      ? [
          {
            title: `Name`,
            value: userName,
          },
        ]
      : []),
    ...(recipientEmail
      ? [
          {
            title: `Email`,
            value: recipientEmail,
          },
        ]
      : []),
    ...(phoneNumber
      ? [
          {
            title: `Phone number`,
            value: phoneNumber,
          },
        ]
      : []),
    ...(address
      ? [
          {
            title: `Address`,
            value: address,
          },
        ]
      : []),
  ];

  const bankInformation = [
    ...(bankName
      ? [
          {
            title: `Bank name`,
            value: bankName,
          },
        ]
      : []),
    ...(bankAccountName
      ? [
          {
            title: `Account name`,
            value: bankAccountName,
          },
        ]
      : []),
    ...(bankAccountNumber
      ? [
          {
            title: `Account number`,
            value: bankAccountNumber,
          },
        ]
      : []),
    ...(ibanNumber
      ? [
          {
            title: `IBAN`,
            value: ibanNumber,
          },
        ]
      : []),
    ...(swiftCode
      ? [
          {
            title: `SWIFT BIC code`,
            value: swiftCode,
          },
        ]
      : []),
    ...(abaRoutingNumber
      ? [
          {
            title: `ABA routing number`,
            value: abaRoutingNumber,
          },
        ]
      : []),
  ];

  const universeData = [...personalInformation, ...bankInformation];

  return { universeData, bankInformation, personalInformation };
};
