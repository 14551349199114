const DiagonalArrow = () => {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.2499 5.875C15.2499 5.65399 15.1622 5.44202 15.0059 5.28574C14.8496 5.12946 14.6376 5.04167 14.4166 5.04167L7.74995 5C7.52894 5 7.31697 5.0878 7.16069 5.24408C7.00441 5.40036 6.91662 5.61232 6.91662 5.83333C6.91662 6.05435 7.00441 6.26631 7.16069 6.42259C7.31697 6.57887 7.52894 6.66667 7.74995 6.66667H12.3833L5.49162 13.575C5.41351 13.6525 5.35151 13.7446 5.30921 13.8462C5.2669 13.9477 5.24512 14.0567 5.24512 14.1667C5.24512 14.2767 5.2669 14.3856 5.30921 14.4871C5.35151 14.5887 5.41351 14.6809 5.49162 14.7583C5.56908 14.8364 5.66125 14.8984 5.7628 14.9407C5.86435 14.9831 5.97327 15.0048 6.08328 15.0048C6.19329 15.0048 6.30221 14.9831 6.40376 14.9407C6.50531 14.8984 6.59748 14.8364 6.67495 14.7583L13.5833 7.85V12.5C13.5833 12.721 13.6711 12.933 13.8274 13.0893C13.9836 13.2455 14.1956 13.3333 14.4166 13.3333C14.6376 13.3333 14.8496 13.2455 15.0059 13.0893C15.1622 12.933 15.2499 12.721 15.2499 12.5V5.875Z"
        fill="#343434"
      />
    </svg>
  );
};

export default DiagonalArrow;
