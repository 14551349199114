// For page query params
export const RECIPIENT_ACCOUNT_TYPE = "recipient_account";
export const TRANSFER_DETAILS_TYPE = "transfer_details";
export const CONFIRM_SUBMIT_TYPE = "confirm_submit";

// For recipient dropdown
export const LOADING_COMPANY = "loading";
export const RECENT_TRANSACTIONS = "recent_transactions";
export const ERROR_NOT_FOUND = "error_not_found";
export const FOUND_COMPANY = "found_company";
export const ERROR_INVALID = "error_invalid";
export const CLOSED_DROPDOWN = "closed";

// Event names
export const J2J_EVENT_CLICK_GUIDE_ME = "click_guide_me";
export const J2J_EVENT_CLOSE_GUIDE = "close_guide";
export const J2J_EVENT_CLICK_NEXT = "click_next";
export const J2J_EVENT_CLICK_CLOSE = "click_close";
export const J2J_EVENT_CLICK_FINISH = "click_finish";
export const J2J_EVENT_CLICK_JACK_ID = "click_jack_id";
export const J2J_EVENT_CLICK_TRANSFER_BALANCE = "click_transfer_balance";
export const J2J_EVENT_CREATE_BETWEEN_JACK = "create_between_jack";
export const J2J_EVENT_CLICK_BETWEEN_JACK = "click_between_jack";
export const J2J_EVENT_CLICK_LOCAL_TRANSFER = "click_local_transfer";
export const J2J_EVENT_CLICK_INTERNATIONAL_TRANSFER =
  "click_international_transfer";
export const J2J_EVENT_CLICK_SELECT_CONTINUE = "click_select_continue";
export const J2J_EVENT_CLICK_HELP_JACKID = "click_help_jackid";
export const J2J_EVENT_CLICK_ARTICLE_URL = "click_article_url";
export const J2J_EVENT_CLICK_BACK = "click_back";
export const J2J_EVENT_CLICK_STAY_HERE = "click_stay_here";
export const J2J_EVENT_CLICK_GO_BACK = "click_go_back";
export const J2J_EVENT_CLICK_RECENT_TRANSFER = "click_recent_transfer";
export const J2J_EVENT_CLICK_CHANGE = "click_change";
export const J2J_EVENT_CLICK_CONTINUE = "click_continue";
export const J2J_EVENT_CLICK_SUBMIT = "click_submit";
