export const colors = {
  grey72: "#727272",
  teal: "#49ABB8",
  errorRed: "#EB5B42",
  greyC2: "#c2c2c2",
  greyea: "#eaeaea",
  yellowFF: "#FFD27C",
  darkTeal: "#218390",
  grey25: "#252525",
  teal21: "#218391",
  yellowFF: "#FFD27C",

  blue19: "#196E97",
  blue09: "#095174",
  blue10: "#102C57",
  bluee1: "#E1EEFF",
  blue9a: "#9AD7D0",
  error9c: "#9C0E0E",
  lightYellowFF: "#FFE7BA",
  lightestYellowFF: "#FFF8EC",
  lightYellowFC: "#FCF4E8",
  redFF: "#FFD5CD",
  redB9: "#B90E0E",
  redEB: "#EB5B42",
  // gradient: "linear-gradient(rgba(73,171,184,1) 5%, rgba(25,110,151,1) 100%)",
  gradient: "linear-gradient(rgba(16,44,87,1) 5%, rgba(72,104,150,1) 100%)",
  lightTeal: "#e5f9fc",
  redE7: "#E73126",
  redE9: "#E95757",
  green28: "#28AB7C",
  greend3: "#D3F5EC",
  whiteFC: "#FCFCFC",
  pink: "#DA649F",
  lightPink: "#FDD7F5",
  blue: "#102C57",
  lightBlue: "#9CC3F5",
  grey33: "#333334",
  primaryBlue: "#102C57",
  grey90: "#909098",
  grey6c: "#6C6C71",
  greyBB: "#BBBBC0",
  blue48: "#486896",
  blue2C: "#2C4A77",
  bluee6: "#E6F0FC",
  greye6: "#E6E6E8",
  redca: "#ca0b0b",
  greyd1: "#D1D1D4",
  orangeFF: "#FF7B5A",
  green06: "#06AC4E",
  yellowF4: "#F4D53D",
  yellowfc: "#FCB037",

  // new jack colors
  neutral100: "#FFFFFF",
  neutral400: "#F1F1F1",
  neutral500: "#E6E6E8",
  neutral600: "#BBBBC0",
  neutral700: "#909098",
  neutral800: "#6C6C71",
  neutral900: "#343434",
  primaryStabilowB9: "#B9FC00",
  yellowF7: "#F7EFE3",
  yellowFC: "#FCB037",
  yellowFFF: "#FFF2BF",
  yellowFFC: "#FFCB00",
  redFF: "#FFEEEE",
  orangeF2: "#F26727",
  redDC: "#DC2F44",
  redE7: "#E73126",
  green06: "#06AC4E",
  salmonFE: "#FEACAA",
  blue60: "#6087E7",
  greenE5: "#E5FAEE",
};

export const jackColors = {
  black34: "#343434",
  greyBB: "#BBBBC0",
  redE7: "#E73126",
  greenB9: "#B9FC00",
  grey6C: "#6C6C71",
  grey90: "#909098",
  greyF1: "#F1F1F1",
  greyE6: "#E6E6E8",
  green06: "#06AC4E",
  neutral100: "#FFFFFF",
  neutral200: "#FCFCFC",
  neutral400: "#F1F1F1",
  neutral500: "#E6E6E8",
  neutral600: "#BBBBC0",
  neutral700: "#909098",
  neutral800: "#6C6C71",
  neutral900: "#343434",
  greyFC: "#FCFCFC",
  redFF: "#FFEEEE",
  yellowFC: "#FCB037",

  // Team colors
  maroon47: "#47001E",
  orangeF8: "#F8762D",
  yellowFF: "#FFCB00",
  cyan04: "#04C7B3",
  blue1C: "#1C4C6C",
  green23: "#238730",
  magenta87: "#876FDE",
  pinkDE: "#DE738B",
  brownC6: "#C69B7B",
  redDC: "#DC2F44",
  blue60: "#6087E7",
  purple5A: "#5A489C",
  purple7F: "#7F0B5C",
  teracottaAA: "#AA3511",
  orangeF2: "#F26727",
  yellowF4: "#F4D53D",
  orangeFF: "#FF7B5A",
  green2C: "#2CC4B5",
  blue54: "#54C4D8",
  blue0A: "#0A7C8C",
  newNotif: "linear-gradient(0deg, rgba(155, 222, 184, 0.15) 0%, rgba(155, 222, 184, 0.15) 100%), #FFF",
  hoveredNotif: "linear-gradient(0deg, rgba(0, 0, 0, 0.05) 0%, rgba(0, 0, 0, 0.05) 100%), linear-gradient(0deg, rgba(155, 222, 184, 0.15) 0%, rgba(155, 222, 184, 0.15) 100%), #FFF"
};
