import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { LOCAL_THIRD_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${LOCAL_THIRD_TARGET}`,
    content: (
      <TooltipContent
        title="Follow this guide then..."
        description="Make sure you've got all the recipient details right and filled completely."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_payroll",
      guided_tour_activity: "upload_recipient_csv"
    }
  },
];

const inputRecipientCsvPayrollProps = { steps };

export default inputRecipientCsvPayrollProps;
