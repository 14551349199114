import CardDisplay from "../components/CardDisplay";
import { colors } from "../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { useState } from "react";
import {
  CurrencyFieldJack,
  TextFieldJack,
} from "../../../../../components/inputs/textfield";
import { useForm } from "react-hook-form";
import BottomContainer from "../components/BottomContainer";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { useMutation, fetch } from "../../../../../tools/api";
import {
  formatCurrencyNoDecimal,
  unformatCurrency,
} from "../../../../../components/tools";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { ToasterHook } from "../../../../../contexts/ToasterContext";
import { getUserRole } from "../../../../../contexts/AuthContext";
import LimitTypeRadioButtons from "../../LimitTypeRadioButtons";
import Skeleton from "react-loading-skeleton";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const AdjustVirtualLimit = () => {
  const { cardDetail, setCurrentScreen, refetchCardDetail } =
    useCardDetailContext();
  const { limit_value: currentLimit, limit_type: currentLimitType } =
    cardDetail || {};
  const { isAdminOrSuperAdmin } = getUserRole();
  const useFormObj = useForm({
    defaultValues: {
      request_limit: currentLimit,
      limit_type: currentLimitType,
    },
  });
  const { handleSubmit, watch } = useFormObj;

  const { data: cardBusiness, loading: loadingCardBusiness } = fetch({
    url: "/card_business_detail",
    formatter: (res) => res?.data,
  });
  const { available_virtual_limit } = cardBusiness?.detailed_data || {};

  const availableVirtualLimit =
    available_virtual_limit > 0 ? available_virtual_limit : 0;

  const requestLimitWatch = +unformatCurrency(watch("request_limit"));
  const limitTypeWatch = watch("limit_type");
  const isButtonDisabled =
    (requestLimitWatch === currentLimit ||
      !requestLimitWatch ||
      requestLimitWatch < 0) &&
    limitTypeWatch === currentLimitType;

  const backHandler = () => setCurrentScreen("index");

  const { successSnackBar } = ToasterHook();

  const { mutation: mutationVirtualUpdate, loading: loadingVirtualUpdate } =
    useMutation({
      url: `/cards/virtual_card/${cardDetail?.id}`,
      afterSuccess: async (res) => {
        await refetchCardDetail();
        backHandler();
        eventsTracker("card_virtual_adjust_limit", {
          card_id: cardDetail?.id,
          limit_type: limitTypeWatch,
          limit_value: requestLimitWatch,
        });
        const isExceedLimit =
          res?.data?.data?.process_exceed_limit?.request_adjust_limit;
        if (isAdminOrSuperAdmin && !isExceedLimit)
          return successSnackBar({ msg: "Card limit has been adjusted!" });
        successSnackBar({ msg: "New card limit has been requested!" });
      },
    });

  const onSubmit = (value) => {
    const {
      request_limit: requestLimit,
      limit_type: requestLimitType,
      reason,
    } = value;
    const payload = {
      limit_type: requestLimitType,
      limit_value: +unformatCurrency(requestLimit),
      reason,
    };
    mutationVirtualUpdate(payload);
  };

  const styles = {
    container: {
      height: "100%",
      paddingBottom: "680px",
    },
    innerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    title: {
      color: colors.neutral900,
      fontSize: "24px",
    },
    input: {
      marginBottom: "0px",
    },
    availableLimitContainer: {
      display: "flex",
      justifyContent: "space-between",
      marginTop: "-18px",
    },
    availableLimitLabel: {
      fontSize: "12px",
      color: colors.neutral900,
      letterSpacing: "0.2px",
    },
    textFieldReason: {
      height: "60px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      marginLeft: "16px",
      padding: "20px",
      display: "flex",
      gap: "16px",
      width: "100%",
    },
    button: {
      width: "100%",
    },
  };

  const containerStyle = {
    height: "100%",
    paddingBottom: "680px",
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  };

  const titleStyle = {
    color: colors.neutral900,
    fontSize: "24px",
  };

  const inputStyle = {
    marginBottom: "0px",
  };

  const availableLimitContainerStyle = {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "-18px",
  };

  const availableLimitLabelStyle = {
    fontSize: "12px",
    color: colors.neutral900,
    letterSpacing: "0.2px",
  };

  const textFieldReasonStyle = {
    height: "60px",
  };

  const bottomContainerStyle = {
    borderTop: `1px solid ${colors.neutral500}`,
    backgroundColor: colors.neutral100,
    marginLeft: "16px",
    padding: "20px",
    display: "flex",
    gap: "16px",
    width: "100%",
  };

  const buttonStyle = {
    width: "100%",
  };
  const { isEmployee } = getUserRole();

  return (
    <div style={styles.container}>
      <div style={styles.innerContainer}>
        <GothamMedium style={styles.title}>Adjust Card Limit</GothamMedium>
        <CardDisplay cardDetail={cardDetail} isSmall woReveal />
        <LimitTypeRadioButtons
          useFormObj={useFormObj}
          name="limit_type"
          label={"Limit Renewal"}
        />
        <div>
          <CurrencyFieldJack
            name="request_limit"
            label="New Limit"
            useFormObj={useFormObj}
            icon={
              <GothamRegular
                style={{
                  color: colors.neutral600,
                }}
              >
                IDR
              </GothamRegular>
            }
            helperText="Please input a different amount from your current limit."
            style={{
              ...styles.input,
              marginBottom: isEmployee ? "0px" : "32px",
            }}
          />
          {!isEmployee && (
            <div style={styles.availableLimitContainer}>
              <GothamRegular style={styles.availableLimitLabel}>
                Available company limit
              </GothamRegular>
              {loadingCardBusiness ? (
                <Skeleton width={160} height={18} />
              ) : (
                <GothamMedium>
                  IDR {formatCurrencyNoDecimal(availableVirtualLimit)}
                </GothamMedium>
              )}
            </div>
          )}
        </div>
        <TextFieldJack
          name="reason"
          label="Reason"
          useFormObj={useFormObj}
          placeholder="Why do you want to adjust the card limit?"
          textFieldStyle={styles.textFieldReason}
          style={styles.input}
          isOptional
        />
      </div>
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack type="outline" onClick={backHandler} style={buttonStyle}>
          Cancel
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          disabled={isButtonDisabled}
          onClick={handleSubmit(onSubmit)}
          isLoading={loadingVirtualUpdate}
        >
          Adjust Limit
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default AdjustVirtualLimit;
