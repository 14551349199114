import CardDisplay from "../components/CardDisplay";
import { BulletList } from "../../../../../components/BulletList";
import { colors } from "../../../../../assets/colors";
import {
  GothamRegular,
  TextInlineMedium,
  GothamMedium,
} from "../../../../../components/Text";
import UnderstandButton from "../components/UnderstandButton";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";

const PhysicalChangePin = () => {
  const { cardDetail, setCurrentScreen } = useCardDetailContext();
  const instructionList = [
    <GothamRegular>
      Go to ATM UOB, enter your card and current PIN.
    </GothamRegular>,
    <GothamRegular>
      Select{" "}
      <TextInlineMedium>Other Transactions {">"} Change PIN</TextInlineMedium>
    </GothamRegular>,
    <GothamRegular>
      Input your <TextInlineMedium>current PIN</TextInlineMedium>
    </GothamRegular>,
    <GothamRegular>
      Input your <TextInlineMedium>new PIN</TextInlineMedium>
    </GothamRegular>,
    <GothamRegular>
      Input your <TextInlineMedium>new PIN</TextInlineMedium> once more to
      confirm the change
    </GothamRegular>,
  ];

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
    },
    title: {
      color: colors.neutral900,
      fontSize: "24px",
    },
    list: {
      border: `1px solid ${colors.neutral500}`,
      borderRadius: "4px",
      padding: "24px 12px 12px",
    },
  };

  return (
    <div style={styles.container}>
      <GothamMedium style={styles.title}>How to change card PIN</GothamMedium>
      <CardDisplay cardDetail={cardDetail} isSmall woReveal />
      <BulletList
        list={instructionList}
        type="filled"
        size="small"
        style={styles.list}
      />
      <UnderstandButton setCurrentScreen={setCurrentScreen} />
    </div>
  );
};

export default PhysicalChangePin;
