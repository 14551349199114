import React, { createContext, useContext, useEffect, useState } from "react";
export const localStorageKey = "guidance_mode"
   
const DemoContext = createContext({
  guidanceState: {},
  setGuidanceState: () => {},
});

const defaultGuidanceState = {
  isGuidanceMode: false,
  isTourRunning: false, 
  guidanceFunction: () => {},
  start: () => {},
  stop: () => {},
  guided : false
};

export const DemoProvider = ({ children }) => {
  const [guidanceState, setGuidanceState] = useState(defaultGuidanceState);

  const values = {
    guidanceState,
    setGuidanceState,
  };

  useEffect(() => {
    const val = localStorage.getItem(localStorageKey)
    if (!val) return;
    setGuidanceState(prev =>({
      ...prev, 
      guided: !!val
    }))
  }, [])

  return <DemoContext.Provider value={values}>{children}</DemoContext.Provider>;
};

export const useDemo = () => {
  const { guidanceState, setGuidanceState } =
    useContext(DemoContext) || {};

  return {
    guidanceState,
    setGuidanceState,
  };
};
