import { useRouter } from "next/router";
import { getUserRole } from "../../../../contexts/AuthContext";
import { MixedFont } from "../components";

const useCategoryWelcomingUpdates = () => {
  const { pathname } = useRouter();

  const { isEmployee } = getUserRole();

  switch (pathname) {
    case "/transactions":
      return [
        {
          name: "New column for Category",
          imgSrc: "/images/category-column-update.png",
          description: (
            <MixedFont
              sentences={[
                {
                  content: "Category",
                  isBolder: true,
                },
                {
                  content:
                    " help to keep your transactions well-organized. You can also filter transactions based on category.",
                },
              ]}
            />
          ),
        },
        {
          name: "Transaction with no category",
          imgSrc: "/images/category-alert-update.png",
          description: (
            <MixedFont
              sentences={[
                {
                  content: "If you set categories as ",
                },
                {
                  content: "required",
                  isBolder: true,
                },
                {
                  content:
                    ", any transactions with no category will be marked as ",
                },
                {
                  content: "Missing Details",
                  isBolder: true,
                },
                {
                  content: " in Task.",
                },
              ]}
            />
          ),
        },
        !isEmployee && {
          name: "Export transactions",
          imgSrc: "/images/category-export-update.png",
          description: (
            <MixedFont
              sentences={[
                {
                  content:
                    "When exporting transactions, your file will have an additional column for ",
                },
                {
                  content: "Category.",
                  isBolder: true,
                },
              ]}
            />
          ),
        },
      ];

    case "/task":
      return [
        {
          name: "Missing Category",
          imgSrc: "/images/category-missing-details-update.png",
          description: (
            <MixedFont
              sentences={[
                {
                  content: "If categories are set to be ",
                },
                {
                  content: "required",
                  isBolder: true,
                },
                {
                  content: ", transactions with no category will appear as ",
                },
                {
                  content: "Missing Details",
                  isBolder: true,
                },
                {
                  content: " along with missing receipt and description.",
                },
              ]}
            />
          ),
        },
        {
          name: "Select Transaction Category",
          imgSrc: "/images/category-select-update.png",
          description: (
            <MixedFont
              sentences={[
                {
                  content:
                    "To resolve the missing category, you can go to each transaction and select a suitable category for each.",
                },
              ]}
            />
          ),
        },
      ];

    default:
      break;
  }
};

export default useCategoryWelcomingUpdates;
