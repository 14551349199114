import { JackIcons } from "../../../assets/jackIcons/parent";
import React, { useEffect, useRef, useState } from "react";
import { colors, jackColors } from "../../../assets/colors";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { useRouter } from "next/router";
import {
  LogoBankVA,
  TextWithIcon,
  bankNameMask,
  dateOnlyIso,
  font10,
  font12,
  font14,
  font20,
  font24,
  icon16,
  icon20,
  iconCustom,
  textEllipsis,
  vaFormatter,
} from "../common/utils";
import { TransactionsFilters } from "../../transactionsComponents/transactions/filters/parent";
import EmptyList from "../common/emptyList";
import { isEmpty } from "lodash";
import { TableJackSimple } from "../../../components/Table/parent";
import { TableBodySkeleton } from "../../../components/Shimmer";
import { HoverComponent, VATableItem, time } from "../common/tableItem";
import { StatusLabelJack } from "../../../components/StatusLabel";
import {
  useBankDetails,
  useDeactivateVa,
  useEditVa,
  useFetchVA,
} from "../common/hooks";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import {
  LongDateFormatter,
  applyRegexOnlyNumbers,
  combineDateAndTimeToIso,
  customSum,
  dateAndHoursFormatter,
  dateFormatter,
  dateOnlyGetHour24Format,
  digitDateFormatterAndTime,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import ViewportList from "react-viewport-list";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { useModalHook } from "../../../components/Modals";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import {
  NumberFieldJack,
  TextFieldJack,
  TextFieldJackHelp,
} from "../../../components/inputs/textfield";
import { useForm } from "react-hook-form";
import BottomContainer from "../../cardPageComponents/components/CardModal/components/BottomContainer";
import { SelectionJack } from "../../../components/inputs/selection";
import { schemaGeneratorKYB } from "../../kybJackComponents/details/yup";
import { DateInputJackState } from "../../../components/inputs/date/parent";
import {
  generateTimeOptions,
  generateTimeOptionsComplex,
  todayChecker,
} from "../create/parents";
import deactivateImg from "../../../assets/images/back-previous-page.svg";
import { StatusAccountIconJack } from "../../../components/Status/JackStatusIcon";
import { SubTextComponent } from "../../bankAccountSettingComponents/tools/helpers";
import Skeleton from "react-loading-skeleton";
import { canUpdateCategoryDecider } from "../../categoryComponents/general/helpers";
import { useMutation } from "../../../tools/api";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import styles from "../va.module.css";

export const allowedPaymentDecider = (usageLimit) => {
  if (usageLimit < 0) return "Unlimited";
  if (usageLimit < 2) return "Single Payment";
  // return `${usageLimit} Payments`
  return `Unlimited`;
};

const VirtualAccountDetailsComponent = () => {
  const [deactivateId, setDeactivateId] = useState(null);

  const { push, query } = useRouter();
  const { id } = query;

  const { successSnackBar } = ToasterHook();

  const { isOpen: isDeactivateModalOpen, toggle } = useModalHook();
  const { isOpen: isEditModalOpen, toggle: toggleEditModal } = useModalHook();

  const { data, loading, nextPage, refetch, afterDelete } = useFetchVA({
    type: "details",
    detailUrl: `/collection_virtual_accounts/${id}?per_page=1`,
  });

  const { mutation: mutationDeactivateVa } = useDeactivateVa({
    vaId: deactivateId,
    afterSuccess: (res) => {
      const vaData = res?.data?.data;
      successSnackBar({ msg: `${vaData?.number} have been deactivated` });
      refetch();
    },
  });

  const handleDeactivateVa = () => {
    if (!deactivateId) return null;
    return mutationDeactivateVa();
  };

  const {
    closedTypeAmount,
    expiryDate,
    trxExpirationDate,
    referenceId,
    displayName,
    vaNumber,
    bankName,
    status,
    actualStatus,
    isOpen,
    transactions,
    adminFee,
  } = vaFormatter(data);

  const { updated_at } = data;

  const arrayOfAmounts = transactions?.map((trx) => Number(trx.amount));

  /* 
  dev comment:
  Admin fee can be changed in the VA level, but once it is used in a trx, it cannot be changed in the trx detail.
  This will cause discrepancies between VA details' total received vs total amount in the transaction table in the details page.
  By doing this change, the total of admin fees will be calculated from the actual transactions themselves instead of the VA fee from BE
  */

  const arrayOfAdminFees = transactions?.map((trx) => Number(trx.fee) || 0);
  const totalSettlementAmount = customSum(
    isEmpty(arrayOfAmounts) ? [0] : arrayOfAmounts
  );

  const totalAdminFee = customSum(
    isEmpty(arrayOfAdminFees) ? [0] : arrayOfAdminFees
  );
  // const totalAdminFee = transactions?.length * adminFee;
  const totalReceived = totalSettlementAmount - totalAdminFee;

  const statusLC = status?.toLowerCase();
  const isTrxExpired = actualStatus === "static_trx_expired";
  const isLimitReached = actualStatus === "limit_reached";
  const showBannerStatuses = ["expired", "need update", "deactivated"];
  const showBanner = showBannerStatuses.some((el) => el === statusLC);

  const isNeverExpired = expiryDate === "never";
  const usageLimit = data?.usage_limit;
  const transactionExpiration = !!trxExpirationDate
    ? trxExpirationDate
    : "Never Expires";

  const isTrxNeverExpire = transactionExpiration === "Never Expires";

  const isDeactivated = status?.toLowerCase() === "deactivated";
  const isVaExpired = status?.toLowerCase() === "expired";

  const { icon, iconSize } = useBankDetails(bankName);

  const handleCopy = () => {
    navigator.clipboard.writeText(vaNumber);
    successSnackBar({ msg: "VA Number copied" });
  };

  const vaInfoItems = [
    { title: "VA Type", value: isOpen ? "Open Amount" : "Closed Amount" },
    {
      title: "Status",
      value: <StatusLabelJack status={status} isOutline woTextFormatter />,
    },
    {
      title: "Payments allowed",
      titleWarning: isLimitReached ? (
        <HoverComponent
          style={{ display: "inline-block" }}
          index={0}
          hoverText="All payments has been received"
          hoverComponent={
            <JackIcons name="info-outline" fill={"#FCB037"} style={icon16} />
          }
        />
      ) : null,
      value: allowedPaymentDecider(usageLimit),
    },
    { title: "Reference ID", value: referenceId },
    {
      title: "Amount",
      value: `IDR ${formatCurrencyNoDecimal(closedTypeAmount)}`,
    },
    {
      title: "Payment due date",
      titleWarning: isTrxExpired ? (
        <HoverComponent
          style={{ display: "inline-block" }}
          index={0}
          hoverText="This payment due date has passed."
          hoverComponent={
            <JackIcons name="info-outline" fill={"#FCB037"} style={icon16} />
          }
        />
      ) : null,
      value: isTrxNeverExpire
        ? "-"
        : digitDateFormatterAndTime(transactionExpiration, "WIB"),
    },
  ];

  const vaInfoContents = isOpen
    ? vaInfoItems.filter((obj) => obj.title !== "Amount")
    : vaInfoItems;

  const vaHeaderBottomStyle = {
    width: 200,
  };

  const expiryDateStyle = {
    ...font14,
    ...textEllipsis,
    maxWidth: 140,
  };

  useEffect(() => {
    if (!deactivateId) return;
    toggle();
  }, [deactivateId]);

  useEffect(() => {
    if (!id) return;
    refetch();
  }, [id]);

  return (
    <>
      <div
        style={{
          padding: 32,
          display: "flex",
          flexDirection: "column",
          rowGap: 24,
        }}
      >
        <div
          // BACK BUTTON
          style={{
            marginTop: 32,
            display: "flex",
            gap: 8,
            cursor: "pointer",
          }}
          onClick={() => push("/virtual-account")}
        >
          <JackIcons
            name="arrow-back"
            fill={jackColors.neutral900}
            style={{ width: 20, height: 20 }}
          />
          <GothamMedium style={{ fontSize: 14, lineHeight: "20px" }}>
            Back
          </GothamMedium>
        </div>
        {showBanner && (
          <StatusBannerDecider
            status={statusLC}
            actualStatus={actualStatus}
            updatedAt={updated_at}
            onClickButton={() => toggleEditModal()}
            withButton
          />
        )}
        <div
          // VA DETAILS
          style={{
            display: "flex",
            columnGap: 24,
            height: 216,
            marginBottom: 8,
          }}
        >
          <div
            // VA Header
            style={{
              width: 720,
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: "4px",
              padding: 20,
              display: "flex",
              flexDirection: "column",
              rowGap: 20,
            }}
          >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <div>
                <GothamMedium
                  style={{
                    ...font20,
                    ...textEllipsis,
                    maxWidth: 575,
                    marginBottom: 4,
                  }}
                >
                  {displayName}
                </GothamMedium>
                <div style={{ display: "flex", columnGap: 4 }}>
                  <GothamLight
                    style={{ ...font14, color: jackColors.neutral700 }}
                  >
                    {vaNumber || "-"}
                  </GothamLight>
                  <HoverComponent
                    index={id}
                    hoverText={`Copy`}
                    hoverComponent={
                      <div
                        onClick={() => handleCopy()}
                        style={{ cursor: "pointer" }}
                      >
                        <JackIcons
                          name="file-copy"
                          fill={jackColors.neutral700}
                          style={{ width: 20, height: 20 }}
                        />
                      </div>
                    }
                  />
                </div>
              </div>

              {!isDeactivated && (
                <div style={{ display: "flex", columnGap: 8 }}>
                  {!isVaExpired && (
                    <ButtonJack
                      style={{ padding: 0, minWidth: 34, ...iconCustom(34) }}
                      type="outline"
                      onClick={() => toggleEditModal()}
                    >
                      <JackIcons
                        name="edit-outline"
                        fill={jackColors.neutral900}
                        style={icon20}
                      />
                    </ButtonJack>
                  )}
                  <ButtonJack
                    style={{ padding: 0, minWidth: 34, ...iconCustom(34) }}
                    type="outline"
                    onClick={() => setDeactivateId(id)}
                  >
                    <JackIcons
                      name="archive-outline"
                      fill={"#E73126"}
                      style={icon20}
                    />
                  </ButtonJack>
                </div>
              )}
            </div>
            <div
              style={{
                borderBottom: `1px solid ${jackColors.neutral500}`,
                width: "100%",
              }}
            />
            <div
              style={{
                height: "100%",
                display: "flex",
                gap: 20,
              }}
            >
              <div style={vaHeaderBottomStyle}>
                <GothamLight
                  style={{
                    ...font12,
                    marginBottom: 8,
                    color: jackColors.neutral700,
                  }}
                >
                  Bank Name
                </GothamLight>
                <div
                  style={{
                    display: "flex",
                    columnGap: 8,
                    alignItems: "center",
                  }}
                >
                  <img src={icon} alt="bank-icon" style={iconSize} />
                  <GothamMedium style={{ ...font14, ...textEllipsis }}>
                    {bankName}
                  </GothamMedium>
                </div>
              </div>
              <div
                style={{
                  borderRight: `1px solid ${jackColors.neutral500}`,
                }}
              />
              <div style={vaHeaderBottomStyle}>
                <TextWithIcon
                  type="light"
                  text={"Total Received"}
                  textStyle={{ ...font12, color: jackColors.neutral700 }}
                  divStyle={{ gap: 4, marginBottom: 8, alignItems: "center" }}
                  topIcon={
                    <JackIcons
                      name={"diagonal-arrow-left-down"}
                      fill={jackColors.neutral700}
                      style={icon16}
                    />
                  }
                />
                <div style={{ display: "flex", columnGap: 8 }}>
                  <GothamMedium style={font14}>
                    {`IDR ${formatCurrencyNoDecimal(totalReceived)}`}
                  </GothamMedium>
                </div>
              </div>
              <div
                style={{
                  borderRight: `1px solid ${jackColors.neutral500}`,
                }}
              />
              <div style={vaHeaderBottomStyle}>
                <TextWithIcon
                  type="light"
                  text={"Waiting Settlement"}
                  textStyle={{ ...font12, color: jackColors.neutral700 }}
                  divStyle={{ gap: 4, marginBottom: 8, alignItems: "center" }}
                  topIcon={
                    <JackIcons
                      name={"repayment"}
                      fill={jackColors.neutral700}
                      style={icon16}
                    />
                  }
                />
                <div style={{ display: "flex", columnGap: 8 }}>
                  <GothamMedium style={font14}>
                    {`IDR ${formatCurrencyNoDecimal(totalSettlementAmount)}`}
                  </GothamMedium>
                </div>
              </div>
              <div
                style={{
                  borderRight: `1px solid ${jackColors.neutral500}`,
                }}
              />
              <div style={vaHeaderBottomStyle}>
                <TextWithIcon
                  type="light"
                  text={"VA Expiry"}
                  textStyle={{ ...font12, color: jackColors.neutral700 }}
                  divStyle={{ gap: 4, marginBottom: 8, alignItems: "center" }}
                  topIcon={
                    <JackIcons
                      name={"clock-outline"}
                      fill={jackColors.neutral700}
                      style={icon16}
                    />
                  }
                />
                <div style={{ display: "flex", columnGap: 8 }}>
                  {isNeverExpired ? (
                    <GothamMedium style={expiryDateStyle}>
                      Never Expires
                    </GothamMedium>
                  ) : (
                    <HoverComponent
                      index={0}
                      hoverText={digitDateFormatterAndTime(expiryDate, "WIB")}
                      hoverComponent={
                        <GothamMedium style={expiryDateStyle}>
                          {digitDateFormatterAndTime(expiryDate, "WIB", true)}
                        </GothamMedium>
                      }
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
          <div
            // VA Info
            style={{
              width: "calc(100% - 740px)",
              border: `1px solid ${jackColors.neutral500}`,
              borderRadius: "4px",
              padding: 16,
              display: "flex",
              flexDirection: "column",
              gap: 16,
            }}
          >
            {vaInfoContents.map((item) => {
              const titleWarningExist = !!item.titleWarning;
              return (
                <div style={{ width: "100%", display: "flex" }}>
                  <div
                    style={{
                      display: "flex",
                      gap: 8,
                      alignItems: "center",
                      minWidth: 180,
                    }}
                  >
                    <GothamLight
                      style={{
                        ...font12,
                        color: jackColors.neutral700,
                      }}
                    >
                      {item?.title}
                    </GothamLight>
                    {titleWarningExist && item.titleWarning}
                  </div>
                  <GothamRegular
                    style={{
                      ...font12,
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      color: jackColors.neutral900,
                    }}
                  >
                    {item?.value}
                  </GothamRegular>
                </div>
              );
            })}
          </div>
        </div>
        <div
          // PAYMENT COUNT
          style={{
            padding: 8,
            borderBottom: `1px solid ${jackColors.neutral500}`,
          }}
        >
          <GothamMedium style={font14}>{`${
            transactions?.length || 0
          } Payment(s) Received`}</GothamMedium>
          <GothamLight style={font14}>
            List of payment received from virtual account
          </GothamLight>
        </div>
        <div
          // PAYMENT TABLE
          style={{ marginTop: -8 }}
        >
          <VAPaymentTable data={transactions} loading={loading} />
        </div>
      </div>
      <EditVaModal
        rawData={data}
        modalControl={{ isOpen: isEditModalOpen, toggle: toggleEditModal }}
        refetch={refetch}
      />
      <ConfirmationModalJack
        img={deactivateImg}
        modal={isDeactivateModalOpen}
        toggle={() => {
          setDeactivateId(null);
          toggle();
        }}
        title="Archive this virtual account?"
        text={
          <>
            This VA will be{" "}
            <span style={{ fontFamily: "GothamMedium" }}>deactivated</span> and
            won’t be able to receive payments anymore.
          </>
        }
        onClick={() => handleDeactivateVa()}
      />
    </>
  );
};

export default VirtualAccountDetailsComponent;

export const VAPaymentTable = ({ data = [], loading = false }) => {
  const [selectedItem, setSelectedItem] = useState(null);
  const { query } = useRouter();
  const { runQuery, searchbox, from_date, to_date } = query;

  const getDateOnly = (isoDate) => {
    return isoDate.split("T")[0];
  };

  const vaId = selectedItem?.collection_virtual_account_id;
  const trxId = selectedItem?.id;
  const isFiltered =
    searchbox !== undefined || from_date !== undefined || to_date !== undefined;

  const dateFilter = {
    label: "Date",
    name: "date",
    type: "date",
    icon: "calendar",
    queryNames: ["from_date", "to_date"],
    modalStyle: { overflow: "auto", height: 280 },
  };

  const activeFilters = ["date"];
  const filters = [dateFilter].filter((item) => item);

  const tableTotalWidth = 1136;
  const tableWidth = (number) => `${(number / tableTotalWidth) * 100}%`;
  const tbodyRef = useRef();

  const adminFeeLabel = (
    <div
      style={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        gap: 4,
      }}
    >
      Admin Fee
      <HoverComponent
        hoverText="This fee is deducted from the amount collected."
        style={{ padding: 12, width: 184 }}
        textAlign="left"
        hoverComponent={
          <JackIcons
            name={"info-outline"}
            fill={jackColors.neutral700}
            style={iconCustom(16)}
          />
        }
      />
    </div>
  );

  const headerTable = [
    {
      label: "Received at",
      width: tableWidth(100),
      textAlign: "left",
    },
    {
      label: "Transaction ID",
      width: tableWidth(390),
      textAlign: "left",
    },
    {
      label: "Status",
      width: tableWidth(150),
      textAlign: "left",
    },

    {
      label: "Amount Collected",
      width: tableWidth(160),
      textAlign: "left",
    },
    {
      label: adminFeeLabel,
      width: tableWidth(160),
      textAlign: "left",
    },
    {
      label: "Amount Received",
      width: tableWidth(160),
      textAlign: "right",
    },
  ];

  const filteredData = (data || [])?.filter(({ reference_id, created_at }) => {
    const createdDate = dateOnlyIso(created_at);
    const fromDate = from_date ? dateOnlyIso(from_date) : "";
    const toDate = to_date ? dateOnlyIso(to_date) : "";

    const searchboxCondition =
      !searchbox || reference_id?.toLowerCase().includes(searchbox);

    const fromDateCondition =
      !fromDate || !from_date || createdDate >= fromDate;
    const toDateCondition = !toDate || !to_date || createdDate <= toDate;

    return searchboxCondition && fromDateCondition && toDateCondition;
  });

  const tableData = isFiltered ? filteredData : data;

  return (
    <>
      <div style={{ padding: 0, marginBottom: 24 }}>
        <TransactionsFilters
          activeFilters={activeFilters}
          filters={filters}
          woAdd={true}
        />
      </div>
      {isEmpty(tableData) ? (
        <EmptyList menu="details" noButton />
      ) : (
        <div style={{ padding: 0 }}>
          <TableJackSimple
            tableStyle={{ tableLayout: "auto" }}
            headerTable={headerTable}
            tbodyRef={tbodyRef}
            bodyComponent={
              <>
                {!!tableData.length && (
                  <ViewportList
                    viewportRef={tbodyRef}
                    items={tableData}
                    itemMinSize={100}
                    margin={8}
                    overscan={10}
                  >
                    {(item, index) => {
                      const vaId = item.id;
                      return (
                        <VATableItem
                          index={index}
                          item={item}
                          key={index}
                          isLast={index === tableData.length - 1}
                          onClick={(item) => setSelectedItem(item)}
                          isDetails
                          // isActive={selectedIds.includes(item.id)}
                          // onClickCheckbox={handleClickItem}
                        />
                      );
                    }}
                  </ViewportList>
                )}
                {loading && <TableBodySkeleton columns={13} />}
              </>
            }
          />
        </div>
      )}
      {selectedItem && (
        <VaTransactionModal
          rawData={selectedItem}
          trxId={trxId}
          vaId={vaId}
          modalControl={{
            isOpen: !!selectedItem,
            toggle: () => setSelectedItem(null),
          }}
        />
      )}
    </>
  );
};

export const StatusBannerDecider = ({
  status,
  actualStatus,
  customBannerStyle,
  text,
  updatedAt = new Date(),
  isWarning = false,
  withButton = false,
  onClickButton = () => {},
}) => {
  const isExpired = status === "expired";
  const isDeactivated = status === "deactivated";
  const isDueDatePassed = actualStatus === "static_trx_expired";
  const isLimitReached = actualStatus === "limit_reached";
  const isNeedUpdate =
    status === "need update" || isLimitReached || isDueDatePassed;
  const isComplete = actualStatus === "complete";

  const textDecider = () => {
    if (isComplete)
      return "All payments have been received. This VA is now marked as expired.";
    if (isExpired) return `This Virtual Account has passed its expiry date.`;
    if (isNeedUpdate) {
      if (isDueDatePassed) {
        return `Payment due date has passed. Please update to receive more payments.`;
      }
      if (isLimitReached) {
        return `All payments allowed have been received. Please update to receive more payments.`;
      }
    }
    if (isDeactivated)
      return `You have deactivated this Virtual Account on ${digitDateFormatterAndTime(
        String(updatedAt)
      )}`;
  };

  const colorDecider = (place) => {
    const isBanner = place === "banner";
    const isBorder = place === "border";

    if (isWarning)
      return isBanner ? "#FFF6E6" : isBorder ? "#FCB037" : "#FCB037";

    if ((isExpired || isDeactivated) && !isComplete)
      return isBanner
        ? jackColors.neutral400
        : isBorder
        ? jackColors.neutral600
        : jackColors.neutral700;

    if (isNeedUpdate)
      return isBanner ? "#FFF6E6" : isBorder ? "#FCB037" : "#FCB037";

    if (isComplete)
      return isBanner ? "#CAF5DD80" : isBorder ? "#06AC4E" : "#06AC4E";
  };

  const bannerStyle = {
    height: 44,
    width: "100%",
    padding: 12,
    display: "flex",
    gap: 8,
    backgroundColor: colorDecider("banner"),
    border: `1px solid ${colorDecider("border")}`,
    borderRadius: 4,
    ...customBannerStyle,
  };

  if (isWarning)
    return (
      <div style={bannerStyle}>
        <JackIcons name="info" fill={colorDecider("logo")} style={icon20} />
        {text}
      </div>
    );

  return (
    <div style={bannerStyle}>
      <JackIcons
        name={isComplete ? "checkmark-circle-2" : "info"}
        fill={colorDecider("logo")}
        style={icon20}
      />
      <GothamMedium style={{ color: jackColors.neutral800, ...font12 }}>
        {textDecider()}
      </GothamMedium>
      {withButton && isNeedUpdate && (
        <GothamMedium
          style={{
            color: jackColors.neutral800,
            ...font12,
            marginLeft: "auto",
            cursor: "pointer",
          }}
          onClick={() => onClickButton()}
        >
          Update
        </GothamMedium>
      )}
    </div>
  );
};

export const EditVaModal = ({
  rawData = {},
  modalControl = {},
  refetch = () => {},
}) => {
  const { isOpen, toggle } = modalControl;

  const { successSnackBar } = ToasterHook();

  const vaId = rawData?.id;

  const { mutation: mutationEditVa } = useEditVa({
    vaId,
    afterSuccess: () => {
      successSnackBar({ msg: "VA details have been updated!" });
      refetch();
      toggle();
    },
  });

  const handleEdit = (submitPayload) => {
    const {
      username_display,
      bank,
      reference_id,
      is_open,
      amount,
      usage_limit,
      is_lifetime,
      expiration_date,
      trx_expiration_date,
      limit,
      exp_date,
      exp_time,
      due_date_exist,
      trx_exp_date,
      trx_exp_time,
    } = submitPayload;

    const realUsageLimitDecider = (usageLimitType, limitAmount) => {
      const isSingle = usageLimitType === "single";
      const isMultiple = usageLimitType === "multiple";
      const isUnlimited = usageLimitType === "unlimited";

      if (isSingle) return 1;
      if (isUnlimited) return -1;
      if (isMultiple) return Number(limitAmount);
    };

    const bankName = bank?.value;
    const bankCode = bank?.code;
    const usageLimit = realUsageLimitDecider(usage_limit?.value, limit);
    const isLifetime = is_lifetime?.value;
    const realAmount = amount ? Number(amount) : 0;

    const vaExpExist = !isLifetime && !!exp_date && !isEmpty(exp_time);
    const dueDateExist =
      due_date_exist && !!trx_exp_date && !isEmpty(trx_exp_time);

    const vaExpDateISO = vaExpExist
      ? combineDateAndTimeToIso(exp_date, exp_time?.value)
      : "";

    const dueDateISO = dueDateExist
      ? combineDateAndTimeToIso(trx_exp_date, trx_exp_time?.value)
      : null;

    const editPayload = {
      username_display,
      bank: bankName,
      code: bankCode,
      reference_id,
      is_open,
      amount: realAmount,
      usage_limit: usageLimit,
      is_lifetime: isLifetime,
      expiration_date: vaExpDateISO,
      trx_expiration_date: dueDateISO,
    };

    mutationEditVa(editPayload);
  };

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      woClose
      array={[
        <>
          <EditVaModalComponent
            rawData={rawData}
            handleEdit={handleEdit}
            handleCancel={toggle}
          />
        </>,
      ]}
    />
  );
};

const EditVaModalComponent = ({
  rawData = {},
  handleEdit = () => {},
  handleCancel = () => {},
}) => {
  const {
    username_display,
    bank,
    reference_id,
    is_open,
    amount,
    usage_limit,
    is_lifetime,
    expiration_date,
    trx_expiration_date,
    status,
    prefix,
  } = rawData;

  const usageLimitOptions = [
    { label: "Single", value: "single" },
    // { label: "Multiple", value: "multiple" },
    { label: "Unlimited", value: "unlimited" },
  ];

  const vaExpiryOptions = [
    { label: "Never Expires", value: true },
    { label: "Custom Expiry Date", value: false },
  ];

  const { data: bankData, loading } = useFetchVA({
    type: "va_banks",
    resultFormatter: (res) => {
      const result = res?.data.map((bank) => {
        return { label: bank.name, code: bank.code, value: bank.name };
      });
      return result;
    },
  });

  const bankOptions = (bankData || []).map((bank) => {
    return { label: bank.name, code: bank.code, value: bank.name };
  });

  const bankToEdit = bankOptions?.find(({ value }) => value === bank);

  const usageLimitDecider = (usage) => {
    if (usage === 1) return usageLimitOptions[0];
    // if (usage === -1) return usageLimitOptions[2];
    if (usage === -1) return usageLimitOptions[1];
    return usageLimitOptions[1];
  };

  const isLifetimeDecider = (isLifetime) => {
    if (isLifetime === "") return "";
    if (Boolean(isLifetime)) return vaExpiryOptions[0];
    return vaExpiryOptions[1];
  };

  const expirationDecider = (expDate, type) => {
    if (!expDate) return "";
    const isDate = type === "date";
    const isTime = type === "time";
    const date = new Date(expDate) || new Date();

    if (isDate) return date;
    if (isTime) {
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      const time = `${hours}:${minutes}`;
      return { label: time, value: time };
    }
  };

  const defaultValues = {
    username_display: username_display ? username_display : "",
    bank: bankToEdit,
    reference_id: reference_id ? reference_id : "",
    is_open: is_open ? is_open : false,
    amount: amount ? String(amount) : "0",
    usage_limit: usage_limit ? usageLimitDecider(usage_limit) : "",
    is_lifetime: isLifetimeDecider(is_lifetime),
    expiration_date: expiration_date ? expiration_date : "",
    trx_expiration_date: trx_expiration_date ? trx_expiration_date : "",
    limit:
      usageLimitDecider(usage_limit)?.value === "unlimited"
        ? ""
        : String(usage_limit),
    exp_date: expirationDecider(expiration_date, "date"),
    exp_time: expirationDecider(expiration_date, "time"),
    due_date_exist: Boolean(trx_expiration_date),
    trx_exp_date: expirationDecider(trx_expiration_date, "date"),
    trx_exp_time: expirationDecider(trx_expiration_date, "time"),
    prefix: !!prefix ? `${prefix} -` : "",
  };

  if (loading) return null;

  return (
    <EditFormVa
      defaultValues={defaultValues}
      bankOptions={bankOptions}
      handleCancel={handleCancel}
      handleEdit={handleEdit}
      usageLimitOptions={usageLimitOptions}
      vaExpiryOptions={vaExpiryOptions}
      status={status}
    />
  );
};

const EditFormVa = ({
  defaultValues = {},
  bankOptions = [],
  usageLimitOptions = [],
  vaExpiryOptions = [],
  status = "",
  handleCancel = () => {},
  handleEdit = () => {},
}) => {
  const useFormObj = useForm({ defaultValues });
  const { handleSubmit, setValue, getValues, watch, register } = useFormObj;

  const isLifetime = watch("is_lifetime")?.value;
  const isOpen = watch("is_open");
  const isMultiple = watch("usage_limit")?.value === "multiple";
  const currentExpDate = watch("exp_date");
  const currentExpTime = watch("exp_time")?.value?.split(":")[0];
  const dueDate = watch("due_date_exist");
  const currentDueDate = watch("trx_exp_date");
  const currentDueTime = watch("trx_exp_time");
  const watchLimit = watch("limit");
  const hasPrefix = !!watch("prefix");
  const isNoDate = !currentExpDate && !currentDueDate;
  const showBanner =
    status === "static_trx_expired" || status === "limit_reached";

  const isTrxExpired = status === "static_trx_expired";
  const isLimitReached = status === "limit_reached";

  const warningTextDecider = (stat) => {
    if (!showBanner) return null;
    if (stat === "limit_reached")
      return "All payments have been received. Update to receive more.";
    return "Payment due date has passed. Please update or turn it off";
  };

  const timeOptions = (type) => {
    const isVaExp = type === "va";
    const isTrx = type === "trx";
    const currentDate = isVaExp ? currentExpDate : currentDueDate;
    const isToday = todayChecker(new Date(currentDate));
    const isDueOnVaExpDate =
      new Date(currentDueDate).getDate() === new Date(currentExpDate).getDate();
    if (isNoDate) return generateTimeOptions();
    if (isTrx) {
      if (!isToday && isDueOnVaExpDate)
        return generateTimeOptions(0, Number(currentExpTime));
    }

    return generateTimeOptionsComplex(new Date(currentDate));
  };

  const buttonStyle = {
    width: 184,
  };

  const colorDecider = (type) => {
    if (type == "disabled") return jackColors.neutral600;
    return jackColors.neutral900;
  };

  useEffect(() => {
    register("is_open");
    register("expiration_date");
    register("is_lifetime");
    register("exp_date");
    register("exp_time");
    register("due_date_exist");
    register("trx_expiration_date");
    register("trx_exp_date");
    register("trx_exp_time");
    register("limit");
    register("prefix");
  }, []);

  useEffect(() => {
    const newValue = applyRegexOnlyNumbers(watchLimit || "");
    setValue("limit", newValue);
  }, [watchLimit]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
        overflowY: "none",
        marginBottom: 80,
      }}
    >
      <GothamMedium style={{ ...font24 }}>Edit Virtual Account</GothamMedium>
      {showBanner && (
        <StatusBannerDecider
          isWarning
          text={
            <GothamMedium style={{ ...font12 }}>
              {warningTextDecider(status)}
            </GothamMedium>
          }
        />
      )}
      <form
        // style={{ display: "flex", flexDirection: "column", gap: 0 }}
        onSubmit={handleSubmit((value) => {
          handleEdit({
            ...value,
            amount: isOpen ? defaultValues.amount : value.amount,
            reference_id: defaultValues.reference_id,
          });
        })}
      >
        {/* <div style={{ display: "flex", alignItems: "center" }}>
          {hasPrefix && (
            <TextFieldJack
              name="prefix"
              label="Display Name"
              useFormObj={useFormObj}
              style={{ width: "30%" }}
              textFieldStyle={{
                display: "flex",
                justifyContent: "center",
                textAlign: "right",
              }}
              textFieldInputStyle={{ marginTop: 0 }}
              disabled
              required
            />
          )}
          <TextFieldJack
            name="username_display"
            label={hasPrefix ? null : "Display Name"}
            useFormObj={useFormObj}
            style={{ width: "100%" }}
            labelStyle={{ color: hasPrefix ? "transparent" : null }}
            required={!hasPrefix}
          />
        </div> */}
        <TextFieldJack
          name="username_display"
          label="Display Name"
          useFormObj={useFormObj}
          required
        />
        <SelectionJack
          name="bank"
          label="Bank Name"
          options={bankOptions}
          useFormObj={useFormObj}
          // defaultValue={bankToEdit}
          required
          isDisabled
        />
        <TextFieldJack
          name="reference_id"
          label={
            <>
              Reference ID{" "}
              <span>
                <GothamMedium style={{ ...font12, display: "inline-block" }}>
                  (Optional)
                </GothamMedium>
              </span>
            </>
          }
          useFormObj={useFormObj}
          helperText="Create a unique ID to identify payments for this VA."
          disabled
        />
        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: 8,
            marginBottom: 32,
          }}
        >
          <GothamRegular style={{ ...font12 }}>Type*</GothamRegular>
          <div
            style={{
              width: "100%",
              height: 40,
              backgroundColor: jackColors.neutral500,
              borderRadius: 4,
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 3,
              gap: 3,
              cursor: "not-allowed",
            }}
            // onClick={() => setValue("is_open", !isOpen)}
          >
            <div
              style={{
                width: "50%",
                height: "100%",
                backgroundColor: isOpen ? "white" : "transparent",
                borderRadius: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextWithIcon
                type="regular"
                text="Open Amount"
                textStyle={{ color: colorDecider("disabled") }}
                divStyle={{ gap: 4 }}
                topIcon={
                  <JackIcons
                    name="sticky-note"
                    fill={colorDecider("disabled")}
                    style={{ ...icon16 }}
                  />
                }
              />
            </div>
            <div
              style={{
                width: "50%",
                height: "100%",
                backgroundColor: !isOpen ? "white" : "transparent",
                borderRadius: 4,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <TextWithIcon
                type="regular"
                text="Closed Amount"
                textStyle={{ color: colorDecider("disabled") }}
                divStyle={{ gap: 4 }}
                topIcon={
                  <JackIcons
                    name="close-circle-outline"
                    fill={colorDecider("disabled")}
                    style={{ ...icon16 }}
                  />
                }
              />
            </div>
          </div>
          <TextFieldJackHelp
            msg="Payer can send any amount."
            helperTextStyle={{ marginTop: -5 }}
            isError={false}
          />
        </div>
        <NumberFieldJack
          name="amount"
          label="Amount"
          useFormObj={useFormObj}
          required={!isOpen}
          disabled={isOpen}
        />
        <div style={{ display: "flex", alignItems: "center", gap: 16 }}>
          {/* <div style={{ width: "70%" }}> */}
          <div style={{ width: "100%" }}>
            <SelectionJack
              name="usage_limit"
              label="Payments Allowed"
              options={usageLimitOptions}
              useFormObj={useFormObj}
              required
              customLabelStyle={isLimitReached ? { color: "#FCB037" } : {}}
              isDisabled={!isLimitReached}
              containerStyle={
                isLimitReached ? { border: "1px solid #FCB037" } : {}
              }
            />
          </div>
          {/* <div style={{ width: "auto" }}>
            <TextFieldJack
              name="limit"
              label="Max. Limit"
              useFormObj={useFormObj}
              required={isMultiple}
              disabled={!isLimitReached && !isMultiple}
              labelStyle={isLimitReached ? { color: "#FCB037" } : {}}
              textFieldStyle={
                isLimitReached ? { border: "1px solid #FCB037" } : {}
              }
              error={isLimitReached}
            />
          </div> */}
        </div>
        <SelectionJack
          name="is_lifetime"
          label="VA Expiry"
          options={vaExpiryOptions}
          useFormObj={useFormObj}
          isDisabled
        />
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            gap: 0,
          }}
        >
          <div style={{ width: "48%" }}>
            <DateInputJackState
              label="Date"
              dateFormat="dd/MM/yyyy"
              value={currentExpDate}
              minDate={new Date()}
              placeholder="Select Date"
              onChange={(e) => {
                setValue("exp_date", e);
              }}
              // disabled={isLifetime}
              disabled
            />
          </div>
          <div style={{ width: "48%" }}>
            <SelectionJack
              name="exp_time"
              label="Time"
              options={timeOptions("va")}
              useFormObj={useFormObj}
              required={!isLifetime}
              // isDisabled={isLifetime}
              isDisabled
            />
          </div>
        </div>

        <div
          style={{
            width: "100%",
            height: "fit-content",
            backgroundColor: "white",
            borderRadius: "4px",
            boxShadow: "0px 8px 16px -4px #16223314",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-between",
            gap: "12px",
            padding: 16,
            border: `1px solid ${jackColors.neutral500}`,
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              // justifyContent: "space-between",
              flexDirection: "column",
              // alignItems: "center",
              borderBottom: `1px solid ${jackColors.neutral500}`,
              padding: 8,
              gap: 8,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                // borderBottom: `1px solid ${jackColors.neutral500}`,
                // padding: 8,
                gap: 8,
              }}
            >
              <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
                <GothamMedium style={{ ...font14 }}>
                  Payment Due Date
                </GothamMedium>
                {isTrxExpired && (
                  <JackIcons
                    name="info-outline"
                    fill={"#FCB037"}
                    style={icon20}
                  />
                )}
              </div>
              <div
                style={{
                  width: 80,
                  height: 24,
                  backgroundColor: jackColors.neutral400,
                  borderRadius: 4,
                  position: "relative",
                  padding: 2,
                  display: "flex",
                  justifyContent: dueDate ? "flex-end" : "flex-start",
                  gap: 8,
                  cursor: isTrxExpired ? "pointer" : "not-allowed",
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isTrxExpired) return null;
                  if (dueDate) {
                    setValue("trx_exp_date", "");
                    setValue("trx_exp_time", "");
                  }
                  setValue("due_date_exist", !dueDate);
                }}
              >
                <div style={{ position: "absolute", left: 10 }}>
                  <GothamMedium
                    style={{
                      ...font14,
                      color: dueDate
                        ? jackColors.neutral600
                        : jackColors.neutral400,
                    }}
                  >
                    Off
                  </GothamMedium>
                </div>
                <div style={{ position: "absolute", right: 10 }}>
                  <GothamMedium
                    style={{
                      ...font14,
                      color: dueDate
                        ? jackColors.neutral400
                        : jackColors.neutral600,
                    }}
                  >
                    On
                  </GothamMedium>
                </div>
                <div
                  style={{
                    width: 36,
                    heigth: "100%",
                    backgroundColor: isTrxExpired
                      ? dueDate
                        ? "#06AC4E"
                        : `${jackColors.black34}`
                      : `${jackColors.neutral700}`,
                    padding: "2px 0px",
                    borderRadius: 4,
                  }}
                />
              </div>
            </div>
            <div
              style={{
                width: "90%",
              }}
            >
              <GothamLight
                style={{
                  ...font12,
                  color: jackColors.neutral800,
                  lineSpacing: "1px",
                }}
              >
                Set the payment cutoff date for transactions linked to this VA.{" "}
                <span
                  style={{
                    textDecoration: "underline",
                    fontWeight: "bolder",
                  }}
                >
                  Learn more
                </span>
              </GothamLight>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              gap: 8,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: 12,
                width: "100%",
                height: "fit-content",
                padding: "0px 8px",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "flex-start",
                  gap: 0,
                  height: 60,
                }}
              >
                <DateInputJackState
                  label="Date"
                  style={{ width: "48%", margin: 0 }}
                  value={isNoDate ? "" : currentDueDate}
                  dateFormat="dd/MM/yyyy"
                  minDate={new Date()}
                  maxDate={isNoDate ? "" : new Date(currentExpDate)}
                  placeholder={"Select Date"}
                  onChange={(e) => {
                    setValue("trx_exp_date", e);
                    setValue("trx_exp_time", "");
                  }}
                  disabled={!dueDate || !isTrxExpired}
                  customFieldStyle={
                    isTrxExpired ? { border: "1px solid #FCB037" } : {}
                  }
                  customLabelStyle={isTrxExpired ? { color: "#FCB037" } : {}}
                  isError={isTrxExpired}
                />
                <SelectionJack
                  divStyle={{ width: "48%" }}
                  name="trx_exp_time"
                  label="Time"
                  options={timeOptions("trx", Number(currentExpTime))}
                  useFormObj={useFormObj}
                  required={dueDate}
                  isDisabled={!dueDate || !isTrxExpired}
                  containerStyle={
                    isTrxExpired ? { border: "1px solid #FCB037" } : {}
                  }
                  customLabelStyle={isTrxExpired ? { color: "#FCB037" } : {}}
                />
              </div>
              <GothamLight
                style={{
                  ...font12,
                  color: jackColors.neutral700,
                }}
              >
                Payment after this date won’t be accepted.
              </GothamLight>
            </div>
          </div>
        </div>

        <BottomContainer
          style={{
            padding: 20,
            gap: "16px",
            borderTop: `1px solid ${colors.neutral500}`,
            backgroundColor: colors.neutral100,
            left: 0,
            display: "flex",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <ButtonJack
            style={buttonStyle}
            type="outline"
            onClick={(e) => {
              e.preventDefault();
              handleCancel();
            }}
          >
            Cancel
          </ButtonJack>
          <ButtonJack style={buttonStyle} buttonType="submit">
            Save Change
          </ButtonJack>
        </BottomContainer>
      </form>
    </div>
  );
};

export const VaTransactionModal = ({
  rawData = {},
  modalControl = {},
  vaId = null,
  trxId = null,
  originatorId = null,
}) => {
  const fromTransactionPage = !!originatorId;

  const { isOpen, toggle } = modalControl;

  const {
    data: trxData,
    loading: loadingTrx,
    refetch: refetchTrx,
  } = useFetchVA({
    type: "details",
    detailUrl: `/collection_va_trxes/${
      fromTransactionPage ? originatorId : trxId
    }`,
  });

  const {
    data: vaData,
    loading,
    refetch,
  } = useFetchVA({
    type: "details",
    detailUrl: `/collection_virtual_accounts/${vaId}?per_page=1`,
  });

  const {
    id,
    amount,
    state,
    reference_id: transactionId,
    created_at,
    category_id,
  } = fromTransactionPage ? trxData : rawData;

  const {
    username_display: displayName,
    number,
    reference_id: vaReferenceId,
    bank,
    fee: adminFee,
  } = vaData || {};

  const maskedDisplayName = loading ? <Skeleton width={"50%"} /> : displayName;
  const trxAmount = `IDR ${formatCurrencyNoDecimal(amount)}`;
  // const adminFee = 2500;
  const adminFeeAmount = `IDR ${formatCurrencyNoDecimal(adminFee)}`;
  const totalReceived = amount - adminFee;
  const totalReceivedAmount = `IDR ${formatCurrencyNoDecimal(totalReceived)}`;

  const details = {
    id,
    state,
    categoryId: category_id,
    maskedDisplayName,
    trxAmount,
    adminFeeAmount,
    totalReceivedAmount,
  };

  // const receivedAt = created_at
  const receivedAt = `${LongDateFormatter(
    created_at
  )}, ${dateOnlyGetHour24Format(created_at)}`;

  const { successSnackBar } = ToasterHook();

  const handleCopy = () => {
    navigator.clipboard.writeText(number);
    successSnackBar({ msg: "VA Number copied" });
  };

  const vaNumber = (
    <SubTextComponent
      text={
        <div style={{ display: "flex", gap: 4, justifyContent: "center" }}>
          <HoverComponent
            index={trxId}
            hoverText={`Copy`}
            hoverComponent={
              <div onClick={handleCopy} style={{ cursor: "pointer" }}>
                <JackIcons
                  name="file-copy"
                  fill={jackColors.neutral700}
                  style={{ width: 16, height: 16 }}
                />
              </div>
            }
          />
          <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
            {number}
          </GothamRegular>
        </div>
      }
      subText={bank}
      customSubStyle={{ ...font10, textAlign: "right" }}
      isOwnComponent
    />
  );

  const transactionDetails = [
    { title: "Reference ID", value: vaReferenceId },
    { title: "Received at", value: receivedAt },
    { title: "Transaction ID", value: transactionId },
    { title: "VA number", value: vaNumber },
  ];

  return (
    <RightModal
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <TrxModalComponent
          transactionDetails={transactionDetails}
          details={details}
        />,
      ]}
    />
  );
};

const TrxModalComponent = ({ transactionDetails, details }) => {
  const {
    id,
    state,
    categoryId,
    maskedDisplayName,
    trxAmount,
    adminFeeAmount,
    totalReceivedAmount,
  } = details;

  const [selectedCategory, setSelectedCategory] = useState(null);
  const { successSnackBar } = ToasterHook();
  const { categories } = useConstants();

  const { mutation: updateCategory } = useMutation({
    url: `/collection_va_trxes/${id}`,
    afterSuccess: () => {
      successSnackBar({ msg: "Transaction category has been updated." });
    },
  });

  const onCategorySelect = (category) => {
    setSelectedCategory(category);
    updateCategory({ category_id: category?.id });
  };

  useEffect(() => {
    if (selectedCategory) return;
    const category = (categories ?? []).find(({ id }) => id === categoryId);
    if (category) setSelectedCategory(category);
  }, [categories, selectedCategory, categoryId]);

  const canUpdateCategory = canUpdateCategoryDecider({
    deps: { state },
  });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
        // overflowY: "scroll",
        marginBottom: 80,
      }}
    >
      <StatusAccountIconJack state={"collection"} />

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 8,
        }}
      >
        <GothamRegular style={{ ...font14 }}>
          Payment received on VA: {maskedDisplayName}
        </GothamRegular>
        <GothamMedium style={{ ...font24 }}>{trxAmount}</GothamMedium>
        <StatusLabelJack status={state} isOutline />
      </div>

      {canUpdateCategory && (
        <CategorySelection
          isForTrxDetails
          style={{ marginBottom: 0 }}
          showAllCategories={true}
          selectedCategory={selectedCategory ?? {}}
          onSelect={(category) => {
            onCategorySelect(category);
          }}
        />
      )}

      <div
        style={{
          padding: 12,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: 4,
        }}
      >
        {transactionDetails.map((item) => {
          return (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "flex-start",
              }}
            >
              <GothamRegular
                style={{ ...font12, color: jackColors.neutral700 }}
              >
                {item.title}
              </GothamRegular>
              <GothamRegular
                style={{ ...font12, color: jackColors.neutral900 }}
              >
                {item.value}
              </GothamRegular>
            </div>
          );
        })}
      </div>

      <div
        style={{
          padding: 12,
          display: "flex",
          flexDirection: "column",
          gap: 16,
          border: `1px solid ${jackColors.neutral500}`,
          borderRadius: 4,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <GothamRegular style={{ ...font12, color: jackColors.neutral700 }}>
            Amount collected
          </GothamRegular>
          <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
            {trxAmount}
          </GothamRegular>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            paddingBottom: 16,
            borderBottom: `1px solid ${jackColors.neutral500}`,
          }}
        >
          <div style={{ display: "flex", gap: 8 }}>
            <JackIcons
              name="minus-circle"
              style={{ ...icon16 }}
              fill={jackColors.neutral500}
            />
            <GothamRegular style={{ ...font12, color: jackColors.neutral700 }}>
              Admin fee
            </GothamRegular>
          </div>
          <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
            {adminFeeAmount}
          </GothamRegular>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
          }}
        >
          <GothamRegular style={{ ...font12, color: jackColors.neutral700 }}>
            Amount received
          </GothamRegular>
          <GothamRegular style={{ ...font12, color: jackColors.neutral900 }}>
            {totalReceivedAmount}
          </GothamRegular>
        </div>
      </div>
    </div>
  );
};
