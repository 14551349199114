const CustomizableBenefit = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2998_190999)">
        <path
          d="M5.83789 8.32841C5.83789 7.49641 6.51789 6.81641 7.34989 6.81641H34.1579C34.9899 6.81641 35.6699 7.49641 35.6699 8.32841C35.6699 9.16041 34.9899 9.84041 34.1499 9.84041H7.34989C6.50989 9.84041 5.83789 9.16841 5.83789 8.32841Z"
          fill="#B3F600"
        />
        <path
          d="M5.83789 20.0003C5.83789 19.1683 6.51789 18.4883 7.34989 18.4883H34.1579C34.9899 18.4883 35.6699 19.1683 35.6699 20.0003C35.6699 20.8323 34.9899 21.5123 34.1579 21.5123H7.34989C6.50989 21.5123 5.83789 20.8323 5.83789 20.0003Z"
          fill="#B3F600"
        />
        <path
          d="M5.83789 31.6722C5.83789 30.8402 6.51789 30.1602 7.34989 30.1602H34.1579C34.9899 30.1602 35.6699 30.8402 35.6699 31.6722C35.6699 32.5042 34.9899 33.1842 34.1579 33.1842H7.34989C6.50989 33.1842 5.83789 32.5042 5.83789 31.6722Z"
          fill="#B3F600"
        />
        <path
          d="M28.0591 11.7216C29.9327 10.9455 30.8225 8.79747 30.0464 6.92381C29.2702 5.05015 27.1222 4.16041 25.2485 4.93652C23.3749 5.71263 22.4851 7.8607 23.2612 9.73436C24.0373 11.608 26.1854 12.4978 28.0591 11.7216Z"
          fill="#B3F600"
        />
        <path
          d="M16.3721 23.3935C18.2457 22.6174 19.1355 20.4693 18.3593 18.5957C17.5832 16.722 15.4352 15.8323 13.5615 16.6084C11.6878 17.3845 10.7981 19.5326 11.5742 21.4062C12.3503 23.2799 14.4984 24.1696 16.3721 23.3935Z"
          fill="#B3F600"
        />
        <path
          d="M22.7888 35.0654C24.6625 34.2893 25.5522 32.1412 24.7761 30.2676C24 28.3939 21.8519 27.5042 19.9783 28.2803C18.1046 29.0564 17.2149 31.2044 17.991 33.0781C18.7671 34.9518 20.9151 35.8415 22.7888 35.0654Z"
          fill="#B3F600"
        />
      </g>
      <defs>
        <clipPath id="clip0_2998_190999">
          <rect
            width="32"
            height="32"
            fill="white"
            transform="translate(4.75 4)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default CustomizableBenefit;
