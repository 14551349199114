import { useState } from "react";
import { useCardDetailContext } from "../../../../../../contexts/Cards/CardDetailContext";
import { getUserRole } from "../../../../../../contexts/AuthContext";
import PINModal from "../../../../../../components/PINModal/parent";
import { useModalHook } from "../../../../../../components/Modals";
import { ToasterHook } from "../../../../../../contexts/ToasterContext";
import { useMutation } from "../../../../../../tools/api";
import { eventsTracker } from "../../../../../../universalFunctions/events";
import PrepaidMoveToCardForm from "./form";
import PrepaidMoveToCardSummary from "./summary";

const PrepaidMoveToCard = () => {
  const {
    cardDetail,
    setCurrentScreen,
    refetchMainBalance,
    refetchBalance,
    setHideClose,
  } = useCardDetailContext();
  const [moveToCardScreen, setMoveToCardScreen] = useState("form");
  const [result, setResult] = useState({});
  const [payload, setPayload] = useState({});
  const [isPinError, setIsPinError] = useState(false);

  const { successSnackBar } = ToasterHook();
  const {
    isOpen: isOpenPin,
    toggle: togglePin,
    close: closePin,
  } = useModalHook();

  const { loading: loadingMoveToCard, mutation: moveToCardMutation } =
    useMutation({
      url: `/cards/${cardDetail?.id}/move_balance`,
      method: "post",
      afterSuccess: () => {
        eventsTracker("card_move_balance_to_card", {
          amount: result?.amount,
          reason: result?.reason,
          card_id: cardDetail?.id,
        });
        if (isAdminOrSuperAdmin) {
          refetchMainBalance();
          setHideClose(false);
          closePin();
          successSnackBar({
            msg: "Prepaid card balance has been moved",
          });
        } else {
          successSnackBar({
            msg: "Prepaid card move balance has been requested",
          });
        }
        setCurrentScreen("index");
        refetchBalance();
      },
    });

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      paddingBottom: "100px",
    },
  };

  const isSummary = moveToCardScreen === "summary";
  const { isAdminOrSuperAdmin } = getUserRole();

  const onSubmitPin = async ({ pin }) => {
    await moveToCardMutation({ pin, ...payload });
  };

  const moveBalanceHandler = async (payload) => {
    setPayload(payload);
    if (isAdminOrSuperAdmin) {
      setHideClose(true);
      return togglePin();
    }
    await moveToCardMutation(payload);
  };

  return (
    <>
      <div style={styles.container}>
        {isSummary ? (
          <PrepaidMoveToCardSummary
            cardDetail={cardDetail}
            result={result}
            setMoveToCardScreen={setMoveToCardScreen}
            onSubmit={moveBalanceHandler}
            isLoading={loadingMoveToCard}
          />
        ) : (
          <PrepaidMoveToCardForm
            cardDetail={cardDetail}
            result={result}
            setResult={setResult}
            setMoveToCardScreen={setMoveToCardScreen}
            setCurrentScreen={setCurrentScreen}
          />
        )}
      </div>
      <PINModal
        isOpen={isOpenPin}
        toggle={togglePin}
        isError={isPinError}
        setIsError={setIsPinError}
        isLoading={loadingMoveToCard}
        onSubmit={onSubmitPin}
        onBack={() => {
          setIsPinError(false);
          setHideClose(false);
          togglePin();
        }}
        woClose
      />
    </>
  );
};

export default PrepaidMoveToCard;
