import { useEffect, useMemo, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { Modal } from "reactstrap";
import { colors, jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { FileInputButtonJack } from "../../../../components/inputs/fileInputs/fileInputButton";
import { TextFieldJack } from "../../../../components/inputs/textfield";
import LoadingSpinner from "../../../../components/Loading";
import { PDFOrImageViewer } from "../../../../components/PDFPreview";
import {
  TextInlineBold,
  GothamMedium,
  GothamRegular,
} from "../../../../components/Text";
import {
  isProduction,
  schemaGenerator,
  unformatCurrency,
} from "../../../../components/tools";
import { useMutation } from "../../../../tools/api";
import {
  attachmentDataFormatter,
  dateToIsoDateFormatter,
  invoiceFormatter,
} from "../../data/formatter";
import { invoicesValidation } from "../../data/parent";
import { CustomRichTextArea } from "../../../../components/CustomRichTextArea";
import { CloseButton } from "../uploadPage";
import { SelectionJack } from "../../../../components/inputs/selection";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { Banner } from "../../../../components/Banner";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../universalFunctions/events";
import { uploadFilesFormatter } from "../../components";
import {
  DateInputJackState,
  DateInputJackUseForm,
} from "../../../../components/inputs/date/parent";
import { CustomDateInputv2 } from "../../../../components/inputs";
import { SimpleTextAreaJack } from "../../../../components/inputs/simpleTextArea";
import { Avatar } from "../../../../components/Avatar";
import * as yup from "yup";
import { useRouter } from "next/router";
import {
  createtaxDescription,
  totalAmountOnFields,
  useInvoiceFormDebounce,
  useInvoiceSingle,
  useSubmitInvoice,
} from "./formLogic";
import CategorySelection from "../../../categoryComponents/general/components/CategorySelection";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../../categoryComponents/general/hooks";
import { isEmpty, times } from "lodash";
import { IconToolTip } from "../../tableJack";
import { useModalHook } from "../../../../components/Modals";
import { LineItems, RecipientInput, TaxLineItems } from "./formComponents";
import { InvoiceFormSummary } from "./formComponents/component";
import { createInvoiceSummaryForm } from "../../../../modals/smartActivityModal/invoicePayment/editDetailModal/logic";
import {
  BILL_EIGHTH_TARGET,
  BILL_FIFTH_TARGET,
  BILL_FOURTH_TARGET,
  BILL_MODULE,
  BILL_SEVENTH_TARGET,
  BILL_SIXTH_TARGET,
  BILL_THIRD_TARGET,
} from "../../../productDemoComponents/constants";

const risolver = schemaGenerator({
  stringArr: [
    {
      name: "invoice_number",
      yup: yup.string().required("Please fill out this field"),
    },
    {
      name: "issued_date",
      yup: yup
        .string()
        .required("Please fill out this field")
        .test("is-notempty", "Please fill out this field", (val) => val)
        .test(
          "is-ok",
          "Please fill out this field",
          (val) =>
            val != "Thu Jan 01 1970 07:00:00 GMT+0700 (Western Indonesia Time)"
        ),
    },
    {
      name: "due_date",
      yup: yup
        .string()
        .required("Please fill out this field")
        .test(
          "is-ok",
          "Please fill out this field",
          (val) =>
            val != "Thu Jan 01 1970 07:00:00 GMT+0700 (Western Indonesia Time)"
        ),
    },
    {
      name: "description",
      yup: yup
        .string()
        .required("Please fill out this field")
        .test(
          "is-notempty",
          "Please fill out this field",
          (val) =>
            `${val}`.replace(/<[^>]*>?/gm, "").replace(/(\r\n|\n|\r)/gm, "")
              ?.length > 0
        ),
    },
    // {
    //   name: "name",
    //   yup: yup.string().required("Please fill out this field"),
    // },
    // {
    //   name: "valid_bank_account",
    //   yup: yup.object().shape({
    //     is_valid: yup
    //       .boolean()
    //       .test("is-valid", "Bank Account not valid", (val) => Boolean(val)),
    //   }),
    // },
    {
      name: "amount",
      yup: yup
        .string()
        .required("Please fill out this field")
        .test(
          "is-ok",
          "Please fill out this field",
          (val) => Number(unformatCurrency(val)) > 0
        )
        .test(
          "is-above-10k",
          `Minimum amount: IDR 10,000`,
          (val) => unformatCurrency(val) >= 10000
        ),
    },
    // {
    //   name: "total_amount",
    //   yup: yup
    //     .string()
    //     .required("Please fill out this field")
    //     .test(
    //       "is-ok",
    //       "Please fill out this field",
    //       (val) => Number(unformatCurrency(val)) > 0
    //     )
    //     .test(
    //       "is-above-10k",
    //       `Minimum amount: IDR 10,000`,
    //       (val) => unformatCurrency(val) >= 10000
    //     ),
    // },
    {
      name: "transfer_notes",
      yup: yup
        .string()
        .nullable(true)
        .test("isnotnull", "Please fill out this field", (val) => Boolean(val))
        .test("max-char", "Max character reached", (val) => val?.length <= 90),
    },
    {
      name: "upload_file",
      yup: yup.array().max(5).nullable(),
    },
    {
      name: "recipient_detail_id",
      yup: yup.number().required(),
    },
  ],
});

const rightSideWidth = "calc(100% - 12% - 32px)";

export const InvoiceFormEditScreen = ({
  detailId,
  toggle,
  refetchMain,
  isDraft = false,
  declareIsMountedDisplay = () => {},
  declareIsMountedFirstForm = () => {},
  declareIsMountedSecondForm = () => {},
  declareIsMountedThirdForm = () => {},
  declareIsMountedFourthForm = () => {},
  declareIsMountedFifthForm = () => {},
}) => {
  const { push } = useRouter();
  const { successSnackBar } = ToasterHook();

  const useFormObj = useForm({
    resolver: risolver,
    mode: "all",
    reValidateMode: "all",
  });

  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    register,
    formState,
  } = useFormObj;

  const { invoice, invoiceLoading, refetch, setData } = useInvoiceSingle({
    detailId,
    reset,
    register,
    setValue,
  });

  const handleClose = () => {
    setData({});
    toggle();
  };

  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const categoryForm = watch("category");
  const isMissingRequiredCategory =
    isRequiredCategory && !categoryForm && hasCategoryList;

  const { mutation: submitOnly } = useMutation({
    url: "/invoice_transactions/change_to_waiting_approval",
    method: "put",
    handleError: (err) => console.log(err),
    afterSuccess: () =>
      push({
        pathname: "/success",
        query: { type: "invoice", id: detailId, origin_draft: true },
      }),
  });
  const { submit: submitDraft, loading: loadingSubmitDraft } = useSubmitInvoice(
    {
      val: getValues(),
      isMissingRequiredCategory,
      invoiceData: invoice,
      afterSuccess: async () => submitOnly({ ids: [detailId] }),
    }
  );
  const { submit: onSubmit, loading: updateLoading } = useSubmitInvoice({
    val: getValues(),
    invoiceData: invoice,
    isMissingRequiredCategory,
    handleError: (err) => console.log(err),
    afterSuccess: async () => {
      await refetchMain();
      successSnackBar({
        msg: "Invoice details has been updated.",
        showClose: true,
      });
      handleClose();
    },
  });

  useEffect(() => {
    if (!detailId) return;
    refetch();
  }, [detailId]);

  const { categories } = useConstants();

  // const category = (categories ?? []).find(
  //   ({ id }) => id === invoice?.category_id
  // );
  
  const {
    is_duplicate,
    pdf,
    fee_from_subscription_prices: fee,
    description,
  } = invoice;

  const documentName = pdf?.split("/")?.slice(-1)?.pop();

  const href = useRef(null);
  const bottomRef = useRef(null);
  useEffect(() => {
    const scrollIntoView = (ref) =>
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });

    const errorKeys = Object.keys(errors);
    const lowSideError = "transfer_notes";
    if (errorKeys?.length == 0) return;
    if (errorKeys?.length == 1 && errorKeys?.includes(lowSideError))
      return scrollIntoView(bottomRef);

    return scrollIntoView(href);
  }, [errors]);

  return (
    <div style={{ width: "100%", height: "100%" }}>
      {invoiceLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              position: "relative",
              minHeight: "100%",
            }}
          >
            <div
              style={{
                height: "calc(100% - 80px)",
                overflow: "scroll",
                padding: "12px 32px 52px 32px",
                display: "flex",
                flexDirection: "column",
              }}
              className="hideScrollbar"
            >
              <InvoiceForm
                useFormObj={useFormObj}
                isErrorCategory={isErrorCategory}
                setIsErrorCategory={setIsErrorCategory}
                // category={category}
                fee={fee}
                declareIsMountedFirstForm={declareIsMountedFirstForm}
                declareIsMountedSecondForm={declareIsMountedSecondForm}
                declareIsMountedThirdForm={declareIsMountedThirdForm}
                declareIsMountedFourthForm={declareIsMountedFourthForm}
                declareIsMountedFifthForm={declareIsMountedFifthForm}
              />
            </div>
            <div>
              <FormSummarySheet
                isDraft={isDraft}
                handleBack={handleClose}
                submitLoading={updateLoading}
                loadingSubmitDraft={loadingSubmitDraft}
                onClick={() => {
                  if (isMissingRequiredCategory) setIsErrorCategory(true);
                  handleSubmit(onSubmit)();
                }}
                draftSubmit={() => {
                  if (isMissingRequiredCategory) setIsErrorCategory(true);
                  handleSubmit(submitDraft)();
                }}
                disableSubmit={!invoice?.check_is_complete}
              />
            </div>
          </div>
          <div
            id={BILL_THIRD_TARGET}
            ref={(e) => {
              if (!e) return;
              declareIsMountedDisplay();
            }}
            style={{ width: "50%", height: "100%" }}
          >
            <PDFOrImageViewer
              url={invoice?.pdf}
              leftHeaderComponent={
                <PDFLeftComponent
                  is_duplicate={is_duplicate}
                  pdf={invoice?.pdf}
                />
              }
            />
          </div>
        </div>
      )}
    </div>
  );
};

export const InvoiceFormEditModal = ({
  detailId,
  toggle,
  refetchMain,
  isDraft = false,
}) => {
  const { push } = useRouter();
  const { successSnackBar } = ToasterHook();

  const useFormObj = useForm({
    resolver: risolver,
    mode: "all",
    reValidateMode: "all",
  });

  const {
    handleSubmit,
    watch,
    getValues,
    setValue,
    reset,
    errors,
    register,
    formState,
  } = useFormObj;

  const { invoice, invoiceLoading, refetch, setData } = useInvoiceSingle({
    detailId,
    reset,
    register,
    setValue,
  });

  const handleClose = () => {
    setData({});
    toggle();
  };

  const [isErrorCategory, setIsErrorCategory] = useState(false);
  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const categoryForm = watch("category");
  const isMissingRequiredCategory =
    isRequiredCategory && !categoryForm && hasCategoryList;

  const { mutation: submitOnly } = useMutation({
    url: "/invoice_transactions/change_to_waiting_approval",
    method: "put",
    handleError: (err) => console.log(err),
    afterSuccess: () =>
      push({
        pathname: "/success",
        query: { type: "invoice", id: detailId, origin_draft: true },
      }),
  });
  const { submit: submitDraft, loading: loadingSubmitDraft } = useSubmitInvoice(
    {
      val: getValues(),
      isMissingRequiredCategory,
      invoiceData: invoice,
      afterSuccess: async () => submitOnly({ ids: [detailId] }),
    }
  );
  const { submit: onSubmit, loading: updateLoading } = useSubmitInvoice({
    val: getValues(),
    invoiceData: invoice,
    isMissingRequiredCategory,
    handleError: (err) => console.log(err),
    afterSuccess: async () => {
      await refetchMain();
      successSnackBar({
        msg: "Invoice details has been updated.",
        showClose: true,
      });
      handleClose();
    },
  });

  useEffect(() => {
    if (!detailId) return;
    refetch();
  }, [detailId]);

  const { categories } = useConstants();

  const category = (categories ?? []).find(
    ({ id }) => id === invoice?.category_id
  );

  const {
    is_duplicate,
    pdf,
    fee_from_subscription_prices: fee,
    description,
  } = invoice;

  const documentName = pdf?.split("/")?.slice(-1)?.pop();

  const href = useRef(null);
  const bottomRef = useRef(null);
  useEffect(() => {
    const scrollIntoView = (ref) =>
      ref?.current?.scrollIntoView({
        behavior: "smooth",
        block: "end",
        inline: "nearest",
      });

    const errorKeys = Object.keys(errors);
    const lowSideError = "transfer_notes";
    if (errorKeys?.length == 0) return;
    if (errorKeys?.length == 1 && errorKeys?.includes(lowSideError))
      return scrollIntoView(bottomRef);

    return scrollIntoView(href);
  }, [errors]);
  return (
    <Modal
      className="my-0"
      contentClassName="modal-full"
      isOpen={detailId}
      toggle={handleClose}
    >
      {invoiceLoading ? (
        <div
          style={{
            display: "flex",
            height: "100vh",
            width: "100vw",
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <LoadingSpinner />
        </div>
      ) : (
        <div
          style={{
            width: "100vw",
            height: "100vh",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "50%",
              position: "relative",
              minHeight: "100vh",
            }}
          >
            <div
              style={{
                height: "calc(100vh - 80px)",
                overflow: "scroll",
                padding: "12px 32px 52px 32px",
                display: "flex",
                flexDirection: "column",
                gap: 32,
              }}
              className="hideScrollbar"
            >
              <FormHeader
                title="Invoice Details"
                text="Make sure the data below matches the original document."
              />
              <div ref={href} />
              <InvoiceForm
                useFormObj={useFormObj}
                isErrorCategory={isErrorCategory}
                setIsErrorCategory={setIsErrorCategory}
                category={category}
                description={description}
                fee={fee}
              />
              <div ref={bottomRef} />
            </div>
            <FormSummarySheet
              isDraft={isDraft}
              handleBack={handleClose}
              submitLoading={updateLoading}
              loadingSubmitDraft={loadingSubmitDraft}
              onClick={() => {
                if (isMissingRequiredCategory) setIsErrorCategory(true);
                handleSubmit(onSubmit)();
              }}
              draftSubmit={() => {
                if (isMissingRequiredCategory) setIsErrorCategory(true);
                handleSubmit(submitDraft)();
              }}
              disableSubmit={!invoice?.check_is_complete}
            />
          </div>
          <div style={{ width: "50%", height: "100vh" }}>
            <PDFOrImageViewer
              url={invoice?.pdf}
              leftHeaderComponent={
                <PDFLeftComponent
                  is_duplicate={is_duplicate}
                  pdf={invoice?.pdf}
                />
              }
            />
          </div>
        </div>
      )}
    </Modal>
  );
};

export const PDFLeftComponent = ({
  pdf,
  is_duplicate,
  product = "invoice",
}) => {
  const documentName = pdf?.split("/")?.slice(-1)?.pop();
  return (
    <div
      style={{
        width: "auto",
        display: "flex",
        gap: 6,
        alignItems: "center",
      }}
    >
      {is_duplicate && <DuplicateIcon product={product} />}
      <GothamRegular
        className="text-white"
        style={{
          width: "80%",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          overflow: "hidden",
        }}
      >
        {documentName}
      </GothamRegular>
    </div>
  );
};

const DuplicateIcon = ({ product }) => (
  <IconToolTip
    icon="alert_circle_outline"
    iconColor="#FFFFFF"
    title={`Duplicated ${product}`}
    text={`We’ve detected identical ${product} in our system. Pleace decide whether to keep or delete it.`}
    position="bottom"
    iconStyle={{ width: 18, height: 18 }}
  />
);

export const FormHeader = ({
  title,
  text,
  style,
  customRef,
  woText = false,
}) => (
  <div
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "start",
      marginBottom: 32,
      ...style,
    }}
    ref={customRef}
  >
    <JackIcons name="fullsize" style={{ marginBottom: 24 }} />
    {!woText && (
      <div>
        <GothamMedium className="font24 mb-1">{title}</GothamMedium>
        <GothamRegular>{text}</GothamRegular>
      </div>
    )}
  </div>
);

const InvoiceForm = ({
  useFormObj,
  // category,
  isErrorCategory,
  setIsErrorCategory,
  fee = 0,
  declareIsMountedFirstForm = () => {},
  declareIsMountedSecondForm = () => {},
  declareIsMountedThirdForm = () => {},
  declareIsMountedFourthForm = () => {},
  declareIsMountedFifthForm = () => {},
}) => {
  const { watch, register, setValue } = useFormObj;
  const issuedDate = watch("issued_date");
  const selectedCategory = watch("category");

  const items = watch("transaction_items");
  const taxes = watch("transaction_taxes");
  const subTotal = unformatCurrency(watch("amount"));
  const tax = unformatCurrency(watch("tax"));
  const description = watch("description");
  const category_id = watch("category");

  const { categories } = useConstants();

  const category = (categories ?? []).find(
    // ({ id }) => id === category_id
    ({ name }) => name === category_id
  );

  const summary = createInvoiceSummaryForm({
    items,
    taxes,
    fee,
    tax,
    amount: subTotal,
  });

  useEffect(() => {
    register("category");
    if (category) setValue("category", category);
  }, [category]);

  return (
    <>
      <div>
        <FormHeader woText style={{ marginBottom: 0 }} />
        <div>
          <div
            style={{
              borderBottom: "1px solid #E6E6E8",
              marginBottom: 32,
              paddingBottom: 32,
            }}
          >
            <GothamMedium className="font24 mb-1">Invoice Details</GothamMedium>
            <GothamRegular>
              Make sure the data below matches the original document.
            </GothamRegular>
          </div>
          <div
            id={BILL_FOURTH_TARGET}
            ref={(e) => {
              if (!e) return;
              declareIsMountedFirstForm();
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6E6E8",
              }}
            >
              <div style={{ width: "12%" }}>
                <GothamRegular className="font12">
                  Invoice Overview
                </GothamRegular>
              </div>
              <div style={{ width: rightSideWidth, paddingBottom: 32 }}>
                <TextFieldJack
                  useFormObj={useFormObj}
                  name="invoice_number"
                  label={redStarLabel("Invoice Number")}
                  placeholder="E.g., INV/00001"
                />
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "48.6%" }}>
                    <DateInputJackUseForm
                      useFormObj={useFormObj}
                      name={"issued_date"}
                      label={redStarLabel("Issued Date")}
                      placeholder="Select Date"
                      dateFormat="d/MM/yyyy"
                    />
                  </div>
                  <div style={{ width: "48.6%" }}>
                    <DateInputJackUseForm
                      useFormObj={useFormObj}
                      label={redStarLabel("Due date")}
                      name="due_date"
                      placeholder="Select Date"
                      dateFormat="d/MM/yyyy"
                      minDate={issuedDate}
                    />
                  </div>
                </div>
                <CategorySelection
                  isError={isErrorCategory}
                  selectedCategory={selectedCategory ?? {}}
                  onSelect={(category) => {
                    setValue("category", category);
                    setIsErrorCategory(false);
                  }}
                />
                <CustomRichTextArea
                  useFormObj={useFormObj}
                  name="description"
                  label={redStarLabel("Description")}
                  placeholder="Help your approver(s) understand the purpose of this invoice."
                  helperText="Help your approver(s) understand the purpose of this invoice."
                  defaultValue={description}
                  isUnlimited
                />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                borderBottom: "1px solid #E6E6E8",
                paddingTop: 32,
                paddingBottom: 32,
              }}
            >
              <div style={{ width: "12%" }}>
                <GothamRegular className="font12">
                  Recipient Details
                </GothamRegular>
              </div>
              <div style={{ width: rightSideWidth }}>
                <RecipientInput
                  useFormObj={useFormObj}
                  name="recipient_detail_id"
                />
              </div>
            </div>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            borderBottom: "1px solid #E6E6E8",
            marginTop: 32,
          }}
        >
          <div style={{ width: "12%" }}>
            <GothamRegular className="font12">
              Transaction Details
            </GothamRegular>
          </div>
          <div
            style={{
              width: rightSideWidth,
              display: "flex",
              flexDirection: "column",
              gap: 32,
            }}
          >
            <div
              id={BILL_FIFTH_TARGET}
              ref={(e) => {
                if (!e) return;
                declareIsMountedSecondForm();
              }}
            >
              <LineItems
                useFormObj={useFormObj}
                name="transaction_items"
                totalName="amount"
                isSubmitted={false}
              />
            </div>
            <div
              id={BILL_SIXTH_TARGET}
              ref={(e) => {
                if (!e) return;
                declareIsMountedThirdForm();
              }}
            >
              <TaxLineItems
                useFormObj={useFormObj}
                name="transaction_taxes"
                totalName="tax"
              />
            </div>
            <div
              id={BILL_SEVENTH_TARGET}
              ref={(e) => {
                if (!e) return;
                declareIsMountedFourthForm();
              }}
            >
              <InvoiceFormSummary data={summary} />
            </div>
            <SimpleTextAreaJack
              useFormObj={useFormObj}
              label={redStarLabel("Transfer Note")}
              name="transfer_notes"
              placeholder="This note will appear on the bank transfer receipt."
              maxLength={90}
              helperText="This note will appear  on the bank transfer receipt."
            />
          </div>
        </div>
      </div>
      <div
        id={BILL_EIGHTH_TARGET}
        ref={(e) => {
          if (!e) return;
          declareIsMountedFifthForm();
        }}
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: 32,
        }}
      >
        <div style={{ width: "12%" }}>
          <GothamRegular className="font12">
            Supporting Document(s)
          </GothamRegular>
        </div>
        <div style={{ width: rightSideWidth }}>
          <div
            style={{
              width: "100%",
              height: 44,
              padding: 12,
              backgroundColor: "#F1F1F1",
              borderRadius: 4,
              marginBottom: 8,
              border: "1px solid #BBBBC0",
              display: "flex",
              gap: 12,
              alignItems: "center",
            }}
          >
            <JackIcons
              name="lightbulb"
              fill="#909098"
              style={{ width: "auto", height: 20, marginBottom: 2 }}
            />
            <GothamRegular className="font12" style={{ color: colors.grey6c }}>
              You can provide any relevant documents, such as proof of
              identification or delivery notes.
            </GothamRegular>
          </div>

          <FileInputButtonJack
            useFormObj={useFormObj}
            name="upload_file"
            fileTypes={["image/jpeg", "application/pdf", "image/png"]}
            multiple={true}
            showLabel={false}
            enablePreview={true}
            module={BILL_MODULE}
          />
        </div>
      </div>
    </>
  );
};

export const useBankInfoText = (accountNumber, id) => {
  if (!accountNumber || !id) return "";
  const { localTransferBanks } = useConstants();
  const [thisBank] = localTransferBanks?.filter(
    ({ id: bankid }) => bankid == id
  );
  const { label } = thisBank;

  return `${label} - ${accountNumber}`;
};

const VendorBankInput = ({ useformobj, name, defaultValue }) => {
  const [input, toggleInput] = useState(false);
  const { register, setValue, watch, errors } = useformobj;

  const currValue = watch(name) || defaultValue;
  const { account_number, payer_id, is_valid, registered_name } =
    currValue || {};

  const { localTransferBanks } = useConstants();

  const bankInfo = useBankInfoText(account_number, payer_id);

  const handleChange = (val) => {
    setValue(name, val);
  };

  useEffect(() => {
    register({ name });
  }, [name, register]);

  const isError = !isEmpty(errors[name]);
  return (
    <>
      <div
        style={{
          flexDirection: "column",
          position: "relative",
        }}
        className="d-flex hover"
      >
        <GothamRegular
          className="font12"
          style={{ color: jackColors.black34, marginBottom: 8 }}
        >
          {redStarLabel("Vendor Bank Account")}
        </GothamRegular>
        {is_valid ? (
          <ActionButtonInvoiceForm
            title={registered_name}
            text={bankInfo}
            rightIconName="edit_outline"
            onClick={() => toggleInput(true)}
            containerStyle={{ marginBottom: 0 }}
          />
        ) : (
          <div
            style={{
              height: 56,
              borderRadius: 4,
              width: "100%",
              padding: "10px 12px",
              border: `1px solid ${isError ? colors.redDC : colors.greye6}`,
            }}
            onClick={() => toggleInput(true)}
          >
            <div style={{ display: "flex", gap: 16 }}>
              <Avatar
                size="medium"
                mainIcon={
                  <JackIcons
                    name="account_Balance"
                    style={{
                      height: 16.67 * 1.2,
                      width: 15.83 * 1.2,
                    }}
                  />
                }
              />
              <div className="d-flex-column justify-content-between h-100">
                <GothamMedium className="font12 " style={{ marginBottom: 2 }}>
                  Input Vendor Bank Account
                </GothamMedium>
                <GothamRegular
                  className="font12"
                  style={{ color: jackColors.neutral700 }}
                >
                  Payment will be sent to this bank account.
                </GothamRegular>
              </div>
            </div>
          </div>
        )}
      </div>
      <VendorBankInputModal
        isOpen={input}
        toggle={() => toggleInput(false)}
        currValue={currValue}
        handleChange={handleChange}
        localTransferBanks={localTransferBanks}
      />
    </>
  );
};

const ActionButtonInvoiceForm = ({
  title,
  text,
  rightIconName,
  onClick,
  containerStyle,
}) => {
  return (
    <div
      style={{
        height: 56,
        width: "100%",
        border: "1px solid #E6E6E8",
        borderRadius: 4,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: 12,
        marginBottom: 32,
        ...containerStyle,
      }}
      onClick={onClick}
    >
      <div className="d-flex">
        <div
          style={{
            width: 32,
            height: 32,
            position: "relative",
            marginRight: 16,
            backgroundColor: jackColors.black34,
            borderRadius: 100,
            display: "grid",
            placeItems: "center",
          }}
        >
          <JackIcons
            style={{
              width: 21,
              height: "auto",
              marginBottom: 2.5,
            }}
            name="bank"
            fill="#FFFFFF"
          />
        </div>
        <div className="d-flex-column">
          <GothamRegular
            className="font12"
            style={{ color: jackColors.black34 }}
          >
            {title}
          </GothamRegular>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700 }}
          >
            {text}
          </GothamRegular>
        </div>
      </div>
      <JackIcons
        name={rightIconName}
        fill="#343434"
        style={{ cursor: "pointer", height: "auto", width: 20 }}
        onClick={onClick}
      />
    </div>
  );
};

export const CheckBankAnimation = () => (
  <div
    style={{
      height: 56,
      width: "100%",
      border: "1px solid #E6E6E8",
      borderRadius: 4,
      display: "flex",
      alignItems: "center",
      padding: 12,
      marginBottom: 32,
    }}
  >
    <div
      style={{
        width: 32,
        height: 32,
        position: "relative",
        marginRight: 16,
      }}
      className="loading-bank"
    >
      <JackIcons
        style={{
          position: "absolute",
          top: 0,
          right: 0,
          width: 16,
          height: 16,
        }}
        name="ellipse13"
        fill="#343434"
      />
    </div>
    <div className="d-flex-column">
      <GothamMedium className="font12" style={{ color: jackColors.black34 }}>
        Validating...
      </GothamMedium>
      <GothamRegular
        className="font12"
        style={{ color: jackColors.neutral700 }}
      >
        Once validated, the bank account will be shown here.
      </GothamRegular>
    </div>
  </div>
);

export const VendorBankInputModal = ({
  isOpen,
  toggle,
  useformobj,
  validBankAccount,
  currValue,
  handleChange,
  setValidBankAccount,
  localTransferBanks,
  customToasterText = "Vendor bank account has been saved.",
  isModal = true,
  leftClick,
  loadingSave = false,
}) => {
  const [isNotValid, setIsNotValid] = useState(false);
  const [validData, setValidData] = useState(currValue);
  const { payer_id, account_number, is_valid, registered_name } = validData;
  const [isEdit, setIsEdit] = useState(false);
  const { successSnackBar, errorSnackBar } = ToasterHook();

  const myUseForm = useForm();
  const { watch } = myUseForm;
  const { handleSubmit, reset, setError, setValue, getValues } = myUseForm;
  const verifyUrl = isProduction
    ? "/local_recipients/update_for_validate/61"
    : "/local_recipients/update_for_validate/62";
  const { mutation: checkAccount, loading: checkLoading } = useMutation({
    method: "put",
    url: verifyUrl,
    handleError: (err) => console.log(err),
    afterSuccess: ({ data }) => {
      const { data: checkResult } = data;
      const {
        is_valid,
        registered_name,
        account_number,
        payer_id,
        name,
        is_match_name,
      } = checkResult;
      if (!is_valid) setIsNotValid(true);
      if (is_valid) {
        setIsEdit(false);
        setIsNotValid(false);
        return setValidData({
          is_valid,
          name,
          registered_name,
          account_number,
          payer_id,
          is_match_name,
        });
      }
    },
  });

  const bankInfo = useBankInfoText(account_number, payer_id);
  const verify = (val) => {
    const { account_number, banks } = val;
    const { id, label: bank_name = "" } = banks;
    const verifyData = {
      account_number,
      payer_id: id,
      bank_name
    };
    setIsNotValid(false);
    checkAccount(verifyData);
  };

  const validFlag = Boolean(is_valid);

  const onClickSave = async () => {
    if (validFlag && !isEdit) {
      const respon = await handleChange(validData);

      if (respon == "bankExist")
        return errorSnackBar({
          msg: "Bank account already exists. Enter a different bank account.",
          showClose: true,
        });

      successSnackBar({
        msg: customToasterText,
        showClose: true,
      });

      isModal && toggle();
      return;
    }

    return handleSubmit(verify);
  };

  const onClickEdit = () => {
    setValue("banks", bankPicker(payer_id).pop());
    setValue("account_number", account_number);
    setValidData({});
    setIsEdit(true);
    setIsNotValid(false);
  };

  const onClickClose = () => {
    toggle();
    reset();
    return setValidData({});
  };

  const bankPicker = (id) => localTransferBanks.filter((bank) => bank.id == id);
  const disabledSubmitVerify = !watch("banks") || !watch("account_number");
  useEffect(() => {
    if (validData?.is_valid && !isEdit) {
      setValue("banks", bankPicker(payer_id));
      setValue("account_number", account_number);
    }
  }, [validData?.is_valid]);

  useEffect(() => {
    if (isNotValid) {
      setError("banks", { message: "   " });
      setError("account_number", { message: "   " });
    }
  }, [isNotValid]);

  if (!isModal) {
    return (
      <div>
        <div
          style={{
            minHeight: 208,
            height: "auto",
            padding: "16px 0px 0px 0px",
          }}
        >
          <SelectionJack
            useFormObj={myUseForm}
            name="banks"
            label={redStarLabel("Bank Name")}
            placeholder="Select bank"
            options={localTransferBanks}
            defaultValue={
              validData?.is_valid ? bankPicker(validData?.payer_id)[0] : null
            }
            isDisabled={validFlag && !isEdit}
          />
          <TextFieldJack
            useFormObj={myUseForm}
            name="account_number"
            label={redStarLabel("Account Number")}
            placeholder="Enter account number here"
            type="number"
            defaultValue={validData?.account_number ?? null}
            disabled={validFlag && !isEdit}
          />
          {checkLoading && <CheckBankAnimation />}
          {isNotValid && (
            <>
              <Banner
                title="Bank account not found"
                msg="Please re-enter and validate the bank account details."
                type="error"
              />
              <div style={{ width: "100%", height: 32 }} />
            </>
          )}
          {validFlag && !isEdit && (
            <ActionButtonInvoiceForm
              title={registered_name}
              text={bankInfo}
              rightIconName="edit_outline"
              onClick={onClickEdit}
            />
          )}
        </div>
        <div
          className="w-100 d-flex justify-content-end"
          style={{ marginTop: 32, gap: 16 }}
        >
          <ButtonJack
            type="outline"
            style={{ backgroundColor: "transparent" }}
            leftIcon={<JackIcons name="arrow_back_outline" fill="#343434" />}
            onClick={leftClick}
          >
            Back
          </ButtonJack>
          <ButtonJack
            disabled={disabledSubmitVerify}
            isLoading={loadingSave}
            onClick={validFlag && !isEdit ? onClickSave : handleSubmit(verify)}
          >
            {validFlag && !isEdit ? "Save" : "Validate"}
          </ButtonJack>
        </div>
      </div>
    );
  }
  return (
    <Modal
      toggle={onClickClose}
      isOpen={isOpen}
      className="my-0"
      centered
      contentClassName="modal-transfez"
    >
      <div style={{ minHeight: 376, height: "auto", width: 480 }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            height: 88,
            padding: "20px 20px 16px 20px",
            width: "100%",
            borderBottom: "1px solid #E6E6E8",
          }}
        >
          <div>
            <GothamMedium className="font20 mb-1">
              {validFlag
                ? "We’ve found the bank account!"
                : isEdit
                ? "Edit Vendor Bank Account "
                : "Add Bank Account"}
            </GothamMedium>
            <GothamRegular style={{ color: colors.grey90 }}>
              {validFlag
                ? "Invoice payment will be sent to this bank account."
                : "Set up a bank account where your reimbursement will be sent."}
            </GothamRegular>
          </div>
          <CloseButton onClick={onClickClose} />
        </div>
        <div
          style={{
            minHeight: 208,
            height: "auto",
            padding: "16px 20px 0px 20px",
            borderBottom: "1px solid #E6E6E8",
          }}
        >
          <SelectionJack
            useFormObj={myUseForm}
            name="banks"
            label={redStarLabel("Bank Name")}
            placeholder="Select bank"
            options={localTransferBanks}
            defaultValue={
              validData?.is_valid ? bankPicker(validData?.payer_id)[0] : null
            }
            isDisabled={validFlag && !isEdit}
          />
          <TextFieldJack
            useFormObj={myUseForm}
            name="account_number"
            label={redStarLabel("Account Number")}
            placeholder="Enter account number here"
            type="number"
            defaultValue={validData?.account_number ?? null}
            disabled={validFlag && !isEdit}
          />
          {checkLoading && <CheckBankAnimation />}
          {isNotValid && (
            <>
              <Banner
                title="Bank account not found"
                msg="Please re-enter and validate the bank account details."
                type="error"
              />
              <div style={{ width: "100%", height: 32 }} />
            </>
          )}
          {validFlag && !isEdit && (
            <ActionButtonInvoiceForm
              title={registered_name}
              text={bankInfo}
              rightIconName="edit_outline"
              onClick={onClickEdit}
            />
          )}
        </div>
        <div style={{ padding: 20, height: 80 }}>
          <ButtonJack
            style={{ width: "100%" }}
            onClick={validFlag && !isEdit ? onClickSave : handleSubmit(verify)}
            disabled={disabledSubmitVerify}
          >
            {validFlag && !isEdit ? "Save" : "Validate"}
          </ButtonJack>
        </div>
      </div>
    </Modal>
  );
};

export const FormSummarySheet = ({
  isDraft = false,
  handleBack,
  onClick,
  submitLoading,
  draftSubmit = () => {},
  loadingSubmitDraft,
  disableSubmit,
  customStyle = {},
}) => {
  return (
    <div
      style={{
        width: "100%",
        height: 80,
        padding: "12px 24px 12px 24px",
        backgroundColor: colors.neutral900,
        position: "absolute",
        bottom: 0,
        display: "flex",
        justifyContent: "end",
        alignItems: "center",
        ...customStyle,
      }}
    >
      {isDraft ? (
        <div style={{ display: "flex", gap: 12 }}>
          <ButtonJack
            type="outline"
            leftIcon={<JackIcons name="arrow_back_outline" />}
            style={{
              border: "1px solid #E6E6E8",
              backgroundColor: "transparent",
              color: "#FFFFFF",
              minWidth: 58,
            }}
            classNameText="text-white"
            onClick={handleBack}
          >
            Back
          </ButtonJack>
          <ButtonJack
            type="outline"
            leftIcon={<JackIcons name="save_outline" fill="#FFFFFF" />}
            style={{
              border: "1px solid #E6E6E8",
              backgroundColor: "transparent",
              color: "#FFFFFF",
              minWidth: 58,
            }}
            isLoading={submitLoading}
            classNameText="text-white"
            onClick={onClick}
          >
            Save
          </ButtonJack>
          <ButtonJack
            type="filled"
            rightIcon={
              <JackIcons
                name="arrow_forward"
                fill={loadingSubmitDraft ? colors.greyBB : colors.neutral900}
              />
            }
            // disabled={disableSubmit}
            style={{ minWidth: 56 }}
            onClick={draftSubmit}
            isLoading={loadingSubmitDraft}
            classNameText={loadingSubmitDraft ? "text-greyBB" : "text-black34"}
          >
            Submit
          </ButtonJack>
        </div>
      ) : (
        <div style={{ display: "flex", gap: 12 }}>
          <ButtonJack
            type="outline"
            leftIcon={<JackIcons name="arrow_back_outline" />}
            style={{
              border: "1px solid #E6E6E8",
              backgroundColor: "transparent",
              color: "#FFFFFF",
              minWidth: 58,
            }}
            classNameText="text-white"
            onClick={handleBack}
          >
            Back
          </ButtonJack>
          <ButtonJack
            type="filled"
            leftIcon={
              <JackIcons
                name="save_outline"
                fill={submitLoading ? colors.greyBB : colors.neutral900}
              />
            }
            style={{ minWidth: 56 }}
            onClick={onClick}
            isLoading={submitLoading}
            classNameText={submitLoading ? "text-greyBB" : "text-black34"}
          >
            Save
          </ButtonJack>
        </div>
      )}
    </div>
  );
};

export const redStarLabel = (label) => (
  <>
    {label}
    <TextInlineBold style={{ color: "red" }}>*</TextInlineBold>
  </>
);
