import { Banner } from "../../../../../components/Banner";
import { isEmpty } from "lodash";
import { cardStateDecider, expiryStatusDecider } from "../../../tools";
import { GothamRegular } from "../../../../../components/Text";
import { colors } from "../../../../../assets/colors";

const CardNotifications = ({ cardDetail, unmaskedDataDetail }) => {
  const isDataDetailUnmasked = !isEmpty(unmaskedDataDetail);
  const {
    expiry_status,
    state,
    user: { status: ownerStatus } = {},
  } = cardDetail || {};
  const { isActivating } = cardStateDecider(state);
  const { isNeedRenewal } = expiryStatusDecider(expiry_status?.status);
  const isPending = ownerStatus !== "active";

  const bannerStyle = {
    margin: "-8px 0px",
  };

  const iconStyleBannerUnmaskedStyle = {
    marginTop: "-8px",
  };

  const iconStyleBannerPendingStyle = {
    marginTop: "-32px",
  };

  if (isDataDetailUnmasked && !isNeedRenewal) {
    return (
      <Banner
        type="warning"
        msg="For your account's security, please don’t disclose your card's CVV number to anyone."
        style={bannerStyle}
        iconStyle={iconStyleBannerUnmaskedStyle}
      />
    );
  }

  if (isActivating) {
    const textStyle = {
      textAlign: "center",
      color: colors.neutral900,
    };

    return (
      <GothamRegular style={textStyle}>
        Your request for activation is being processed, and it will be completed
        in 3 to 5 hours
      </GothamRegular>
    );
  }

  if (isPending) {
    return (
      <Banner
        type="warning"
        title="Cardholder hasn’t registered to Jack"
        msg="Please notify the cardholder to register through the invitation sent to their email."
        style={bannerStyle}
        iconStyle={iconStyleBannerPendingStyle}
      />
    );
  }

  return null;
};

export default CardNotifications;
