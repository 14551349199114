import { useRef } from "react";
import { jackColors } from "../../../../assets/colors";
import CustomHoverInfo from "../../../../components/CustomHoverInfo/CustomHoverInfo";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import styles from "./styles.module.css";

// this tooltip is created for components with high z-index (for instance, modal)
// you need to adjust the position of the tooltip body and the floater (arrow) manually

const FloatingTooltip = ({
  isOpen,
  close,
  children,
  title = "title",
  body = "this is tooltip content",
  tooltipStyle = {
    translate: "515px -455px",
  },
  translateTriangle = `-7px 55px`, // rule of thumb: if position is top/bottom => first number is tooltip_width/2, left/right => 2nd number is tooltip_height/2
  position = "right",
  woButton = false,
  hideTooltip = false,
}) => {
  if (hideTooltip) return null;
  const ref = useRef(null);
  const rotateTriangleDecider = () => {
    switch (position) {
      case "bottom":
        return "90deg";
      case "left":
        return "180deg";
      case "top":
        return "270deg";
      case "right":
      default:
        return "0deg";
    }
  };
  const rotateTrianglePoint = rotateTriangleDecider();

  useClickOutside({ ref, clickOutside: close });

  return (
    <div style={{ position: "relative" }}>
      {children}
      <CustomHoverInfo
        show={isOpen}
        style={{
          zIndex: 99999,
          padding: "16px",
          width: "251px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
          translate: "515px -455px",
          position: "fixed",
          ...tooltipStyle, // xx yy
        }}
        translateTrianglePoint={translateTriangle} // xx yy
        rotateTrianglePoint={rotateTrianglePoint} // starts from 0deg (to the left)
        backgroundColor={jackColors.black34}
        tooltipRef={ref}
      >
        <GothamMedium className="font14" style={{ color: "white" }}>
          {title}
        </GothamMedium>
        <GothamRegular
          woFontColor
          className="font12"
          style={{ color: "white" }}
        >
          {body}
        </GothamRegular>
        {!woButton && (
          <div className={styles["close-btn-container"]}>
            <button className={styles["close-btn"]} onClick={close}>
              <GothamMedium>Got it</GothamMedium>
            </button>
          </div>
        )}
      </CustomHoverInfo>
    </div>
  );
};

export default FloatingTooltip;
