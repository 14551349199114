import { booleanState } from "../../components/Status";

export const pathDecider = (nav) => {
  switch (nav) {
    case "home":
      return "/dashboard";
    case "login":
      return "/login";
    case "kyb":
      return "/kyb-jack";
    case "kyb_detail":
      return "/kyb-jack/details";
    case "scheduled-payment":
      return "/dashboard/?pageType=scheduled_transactions";
    case "transactions":
    case "transactions_detail":
      return "/transactions";
    case "cards":
    case "cards_limit":
    case "cards_detail":
    case "cards_credit":
      return "/cards";
    case "task":
    case "task_detail":
      return "/task";
    case "draft":
    case "draft_detail":
      return "/draft";
    case "alert":
    case "alert_detail":
      return "/alerts-and-attention";
    case "bank-account":
      return "/bank-account";
    case "people-management":
      return "/people-management";
    case "plans":
      return "/plans";
    case "workflow":
      return "/workflow";
    case "create_international_transfer":
      return "/cross-border/single";
    case "create_local_transfer":
      return "/local-transfer/create";
    case "create_payroll":
      return "/payroll/create";
    case "create_reimbursement":
      return "/reimbursement/create/?step=input-name";
    case "create_invoice":
      return "/invoice-payment/create/?step=upload";
    case "customize-receipt":
      return "/customize-receipt";
    default:
      return "/";
  }
};

export const queryDecider = (navigation_type, params) => {
  const { id = null, type = null } = params ?? {}; // type equals to originator type, nav_type is the direction

  const regex = /detail/i;
  const isNotDetail = !regex.test(navigation_type);
  const defaultParams = { id, type, isOpen: true };

  const arrGeneral = ["transactions", "draft", "task", "alert"];
  const isGeneralNav = arrGeneral.includes(navigation_type);

  if (isNotDetail && isGeneralNav) return {};

  const {
    isLocalTransfer,
    isInvoice,
    isInvoicePayment,
    isReimbursement,
    isPayroll,
  } = booleanState(type);

  const draftMenuDecider = () => {
    if (isInvoice || isInvoicePayment) {
      return "bill_payment";
    } else if (isLocalTransfer) {
      return "local_transfer";
    } else if (isReimbursement) {
      return "reimbursement";
    } else if (isPayroll) {
      return "payroll";
    }
    return type;
  };

  const cardsQueryDecider = () => {
    if (navigation_type === "cards_credit") {
      return { menu: "archived" };
    } else if (navigation_type === "cards_limit") {
      return { isCardRequestLimit: true };
    }
    return null;
  };

  switch (navigation_type) {
    case "draft":
      return { ...defaultParams, menu: draftMenuDecider() };
    // case "cards":
    //   return { ...defaultParams, ...cardsQueryDecider() };
    case "cards_limit":
    case "cards_credit":
      return cardsQueryDecider();
    default:
      return defaultParams;
  }
};

export const iconAndSubCategoryDecider = (subgroup) => {
  if (subgroup === "card") {
    return { iconName: "NotifCards", subcategory: "Corporate Cards" };
  }
  if (subgroup === "international_transfer") {
    return {
      iconName: "NotifInternationalTransfer",
      subcategory: "International Transfer",
    };
  }
  if (subgroup === "bill_payment") {
    return { iconName: "NotifBillPayment", subcategory: "Bill Payment" };
  }
  if (subgroup === "local_transfer") {
    return { iconName: "NotifLocalTransfer", subcategory: "Local Transfer" };
  }
  if (subgroup === "payroll") {
    return { iconName: "NotifPayroll", subcategory: "Payroll" };
  }
  if (subgroup === "reimbursement") {
    return { iconName: "NotifReimbursement", subcategory: "Reimbursement" };
  }
  if (subgroup === "user_and_team") {
    return { iconName: "PeopleOutline", subcategory: "User & Teams" };
  }
  if (subgroup === "subscription") {
    return { iconName: "JackSpade", subcategory: "Plans" };
  }
  if (subgroup === "workflow") {
    return { iconName: "Workflow", subcategory: "Workflow" };
  }
  return { iconName: "JackSpade", subcategory: "General" };
};

export const urlCategoryDecider = (
  originator_type,
  originator_id,
  asSingleObject = true
) => {
  const {
    isLocalDisbursement, 
    isLocalDisbursementBatch, 
    isReimbursement, 
    isInvoice, 
    isCrossBorder,
    isBatchTransaction,
    isPayroll, 
  } = booleanState(originator_type);

  if (isLocalDisbursementBatch || isLocalDisbursement)
    return asSingleObject
      ? `/local_transaction_batches/${originator_id}`
      : `/local_transaction_batches/${originator_id}/show_transactions?page=1&per_page=10`;
  if (isReimbursement) return `/reimbursements/${originator_id}`;
  if (isInvoice) return `/invoice_transactions/${originator_id}`;
  if (isCrossBorder) return `/partner_transactions/${originator_id}`;
  if (isBatchTransaction) {
    return `/business_transactions/${originator_id}?page=1&per_page=1000`;
  }
  if (isPayroll) return `/payrolls/${originator_id}`;

  return "";
};
