import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  REIMBURSEMENT_FIRST_TARGET,
} from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${REIMBURSEMENT_FIRST_TARGET}`,
    content: (
      <TooltipContent
        title="Name your reimbursement"
        description="This will help your company in approving and monitoring all reimbursements."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_reimbursement",
      guided_tour_activity: "reimbursement_name"
    },
    disableScrolling: true
  },
];

const reimbursementFirstPageProps = { steps };

export default reimbursementFirstPageProps;
