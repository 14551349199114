const LimitlessBenefit = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.58006 13.8901C5.96006 13.6201 6.33006 13.3201 6.73006 13.0901C9.69006 11.4301 12.6201 11.5201 15.4801 13.3601C15.9501 13.6601 16.3801 14.0601 16.7801 14.4601C20.0001 17.6601 23.2001 20.8801 26.4201 24.0901C28.0101 25.6701 29.9301 26.2101 32.1201 25.6801C35.5501 24.8501 37.4601 20.9301 36.0201 17.7101C34.6201 14.5701 31.0601 13.2801 27.9901 14.8201C27.4201 15.1001 26.9201 15.5401 26.4401 15.9601C25.9001 16.4301 25.4301 16.9701 24.9101 17.4701C24.5301 17.8401 23.9701 17.8901 23.5501 17.6301C23.1101 17.3501 22.9101 16.8401 23.0701 16.3601C23.1201 16.2101 23.1901 16.0601 23.2901 15.9501C24.2101 15.0301 25.0501 14.0101 26.1601 13.3101C28.9301 11.5701 31.8101 11.4101 34.6501 13.0401C37.5801 14.7201 38.9201 17.3801 38.6801 20.7401C38.5101 23.1301 37.4001 25.0901 35.4601 26.5101C33.0801 28.2501 30.4701 28.5601 27.7101 27.5601C26.5901 27.1501 25.6401 26.4701 24.7901 25.6301C21.5701 22.4101 18.3601 19.1901 15.1301 15.9801C13.5301 14.3901 11.6101 13.8501 9.41006 14.3801C5.99006 15.2101 4.08006 19.1501 5.53006 22.3601C6.94006 25.4901 10.5001 26.7901 13.5701 25.2401C14.1301 24.9601 14.6201 24.5301 15.1001 24.1201C15.6101 23.6801 16.0601 23.1701 16.5401 22.6901C17.0501 22.1901 17.7301 22.1601 18.1901 22.6201C18.6501 23.0801 18.6301 23.7701 18.1201 24.2701C17.2401 25.1301 16.4501 26.1001 15.3901 26.7601C12.6201 28.5001 9.77006 28.6001 6.89006 27.0401C6.43006 26.7901 6.02006 26.4501 5.58006 26.1501C5.32006 25.8901 5.05006 25.6201 4.79006 25.3601C4.59006 25.0901 4.38006 24.8401 4.20006 24.5501C2.41006 21.7001 2.33006 18.7901 3.98006 15.8501C4.21006 15.4401 4.51006 15.0801 4.78006 14.7001C5.05006 14.4201 5.31006 14.1601 5.58006 13.8901Z"
        fill="#B3F600"
      />
    </svg>
  );
};

export default LimitlessBenefit;
