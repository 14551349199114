const batchCategoryChangePayloadFormatter = ({
  categoryId = null,
  data = [],
  categories = [],
  dataParent = {},
  dataDetail = {},
}) => {
  const newData = data?.map((item) => {
    if (dataDetail?.id === item?.id) {
      return { ...item, category_id: categoryId };
    }

    return item;
  });

  const newCategoryIdsSet = new Set(
    newData?.map(({ category_id }) => category_id)?.filter((value) => value)
  );

  const newCategoryIds = Array.from(newCategoryIdsSet);

  const newCategoryName = newCategoryIds?.map(
    (newCategoryId) => categories?.find(({ id }) => id === newCategoryId)?.name
  );

  const newDetails = {
    ...dataParent?.details,
    category: newCategoryName,
    category_id: newCategoryIds,
  };

  const payload = {
    ...dataParent,
    details: newDetails,
    isChangingCategory: true,
  };

  return payload;
};

export default batchCategoryChangePayloadFormatter;
