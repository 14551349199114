import {
  getUserRole,
  useGetAuth,
} from "../../../../../../contexts/AuthContext";
import ParserRichTextArea from "../../../../../../components/ParserRichTextArea";
import { LeftRightList } from "../../../../../../components/Lists";
import {
  formatCurrencyNoDecimal,
  unformatCurrency,
} from "../../../../../../components/tools";
import { SeeMore } from "../../../../../../components/SeeMore";
import BottomContainer from "../../components/BottomContainer";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import CardInfoBalance from "../../components/CardInfoBalance";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import { colors } from "../../../../../../assets/colors";

const PrepaidMoveToCompanySummary = ({
  cardDetail,
  setMoveToCompanyScreen,
  result,
  onSubmit,
  isLoading,
}) => {
  const { returned_amount: returnedAmount, reason } = result;
  const { user } = useGetAuth();
  const balance = user?.mainBalance?.balance;
  const { isAdminOrSuperAdmin } = getUserRole();

  const backHandler = () => setMoveToCompanyScreen("form");

  const styles = {
    headerContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    title: {
      fontSize: "24px",
      color: colors.neutral900,
    },
    subtitle: {
      color: colors.neutral800,
      fontSize: "12px",
      letterSpacing: "0.1px",
    },
    reason: {
      color: colors.neutral900,
      fontSize: "12px",
      letterSpacing: "0.2px",
    },
    leftAlign: {
      textAlign: "left",
    },
    list: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  const list = [
    isAdminOrSuperAdmin && {
      left: "Company balance",
      right: "IDR " + formatCurrencyNoDecimal(balance),
      styleRight: styles.leftAlign,
    },
    isAdminOrSuperAdmin && {
      spacer: true,
    },
    {
      left: "Amount to be returned",
      right: "IDR " + formatCurrencyNoDecimal(returnedAmount),
      rightBold: true,
      styleRight: styles.leftAlign,
    },
    {
      left: "Reason",
      right: (
        <GothamRegular style={styles.reason}>
          <SeeMore buttonAlign="left" height={80} width={170}>
            <ParserRichTextArea text={reason} />
          </SeeMore>
        </GothamRegular>
      ),
      styleRight: styles.leftAlign,
    },
  ];

  return (
    <>
      <div style={styles.headerContainer}>
        <GothamMedium style={styles.title}>Return balance summary</GothamMedium>
        {!isAdminOrSuperAdmin && (
          <GothamRegular style={styles.subtitle}>
            Your company will review and approve this action before <br />{" "}
            processing.
          </GothamRegular>
        )}
      </div>
      <CardInfoBalance cardDetail={cardDetail} text="From :" />
      <LeftRightList array={list} />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          onClick={() => {
            onSubmit({
              action_type: "withdraw",
              amount: +unformatCurrency(returnedAmount),
              reason,
            });
          }}
          isLoading={isLoading}
        >
          {isAdminOrSuperAdmin ? "Return Balance" : "Submit Request"}
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidMoveToCompanySummary;
