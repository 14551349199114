import React, { useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import BigTrash from "../../../assets/images/big-trash.png";
import { CustomButton } from "../../../components/Buttons";
import {
  MinimalistSelect,
  MinimalistTextField,
} from "../../../components/inputs";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { CenteredModalWithHeader } from "../../../modals/smartKYBUploadDocsModal/components";
import { TheadTablePayroll } from "./table/components";
import { useBooleanTable } from "./table/logic";
import deleteIcon from "../../../assets/images/delete-icon-pink.svg";
import plusIcon from "../../../assets/images/plus-pink-icon.svg";
import { formatCurrencyNoDecimal } from "../../../components/tools";
import { BackAndConfirmButton, ButtonAddRow } from "./components";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { ErrorMessageGenerator, useCheckerErrorAddRow } from "./logic";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { FormatterOptionsBank } from "./formatter";

export const dummyDataPayroll = [
  { name: "delete" },
  { name: "number" },
  { name: "name", value: "", label: "Name" },
  { name: "role", value: "", label: "Role" },
  { name: "department", value: "", label: "Department" },
  { name: "bank", value: "", label: "Bank" },
  { name: "account_number", value: "", label: "Bank Account Number" },
  { name: "notes", value: "", label: "Notes" },
  { name: "email", value: "", label: "Email" },
  { name: "net_salary", value: "", label: "Net Salary" },
  { name: "action" },
];

export const ModalDelete = ({
  title = "Are You Sure?",
  desc = "You will delete the transaction from the list.",
  isOpen,
  toggleDelete,
  confirmDelete,
}) => {
  if (!isOpen) return null;
  const [loading, setLoading] = useState(false);
  return (
    <CenteredModalWithHeader
      isOpen={isOpen}
      woClose
      toggle={toggleDelete}
      style={{ width: 400 }}
    >
      <div className="text-center">
        <img src={BigTrash} alt="trash" className="mb-4" />
        <GothamMedium
          style={{ color: colors.blue10, fontSize: 24, marginBottom: 8 }}
        >
          {title}
        </GothamMedium>
        <GothamRegular style={{ color: colors.grey6c }}>{desc}</GothamRegular>
        <CustomButton
          style={{
            width: "100%",
            marginTop: 24,
            marginBottom: 16,
            backgroundColor: colors.pink,
            color: "white",
            height: 48,
          }}
          isLoading={loading}
          onClick={() => {
            setLoading(true);
            toggleDelete();
            setTimeout(confirmDelete, 200);
          }}
        >
          Delete
        </CustomButton>
        <CustomButton
          style={{
            width: "100%",
            backgroundColor: colors.greye6,
            color: "white",
            height: 48,
          }}
          onClick={toggleDelete}
        >
          Cancel
        </CustomButton>
      </div>
    </CenteredModalWithHeader>
  );
};

export const ModalAddRow = ({
  title = "Are You Sure?",
  desc = "You will delete the transaction from the list.",
  isOpen,
  toggle,
  headerArray,
  setCombineData,
}) => {
  const { successToaster } = ToasterHook();
  // const data = [
  //   { name: "delete" },
  //   { name: "number" },
  //   { name: "name", value: "", label: "Name" },
  //   { name: "bank", value: "", label: "Bank" },
  //   { name: "account_number", value: "", label: "Bank Account Number" },
  //   { name: "net_salary", value: "", label: "Net Salary" },
  //   { name: "notes", value: "", label: "Notes" },
  //   { name: "role", value: "", label: "Role" },
  //   { name: "department", value: "", label: "Department" },
  //   { name: "email", value: "", label: "Email" },
  //   { name: "action" },
  // ];

  const { localTransferBanks: optionsBank } = useConstants();
  const formattedOptionsBank = FormatterOptionsBank({ options: optionsBank });
  const getIdBank = (valueRaw) =>
    formattedOptionsBank.filter(({ value }) => value == valueRaw)[0];

  const dummyData = [[...dummyDataPayroll]];
  const [renderFirst, setRenderFirst] = useState(true);
  const [showError, setShowError] = useState(false);
  const [defaultData, setDefaultData] = useState(dummyData);

  const handleAddRow = () => {
    setDefaultData([...defaultData, dummyDataPayroll]);
    setShowError(false);
  };

  const handleChangeRow = () => setRenderFirst((p) => !p);

  const handleDelete = (index) => {
    const filteredData = defaultData.filter(
      (_, childIndex) => childIndex !== index
    );
    setDefaultData(filteredData);
  };

  const handleAddData = () => {
    const isError = useCheckerErrorAddRow({ data: defaultData });

    if (isError) {
      setShowError(true);
      setRenderFirst(true);
      return;
    }
    setCombineData(defaultData);
    setDefaultData(dummyData);
    setRenderFirst(true);
    setShowError(false);
    successToaster({
      title: "Success!",
      msg: `${defaultData.length} employee has been added.`,
    });
    toggle();
  };

  const handleCloseModal = () => {
    setDefaultData(dummyData);
    setShowError(false);
    toggle();
  };

  useEffect(() => {
    if (defaultData.length === 0) {
      toggle();
      setDefaultData(dummyData);
    }
  }, [defaultData]);

  const handleChange = (event, index, name) => {
    const { name: valueSelect } = event || {};
    const { isBank, isSalary } = useBooleanTable({ name });
    const idBank = getIdBank(valueSelect);
    const { id } = idBank || {};

    const valueDecider = () => {
      if (isSalary) return formatCurrencyNoDecimal(event);
      if (isBank) return valueSelect;
      return event;
    };

    const value = valueDecider();
    setDefaultData((prev) => {
      const result = prev.map((array, childIndex) => {
        const isChangedRow = index == childIndex;
        if (!isChangedRow) return array;
        const newArray = array.map((item) => {
          const { name: currName } = item;
          if (currName != name) return item;
          return {
            ...item,
            id,
            details: idBank,
            value,
          };
        });
        return newArray;
      });
      return result;
    });
  };

  const styleDefault = {
    textAlign: "center",
    cursor: "pointer",
  };

  const wordingStep = renderFirst
    ? "Add new recipient to this batch transaction."
    : "To provide more details, fill out the emails and transaction notes. These fields are optional.";

  return (
    <CenteredModalWithHeader
      isOpen={isOpen}
      style={{ width: 1200, maxHeight: 600, overflowY: "hidden" }}
      closeModal={handleCloseModal}
    >
      <div>
        <GothamMedium
          style={{ fontSize: 24, color: colors.blue10, marginBottom: 8 }}
        >
          Add New Transaction
        </GothamMedium>
        <div
          className="d-flex align-items-center justify-content-between"
          style={{ marginBottom: 24 }}
        >
          <GothamRegular>{wordingStep}</GothamRegular>
          <ButtonAddRow onClick={handleAddRow} />
        </div>
        <table
          style={{
            wordBreak: "break-word",
            minWidth: 1040,
            borderBottom: "1px solid #E6E6E8",
            marginBottom: 16,
            maxHeight: "300px",
            display: "block",
            overflowY: "auto",
            position: "relative",
            zIndex: 0,
          }}
        >
          <TheadTablePayroll
            headerArray={headerArray}
            renderFirst={renderFirst}
            fromModal
          />
          <tbody>
            {defaultData?.map((data, index) => {
              return (
                <tr key={index}>
                  {data?.map((item, i) => {
                    const { name, value, label } = item || {};
                    const {
                      isSecondRender,
                      isFirstRender,
                      isNumber,
                      isName,
                      isAction,
                      isAccNumber,
                      isDelete,
                      isBank,
                      isRequired,
                      isSalary,
                    } = useBooleanTable({
                      name,
                    });
                    const { msg: errorMsg, isBelowMinimumAmount } =
                      ErrorMessageGenerator({
                        name,
                        value,
                      });
                    const isSelect = isBank;
                    const isValueEmpty = !Boolean(value);
                    const isError =
                      showError &&
                      isRequired &&
                      (isValueEmpty || isBelowMinimumAmount);
                    const isOnlyNumber = isSalary || isAccNumber;

                    const renderedValue = () => {
                      if (isNumber)
                        return (
                          <GothamRegular style={styleDefault}>
                            {index + 1}
                          </GothamRegular>
                        );
                      if (isAction)
                        return (
                          <img
                            style={styleDefault}
                            src={deleteIcon}
                            onClick={() => handleDelete(index)}
                          />
                        );
                      if (isSelect) {
                        return (
                          <MinimalistSelect
                            options={formattedOptionsBank}
                            onChange={(e) => handleChange(e, index, name)}
                            noArrowDropDown
                            value={value}
                            required={isRequired}
                            error={isError}
                            errorMsg={errorMsg}
                          />
                        );
                      }
                      return (
                        <MinimalistTextField
                          name={name}
                          error={isError}
                          errorMsg={errorMsg}
                          prefix={isSalary && "IDR"}
                          label={label}
                          value={value}
                          isNumber={isOnlyNumber}
                          required={isRequired && "*"}
                          setValue={(e) => handleChange(e, index, name)}
                        />
                      );
                    };

                    if (isDelete) return null;
                    if (renderFirst && isSecondRender) return null;
                    if (!renderFirst && isFirstRender) return null;
                    return (
                      <td
                        key={i}
                        style={{
                          padding: "12px 18px",
                          textAlign: (isAction || isNumber) && "center",
                          borderRight: isName && "1px solid #E6E6E8",
                          borderLeft: isAction && "1px solid #E6E6E8",
                        }}
                      >
                        {renderedValue()}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        <BackAndConfirmButton
          renderFirst={renderFirst}
          onClick={handleChangeRow}
          handleAddData={handleAddData}
        />
      </div>
    </CenteredModalWithHeader>
  );
};
