import { jackColors } from "../../assets/colors";
import { GothamRegular } from "../Text";

const DynamicText = ({ text, isCustomText }) => {
  if (isCustomText) return text;

  const isIncludes = (include) => String(text).includes(include);

  const isDrafterRequested = isIncludes("requested");
  const isDrafterCreated = isIncludes("created");
  const isDrafter = isDrafterCreated || isDrafterRequested;
  const isApprover = isIncludes("from");
  const isApproved = isIncludes("Approved by");
  const isRejected = isIncludes("Rejected by");
  const isCanceled = isIncludes("Canceled by");
  const isMarkRejected = isIncludes("Marked as rejected by");
  const isMarkApproved = isIncludes("Marked as approved by");
  const isReviewed = isIncludes("Reviewed by");
  const isReleasedBy = isIncludes("Payment has been released by");

  let splittedString = "";

  const textDecider = () => {
    const splitter = (split) => {
      splittedString = split;
      return String(text).split(split);
    };

    if (isDrafterRequested) return splitter("requested");
    if (isDrafterCreated) return splitter("created");
    if (isApprover) return splitter("from");
    if (isApproved) return splitter("Approved by");
    if (isRejected) return splitter("Rejected by");
    if (isCanceled) return splitter("Canceled by");
    if (isMarkRejected) return splitter("Marked as rejected by");
    if (isMarkApproved) return splitter("Marked as approved by");
    if (isReviewed) return splitter("Reviewed by");
    if (isReleasedBy) return splitter("Payment has been released by");
    return String(text);
  };

  const isSpecialCase =
    isDrafterRequested ||
    isDrafterCreated ||
    isApprover ||
    isApproved ||
    isRejected ||
    isMarkRejected ||
    isMarkApproved ||
    isCanceled ||
    isReviewed ||
    isReleasedBy;

  const textToRender = textDecider();

  if (isSpecialCase) {
    return (
      <>
        <span style={{ fontFamily: isDrafter ? "GothamMedium" : "GothamBook" }}>
          {textToRender[0] + " "}
        </span>
        <span>{splittedString}</span>
        <span style={{ fontFamily: isDrafter ? "GothamBook" : "GothamMedium" }}>
          {" " + textToRender[1]}
        </span>
      </>
    );
  }

  return <span>{textToRender}</span>;
};

const Text = ({ item, children, isCustomText }) => {
  const { isActive } = item || {};

  return (
    <GothamRegular
      style={{
        color: isActive ? jackColors.neutral900 : jackColors.neutral700,
        fontSize: "12px",
        lineHeight: "16px",
      }}
    >
      <DynamicText text={children} isCustomText={isCustomText} />
    </GothamRegular>
  );
};

export default Text;
