import { createContext, useContext, useState, useEffect } from "react";
import { useModalHook } from "../../components/Modals";
import { fetch } from "../../tools/api";
import { useRouter } from "next/router";
import { ModalContent } from "../../pageComponents/cardPageComponents/components/CardModal";
import CardTransactionDetail from "../../pageComponents/cardPageComponents/components/CardModal/screens/CardTransactionDetail";
import CardTransaction from "../../pageComponents/cardPageComponents/components/CardModal/screens/CardTransaction";
import MerchantLinked from "../../pageComponents/cardPageComponents/components/CardModal/screens/MerchantLinked";
import { isEmpty } from "lodash";
import { eventsTracker } from "../../universalFunctions/events";

export const CardDetailContext = createContext();

export const CardDetailContextProvider = ({
  children,
  setCards,
  cardDetail,
  toggle,
  isArchiveScreen,
  toggleConfirmDelete,
  isTask,
  setSelectedItem,
  isReveal,
  isAlert,
}) => {
  const [rightModalArray, setRightModalArray] = useState(array);
  const [rightModalWidth, setRightModalWidth] = useState([]);
  const [currentScreen, setCurrentScreen] = useState(false);

  const [cardDetailLatest, setCardDetailLatest] = useState({});
  const [unmaskedDataDetail, setUnmaskedDataDetail] = useState({});
  const [selectedTransaction, setSelectedTransaction] = useState(null);
  const [updatedTransaction, setUpdatedTransaction] = useState(null);
  const [hideClose, setHideClose] = useState(false);
  const [isHideModal, setIsHideModal] = useState(false);

  const { query, replace } = useRouter();
  const { cardTransactionId: queryCardTransactionId, isTopUp } = query;

  const rightModalToggle = () => {
    toggle();
    if (isTask || isAlert) return;
    setCurrentScreen("index");
    setUnmaskedDataDetail({});
    setSelectedItem && setSelectedItem(null);
    setCardDetailLatest({});
    let newQuery = {
      ...query,
      cardTransactionId: undefined,
      isCardRequest: undefined,
      id: undefined,
      runQueryTransaction: undefined,
      sort_by: undefined,
      merchant_name: undefined,
      transaction_status: undefined,
      isTopUp: undefined,
    };
    newQuery = Object.keys(newQuery).reduce((acc, key) => {
      if (newQuery[key] !== undefined) {
        acc[key] = newQuery[key];
      }
      return acc;
    }, {});
    replace({
      pathname: "/cards",
      query: newQuery,
    });
  };

  const afterSuccessCardDetail = (res) => {
    const item = res?.data;
    const { id, is_archived: isArchived } = item;
    setCardDetailLatest(item);
    if (isArchived && !isArchiveScreen) {
      rightModalToggle();
      return setCards((prev) => {
        const index = prev.findIndex((item) => item?.id === id);
        const newArray = [...prev];
        newArray.splice(index, 1);
        return newArray;
      });
    }

    setCards((prev) => {
      const index = prev.findIndex((item) => item?.id === id);
      const newArray = [...prev];
      newArray[index] = item;
      return newArray;
    });
  };

  const { refetch, loading: loadingCardDetail } = fetch({
    url: `/cards/${
      cardDetailLatest?.id ||
      cardDetail?.originator?.originator_id ||
      cardDetail?.originator_id ||
      cardDetail?.id
    }`,
    woInit: true,
    afterSuccess: afterSuccessCardDetail,
  });

  const { refetch: refetchBalance } = fetch({
    url: `/cards/${
      cardDetailLatest?.id ||
      cardDetail?.originator?.originator_id ||
      cardDetail?.id
    }`,
    woInit: true,
    afterSuccess: (res) => {
      afterSuccessCardDetail(res);
    },
  });

  const { refetch: refetchMainBalance, data: mainBalance } = fetch({
    url: "/my_company_balance",
    formatter: (res) => res?.data?.disbursement_balance[0]?.balance,
  });

  const toggleAdjacentModal = (name, component) => {
    closeAdjacentModal();
    let isOpenAdjacentModal;
    setRightModalArray((prev) => {
      isOpenAdjacentModal = Boolean(
        prev.find((item) => item?.type?.name === name)
      );
      if (isOpenAdjacentModal) return [prev[0]];

      const newArray = [...prev];
      newArray.push(component);
      return newArray;
    });
    if (isOpenAdjacentModal) return setRightModalWidth([420]);

    setRightModalWidth((prev) => {
      const newArray = [...prev];
      newArray.push(510);
      return newArray;
    });
  };

  const openTransactionList = () => {
    eventsTracker("card_open_transaction_list", {
      card_id: cardDetailLatest?.id,
    });
    toggleAdjacentModal("CardTransaction", <CardTransaction />);
    setSelectedTransaction(null);
  };

  const closeTransactionList = () => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  };

  const openMerchantLinked = () => {
    eventsTracker("card_open_merchant_linked", {
      card_id: cardDetailLatest?.id,
    });
    toggleAdjacentModal("MerchantLinked", <MerchantLinked />);
  };

  const closeAdjacentModal = () => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  };

  const resetModal = (screen) => {
    if (currentScreen == screen) return;

    setCurrentScreen(screen);
    closeAdjacentModal();
  };

  const openTransactionDetail = () => {
    eventsTracker("card_transaction_open_detail", {
      card_transaction_id: selectedTransaction?.id,
    });
    setRightModalArray([
      <ModalContent />,
      <CardTransaction />,
      <CardTransactionDetail
        transactionDetail={selectedTransaction}
        setUpdatedTransaction={setUpdatedTransaction}
      />,
    ]);

    setRightModalWidth([420, 510, 420]);
  };

  useEffect(() => {
    if (cardDetail) refetch();
  }, [cardDetail]);

  useEffect(() => {
    if (selectedTransaction) openTransactionDetail();
  }, [selectedTransaction]);

  useEffect(() => {
    setRightModalArray(array);
    setRightModalWidth([420]);
  }, [cardDetailLatest, unmaskedDataDetail]);

  useEffect(() => {
    const isCardDetailExist = !isEmpty(cardDetailLatest);
    if (queryCardTransactionId && isCardDetailExist)
      setSelectedTransaction({ id: queryCardTransactionId });
    if (isTopUp && isCardDetailExist) {
      setTimeout(() => {
        setCurrentScreen("prepaidTopUpMethod");
      }, 500);
    }
  }, [cardDetailLatest, queryCardTransactionId, isTopUp]);

  useEffect(() => {
    resetModal(currentScreen);
    closeAdjacentModal();
  }, [currentScreen]);

  useEffect(() => {
    if (isReveal) setIsHideModal(true);
  }, [isReveal]);

  const array = [<ModalContent />];

  const value = {
    rightModalArray,
    rightModalWidth,
    rightModalToggle,
    cardDetail: cardDetailLatest,
    unmaskedDataDetail,
    openTransactionList,
    closeTransactionList,
    refetchCardDetail: refetch,
    setHideClose,
    refetchBalance,
    hideClose,
    updatedTransaction,
    setUpdatedTransaction,
    selectedTransaction,
    openTransactionDetail,
    setSelectedTransaction,
    loadingCardDetail,
    currentScreen,
    setCurrentScreen,
    mainBalance,
    refetchMainBalance,
    toggleConfirmDelete,
    openMerchantLinked,
    isTask,
    closeAdjacentModal,
    setUnmaskedDataDetail,
    isReveal,
    isHideModal,
    setIsHideModal,
  };

  return (
    <CardDetailContext.Provider value={value}>
      {children}
    </CardDetailContext.Provider>
  );
};

export const useCardDetailContext = () => useContext(CardDetailContext);
