import { colors } from "../../../../../assets/colors";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
  TextInlineMedium,
} from "../../../../../components/Text";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";
import {
  cardStateDecider,
  limitTypeDecider,
  cardTypeDecider,
} from "../../../tools";
import { Banner } from "../../../../../components/Banner";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { billCycleDecider } from "../../../tools";

const LeftRightList = ({ list, bottomComponent }) => {
  const containerStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
  };

  const innerContainerStyle = {
    display: "flex",
  };

  const leftStyle = {
    width: "50%",
  };

  const leftTextStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    color: colors.neutral700,
  };

  const rightTextStyle = {
    fontSize: "12px",
    lineHeight: "16px",
    color: colors.neutral900,
  };

  const rightStyle = {
    width: "50%",
    display: "flex",
    justifyContent: "flex-end",
  };

  return (
    <div style={containerStyle}>
      {list.map(({ left, right }, index) => {
        const isRightString = typeof right === "string";
        return (
          <div style={innerContainerStyle} key={index}>
            <div style={leftStyle}>
              <GothamRegular style={leftTextStyle}>{left}</GothamRegular>
            </div>
            {isRightString ? (
              <div style={rightStyle}>
                <GothamRegular style={rightTextStyle}>{right}</GothamRegular>
              </div>
            ) : (
              <div style={rightStyle}>{right}</div>
            )}
          </div>
        );
      })}
      {bottomComponent}
    </div>
  );
};

const SuspendedBanner = ({ cardDetail }) => {
  const totalBill = cardDetail?.get_active_billing?.total_bill;
  const { user } = useGetAuth();
  const period = user?.partner?.get_active_bill?.period;
  const businessId = user?.partner?.id;

  const { startingMonth, endingMonth, startingYear, endingYear } =
    billCycleDecider(period, businessId);
  return (
    <Banner
      msg={`Payment overdue. Please pay IDR ${formatCurrencyNoDecimal(
        totalBill
      )} for your bill of 26 ${startingMonth} ${startingYear} - 25 ${endingMonth} ${endingYear}.`}
      type="error"
    />
  );
};

const CardSpending = ({ cardDetail }) => {
  const {
    card_type: cardType,
    get_active_billing,
    is_adjusting_limit,
    is_suspended: isSuspended,
    limit_type: limitType,
    limit_value: limitValue,
    state,
    total_remaining_bill_and_spent,
  } = cardDetail || {};
  const { value: isAdjustingLimit, amount: newLimit } =
    is_adjusting_limit || {};
  const {
    available_spend,
    current_remaining_bill: currentRemainingBill,
    total_spent: totalSpent,
  } = total_remaining_bill_and_spent || {};
  const { total_bill: totalBill = 0 } = get_active_billing || {};

  const availableSpend = available_spend > 0 ? available_spend : 0;
  const { isNotActive, isRejected, isPermanentBlock, isActivating } =
    cardStateDecider(state);
  const { isPrepaid } = cardTypeDecider(cardType);
  const { user } = useGetAuth();
  const isLoggedInUser = user?.id === cardDetail?.user_id;

  if (
    isNotActive ||
    isRejected ||
    isPermanentBlock ||
    isActivating ||
    (isAdjustingLimit && !isLoggedInUser)
  )
    return null;

  const billToPay = +totalBill + +currentRemainingBill;

  const limitPeriod = limitTypeDecider(limitType);

  const availableSpendStyle = {
    fontSize: "12px",
    color: colors.neutral900,
    marginBottom: "0",
  };

  const limitContainerStyle = {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    gap: "2px",
  };

  const limitValueStyle = {
    fontSize: "12px",
    color: colors.neutral900,
  };

  const limitTypeStyle = {
    fontSize: "10px",
    color: colors.neutral700,
    marginTop: "-4px",
  };

  const bannerMessageStyle = {
    fontSize: "12px",
    color: colors.neutral800,
    letterSpacing: "0.2px",
  };

  const bottomComponent = isAdjustingLimit && isLoggedInUser && (
    <Banner
      title="Limit adjustment is in progress"
      msg={
        <>
          We're working on changing your limit from{" "}
          <TextInlineMedium>
            IDR {formatCurrencyNoDecimal(limitValue)}{" "}
          </TextInlineMedium>
          to{" "}
          <TextInlineMedium>
            IDR {formatCurrencyNoDecimal(newLimit)}
          </TextInlineMedium>
        </>
      }
      msgStyle={bannerMessageStyle}
    />
  );

  const listPhysicalVirtual = [
    {
      left: "Available to spend",
      right: (
        <GothamBold style={availableSpendStyle}>
          IDR {formatCurrencyNoDecimal(availableSpend)}
        </GothamBold>
      ),
    },
    {
      left: "Limit used",
      right: `IDR ${formatCurrencyNoDecimal(totalSpent)}`,
    },
    {
      left: "Bill to pay",
      right: `IDR ${formatCurrencyNoDecimal(billToPay)}`,
    },
    {
      left: "Card limit",
      right: (
        <div style={limitContainerStyle}>
          <GothamRegular style={limitValueStyle}>
            IDR {formatCurrencyNoDecimal(limitValue)}
          </GothamRegular>
          <GothamRegular style={limitTypeStyle}>{limitPeriod}</GothamRegular>
        </div>
      ),
    },
  ];

  const listPrepaid = [
    {
      left: "Total spent",
      right: `IDR ${formatCurrencyNoDecimal(totalSpent)}`,
    },
  ];

  const list = isPrepaid ? listPrepaid : listPhysicalVirtual;

  const containerStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    gap: "16px",
    border: `1px solid ${colors.neutral500}`,
    padding: "12px",
    gap: "16px",
  };

  return (
    <div style={containerStyle}>
      <GothamMedium>Spending This Cycle</GothamMedium>
      {isSuspended ? (
        <SuspendedBanner cardDetail={cardDetail} />
      ) : (
        <LeftRightList list={list} bottomComponent={bottomComponent} />
      )}
    </div>
  );
};

export default CardSpending;
