import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  REIMBURSEMENT_FIFTH_TARGET,
  REIMBURSEMENT_SIXTH_TARGET,
  REIMBURSEMENT_SEVENTH_TARGET,
} from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    disableScrolling: false,
    target: `#${REIMBURSEMENT_SIXTH_TARGET}`,
    content: (
      <TooltipContent
        title="Input fields manually"
        description="Since you don’t have the receipt yet, you need to manually input these fields."
      />
    ),
    placement: "right",
    locale: {
      back: <NextText>Back</NextText>,
      close: <NextText>Next</NextText>,
    },
  },
  {
    ...DEFAULT_PROPERTIES,
    disableScrolling: false,
    target: `#${REIMBURSEMENT_FIFTH_TARGET}`,
    content: (
      <TooltipContent
        title="Found your receipt?"
        description="Upload it here and we will generate the expense details automatically. "
      />
    ),
    locale: {
      back: <NextText>Back</NextText>,
      close: <NextText>Next</NextText>,
    },
    placement: "left",
  },
  {
    ...DEFAULT_PROPERTIES,
    disableScrolling: false,
    target: `#${REIMBURSEMENT_SEVENTH_TARGET}`,
    content: (
      <TooltipContent
        title="Supporting Document"
        description="Attach any relevant document to help the Approver(s) identify your request."
      />
    ),
    locale: {
      back: <NextText>Back</NextText>,
      close: <NextText>Got it</NextText>,
    },
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle } = data ?? {};

  const isComplete = lifecycle === "complete";

  if (!isComplete) return;

  switch (action) {
    case "close":
      if (index < 2) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex + 1,
        }));
        break;
      }
      if (index === 2) {
        setGuidedTourState(defaultState);
        break;
      }
      break;

    case "prev":
      if (index > 0) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex - 1,
        }));
        break;
      }
      break;

    default:
      break;
  }
};

const reimbursementFourthPageProps = { steps, callback };

export default reimbursementFourthPageProps;
