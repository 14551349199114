import { eventsTracker } from "../../../../universalFunctions/events";
import {
  DRAFT_FIRST_TARGET,
  DRAFT_SECOND_TARGET,
  TOOLTIP_DEFAULT_PROPERTIES,
} from "../../constants";
import { DEMO_TOOLTIP_CLOSE, DEMO_TOOLTIP_NEXT } from "../../constants/events";

const steps = [
  {
    ...TOOLTIP_DEFAULT_PROPERTIES,
    target: `#${DRAFT_FIRST_TARGET}`,
    title: "We have organized your drafts!",
    content:
      "This tab helps you to track your drafts based on transaction type.",
    placement: "bottom",
    eventPayload: {
      page_title: "draft",
      tooltip_activity: "draft_tab",
    },
  },
  {
    ...TOOLTIP_DEFAULT_PROPERTIES,
    target: `#${DRAFT_SECOND_TARGET}`,
    title: "Filter by date",
    content: "You can filter drafted transactions by their last saved date.",
    placement: "left",
    eventPayload: {
      page_title: "draft",
      tooltip_activity: "draft_filter_date",
    },
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle, step } = data ?? {};
  const { eventPayload = {} } = step ?? {};

  const stepCount = steps.length - 1;

  const isComplete = lifecycle === "complete";
  const isSkipped = action === "skip";

  if (isSkipped) {
    eventsTracker(DEMO_TOOLTIP_CLOSE, eventPayload);
  }

  if (!isComplete) return;

  switch (action) {
    case "close":
    case "next":
      eventsTracker(DEMO_TOOLTIP_NEXT, eventPayload);
      if (index < stepCount) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex + 1,
        }));
        break;
      }
      if (index === stepCount) {
        setGuidedTourState(defaultState);
        break;
      }
      break;

    case "prev":
      if (index > 0) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex - 1,
        }));
        break;
      }
      break;

    default:
      break;
  }
};

const draftProps = { steps, callback };

export default draftProps;
