import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  REIMBURSEMENT_EIGHTH_TARGET,
} from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${REIMBURSEMENT_EIGHTH_TARGET}`,
    content: (
      <TooltipContent
        title="Select bank account"
        description="Recent bank account will be selected automatically. You can select another or add new bank accout."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_reimbursement",
      guided_tour_activity: "select_bank_account"
    },
  },
];

const reimbursementFifthPageProps = { steps };

export default reimbursementFifthPageProps;
