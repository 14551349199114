import { restrictedPath } from "../pageComponents/productDemoComponents/constants/pathname";

export const canBeAccessedAllTheTimePathnames = [
  "/",
  "/home",
  "/login-demo",
  "/login",
  "/register",
  "/create-password",
  "/account-details",
  "/verification",
  "/settings",
  "/local-transfer",
  "/local-disbursement",
  "/kyb",
  "/account/invite-users",
  // "/cards",
  "/old-activity",
  // "/workflow",
  // "/e-wallet",
  "/task",
  "/history",
  "/success",
  "/dashboard",
  "/transactions",
  "/kyb-jack",
  "/alerts-and-attention",
  "/people-management",
  "/draft",
  "/reset-pin",
  "/change-password",
  "/email-redirect",
  // "/plans",
  "/terms-and-conditions",
  "/tnc-login",
  "/tnc-subscription",
  // "/calendar",
  "/jack-transfer",
  
  // dihide for demo purpose
  // "/settings-jack",
  // "/bank-account",
  // "/notifications",
  // "/payment-cycle",
  // "/dev-tools",
  // "/plans/showcase",
  // "/category",
];

// bikin lemot anjim
// // DEMO PURPOSE 
// export const canBeAccessedAllTheTimePathnames =
// canBeAccessedAllTheTimePathnamesRaw.filter((item) =>
// !restrictedPath.includes(item)
// );

export const activitiesORTransactionsPathnames = ["/home", "/history"];

export const createTransactionPathnames = ["/cross-border"];

export const VAPathnames = ["/virtual-account", "/collection-report"];

export const accountPathnames = ["/account"];

export const settingPathnames = [
  "/menu-api",
  "/settings",
  "/workflow",
  // "/customize-receipt", // dihide for demo
];
