import { snakeCase } from "lodash";
import { useRouter } from "next/router";
import { useContext, useEffect, useRef, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import NewTag from "../../../components/NewTag";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import TransferSelectionModal from "../../../modals/TransferSelectionModal";
import { J2J_EVENT_CLICK_TRANSFER_BALANCE } from "../../../pageComponents/jackTransferComponents/constants";
import { NotificationIcon } from "../../../pageComponents/notifications/components";
import { NotificationModal } from "../../../pageComponents/notifications/parent";
import FloatingTooltip from "../../../pageComponents/productDemoComponents/components/FloatingTooltip";
import {
  DEMO_FIFTH_TARGET,
  DEMO_FIRST_TARGET,
  DEMO_FOURTH_TARGET,
  DEMO_SECOND_TARGET,
  DEMO_SEVENTH_TARGET,
  DEMO_SIXTH_TARGET,
  DEMO_THIRD_TARGET,
} from "../../../pageComponents/productDemoComponents/constants";
import PreventionModal from "../../../pageComponents/subscriptionsJackComponents/general-components/PreventionModal/parent";
import { useSubscriptionUsage } from "../../../pageComponents/subscriptionsJackComponents/logics/general-hooks";
import { eventsTracker } from "../../../universalFunctions/events";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { getUserButtonBooleans, getUserRole } from "../../AuthContext";
import { useConstants } from "../../ConstantsContext/parent";
import { localStorageKey, useDemo } from "../../DemoContext";
import { TaskContext } from "../../TaskContext/parent";
import { useLayout } from "../parent";

export const CreateAndBellHeader = ({ style = {} }) => {
  const [preventionModalType, setPreventionModalType] = useState("");

  const { push, replace, pathname, query } = useRouter();

  const {
    invoice_qty_left,
    reimbursement_qty_left,
    isSeedPlan,
    isSuspended,
    loadingSubscriptionUsage,
  } = useSubscriptionUsage();

  const isSeedV2Invoice = isSeedPlan && invoice_qty_left != null;
  const isSeedV2Reimbursement = isSeedPlan && reimbursement_qty_left != null;
  const isReachedInvoiceLimit = isSeedV2Invoice && invoice_qty_left === 0;
  const isReachedReimbursementLimit =
    isSeedV2Reimbursement && reimbursement_qty_left === 0;

  const { isOpen, toggle, close } = useModalHook();
  const {
    isOpen: isOpenPreventionModal,
    open: openPreventionModal,
    close: closePreventionModal,
  } = useModalHook();
  const {
    isOpen: isOpenTransferSelectionModal,
    open: openTransferSelectionModal,
    close: closeTransferSelectionModal,
  } = useModalHook();

  const { canCreateCard, canCreateVA } = getUserButtonBooleans();
  const canSeeFeatures = canCreateVA || canCreateCard;

  const {
    isDrafterInvoice,
    isDrafterReimbursement,
    isDrafterPayroll,
    isDrafterSendMoney,
    isAdminOrSuperAdmin,
    isDrafterBalanceManagement,
  } = getUserRole();

  const handlePreventionModal = (type = "invoice") => {
    setPreventionModalType(type);
    openPreventionModal();
  };

  const handleClick = (value) => {
    if (loadingSubscriptionUsage) return;

    const isInvoice = value.includes("invoice");
    const isReimbursement = value.includes("reimbursement");
    const isSendMoney = value === "send_money";
    const isDraft = value === "/draft";

    const isPreventCreateInvoice = isInvoice && isReachedInvoiceLimit;
    const isPreventCreateReimbursement =
      isReimbursement && isReachedReimbursementLimit;

    if (isSendMoney) {
      eventsTracker(J2J_EVENT_CLICK_TRANSFER_BALANCE, {
        parameters: { page_title: "dashboard" },
        value: "dashboard",
      });
      return openTransferSelectionModal();
    }
    if (isPreventCreateInvoice) return handlePreventionModal("invoice");
    if (isPreventCreateReimbursement)
      return handlePreventionModal("reimbursement");
    const pathWoQuery = value.split("?")[0];
    eventsTracker(
      `topbar_create_to_${snakeCase(pathWoQuery)}`,
      isDraft && { page_title: "create_button" }
    );
    push(value);
  };

  const array = [
    {
      title: "Transfer",
      array: [
        isDrafterBalanceManagement && {
          label: "Between Jack",
          iconName: "swap-outline",
          value: "/jack-transfer",
          // isNew: true,
        },
        isDrafterSendMoney && {
          label: "International Transfer",
          iconName: "international-transfer",
          value: "/cross-border/single",
          id: DEMO_SECOND_TARGET,
        },
        isDrafterSendMoney && {
          label: "Local Transfer",
          iconName: "send-money",
          value: "/local-transfer/create",
          id: DEMO_THIRD_TARGET,
        },
      ].filter((item) => item),
    },
    {
      title: "Payables",
      array: [
        isDrafterReimbursement && {
          label: "Reimbursement",
          iconName: "Reimbursement",
          value: "/reimbursement/create?step=input-name",
          id: DEMO_FOURTH_TARGET,
        },
        isDrafterInvoice && {
          label: "Bill Payment",
          iconName: "Invoice Payment",
          value: "/invoice-payment/create?step=upload",
          id: DEMO_FIFTH_TARGET,
        },
        isDrafterPayroll && {
          label: "Payroll",
          iconName: "payroll",
          value: "/payroll/create",
          id: DEMO_SIXTH_TARGET,
        },
      ].filter((item) => item),
    },
    canSeeFeatures && {
      title: "Features",
      array: [
        canCreateCard && {
          label: "New Card",
          value: "/cards/create",
          iconName: "add-card",
          eventsName: "card_navigate_to_create_card",
        },
        canCreateVA && {
          label: "Virtual Account",
          value: "/virtual-account/create",
          id: DEMO_SEVENTH_TARGET,
          iconName: "coin",
        },
      ].filter((item) => item),
    },
    {
      array: [
        { label: "Draft", value: "/draft", iconName: "archive-outline" },
      ].filter((item) => item),
    },
  ].filter((item) => item);

  const ref = useRef();
  const { clientWidth = 0 } = ref?.current || {};

  const { scrollTop } = useLayout();

  useEffect(close, [scrollTop]);

  const [refChild, setRefChild] = useState({});
  const padding = 8;
  const componentHeight = (refChild?.clientHeight || 0) + padding * 2;

  // DEMO PURPOSE
  const { openCreate } = query ?? {};
  const { guidanceState, setGuidanceState } = useDemo();
  const {
    isTourRunning = false,
    stop,
    isHelperClicked = false,
  } = guidanceState || {};

  const clickOutsideDependencies = !isTourRunning && !isHelperClicked;

  useClickOutside({
    ref,
    clickOutside: () => {
      if (!isTourRunning && !isHelperClicked) {
        close();
        // delete query?.openCreate;
        // push({ query });
        return;
      }
    },
    dependency: clickOutsideDependencies,
  });

  const handleClickCreate = () => {
    // demo purpose dihide
    // if (isSuspended) {
    //   setPreventionModalType("suspended");
    //   openPreventionModal();
    //   return;
    // }

    eventsTracker("topbar_create_open_dropdown");
    toggle();
  };

  const {
    counterTotal: totalUnread,
    refetchTotal,
    isOpen: isOpenNotif,
    toggle: toggleNotif,
    close: closeNotif,
    setDataTotal,
  } = useConstants();

  const handleClickBell = () => {
    eventsTracker("click_bell_notif");
    toggleNotif();
    // setDataTotal(0)
  };

  // const {
  //   isOpen: isOpenNotif,
  //   toggle: toggleNotif,
  //   close: closeNotif,
  // } = useModalHook();

  const handleCloseNotif = () => {
    const newQuery = {};
    replace({
      query: {
        ...newQuery,
      },
    });
    refetchTotal();
    closeNotif();
  };

  useEffect(() => {
    refetchTotal();

    const intervalId = setInterval(() => {
      refetchTotal();
    }, 20000); // 20 seconds in milliseconds

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const { isOpen: isOpenCreateInfo, toggle: toggleCreateInfo } =
    useModalHook(false);
  const isTaskPage = pathname === "/task";
  const { totalTask, loading } = useContext(TaskContext);
  const isTaskEmpty = totalTask === 0;

  useEffect(() => {
    if (!ref?.current) return;
    setIsMounted(true);
  }, [ref?.current]);

  useEffect(() => {
    if (loading) return;
    if (!isTaskPage) return;
    if (!isTaskEmpty) return;
    if (!isMounted) return;

    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted, isTaskPage, isTaskEmpty, loading]);

  useEffect(() => {
    if (!openCreate) return;
    handleClickCreate();
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [openCreate]);

  const tooltipPropsDecider = () => {
    const isTaskTooltip = !openCreate;

    return {
      title: isTaskTooltip
        ? "Since your task list is empty..."
        : "Start your request/transaction",
      body: isTaskTooltip
        ? "It's a perfect time to create new transaction request!"
        : "Select one that you'd like to explore",
      translateTriangle: isTaskTooltip ? "234px -10px" : "260px 60px",
      tooltipTranslate: isTaskTooltip ? "-180px 10px" : "-425px -40px",
      position: isTaskTooltip ? "bottom" : "left",
    };
  };

  const { title, body, translateTriangle, tooltipTranslate, position } =
    tooltipPropsDecider();

  return (
    <div
      className="d-flex justify-content-end align-items-center"
      style={{
        paddingRight: 32,
        paddingTop: 16,
        paddingBottom: 24,
        ...style,
        gap: 16,
      }}
    >
      <JackIcons
        name="help"
        fill={jackColors.black34}
        onClick={() => {
          eventsTracker("topbar_create_open_commandbar");
          window.CommandBar.open();
        }}
        style={{ height: 20, width: 20 }}
        className="hover"
      />
      <NotificationIcon
        handleClickBell={handleClickBell}
        counter={totalUnread}
      />
      <div ref={ref}>
        <FloatingTooltip
          isOpen={isHoveredInfo}
          close={() => {
            delete query?.openCreate;
            push({ query });
            setIsHoveredInfo(false);
          }}
          title={title}
          body={body}
          tooltipStyle={{
            translate: tooltipTranslate,
            width: 260,
          }}
          translateTriangle={translateTriangle}
          position={position}
        >
          <ButtonJack
            leftIcon={
              <JackIcons
                name="plus-outline"
                fill={
                  loadingSubscriptionUsage
                    ? jackColors.neutral700
                    : jackColors.black34
                }
              />
            }
            onClick={handleClickCreate}
            isLoading={loadingSubscriptionUsage}
            className={DEMO_FIRST_TARGET}
          >
            Create
          </ButtonJack>
        </FloatingTooltip>

        <FloatingTooltip
          isOpen={isOpenCreateInfo}
          close={() => toggleCreateInfo()}
          title="Since your task list is empty..."
          body="It's a perfect time to create new transaction request!"
          tooltipStyle={{
            translate: "-420px -40px",
            width: 260,
          }}
          translateTriangle={`260px 55px`}
          position="left"
        >
          <div
            style={{
              backgroundColor: "white",
              boxShadow: "0px 8px 16px -4px rgba(22, 34, 51, 0.08)",
              borderRadius: 8,
              width: 256,
              position: "fixed",
              marginLeft: clientWidth - 256,
              padding: 8,
              zIndex: 10,
              height: isOpen ? componentHeight : 0,
              overflow: "hidden",
              transition: "all 0.4s linear",
              visibility: isOpen ? "initial" : "hidden",
            }}
          >
            <div ref={(e) => setRefChild(e)}>
              {array.map((item, index) => {
                const { title, array } = item;

                if (!array.length) return null;

                return (
                  <div key={index}>
                    <GothamMedium
                      style={{ padding: 8, color: jackColors.black34 }}
                    >
                      {title}
                    </GothamMedium>
                    <div
                      className="w-100"
                      style={{ height: 1, backgroundColor: jackColors.greyE6 }}
                    />

                    {array.map((item, index) => {
                      if (!item) return null;

                      const {
                        label,
                        value,
                        iconName,
                        eventsName,
                        isNew,
                        id = null,
                      } = item;

                      const handleClickCreateItem = () => {
                        stop(); // to stop tour
                        // if guidance mode => set local storage
                        if (isHelperClicked) {
                          localStorage.setItem(localStorageKey, "true");
                          setGuidanceState((prev) => ({
                            ...prev,
                            guided: true,
                            isHelperClicked: false,
                          }));
                        } else {
                          // else remove local storage
                          localStorage.removeItem(localStorageKey);
                          setGuidanceState((prev) => ({
                            ...prev,
                            guided: false,
                          }));
                        }
                        handleClick(value);
                        eventsName && eventsTracker(eventsName);
                      };

                      return (
                        <div
                          id={id}
                          key={index}
                          className="d-flex align-items-center hover"
                          style={{
                            padding: 10,
                            paddingLeft: 8,
                            paddingRight: 8,
                            gap: 8,
                          }}
                          onClick={handleClickCreateItem}
                        >
                          <JackIcons name={iconName} fill={jackColors.grey6C} />
                          <GothamRegular>{label}</GothamRegular>
                          {isNew && <NewTag />}
                        </div>
                      );
                    })}
                  </div>
                );
              })}
            </div>
          </div>
        </FloatingTooltip>
      </div>

      <PreventionModal
        type={preventionModalType}
        isOpen={isOpenPreventionModal}
        toggle={closePreventionModal}
        onClick={() => {
          if (preventionModalType === "suspended")
            return closePreventionModal();
          push("/plans/showcase");
        }}
      />
      <NotificationModal isOpen={isOpenNotif} toggle={handleCloseNotif} />
      <TransferSelectionModal
        isOpen={isOpenTransferSelectionModal}
        toggle={closeTransferSelectionModal}
      />
    </div>
  );
};
