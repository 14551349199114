import UpdateTracker from "../../../../../components/UpdateTracker/parent";
import { updatesTrackerDecider } from "../../../screens/SuccessScreen/tools";
import { cardStateDecider, cardTypeDecider } from "../../../tools";
import { isEmpty } from "lodash";

const CardUpdatesTracker = ({ cardDetail }) => {
  const {
    card_type: cardType,
    current_workflow_process: currentWorkflowProcess,
    is_seen: isSeen,
    user: { status: ownerStatus } = {},
  } = cardDetail || {};
  const isCurrentWorkflowActive = currentWorkflowProcess === "request_active";

  const { isPhysical, isPrepaid } = cardTypeDecider(cardType);
  const isNotShowTracker = isPhysical
    ? isSeen
    : isSeen || !isCurrentWorkflowActive;

  const isPending = ownerStatus !== "active";

  if (isNotShowTracker || isPending || isPrepaid) return null;
  const { updates, rejectionNote } = updatesTrackerDecider(cardDetail);

  if (isEmpty(updates)) return null;
  return <UpdateTracker updates={updates} rejectionNote={rejectionNote} />;
};

export default CardUpdatesTracker;
