import { useRouter } from "next/router";
import React, { useEffect, useRef, useState } from "react";
import { useModalHook } from "../../../../components/Modals";
import { useDemo } from "../../../../contexts/DemoContext";
import { useGuidedTour } from "../../../../contexts/GuidedTourContext";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import { eventsTracker } from "../../../../universalFunctions/events";
import { useClickOutside } from "../../../../universalFunctions/useClickOutside";
import useGuidedTourBuilder from "../../../productDemoComponents/builders";
import {
  PAYROLL_FOURTH_PAGE,
  PAYROLL_MODULE,
  PAYROLL_SIXTH_TARGET,
} from "../../../productDemoComponents/constants";
import { recipientData } from "../../../productDemoComponents/constants/localTransfer";
import {
  emptyData,
  prefilledDataPayroll,
} from "../../../productDemoComponents/constants/payroll";
import { dummyDataPayroll } from "../modal";
import { TbodyTablePayroll, TheadTablePayroll } from "./components";
import { useBooleanTable } from "./logic";
import { isEqual } from "lodash";

export const TableCreatePayroll = ({
  data,
  style,
  setData,
  noBorder,
  isEmptyData,
  afterAddRow,
  headerArray,
  filteredData,
  showDeleteRow,
  funcPropsTable,
  isScreenDuplicate,
  isFirstScreenTable,
  isScreenIncomplete,
  setSelectedIdsDelete,
  isEmptyTable,
}) => {
  const [modalIndex, setModalIndex] = useState([]);
  const [indexDelete, setIndexDelete] = useState(false);

  const { successSnackBar, errorToaster } = ToasterHook();
  const { isOpen, close, toggle } = useModalHook();
  const {
    isOpen: isOpenDelete,
    close: closeDelete,
    toggle: toggleDelete,
  } = useModalHook();

  const refClick = useRef(null);
  const refTable = useRef(null);
  const { clientWidth } = refTable.current || {};
  const rightOffset = clientWidth - 1075;
  useClickOutside({ ref: refClick, clickOutside: close });

  const {
    handleClickItem,
    handleUncheckedAll,
    handleClickAll,
    isAllChecked,
    selectedIds,
  } = funcPropsTable || {};

  const isAnythingSelected = !!selectedIds?.length;

  const handleClick = (data, item, index, childIndex) => {
    const { name } = item;
    const { isAction, isNumber, isDelete } = useBooleanTable({ name });

    if (isNumber || isDelete) return;
    if (isAction) {
      setIndexDelete(index);
      toggleDelete();
      return;
    }
    return handleEdit(item, index, childIndex);
  };

  const handleEdit = (item, index, childIndex) => {
    toggle();
    setModalIndex([index, childIndex]);
  };

  const handleDelete = () => {
    const isOnlyOneData = data?.length === 1;
    const filteredData = data.filter((item, index) => index !== indexDelete);
    const deletedData = data.filter((_, index) => index == indexDelete);
    // toggleDelete();

    successSnackBar({
      title: "Deleted!",
      msg: "1 transaction has been deleted.",
    });
    handleUncheckedAll();
    eventsTracker("payroll_table_delete_single_recipient", deletedData);
    if (isOnlyOneData) return setData([dummyDataPayroll]);
    setData(filteredData);
  };

  useEffect(() => {
    setSelectedIdsDelete && setSelectedIdsDelete(selectedIds);
  }, [selectedIds]);

  const refScrollBottom = useRef(null);

  useEffect(() => {
    if (afterAddRow) {
      refScrollBottom?.current?.scrollTo({ behavior: "smooth", top: 9999999 });
    }
  }, [afterAddRow]);

  // demo purpose
  const guidedTourProps = useGuidedTourBuilder({
    module: PAYROLL_MODULE,
    page: PAYROLL_FOURTH_PAGE,
    methods: isEmptyTable ? "" : "csv",
  });

  const { startTour, stopTour } = useGuidedTour({
    ...guidedTourProps,
    stepIndex: 0,
    props: {
      continuous: true,
    },
  });
  const { guidanceState, setGuidanceState } = useDemo();
  const { isGuidanceMode, isTourRunning } = guidanceState ?? {};

  useEffect(() => {
    if (!isGuidanceMode) return;
    if (isEmptyData) return;
    setTimeout(() => {
      setGuidanceState((prev) => ({ ...prev, isTourRunning: true }));
      startTour();
    }, 500);
  }, [isEmptyData]);

  const { query, push } = useRouter();
  const { step, action } = query ?? {};

  const firstRowData = data?.[0];

  const isEqualDummyAndData = isEqual(firstRowData, prefilledDataPayroll?.[0]);
  const isDataFilled = firstRowData
    ?.slice(2, 10)
    .some((item) => item.value !== "");

  const setRecipient = () => handleClick(data, ...recipientData);

  useEffect(() => {
    if ((step == 0 && action == "next") || (step == 2 && action == "prev")) {
      return setRecipient();
    }
    return close()
  }, [step, action]);

  const handleStartTour = () => {
    if (isScreenIncomplete || isScreenDuplicate) {
      push({ query: { ...query, menu: "all_transactions" } });
    }
    startTour()
  };

  useEffect(() => {
    if (isTourRunning && isEmptyTable) {
      if (isDataFilled) return;
      setData([prefilledDataPayroll]);
    }
    if (!isTourRunning) {
      delete query?.step;
      delete query?.action;
      delete query?.recipientName;
      push({ query });
    }
    return setGuidanceState((prev) => ({
      ...prev,
      guidanceFunction: isTourRunning ? stopTour : handleStartTour,
      eventPayload: {
        activity_type: "create_payroll",
        page_title: "transfer_details"
      }
    }));
  }, [isTourRunning, isEmptyTable, screen, isScreenIncomplete]);

  if (isEmptyData) return null;

  return (
    <div
      ref={refScrollBottom}
      style={{
        // paddingBottom: isScreenIncomplete ? 440 : 320,
        // overflowY: "scroll",
        position: "relative",
        ...style,
      }}
    >
      <table
        id={PAYROLL_SIXTH_TARGET}
        ref={refTable}
        style={{
          width: "100%",
          position: "relative",
          tableLayout: "fixed",
          wordBreak: "break-word",
          border: "1px solid #e6e6e8",
          backgroundColor: "white",
          borderCollapse: "separate",
          padding: "8px 8px 0px 8px",
          borderRadius: 8,
          // marginBottom: 200,
        }}
        cellSpacing={0}
      >
        <TheadTablePayroll
          headerArray={headerArray}
          isAllChecked={isAllChecked}
          showDeleteRow={showDeleteRow}
          handleClickAll={handleClickAll}
          renderFirst={isFirstScreenTable}
          isAnythingSelected={isAnythingSelected}
          isScreenIncomplete={isScreenIncomplete}
        />
        <TbodyTablePayroll
          close={close}
          handleClick={handleClick}
          toggleDelete={toggleDelete}
          confirmDelete={handleDelete}
          data={data}
          isOpen={isOpen}
          setData={setData}
          noBorder={noBorder}
          modalIndex={modalIndex}
          selectedIds={selectedIds}
          headerArray={headerArray}
          isOpenDelete={isOpenDelete}
          filteredData={filteredData}
          showDeleteRow={showDeleteRow}
          renderFirst={isFirstScreenTable}
          handleClickItem={handleClickItem}
          isScreenDuplicate={isScreenDuplicate}
          isAnythingSelected={isAnythingSelected}
          isScreenIncomplete={isScreenIncomplete}
        />
      </table>
    </div>
  );
};
