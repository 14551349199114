import axios from "axios";
import { AES, enc } from "crypto-js";
import { isEmpty } from "lodash";
import { useContext, useEffect, useState } from "react";
import { useConstants } from "../contexts/ConstantsContext/parent";
import { ToasterContext, ToasterHook } from "../contexts/ToasterContext";

const AESPassword = "hacker pergi sono!";

const localStorageBooleans = () => {
  const storage = localStorage.getItem("env_type");
  const original = !storage;
  const prod = storage == "production";
  const internal = storage == "internal_production";
  const internal2 = storage == "internal_production_2";
  const sandbox = storage == "sandbox";
  const staging = storage == "staging";
  const prod3 = storage == "prod3";
  const api2 = storage == "api2";

  return { original, prod, internal, internal2, prod3, api2, sandbox, staging };
};

export const urlDecider = (defaultUrl) => {
  const isDefaultUrl =
    defaultUrl == process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL;

  const isDownload =
    defaultUrl == process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL;

  const { internal, sandbox, internal2, prod, staging, prod3, api2 } =
    localStorageBooleans();

  const defaultUrlFunc = () => {
    if (prod) return "https://api.enterprise.transfez.com/api/v1";
    if (internal) return "https://inprod.api.business.transfez.io/api/v1";
    if (internal2) return "https://api.enterprise.transfez.app/api/v1/";

    if (prod3) return "https://prod3.business.transfez.app/api/v1";
    if (api2) return "https://api2.enterprise.transfez.app/api/v1";

    if (sandbox) return "https://sandbox.api.business.transfez.tech/api/v1";
    if (staging) return "https://staging.api.business.transfez.tech/api/v1";

    return process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL;
  };

  if (isDefaultUrl) return defaultUrlFunc();

  if (isDownload) return defaultUrlFunc().replace("/api/v1", "");

  return defaultUrl;
};

export const encryptToken = (token) => {
  const safeToken = token || "";
  const encryptedToken = AES.encrypt(safeToken, AESPassword).toString();
  return encryptedToken;
};

export const decryptToken = (encryptedToken) => {
  const bytes = AES.decrypt(encryptedToken, AESPassword);
  const result = bytes.toString(enc.Utf8);
  return result;
};
const createObject = (baseURL, timeout = 30000) => ({
  baseURL,
  timeout,
  CancelToken: axios.CancelToken,
});

const defaultHeader = async (config) => {
  const getToken = localStorage.getItem("token");
  const decryptedToken = getToken ? decryptToken(getToken) : "";
  config.baseURL = urlDecider(config.baseURL);
  config.headers = { Authorization: decryptedToken };
  return config;
};

const defaultHeaderV2 = async (config) => {
  const getToken = localStorage.getItem("token");
  const decryptedToken = getToken ? decryptToken(getToken) : "";
  config.baseURL = changeV1toV2(urlDecider(config.baseURL));
  config.headers = { Authorization: decryptedToken };
  return config;
};

const responseFunc = (res) => res;

const changeV1toV2 = (url) => url?.replace("v1", "v2");

export const errorApiDecider = (error) => {
  const stringErr = String(error || "");
  const isNoInternet = stringErr.includes("Network Error");
  const isServerError = stringErr.includes("500");
  const invalidData = stringErr.includes("422");
  const isUnauthorized = stringErr.includes("401") || stringErr.includes("404");

  return { isNoInternet, isServerError, invalidData, isUnauthorized };
};

const errorFunc = (error) => Promise.reject(error);

export const apiBusiness = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL)
);

apiBusiness.interceptors.request.use(defaultHeader);
apiBusiness.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessV2 = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL)
);

apiBusinessV2.interceptors.request.use(defaultHeaderV2);
apiBusinessV2.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessDownload = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL)
);

apiBusinessDownload.interceptors.request.use(defaultHeader);
apiBusinessDownload.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessWoHeaderDownload = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_WO_VERSION_URL)
);

apiBusinessWoHeaderDownload.interceptors.request.use(async (config) => {
  config.baseURL = urlDecider(config.baseURL);
  return config;
});
apiBusinessWoHeaderDownload.interceptors.response.use(responseFunc, errorFunc);

export const apiBusinessLong = axios.create(
  createObject(process.env.NEXT_PUBLIC_TRANSFEZ_BUSINESS_URL, 300000)
);

apiBusinessLong.interceptors.request.use(defaultHeader);
apiBusinessLong.interceptors.response.use(responseFunc, errorFunc);

const strapi = axios.create(createObject("https://strapi.transfez.com"));

const apiDecider = (type) => {
  if (type == "axios") return axios;
  if (type == "strapi") return strapi;
  if (type == "long") return apiBusinessLong;
  if (type == "v2") return apiBusinessV2;
  return apiBusiness;
};

const isUserDetailsChecker = (path) => {
  // Define the regular expression pattern
  const pattern = /^\/business_users\/\d+$/;

  // Test the path against the pattern and return the result
  return pattern.test(path);
};

export const fetch = ({
  url,
  formatter = (val) => val,
  type,
  noToaster = true,
  defaultValue = [],
  woInit,
  defaultLoading,
  afterSuccess,
  params = {},
  errorHandler = () => {},
  isLocalApi,
}) => {
  const { errorToasterApi } = useContext(ToasterContext);
  const [loading, setLoading] = useState(
    defaultLoading ? defaultLoading : !woInit
  );

  const { allUsers } = useConstants();

  const [data, setData] = useState(defaultValue);

  const isUserDetails = isUserDetailsChecker(url);

  const getData = async () => {
    try {
      setLoading(true);
      const api = isLocalApi ? axios : apiDecider(type);

      const afterFetch = async (data) => {
        setData((prev) => formatter(data, prev));
        afterSuccess && (await afterSuccess(data));
      };

      const getUser = (id) => allUsers.find((item) => item.id == id);

      if (isUserDetails) {
        if (isEmpty(allUsers)) return;
        const id = url.split("/business_users/").pop();

        await afterFetch({ data: getUser(id) });
        return;
      }

      const { data } = await api.get(url, { params });

      const user_id = data?.data?.user_id;

      if (user_id) {
        data.data = { ...(data?.data || {}), user: getUser(user_id) };
      }

      await afterFetch(data);
    } catch (err) {
      errorHandler();
      const is401 = err?.response?.data?.status === 401;
      if (is401) {
        errorToasterApi(err);
        setLoading(false);
        return;
      }
      if (noToaster) return;
      errorToasterApi(err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (woInit) return;
    if (isUserDetails) return;
    getData();
  }, []);

  useEffect(() => {
    if (!isUserDetails) return;
    if (isEmpty(allUsers)) return;
    getData();
  }, [isEmpty(allUsers)]);

  return { data, loading, refetch: getData, setData };
};

export const localFetch = async (url, params) => {
  if (process.env.NODE_ENV == "development") {
    url = `/api/${url}`;
  } else {
    url = `/.netlify/functions/${url}`;
  }
  const res = await axios.get(url, { params });
  return res;
};

export const useLocalApi = ({ url, params, formatter }) => {
  if (process.env.NODE_ENV == "development") {
    url = `/api/${url}`;
  } else {
    url = `/.netlify/functions/${url}`;
  }

  const res = fetch({ url, params, formatter, isLocalApi: true });
  return res;
};

export const useMutation = ({
  type,
  defaultValue = {},
  method = "put",
  url,
  resultFormatter = (data) => data,
  afterSuccess,
  withError = true,
  handleError,
  defaultLoading = false,
  beforeApiCall = () => {},
}) => {
  const [loading, setLoading] = useState(defaultLoading);
  const [result, setResult] = useState(defaultValue);

  const { errorToasterApi } = ToasterHook();

  const mutation = async (value) => {
    try {
      setLoading(true);
      const api = apiDecider(type);
      await beforeApiCall(value);
      const response = await api[method](url, value);
      const result = resultFormatter(response);
      setResult((prev) => resultFormatter(response, prev));

      const { token, ...payload } = value || {};

      typeof afterSuccess == "function" &&
        (await afterSuccess(result, payload, response));
    } catch (error) {
      handleError && handleError(error);
      if (withError) errorToasterApi(error);
    } finally {
      setLoading(false);
    }
  };

  return { loading, mutation, result, setResult };
};

export const handleDownloadRaw = ({
  data,
  type = "application/pdf",
  fileName = "",
}) => {
  const file = new Blob([data], {
    type,
  });
  const targetUrl = window.URL.createObjectURL(file);
  const link = document.createElement("a");
  link.href = targetUrl;
  link.setAttribute("download", fileName);
  document.body.appendChild(link);
  link.click();
  link.parentNode && link.parentNode.removeChild(link);
};

export const downloadApi = async ({
  type = "application/pdf",
  url,
  fileName = "Jack-Service-Agreement-and-Policy.pdf",
  jsonHandler,
  woV1 = false,
  errorToasterApi,
  afterSuccess,
  isAxios,
  woHeader = false,
  isPut = false,
  payloadPut,
  longTimeout = false,
}) => {
  try {
    const apiDecider = () => {
      if (woHeader) return apiBusinessWoHeaderDownload;
      if (isAxios) return axios;
      if (woV1) return apiBusinessDownload;
      if (longTimeout) return apiBusinessLong;
      return apiBusiness;
    };
    const apiDownload = apiDecider();

    const Method = () => {
      if (isPut)
        return apiDownload.put(url, payloadPut, {
          responseType: "blob",
        });
      return apiDownload.get(url, {
        responseType: "blob",
      });
    };

    const { data } = await Method();
    const { type: responseType } = data;
    const isJson = responseType == "application/json";

    if (isJson && jsonHandler) return jsonHandler(url, fileName);
    handleDownloadRaw({ data, fileName, type });

    afterSuccess && afterSuccess(url, fileName);
  } catch (err) {
    errorToasterApi && errorToasterApi(err);
  }
};

export const useDownload = () => {
  const [loading, setLoading] = useState(false);
  const { errorToasterApi } = ToasterHook();

  const handleDownload = async ({
    type = "application/pdf",
    url,
    fileName = "Service-Agreement-and-Policy.pdf",
    jsonHandler,
    afterSuccess,
    isPut,
    payloadPut,
    longTimeout,
  }) => {
    setLoading(true);
    await downloadApi({
      type,
      fileName,
      url,
      jsonHandler,
      errorToasterApi,
      afterSuccess,
      isPut,
      payloadPut,
      longTimeout,
    });
    setLoading(false);
  };

  return { handleDownload, loading };
};
