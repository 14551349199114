import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import { unformatCurrency } from "../../../../../../components/tools";
import { colors } from "../../../../../../assets/colors";
import { GothamRegular } from "../../../../../../components/Text";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import TitleIcon from "../../components/TitleIcon";
import CardInfoBalance from "../../components/CardInfoBalance";
import { CardSelection } from "./components";
import {
  CurrencyFieldJack,
  TextFieldJack,
} from "../../../../../../components/inputs/textfield";
import BottomContainer from "../../components/BottomContainer";

const PrepaidMoveToCardForm = ({
  cardDetail,
  result,
  setResult,
  setMoveToCardScreen,
  setCurrentScreen,
}) => {
  const useFormObj = useForm();
  const { getValues, handleSubmit } = useFormObj;
  const { moved_amount: movedAmount, reason } = getValues();
  const { balance } = cardDetail;
  const { recipient } = result;
  const movedAmountNumber = +unformatCurrency(movedAmount);
  const balanceAmountNumber = +unformatCurrency(balance);
  const isAmountValid =
    movedAmountNumber > 0 && movedAmountNumber <= balanceAmountNumber;
  const isButtonDisabled = !reason || !isAmountValid || isEmpty(recipient);

  const backToDetailsHandler = () => setCurrentScreen("index");
  const backHandler = () => setCurrentScreen("prepaidMoveBalanceMethod");
  const onSubmit = (data) => {
    setResult({
      ...result,
      ...data,
    });
    setMoveToCardScreen("summary");
  };

  const styles = {
    backButton: {
      width: "fit-content",
    },
    textInput: {
      marginBottom: "0px",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    reasonInput: {
      height: "60px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  return (
    <>
      <ButtonJack
        leftIcon={
          <JackIcons name="cornerUpLeftOutline" fill={colors.neutral900} />
        }
        type="outline"
        style={styles.backButton}
        onClick={backToDetailsHandler}
      >
        Go to Card Details
      </ButtonJack>
      <TitleIcon title="Move balance to other card" icon="card-menu" />
      <CardInfoBalance cardDetail={cardDetail} text="From :" />
      <CardSelection
        cardDetail={cardDetail}
        result={result}
        setResult={setResult}
      />
      <CurrencyFieldJack
        name="moved_amount"
        label="Amount to be moved"
        useFormObj={useFormObj}
        style={styles.textInput}
        icon={
          <GothamRegular
            style={{
              color: colors.neutral600,
            }}
          >
            IDR
          </GothamRegular>
        }
        required
      />
      <TextFieldJack
        name="reason"
        useFormObj={useFormObj}
        style={styles.textInput}
        textFieldStyle={styles.reasonInput}
        required
      />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          onClick={handleSubmit(onSubmit)}
          disabled={isButtonDisabled}
        >
          Continue
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidMoveToCardForm;
