import Head from "next/head";
import { useRouter } from "next/router";
import React from "react";
// import { MetaData } from "./metadata";

const SEOComponents = () => {
  const { pathname } = useRouter();

  //   const { titleEn, descEn, keywordsEn } = MetaData({ pathname });
  const isStaging = process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV === "STAGING";
  const isProduction =
    process.env.NEXT_PUBLIC_TRANSFEZ_APP_ENV === "PRODUCTION";

  const ld = () => (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
          {
            "@context": "http://schema.org",
            "@type": "Organization",
            "address": {
                "@type": "PostalAddress",
                "addressLocality": "DKI Jakarta",
                "postalCode": "12870",
                "streetAddress": "Jl. Casablanca Raya Kav.88, Prudential Centre Lt. 22, RT.14/RW.5, Menteng Dalam, Kota Jakarta Selatan, Daerah Khusus Ibukota Jakarta 12870",
                "addressRegion": "Jakarta Selatan"
            },
            "url": "https://itsjack.com",
            "logo": "https://www.itsjack.id/wp-content/uploads/2023/07/Jack-Icon-Google-Play-Store.jpg",
            "name": "PT. Indo Koala Remittance",
            "sameAs": [ "https://www.facebook.com/itsjackcom/",
            "https://twitter.com/itsjackcom",
            "https://www.tiktok.com/@itsjackcom",
            "https://www.instagram.com/itsjackcom/",
            "https://www.youtube.com/channel/UCzk6QGSSRvz4qIg8KVnA0iw",
            "https://www.linkedin.com/company/itsjack/" ],
            "description": "Aplikasi Transfer Uang Antar Negara Terpercaya, Cepat, dan Aman",
            "email": "hello@itsjack.com",
            "contactPoint": {
                "@type": "ContactPoint",
                "telephone": "+6281299823838",
                "contactType": "Customer service"
            }
        }
        `,
        }}
      />
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: `
        {
          "@context": "https://schema.org",
          "@type": "SoftwareApplication",
          "name": "PT. Indo Koala Remittance",
          "operatingSystem": "ANDROID",
          "applicationCategory": "Finance",
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "4.6",
            "ratingCount": "8864"
          },
          "offers": {
            "@type": "Offer",
            "price": "0",
            "priceCurrency": "IDR"
          }
        }
        `,
        }}
      />
    </>
  );

  const linkedIn = () => {
    if (!isProduction) return null;
    return (
      <>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `
          _linkedin_partner_id = "5001860";
          window._linkedin_data_partner_ids = window._linkedin_data_partner_ids || [];
          window._linkedin_data_partner_ids.push(_linkedin_partner_id);
          `,
          }}
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `(function(l) {
          if (!l){window.lintrk = function(a,b){window.lintrk.q.push([a,b])};
          window.lintrk.q=[]}
          var s = document.getElementsByTagName("script")[0];
          var b = document.createElement("script");
          b.type = "text/javascript";b.async = true;
          b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js";
          s.parentNode.insertBefore(b, s);})(window.lintrk);
          `,
          }}
        />
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <img height="1" width="1" style="display:none;" alt="" src="https://px.ads.linkedin.com/collect/?pid=5001860&fmt=gif" />
        `,
          }}
        />
      </>
    );
  };

  const ga = () => {
    // GOOGLE ANALYTICS

    if (!isProduction) return null;

    return (
      <>
        <script
          type="text/javascript"
          src="https://www.googletagmanager.com/gtag/js?id=G-53B3LLF27F"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'G-53B3LLF27F');
        `,
          }}
        />

        <script
          type="text/javascript"
          src="https://www.googletagmanager.com/gtag/js?id=UA-276417150-1"
        />
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'UA-276417150-1');
        `,
          }}
        />
      </>
    );
  };

  const fp = () => {
    // FACEBOOK PIXEL
    if (!isProduction) return null;
    return (
      <>
        <script
          type="text/javascript"
          dangerouslySetInnerHTML={{
            __html: `!function(f,b,e,v,n,t,s)
          {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
          n.callMethod.apply(n,arguments):n.queue.push(arguments)};
          if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
          n.queue=[];t=b.createElement(e);t.async=!0;
          t.src=v;s=b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t,s)}(window,document,'script',
          'https://connect.facebook.net/en_US/fbevents.js');
           fbq('init', '1754386201699437'); 
          fbq('track', 'PageView');`,
          }}
        ></script>
        <noscript
          dangerouslySetInnerHTML={{
            __html: `
            <img height="1" width="1" src="https://www.facebook.com/tr?id=1754386201699437&ev=PageView&noscript=1"/>
          `,
          }}
        ></noscript>
      </>
    );
  };

  return (
    <div>
      <Head>
        <meta charSet="utf-8" />
        <link rel="canonical" href={pathname} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={pathname} />
        <meta property="og:site_name" content="Jack" />
        <meta
          property="og:image"
          content="https://www.itsjack.id/wp-content/uploads/2023/07/featured-images-itsjack.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta
          property="twitter:image"
          content="https://www.itsjack.id/wp-content/uploads/2023/07/featured-images-itsjack.jpg"
        />
        <link rel="alternate" hrefLang="en-us" href={pathname} />
        {ld()}
        {linkedIn()}
        {/* GOOGLE TAG MANAGER */}
        {isProduction && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `
          (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-567HLBD');
        `,
            }}
          />
        )}
        {ga()}

        {/* CLARITY HEATMAP TRACKER */}
        {isProduction && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `(function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
            })(window, document, "clarity", "script", "hy08atv08f");
            `,
            }}
          />
        )}
        {/* GOOGLE ADS CONVERSION */}
        {isProduction && (
          <script
            type="text/javascript"
            dangerouslySetInnerHTML={{
              __html: `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', 'AW-693507672');
          `,
            }}
          />
        )}
        {/* GOOGLE ADS CONVERSION */}
        {isProduction && (
          <script
            type="text/javascript"
            src="https://www.googletagmanager.com/gtag/js?id=AW-693507672"
          />
        )}
        {fp()}
      </Head>
      {/* GOOGLE TAG MANAGER */}
      {isProduction && (
        <noscript
          dangerouslySetInnerHTML={{
            __html: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-567HLBD"
            height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
          }}
        ></noscript>
      )}
    </div>
  );
};

export default SEOComponents;
