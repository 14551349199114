import { colors } from "../../../../../assets/colors";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";

const BottomButton = ({
  onCancel,
  onConfirm,
  confirmText,
  loadingConfirm,
  woBorder,
  isConfirmDisabled,
}) => {
  const containerStyle = {
    display: "flex",
    padding: "20px",
    gap: "16px",
    borderTop: !woBorder && `1px solid ${colors.neutral500}`,
  };

  const buttonStyle = {
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      <ButtonJack style={buttonStyle} onClick={onCancel} type="outline">
        Cancel
      </ButtonJack>
      <ButtonJack
        style={buttonStyle}
        onClick={onConfirm}
        isLoading={loadingConfirm}
        disabled={isConfirmDisabled}
      >
        {confirmText}
      </ButtonJack>
    </div>
  );
};

export default BottomButton;
