import { colors } from "../../../../../assets/colors";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";

const CardInfoBalance = ({ cardDetail, style, text }) => {
  const {
    balance,
    masked_card_number: maskedCardNumber,
    name,
    nickname,
    user: { name: userName } = {},
  } = cardDetail || {};

  const maskedNumberWithName = `**${maskedCardNumber?.substr(-4)} - ${
    nickname || name
  }`;

  const textStyle = {
    fontSize: "12px",
    color: colors.neutral900,
    letterSpacing: "0.2px",
    marginBottom: "8px",
  };

  const cardContainerStyle = {
    padding: "12px",
    borderRadius: "4px 4px 0 0",
    border: `1px solid ${colors.neutral500}`,
    display: "flex",
  };

  const cardIconContainerStyle = {
    display: "flex",
    gap: "8px",
    marginLeft: "8px",
    alignItems: "center",
  };

  const userNameStyle = {
    fontSize: "12px",
    marginBottom: "2px",
    color: colors.neutral900,
  };

  const maskedNumberStyle = {
    color: colors.neutral700,
    fontSize: "10px",
    marginTop: "-4px",
  };

  const backgroundStyle = {
    width: "100%",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "100% 100%",
    backgroundSize: "contain",
    backgroundColor: colors.neutral100,
    padding: "12px 16px",
    borderRadius: "0 0 4px 4px",
    display: "flex",
    justifyContent: "space-between",
    border: `1px solid ${colors.neutral500}`,
    marginTop: "-1px",
  };

  const cardBalanceTextStyle = {
    color: colors.neutral900,
    fontSize: "12px",
    letterSpacing: "0.2px",
  };

  const balanceAmountStyle = {
    color: colors.neutral900,
  };

  return (
    <div style={style}>
      <GothamMedium style={textStyle}>{text}</GothamMedium>
      <div style={cardContainerStyle}>
        <JackIcons name="mastercard" />
        <div style={cardIconContainerStyle}>
          <div>
            <GothamRegular style={userNameStyle}>{userName}</GothamRegular>
            <GothamRegular style={maskedNumberStyle}>
              {maskedNumberWithName}
            </GothamRegular>
          </div>
        </div>
      </div>
      <div style={backgroundStyle}>
        <GothamRegular style={cardBalanceTextStyle}>Card Balance</GothamRegular>
        <GothamMedium style={balanceAmountStyle}>
          IDR {formatCurrencyNoDecimal(balance)}
        </GothamMedium>
      </div>
    </div>
  );
};

export default CardInfoBalance;
