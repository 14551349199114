import BottomContainer from "../components/BottomContainer";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import RowRadioButton from "../components/RowRadioButton";
import { useForm } from "react-hook-form";
import { getUserRole } from "../../../../../contexts/AuthContext";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { snakeCase } from "lodash";

const PrepaidTopUpMethod = () => {
  const { setCurrentScreen, mainBalance } = useCardDetailContext();
  const { isAdminOrSuperAdmin } = getUserRole();
  const topUpList = [
    {
      title: isAdminOrSuperAdmin ? "Company Balance" : "Request to Company",
      subtitle: isAdminOrSuperAdmin
        ? `Current balance: IDR ${formatCurrencyNoDecimal(mainBalance)}`
        : "Ask company to top up your balance",
      icon: "top-up",
      value: "prepaidTopUpCompany",
    },
    {
      title: "Transfer to Virtual Account",
      icon: "bank",
      value: "prepaidTopUpVA",
    },
  ];

  const useFormObj = useForm({
    defaultValues: {
      prepaidTopUpMethod: topUpList[0].value,
    },
  });
  const { watch } = useFormObj;
  const selected = watch("prepaidTopUpMethod");

  const backHandler = () => setCurrentScreen("index");
  const continueHandler = () => {
    eventsTracker(`card_to_${snakeCase(selected)}`);
    setCurrentScreen(selected);
  };

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      gap: "32px",
      paddingBottom: "100px",
      overflowY: "auto",
    },
    backButton: {
      width: "fit-content",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "8px",
    },
    title: {
      fontSize: "24px",
      color: colors.neutral900,
    },
    subtitle: {
      letterSpacing: "0.1px",
      color: colors.neutral800,
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  return (
    <div style={styles.container}>
      <ButtonJack
        leftIcon={
          <JackIcons name="cornerUpLeftOutline" fill={colors.neutral900} />
        }
        type="outline"
        style={styles.backButton}
        onClick={backHandler}
      >
        Go to Card Details
      </ButtonJack>
      <div style={styles.titleContainer}>
        <GothamMedium style={styles.title}>Select top up method</GothamMedium>
        <GothamRegular style={styles.subtitle}>
          Choose how you would like to top up the card balance.
        </GothamRegular>
      </div>
      <RowRadioButton
        name="prepaidTopUpMethod"
        list={topUpList}
        useFormObj={useFormObj}
      />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack style={styles.button} onClick={continueHandler}>
          Continue
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

export default PrepaidTopUpMethod;
