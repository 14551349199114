import { GothamBold, GothamRegular } from "../../../components/Text";
import styles from "./style.module.css";

export const ChangeButton = ({ onClick }) => (
  <div
    style={{
      height: 20,
      width: 56,
      borderRadius: 4,
      border: "1px solid #BBBBC0",
      display: "grid",
      placeItems: "center",
      cursor: "pointer",
    }}
    onClick={onClick}
  >
    <GothamRegular className="font12" style={{ color: "#6C6C71" }}>
      Change
    </GothamRegular>
  </div>
);

export const DefaultOption = ({ onClick, flag, label }) => {
  return (
    <div
      className={styles.option}
      onClick={onClick}
      style={{ backgroundColor: flag && "#E6E6E8" }}
    >
      <GothamRegular
        style={{ color: flag ? "#343434" : "#6C6C71" }}
        className="font12"
      >
        {label}
      </GothamRegular>
    </div>
  );
};

export const OptionWithButton = ({ topText, bottomText, onClickButton }) => {
  return (
    <div
      style={{
        height: 44,
        width: "100%",
        padding: "1px 8px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        backgroundColor: "#E6E6E8",
      }}
      className={styles.option}
    >
      <div className="d-flex-column font12">
        <GothamBold style={{ fontSize: 12, color: "#343434" }}>
          {topText}
        </GothamBold>
        <GothamRegular className="font12">{bottomText}</GothamRegular>
      </div>
      <ChangeButton onClick={onClickButton} />
    </div>
  );
};

export const DefaultTag = () => (
  <div className="d-flex align-items-center">
    <div
      style={{
        width: 8,
        height: 8,
        backgroundColor: "#B9FC00",
        borderRadius: 100,
        marginRight: 6,
      }}
    />
    <GothamRegular className="font10 text-black34">Default</GothamRegular>
  </div>
);
