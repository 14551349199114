import { useContext, useEffect, useState } from "react";
import { jackColors } from "../../../assets/colors";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { GothamRegular } from "../../../components/Text";
import { SummarySheetContainer } from "../../invoiceComponents/createPage/mainPage"
import styles from "../../alertsAndAttentionComponents/AlertsAndAttention.module.css";
import { DraftContext } from "../parent";
import { useDeleteRow } from "./useDeleteRow";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import deleteImage from "../../../assets/images/delete-trash-grey.svg"

const MarkAsDeleteSummary = ({ menu, afterSuccess }) => {
  
  const { toBeDeleted, setToBeDeleted } = useContext(DraftContext);
  const [deleteId, setDeleteId] = useState(null)
  const [deleteConfirm, setDeleteConfirm] = useState(false)
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
  const { bulkDelete } = useDeleteRow({ page: menu, deleteId })
  const deleteIds = toBeDeleted.map(item => item.id)
  const thereIsNothingToDeleted = toBeDeleted.length === 0;

  const handleClick = () => {
    setOpenConfirmationModal(true)
  };
  
  const handleCancel = () => {
    setToBeDeleted([])
  };
  
  const handleBulkDelete = () => {
    setDeleteConfirm(true)
  }

  useEffect(() => {
    if(!deleteConfirm) return;
    bulkDelete(deleteIds)
    setTimeout(() => afterSuccess(), 1000)
    setDeleteConfirm(false)
    setToBeDeleted([])
  }, [deleteConfirm])

  
  if (thereIsNothingToDeleted) return null;

  return (
    <SummarySheetContainer>
      <div className={styles["mark-as-read-summary-container"]}>
        <div className={styles["mark-as-read-summary-transactions-container"]}>
          <GothamRegular
            woFontColor
            className={`font12 ${styles["white-font"]}`}
          >
            Selected Transaction
          </GothamRegular>
          <GothamRegular
            woFontColor
            className={`font16 ${styles["white-font"]}`}
          >
            {toBeDeleted.length} Transactions
          </GothamRegular>
        </div>

        <div className={styles["mark-as-read-summary-buttons-container"]}>
          <ButtonJack
            style={{
              border: `1px solid ${jackColors.neutral500}`,
              backgroundColor: jackColors.neutral900,
            }}
            classNameText={`font14 ${styles["cancel-font"]}`}
            onClick={handleCancel}
          >
            Cancel
          </ButtonJack>
          <ButtonJack
            onClick={handleClick}
          >
            Delete Transaction
          </ButtonJack>
        </div>
      </div>
      <ConfirmationModalJack
        modal={openConfirmationModal}
        isCentered={true}
        img={deleteImage}
        title="Delete this transaction(s)?"
        text="You will delete selected transaction(s) from draft."
        buttonTextLeft="Back"
        buttonTextRight="Delete"
        toggle={() => setOpenConfirmationModal(false)}
        onClick={handleBulkDelete}
      />
    </SummarySheetContainer>
  );
};

export default MarkAsDeleteSummary;
