import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import {
  filterDataByDuplicate,
  filterDataByIncomplete,
} from "../../../PayrollCreateEnhancement/create/logic";

export const usePageTogglerLocal = ({ data }) => {
  const { query, push } = useRouter();
  const { menu } = query || {};
  const isIncomplete = menu === "incomplete";
  const isDuplicate = menu === "duplicate";

  const setScreen = (val) => {
    push({ query: { ...query, menu: val } });
  };

  const { dataIncomplete, dataIncompleteLength, isEmptyIncompleteData } =
    filterDataByIncomplete({
      data,
    });
  const { dataDuplicate, dataDuplicateLength, isEmptyDuplicateData } =
    filterDataByDuplicate({
      data,
    });

  useEffect(() => {
    if (isEmptyIncompleteData) {
      if (isEmptyDuplicateData) return setScreen("all_transactions");
      return setScreen("duplicate");
    }
  }, [isEmptyIncompleteData, isEmptyDuplicateData]);

  useEffect(() => {
    if (isIncomplete) return setScreen("incomplete");
    if (isDuplicate) return setScreen("duplicate");
    return setScreen("all_transactions");
  }, [menu]);

  const isScreenIncomplete = isIncomplete;
  const isScreenDuplicate = isDuplicate;

  return {
    setScreen,
    isScreenIncomplete,
    isScreenDuplicate,
    dataIncompleteLength,
    dataDuplicateLength,
    isEmptyIncompleteData,
    isEmptyDuplicateData,
  };
};
