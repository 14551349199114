import { useRouter } from "next/router";
import { jackColors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";
import { TopUpModal, useModalHook } from "../../../../../components/Modals";
import PINModal from "../../../../../components/PINModal/parent";
import { GothamRegular, TextInlineBold } from "../../../../../components/Text";
import { invoicePageTypeDecider } from "../../../../../pageComponents/invoiceComponents/data/formatter";
import { useMutation } from "../../../../../tools/api";
import { useDetailsExtractor } from "../../../crossBorderSingleModal/logics";
import { PayrollRejectModal } from "../../../payroll/rejectModal";
import { SchedulePaymentButtons } from "./buttons";
import { PayNowModal, RescheduleModal } from "./modals";

export const useSchedulePaymentButtons = ({ transaction, isReleaser }) => {
  const {
    id,
    activity_state,
    scheduled_payment,
    invoice_file: isInvoice,
    reimbursement_workflow_summary,
    transaction_type,
  } = transaction || {};
  const isLocalTransfer = transaction_type == "transfer";
  const isReimbursement = Boolean(reimbursement_workflow_summary);
  const transactionTypeDecider = () => {
    if (isLocalTransfer) return "local_transfer";
    return "payroll";
  };

  const transactionType = transactionTypeDecider();

  const { id: schedule_payment_id } = scheduled_payment || {};
  const isScheduled = activity_state == "scheduled";
  const isDelayedPayment = activity_state == "payment_delayed";
  const isCompleted = activity_state == "completed";
  const isOnProgress = activity_state == "on_progress";

  const detailData = useDetailsExtractor(transaction, true, true);

  const { isOpen: isOpenPayNowModal, toggle: togglePayNowModal } =
    useModalHook();
  const { isOpen: isOpenPinModal, toggle: togglePinModal } = useModalHook();
  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();
  const {
    isOpen: isOpenRejectModal,
    toggle: toggleReject,
    open: openRejectModal,
  } = useModalHook();
  const { isOpen: isOpenReschedule, toggle: toggleReschedule } = useModalHook();

  const { loading: loadingChangeState, mutation: changeState } = useMutation({
    url: `/scheduled_payments/${schedule_payment_id}/change_state`,
    afterSuccess: (res) => {
      afterSuccess("Transaction successfully approved");
    },
  });

  const { loading: loadingReschedule, mutation: reschedule } = useMutation({
    url: `/scheduled_payments/${schedule_payment_id}`,
    afterSuccess: (res) => {
      afterSuccess("Transaction successfully approved");
    },
  });

  const { push } = useRouter();
  const afterSuccess = (msg) => {
    if (isInvoice) return push(`success?type=invoice&id=${id}&pageType=pay`);

    if (isReimbursement)
      return push(`success?type=reimbursement&btcid=${id}pageType=pay`);

    return push(`/success/?type=${transactionType}&id=${id}`);
  };

  const generalLoading = loadingReschedule || loadingChangeState;

  const onSubmit = (payload, state) => {
    const { pin, reason: rejection_note } = payload;

    if (rejection_note) return changeState({ rejection_note, state, pin });
    return changeState({ pin, state });
  };

  const onSubmitReschedule = (v) => {
    const { pin, type: state, payload } = v;

    const isPayImmediately = state == "pay_immediately";
    if (isPayImmediately) return changeState({ pin, state });

    return reschedule({ ...payload, pin });
  };

  const buttons = () => {
    if (isCompleted) return null;
    if (isOnProgress) return null;

    if (isReleaser) {
      if (isScheduled || isDelayedPayment) {
        return (
          <SchedulePaymentButtons
            isIRM={isReimbursement || isInvoice}
            onClickRight={togglePayNowModal}
            onClickCancel={openRejectModal}
            onClickLeft={toggleReschedule}
          >
            <PayNowModal
              isOpen={isOpenPayNowModal}
              toggle={togglePayNowModal}
              onClick={() => {
                togglePayNowModal();
                togglePinModal();
              }}
              data={transaction}
              detailData={detailData}
            />
            <PINModal
              isLoading={generalLoading}
              isOpen={isOpenPinModal}
              toggle={() => {
                togglePinModal();
                togglePayNowModal();
              }}
              onBack={() => {
                togglePinModal();
                togglePayNowModal();
              }}
              onSubmit={({ pin }) => onSubmit({ pin }, "pay_immediately")}
            />
            <TopUpModal
              modal={isOpenTopUpModal}
              toggle={toggleTopUpModal}
              dummyPaidButton
              paid
            />
            <PayrollRejectModal
              text="cancelation"
              isOpen={isOpenRejectModal}
              toggle={toggleReject}
              loading={generalLoading}
              onSubmit={(v) => onSubmit(v, "cancel")}
              detailData={detailData}
              customHeadline="Cancel this transaction?"
              customSubtitle="You're about to cancel a transaction that’s been approved and scheduled for payment."
            />
            <RescheduleModal
              isOpen={isOpenReschedule}
              toggle={toggleReschedule}
              onClickTopup={toggleTopUpModal}
              data={transaction}
              detailData={detailData}
              onSubmit={onSubmitReschedule}
              isLoading={generalLoading}
            />
          </SchedulePaymentButtons>
        );
      }
    }
  };

  return {
    buttons,
  };
};
