import { Fragment, useState } from "react";
import { GothamMedium, GothamRegular } from "../../../../components/Text";
import { jackColors } from "../../../../assets/colors";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { downloadApi } from "../../../../tools/api";
import { useRouter } from "next/router";
import { ToasterHook } from "../../../../contexts/ToasterContext";
import moment from "moment";
import UpgradeRequestImage from "./UpgradeRequestImage";
import { eventsTracker } from "../../../../universalFunctions/events";

const UpgradeContent = ({
  type = "",
  redirectUrl = "",
  toggle = () => {},
  setIsRequesting = () => {},
  mutationUpgrade = async () => {},
}) => {
  const [isRequestSent, setIsRequestSent] = useState(false);

  const { push } = useRouter();

  const { successSnackBar } = ToasterHook();

  const isNonAdmin = !type.includes("admin");

  const handleDownload = () => {
    downloadApi({
      url: `/subscription_module/terms_and_conditions`,
      fileName: `Transfez_Subscription_Terms_and_Conditions`,
    });
  };

  const handleClick = async (type = "") => {
    const isOkay = type === "okay";
    const isCancel = type === "cancel";

    if (isCancel) return toggle();

    if (isOkay) {
      toggle();
      redirectUrl && (await push(redirectUrl));
      const timer = moment(new Date()).add(1, "m").toDate(); // 1 minutes
      localStorage.setItem("upgrade_request_timer", timer);
      successSnackBar({
        msg: isNonAdmin
          ? "Your admins has been notified."
          : "Growth Plan upgrade has been requested.",
      });
      return;
    }

    eventsTracker("request_upgrade_plan");
    mutationUpgrade();
    setIsRequesting(true);
    setTimeout(() => setIsRequestSent(true), 400);
  };

  if (isRequestSent) {
    return (
      <Fragment>
        <img src={"/images/notified-image"} />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          {isNonAdmin
            ? "We've notified your admin!"
            : "Plan upgrade request has been received!"}
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          {isNonAdmin
            ? "Sit back and relax. Your plan upgrade request will be reviewed soon."
            : "Our sales representative will be in touch soon to help you with the process."}
        </GothamRegular>
        <ButtonJack
          style={{ width: "100%", marginTop: "32px" }}
          onClick={() => handleClick("okay")}
        >
          Okay
        </ButtonJack>
      </Fragment>
    );
  }

  if (isNonAdmin) {
    return (
      <Fragment>
        <UpgradeRequestImage />
        <GothamMedium
          className="font20"
          style={{ color: jackColors.neutral900, marginTop: "32px" }}
        >
          Are you sure to send the request?
        </GothamMedium>
        <GothamRegular
          woFontColor
          style={{ color: jackColors.neutral700, marginTop: "16px" }}
        >
          Your admin will get notified about the need for plan upgrade.
        </GothamRegular>
        <div style={{ display: "flex", gap: "16px", width: "100%" }}>
          <ButtonJack
            type="outline"
            style={{ width: "100%", marginTop: "32px" }}
            onClick={() => handleClick("cancel")}
          >
            Cancel
          </ButtonJack>
          <ButtonJack
            style={{ width: "100%", marginTop: "32px" }}
            onClick={() => handleClick()}
          >
            Send Request
          </ButtonJack>
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <UpgradeRequestImage />
      <GothamMedium
        className="font20"
        style={{ color: jackColors.neutral900, marginTop: "32px" }}
      >
        Upgrade to Growth Plan?
      </GothamMedium>
      <GothamRegular
        woFontColor
        style={{ color: jackColors.neutral700, marginTop: "16px" }}
      >
        Our sales representative will contact you promptly to provide
        assistance.
      </GothamRegular>
      <ButtonJack
        style={{ width: "100%", marginTop: "32px" }}
        onClick={() => handleClick("not-okay")}
      >
        Upgrade Plan
      </ButtonJack>
      <GothamRegular
        woFontColor
        className="font12"
        style={{
          color: jackColors.neutral800,
          textAlign: "left",
          alignSelf: "flex-start",
          marginTop: "12px",
        }}
      >
        By upgrading your plan, you agree to the following{" "}
        <a
          style={{
            fontFamily: "GothamMedium",
            cursor: "pointer",
            color: jackColors.neutral900,
          }}
          target="_blank"
          href="/tnc-subscription"
          // onClick={handleDownload}
        >
          Terms & Conditions
        </a>
      </GothamRegular>
    </Fragment>
  );
};

export default UpgradeContent;
