import BottomContainer from "./BottomContainer";
import { colors } from "../../../../../assets/colors";
import { ButtonJack } from "../../../../../components/ButtonsJack/parent";

const UnderstandButton = ({ setCurrentScreen }) => {
  const onClick = () => {
    setCurrentScreen("detailIndex");
  };

  const bottomContainerStyle = {
    padding: "0 32px 0 16px",
    width: "calc(100% + 48px)",
  };

  const bottomButtonContainerStyle = {
    padding: "20px",
    borderTop: `1px solid ${colors.neutral500}`,
  };

  const buttonStyle = {
    width: "100%",
  };

  return (
    <BottomContainer style={bottomContainerStyle}>
      <div style={bottomButtonContainerStyle}>
        <ButtonJack style={buttonStyle} onClick={onClick}>
          I understand
        </ButtonJack>
      </div>
    </BottomContainer>
  );
};

export default UnderstandButton;
