import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook, useModalWidth } from "../../../components/Modals";
import { ProgressBarJack } from "../../../components/Stepper";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import { FileInputJack } from "../../../components/inputs/fileInputs/fileInput";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useDemo } from "../../../contexts/DemoContext";
import { useGuidedTour } from "../../../contexts/GuidedTourContext";
import { useHeader } from "../../../contexts/Layout/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { downloadApi } from "../../../tools/api";
import { eventsTracker } from "../../../universalFunctions/events";
import { WrapperJackCenter } from "../../crossBorder/components";
import useGuidedTourBuilder from "../../productDemoComponents/builders";
import { ModalConfirmationCloseLocal } from "./modal";
import { stepArrayLocalFunc } from "./parent";
import { HeaderDataTableLocal } from "./table/data";
import { ModalAddRowLocal } from "./table/modal";
import {
  LOCAL_MODULE,
  LOCAL_SECOND_PAGE,
  LOCAL_SECOND_TARGET,
  LOCAL_THIRD_PAGE,
  LOCAL_THIRD_TARGET,
} from "../../productDemoComponents/constants";
import { Banner } from "../../../components/Banner";
import {
  DEMO_CANCEL_CLOSE_PAGE,
  DEMO_CHOOSE_EMPTY_TABLE,
  DEMO_CHOOSE_EXCEL_TEMPLATE,
  DEMO_CLICK_BACK,
  DEMO_CLICK_NEXT,
  DEMO_CLOSE_PAGE,
  DEMO_CONFIRM_CLOSE_PAGE,
  DEMO_PAGE_VIEW,
  DEMO_POPUP_REGISTER,
} from "../../productDemoComponents/constants/events";
import DemoGeneralModal from "../../productDemoComponents/components/DemoGeneralModal";

export const dummyDataLocal = [
  { name: "delete" },
  { name: "number" },
  { name: "name", value: "", label: "Name" },
  { name: "bank", value: "", label: "Bank" },
  { name: "account_number", value: "", label: "Bank Account Number" },
  { name: "notes", value: "", label: "Notes" },
  { name: "email", value: "", label: "Email" },
  { name: "amount", value: "", label: "Amount" },
  { name: "action" },
];

const LocalTransferSecondForm = ({
  onSubmit: onSubmitProps = () => {},
  toggle,
  loading,
}) => {
  const useFormObj = useForm();
  const { watch, handleSubmit } = useFormObj;
  const { successSnackBar } = ToasterHook();
  const { headerArray, tableBodyRef } = HeaderDataTableLocal();
  const {
    isOpen: isOpenAdd,
    toggle: toggleAdd,
    close: closeAdd,
  } = useModalHook();
  const { isOpen: isOpenRestrict, toggle: toggleRestrict } = useModalHook();
  const { isOpen: isOpenClose, toggle: toggleClose } = useModalHook();
  const { setHeader } = useHeader();

  const { setTourState } = useConstants();

  useEffect(() => {
    setHeader({
      type: "close",
      onCloseProps: () => {
        eventsTracker(DEMO_CLOSE_PAGE, {
          activity_type: "create_local_transfer",
          page_title,
        });
        toggleClose();
      },
      middleComponent: <ProgressBarJack arrayStep={stepArrayLocalFunc(1)} />,
    });
  }, []);

  const [screen, setScreen] = useState("choose");

  const isChooseScreen = screen === "choose";
  const isUploadScreen = screen === "upload";
  const isEmptyTableScreen = screen === "empty_table";

  const handleClick = ({ value, type }) => {
    const isBack = type === "back";
    const isNext = type === "next";
    const isToChoose = type === "to_choose";
    const isCSV = value === "csv";
    const isEmptyTable = value === "empty_table";

    if (isEmptyTable) {
      eventsTracker(DEMO_CLICK_NEXT, {
        activity_type: "create_local_transfer",
        page_title: "input_recipient",
      });
      eventsTracker(DEMO_CHOOSE_EMPTY_TABLE, {
        activity_type: "create_local_transfer",
        page_title: "input_recipient",
      });
      return setCombineData([dummyDataLocal]);
    }
    if (isToChoose) {
      eventsTracker(DEMO_CLICK_BACK, {
        activity_type: "create_local_transfer",
        page_title: "upload_recipient_excel",
      });
      return setScreen("choose");
    }
    if (isNext) {
      if (isCSV) {
        eventsTracker(DEMO_CLICK_NEXT, {
          activity_type: "create_local_transfer",
          page_title: "input_recipient",
        });
        eventsTracker(DEMO_CHOOSE_EXCEL_TEMPLATE, {
          activity_type: "create_local_transfer",
          page_title: "input_recipient",
        });
        return setScreen("upload");
      }
      // return setScreen("emptytable");
    }
    if (isBack) {
      eventsTracker(DEMO_CLICK_BACK, {
        activity_type: "create_local_transfer",
        page_title,
      });
      return toggle();
    }
  };

  // const { realFile } = watch("upload_file") || {};
  const file = watch("upload_file");
  const isDisabled = !file;

  const afterSuccess = () => {
    successSnackBar({
      msg: "Template has been downloaded.",
      showClose: true,
    });
  };

  const onClickDownload = () => {
    // downloadApi({
    //   url: "/local_transaction_batches/template_batch_import?format_template=excel",
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   fileName: "Jack-Template-Local-Transfer.xlsx",
    //   afterSuccess,
    // });
    // demo purpose
    eventsTracker("download_template_local_transfer", {
      activity_type: "create_local_transfer",
      page_title: "upload_recipient_excel",
    });
    eventsTracker(DEMO_POPUP_REGISTER, {
      page_title: "create_local_transfer",
    });
    toggleRestrict();
  };

  const onSubmit = (values) => {
    eventsTracker(DEMO_CLICK_NEXT, {
      activity_type: "create_local_transfer",
      page_title,
    });
    onSubmitProps({ type: "second", values });
  };
  const setCombineData = (values) =>
    onSubmitProps({ type: "second_modal", values });

  const modalWidth = useModalWidth(528);

  const arrayInputMethod = [
    {
      title: "CSV",
      desc: "Transfer to up to 1000 recipients by uploading Excel/CSV file",
      icon: "file-text-outline",
      value: "csv",
    },
    {
      title: "Empty Table",
      desc: "Enter your recipients details manually, one by one",
      icon: "backup-table",
      value: "empty_table",
    },
  ];

  const arrayUploadDetails = {
    title1: "1. Download this template",
    desc1:
      "Download and fill in the recipient details according to this template.",
    title2: "2. Upload the filled template",
    desc2: "Upload the filled Excel/CSV to start creating your transaction.",
  };

  // demo purpose
  const [pageProps, setPageProps] = useState(null);

  const thirdPageProps = useGuidedTourBuilder({
    module: LOCAL_MODULE,
    page: LOCAL_THIRD_PAGE,
  });

  const secondPageProps = useGuidedTourBuilder({
    module: LOCAL_MODULE,
    page: LOCAL_SECOND_PAGE,
  });

  const { startTour, stopTour } = useGuidedTour(pageProps);
  const { guidanceState, setGuidanceState } = useDemo();
  const { isGuidanceMode, isTourRunning } = guidanceState ?? {};

  const page_title = (function () {
    if (isChooseScreen) {
      return "input_recipient";
    }
    if (isUploadScreen) {
      return "upload_recipient_excel";
    }
  })();

  useEffect(() => {
    if (!isGuidanceMode && !isTourRunning) return;
    setTimeout(() => {
      setGuidanceState((prev) => ({ ...prev, isTourRunning: true }));
      startTour();
    }, 200);
  }, [screen, isGuidanceMode, pageProps]);

  useEffect(() => {
    if (!pageProps) return;
    return setGuidanceState((prev) => ({
      ...prev,
      guidanceFunction: isTourRunning ? stopTour : startTour,
      eventPayload: {
        activity_type: "create_local_transfer",
        page_title,
      },
    }));
  }, [isTourRunning, pageProps]);

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, {
      activity_type: "create_local_transfer",
      page_title,
    });

    if (isUploadScreen) {
      setPageProps(thirdPageProps);
      return;
    }
    setPageProps(secondPageProps);
  }, [screen]);

  return (
    <div ref={tableBodyRef}>
      {isChooseScreen && (
        <ChooseInputMethod array={arrayInputMethod} handleClick={handleClick} />
      )}
      {isUploadScreen && (
        <Fragment>
          <ImportRecipientCSV
            id={LOCAL_THIRD_TARGET}
            useFormObj={useFormObj}
            onClick={handleSubmit(onSubmit)}
            handleClick={handleClick}
            onClickDownload={onClickDownload}
            isDisabled={isDisabled}
            arrayDetails={arrayUploadDetails}
            loading={loading}
            module={LOCAL_MODULE}
          />
          <DemoGeneralModal
            isOpen={isOpenRestrict}
            toggle={toggleRestrict}
            pageTitle={"create_local_transfer"}
          />
        </Fragment>
      )}

      {/* <GothamRegular>
        Or, click here to{" "}
        <span
          style={{
            color: colors.pink,
            textDecoration: "underline",
            cursor: "pointer",
          }}
          onClick={toggleAdd}
        >
          start with an empty table
        </span>
      </GothamRegular> */}
      <ModalAddRowLocal
        widthParent={1376}
        isOpen={isOpenAdd}
        toggle={toggleAdd}
        headerArray={headerArray}
        setCombineData={setCombineData}
      />
      <ModalConfirmationCloseLocal
        isOpen={isOpenClose}
        product="local"
        onClick={() => {
          eventsTracker(DEMO_CONFIRM_CLOSE_PAGE, {
            activity_type: "create_local_transfer",
            page_title,
          });
        }}
        onClickLeft={() => {
          if (isOpenClose)
            eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
              activity_type: "create_local_transfer",
              page_title,
            });
          toggleClose();
        }}
        onClickClose={() => {
          eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
            activity_type: "create_local_transfer",
            page_title,
          });
        }}
        toggle={toggleClose}
      />
    </div>
  );
};

export default LocalTransferSecondForm;

export const ChooseInputMethod = ({
  array,
  handleClick,
  title = "Input your recipient details",
  desc = "Select the way you want to input your recipient details",
}) => {
  const [isHover, setIsHover] = useState(false);
  const [active, setActive] = useState(false);

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span id={LOCAL_SECOND_TARGET} style={{ display: "inline-flex" }}>
        <WrapperJackCenter title={title} styleTitle={{ marginBottom: 8 }}>
          <GothamRegular
            style={{ color: jackColors.neutral800, marginBottom: 32 }}
          >
            {desc}
          </GothamRegular>
          {array?.map((item, index) => {
            const { title, desc, icon, value } = item || {};
            const isFirst = index === 0;
            const isActive = value === active;
            const isActiveHover = isHover === index;
            return (
              <div
                key={index}
                className="d-flex align-items-center justify-content-between"
                style={{
                  border: `1px solid ${
                    isActive ? jackColors.black34 : jackColors.neutral500
                  }`,
                  borderRadius: 4,
                  cursor: "pointer",
                  padding: 12,
                  height: 64,
                  marginTop: !isFirst && 16,
                  backgroundColor: "white",
                  transition: "all 0.4s ease",
                  backgroundColor: isActiveHover
                    ? "rgba(0, 0, 0, 0.04)"
                    : "white",
                }}
                onMouseEnter={() => setIsHover(index)}
                onMouseLeave={() => setIsHover(false)}
                onClick={() => setActive(value)}
              >
                <div className="d-flex align-items-center">
                  <JackIcons
                    style={{ width: 20, height: 20 }}
                    styleDiv={{
                      backgroundColor: jackColors.neutral900,
                      width: 32,
                      height: 32,
                      padding: 6,
                      borderRadius: 8,
                      marginRight: 16,
                    }}
                    name={icon}
                    fill="white"
                  />
                  <div>
                    <GothamMedium
                      style={{
                        color: isActive
                          ? jackColors.neutral900
                          : jackColors.neutral800,
                        lineHeight: "20px",
                      }}
                    >
                      {title}
                    </GothamMedium>
                    <GothamRegular
                      className="font12"
                      style={{
                        color: jackColors.neutral800,
                        lineHeight: "20px",
                      }}
                    >
                      {desc}
                    </GothamRegular>
                  </div>
                </div>
                {isActive && (
                  <JackIcons
                    name="checkmark-circle-2"
                    fill={jackColors.neutral900}
                    style={{ width: 20, height: 20 }}
                  />
                )}
              </div>
            );
          })}
          <div
            className="d-flex justify-content-end"
            style={{ gap: 16, marginTop: 32 }}
          >
            <ButtonJack
              type="outline"
              children="Back"
              style={{ minWidth: 65 }}
              onClick={() => handleClick({ type: "back" })}
              leftIcon={
                <JackIcons
                  name="arrow-back-outline"
                  fill="black"
                  style={{ width: 20, height: 20 }}
                />
              }
            />
            <ButtonJack
              disabled={!active}
              type="filled"
              children="Next"
              style={{ minWidth: 60 }}
              onClick={() => handleClick({ type: "next", value: active })}
              rightIcon={
                <JackIcons
                  style={{ width: 20, height: 20 }}
                  name="arrow-forward"
                  fill={!active ? jackColors.neutral600 : "black"}
                />
              }
            />
          </div>
        </WrapperJackCenter>
      </span>
    </div>
  );
};

export const ImportRecipientCSV = ({
  onClick,
  handleClick,
  useFormObj,
  onClickDownload,
  isDisabled,
  arrayDetails,
  title = "Input recipient details in CSV",
  loading,
  moreFileTypes = [],
  wrapperStyle,
  woButton = false,
  id = "input-csv",
  module = null,
}) => {
  const { title1, title2, desc1, desc2 } = arrayDetails || {};
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <span id={id} style={{ display: "inline-flex" }}>
        <WrapperJackCenter title={title} containerStyle={wrapperStyle}>
          <GothamMedium className="mb-1">{title1}</GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700, marginBottom: 16 }}
          >
            {desc1}
          </GothamRegular>
          <ButtonJack
            onClick={onClickDownload}
            className="w-100"
            type="outline"
            children="Download template"
            style={{ marginBottom: 32, height: 32 }}
            rightIcon={
              <JackIcons
                style={{ width: 20, heigth: 20 }}
                name="download-outline"
                fill={jackColors.neutral900}
              />
            }
          />
          <GothamMedium className="mb-1">{title2}</GothamMedium>
          <GothamRegular
            className="font12"
            style={{ color: jackColors.neutral700, marginBottom: 16 }}
          >
            {desc2}
          </GothamRegular>
          <Banner
            style={{ marginBottom: "16px" }}
            msg={
              "We’ve prepared a sample template to optimize your demo experience. Click the area below to proceed!"
            }
          />
          <FileInputJack
            formData
            maxSizeInfoInside
            showLabel={false}
            fileTypes={[
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
              ...moreFileTypes,
            ]}
            useFormObj={useFormObj}
            name="upload_file"
            type="general"
            firstCopy="Browse or drag files here"
            multiple={false}
            module={module}
          />
          {!woButton && (
            <div
              className="d-flex justify-content-end"
              style={{ gap: 16, marginTop: 32 }}
            >
              <ButtonJack
                type="outline"
                children="Back"
                style={{ minWidth: 65 }}
                onClick={() => handleClick({ type: "to_choose" })}
                leftIcon={
                  <JackIcons
                    name="arrow-back-outline"
                    fill="black"
                    style={{ width: 20, height: 20 }}
                  />
                }
              />
              <ButtonJack
                disabled={isDisabled}
                isLoading={loading}
                type="filled"
                children="Next"
                style={{ minWidth: 60 }}
                onClick={onClick}
                rightIcon={
                  <JackIcons
                    style={{ width: 20, height: 20 }}
                    name="arrow-forward"
                    fill={isDisabled ? jackColors.neutral600 : "black"}
                  />
                }
              />
            </div>
          )}
          {/* <div className="d-flex justify-content-center">
        <OutlineButton
          style={{
            height: 34,
            fontSize: 14,
            lineHeight: "22px",
            marginBottom: 24,
            marginTop: 8,
            width: "100%",
          }}
          icon={<img src={downloadIcon} style={{ marginRight: 8 }} />}
          onClick={onClickDownload}
        >
          Download Template
        </OutlineButton>
      </div>
      <NumberedList
        array={["", "Upload the filled template to create a transaction"]}
      />
      <FileInput
        isDottedBorder
        maxSizeInfoInside
        formData
        customText={["Browse or drag files here to upload"]}
        fileTypes={[
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ]}
        useFormObj={useFormObj}
        name="upload_file"
        type="general"
        multiple={false}
      />
      <CustomButton
        disabled={isDisabled}
        style={{ margin: "24px 0px 16px 0px" }}
        className="w-100 font16"
        onClick={onClick}
        //   isLoading={loading}
      >
        Continue
      </CustomButton> */}
        </WrapperJackCenter>
      </span>
    </div>
  );
};
