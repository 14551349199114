import {
  BILL,
  DIVIDER,
  EXPENSE_NUMBER,
  HANDLING_FEE,
  LOCAL,
  PAYROLL,
  REIMBURSEMENT,
  SUBTOTAL,
  TOTAL_AMOUNT,
  TOTAL_PAYMENT,
  TRX_TEMPLATE,
  VAT,
} from "../constants";

const summaryFormatter = (trx = TRX_TEMPLATE) => {
  const {
    vat,
    fee,
    type,
    name,
    subtotal,
    totalAmount,
    totalPayment,
    numOfExpense,
  } = trx ?? {};

  switch (type) {
    case LOCAL:
    case PAYROLL:
      return {
        type: type === LOCAL ? LOCAL : PAYROLL,
        name,
        list: [
          { type: TOTAL_AMOUNT, value: totalAmount },
          { type: HANDLING_FEE, value: fee },
          { type: DIVIDER },
          { type: TOTAL_PAYMENT, value: totalPayment },
        ],
      };

    case BILL:
      return {
        type: BILL,
        name,
        list: [
          { type: SUBTOTAL, value: subtotal },
          { type: VAT, value: vat },
          { type: DIVIDER },
          { type: TOTAL_AMOUNT, value: totalAmount },
          { type: HANDLING_FEE, value: fee },
          { type: DIVIDER },
          { type: TOTAL_PAYMENT, value: totalPayment },
        ],
      };

    case REIMBURSEMENT:
      return {
        type: REIMBURSEMENT,
        name,
        list: [
          { type: EXPENSE_NUMBER, value: numOfExpense },
          { type: TOTAL_AMOUNT, value: totalAmount },
          { type: HANDLING_FEE, value: fee },
          { type: DIVIDER },
          {
            type: TOTAL_PAYMENT,
            value: totalPayment - fee + fee * numOfExpense,
          },
        ],
      };

    default:
      return TRX_TEMPLATE;
  }
};

export default summaryFormatter;
