import React, { useEffect, useState } from "react";
import {
  BackHeader,
  MiddleWrapper,
  RightCardModalAtomic,
} from "../../../../components/CardModal";
import { ActivityDetailsHeader } from "../../activityModalComponents";
import crossBorderIcon from "../../../../assets/images/crossborder-icon-40.svg";
import CaretWhiteRightIcon from "../../../../assets/images/caret-right-white-icon.svg";
import { CenterendIconLocalTransfer } from "../../localDisbursementBatchModal/transactionModal";
import { useCrossBorderMutation, usePaymentMethod } from "./logic";
import {
  AlertTopup,
  PaymentMethodSelection,
  PaymentSummaryMethod,
} from "./components";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { PayrollPinModal } from "../../payroll/pinModal";
import { TopUpModal, useModalHook } from "../../../../components/Modals";
import { CustomButton } from "../../../../components/Buttons";
import { SmartActivityModal } from "../../parent";
import { DirectPaymentInstructionModal } from "../DirectPaymentInstructionModal";
import { eventsTracker } from "../../../../universalFunctions/events";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import { GothamMedium } from "../../../../components/Text";
import { WrapperModalButtons } from "../../../../pageComponents/homeComponents/topup/components";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import PINModal from "../../../../components/PINModal/parent";
import { jackColors } from "../../../../assets/colors";
import FloatingTooltip from "../../../../pageComponents/productDemoComponents/components/FloatingTooltip";

export const PaymentMethodModal = ({ isOpen, toggle, data }) => {
  const [selectedMethod, setSelectedMethod] = useState("balance");
  const [middleHeight, setMiddleHeight] = useState(0);
  const [dataPaymentInstruction, setDataPaymentInstruction] = useState({});
  const { toToggle, toListener } = useSmoothRightModal();

  const { isOpen: isOpenParent, toggle: toggleParent } = useModalHook();
  const { isOpen: isOpenPinModal, toggle: togglePinModal } = useModalHook();
  const {
    isOpen: isOpenTopUpModal,
    toggle: toggleTopUpModal,
    close: closeTopUpModal,
  } = useModalHook();
  const {
    isOpen: isOpenDirectInstruction,
    toggle: toggleDirectInstruction,
    close: closeDirectInstruction,
  } = useModalHook();

  const { user, refetchUser } = useGetAuth();
  const { balance } = user?.mainBalance || {};
  const { total_trx_amount } = data || {};

  const isNotEnoughBalance = Number(total_trx_amount) > Number(balance);
  const isBankTransfer = selectedMethod === "transfer";
  const disabledButton = isNotEnoughBalance && !isBankTransfer;

  const { headerData } = usePaymentMethod();

  const onClick = (event) => {
    const isOpenPin = event === "pin";
    const isBackParent = event === "back_parent";
    const isTopup = event === "top_up";

    if (isTopup) {
      eventsTracker("modal_topup_from_crossborder_payment_method");
      return toggleTopUpModal();
    }
    if (isBackParent) {
      toggle();
      return toggleParent();
    }
    return togglePinModal();
  };

  const afterSuccessDirectPayment = async (res) => {
    toggle();
    await setDataPaymentInstruction(res);
    toggleDirectInstruction();
    eventsTracker("open_modal_crossborder_payment_instruction");
  };

  const afterApprove = async (res) => {
    const { status } = res || {};
    const isSuccess = status === 200 || status === 204;
    if (isSuccess) {
      togglePinModal();
      onSubmit({ type: "update_single" });
    }
  };

  const { loading, approve, updateSingle } = useCrossBorderMutation({
    data,
    afterApprove,
    afterSuccess: isBankTransfer && afterSuccessDirectPayment,
  });

  const onSubmit = async ({ value, type }) => {
    const isDirectTransfer = type === "direct_transfer";
    const isUpdateSingle = type === "update_single";
    const isSubmitPin = type === "submit_pin";

    const { pin } = value || {};
    const payload = {
      payment_method: isBankTransfer ? "bank_transfer" : "indonesian_wallet",
    };

    if (isSubmitPin) {
      eventsTracker("submit_pin_crossborder_payment_method", {
        payment_method: payload,
      });
      return approve({ pin });
    }
    if (isUpdateSingle) {
      eventsTracker("choose_payment_method_crossborder", {
        data,
        payment_method: payload,
      });
      return updateSingle(payload);
    }
  };

  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (!isMounted) return;
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted]);

  const components = [
    <WrapperModalButtons
      childrenButton={
        <>
          <ButtonJack
            isLoading={loading}
            type="outline"
            children="Back"
            onClick={toToggle}
            style={{ width: "100%" }}
            leftIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="close-circle"
                fill={"black"}
              />
            }
          />
          <ButtonJack
            isLoading={loading}
            disabled={disabledButton}
            children="Continue"
            onClick={() => onClick("pin")}
            style={{ width: "100%", marginRight: 16 }}
            leftIcon={
              <JackIcons
                style={{ width: 20, height: 20 }}
                name="checkmark-circle-2"
                fill={disabledButton ? jackColors.greyBB : "black"}
              />
            }
          />
        </>
      }
      customComponentsStyle={{ top: -58 }}
      customComponentsTopButton={
        disabledButton && (
          <AlertTopup onClick={() => onClick("top_up")} balance={balance} />
        )
      }
    >
      <GothamMedium className="font24" style={{ marginBottom: 32 }}>
        Choose payment method
      </GothamMedium>
      <span
        ref={(e) => {
          if (!e) return;
          setIsMounted(true);
        }}
      >
        <FloatingTooltip
          isOpen={isHoveredInfo}
          close={() => setIsHoveredInfo(false)}
          title="Deciding on a payment method?"
          body="Pro tip: company balance is the simplest option. Say goodbye to manual verification and receipt uploads!"
          tooltipStyle={{
            translate: "-280px -140px",
            width: 260,
          }}
          translateTriangle={`260px 70px`}
          position="left"
        >
          <PaymentMethodSelection
            balance={balance}
            selectedMethod={selectedMethod}
            setSelectedMethod={setSelectedMethod}
          />
        </FloatingTooltip>
      </span>
      <hr style={{ margin: "32px 0px" }} />
      <PaymentSummaryMethod
        balance={balance}
        refetchUser={refetchUser}
        totalTrx={total_trx_amount}
        selectedMethod={selectedMethod}
        isNotEnoughBalance={isNotEnoughBalance}
        onClick={() => onClick("top_up")}
      />
    </WrapperModalButtons>,
  ];

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={toggle}
        array={components}
        listener={toListener}
        width={[420]}
      />
      <PINModal
        isOpen={isOpenPinModal}
        toggle={togglePinModal}
        isLoading={loading}
        onSubmit={(value) => onSubmit({ value, type: "submit_pin" })}
        onBack={togglePinModal}
      />
      <SmartActivityModal
        data={{ ...data, type: "single_transaction" }}
        isOpen={isOpenParent}
        toggle={toggleParent}
      />
      <TopUpModal
        paid
        back
        onClickPaid={() => toggleTopUpModal()}
        toggle={() => toggleTopUpModal()}
        modal={isOpenTopUpModal}
      />
      <DirectPaymentInstructionModal
        data={dataPaymentInstruction}
        isOpen={isOpenDirectInstruction}
        toggle={toggleDirectInstruction}
        close={closeDirectInstruction}
        onClick={() => onClick("back_parent")}
      />
    </>
  );

  return (
    <>
      <RightCardModalAtomic
        isOpen={isOpen}
        header={
          <ActivityDetailsHeader
            toggle={toggle}
            data={headerData}
            close={
              <BackHeader
                color="#FFF"
                text="Back"
                toggle={() => onClick("back_parent")}
              />
            }
          />
        }
        headerStyle={{ paddingBottom: 60 }}
        middleHeight={middleHeight}
        middle={
          <MiddleWrapper setHeight={setMiddleHeight} height={middleHeight}>
            <CenterendIconLocalTransfer icon={crossBorderIcon} />
          </MiddleWrapper>
        }
        paddingTop={18}
        body={
          <>
            <PaymentMethodSelection
              balance={balance}
              selectedMethod={selectedMethod}
              setSelectedMethod={setSelectedMethod}
            />
            <hr className="my-4" />
            <PaymentSummaryMethod
              balance={balance}
              refetchUser={refetchUser}
              totalTrx={total_trx_amount}
              selectedMethod={selectedMethod}
              isNotEnoughBalance={isNotEnoughBalance}
              onClick={() => onClick("top_up")}
            />
            <CustomButton
              disabledGrey={disabledButton}
              style={{ width: "100%", height: 40 }}
              rightIcon={<img src={CaretWhiteRightIcon} className="ml-2" />}
              onClick={() => onClick("pin")}
            >
              Continue
            </CustomButton>
          </>
        }
      />
      <PayrollPinModal
        loading={loading}
        buttonText="Submit"
        isOpen={isOpenPinModal}
        toggle={togglePinModal}
        title="Input your PIN to approve"
        onSubmit={(value) => onSubmit({ value, type: "submit_pin" })}
        // isEmployeeAllowed
      />
      <SmartActivityModal
        data={{ ...data, type: "single_transaction" }}
        isOpen={isOpenParent}
        toggle={toggleParent}
      />
      <TopUpModal
        paid
        back
        onClickPaid={() => toggleTopUpModal()}
        toggle={() => toggleTopUpModal()}
        modal={isOpenTopUpModal}
      />
      <DirectPaymentInstructionModal
        data={dataPaymentInstruction}
        isOpen={isOpenDirectInstruction}
        toggle={toggleDirectInstruction}
        close={closeDirectInstruction}
      />
    </>
  );
};
