import { eventsTracker } from "../../../../universalFunctions/events";
import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  REIMBURSEMENT_SECOND_TARGET,
  REIMBURSEMENT_THIRD_TARGET,
} from "../../constants";
import { DEMO_GUIDED_TOUR_BACK, DEMO_GUIDED_TOUR_NEXT } from "../../constants/events";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${REIMBURSEMENT_SECOND_TARGET}`,
    content: (
      <TooltipContent
        title="Upload your receipts here"
        description="You can upload up to 10 receipts in one batch of reimbursement."
      />
    ),
    locale: {
      next: <NextText>Next</NextText>,
      close: <NextText>Next</NextText>,
    },
    eventPayload: {
      activity_type: "create_reimbursement",
      guided_tour_activity: "upload_reimbursement"
    },
  },
  {
    ...DEFAULT_PROPERTIES,
    target: `#${REIMBURSEMENT_THIRD_TARGET}`,
    content: (
      <TooltipContent
        title="Continue without receipt"
        description="Don't have the receipt yet? You can fill out the details first and then upload the receipt later."
      />
    ),
    locale: {
      back: <NextText>Back</NextText>,
      next: <NextText>Got it</NextText>,
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_reimbursement",
      guided_tour_activity: "continue_without_receipt"
    },
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle, step } = data ?? {};
  const {eventPayload = {}} = step ?? {}

  const isComplete = lifecycle === "complete";

  if (!isComplete) return;

  switch (action) {
    case "close":
      eventsTracker(DEMO_GUIDED_TOUR_NEXT, eventPayload)
      if (index === 0) {
        setGuidedTourState((prev) => ({ ...prev, stepIndex: 1 }));
        break;
      }
      if (index === 1) {
        setGuidedTourState(defaultState);
        break;
      }
      break;
      
      case "prev":
      eventsTracker(DEMO_GUIDED_TOUR_BACK, eventPayload)
      if (index === 1) {
        setGuidedTourState((prev) => ({ ...prev, stepIndex: 0 }));
        break;
      }
      break;

    default:
      break;
  }
};

const reimbursementSecondPageProps = { steps, callback };

export default reimbursementSecondPageProps;
