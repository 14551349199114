import { useRouter } from "next/router";
import { Fragment, useEffect, useState } from "react";
import { colors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { RightModal } from "../../../components/Modals/RightModal/parent";
import { StatusLabelJack } from "../../../components/StatusLabel";
import { GothamMedium, GothamRegular } from "../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { WrapperModalButtons } from "../../../pageComponents/homeComponents/topup/components";
import { attachmentDataFormatter } from "../../../pageComponents/invoiceComponents/data/formatter";
import { InvoicePaymentTableJack } from "../../../pageComponents/invoiceComponents/tableJack";
import {
  draftTotalAmount,
  reimbursementFormatter,
} from "../../../pageComponents/reimbursementComponents/data/formatter";
import { fetch } from "../../../tools/api";
import { DetailsModal } from "../crossBorderSingleModal/components";
import { useSchedulePaymentButtons } from "../invoicePayment/hooks/components";
import { DraftButtons } from "../invoicePayment/parent";
import { InvoicePaymentStatusLine } from "../invoicePayment/status";
import {
  recipientDetails,
  reimbursementBatchWorkflow,
  reimbursementStatus,
  totalExpenseDetails,
} from "./logics";
import { ReimbursementDetailModal } from "./modals";
import { getUserRole, useGetAuth } from "../../../contexts/AuthContext";
import { ScheduledBanner } from "../invoicePayment/hooks/components/buttons";
import { ReimbursementDetails } from "../../../pageComponents/reimbursementComponents/createPage/submitPage";
import {
  useRetryResubmitModalPayloads,
  useTrxReleaser,
} from "../retry-resubmit/hooks";
import { booleanState } from "../../../components/Status";
import {
  RetryResubmitBanner,
  RetryResubmitButton,
  SingleRetryUpdateTracker,
} from "../retry-resubmit/components";
import UpdateTrackerV2 from "../../../components/UpdateTrackerV2";
import FloatingTooltip from "../../../pageComponents/productDemoComponents/components/FloatingTooltip";

export const ReimbursementModal = ({
  isOpen,
  toggle,
  data,
  handleDelete,
  listener,
}) => {
  const { push } = useRouter();
  if (!isOpen) return null;

  const { id: idRaw, originator_id } = data || {};
  const id = originator_id || idRaw;

  const [reimbursementId, setReimbursementId] = useState(id);

  const {
    data: details,
    refetch,
    loading,
    setData: setDetails,
  } = fetch({
    url: `/reimbursements/${reimbursementId}`,
    woInit: true,
    defaultValue: {},
    formatter: (res) => {
      const { data, attachment_data } = res;
      // const [formattedData] = reimbursementFormatter([data]);
      // return { ...formattedData };

      if (data) {
        const formattedReimbursementData = reimbursementFormatter([data]);
        const [newData] = formattedReimbursementData;

        const formattedReimbursementChild = reimbursementFormatter(
          data?.reimbursement_child
        );

        const newAttachmentData = attachmentDataFormatter(
          (attachment_data || []).map((item, index) => {
            const url =
              formattedReimbursementData[0].supporting_documents_files[index];
            return { ...item, url };
          })
        );
        return {
          ...newData,
          newAttachmentData,
          reimbursement_child: formattedReimbursementChild,
        };
      }
    },
  });

  useEffect(() => {
    if (!reimbursementId && id) return setReimbursementId(id);
    refetch();
  }, [reimbursementId, id]);

  const { user } = useGetAuth();
  const { id: userId } = user ?? {};

  const { reimbursement_child, state, scheduled_payment } = details || {};
  const isScheduledTransaction = Boolean(scheduled_payment);

  const {
    buttons: workflowButton,
    canApprove,
    rejectModal,
    isRejection,
    hideButtons,
    workflowObjs,
    isLastApprover,
    isApprover,
    isDrafter,
    handleTableRowClick,
  } = reimbursementBatchWorkflow({
    transaction: details,
    refetch,
    setTransaction: setDetails,
    dataParent: data,
    allData: reimbursement_child,
    listener,
  });
  const [detailId, setDetailId] = useState(null);
  const isDrafted = details?.state == "drafted";

  const handleClickRow = ({ id }) => {
    if (isDrafted) return;
    setDetailId(id);
  };

  const { buttons: schedulePaymentButton } = useSchedulePaymentButtons({
    transaction: details,
    isReleaser: isLastApprover,
  });

  const transaction = {
    ...details,
  };

  const { canSeeBanner, canRetryOrResubmit, retryResubmitDefaultProps } =
    useRetryResubmitModalPayloads({
      module: "reimbursement",
      transaction,
      onClickSeeDetails: (detailId) => setReimbursementId(detailId),
    });

  const { onSeeDetails } = retryResubmitDefaultProps;

  const buttonDecider = () => {
    const { state } = details ?? {};

    if (isScheduledTransaction) return schedulePaymentButton();

    if (canRetryOrResubmit) {
      return (
        <div className="d-flex justify-content-between w-100">
          <RetryResubmitButton
            {...retryResubmitDefaultProps}
            style={{ marginTop: "20px" }}
          />
        </div>
      );
    }
    if (state == "drafted")
      return (
        <DraftButtons
          drafts={reimbursement_child}
          type="reimbursement"
          batchId={id}
          handleContinue={() =>
            push(
              `/reimbursement/create/?step=review&btcid=${id}&origin_draft=true`
            )
          }
          handleDelete={() => handleDelete(id)}
        />
      );

    return workflowButton();
  };
  return (
    <>
      <RightModal
        isOpen={isOpen && !isRejection}
        toggle={toggle}
        noPadding={true}
        width={[420, 480]}
        isLoading={loading}
        array={[
          <MainContent
            details={details}
            buttons={buttonDecider}
            isLastApprover={isLastApprover}
            isApprover={isApprover}
            onSeeDetails={onSeeDetails}
            children={
              canSeeBanner && (
                <RetryResubmitBanner
                  {...retryResubmitDefaultProps}
                  style={{ marginTop: "24px", marginBottom: "24px" }}
                />
              )
            }
          />,
          <ReimbursementChildList
            childs={reimbursement_child}
            onClick={handleTableRowClick(handleClickRow)}
            isDraft={state == "drafted"}
          />,
        ]}
      />
      <ReimbursementDetailModal
        isApprover={canApprove}
        isDrafter={isDrafter}
        data={reimbursement_child}
        setAllData={setDetails}
        batchId={id}
        isOpen={Boolean(detailId)}
        detailId={detailId}
        toggle={() => setDetailId(null)}
        onClickRow={handleClickRow}
        hideButtons={hideButtons}
        workflowObjs={workflowObjs}
        refetch={refetch}
        dataState={details?.state ?? details?.status}
        dataParent={data}
        allData={details?.reimbursement_child}
        listener={listener}
      />
      {rejectModal()}
    </>
  );
};

const totalAmountDecider = (details) => {
  if (!details) return "IDR 0";

  const { amount, amountIDR, state, initial_amount, reimbursement_child } =
    details;

  const isFullCancel = amount == 0;
  const isDraft = state == "drafted";

  if (isDraft) return draftTotalAmount(reimbursement_child);

  return !isFullCancel
    ? `IDR ${formatCurrencyNoDecimal(amount)}`
    : `IDR ${formatCurrencyNoDecimal(initial_amount)}`;
};

const MainContent = ({
  buttons,
  details,
  isLastApprover,
  children,
  isApprover,
  onSeeDetails = () => {},
}) => {
  const {
    amount,
    amountIDR,
    status,
    state,
    user,
    name,
    merchant,
    initial_amount,
    last_rejected_receipt_by: rejectExecutor,
    reimbursement_child,
    scheduled_payment,
    ancestor_id,
  } = details ?? {};

  const { data: ancestorTrxData, refetch: fetchAncestorTrxData } = fetch({
    url: `/reimbursements/${ancestor_id}`,
    woInit: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (!ancestor_id) return;
    const fetchTimeout = setTimeout(() => {
      fetchAncestorTrxData();
    }, 400);

    return () => clearTimeout(fetchTimeout);
  }, [ancestor_id]);

  const { releaser } = useTrxReleaser({ trx: ancestorTrxData?.data });

  const fullCancel = amount == 0;
  const totalAmountMain = totalAmountDecider(details);
  console.log('details:', details)
  const recipientArray = recipientDetails(details);
  const totalAmountArray = totalExpenseDetails(details, isLastApprover);

  const isRetriedTrx = !!ancestor_id;

  const { isBookKeeper, isAdmin, isSuperAdmin } = getUserRole();
  const canViewFee = isSuperAdmin || isAdmin || isApprover || isBookKeeper;
  return (
    <WrapperModalButtons
      childrenButton={buttons()}
      containerStyle={buttonContainerStyle}
    >
      <div
        style={{
          padding: 16,
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
      >
        <Avatar
          size="medium"
          mainIcon={
            <JackIcons
              name="reimbursement"
              style={{ width: "auto", height: 18 }}
            />
          }
        />
        <div>
          <GothamRegular className="font14 text-black34 mb-1">
            Reimbursement: {name ?? merchant}
          </GothamRegular>
          <GothamMedium className="font24 text-black34 mb-1.5">
            {totalAmountMain}
          </GothamMedium>
          <div
            className="d-flex justify-content-between align-items-center"
            style={{ marginBottom: "24px" }}
          >
            <GothamRegular className="font12" style={{ color: colors.grey90 }}>
              Created by {user?.name ?? "-"}
            </GothamRegular>
            <StatusLabelJack
              status={reimbursementStatus(state, rejectExecutor, false)}
              isOutline={true}
              woTextFormatter
            />
          </div>
          <ScheduledBanner
            scheduled_payment={scheduled_payment}
            style={{ margin: "32px 0px" }}
          />
          {children}
          {isRetriedTrx ? (
            <SingleRetryUpdateTracker
              releaser={releaser}
              transaction={details}
              onSeeDetails={onSeeDetails}
            />
          ) : (
            <InvoicePaymentStatusLine transaction={details} />
          )}
        </div>
        <DetailsModal array={recipientArray} />
        <ReimbursementDetails data={details} woHeader isSimple={!canViewFee} />
      </div>
    </WrapperModalButtons>
  );
};

const ReimbursementChildList = ({ childs, onClick, isDraft = false }) => {
  // DEMO PURPOSE
  const [isHoveredInfo, setIsHoveredInfo] = useState(false);
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    if (isDraft) return;
    if (!isMounted) return;
    setTimeout(() => setIsHoveredInfo(true), 500);
  }, [isMounted, isDraft]);

  return (
    <div
      style={{ padding: 16 }}
      ref={(e) => {
        if (!e) return;
        setIsMounted(true);
      }}
    >
      <FloatingTooltip
        isOpen={isHoveredInfo}
        close={() => setIsHoveredInfo(false)}
        title="List of expense"
        body="All expense are listed in this section. Click on any to start reviewing."
        tooltipStyle={{
          translate: "-267px -250px",
          width: 260,
        }}
        translateTriangle={`260px 60px`}
        position="left"
      >
        <InvoicePaymentTableJack
          onClick={onClick}
          bodyArr={childs}
          tableType="rmbdetail"
          defaultSelectedIds={[]}
        />
      </FloatingTooltip>
    </div>
  );
};

export const buttonContainerStyle = {
  minHeight: 80,
  height: "auto",
  width: 420,
  padding: 0,
  paddingTop: 4,
  paddingLeft: 20,
  paddingRight: 20,
  position: "fixed",
  bottom: 56,
};
export default ReimbursementModal;
