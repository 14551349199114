import * as yup from "yup";
import { schemaGenerator } from "../../../components/tools";

const yupString = (string) =>
  yup
    .string()
    .required(string)
    .matches(/^(?!\s+$).*/, string);

export const schemaGeneratorKYB = ({
  stringArr = [],
  selectionArr = [],
  emailArr = [],
  filesArr = [],
}) => {
  const yupFilloutThisField = (array) =>
    array
      .filter((item) => item)
      .map((name) => ({
        name,
        yup: yupString("Please fill out this field"),
      }));

  const yupSelect = (array) =>
    array
      .filter((item) => item)
      .map((name) => ({ name, yup: yupString("Please select one") }));

  const yupEmail = (array) =>
    array
      .filter((item) => item)
      .map((name) => ({
        name,
        yup: yup
          .string()
          .email("Email invalid")
          .required("Please fill out this field"),
      }));

  const result = [
    ...yupSelect(selectionArr),
    ...yupFilloutThisField(stringArr),
    ...yupEmail(emailArr),
  ];

  const resolver = schemaGenerator({ stringArr: result, filesArr });

  return resolver;
};
