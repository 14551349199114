import { useRouter } from "next/router";
import React, { useState } from "react";
import Skeleton from "react-loading-skeleton";
import transfez from "../../../../assets/images/logo-only-transfez.svg";
import { CustomButton } from "../../../../components/Buttons";
import { useModalHook } from "../../../../components/Modals";
import { SmallScreenWrapper } from "../../../../components/ScreenWrappers";
import {
  LabelPayrollMasking,
  booleanState,
} from "../../../../components/Status";
import { GothamMedium } from "../../../../components/Text";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
  windowDimension,
} from "../../../../components/tools";
import { fetch } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import { TransactionActivities } from "../../../accountCardDetailsComponents/lists";
import { SuccessMoreOrLessButton } from "./buttons";
import { arrayDecider } from "./decider";
import { ReasonNotes, StatusPayrollLine } from "./status";

export const SuccessScreenDefault = ({
  title,
  array = [],
  statusArray = [],
  rejectionNote,
  onClick,
  buttonText = "Finish",
  limit,
  isLoading,
  woStateFormatter,
}) => {
  const { isOpen: showAll, toggle } = useModalHook();
  const { isTabOrPhone } = windowDimension();

  const arrayShownDecider = () => {
    if (isTabOrPhone) return array;

    if (typeof limit == "number") {
      if (showAll) return array;
      return array.filter((_, index) => index < limit);
    }

    return array;
  };

  const filteredArray = arrayShownDecider();

  const showSuccessOrLessButton =
    limit && array.length > limit && !isTabOrPhone;

  const [titleHeight, setTitleHeight] = useState(0);

  const remainingArray = array.length - filteredArray.length;

  return (
    <SmallScreenWrapper
      woBack
      style={{ width: showAll ? 312 + 32 + 480 : 480, position: "relative" }}
    >
      <div style={{ width: 480 }}>
        <div
          className="w-100 align-items-center d-flex"
          style={{ flexDirection: "column", marginBottom: 24 }}
          ref={(e) => setTitleHeight(e?.clientHeight || 0)}
        >
          <img src={transfez} />
          <GothamMedium className="font24" style={{ textAlign: "center" }}>
            {title}
          </GothamMedium>
        </div>
        <div>
          <TransactionActivities
            itemStyle={{
              backgroundColor: "white",
              borderWidth: 0,
              boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            }}
            woStateFormatter={woStateFormatter}
            data={filteredArray}
          />
          {showSuccessOrLessButton && (
            <SuccessMoreOrLessButton
              text={showAll ? "Few Less" : `+${remainingArray} More`}
              toggle={toggle}
            />
          )}
          {!showAll && (
            <StatusPayrollLine
              array={statusArray}
              style={{ marginTop: 20 }}
              loading={isLoading}
            />
          )}
          {rejectionNote && <ReasonNotes text={rejectionNote} />}
          <CustomButton
            className="w-100"
            onClick={onClick}
            style={{ marginTop: showAll ? 16 : 0 }}
            isLoading={isLoading}
          >
            {buttonText}
          </CustomButton>
        </div>
        {showAll && (
          <StatusPayrollLine
            array={statusArray}
            style={{
              width: 312,
              marginTop: 20,
              position: "absolute",
              top: titleHeight,
              right: 0,
            }}
          />
        )}
      </div>
    </SmallScreenWrapper>
  );
};

export const SuccessPayroll = () => {
  const { query, push } = useRouter();
  const { id } = query;

  const { data, loading } = fetch({
    url: `/payrolls/${id}`,
    formatter: (res) => res?.data || {},
    defaultValue: {},
  });
  const { name, total_amount, total_fee, updated_at } = data?.batch || {};

  const totalAmountFee = Number(total_amount) + Number(total_fee);

  const {
    state,
    state_summary,
    updated_at: updatedAt,
    rejection_note,
    created_at,
  } = data || {};

  const {
    isRejectedByApproval,
    isWaitingApprovalTask,
    isWaitingForRelease,
    isCancelled,
    isOnProgress,
    isCompleted,
    isRejectedByAdmin,
  } = booleanState(state);

  const isYetApproved = state_summary?.every(
    (item) => item.approved_at === null
  );

  const filteredStateSummary = state_summary?.filter(
    ({ role }) => role === "approver"
  );

  const isAllAlreadyApproved = filteredStateSummary?.every(
    (item) => item.state === "approved"
  );

  const isSubmitted = isWaitingApprovalTask && isYetApproved;

  const TitleDecider = () => {
    if (isRejectedByApproval || isRejectedByAdmin)
      return `You rejected the payroll`;
    if (isSubmitted) return `Your payroll has been submitted for approval`;
    if (isWaitingApprovalTask) return `You approved the payroll`;
    if (isWaitingForRelease && isAllAlreadyApproved)
      return `You approved the payroll`;
    if (isCancelled) return `Your payroll has been cancelled`;
    if (isOnProgress) return `Transaction is on progress`;
    if (isCompleted) return `Transaction has been completed`;
    return `-`;
  };

  const LeftBottomDecider = () => {
    // if (isSubmitted || isOnProgress) return ``;
    return `${customDateFormatter(updatedAt)}`;
  };
  const TitleText = TitleDecider();

  const array = [
    {
      leftTop: name,
      leftStatus: LabelPayrollMasking(state),
      leftBottom: LeftBottomDecider(),
      rightTop: `IDR ${formatCurrencyNoDecimal(totalAmountFee)}`,
      category: "payroll",
      state,
      created_at,
    },
  ];

  const statusArray = arrayDecider({ data });

  if (loading) return <ShimmerSuccesScreen />;

  return (
    <div style={{ height: "100vh", overflowY: "scroll", paddingBottom: 150 }}>
      <SuccessScreenDefault
        array={array}
        statusArray={statusArray}
        onClick={() => {
          eventsTracker(
            isSubmitted ? "payroll_submit_success" : "payroll_success",
            { id }
          );
          push({ pathname: "/payroll", query });
        }}
        title={TitleText}
        rejectionNote={rejection_note}
      />
    </div>
  );
};

const ShimmerSuccesScreen = () => {
  return (
    <SmallScreenWrapper woBack>
      <div
        className="w-100 align-items-center d-flex"
        style={{ flexDirection: "column", marginBottom: 24 }}
      >
        <img src={transfez} />
        <Skeleton width={400} height={50} className="mb-2" />
        <div
          style={{
            backgroundColor: "white",
            borderWidth: 0,
            boxShadow: "0px 4px 8px rgba(88, 88, 88, 0.1)",
            padding: 20,
            marginBottom: 20,
          }}
        >
          <Skeleton width={440} height={30} />
        </div>
        <Skeleton width={480} height={100} className="mb-2" />
        <CustomButton className="w-100 mt-2" isLoading={true}>
          finish
        </CustomButton>
      </div>
    </SmallScreenWrapper>
  );
};
