import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: "#payroll-first-form",
    content: (
      <TooltipContent
        title="Input payroll period, name, and category"
        description="These details will help your company in monitoring payroll activities."
      />
    ),
    locale: {
      close: <NextText>Got it</NextText>,
    },
    eventPayload: {
      activity_type: "create_payroll",
      guided_tour_activity: "fill_name_and_category"
    }
  },
];

const payrollNameProps = { steps };

export default payrollNameProps;
