import { colors } from "../../../../assets/colors";
import { RightModal } from "../../../../components/Modals/RightModal/parent";
import { GothamMedium } from "../../../../components/Text";
import { TextFieldJack } from "../../../../components/inputs/textfield";
import { useForm } from "react-hook-form";
import BottomContainer from "../CardModal/components/BottomContainer";
import { ButtonJack } from "../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { useSmoothRightModal } from "../../../../components/Modals/RightModal/parent";

const ModalContent = ({ toggle, onSubmit: onSubmitProps, isLoading }) => {
  const useFormObj = useForm();
  const { handleSubmit } = useFormObj;

  const onSubmit = (values) => {
    const { reason } = values || {};
    onSubmitProps(reason);
  };

  const buttonLeftIcon = (
    <JackIcons name="close-circle" fill={colors.neutral900} />
  );
  const buttonRightIcon = (
    <JackIcons name="checkmark-circle-2" fill={colors.neutral900} />
  );

  const containerStyle = {
    position: "relative",
    height: "100%",
  };

  const innerContainerStyle = {
    display: "flex",
    flexDirection: "column",
    gap: "32px",
  };

  const titleStyle = {
    color: colors.neutral900,
    fontSize: "24px",
  };

  const textFieldReasonStyle = {
    height: "60px",
  };

  const bottomContainerStyle = {
    padding: "20px 20px 0px 20px",
    display: "flex",
    gap: "16px",
  };

  const buttonStyle = {
    width: "100%",
  };

  return (
    <div style={containerStyle}>
      <div style={innerContainerStyle}>
        <GothamMedium style={titleStyle}>
          Why do you reject this request?
        </GothamMedium>
        <TextFieldJack
          name="reason"
          label="Reason of Rejection"
          textFieldStyle={textFieldReasonStyle}
          useFormObj={useFormObj}
          placeholder="E.g., This request contains incorrect data"
        />
      </div>
      <BottomContainer style={bottomContainerStyle}>
        <ButtonJack
          type="outline"
          onClick={toggle}
          leftIcon={buttonLeftIcon}
          style={buttonStyle}
        >
          Cancel
        </ButtonJack>
        <ButtonJack
          leftIcon={buttonRightIcon}
          style={buttonStyle}
          onClick={handleSubmit(onSubmit)}
          isLoading={isLoading}
        >
          Submit
        </ButtonJack>
      </BottomContainer>
    </div>
  );
};

const RejectModal = ({ isOpen, toggle, onSubmit, isLoading }) => {
  const { toListener } = useSmoothRightModal();
  return (
    <RightModal
      listener={toListener}
      isOpen={isOpen}
      toggle={toggle}
      array={[
        <ModalContent
          toggle={toggle}
          onSubmit={onSubmit}
          isLoading={isLoading}
        />,
      ]}
      woClose
    />
  );
};

export default RejectModal;
