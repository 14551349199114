import { isEmpty } from "lodash";
import {
  customDateFormatter,
  formatCurrencyNoDecimal,
  teamManagerTextDecider,
} from "../../../../components/tools";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { cardIssuanceStatusDecider } from "../../tools";
import { titleCase } from "change-case";
import { cardStateDecider } from "../../tools";
import { GothamRegular, TextInlineMedium } from "../../../../components/Text";
import { colors } from "../../../../assets/colors";

const useCardWorkflow = (cardDetail = {}) => {
  if (isEmpty(cardDetail))
    return {
      progressArray: [],
    };

  const {
    current_workflow_process: current_workflow_process_type,
    is_adjusting_limit,
    issuance_status: issuanceStatus,
    notes,
    process_exceed_limit: processExceedLimit,
    progress_summary,
    state,
    workflow_process: workflow_processRaw = {},
    user: cardOwner,
  } = cardDetail || {};

  const {
    approved_by_ops_on: approvedByOpsOnAdjustLimit,
    need_ops_review: isNeedOpsReview,
    rejected_by_ops_on: rejectedByOpsOnAdjustLimit,
    rejection_reason_ops: rejectionReasonOpsAdjustLimit,
    rejection_note: rejectionNoteAdjustLimit,
    timestamp: requestAdjustLimitTimestamp,
    value: isRequestAdjustingLimitValue,
  } = is_adjusting_limit || {};

  const currentWorkflowProcess = workflow_processRaw?.find(
    ({ type }) => type === current_workflow_process_type
  )?.data;

  if (
    (isEmpty(workflow_processRaw) || isEmpty(currentWorkflowProcess)) &&
    !isNeedOpsReview
  )
    return { progressArray: [] };

  const {
    request_active: isRequestActiveExceed,
    request_adjust_limit: isRequestAdjustLimitExceed,
  } = processExceedLimit || {};
  const { isOnReviewOps } = cardIssuanceStatusDecider(issuanceStatus);

  const isAdjustLimit =
    current_workflow_process_type === "request_adjust_limit";
  const isRequestActive = current_workflow_process_type === "request_active";
  const isRequestTopUpMainBalance =
    current_workflow_process_type === "request_withdraw_balance_company";
  const isRequestWithdrawMainBalance =
    current_workflow_process_type === "request_topup_balance_company";
  const isRequestMoveBalanceToCard =
    current_workflow_process_type === "request_move_balance";
  const isMoveBalanceWorkflow =
    isRequestTopUpMainBalance ||
    isRequestWithdrawMainBalance ||
    isRequestMoveBalanceToCard;

  const isRequestAdjustingLimit = isRequestAdjustingLimitValue || isAdjustLimit;

  const {
    approved_by_ops_on: approvedByOpsOnActivate,
    rejected_by_ops_on: rejectedByOpsOnActivate,
    rejection_note: rejectionNoteActivate,
  } = notes || {};

  const additionalWorkflowData = workflow_processRaw?.find(
    ({ type }) => type === current_workflow_process_type
  )?.additional_data;
  const { rejection_note: rejectionNoteMoveBalance } =
    additionalWorkflowData || {};

  const rejectionNoteDecider = () => {
    if (isRequestAdjustingLimit)
      return rejectionNoteAdjustLimit || rejectionReasonOpsAdjustLimit;
    if (isRequestActive) return rejectionNoteActivate;
    if (isMoveBalanceWorkflow) return rejectionNoteMoveBalance;
  };

  const rejectionNote = rejectionNoteDecider();
  const { isActive } = cardStateDecider(state, issuanceStatus);

  const { user } = useGetAuth();

  const youOrNameFunc = ({ name, currentUser }) => {
    const { name: userName, id } = currentUser || {};
    if (!name) return "";
    if (userName == name || id === name) return "You";
    return name;
  };

  let approverLineArray = [];
  let isActiveArray = [];
  let rejectChecker = false;

  const workflowProcessFormatter = () => {
    let obj = {};

    Object.keys(currentWorkflowProcess || {}).forEach((key) => {
      const array = currentWorkflowProcess[key] || [];

      const filterer = (roleName) =>
        array.filter(({ user_role_name }) => user_role_name === roleName);

      const includesAdmin = filterer("partner_admin");

      const includesSuperAdmin = filterer("partner_super_admin");

      const includesEmployee = filterer("partner_maker");

      const includesBookkeeper = filterer("partner_viewer");

      const includesManager = array.filter((user) => user.team_manager);

      const arrayDecider = () => {
        if (!includesAdmin.length) return array;
        if (includesSuperAdmin.length)
          return [
            ...includesManager,
            ...includesSuperAdmin,
            ...includesAdmin,
            ...includesBookkeeper,
            ...includesEmployee,
          ];
        return [
          ...includesManager,
          ...includesAdmin,
          ...includesBookkeeper,
          ...includesEmployee,
        ];
      };

      const cleanArray = arrayDecider();
      const filteredApproved = array.filter(({ approved_at }) => approved_at);
      const newArray = filteredApproved.length ? filteredApproved : cleanArray;
      obj[key] = newArray;
    });

    return { obj, currentWorkflowProcess };
  };

  const { obj: workflow_process } = workflowProcessFormatter();

  Object.keys(workflow_process).forEach((key, indexParent) => {
    const array = workflow_process[key];
    const result = array
      .map((item, index) => {
        if (rejectChecker) return null;

        const {
          user_role_name,
          user_name,
          user_email,
          approved_at,
          approved_by_user_id,
          approved_by_user_name,
          rejected_at,
          rejected_by_user_id,
          rejected_by_user_name,
          team_manager,
        } = item;

        const hasApproved = Boolean(approved_at);
        const isFirst = index === 0;

        const date = hasApproved
          ? customDateFormatter(approved_at)
          : rejected_at
          ? customDateFormatter(rejected_at)
          : "";

        const nameDecider = () => {
          const isAdmin = user_role_name == "partner_admin";
          const isSuperAdmin = user_role_name === "partner_super_admin";
          const isBookkeeper = user_role_name === "partner_viewer";
          const isEmployee = user_role_name === "partner_maker";

          const haveUserName = user_name === "" || user_name;
          const userName = user_name === "" ? user_email : user_name;

          const isTeamManager = team_manager && haveUserName && cardOwner?.id;
          const { id } = user;

          const teamManagerTextSimplifiedDecider = (text = "") =>
            teamManagerTextDecider({ isTeamManager, text });

          if (approved_at) {
            if (id === approved_by_user_id) {
              return teamManagerTextSimplifiedDecider("You");
            }

            return teamManagerTextSimplifiedDecider(approved_by_user_name);
          }

          if (rejected_at) {
            if (id === rejected_by_user_id) {
              return teamManagerTextSimplifiedDecider("You");
            }

            return titleCase(rejected_by_user_name);
          }

          if (isAdmin) return "Any Admin";
          if (isBookkeeper) return "Any Bookkeeper";
          if (isSuperAdmin) return "Business Owner";
          if (isEmployee) return "Any Employee";

          return teamManagerTextSimplifiedDecider(
            youOrNameFunc({
              name: userName,
              currentUser: user,
            })
          );
        };

        const name = nameDecider();

        const textDecider = () => {
          if (hasApproved) return `Approved by ${name}`;
          if (rejected_at) return `Rejected by ${name}`;
          if (isFirst) return `Waiting approval from ${name}`;
          return `/${name}`;
        };

        const text = textDecider();

        return {
          ...item,
          date,
          text,
          isOrange: !hasApproved,
          isGreen: hasApproved,
          isRed: Boolean(rejected_at),
        };
      })
      .filter((array) => {
        return array;
      });

    const obj = { data: result };

    approverLineArray.push(obj);
    isActiveArray.push(Boolean(result.filter(({ date }) => date).length));
  });

  approverLineArray = approverLineArray
    .map((item, index) => {
      const isActive = isActiveArray[index - 1] || index == 0;
      return { ...item, isActive };
    })
    .filter(({ data }) => data.length > 0);

  const isWorkflowDoneChecker = (approverLineArray) => {
    const lastItem = approverLineArray[approverLineArray.length - 1];
    if (!lastItem) return true;
    const lastItemData = lastItem.data[(lastItem.data || []).length - 1] || {};
    const lastDataWithDate = lastItemData.date;
    return !!lastDataWithDate;
  };

  const isWorkflowDone = isWorkflowDoneChecker(approverLineArray);
  const progressArrayDecider = () => {
    const { amount, requester_name: requesterName } =
      additionalWorkflowData || {};

    if (isRequestAdjustingLimit) {
      const limitRequesterArray = [
        {
          text: `${youOrNameFunc({
            name: requesterName,
            currentUser: user,
          })} requested to adjust the card limit`,
          isActive: true,
          date: customDateFormatter(requestAdjustLimitTimestamp),
        },
      ];

      const finalProgressArrayDecider = () => {
        const progressArray = [...limitRequesterArray, ...approverLineArray];

        if (rejectionNoteAdjustLimit) return progressArray;

        const filterData = progressArray.filter(({ data }) => data);
        const { approved_at } = filterData[filterData.length - 1] || {};

        const date =
          approvedByOpsOnAdjustLimit ||
          rejectedByOpsOnAdjustLimit ||
          approved_at;

        if (isRequestAdjustLimitExceed && !rejectedByOpsOnAdjustLimit) {
          const isActiveReviewByOurTeam = isWorkflowDone;
          const isReviewDone =
            approvedByOpsOnAdjustLimit || rejectedByOpsOnAdjustLimit;

          progressArray.push({
            text: isReviewDone
              ? "Request has been reviewed by our team"
              : "Request is under review by our team",
            isCustomText: true,
            isActive: isActiveReviewByOurTeam,
            date: isActive && customDateFormatter(date),
          });
        }

        if (rejectedByOpsOnAdjustLimit) {
          progressArray.push({
            text: "Limit adjustment rejected",
            date: customDateFormatter(rejectedByOpsOnAdjustLimit),
            isActive: true,
            isRejected: true,
          });
        }

        if (!rejectedByOpsOnAdjustLimit) {
          const isActive = isRequestAdjustLimitExceed
            ? approvedByOpsOnAdjustLimit || rejectedByOpsOnAdjustLimit
            : isWorkflowDone;

          progressArray.push({
            text: "Card limit adjusted",
            isCustomText: true,
            isActive,
            date: isActive && customDateFormatter(date),
          });
        }

        return progressArray;
      };

      const progressArray = finalProgressArrayDecider();
      return progressArray;
    }
    if (isMoveBalanceWorkflow) {
      const { target_card: targetCard, requested_at: requestedAt } =
        additionalWorkflowData || {};
      const { name: targetName } = targetCard || {};

      const requesterObjDecider = () => {
        const textStyle = {
          fontSize: "12px",
          lineHeight: "16px",
          color: colors.neutral900,
        };
        const obj = {
          isActive: true,
          date: customDateFormatter(requestedAt),
          isCustomText: true,
        };
        const requesterNameText = youOrNameFunc({
          name: requesterName,
          currentUser: user,
        });

        if (isRequestTopUpMainBalance) {
          obj.text = (
            <GothamRegular style={textStyle}>
              <TextInlineMedium>{requesterNameText}</TextInlineMedium> requested
              a card top up{" "}
              <TextInlineMedium>
                IDR {formatCurrencyNoDecimal(amount)}
              </TextInlineMedium>
            </GothamRegular>
          );
        }

        if (isRequestWithdrawMainBalance) {
          obj.text = (
            <GothamRegular style={textStyle}>
              <TextInlineMedium>{requesterNameText}</TextInlineMedium> requested
              to return{" "}
              <TextInlineMedium>
                IDR {formatCurrencyNoDecimal(amount)}
              </TextInlineMedium>{" "}
              to company
            </GothamRegular>
          );
        }

        if (isRequestMoveBalanceToCard) {
          obj.text = (
            <GothamRegular style={textStyle}>
              <TextInlineMedium>{requesterNameText}</TextInlineMedium> requested
              to move{" "}
              <TextInlineMedium>
                IDR {formatCurrencyNoDecimal(amount)}
              </TextInlineMedium>{" "}
              to <TextInlineMedium>{targetName}</TextInlineMedium>
            </GothamRegular>
          );
        }
        return obj;
      };

      const requesterObj = requesterObjDecider();

      const finalProgressArrayDecider = () => {
        const progressArray = [requesterObj, ...approverLineArray];

        if (rejectionNote) return progressArray;

        const filterData = progressArray.filter(({ data }) => data);
        const lastData = filterData[filterData.length - 1]?.data || {};
        const lastApprover = lastData[lastData.length - 1] || {};
        const { approved_at: lastApprovedAt, rejected_at: rejectedAt } =
          lastApprover || {};

        const isRejected = Boolean(rejectedAt);

        if (!isRejected) {
          const obj = {
            isCustomText: true,
            isActive: isWorkflowDone,
            date: customDateFormatter(lastApprovedAt),
          };

          if (isRequestTopUpMainBalance) obj.text = "Card top up completed!";
          if (isRequestWithdrawMainBalance)
            obj.text = "Card balance return completed!";
          if (isRequestMoveBalanceToCard)
            obj.text = "Card balance move completed!";
          progressArray.push(obj);
        }

        return progressArray;
      };

      const progressArray = finalProgressArrayDecider();
      return progressArray;
    }

    const cardRequesterArray = [
      {
        text: `${youOrNameFunc({
          name: progress_summary[0]?.user_name,
          currentUser: user,
        })} requested to issue a card`,
        isActive: true,
        date: customDateFormatter(progress_summary[0].timestamp),
      },
    ];

    const isActiveCompletedStatus =
      (isRequestActiveExceed && approvedByOpsOnActivate) ||
      (isWorkflowDone && !isRequestActiveExceed);

    const completedVirtualArray = [
      isRequestActiveExceed && {
        text: "Request is under review by our team",
        date: customDateFormatter(approvedByOpsOnActivate),
        isCustomText: true,
        isActive: isOnReviewOps || isWorkflowDone,
      },
      {
        text: progress_summary[progress_summary.length - 2].text,
        isActive: isActiveCompletedStatus,
        date: progress_summary[progress_summary.length - 2].timestamp,
      },
      {
        text: progress_summary[progress_summary.length - 1].text,
        isActive: isActiveCompletedStatus,
        date: progress_summary[progress_summary.length - 1].timestamp,
        isSuccess: isActiveCompletedStatus,
      },
    ];

    const finalProgressArrayDecider = () => {
      const progressArray = [...cardRequesterArray, ...approverLineArray];

      if (rejectionNoteActivate) return progressArray;

      if (rejectedByOpsOnActivate) {
        progressArray.push({
          text: "Card request rejected by our team",
          date: customDateFormatter(rejectedByOpsOnActivate),
          isActive: true,
          isRejected: true,
        });
      }

      if (isActive) progressArray.push(...completedVirtualArray);

      return progressArray;
    };

    const progressArray = finalProgressArrayDecider();

    return progressArray;
  };

  const progressArray = progressArrayDecider();

  return {
    progressArray,
    rejectionNote,
    currentWorkflowProcess,
    isWorkflowDone,
  };
};

export default useCardWorkflow;
