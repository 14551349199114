import { eventsTracker } from "../../../../universalFunctions/events";
import {
  TOOLTIP_DEFAULT_PROPERTIES,
  WORKFLOW_FIRST_TARGET,
  WORKFLOW_SECOND_TARGET,
  WORKFLOW_THIRD_TARGET,
} from "../../constants";
import { DEMO_TOOLTIP_CLOSE, DEMO_TOOLTIP_NEXT } from "../../constants/events";

const steps = [
  {
    ...TOOLTIP_DEFAULT_PROPERTIES,
    target: `#${WORKFLOW_FIRST_TARGET}`,
    title: "Customize workflow for each transaction type",
    content:
      "You can adjust each approval workflow to match your company's specific rules.",
    placement: "right",
    eventPayload: {
      page_title: "workflow",
      tooltip_activity: "workflow_type",
    },
  },
  {
    ...TOOLTIP_DEFAULT_PROPERTIES,
    target: `#${WORKFLOW_SECOND_TARGET}`,
    title: "Edit Drafter",
    content:
      "Click this to adjust the Drafters that can request Local and International Transfer.",
    placement: "top",
    eventPayload: {
      page_title: "workflow",
      tooltip_activity: "edit_drafter",
    },
  },
  {
    ...TOOLTIP_DEFAULT_PROPERTIES,
    target: `#${WORKFLOW_THIRD_TARGET}`,
    title: "Add approval step for extra security",
    content:
      "You can set up cases and choose who have the authority to approve certain amounts in transactions.",
    placement: "top",
    eventPayload: {
      page_title: "workflow",
      tooltip_activity: "add_case",
    },
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle, step } = data ?? {};
  const { eventPayload } = step ?? {};

  const stepCount = steps.length - 1;

  const isComplete = lifecycle === "complete";

  if (action === "skip") {
    setGuidedTourState(defaultState);
    eventsTracker(DEMO_TOOLTIP_CLOSE, eventPayload);
  }

  if (!isComplete) return;

  switch (action) {
    case "close":
    case "next":
      eventsTracker(DEMO_TOOLTIP_NEXT, eventPayload);
      if (index < stepCount) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex + 1,
        }));
        break;
      }
      if (index === stepCount) {
        setGuidedTourState(defaultState);
        break;
      }
      break;

    case "prev":
      if (index > 0) {
        setGuidedTourState((prev) => ({
          ...prev,
          stepIndex: prev.stepIndex - 1,
        }));
        break;
      }
      break;

    default:
      break;
  }
};

const workflowProps = { steps, callback };

export default workflowProps;
