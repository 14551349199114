import { useRouter } from "next/router";
import { Fragment, useContext, useEffect, useState } from "react";
import { CgSpinnerAlt } from "react-icons/cg";
import { Modal } from "reactstrap";
import { jackColors } from "../../../../assets/colors";
import { JackIcons } from "../../../../assets/jackIcons/parent";
import { Banner } from "../../../../components/Banner";
import { ProgressBarJack } from "../../../../components/Stepper";
import {
  GothamBold,
  GothamMedium,
  GothamRegular,
} from "../../../../components/Text";
import {
  AuthContext,
  getUserRoleBooleans,
  useGetAuth,
} from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import BlockingBlurModal from "../../../../modals/BlockingBlurModal";
import { fetch, useMutation } from "../../../../tools/api";
import { eventsTracker } from "../../../../universalFunctions/events";
import { CloseButton } from "../../../invoiceComponents/createPage/uploadPage";
import { font12, font24 } from "../../../virtualAccountComponents/common/utils";
import { DEMO_PAGE_VIEW, DEMO_START_GUIDANCE } from "../../constants/events";
import { getUserRoleDemo } from "../../constants/role";
import LoadingSpinnerDemo from "../LoadingSpinnerDemo";
import { TodoItem } from "../SwitchRoleModal";
import styles from "./styles.module.css";

const SwitchAccountModal = ({ isOpen, toggle, currentUser }) => {
  if (!isOpen) return null;

  const [loading, setLoading] = useState(false);
  const { users, loadingUsers } = useConstants();
  const { user, userLoading } = useGetAuth();
  const { id: currentUserId, partner: { is_initialized_demo } = {} } =
    user ?? {};

  users.sort((a, b) => {
    if (a?.id === currentUserId) return -1;
    if (b?.id !== currentUserId) return 1;
    return 0;
  });

  const { authorize } = useContext(AuthContext);
  const { push } = useRouter();

  const { mutation, loading: loadingSwitchAccount } = useMutation({
    url: "/switch_account",
    method: "post",
    afterSuccess: (res, payload) => {
      const { auth_token } = res?.data ?? {};
      authorize({
        token: auth_token,
        handleRedirect: () => {
          push("/dashboard");
          setTimeout(() => {
            // setLoading(false);
            setTimeout(() => {
              toggle();
            }, 300);
          }, 1200);
        },
      });
      localStorage.removeItem("hide-switch");
    },
  });

  const onSwitchRole = (id) => {
    // setLoading(true);
    localStorage.setItem("switch", id);
    mutation({ user_id: id });
  };

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, { page_title: "switch_role" });
  }, []);

  if (loadingUsers || userLoading) return null;

  // if (loading) return <LoadingSpinnerDemo />
  {
    /* {loading && <FullScreenLoading />} */
  }

  return (
    <Fragment>
      <Modal
        unmountOnClose
        toggle={toggle}
        isOpen={isOpen}
        className={styles["container"]}
        backdropClassName={"transparent-backdrop"}
      >
        <div className={styles["outer"]}>
          <div className={styles["header"]}>
            <GothamMedium className={styles["title"]}>
              Switch User Role
            </GothamMedium>

            <JackIcons
              name="CloseOutline"
              fill={jackColors.black34}
              style={{ height: "16px", cursor: "pointer" }}
              onClick={toggle}
            />
          </div>

          <Banner
            type="info-alternate"
            msg={
              "Try out different experience with these roles! Select one to switch between roles effortlessly."
            }
            bannerStyle={{
              borderRadius: "8px",
              backgroundColor: jackColors.greyF1,
              border: `1px solid ${jackColors.greyE6}`,
            }}
            textIconStyle={{ alignItems: "start" }}
          />

          {users.map((user, i) => {
            const { id = null } = user ?? {};
            const { roleName } = getUserRoleBooleans(user);
            const { description } = getUserRoleDemo(roleName);
            const isCurrentUser = currentUserId === id;
            const iconName = isCurrentUser
              ? "CheckmarkCircle2"
              : "ArrowForward";
            const iconFill = isCurrentUser
              ? jackColors.green06
              : jackColors.black34;
            const backgroundColor = isCurrentUser
              ? "rgba(155, 222, 184, 0.15)"
              : "white";

            const handleSwitchRole = () => {
              if (isCurrentUser) return;
              const { eventName } = getUserRoleDemo(roleName);
              eventsTracker(eventName, { page_title: "switch_role" });
              onSwitchRole(id);
            };
            return (
              <div
                key={i}
                className={styles["role-card"]}
                onClick={handleSwitchRole}
                style={{
                  cursor: !isCurrentUser ? "pointer" : "not-allowed",
                  background: backgroundColor,
                }}
              >
                <div className={styles["card-left-content"]}>
                  <div className={styles["card-title-container"]}>
                    <GothamRegular
                      style={{ color: "white", ...font12 }}
                      className={styles["card-title"]}
                    >
                      {roleName}
                    </GothamRegular>
                  </div>
                  <GothamRegular style={{ ...font12 }}>
                    {description}
                  </GothamRegular>
                </div>
                <div className={styles["icon-container"]}>
                  <JackIcons
                    name={iconName}
                    fill={iconFill}
                    style={{ height: "16px", width: "16px" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Modal>
      <LoadingSpinnerDemo show={loadingSwitchAccount} />
    </Fragment>
  );
};

export const SetupRolesModal = ({ isOpen, toggle = () => {} }) => {
  if (!isOpen) return null;
  const {
    data,
    loading,
    refetch,
  } = fetch({
    url: "/business_partners/demo_progress",
  });

  const { current_task, status } = data ?? {};

  const steps = [
    {
      step: "Creating users",
      id: "users",
    },
    {
      step: "Creating account details",
      id: "account_numbers",
    },
    {
      step: "Creating other details",
      id: "transactions",
    },
  ].map((item) => {
    return {
      ...item,
      isActive: item?.id === current_task,
      isCompleted: item?.id === "transactions" && status === "complete",
    };
  });
  const isFinished = status === "complete";

  useEffect(() => {
    refetch();

    const fetchDataInterval = setInterval(() => {
      if (!isFinished) {
        refetch();
      }
    }, 5000);

    return () => clearInterval(fetchDataInterval);
  }, [isFinished]);

  const { push } = useRouter();
  const todos = [
    {
      id: "create_transactions",
      label: "Create and request transactions",
      icon: "FileAddOutline",
      onClick: () => {
        // ke dashboard, buka button create
        eventsTracker(DEMO_START_GUIDANCE, {
          page_title: "demo_initialization",
          activity_type: "create_trx",
        });
        toggle();
        push({ query: { openCreate: true } });
      },
    },
    {
      id: "approve",
      label: "Manage task",
      icon: "FactCheck",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, {
          page_title: "demo_initialization",
          activity_type: "approve_task",
        });
        push("/task");
      },
    },
    {
      id: "workflow",
      label: "Manage approval workflow",
      icon: "Workflow",
      onClick: () => {
        eventsTracker(DEMO_START_GUIDANCE, {
          page_title: "demo_initialization",
          activity_type: "workflow",
        });
        push("/workflow");
      },
    },
  ];

  useEffect(() => {
    if (!isOpen) return;
    eventsTracker(DEMO_PAGE_VIEW, { page_title: "demo_initialization" });
  }, [isOpen]);

  const content = () => {
    // if (isFinished && loading) {
    //   return (
    //     <Fragment>
    //       <div className={styles["setup-modal-body"]}>
    //         <div className={styles["loading-container"]}>
    //           <CgSpinnerAlt
    //             className="icon-spin"
    //             size={48}
    //             style={{ animationDuration: "0.8s" }}
    //           />
    //         </div>
    //         <JackIcons name="JackSpade" />
    //         <GothamBold style={{ ...font24 }}>
    //           We’re setting up another roles for you…
    //         </GothamBold>
    //         <ProgressBarJack
    //           arrayStep={steps}
    //           customLineLength={250}
    //           style={{ marginBottom: "32px" }}
    //         />
    //         <GothamRegular>
    //           In the mean time, let’s check these guides:
    //         </GothamRegular>
    //       </div>
    //     </Fragment>
    //   );
    // }
    if (isFinished) {
      return (
        <Fragment>
          <div className={styles["setup-modal-body"]}>
            <JackIcons name="JackSpade" />
            <GothamBold style={{ ...font24 }}>
              We’ve done setting up another roles!
            </GothamBold>
            <GothamRegular>
              Close this pop-up and switch to your preferred role.
            </GothamRegular>
          </div>
        </Fragment>
      );
    }
    return (
      <Fragment>
        <div className={styles["setup-modal-body"]}>
          <JackIcons name="JackSpade" />
          <GothamBold style={{ ...font24 }}>
            We’re setting up another roles for you…
          </GothamBold>
          <ProgressBarJack
            arrayStep={steps}
            customLineLength={250}
            style={{ marginBottom: "32px" }}
          />
          <div className={styles["other-guides-container"]}>
            <GothamRegular>
              In the mean time, let’s check these guides:
            </GothamRegular>
            <div className={styles["other-guides"]}>
              {todos?.map((todo, i) => {
                return <TodoItem item={todo} />;
              })}
            </div>
          </div>
        </div>
      </Fragment>
    );
  };

  return (
    <BlockingBlurModal isOpen={isOpen}>
      <div className={styles["setup-outer"]}>
        <div className={styles["setup-modal"]}>
          <div className={styles["close-btn"]}>
            <CloseButton onClick={toggle} />
          </div>
          {content()}
        </div>
      </div>
    </BlockingBlurModal>
  );
};

export default SwitchAccountModal;
