import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useModalHook } from "../../../components/Modals";
import { fetch, useMutation } from "../../../tools/api";
import { useCleanQueries } from "../../../universalFunctions/cleanQueries";
import { eventsTracker } from "../../../universalFunctions/events";
import { LocalTransferFormLoop } from "../../localTransferComponents/localTransfer/parent";
import { LocalTransferFirstForm } from "./first";
import LocalTransferSecondForm from "./second";
import { LoadingScreenImport } from "./components";
import {
  CheckerValidation,
  CreateFromBgFunc,
  FormatterCreateFromBackground,
} from "./logic";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import checkExcelImg from "../../../assets/images/check-excel.svg";
import { ModalFailCreateBG, ModalFailExcel } from "./modal";

export const stepArrayLocalFunc = (index, isResubmit) =>
  (isResubmit
    ? [
        { step: "Transfer Details" },
        { step: "Bank Validation" },
        { step: "Confirm to Submit" },
      ]
    : [
        { step: "Batch Name" },
        { step: "Input Recipient" },
        { step: "Transfer Details" },
        { step: "Bank Validation" },
        { step: "Confirm to Submit" },
      ]
  ).map((item, indexChild) => ({ ...item, isActive: index == indexChild }));

export const LocalTransferCreatePage = () => {
  const [idBgProcess, setIdBgProcess] = useState(false);
  const [firstValues, setFirstValues] = useState(false);
  const [isNotFromExcel, setIsNotFromExcel] = useState(false);
  const [dataForTable, setDataForTable] = useState([]);
  const { query, push } = useRouter();
  const { isFirstRender } = useCleanQueries();

  const { isOpen: isOpenErrorExcel, toggle: toggleErrorExcel } = useModalHook();
  const { isOpen: isOpenErrorCreate, toggle: toggleErrorCreate } =
    useModalHook();

  const { state } = query;
  const isCreate = !state || state == "create";
  const LoadingProcessBackground = !!idBgProcess;
  const isMinimalTrxTreshold = 400;

  // START CREATE TRX FROM EXCEL & BACKGROUND PROCESS > 400 TRX //
  const { createFromBackground, isError: isErrorCreateFromBg } =
    CreateFromBgFunc({
      idBgProcess,
      setIdBgProcess,
    });

  const {
    mutation,
    result: defaultValues,
    loading: loadingNext,
  } = useMutation({
    url: "local_transaction_batches/import",
    method: "post",
    defaultValue: [],
    resultFormatter: (data) => data?.data?.data,
    afterSuccess: (res) => {
      const isMoreThanLimit = res?.length >= isMinimalTrxTreshold;
      const mutationData = FormatterCreateFromBackground({
        name: firstValues?.batch_name,
        data: res,
      });
      const { isNotPass } = CheckerValidation({ data: res });
      if (isMoreThanLimit) {
        if (isNotPass) return toggleErrorExcel();
        return createFromBackground(mutationData);
      }
      push({ query: { state: "create" } });
    },
  });
  // END CREATE TRX FROM EXCEL & BACKGROUND PROCESS > 300 TRX //

  useEffect(() => {
    if (!state) {
      setIsNotFromExcel(false);
      setDataForTable([]);
    }
  }, [query]);

  useEffect(() => {
    if (isErrorCreateFromBg) {
      toggleErrorCreate();
    }
  }, [isErrorCreateFromBg]);

  const onSubmit = ({ values, type }) => {
    const isFirst = type == "first";
    const isSecond = type == "second";
    const isSecondModal = type == "second_modal";

    if (isFirst) {
      eventsTracker("create_local_transfer_initial", {
        activity_type: "create_local_transfer",
        page_title: "batch_name",
        ...values,
      });
      const { batch_name, category_id } = values || {};
      const formatValues = batch_name?.split("\n").join("");
      const newValue = { batch_name: formatValues, category_id };
      return setFirstValues(newValue);
    }

    if (isSecond) {
      const { upload_file } = values || {};
      let formData = new FormData();
      formData.append("file", upload_file);
      formData.append("import_type", "not_create");
      formData.append("type", "local_transfer");
      eventsTracker("create_local_transfer_upload");
      mutation(formData); // import file local transfer
      return;
    }

    if (isSecondModal) {
      setIsNotFromExcel(true);
      setDataForTable(values);
      eventsTracker("create_local_transfer_empty_table");
      push({ query: { state: "create" } });
      return;
    }
  };

  if (isFirstRender) return null;

  const data = isNotFromExcel ? dataForTable : defaultValues; //disini modify data CSV

  if (LoadingProcessBackground)
    return (
      <>
        <LoadingScreenImport />
        <ModalFailCreateBG
          isOpen={isOpenErrorCreate}
          toggle={toggleErrorCreate}
          backToFirst={() => setFirstValues(false)}
        />
      </>
    );

  if (state)
    return (
      <LocalTransferFormLoop
        firstValues={firstValues}
        defaultValues={data}
        isNotFromExcel={isNotFromExcel}
      />
    );

  if (firstValues) {
    return (
      <>
        <LocalTransferSecondForm
          onSubmit={onSubmit}
          toggle={() => setFirstValues(false)}
          loading={loadingNext}
        />
        <ModalFailExcel isOpen={isOpenErrorExcel} toggle={toggleErrorExcel} />
      </>
    );
  }

  return <LocalTransferFirstForm onSubmit={onSubmit} />;
};
