import { Fragment } from "react";
import {
  BILL_MODULE,
  INTERNATIONAL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  VA_MODULE,
} from "../../../constants";

const titleDecider = (module) => {
  switch (module) {
    case LOCAL_MODULE:
      return "Create local transfer";
    case INTERNATIONAL_MODULE:
      return "Create international transfer";
    case PAYROLL_MODULE:
      return "Create payroll";
    case BILL_MODULE:
      return "Create bill payment";
    case REIMBURSEMENT_MODULE:
      return "Request a reimbursement";
    case VA_MODULE:
      return "Create virtual account";
    default:
      return "";
  }
};

const textDecider = (module) => {
  switch (module) {
    case LOCAL_MODULE:
      return (
        <Fragment>
          Let's dive into Jack’s{" "}
          <span style={{ fontFamily: "GothamMedium" }}>Local Transfer</span> and
          learn how to create one.
        </Fragment>
      );
    case INTERNATIONAL_MODULE:
      return (
        <Fragment>
          We’ll guide you to effortlessly create{" "}
          <span style={{ fontFamily: "GothamMedium" }}>
            international transfer
          </span>{" "}
          to across +60 countries.
        </Fragment>
      );
    case PAYROLL_MODULE:
      return "Follow our simple steps to distribute and streamline your company’s payroll.";
    case BILL_MODULE:
      return (
        <Fragment>
          Let's dive into Jack’s{" "}
          <span style={{ fontFamily: "GothamMedium" }}>Bill Payment</span> and
          learn how to create one.
        </Fragment>
      );
    case REIMBURSEMENT_MODULE:
      return "Explore how easy it is to submit reimbursement, get approvals, and get paid fast.";
    case VA_MODULE:
      return (
        <Fragment>
          Find out how to create{" "}
          <span style={{ fontFamily: "GothamMedium" }}>virtual accounts</span>{" "}
          and share them to receive payments on behalf of your company.{" "}
        </Fragment>
      );
    default:
      return "";
  }
};

const imageSrcDecider = (module) => {
  switch (module) {
    case LOCAL_MODULE:
      return "/images/local-demo.png";
    case INTERNATIONAL_MODULE:
      return "/images/international-demo.png";
    case PAYROLL_MODULE:
      return "/images/payroll-demo.png";
    case BILL_MODULE:
      return "/images/bill-demo.png";
    case REIMBURSEMENT_MODULE:
      return "/images/reimbursement-demo.png";
    case VA_MODULE:
      return "/images/va-demo.png";
    default:
      return "";
  }
};

const onboardModalPopupContentDecider = (type, module) => {
  switch (type) {
    case "title":
      return titleDecider(module);
    case "text":
      return textDecider(module);
    case "image-src":
      return imageSrcDecider(module);
    default:
      return "";
  }
};

export default onboardModalPopupContentDecider;
