import { isSameDay } from "date-fns";
import { isEmpty } from "lodash";

export const generateTimeOptions = (from = 0, to = 24) => {
  const options = [];
  for (let hour = from; hour <= to; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    const label = `${formattedHour}:00`;
    options.push({ label, value: label });
  }
  return options;
};

export const generateTimeOptionsComplex = (date) => {
  if (!Boolean(date)) return [];
  const isToday = isSameDay(date, new Date());
  const defaultOptions = generateTimeOptions(6, 22);
  if (isToday) {
    const currentDate = new Date();
    const hour = currentDate?.getHours();

    if (hour <= 6) return defaultOptions;
    if (hour >= 22) return [];

    return generateTimeOptions(hour + 1, 22);
  }

  return defaultOptions;
};

export const timeRange = [
  { label: "Day", value: "day" },
  { label: "Week", value: "week" },
  { label: "Month", value: "month" },
  { label: "Year", value: "year" },
];

export const days = [
  { label: "Sun", value: 0 },
  { label: "Mon", value: 1 },
  { label: "Tue", value: 2 },
  { label: "Wed", value: 3 },
  { label: "Thu", value: 4 },
  { label: "Fri", value: 5 },
  { label: "Sat", value: 6 },
];

export const daysLabel = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const productsMenuOptions = [
  { label: "Payroll", value: "Payroll" },
  { label: "Local Transfer", value: "LocalTransfer" },
  { label: "Bill Payment", value: "InvoiceTransaction" },
  { label: "Reimbursement", value: "Reimbursement" },
];
