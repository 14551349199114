import { isEmpty } from "lodash";
import React, { useState } from "react";
import { SmartActivityModal } from "../../modals/smartActivityModal/parent";

const contextTemplate = {
  isPayment: false,
  showTrxModal: false,
  showTaskSummary: false,
  isUploadedDocuments: false,
  triggerResetTask: 0,
  selectedTask: {},
  selectedTasks: { paymentTasks: [], approvalTasks: [] },
  resetTasks: () => {},
  setSelectedTask: () => {},
  setSelectedTasks: () => {},
  setTriggerResetTask: () => {},
  setIsUploadedDocuments: () => {},
};

export const TaskPageContext = React.createContext(contextTemplate);

const TaskPageProvider = ({ children }) => {
  const defaultSelectedTasks = {
    paymentTasks: [],
    approvalTasks: [],
    delayedTasks: [],
  };

  const [isUploadedDocuments, setIsUploadedDocuments] = useState(false);
  const [triggerResetTask, setTriggerResetTask] = useState(0);
  const [selectedTask, setSelectedTask] = useState({});
  const [selectedTasks, setSelectedTasks] = useState({
    ...defaultSelectedTasks,
  });

  const showTaskSummary =
    selectedTasks.approvalTasks.length !== 0 ||
    selectedTasks.paymentTasks.length !== 0;

  const isPayment = selectedTasks.paymentTasks.length > 0;

  const showTrxModal = !isEmpty(selectedTask);

  const resetTasks = () => {
    setSelectedTasks({ ...defaultSelectedTasks });
    setTriggerResetTask((prev) => prev + 1);
  };

  const value = {
    isPayment,
    showTrxModal,
    selectedTask,
    selectedTasks,
    showTaskSummary,
    triggerResetTask,
    resetTasks,
    setSelectedTask,
    setSelectedTasks,
    setTriggerResetTask,
    isUploadedDocuments,
    setIsUploadedDocuments,
  };

  return (
    <TaskPageContext.Provider value={value}>
      {children}
      <SmartActivityModal
        data={selectedTask}
        isOpen={showTrxModal}
        toggle={() => setSelectedTask({})}
        onUploadDocument={() => setIsUploadedDocuments(true)}
        afterSuccess={resetTasks}
        isTask
      />
    </TaskPageContext.Provider>
  );
};

export default TaskPageProvider;
