import { isEmpty, times } from "lodash";
import { Fragment, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { EmptyStateNotif, NotifFilter, NotifList } from "../components";
import styles from "../notif.module.css";
import useNotifications from "../useNotifications";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { eventsTracker } from "../../../universalFunctions/events";

const NotifTabScreen = ({
  handleOnPress = () => {},
  tab = "activity",
  canRefetch = false,
}) => {
  const isActivity = tab === "activity";
  const { tourRunning, tourRunningDummyData } = useConstants();

  const {
    data: dataRaw,
    loading,
    markAsReadBulk,
    markAsRead,
    nextPage,
    refetch,
    unread,
  } = useNotifications(tab);

  const { array: data = [] } = dataRaw || {};

  useEffect(() => {
    if (!canRefetch) return;
    nextPage();
  }, [canRefetch]);

  useEffect(() => {
    refetch();
    eventsTracker("page_view", {
      page_title: `notif_tab_${isActivity ? 1 : 2}`,
    });
  }, []);

  const handleMarkAsRead = (id, subgroup = "") => {
    markAsRead(id);
    eventsTracker("click_mark_as_read", {
      page_title: `notif_tab_${isActivity ? 1 : 2}`,
      subgroup,
    });
  };

  const dataDecider = () => {
    if (tourRunning) {
      return isActivity ? [] : tourRunningDummyData;
    }
    return data;
  };

  const finalData = dataDecider();

  const disableFilter = (tourRunning && finalData?.filter((item) => !item.is_read).length === 0) ||
    (!tourRunning && !unread && !loading);

  // const handleMarkAsReadBulk = () => {
  //   const marBulkTour = () => {
  //     setTourRunningDummyData((prev) => {
  //       const prevObj = prev[0]
  //       return [{...prevObj, is_read: true}]
  //     })
  //   }
  //   tourRunning ? marBulkTour() : markAsReadBulk()
  // }

  const handleMarkAsReadBulk = () => {
    eventsTracker("click_mark_all__as_read", {
      page_title: `notif_tab_${isActivity ? 1 : 2}`,
    });
    markAsReadBulk();
  };

  if (!loading && isEmpty(finalData)) {
    return <EmptyStateNotif />;
  }

  return (
    <div style={{ position: "relative" }}>
      <Fragment>
        <NotifFilter
          handleMarkAllAsRead={handleMarkAsReadBulk}
          disableUnread={disableFilter}
          tab={tab}
        />
        {loading ? (
          times(15, (index) => {
            return <NotifSkeleton key={index} />;
          })
        ) : (
          <NotifList
            data={finalData}
            handleOnPress={handleOnPress}
            handleMarkAsRead={handleMarkAsRead}
          />
        )}
      </Fragment>
    </div>
  );
};

export default NotifTabScreen;

const NotifSkeleton = () => {
  return (
    <div className={`${styles["notif-item-container"]}`}>
      <div className={styles["notif-item"]}>
        <div className="d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center" style={{ gap: 8 }}>
            <Skeleton circle height={20} width={20} />
            <Skeleton width={90} height={12} />
            <Skeleton width={150} height={12} />
          </div>
        </div>
        <div className="d-flex flex-column" style={{ gap: 1 }}>
          <Skeleton width={180} height={14} />
          <Skeleton width={300} height={14} />
          <Skeleton width={300} height={14} />
        </div>
      </div>
    </div>
  );
};
