export const demoPayrollName = {
  payroll_name: "Payroll February 2024",
  month: { label: "February", value: 2 },
  year: { label: 2024, value: 2024 },
};

export const demoCategory = {
  id: 1203,
  name: "Office Supplies",
  partner_id: 285,
  account_code: "00003",
  color: "#DE738B",
  icon: null,
  created_at: "2023-08-09T11:38:29.000+07:00",
  updated_at: "2023-08-09T11:38:29.000+07:00",
  is_archived: false,
  description: "Paper & writing tools, technologies, utilities, food, etc.",
  total_expense: {
    current_expense: 0,
    prev_expense: 0,
  },
  log: null,
  teams: [],
};

export const prefilledDataPayroll = [
  {
    name: "delete",
  },
  {
    name: "number",
  },
  {
    name: "name",
    value: "Demo Business Owner",
    label: "Name",
  },
  {
    name: "role",
    value: "",
    label: "Role",
  },
  {
    name: "department",
    value: "",
    label: "Department",
  },
  {
    name: "bank",
    value: "danamon",
    label: "Bank",
    id: 7,
    details: {
      id: 7,
      name: "danamon",
      full_name: "PT. BANK DANAMON INDONESIA TBK.",
      alias: "Bank Danamon",
      value: "danamon",
      bank_name: "PT. BANK DANAMON INDONESIA TBK.",
      label: "BANK DANAMON",
    },
  },
  {
    name: "account_number",
    value: "111122223333",
    label: "Bank Account Number",
  },
  {
    name: "notes",
    value: " ",
    label: "Notes",
  },
  {
    name: "email",
    value: "Businessowner[xxxyyyyy]@demo.com",
    label: "Email",
  },
  {
    name: "net_salary",
    value: "100,000,000",
    label: "Net Salary",
  },
  {
    name: "action",
  },
];

export const emptyData = [
  {
    name: "delete",
  },
  {
    name: "number",
  },
  {
    name: "name",
    value: "",
    label: "Name",
  },
  {
    name: "role",
    value: "",
    label: "Role",
  },
  {
    name: "department",
    value: "",
    label: "Department",
  },
  {
    name: "bank",
    value: "",
    label: "Bank",
  },
  {
    name: "account_number",
    value: "",
    label: "Bank Account Number",
  },
  {
    name: "notes",
    value: "",
    label: "Notes",
  },
  {
    name: "email",
    value: "",
    label: "Email",
  },
  {
    name: "net_salary",
    value: "",
    label: "Net Salary",
  },
  {
    name: "action",
  },
];
