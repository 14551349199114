import styles from "./CategoryArticle.module.css";

const CategoryArticle = () => {
  return (
    <a
      className={styles.categoryArticle}
      href="https://support.itsjack.com/en/support/solutions/articles/150000142739-all-about-categories-feature"
      target="_blank"
    >
      this article.
    </a>
  );
};

export default CategoryArticle;
