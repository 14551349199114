import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useModalHook } from "../../../../../components/Modals";
import { removeDuplicates } from "../../../../../components/tools";
import { apiBusiness, useMutation } from "../../../../../tools/api";
import { eventsTracker } from "../../../../../universalFunctions/events";
import { getDefaultValueIdsObj } from "../../../../localTransferComponents/localTransfer/formatter";
import {
  mismatchOrInvalidPayroll,
  payrollValidationFormatter,
  validationOnChange,
  validationOnChangeMismatch,
  validationPayrollSubmitFormatter,
  validationPayrollSubmitFormatterSkip,
} from "../formatter";

export const useChangeStatePayroll = (batch_id, afterSuccess = () => {}) => {
  const { query } = useRouter();
  const { action, ancestor_id } = query;
  const isResubmit = action === "resubmit";

  const { mutation: changeState, loading } = useMutation({
    url: `/local_transaction_batches/${batch_id}/change_state`,
    afterSuccess,
  });

  const { mutation: assignAncestorId, loading: loadingAssignAncestorId } =
    useMutation({ url: `/local_transaction_batches/${batch_id}` });

  const handleChangeState = async (values) => {
    try {
      if (isResubmit && ancestor_id) {
        await assignAncestorId({ ancestor_id: Number(ancestor_id) });
      }

      await changeState(values);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return {
    changeState: handleChangeState,
    loading: loading || loadingAssignAncestorId,
  };
};

export const useDefaultValidation = ({
  batch_id,
  defaultValues,
  onSubmit = () => {},
  isLocal,
}) => {
  const { query, push } = useRouter();

  const { id } = query;

  // INVALID OR MISMATCH COUNTER
  const newDefaultValues = payrollValidationFormatter(defaultValues, [
    { name: "number" },
    { name: "action" },
  ]);

  const { invalidArr, mismatchArr } =
    mismatchOrInvalidPayroll(newDefaultValues);

  const [multiData, setMultiData] = useState({
    invalidDefaultValues: invalidArr,
    mismatchDefaultValues: mismatchArr,
  });

  const { mismatchDefaultValues, invalidDefaultValues } = multiData;

  const invalid = invalidDefaultValues.length;
  const mismatch = mismatchDefaultValues.length;

  useEffect(() => {
    if (mismatch || invalid) return;
    onSubmit([]);
  }, [invalid, mismatch]);

  const numbers = { invalid, mismatch };

  // INVALID OR MISMATCH COUNTER

  // INVALID OR MISMATCH PAGE TYPE

  const [invalidOrMismatch, setInvalidOrMismatch] = useState("");

  const isInvalid = invalidOrMismatch == "invalid";
  const isMismatch = invalidOrMismatch == "mismatch";

  // INVALID OR MISMATCH PAGE TYPE

  // MUTATORS
  const { changeState } = useChangeStatePayroll(batch_id, () =>
    push({ query: { ...query, state: "create" } })
  );

  const { isOpen: isOpenInvalid, toggle: toggleInvalid } = useModalHook();
  const { isOpen: isOpenMismatch, toggle: toggleMismatch } = useModalHook();

  const afterSuccessSkip = () => {
    if (isOpenInvalid || isOpenMismatch) return;

    if (isLocal) {
      eventsTracker("continue_anyway_data_validation_local_transfer", { id });
      return push({
        pathname: `/local-transfer/${id}`,
        query: {
          state: "validating",
        },
      });
    }

    push({
      pathname: `/payroll/create/${id}`,
      query: { state: "validating" },
    });
    eventsTracker("payroll_continue_anyway", { id });
  };

  const { loading: loadingSkip, mutation: mutationSkip } = useMutation({
    url: `/local_transaction_batches/${batch_id}/update_transaction`,
    afterSuccess: afterSuccessSkip,
  });

  const handleSkip = async () => {
    const defaultValueIdsObj = getDefaultValueIdsObj(defaultValues);
    const ids = Object.values(defaultValueIdsObj);
    const transactions = removeDuplicates(ids).map((id) => ({
      local_transaction_id: id,
    }));
    const result = {
      invalid_action: "continue_anyway",
      transactions,
    };
    await mutationSkip(result);
  };

  const handleSkipDetail = async (arr) => {
    const transactions = arr.map((array) => ({
      local_transaction_id: array.filter(({ name }) => name == "id")[0]?.value,
    }));

    const ids = transactions.map(
      ({ local_transaction_id }) => local_transaction_id
    );

    const result = {
      invalid_action: "continue_anyway",
      transactions,
    };
    await mutationSkip(result);

    const obj = isOpenInvalid
      ? { values: mismatchDefaultValues, array: invalidArr, ids }
      : {
          values: invalidDefaultValues,
          array: mismatchArr,
          ids,
        };
    const values = validationPayrollSubmitFormatterSkip(obj);
    onSubmit(values);
  };

  const onSubmitInvalid = (data) => {
    const values = validationPayrollSubmitFormatter(
      data,
      invalidArr,
      mismatchDefaultValues
    );
    onSubmit(values);
  };

  const onSubmitMismatch = (data) => {
    const values = validationPayrollSubmitFormatter(
      data,
      mismatchArr,
      invalidDefaultValues
    );
    onSubmit(values);
  };

  // MUTATORS

  // PROPS

  const invalidProps = {
    onSkip: toggleInvalid,
    onSubmit: onSubmitInvalid,
    defaultValues: invalidDefaultValues,
    onBack: () => setInvalidOrMismatch(""),
    onChangeData: (data, setData) =>
      validationOnChange(data, setData, setMultiData),
  };

  const invalidModalProps = {
    isOpen: isOpenInvalid,
    toggle: toggleInvalid,
    onClick: () => handleSkipDetail(invalidArr),
    isInvalid: true,
    loading: loadingSkip,
  };

  const mismatchProps = {
    onSkip: toggleMismatch,
    onSubmit: onSubmitMismatch,
    defaultValues: mismatchDefaultValues,
    onBack: () => setInvalidOrMismatch(""),
    onChangeData: (data, setData) =>
      validationOnChangeMismatch(data, setData, setMultiData),
  };

  const mismatchModalProps = {
    isOpen: isOpenMismatch,
    toggle: toggleMismatch,
    onClick: () => handleSkipDetail(mismatchArr),
    isInvalid: false,
    loading: loadingSkip,
  };
  // PROPS

  return {
    isInvalid,
    isMismatch,
    changeState,
    numbers,
    handleSkip,
    loadingSkip,
    onSubmitInvalid,
    setInvalidOrMismatch,
    // PROPS
    invalidProps,
    invalidModalProps,
    mismatchProps,
    mismatchModalProps,
    // PROPS
  };
};
