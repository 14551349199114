import {
  DEMO_SWITCH_ROLE_TO_ADMIN,
  DEMO_SWITCH_ROLE_TO_BO,
  DEMO_SWITCH_ROLE_TO_BOOKKEEPER,
  DEMO_SWITCH_ROLE_TO_EMPLOYEE,
} from "./events";

export const business_owner = "Business Owner";
export const admin = "Admin";
export const employee = "Employee";
export const bookkeeper = "Bookkeeper";

export const getUserRoleDemo = (roleName) => {
  switch (roleName) {
    case "Business Owner":
      return {
        eventName: DEMO_SWITCH_ROLE_TO_BO,
        page_title: `switch_role_success_bo`,
        description:
          "Has access to all of Jack’s full capabilities and controls, which includes approving and releasing transactions.",
      };
    case "Admin":
      return {
        eventName: DEMO_SWITCH_ROLE_TO_ADMIN,
        page_title: `switch_role_success_admin`,
        description:
          "Can access nearly all of Jack’s capabilities and controls, which includes approving and releasing transactions. ",
      };
    case "Employee":
      return {
        eventName: DEMO_SWITCH_ROLE_TO_EMPLOYEE,
        page_title: `switch_role_success_employee`,
        description:
          "Can create or request any transaction and view their submitted transactions",
      };
    case "Bookkeeper":
      return {
        eventName: DEMO_SWITCH_ROLE_TO_BOOKKEEPER,
        page_title: `switch_role_success_bookkeeper`,
        description:
          "Can view, change category, and download all transactional data in Jack.",
      };
    default:
      return {
        eventName: "",
        description: "",
      };
  }
};
