import { useState, useEffect, useContext } from "react";
import { useRouter } from "next/router";
import { apiBusiness, fetch, useMutation } from "../../tools/api";
import { deepRemoveDuplicates } from "../../components/tools";
import { useConstants } from "../../contexts/ConstantsContext/parent";
import { ToasterContext } from "../../contexts/ToasterContext";

const PER_PAGE = 10;

const useNotifications = (tab = null) => {
  const isActivity = tab === "activity";
  const urlDecider = (tab) => {
    if (isActivity) {
      return "/activity_custom_notifications";
    } else if (tab === "news_and_promo") {
      return "/news_promo_custom_notifications";
    }
    return "/custom_notifications";
  };

  const [page, setPage] = useState(1);
  const { query, isReady, replace } = useRouter();
  const { originator_type, is_read } = query;
  const isRead = is_read === "true";

  const navigationTab = `&q[notification_tab_eq]=${
    isActivity ? "activity" : "news_and_promo"
  }`;

  const urlQueryFormatter = () => {
    if (!originator_type && !is_read) return "";
    const typeQuery = originator_type
      ? `&q[originator_type_eq]=${originator_type}`
      : "";
    const isReadQuery = isRead ? `&q[is_read_eq]=false` : "";
    const queryUrl = `${typeQuery}${isReadQuery}`;
    return queryUrl;
  };

  const formattedQuery = urlQueryFormatter();
  const url = `/custom_notifications?per_page=${PER_PAGE}&page=${page}${formattedQuery}`;

  const { data, loading, refetch, setData } = fetch({
    url: `${url}${navigationTab}`,
    woInit: true,
    formatter: (data, prev) => {
      const res = data?.data;
      const formatted = res.map((item) => {
        const { originator_type } = item;
        const typeOutput = typeDecider(originator_type);

        return {
          ...item,
          originator_type: typeOutput,
        };
      });

      const arr = deepRemoveDuplicates(
        [...(prev?.array || []), ...(formatted || [])],
        "id"
      );
      return { array: arr, ...data };
    },
    afterSuccess: () => {},
  });

  const {
    total_page: totalPage = 0,
    total_unread_notifications: unread = 0,
    page: currPage,
  } = data || {};

  useEffect(() => {
    if (originator_type && isReady) {
      replace({
        pathname: "/custom_notifications",
      });
    }
  }, [isReady]);

  useEffect(() => {
    if (page === 1) return;
    refetch();
  }, [page]);

  useEffect(() => {
    setData({});
    if (page === 1) {
      refetch();
    }
    setPage(1);
  }, [is_read, tab]);

  const nextPage = () => {
    if (currPage >= totalPage) return;
    if (loading) return;
    setPage((p) => p + 1);
  };

  const { refetchActivity, refetchNews } = useConstants();

  const {
    mutation: markAsReadBulk,
    setResult,
    result,
  } = useMutation({
    url: urlDecider(tab),
    method: "post",
    afterSuccess: () => {
      setData({});
      // if (page === 1) {
      refetch();
      // }
      // setPage(1);
      if (isActivity) refetchActivity();
      else refetchNews();
    },
  });

  const { errorToaster } = useContext(ToasterContext);

  const markAsRead = async (id) => {
    try {
      const data = await apiBusiness.put(`${urlDecider()}/${id}`);
      if (!data || data?.status != 200) {
        return errorToaster("Error", "error mark as read item");
      }
      setData((prev) => {
        const newArr = [...(prev?.array || [])].map((item) => {
          if (item.id === id) {
            return {
              ...item,
              is_read: true,
            };
          }
          return item;
        });

        return {
          ...prev,
          array: newArr,
        };
      });
      refetch();
      if (isActivity) refetchActivity();
      else refetchNews();
    } catch (error) {
      console.log("error:", error);
    }
  };

  return {
    loading,
    nextPage,
    data,
    unread,
    refetch,
    markAsReadBulk,
    markAsRead,
  };
};

export default useNotifications;

export const useAutoOpen = (setSelectedItem, openCondition) => {
  const { push, query } = useRouter();
  const { id = null, type = null, isOpen = false } = query ?? {};
  
  useEffect(() => {
    if (openCondition !== undefined && !openCondition) return;
    if (id && isOpen) {
      setSelectedItem({ originator_id: id, type });
      delete query?.isOpen;
      push({ query });
    }
  }, [id, type, isOpen]);
};

const typeDecider = (originator_type) => {
  const isAction = originator_type === "Action";

  const isInvoice = originator_type === "InvoiceTransaction";
  const isLocalTransfer = originator_type === "LocalTransactionBatch";
  const isCrossBorder = originator_type === "BusinessTransaction";
  const isCrossBorderBatch = originator_type === "Job";
  const isPayroll = originator_type === "Payroll";
  const isWallet = originator_type === "WalletTransaction";
  const isReimbursement = originator_type === "Reimbursement";
  const isCard = originator_type === "UserCard";

  // const isActionCard = action_originator_type === "UserCard";
  // const isCardRequestActive =
  //   action_type === "request_active" && isActionCard;
  // const isCardRequestLimit =
  //   action_type === "request_adjust_limit" && isActionCard;
  // const isCardTopUpBalance =
  //   action_type === "request_withdraw_balance_company" && isActionCard;
  // const isCardWithdrawBalance =
  //   action_type === "request_topup_balance_company" && isActionCard;
  // const isCardMoveBalanceToCard =
  //   action_type === "request_move_balance" && isActionCard;
  // const isCardWithAmount =
  //   isCardTopUpBalance || isCardWithdrawBalance || isCardMoveBalanceToCard;

  // if (isAction) {
  //   if (isActionCard) return "card";
  // }
  if (isInvoice) return "invoice";
  if (isLocalTransfer) return "local_disbursement_batch";
  if (isPayroll) return "payroll";
  if (isCrossBorder) return "single";
  if (isWallet) return transaction_type;
  if (isCrossBorderBatch) return "batch";
  if (isReimbursement) return "reimbursement";
  if (isCard) return "card";
  return originator_type;
};
