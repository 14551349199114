import { eventsTracker } from "../../../../universalFunctions/events";
import {
  TOOLTIP_DEFAULT_PROPERTIES,
  TRANSACTIONS_FIFTH_TARGET,
  TRANSACTIONS_FIRST_TARGET,
  TRANSACTIONS_FOURTH_TARGET,
  TRANSACTIONS_SECOND_TARGET,
  TRANSACTIONS_THIRD_TARGET,
} from "../../constants";
import { DEMO_TOOLTIP_CLOSE, DEMO_TOOLTIP_NEXT } from "../../constants/events";

const stepsDecider = (isEmployee) => {
  const defaultSteps = [
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TRANSACTIONS_FIRST_TARGET}`,
      title: "Company Transaction",
      content: "All your company’s transactions are displayed on this tab. ",
      placement: "right",
      eventPayload: {
        page_title: "transaction",
        tooltip_activity: "company_transaction",
      },
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TRANSACTIONS_SECOND_TARGET}`,
      title: "My Transactions",
      content: "Track all transactions you’ve created in this tab.",
      placement: "right",
      eventPayload: {
        page_title: "transaction",
        tooltip_activity: "my_transaction",
      },
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TRANSACTIONS_THIRD_TARGET}`,
      title: "Transaction’s status",
      content:
        "Each transaction's status is shown here. Click to view specific details.",
      placement: "top",
      eventPayload: {
        page_title: "transaction",
        tooltip_activity: "transaction_status",
      },
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TRANSACTIONS_FOURTH_TARGET}`,
      title: "Filtering transaction",
      content:
        "Effortlessly sort out your transactions using available filters.",
      placement: "left",
      eventPayload: {
        page_title: "transaction",
        tooltip_activity: "filter_transaction_status",
      },
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TRANSACTIONS_FIFTH_TARGET}`,
      title: "Export your transaction report",
      content: "Use this feature to export your data to Excel documents.",
      placement: "right",
      eventPayload: {
        page_title: "transaction",
        tooltip_activity: "export_transaction_report",
      },
    },
  ];

  if (isEmployee) defaultSteps.splice(0, 1);
  return defaultSteps;
};

const useTransactionProps = ({ isEmployee }) => {
  const callback = ({ data, defaultState, setGuidedTourState }) => {
    const { index, action, lifecycle, step } = data ?? {};
    const { eventPayload = {} } = step ?? {};

    const stepCount = steps.length - 1;

    const isComplete = lifecycle === "complete";
    const isSkipped = action === "skip";

    if (isSkipped) {
      eventsTracker(DEMO_TOOLTIP_CLOSE, eventPayload);
    }

    if (!isComplete) return;

    switch (action) {
      case "close":
      case "next":
        eventsTracker(DEMO_TOOLTIP_NEXT, eventPayload);
        if (index < stepCount) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex + 1,
          }));
          break;
        }
        if (index === stepCount) {
          setGuidedTourState(defaultState);
          break;
        }
        break;

      case "prev":
        if (index > 0) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex - 1,
          }));
          break;
        }
        break;

      default:
        break;
    }
  };

  const steps = stepsDecider(isEmployee);

  return {
    steps,
    callback,
  };
};

export default useTransactionProps;
