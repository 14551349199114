import { isEmpty } from "lodash";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { useModalHook } from "../../../components/Modals";
import { ProgressBarJack } from "../../../components/Stepper";
import { SimpleTextAreaJack } from "../../../components/inputs/simpleTextArea";
import { useDemo } from "../../../contexts/DemoContext";
import { useGuidedTour } from "../../../contexts/GuidedTourContext";
import { useHeader } from "../../../contexts/Layout/parent";
import { WrapperJackCenter } from "../../../pageComponents/crossBorder/components";
import CategorySelection from "../../categoryComponents/general/components/CategorySelection";
import {
  useAvailableCategories,
  useCategoryFlag,
} from "../../categoryComponents/general/hooks";
import { ValidationValueContainWhiteSpace } from "../../payrollCreateComponents/logics";
import useGuidedTourBuilder from "../../productDemoComponents/builders";
import { ModalConfirmationCloseLocal } from "./modal";
import { stepArrayLocalFunc } from "./parent";
import CreateTransactionsOnboardModal from "../../productDemoComponents/components/OnboardModalPopup";
import { TextInlineMedium } from "../../../components/Text";
import { getDemoData } from "../../productDemoComponents/hooks";
import {
  LOCAL_FIRST_PAGE,
  LOCAL_FIRST_TARGET,
  LOCAL_MODULE,
} from "../../productDemoComponents/constants";
import { useRouter } from "next/router";
import { batchname } from "../../productDemoComponents/constants/localTransfer";
import {
  DEMO_CANCEL_CLOSE_PAGE,
  DEMO_CLOSE_PAGE,
  DEMO_CONFIRM_CLOSE_PAGE,
  DEMO_PAGE_VIEW,
} from "../../productDemoComponents/constants/events";
import { eventsTracker } from "../../../universalFunctions/events";

const defaultCategory = {
  id: 1203,
  name: "Office Supplies",
  partner_id: 285,
  account_code: "00003",
  color: "#DE738B",
  icon: null,
  created_at: "2023-08-09T11:38:29.000+07:00",
  updated_at: "2023-08-09T11:38:29.000+07:00",
  is_archived: false,
  description: "Paper & writing tools, technologies, utilities, food, etc.",
  total_expense: {
    current_expense: 0,
    prev_expense: 0,
  },
  log: null,
  teams: [],
};

export const LocalTransferFirstForm = ({ onSubmit: onSubmitProps }) => {
  const [selectedCategory, setSelectedCategory] = useState(null);

  const useFormObj = useForm();
  const { setHeader } = useHeader();
  const { isOpen, toggle } = useModalHook();

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, {
      activity_type: "create_local_transfer",
      page_title: "batch_name",
    });

    setHeader({
      type: "close",
      onCloseProps: () => {
        eventsTracker(DEMO_CLOSE_PAGE, {
          activity_type: "create_local_transfer",
          page_title: "batch_name",
        });
        toggle();
      },
      middleComponent: <ProgressBarJack arrayStep={stepArrayLocalFunc(0)} />,
    });
  }, []);

  const { hasCategoryList } = useAvailableCategories();
  const { isRequiredCategory } = useCategoryFlag();
  const isMissingRequiredCategory = Boolean(
    isRequiredCategory && !selectedCategory && hasCategoryList
  );

  const { watch, handleSubmit, setValue } = useFormObj;
  const watchName = watch("batch_name");
  const { valueValidation, isDisabled } =
    ValidationValueContainWhiteSpace(watchName);
  const isDisabledSubmit = isDisabled || isMissingRequiredCategory;

  const onSubmit = (values) => {
    const payload = { type: "first", values };

    if (!isEmpty(selectedCategory)) {
      payload.values = { ...values, category_id: selectedCategory.id };
    }

    onSubmitProps(payload);
  };

  useEffect(() => {
    setValue("batch_name", valueValidation);
  }, [watchName]);

  const handleSelectCategory = (category) => {
    setSelectedCategory(category);
  };

  // DEMO PURPOSE
  const guidedTourProps = useGuidedTourBuilder({
    module: LOCAL_MODULE,
    page: LOCAL_FIRST_PAGE,
  });
  const { startTour, stopTour } = useGuidedTour(guidedTourProps);
  const { setGuidanceState, guidanceState } = useDemo();
  const { isTourRunning, guided = false } = guidanceState ?? {};

  const prefillData = () => {
    setValue("batch_name", batchname);
    setSelectedCategory(defaultCategory);
  };

  const handleStart = () => {
    prefillData();
    startTour();
  };

  const onClickGuideMe = () => {
    handleStart(); // to start the tour
    setGuidanceState((prev) => ({ ...prev, isGuidanceMode: true }));
  };

  const onExplore = () => {
    setGuidanceState((prev) => ({ ...prev, isGuidanceMode: false }));
  };

  useEffect(() => {
    return setGuidanceState((prev) => ({
      ...prev,
      guidanceFunction: isTourRunning ? stopTour : startTour,
      eventPayload: {
        activity_type: "create_local_transfer",
        page_title: "batch_name",
      },
    }));
  }, [isTourRunning]);

  useEffect(() => {
    if (!guided) return;
    setTimeout(() => {
      onClickGuideMe();
    }, 500);
  }, [guided]);

  useEffect(() => {
    return () => {
      stopTour(); // this is necessary to prevent a clashing tour (if user presses back in the middle of a running tour)
    };
  }, []);

  return (
    <>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <span id={LOCAL_FIRST_TARGET} style={{ display: "inline-flex" }}>
          <WrapperJackCenter
            title="Pick a name for your local batch transfer"
            containerStyle={{ margin: "auto" }}
          >
            <form onSubmit={handleSubmit(onSubmit)}>
              <SimpleTextAreaJack
                useFormObj={useFormObj}
                label="Batch Transfer Name"
                name="batch_name"
                placeholder="E.g., Vendor Payment June 2023"
                maxLength={50}
                style={{ height: 60 }}
                containerStyle={{ marginBottom: 26 }}
              />

              <CategorySelection
                selectedCategory={selectedCategory ?? {}}
                onSelect={handleSelectCategory}
              />

              <div className="d-flex justify-content-end" id="meong">
                <ButtonJack
                  buttonType="submit"
                  disabled={isDisabledSubmit}
                  style={{ minWidth: 80 }}
                  rightIcon={
                    <JackIcons
                      name="arrow-forward"
                      style={{ width: 20, height: 20 }}
                      fill={
                        isDisabledSubmit
                          ? jackColors.neutral600
                          : jackColors.neutral900
                      }
                    />
                  }
                >
                  Next
                </ButtonJack>
              </div>
            </form>
          </WrapperJackCenter>
        </span>
      </div>

      <CreateTransactionsOnboardModal
        onClick={onClickGuideMe}
        onExplore={onExplore}
      />

      <ModalConfirmationCloseLocal
        isOpen={isOpen}
        product="local"
        onClick={() => {
          eventsTracker(DEMO_CONFIRM_CLOSE_PAGE, {
            activity_type: "create_local_transfer",
            page_title: "batch_name",
          });
        }}
        onClickLeft={() => {
          if (isOpen)
            eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
              activity_type: "create_local_transfer",
              page_title: "batch_name",
            });
          toggle();
        }}
        onClickClose={() => {
          eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
            activity_type: "create_local_transfer",
            page_title: "batch_name",
          });
        }}
        toggle={toggle}
      />
    </>
  );
};
