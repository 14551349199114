import { TopUpModal } from "../../../../components/Modals";
import {
  RightModal,
  useSmoothRightModal,
} from "../../../../components/Modals/RightModal/parent";
import PINModal from "../../../../components/PINModal/parent";
import {
  CardDetailContextProvider,
  useCardDetailContext,
} from "../../../../contexts/Cards/CardDetailContext";
import CardInfoBalance from "./components/CardInfoBalance";
import DetailBlock from "./screens/DetailBlock";
import DetailIndex from "./screens/DetailIndex";
import PhysicalAM from "./screens/PhysicalAM";
import PhysicalChangePin from "./screens/PhysicalChangePin";
import PhysicalEditInfo from "./screens/PhysicalEditInfo";
import PrepaidMoveBalanceMethod from "./screens/PrepaidMoveBalanceMethod";
import PrepaidMoveToCard from "./screens/PrepaidMoveToCard";
import PrepaidMoveToCompany from "./screens/PrepaidMoveToCompany";
import PrepaidTopUpByCompany from "./screens/PrepaidTopUpByCompany";
import PrepaidTopUpByVA from "./screens/PrepaidTopUpByVA";
import PrepaidTopUpMethod from "./screens/PrepaidTopUpMethod";
import VirtualAdjustLimit from "./screens/VirtualAdjustLimit";
import VirtualEditInfo from "./screens/VirtualEditInfo";

export const ModalContent = () => {
  const { currentScreen } = useCardDetailContext();

  const screenDecider = () => {
    switch (currentScreen) {
      case "temporaryBlock":
        return <DetailBlock type="temporary" />;
      case "permanentBlock":
        return <DetailBlock type="permanent" />;
      case "physicalChangePin":
        return <PhysicalChangePin />;
      case "physicalAdjustLimit":
        return <PhysicalAM type="adjustCardLimit" />;
      case "physicalReplaceCard":
        return <PhysicalAM type="replaceCard" />;
      case "physicalEditInfo":
        return <PhysicalEditInfo />;
      case "virtualEditInfo":
        return <VirtualEditInfo />;
      case "virtualAdjustLimit":
        return <VirtualAdjustLimit />;
      case "prepaidMoveBalanceMethod":
        return <PrepaidMoveBalanceMethod />;
      case "prepaidTopUpMethod":
        return <PrepaidTopUpMethod />;
      case "prepaidTopUpCompany":
        return <PrepaidTopUpByCompany />;
      case "prepaidTopUpVA":
        return <PrepaidTopUpByVA />;
      case "prepaidMoveToCompany":
        return <PrepaidMoveToCompany />;
      case "prepaidMoveToCard":
        return <PrepaidMoveToCard />;
      case "index":
      default:
        return <DetailIndex />;
    }
  };

  const screen = screenDecider();

  return screen;
};

const CardDetailModal = ({ isOpen }) => {
  const {
    rightModalArray = [],
    rightModalWidth,
    rightModalToggle,
    isOpenPin,
    isOpenReject,
    hideClose,
    loadingCardDetail,
    isHideModal,
  } = useCardDetailContext();

  return (
    <>
      <RightModal
        isOpen={isOpen}
        toggle={rightModalToggle}
        array={rightModalArray}
        hideModal={isOpenPin || isOpenReject || isHideModal}
        width={rightModalWidth}
        isLoading={loadingCardDetail}
        woClose={hideClose}
      />
    </>
  );
};

const CardModal = ({
  isOpen,
  toggle,
  cardDetail,
  isArchiveScreen,
  setSelectedItem,
  setCards,
  toggleConfirmDelete,
  isTask,
  isReveal,
  isAlert,
}) => {
  return (
    <CardDetailContextProvider
      setCards={setCards}
      cardDetail={cardDetail}
      toggle={toggle}
      isArchiveScreen={isArchiveScreen}
      toggleConfirmDelete={toggleConfirmDelete}
      isTask={isTask}
      setSelectedItem={setSelectedItem}
      isReveal={isReveal}
      isAlert={isAlert}
    >
      <CardDetailModal isOpen={isOpen} isReveal={isReveal} />
    </CardDetailContextProvider>
  );
};

export default CardModal;
