import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, { INTERNATIONAL_THIRD_TARGET } from "../../constants";

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${INTERNATIONAL_THIRD_TARGET}`,
    content: (
      <TooltipContent
      title="Inputting the recipient details"
      description="Add a new recipient or simply choose from existing recipient list."
      />
    ),
    eventPayload: {
      activity_type: "create_international_transfer",
      guided_tour_activity: "recipient_details",
    },
  },
];

const internationalSecondPageProps = { steps };

export default internationalSecondPageProps;
