import { getMonthEN, unformatCurrency } from "../../../components/tools";

import {
  getDefaultValuesArray,
  getRecipientObj,
} from "../../localTransferComponents/localTransfer/formatter";

export const payrollSubmitFormatter = (values, firstValues) => {
  const { payroll_name: name, month: monthNum, year } = firstValues || {};

  const month = getMonthEN(monthNum);

  const payroll_attributes = {
    name,
    month,
    year,
  };

  const local_transactions_attributes = values.map((item) => {
    const {
      name,
      role: team_role,
      department: department_name,
      bank,
      account_number,
      email,
      net_salary,
      notes,
      id: local_transaction_id,
      partner_id,
      category_id,
    } = item;

    const amount = unformatCurrency(net_salary);
    const payer_id = bank?.value;

    const payroll_recipient_attributes = {
      department_name,
      team_role,
      salary: amount,
    };

    // const recipient = { name, email };
    const recipient = getRecipientObj(name, email);

    const local_recipient_attributes = {
      is_payroll: true,
      name,
      payer_id,
      account_number,
      payroll_recipient_attributes,
      email,
      partner_id,
    };

    const result = {
      amount,
      notes,
      local_recipient_attributes,
      local_transaction_id,
      partner_id,
      recipient,
    };

    if (category_id) result.category_id = category_id;

    return result;
  });

  const defaultResult = {
    transaction_type: "payroll",
    local_transactions_attributes,
  };

  const result = firstValues
    ? {
        ...defaultResult,
        payroll_attributes,
      }
    : defaultResult;

  return result;
};

export const payrollEditFormatter = ({
  values,
  defaultValues,
  partner_id,
  name,
}) => {
  const defaultValuesArr = getDefaultValuesArray(defaultValues);
  const getId = (array) => array.map(({ id }) => id);
  const defaultValueIds = getId(defaultValuesArr);
  const valuesIds = getId(values);

  // delete
  const deletedIds = defaultValueIds.filter((id) => !valuesIds.includes(id));

  const deletePayload = {
    ids: deletedIds,
  };
  // delete

  // update
  const transactionsRaw = defaultValueIds
    .map((id) => {
      if (valuesIds.includes(id)) {
        const obj =
          values.filter(({ id: valuesId }) => id == valuesId)[0] || {};
        const { unprocessed } = obj || {};

        if (unprocessed) return null;

        return { ...obj, partner_id };
      }
    })
    .filter((item) => item);

  const compareUpdatedData = CompareDataPayroll({
    defaultValues: defaultValuesArr,
    currentValues: transactionsRaw,
    partner_id,
  });

  const { local_transactions_attributes: transactions } =
    payrollSubmitFormatter(compareUpdatedData);

  const updatePayload = {
    mismatched_action: "replace",
    transactions,
  };
  // update

  // create
  const local_transactions_attributesRaw = values
    .filter(({ id }) => !defaultValueIds.includes(id))
    .map((item) => ({ ...item, partner_id }));

  const { local_transactions_attributes } = payrollSubmitFormatter(
    local_transactions_attributesRaw
  );
  const createPayload = { local_transactions_attributes, partner_id };
  // create

  const isDelete = deletedIds.length;

  const isCreate = local_transactions_attributesRaw.length || name;

  const isUpdate = !!compareUpdatedData.length;

  return {
    deletePayload,
    updatePayload,
    createPayload,
    isDelete,
    isCreate,
    isUpdate,
  };
};

const CompareDataPayroll = ({ defaultValues, currentValues }) => {
  // payroll using different compare logic to localtransfer

  const getObj = (item) => defaultValues.filter(({ id }) => id == item?.id)[0];
  // updates just check the added value, if there are none. Then its not an update
  const transactions = currentValues.filter((item) => {
    const obj = getObj(item);
    if (!obj) return false;

    const {
      net_salary,
      bank: bank_id,
      name,
      bank_account_number,
      notes: notesRaw,
      email: emailRaw,
      department,
      role,
      category_id,
    } = obj || {};
    const email = emailRaw || "";
    const notes = notesRaw || "";

    // c stands for compare
    const {
      net_salary: cnet_salary,
      bank: cbank,
      recipient_name: crecipient_name,
      bank_account_number: cbank_account_number,
      notes: cnotes,
      email: cemailR,
      recipient,
      department: cdepartment,
      role: crole,
      category_id: ccategory_id,
    } = item;
    const { id: cbank_id } = cbank || {};

    const { email: emailR } = recipient || {};
    const cemail = emailR || cemailR;

    const comparator = (first, second) => first != second;
    const comparatorNumber = (first, second) =>
      unformatCurrency(first) !== unformatCurrency(second);

    const netSalaryComp = comparatorNumber(net_salary, cnet_salary);
    const bankComp = comparator(bank_id, cbank_id);
    const recipient_nameComp = comparator(name, crecipient_name);
    const bank_account_numberComp = comparator(
      bank_account_number,
      cbank_account_number
    );
    const notesComp = comparator(notes, cnotes);
    const emailComp = comparator(email, cemail);
    const departmentComp = comparator(department, cdepartment);
    const roleComp = comparator(role, crole);
    const categoryComp = comparator(category_id, ccategory_id);

    if (netSalaryComp) return true;
    if (bankComp) return true;
    if (recipient_nameComp) return true;
    if (bank_account_numberComp) return true;
    if (notesComp) return true;
    if (emailComp) return true;
    if (departmentComp) return true;
    if (roleComp) return true;
    if (categoryComp) return true;

    return false;
  });

  return transactions;
};
