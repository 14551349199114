import {
  TASK_FIRST_TARGET,
  TASK_FOURTH_TARGET,
  TASK_SECOND_TARGET,
  TASK_THIRD_TARGET,
  TOOLTIP_DEFAULT_PROPERTIES,
} from "../../constants";

const stepsDecider = (methods) => {
  const { hasApproval, hasPayment } = methods ?? {};
  const showRelease = !hasApproval;
  const defaultSteps = [
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TASK_FIRST_TARGET}`,
      title: "Release payment",
      content:
        "This list shows requests that need final approval. Once approved, payment will be released.",
      placement: "right",
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${TASK_SECOND_TARGET}`,
      title: "Require approval",
      content:
        "Approve these requests and they will go to the next approval step.",
      placement: "right",
    },
    {
      ...TOOLTIP_DEFAULT_PROPERTIES,
      target: `#${showRelease ? TASK_THIRD_TARGET : TASK_FOURTH_TARGET}`,
      title: "Multi-approve or pay",
      content:
        "Want to save your time? Tick the boxes to select the requests you want to approve/pay.",
      placement: "right",
    },
  ];

  if (!hasPayment) defaultSteps.splice(0, 1);
  if (!hasApproval) defaultSteps.splice(1, 1);
  return defaultSteps;
};

const useTaskProps = ({ methods }) => {
  const callback = ({ data, defaultState, setGuidedTourState }) => {
    const { index, action, lifecycle } = data ?? {};

    const stepCount = steps.length - 1;

    const isComplete = lifecycle === "complete";

    if (action === "skip") {
      setGuidedTourState(defaultState);
    }
    if (!isComplete) return;

    switch (action) {
      case "close":
      case "next":
        if (index < stepCount) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex + 1,
          }));
          break;
        }
        if (index === stepCount) {
          setGuidedTourState(defaultState);
          break;
        }
        break;

      case "prev":
        if (index > 0) {
          setGuidedTourState((prev) => ({
            ...prev,
            stepIndex: prev.stepIndex - 1,
          }));
          break;
        }
        break;

      default:
        break;
    }
  };

  const steps = stepsDecider(methods);

  return {
    steps,
    callback,
  };
};

export default useTaskProps;
