export const recipientType = {
  title: "Individual/Personal",
  value: "personal",
};

export const settlementMethod = {
  title: "SWIFT OUR",
  desc: "You are paying all transaction fees and the recipient will receive in full amount",
  fee: "Fee IDR 300,000",
  icon: "SwiftOur",
  items: [
    {
      point: "Customize notes",
    },
    {
      point: "Global proof of payment (MT-103)",
    },
    {
      point: "Should arrive",
      pointBold: "D+1 banking day if send before 11:45 AM (GMT+7)",
    },
  ],
};

export const selectedCategory = {
  id: 468,
  name: "Service Provider",
  partner_id: 51,
  account_code: "00008",
  color: "#0A7C8C",
  icon: "build",
  created_at: "2024-04-23T14:59:33.000+07:00",
  updated_at: "2024-04-23T14:59:33.000+07:00",
  is_archived: false,
  description: "Vendors, professional consulting, legal services, etc.",
  total_expense: {
    current_expense: 0,
    prev_expense: 0,
  },
  log: null,
  teams: [],
};

export const additionalInfo = {
  purpose_of_remittance: {
    label: "Pay for services",
    value: 7,
    code: "PAY_FOR_SERVICES",
    nium: null,
  },
  source_of_funds: {
    label: "Business/investment",
    value: 2,
    code: "BUSINESS_INVESTMENT",
  },
  beneficiary_relationship: {
    label: "Others",
    value: 11,
    code: "OTHER",
  },
  document_reference_number: "333322221111",
};
