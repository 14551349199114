import { pickBy } from "lodash";
import { removeDuplicates, unformatCurrency } from "../../../components/tools";

export const getDefaultValuesArray = (defaultValues) => {
  const defaultValuesNumbersDirty = Object.keys(defaultValues || {})
    .map((key) => key.split("_").pop())
    .filter((item) => Number(item));
  const defaultValuesNumbersClean = removeDuplicates(defaultValuesNumbersDirty);

  const defaultValuesArray = defaultValuesNumbersClean.map((number) => {
    let obj = {};
    const keys = Object.keys(defaultValues);
    keys.forEach((key) => {
      const numberFromObj = key.split("_").pop();
      const isSame = numberFromObj == number;
      const value = defaultValues[key];
      const newKey = key.split(`_${number}`)[0];
      if (isSame) obj[newKey] = value;
    });
    return obj;
  });
  return defaultValuesArray;
};

const addsFormatter = (array, partner_id, category_id) =>
  array?.map((item) => {
    const {
      amount: amountRaw,
      bank,
      bank_account_number: account_number,
      email,
      notes = "",
      recipient_name: name,
      local_recipient_id,
      // purpose_of_transfer = "testing",
    } = item || {};

    const amount = Number(unformatCurrency(amountRaw));

    const payer_id = bank?.id;

    const local_recipient_attributes = {
      name,
      payer_id,
      account_number: String(account_number),
      email,
      partner_id,
    };

    const createResult = {
      local_recipient_attributes,
      amount,
      local_recipient_id,
      notes,
      partner_id,
    };

    if (category_id) createResult.category_id = category_id;

    return createResult;
  });

export const getDefaultValueIdsObj = (defaultValues) =>
  pickBy(defaultValues, (_, key) => {
    const isId = key.includes("id_");
    const notYou = key.includes("_id_") || key.includes("valid");
    if (notYou) return false;
    if (isId) return true;
    return false;
  });

export const getRecipientObj = (name, email) => {
  const emailDecider = () => {
    if (!email) return { email: "" };

    const isMulti = email.includes(",");
    if (!isMulti) return { email };

    const [realEmail, ...additionalEmails] = email
      .split(",")
      .map((email) => email.replace(" ", ""));

    const additional_emails = additionalEmails.join(", ");

    return { additional_emails, email: realEmail };
  };

  const recipient = { name, ...emailDecider() };

  return recipient;
};

const localTrxAttributes = (array, partner_id, category_id) =>
  addsFormatter(array, partner_id, category_id).map((item) => {
    const { name, email } = item?.local_recipient_attributes || {};

    const recipient = getRecipientObj(name, email);
    return { ...item, recipient };
  });

export const updateLocalTransferFormatter = ({
  array,
  defaultValues,
  partner_id,
  name = "",
  category_id,
}) => {
  const arrayIds = array.map(({ id }) => id);
  const defaultValuesArray = getDefaultValuesArray(defaultValues);

  const ids = defaultValuesArray
    .filter(({ id }) => !arrayIds.includes(id))
    .map(({ id }) => id);
  const deletedIds = { ids };

  const getObj = (item) => array.filter(({ id }) => id == item?.id)[0];
  // updates just check the added value, if there are none. Then its not an update
  const transactions = defaultValuesArray
    .filter((item) => {
      const obj = getObj(item);
      if (!obj) return false;

      const {
        amount,
        bank,
        recipient_name,
        bank_account_number,
        notes: notesRaw,
        email: emailRaw,
      } = obj || {};
      const email = emailRaw || "";
      const notes = notesRaw || "";
      const { id: bank_id } = bank || {};

      // c stands for compare
      const {
        amount: camount,
        bank: cbank_id,
        recipient_name: crecipient_name,
        bank_account_number: cbank_account_number,
        notes: cnotes,
        email: cemailR,
        recipient,
      } = item;

      const { email: emailR } = recipient || {};
      const cemail = emailR || cemailR;

      const comparator = (first, second) => first != second;
      const comparatorNumber = (first, second) =>
        unformatCurrency(first) !== unformatCurrency(second);

      const amountComp = comparatorNumber(amount, camount);
      const bankComp = comparator(bank_id, cbank_id);
      const recipient_nameComp = comparator(recipient_name, crecipient_name);
      const bank_account_numberComp = comparator(
        bank_account_number,
        cbank_account_number
      );
      const notesComp = comparator(notes, cnotes);
      const emailComp = comparator(email, cemail);

      if (amountComp) return true;
      if (bankComp) return true;
      if (recipient_nameComp) return true;
      if (bank_account_numberComp) return true;
      if (notesComp) return true;
      if (emailComp) return true;

      return false;
    })
    .map((item) => {
      const { registered_name } = item?.local_recipient || {};

      const {
        amount,
        notes,
        recipient_name,
        email,
        bank_account_number: account_number,
        id,
        bank,
      } = getObj(item);
      const payer_id = bank?.value;

      const recipient = getRecipientObj(recipient_name, email);

      const local_recipient_attributes = {
        name: recipient_name,
        registered_name,
        payer_id,
        partner_id,
        account_number,
      };
      const result = {
        amount: unformatCurrency(amount),
        notes,
        recipient,
        local_recipient_attributes,
        id,
      };

      if (category_id) result.category_id = category_id;

      return result;
    });

  const updateObj = { mismatched_action: "replace", transactions };

  const createArrays = array?.filter(({ id }) => !id);
  const local_transactions_attributes = localTrxAttributes(
    createArrays,
    partner_id,
    category_id
  );

  const createObj = name
    ? { name, local_transactions_attributes }
    : { local_transactions_attributes };

  return {
    updateObj,
    createObj,
    deletedIds,
  };
};

export const localTransferFormatter = ({
  batch_name: name,
  array,
  partner_id,
  defaultValues,
  isEdit,
  category_id,
  ancestor_id,
}) => {
  if (isEdit) {
    const { createObj, deletedIds, updateObj } = updateLocalTransferFormatter({
      array,
      defaultValues,
      partner_id,
      name,
      category_id,
    });
    return { createObj, deletedIds, updateObj };
  }

  const local_transactions_attributes = localTrxAttributes(
    array,
    partner_id,
    category_id
  );

  const result = { name, local_transactions_attributes };

  if (ancestor_id) result.ancestor_id = ancestor_id;

  return result;
};

export const localTransferDefaultValuesFormatter = (res) => {
  const { data, batch } = res;
  const array = data || [];
  const batch_name = batch?.name;

  let defaultValues = { batch_name };

  array.forEach((item, index) => {
    const number = index + 1;

    const { amount, local_recipient, notes, recipient } = item;

    const {
      name,
      payer_id: bank,
      account_number: bank_account_number,
      registered_name: name_check_result,
    } = local_recipient || {};

    const { name: nameR } = recipient || {};

    const recipient_name = nameR || name;

    const object = {
      ...item,
      amount,
      recipient_name,
      bank,
      notes,
      bank_account_number,
      name_check_result,
      account_number: bank_account_number,
      name: recipient_name,
    };

    const keys = Object.keys(object);

    keys.forEach((key) => {
      const value = object[key];
      const newKey = `${key}_${number}`;
      defaultValues[newKey] = value;
    });
  });

  const defaultValuesTable = array.map((item) => {
    const {
      local_recipient,
      amount,
      notes,
      beneficiary_bank_name,
      id,
      recipient,
    } = item || {};
    const {
      id: local_recipient_id,
      account_number,
      email: emailRaw,
      name,
      payer_id,
    } = local_recipient || {};
    const {
      email: emailR,
      name: nameR,
      additional_emails: additional_emailR,
    } = recipient || {};

    const emailDecider = () => {
      const email = emailR || emailRaw;
      if (!additional_emailR) return email;
      return `${email},${additional_emailR}`;
    };
    const email = emailDecider();

    const recipient_name = nameR || name;

    const result = {
      account_number,
      amount: Number(amount),
      bank: beneficiary_bank_name,
      bank_code: beneficiary_bank_name,
      email,
      name: recipient_name,
      recipient_name,
      recipient_email: email,
      local_recipient_id,
      notes,
      payer_id,
      id,
    };
    return result;
  });

  return { ...res, data: array, defaultValues, defaultValuesTable };
};
