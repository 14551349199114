import { Modal } from "reactstrap";
import styles from "./styles.module.css";

const BlockingBlurModal = ({ children = null, isOpen = false }) => {
  return (
    <Modal
      centered
      isOpen={isOpen}
      className={styles.className}
      modalClassName={styles.modalClassName}
      contentClassName={styles.contentClassName}
      backdropClassName={styles.backdropClassName}
    >
      {children}
    </Modal>
  );
};

export default BlockingBlurModal;
