import { eventsTracker } from "../../../../universalFunctions/events";
import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  INTERNATIONAL_FIFTH_TARGET,
  INTERNATIONAL_FOURTH_TARGET,
} from "../../constants";
import { DEMO_GUIDED_TOUR_BACK, DEMO_GUIDED_TOUR_NEXT } from "../../constants/events";

const locale = {
  last: <NextText>Got it</NextText>,
  close: <NextText>Got it</NextText>,
  back: <NextText>Back</NextText>,
  next: <NextText>Next</NextText>,
};

const steps = [
  {
    ...DEFAULT_PROPERTIES,
    target: `#${INTERNATIONAL_FOURTH_TARGET}`,
    content: (
      <TooltipContent
        title="Inputting additional information"
        description="Complete the following details to ensure a smooth transfer process."
      />
    ),
    // disableScrolling: false,
    locale: {
      ...locale,
      last: <NextText>Got it</NextText>,
      close: <NextText>Next</NextText>,
    },
    eventPayload: {
      activity_type: "create_international_transfer",
      guided_tour_activity: "input_additional_info",
    },
  },
  {
    ...DEFAULT_PROPERTIES,
    target: `#${INTERNATIONAL_FIFTH_TARGET}`,
    content: (
      <TooltipContent
        title="Make sure to tick this box..."
        description="To verify that the informations you’ve provided are correct and true."
      />
    ),
    disableScrolling: false,
    // disableBeacon: false,
    locale,
    placement: "left",
    eventPayload: {
      activity_type: "create_international_transfer",
      guided_tour_activity: "tick_declaration_box",
    },
  },
];

const callback = ({ data, defaultState, setGuidedTourState }) => {
  const { index, action, lifecycle, step } = data ?? {};
  const {eventPayload = {}} = step ?? {}

  const isComplete = lifecycle === "complete";

  if (!isComplete) return;

  switch (action) {
    case "close":
      eventsTracker(DEMO_GUIDED_TOUR_NEXT, eventPayload)
      if (index === 0) {
        setGuidedTourState((prev) => ({ ...prev, stepIndex: 1 }));
        break;
      }
      if (index === 1) {
        setGuidedTourState(defaultState);
        break;
      }
      break;
      
      case "prev":
      eventsTracker(DEMO_GUIDED_TOUR_BACK, eventPayload)
      if (index === 1) {
        setGuidedTourState((prev) => ({ ...prev, stepIndex: 0 }));
        break;
      }
      break;

    default:
      break;
  }
};

const internationalThirdPageProps = { steps, callback };

export default internationalThirdPageProps;
