import Skeleton from "react-loading-skeleton";
import { jackColors } from "../../../../../../assets/colors";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import { GothamMedium, GothamRegular } from "../../../../../../components/Text";
import NextImage from "../../../../../../components/NextImage";
import { useGetAuth } from "../../../../../../contexts/AuthContext";
import { formatCurrencyNoDecimal } from "../../../../../../components/tools";
import styles from "./CompanyBalanceBanner.module.css";
import { TopUpModal, useModalHook } from "../../../../../../components/Modals";

const CompanyBalanceBanner = () => {
  const { user, userLoading, refetchUser } = useGetAuth();
  const { mainBalance } = user;
  const { balance } = mainBalance;
  const companyBalance = `IDR ${formatCurrencyNoDecimal(balance)}`;

  const { isOpen: isOpenTopUpModal, toggle: toggleTopUpModal } = useModalHook();

  return (
    <div className={styles.companyBalanceBanner}>
      <div className={styles.leftSection}>
        <div className={styles.titleContainer}>
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.neutral100 }}
          >
            Company Balance
          </GothamRegular>
          <JackIcons
            name="refresh"
            fill={jackColors.neutral100}
            style={{
              scale: "0.6",
              translate: "-4px 0px",
              cursor: "pointer",
            }}
            onClick={refetchUser}
          />
        </div>
        {userLoading ? (
          <Skeleton width={180} height={14} style={{ translate: "0px -4px" }} />
        ) : (
          <GothamMedium
            style={{
              color: jackColors.neutral100,
              translate: "0px -4px",
            }}
          >
            {companyBalance}
          </GothamMedium>
        )}
      </div>
      <div className={styles.rightSection}>
        <button className={styles.topUpButton} onClick={toggleTopUpModal}>
          <JackIcons
            name="top-up"
            fill={jackColors.greenB9}
            style={{ scale: "0.6" }}
          />
          <GothamRegular
            woFontColor
            className="font12"
            style={{ color: jackColors.greenB9 }}
          >
            Top up
          </GothamRegular>
        </button>
        <div className={styles.abstractContainer}>
          <NextImage
            width={143}
            height={64}
            src="/images/company-balance-banner-abstract.png"
            alt="Abstract"
          />
        </div>
      </div>

      <TopUpModal
        paid
        back
        modal={isOpenTopUpModal}
        toggle={() => toggleTopUpModal()}
        onClickPaid={() => {
          refetchUser();
          toggleTopUpModal();
        }}
      />
    </div>
  );
};

export default CompanyBalanceBanner;
