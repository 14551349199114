import { colors } from "../../../../../assets/colors";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import { Banner } from "../../../../../components/Banner";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { TooltipError as Tooltip } from "../../../../PayrollCreateEnhancement/create/tooltip";
import { expiryStatusDecider } from "../../../tools";
import { useGetAuth } from "../../../../../contexts/AuthContext";
import { useMutation } from "../../../../../tools/api";
import { useCardDetailContext } from "../../../../../contexts/Cards/CardDetailContext";
import { eventsTracker } from "../../../../../universalFunctions/events";

const ExpiryCardInfo = ({ openMerchantLinked, cardDetail }) => {
  const { refetchCardDetail } = useCardDetailContext();
  const { expiry_status, user_id: ownerId } = cardDetail;
  const { notification_id: notifId, status: expiryStatus } =
    expiry_status || {};
  const { isNeedRenewal, isRenewAuto, isActive, isRenewed } =
    expiryStatusDecider(expiryStatus);
  const { user } = useGetAuth();
  const isOwner = user?.id === ownerId;
  if (isActive) return null;

  const bannerStyle = {
    marginTop: "-56px",
    paddingTop: "32px",
  };

  if (isRenewed) {
    const { mutation: removeNotificationMutation } = useMutation({
      url: `/notifications/read_notification/${notifId}`,
      afterSuccess: () => {
        refetchCardDetail();
        eventsTracker("card_renewal_notification_dismissed", {
          card_id: cardDetail.id,
        });
      },
    });

    const headerContainerStyle = {
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    };

    const tooltipMessageStyle = {
      color: colors.neutral800,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
    };

    const closeIconStyle = {
      cursor: "pointer",
    };

    const messageContainerStyle = {
      display: "flex",
      flexDirection: "column",
      gap: "16px",
    };

    const messageStyle = {
      color: colors.neutral800,
      fontSize: "12px",
      lineHeight: "16px",
      letterSpacing: "0.2px",
    };

    const ctaMessageStyle = {
      color: colors.neutral800,
      fontSize: "12px",
      textDecoration: "underline",
      cursor: "pointer",
    };

    return (
      <Banner
        style={bannerStyle}
        type={isRenewAuto ? "warning" : "success"}
        title={
          <div style={headerContainerStyle}>
            <GothamMedium>
              {isOwner ? "Your" : "This"} card has been
              {isRenewAuto ? " automatically" : ""} renewed!
            </GothamMedium>
            {isOwner && (
              <Tooltip
                title={
                  <GothamRegular style={tooltipMessageStyle}>
                    Don’t show this <br /> message again
                  </GothamRegular>
                }
              >
                <div
                  style={closeIconStyle}
                  onClick={() => removeNotificationMutation()}
                >
                  <JackIcons name="close" fill={colors.neutral900} />
                </div>
              </Tooltip>
            )}
          </div>
        }
        msg={
          <div style={messageContainerStyle}>
            <GothamRegular style={messageStyle}>
              {isOwner
                ? "Make sure to update the payment details on all merchants where this card is used."
                : "The cardholder must update the payment details on all merchants where this card is used."}
            </GothamRegular>
            <GothamMedium style={ctaMessageStyle} onClick={openMerchantLinked}>
              See merchants linked to card
            </GothamMedium>
          </div>
        }
        customIcon={{
          name: "card-renewal",
          fill: colors.neutral900,
        }}
        iconStyle={{
          marginTop: "-64px",
        }}
      />
    );
  }

  if (isNeedRenewal)
    return (
      <Banner
        style={bannerStyle}
        type="warning"
        title={`${isOwner ? "Your" : "This"} card is expiring soon!`}
        msg={
          isOwner
            ? "Renew your card now to get a new Expiry Date and CVV."
            : "The card should be renewed by the cardholder."
        }
        customIcon={{
          name: "card-warning",
        }}
        iconStyle={{
          marginTop: "-12px",
        }}
      />
    );

  return null;
};

export default ExpiryCardInfo;
