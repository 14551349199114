import { useRouter } from "next/router";
import { ACTIONS, EVENTS, STATUS } from "react-joyride";
import { useDemo } from "../../../../contexts/DemoContext";
import {
  NextText,
  TooltipContent,
} from "../../components/OnboardModalPopup/components";
import DEFAULT_PROPERTIES, {
  VA_FIFTH_TARGET,
  VA_FOURTH_TARGET,
  VA_SECOND_TARGET,
  VA_THIRD_TARGET,
} from "../../constants";
import { eventsTracker } from "../../../../universalFunctions/events";
import { DEMO_GUIDED_TOUR_BACK, DEMO_GUIDED_TOUR_NEXT } from "../../constants/events";

const disableBeacon = false;

const stepsDecider = (isUpload = false) => {
  const defaultSteps = [
    {
      ...DEFAULT_PROPERTIES,
      target: `#${VA_SECOND_TARGET}`,
      content: (
        <TooltipContent
          title="Select bank"
          description="Select your preferred bank."
        />
      ),
      eventPayload: {
        activity_type: "create_virtual_account",
        guided_tour_activity: "select_bank_1",
      },
    },
    {
      ...DEFAULT_PROPERTIES,
      target: `#${VA_THIRD_TARGET}`,
      content: (
        <TooltipContent
          title="Select bank"
          description="Simply type in the bank name or select from the list and click ‘Enter’ to save it."
        />
      ),
      disableBeacon,
      eventPayload: {
        activity_type: "create_virtual_account",
        guided_tour_activity: "select_bank_2",
      },
    },
    {
      ...DEFAULT_PROPERTIES,
      target: `#${VA_FOURTH_TARGET}`,
      content: (
        <TooltipContent
          title="Complete the remaining details"
          description="Make sure you’ve filled in all required fields before creating the VA."
        />
      ),
      disableBeacon,
      placement: "bottom",
      eventPayload: {
        activity_type: "create_virtual_account",
        guided_tour_activity: "complete_remaining_details",
      },
    },
    {
      ...DEFAULT_PROPERTIES,
      target: `#${VA_FIFTH_TARGET}`,
      content: (
        <TooltipContent
          title="Want to add other virtual account?"
          description="You can add an empty row or import the details from Excel file."
        />
      ),
      eventPayload: {
        activity_type: "create_virtual_account",
        guided_tour_activity: "add_more_virtual_account",
      },
      // disableBeacon: isUpload,
    },
  ];
  if (isUpload) {
    defaultSteps.splice(2, 1);
  }
  return defaultSteps;
};

const useVaThirdPageProps = ({ methods = "" }) => {
  const { push, query } = useRouter();
  const { setGuidanceState } = useDemo();
  const isUpload = methods === "upload";

  const callback = ({ data, setGuidedTourState, defaultState, stepIndex }) => {
    const { action, status, type, index, step, lifecycle } = data ?? {};
    const { eventPayload = {} } = step ?? {};
    const isCompleted = lifecycle === "complete"

    const setTourStateObject = (obj) => {
      const { isRunning = true, stepIndex } = obj ?? {};

      setGuidedTourState((prev) => ({
        ...prev,
        isRunning,
        stepIndex,
      }));
    };

    const setStateOnTimeout = (
      obj,
      timeout = 400,
      customFunction = () => {}
    ) => {
      setTimeout(() => {
        setTourStateObject(obj);
        customFunction && customFunction();
      }, timeout);
    };

    const defaultStepIndex = {
      stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
    };

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      isCompleted && eventsTracker(action === ACTIONS.PREV ? DEMO_GUIDED_TOUR_BACK : DEMO_GUIDED_TOUR_NEXT, eventPayload)
      push({
        query: {
          ...query,
          step: stepIndex || index,
          action: action === ACTIONS.PREV ? "prev" : "next",
        },
      });
      setStateOnTimeout(defaultStepIndex, 50);
    } else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      setGuidedTourState(defaultState);
      setGuidanceState((prev) => ({
        ...prev,
        isTourRunning: false,
      }));
    }
  };
  const steps = stepsDecider(isUpload);
  return { steps, callback };
};

export default useVaThirdPageProps;
