import { titleCase } from "change-case";
import { isEmpty } from "lodash";
import React, { useEffect, useRef } from "react";
import { jackColors } from "../../../assets/colors";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../Avatar";
import { useModalHook } from "../../Modals";
import { GothamMedium, GothamRegular } from "../../Text";
import { fileSizeString, stringCutter } from "../../tools";
import { Files } from "./components";
import {
  fileTypesTranslatorArrayToString,
  useSetsValueFileInput,
} from "./logics";
import { Banner } from "../../Banner";
import {
  BILL_MODULE,
  LOCAL_MODULE,
  PAYROLL_MODULE,
  REIMBURSEMENT_MODULE,
  VA_MODULE,
} from "../../../pageComponents/productDemoComponents/constants";
import { eventsTracker } from "../../../universalFunctions/events";
import { DEMO_UPLOAD_FILE } from "../../../pageComponents/productDemoComponents/constants/events";

const singleFile = (files, multiple) => {
  const defaultObj = { file: {}, hasSubmitted: false };
  const notSubmitted = Array.isArray(files);

  if (multiple || notSubmitted) return defaultObj;

  return { file: files, hasSubmitted: true };
};

const fileDecider = (module, e, uploadedFiles = []) => {
  switch (module) {
    case BILL_MODULE:
      let files = [
        {
          name: "INV123456.pdf",
          size: 1200000,
          type: "image/png",
          template_type: "rent",
        },
        {
          name: "INV234567.pdf",
          size: 1200000,
          type: "image/png",
          template_type: "equipment",
        },
      ];
      if (uploadedFiles?.length > 0) {
        let template_type = uploadedFiles.map((item) => item?.template_type);
        files = files.filter(
          (item) => !template_type?.includes(item?.template_type)
        );
      }
      return {
        dataTransfer: {
          files,
        },
      };
    case REIMBURSEMENT_MODULE:
      let reimburse_files = [
        {
          name: "redbirdtaxi.jpg",
          size: 1200000,
          type: "image/png",
          template_type: "transportation",
        },
        {
          name: "oasisdining.jpg",
          size: 1200000,
          type: "image/png",
          template_type: "meal",
        },
      ];
      if (uploadedFiles?.length > 0) {
        let uploaded = uploadedFiles.map((item) => item?.template_type);
        reimburse_files = reimburse_files.filter(
          (item) => !uploaded?.includes(item?.template_type)
        );
        console.log("reimburse_files:", reimburse_files);
      }
      return {
        dataTransfer: {
          files: reimburse_files,
        },
      };
    case PAYROLL_MODULE:
      return {
        dataTransfer: {
          files: [
            {
              name: "payroll.csv",
              size: 1200000,
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          ],
        },
      };
    case VA_MODULE:
      return {
        dataTransfer: {
          files: [
            {
              name: "virtual accounts.xlsx",
              size: 2000000,
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          ],
        },
      };
    case LOCAL_MODULE:
      return {
        dataTransfer: {
          files: [
            {
              name: "Marketing & Advertising Oct 2023.csv",
              size: 2100000,
              type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            },
          ],
        },
      };
    default:
      return e;
  }
};

const eventPayloadDecider = (module) => {
  switch (module) {
    case BILL_MODULE:
      return {};
    case REIMBURSEMENT_MODULE:
      return {
        activity_type: "create_reimbursement",
        page_title: "upload_receipt",
      };
    case PAYROLL_MODULE:
      return {};
    case VA_MODULE:
      return {};
    case LOCAL_MODULE:
      return {};
    default:
      return {};
  }
};

export const FileInputJack = ({
  useFormObj,
  name,
  label: labelProps,
  labelStyle,
  multiple = false,
  helperText = "",
  maxFiles = 5,
  maxSizeMB = 10,
  fileTypes = ["image/png", "application/pdf"],
  firstCopy = "Browse or drag files here to upload",
  showLabel = true,
  containerMaxHeight,
  customError,
  enablePreview,
  middleComponent = null,
  bottomComponent = null,
  bannerMessage = "",
  module = null,
  uploadedFiles = [],
}) => {
  const label =
    typeof labelProps == "string" ? labelProps : labelProps || titleCase(name);
  const { register, setValue, getValues, watch } = useFormObj;
  const watchFile = watch(name);

  const fileTypeString = fileTypesTranslatorArrayToString(fileTypes);

  const {
    setsValue,
    errors,
    reset,
    ref: inputRef,
  } = useSetsValueFileInput({
    fileTypes,
    maxFiles,
    maxSizeMB,
    multiple,
    name,
    useFormObj,
  });

  // const { isTooBig, isTypeNotMatch, isTooMuchFiles } = errors;

  const files = getValues()[name] || [];

  useEffect(() => {
    register({ name });
  }, []);

  useEffect(() => {
    setTimeout(() => {
      setValue(name, watchFile);
    }, 500);
  }, []);

  const { isOpen: isDragging, close: notDrag, open: drag } = useModalHook();

  const stopsPropagation = (e) => {
    if (!e) return;
    e.preventDefault();
    e.stopPropagation();
  };

  const ref = useRef();

  const height = ref?.current?.clientHeight + 8 || 0;

  const { file, hasSubmitted } = singleFile(files, multiple);

  const content = () => {
    if (hasSubmitted) {
      const { name, size } = file;
      return (
        <>
          <JackIcons
            style={{
              position: "absolute",
              right: 8,
              top: 8,
            }}
            onClick={(e) => {
              stopsPropagation(e);
              reset();
            }}
            name="close"
            fill={jackColors.grey6C}
          />

          <Avatar
            type="square"
            mainIcon={
              <JackIcons name="checkmark-square-2" fill={jackColors.greenB9} />
            }
          />
          <GothamMedium className="my-1">{stringCutter(name, 30)}</GothamMedium>
          {!!size && (
            <GothamRegular className="font12">
              {fileSizeString(size)}
            </GothamRegular>
          )}
        </>
      );
    }

    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          alignItems: "center",
        }}
      >
        <Avatar
          type="square"
          mainIcon={<JackIcons name="file-outline" fill={jackColors.greenB9} />}
        />
        <GothamMedium className="font14" style={{ color: jackColors.black34 }}>
          {firstCopy}
        </GothamMedium>
        <GothamRegular className="font12" style={{ color: jackColors.grey6C }}>
          Max. size for each file: {maxSizeMB} MB
        </GothamRegular>
        <GothamRegular
          className="font12"
          style={{
            color: jackColors.grey6C,
          }}
        >
          Supported files: {fileTypeString}
        </GothamRegular>
      </div>
    );
  };

  return (
    <div
      className="d-flex"
      style={{
        flexDirection: "column",
        position: "relative",
      }}
      onClick={() => {
        const eventPayload = eventPayloadDecider(module);
        !isEmpty(eventPayload) && eventsTracker(DEMO_UPLOAD_FILE, eventPayload);
        setsValue(fileDecider(module, null, uploadedFiles));
      }}
    >
      <div ref={ref}>
        {showLabel && (
          <GothamMedium className="mb-3" style={labelStyle}>
            {label}
          </GothamMedium>
        )}{" "}
        {!!bannerMessage && (
          <div style={{ marginTop: 16, marginBottom: 16 }}>
            <Banner msg={bannerMessage} />
          </div>
        )}
        <label
          onDragEnter={(e) => {
            stopsPropagation(e);
            drag();
          }}
          onDragLeave={(e) => {
            stopsPropagation(e);
            notDrag();
          }}
          onDragOver={(e) => {
            stopsPropagation(e);
          }}
          onDrop={(e) => {
            stopsPropagation(e);
            if (!e) return;
            e.persist();
            // setsValue(e);
            const eventPayload = eventPayloadDecider(module);
            !isEmpty(eventPayload) &&
              eventsTracker(DEMO_UPLOAD_FILE, eventPayload);
            setsValue(fileDecider(module, e, uploadedFiles));
          }}
          style={{
            flexDirection: "column",
            minHeight: 144,
            border: "2px dashed #6C6C71",
            borderRadius: 8,
            backgroundColor: isDragging ? jackColors.greyF1 : "",
            position: "relative",
            marginBottom: 0,
          }}
          className="d-flex align-items-center justify-content-center hover"
        >
          {content()}
          {/* <input
            type="file"
            style={{ display: "none" }}
            multiple={multiple}
            ref={inputRef}
            onChange={(e) => {
              if (!e) return;
              setsValue(fileDecider(module));
            }}
          /> */}
        </label>
      </div>
      <GothamRegular
        className="font10"
        style={{ color: jackColors.grey90, position: "absolute", top: height }}
      >
        {helperText}
      </GothamRegular>
      {middleComponent}
      <Files
        files={files}
        multiple={multiple}
        inputRef={inputRef}
        name={name}
        setValue={setValue}
        containerMaxHeight={containerMaxHeight}
        customError={customError}
        enablePreview={enablePreview}
      />
      {bottomComponent}
    </div>
  );
};
