import { LeftRightList } from "../../../../../components/Lists";
import { GothamMedium, GothamRegular } from "../../../../../components/Text";
import { SeeMore } from "../../../../../components/SeeMore";
import { colors } from "../../../../../assets/colors";
import { formatCurrencyNoDecimal } from "../../../../../components/tools";
import { isEmpty } from "lodash";
import { JackIcons } from "../../../../../assets/jackIcons/parent";
import ParserRichTextArea from "../../../../../components/ParserRichTextArea";
import { Avatar, NameAvatar } from "../../../../../components/Avatar";

const CardMoveBalanceInfo = ({ cardDetail }) => {
  const {
    current_workflow_process: currentWorkflowProcessType,
    get_approval_tasks: getApprovalTasks,
    workflow_process: workflowProcess,
  } = cardDetail || {};

  const isRequestTopUpMainBalance =
    currentWorkflowProcessType === "request_withdraw_balance_company";
  const isRequestWithdrawMainBalance =
    currentWorkflowProcessType === "request_topup_balance_company";
  const isRequestMoveBalanceToCard =
    currentWorkflowProcessType === "request_move_balance";
  const isMoveBalanceRequest =
    isRequestTopUpMainBalance ||
    isRequestWithdrawMainBalance ||
    isRequestMoveBalanceToCard;

  const isHaveApprovalTasks = !isEmpty(getApprovalTasks);

  if (!isMoveBalanceRequest || !isHaveApprovalTasks) return null;

  const currentWorkflowProcess = workflowProcess?.find(
    ({ type }) => type === currentWorkflowProcessType
  );

  const { additional_data: additionalData } = currentWorkflowProcess || {};
  const {
    reason,
    amount,
    target_card: targetCard,
    requester_name: requesterName,
  } = additionalData || {};
  const {
    name: targetCardName,
    masked_card_number: maskedCardNumber,
    user_name: targetUserName,
  } = targetCard || {};

  const titleListStyle = {
    color: colors.neutral900,
    marginBottom: "16px",
  };

  const reasonStyle = {
    color: colors.neutral900,
    fontSize: "12px",
    letterSpacing: "0.2px",
  };

  const styleRight = {
    textAlign: "left",
    marginLeft: "60px",
  };

  const rowContainerStyle = {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  };

  const rowTextStyle = {
    fontSize: "12px",
    marginBottom: "2px",
    color: colors.neutral900,
  };

  const titleDecider = () => {
    if (isRequestTopUpMainBalance) return "Top up has been requested!";
    if (isRequestWithdrawMainBalance)
      return "Return balance to company requested!";
    if (isRequestMoveBalanceToCard) return "Move balance has been requested!";
  };

  const title = titleDecider();

  const leftTextDecider = () => {
    if (isRequestTopUpMainBalance) return "Requested amount";
    if (isRequestWithdrawMainBalance) return "Returned balance";
    if (isRequestMoveBalanceToCard) return "Moved balance";
  };

  const listDecider = () => {
    const array = [];
    array.push({
      left: leftTextDecider(),
      right: `IDR ${formatCurrencyNoDecimal(amount)}`,
      rightBold: true,
      styleRight,
    });
    if (isRequestMoveBalanceToCard) {
      const maskedNumberWithName = `**${maskedCardNumber} - ${targetCardName}`;
      array.push({
        left: "To",
        right: (
          <div style={rowContainerStyle}>
            <JackIcons name="mastercard" />
            <div>
              <GothamRegular style={rowTextStyle}>
                {targetUserName}
              </GothamRegular>
              <GothamRegular
                style={{
                  color: colors.neutral700,
                  marginTop: "-4px",
                  fontSize: "10px",
                }}
              >
                {maskedNumberWithName}
              </GothamRegular>
            </div>
          </div>
        ),
        styleRight,
      });
    }
    if (isMoveBalanceRequest && requesterName) {
      array.push({
        left: "Requester",
        right: (
          <div
            style={{
              ...rowContainerStyle,
              marginLeft: "34px",
            }}
          >
            <Avatar name={requesterName} size="small" />
            <GothamRegular style={rowTextStyle}>{requesterName}</GothamRegular>
          </div>
        ),
      });
    }
    array.push({
      left: "Reason",
      right: (
        <GothamRegular style={reasonStyle}>
          <SeeMore buttonAlign="left" height={80} width={178}>
            <ParserRichTextArea text={reason} />
          </SeeMore>
        </GothamRegular>
      ),
      styleRight,
    });
    return array;
  };

  const list = listDecider();

  return (
    <LeftRightList
      topComponent={<GothamMedium style={titleListStyle}>{title}</GothamMedium>}
      array={list}
    />
  );
};

export default CardMoveBalanceInfo;
