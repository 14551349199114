import { useForm } from "react-hook-form";
import {
  CurrencyFieldJack,
  TextFieldJack,
} from "../../../../../../components/inputs/textfield";
import TitleIcon from "../../components/TitleIcon";
import { unformatCurrency } from "../../../../../../components/tools";
import { colors } from "../../../../../../assets/colors";
import { ButtonJack } from "../../../../../../components/ButtonsJack/parent";
import { JackIcons } from "../../../../../../assets/jackIcons/parent";
import CardInfoBalance from "../../components/CardInfoBalance";
import { GothamRegular } from "../../../../../../components/Text";
import BottomContainer from "../../components/BottomContainer";

const PrepaidMoveToCompanyForm = ({
  cardDetail,
  setCurrentScreen,
  setMoveToCompanyScreen,
  setResult,
}) => {
  const useFormObj = useForm();
  const { getValues, handleSubmit } = useFormObj;
  const values = getValues();
  const { returned_amount: returnedAmount, reason } = values;
  const { balance } = cardDetail;
  const returnedAmountNumber = +unformatCurrency(returnedAmount);
  const balanceAmountNumber = +unformatCurrency(balance);
  const isAmountValid =
    returnedAmountNumber <= balanceAmountNumber && returnedAmountNumber > 0;
  const isButtonDisabled = !reason || !isAmountValid;

  const backToDetailsHandler = () => setCurrentScreen("index");
  const backHandler = () => setCurrentScreen("prepaidMoveBalanceMethod");
  const onSubmit = (data) => {
    setResult(data);
    setMoveToCompanyScreen("summary");
  };

  const styles = {
    backButton: {
      width: "fit-content",
    },
    textInput: {
      marginBottom: "0px",
      whiteSpace: "pre-wrap",
      wordWrap: "break-word",
      overflowWrap: "break-word",
    },
    reasonInput: {
      height: "60px",
    },
    bottomContainer: {
      borderTop: `1px solid ${colors.neutral500}`,
      backgroundColor: colors.neutral100,
      padding: "20px",
      left: "0px",
      width: "100%",
      display: "flex",
      gap: "16px",
      position: "fixed",
    },
    button: {
      width: "50%",
    },
  };

  return (
    <>
      <ButtonJack
        leftIcon={
          <JackIcons name="cornerUpLeftOutline" fill={colors.neutral900} />
        }
        type="outline"
        style={styles.backButton}
        onClick={backToDetailsHandler}
      >
        Go to Card Details
      </ButtonJack>
      <TitleIcon title="Return balance to company" icon="resend-callback" />
      <CardInfoBalance cardDetail={cardDetail} text="From :" />
      <CurrencyFieldJack
        name="returned_amount"
        label="Amount to be returned"
        useFormObj={useFormObj}
        style={styles.textInput}
        icon={
          <GothamRegular
            style={{
              color: colors.neutral600,
            }}
          >
            IDR
          </GothamRegular>
        }
        required
      />
      <TextFieldJack
        name="reason"
        useFormObj={useFormObj}
        style={styles.textInput}
        textFieldStyle={styles.reasonInput}
        required
      />
      <BottomContainer style={styles.bottomContainer}>
        <ButtonJack
          type="outline"
          onClick={backHandler}
          style={styles.button}
          leftIcon={
            <JackIcons name="arrow-back-outline" fill={colors.neutral900} />
          }
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={styles.button}
          onClick={handleSubmit(onSubmit)}
          disabled={isButtonDisabled}
        >
          Continue
        </ButtonJack>
      </BottomContainer>
    </>
  );
};

export default PrepaidMoveToCompanyForm;
