export const batchname = "Marketing & Advertising Oct 2023"

export const recipientData = [
  {
    name: "name",
    value: "Anisya Azni Amara",
    label: "Name",
    invalid: false,
  },
  0,
  2,
];

export const recipientName = recipientData[0]?.value;

export const emptyData = [
  {
    name: "delete",
  },
  {
    name: "number",
  },
  {
    name: "name",
    value: "",
    label: "Name",
    invalid: false,
  },
  {
    name: "bank",
    value: "",
    label: "Bank",
  },
  {
    name: "account_number",
    value: "",
    label: "Bank Account Number",
  },
  {
    name: "notes",
    value: "",
    label: "Notes",
  },
  {
    name: "email",
    value: "",
    label: "Email",
  },
  {
    name: "amount",
    value: "",
    label: "Amount",
  },
  {
    name: "action",
  },
];

export const prefilledData = [
  {
    name: "delete",
  },
  {
    name: "number",
  },
  {
    name: "name",
    value: "Maria Angelika",
    label: "Name",
    invalid: false,
  },
  {
    name: "bank",
    value: "danamon",
    label: "Bank",
    id: 7,
    details: {
      id: 7,
      name: "danamon",
      full_name: "PT. BANK DANAMON INDONESIA TBK.",
      alias: "Bank Danamon",
      value: "danamon",
      bank_name: "PT. BANK DANAMON INDONESIA TBK.",
      label: "BANK DANAMON",
    },
    invalid: false,
    duplicate: false,
  },
  {
    name: "account_number",
    value: "111122223333",
    label: "Bank Account Number",
    invalid: false,
    duplicate: false,
  },
  {
    name: "notes",
    value: "KOL payment",
    label: "Notes",
    invalid: false,
  },
  {
    name: "email",
    value: "mariaangelika@dummyemail.com",
    label: "Email",
    invalid: false,
  },
  {
    name: "amount",
    value: "15,000,000",
    label: "Amount",
    invalid: false,
  },
  {
    name: "action",
  },
];

const constants = { recipientData, emptyData, prefilledData };

export default constants;
