import { Modal } from "reactstrap";
import { JackIcons } from "../../assets/jackIcons/parent";
import { jackColors } from "../../assets/colors";
import { ButtonJack } from "../../components/ButtonsJack/parent";
import styles from "./SimpleCenteredModal.module.css";

const SimpleCenteredModal = ({
  isOpen = false,
  woClose = false,
  isHidden = false,
  isLoading = false,
  isDisabled = false,
  children = null,
  customButtons = null,
  modalClassName = null,
  leftHeaderComponent = null,
  centeredHeaderComponent = null,
  buttonText = "Okay",
  toggle = () => {},
  onClick = () => {},
}) => {
  const contentClassName = modalClassName ?? styles["simple-centered-modal"];

  const buttons = customButtons ?? (
    <ButtonJack
      disabled={isDisabled}
      onClick={onClick}
      isLoading={isLoading}
      style={{ width: "100%" }}
    >
      {buttonText}
    </ButtonJack>
  );

  return (
    <Modal
      centered
      hidden={isHidden}
      isOpen={isOpen}
      contentClassName={contentClassName}
      toggle={toggle}
    >
      <div className={styles["modal-header"]}>
        {leftHeaderComponent || <div />}
        {!leftHeaderComponent && centeredHeaderComponent}
        {!woClose && (
          <button className={styles["close-btn-container"]} onClick={toggle}>
            <JackIcons name="close-outline" fill={jackColors.neutral900} />
          </button>
        )}
      </div>
      {children}
      <div className={styles["modal-footer"]}>{buttons}</div>
    </Modal>
  );
};

export default SimpleCenteredModal;
