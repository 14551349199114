import { isToday } from "date-fns";
import { isArray, isEmpty, isEqual, uniqueId } from "lodash";
import { useRouter } from "next/router";
import React, { Fragment, useEffect, useRef, useState } from "react";
import { useForm } from "react-hook-form";
import ViewportList from "react-viewport-list";
import { jackColors } from "../../../assets/colors";
import backModalImg from "../../../assets/images/back-previous-page.svg";
import deleteModalImg from "../../../assets/images/delete-trash-grey.svg";
import emptySearchImg from "../../../assets/images/empty-state-search.svg";
import { JackIcons } from "../../../assets/jackIcons/parent";
import { Avatar } from "../../../components/Avatar";
import { ConfirmationModalJack } from "../../../components/ButtonsJack/confirmation";
import { ButtonJack } from "../../../components/ButtonsJack/parent";
import { CheckBox } from "../../../components/Checkbox";
import { useModalHook } from "../../../components/Modals";
import { ProgressBarJack } from "../../../components/Stepper";
import { SummarySheet } from "../../../components/SummarySheet/parent";
import TabMenu from "../../../components/TabMenu/parent";
import { TableJackSimple } from "../../../components/Table/parent";
import {
  GothamLight,
  GothamMedium,
  GothamRegular,
} from "../../../components/Text";
import { TransitionTr } from "../../../components/Transition";
import { MinimalistSelect } from "../../../components/inputs";
import { DateInputJackState } from "../../../components/inputs/date/parent";
import { SelectionJackState } from "../../../components/inputs/selection";
import { TextFieldJackState } from "../../../components/inputs/textfield";
import {
  applyRegexOnlyNumbers,
  combineDateAndTimeToIso,
  formatCurrencyNoDecimal,
} from "../../../components/tools";
import { useConstants } from "../../../contexts/ConstantsContext/parent";
import { useDemo } from "../../../contexts/DemoContext";
import { useGuidedTour } from "../../../contexts/GuidedTourContext";
import { useHeader } from "../../../contexts/Layout/parent";
import { ToasterHook } from "../../../contexts/ToasterContext";
import { downloadApi, useMutation } from "../../../tools/api";
import { useClickOutside } from "../../../universalFunctions/useClickOutside";
import { useCustomKeypress } from "../../../universalFunctions/useEscapeKeypress";
import { SubTextComponent } from "../../bankAccountSettingComponents/tools/helpers";
import { ImportRecipientCSV } from "../../localTransferEnhancement/create/second";
import useGuidedTourBuilder from "../../productDemoComponents/builders";
import CreateTransactionsOnboardModal from "../../productDemoComponents/components/OnboardModalPopup";
import {
  VA_FIFTH_TARGET,
  VA_FIRST_PAGE,
  VA_FIRST_TARGET,
  VA_FOURTH_PAGE,
  VA_FOURTH_TARGET,
  VA_MODULE,
  VA_SECOND_PAGE,
  VA_SECOND_TARGET,
  VA_THIRD_PAGE,
  VA_THIRD_TARGET,
} from "../../productDemoComponents/constants";
import {
  dummyDataVa,
  emptyDataWithBankVa,
  emptyDummyDataVa,
  vaDummyBankName,
} from "../../productDemoComponents/constants/va";
import { TransactionsFilters } from "../../transactionsComponents/transactions/filters/parent";
import EmptyList from "../common/emptyList";
import { useBankDetails } from "../common/hooks";
import {
  HoverComponent,
  TypeLabel,
  expiryDateDecider,
} from "../common/tableItem";
import UploadCsvModal from "../common/uploadCsvModal";
import {
  font12,
  font14,
  font24,
  icon20,
  icon24,
  textEllipsis,
  vaFormatter,
} from "../common/utils";
import styles from "../va.module.css";
import { eventsTracker } from "../../../universalFunctions/events";
import {
  DEMO_ADD_EMPTY_ROW,
  DEMO_CANCEL_CLOSE_PAGE,
  DEMO_CHOOSE_EMPTY_TABLE,
  DEMO_CHOOSE_EXCEL_TEMPLATE,
  DEMO_CLICK_BACK,
  DEMO_CLICK_NEXT,
  DEMO_CLOSE_PAGE,
  DEMO_CONFIRM_CLOSE_PAGE,
  DEMO_DOWNLOAD_TEMPLATE,
  DEMO_IMPORT_EXCEL,
  DEMO_PAGE_VIEW,
  DEMO_POPUP_REGISTER,
} from "../../productDemoComponents/constants/events";
import DemoGeneralModal from "../../productDemoComponents/components/DemoGeneralModal";

export const stepArrayCustom = ({ index, stepArray = [] }) => {
  return stepArray.map((item, indexChild) => ({
    ...item,
    isActive: index == indexChild,
  }));
};

const defaultIgnore = [
  "can_set_due_date",
  "is_complete",
  "is_duplicated_reference",
  "is_ref_already_used",
  "temp_id",
];

const shouldIgnore = ({ item, ignore = [] }) => {
  let isDataFilled = false;
  const finalIgnore = [...defaultIgnore, ...ignore];

  Object.keys(item).forEach((key) => {
    if (finalIgnore.includes(key)) return;
    if (key == "amount" && item[key] == 0) return;
    if (item[key] !== "") {
      isDataFilled = true;
      return;
    }
  });
  return isDataFilled;
};

const CreateVirtualAccountComponent = () => {
  // HOOKS
  const [screen, setScreen] = useState("choose");
  const [defaultValue, setDefaultValue] = useState([]);
  const { setHeader } = useHeader();

  const { push, query } = useRouter();
  const useFormObj = useForm();
  const { successSnackBar } = ToasterHook();
  const { isOpen, toggle } = useModalHook();
  // const { isAdminOrSuperAdmin } = getUserRole();

  // VARS
  const { watch, handleSubmit, reset } = useFormObj;
  const isChoose = screen === "choose";
  const isUpload = screen === "upload";
  const isConfirm = screen === "confirm";
  const { state, type } = query;

  const isImport = isConfirm && type === "import";
  const isInput = isConfirm && type === "input";

  const arrayUploadDetails = {
    title1: "1. Download this template",
    desc1:
      "Download and fill the virtual account details according to this template.",
    title2: "2. Upload the filled template",
    desc2:
      "Upload the filled Excel/XLSX to start creating your virtual account.",
  };

  // FUNCTIONS
  const handleClick = ({ type, value }) => {
    const isNext = type === "next";
    const isBackNoValue = type === "back" && !value;
    const isToChoose = type === "to_choose";
    const isCSV = isNext && value === "upload_csv";
    const isEmptyTable = isNext && value === "empty_table";

    if (isBackNoValue) return push("/virtual-account");
    if (isToChoose) {
      eventsTracker(DEMO_CLICK_BACK, {
        activity_type: "create_virtual_account",
        page_title: "upload_virtual_account_excel",
      });
      return setScreen("choose");
    }
    if (isCSV || isEmptyTable) {
      eventsTracker(DEMO_CLICK_NEXT, {
        activity_type: "create_virtual_account",
        page_title: "select_method",
      });
    }
    if (isCSV) {
      eventsTracker(DEMO_CHOOSE_EXCEL_TEMPLATE, {
        activity_type: "create_virtual_account",
        page_title: "select_method",
      });
      return setScreen("upload");
    }
    if (isEmptyTable) {
      eventsTracker(DEMO_CHOOSE_EMPTY_TABLE, {
        activity_type: "create_virtual_account",
        page_title: "select_method",
      });
      return push({ query: { state: "create", type: "input" } });
    }
  };

  // function to decide events "page_title" payload
  const page_title = (function () {
    switch (screen) {
      case "choose":
        return "select_method";
      case "upload":
        return "upload_virtual_account_excel";
      case "confirm":
        return "input_and_confirm";
      default:
        break;
    }
  })();

  const handleClose = () => {
    eventsTracker(DEMO_CLOSE_PAGE, {
      activity_type: "create_virtual_account",
      page_title,
    });
    if (isConfirm) return toggle();
    push("/virtual-account");
  };

  const stepDecider = () => {
    const chooseArray = [
      { step: "Select Method" },
      { step: "Input & Confirm" },
    ];

    const uploadArray = [
      { step: "Select Method" },
      { step: "Upload XLSX" },
      { step: "Confirm Details" },
    ];

    if (isInput) return { length: 300, array: chooseArray, index: 1 };
    if (isImport) return { length: 250, array: uploadArray, index: 2 };
    if (isChoose) return { length: 300, array: chooseArray, index: 0 };
    if (isUpload) return { length: 250, array: uploadArray, index: 1 };
    return { length: 300, array: chooseArray, index: 1 };
  };

  // TEMPLATE RELATED
  const file = watch("upload_file");
  const isDisabled = !file;

  const onClickDownload = () => {
    eventsTracker(DEMO_DOWNLOAD_TEMPLATE, {
      activity_type: "create_virtual_account",
      page_title: "upload_virtual_account_excel",
    });
    eventsTracker(DEMO_POPUP_REGISTER, {
      page_title: "create_virtual_account",
    });
    toggleRestrict();

    // downloadApi({
    //   url: "/collection_virtual_accounts/template_va_import?format_template=excel",
    //   type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   fileName: "Jack-Template-Virtual-Account.xlsx",
    //   afterSuccess,
    // });
  };

  const afterSuccess = () => {
    successSnackBar({
      msg: "Template has been downloaded.",
      showClose: true,
    });
  };

  // UPLOAD RELATED
  const {
    mutation,
    result: tableData,
    loading: loadingImport,
  } = useMutation({
    url: "/collection_virtual_accounts/import",
    method: "post",
    defaultValue: [],
    resultFormatter: (data) => data?.data?.data,
    afterSuccess: (res) => {
      eventsTracker(DEMO_CLICK_NEXT, {
        activity_type: "create_virtual_account",
        page_title: "upload_virtual_account_excel",
      });
      setDefaultValue(res);
      push({ query: { state: "create", type: "import" } });
    },
  });

  // EFFECTS
  useEffect(() => {
    const { length, array, index } = stepDecider();
    if (screen === "loading_import") return;
    setHeader({
      type: "close",
      onCloseProps: () => handleClose(),
      onClick: (value) => value,
      middleComponent: (
        <ProgressBarJack
          customLineLength={length}
          arrayStep={stepArrayCustom({
            stepArray: array,
            index: index,
          })}
        />
      ),
    });
  }, [screen, state]);

  useEffect(() => {
    eventsTracker(DEMO_PAGE_VIEW, {
      activity_type: "create_virtual_account",
      page_title,
    });
    if (screen !== "choose") return;
    reset();
    setDefaultValue([]);
    push({ query: "" });
  }, [screen]);

  useEffect(() => {
    if (!state) return;
    if (!type) return;
    setScreen("confirm");
  }, [state]);

  useEffect(() => {
    if (!isImport) return;
    if (!isEmpty(defaultValue)) return;
    setScreen("choose");
    push({ query: "" });
  }, [isImport]);

  // useEffect(() => {
  //   if (isAdminOrSuperAdmin) return;
  //   return push("/dashboard");
  // }, []);

  const onSubmit = ({ values, type }) => {
    const isUploadCSV = type == "upload_csv";

    if (isUploadCSV) {
      const { upload_file } = values || {};
      let formData = new FormData();
      formData.append("file", upload_file);
      formData.append("import_type", "not_create");
      return mutation(formData);
    }
  };

  const onSubmitCSV = (values) => onSubmit({ type: "upload_csv", values });

  // demo purpose
  const { isOpen: isOpenRestrict, toggle: toggleRestrict } = useModalHook();
  const [isFirstRender, setIsFirstRender] = useState(true);
  const guidedTourPage = isChoose
    ? VA_FIRST_PAGE
    : isUpload
    ? VA_SECOND_PAGE
    : VA_THIRD_PAGE;

  const guidedTourProps = useGuidedTourBuilder({
    module: VA_MODULE,
    page: guidedTourPage,
    methods: isImport ? "upload" : "",
  });

  const { startTour, stopTour } = useGuidedTour({
    ...guidedTourProps,
    props: {
      scrollOffset: 300,
      continuous: guidedTourProps?.steps?.length > 1,
    },
    stepIndex: 0,
  });
  const { setGuidanceState, guidanceState } = useDemo();
  const { isTourRunning, isGuidanceMode, guided = false } = guidanceState ?? {};

  const handleGuideMe = () => {
    startTour(); // to start the tour
    setGuidanceState((prev) => ({ ...prev, isGuidanceMode: true }));
  };

  const handleExplore = () =>
    setGuidanceState((prev) => ({ ...prev, isGuidanceMode: false }));

  useEffect(() => {
    return setGuidanceState((prev) => ({
      ...prev,
      guidanceFunction: isTourRunning ? stopTour : startTour,
      eventPayload: {
        activity_type: "create_virtual_account",
        page_title,
      },
    }));
  }, [isTourRunning, screen]);

  useEffect(() => {
    if (isFirstRender) return setIsFirstRender(false);
  }, []);

  useEffect(() => {
    if (!isGuidanceMode) return;
    if (isUpload || isConfirm) {
      return handleGuideMe();
    }
  }, [isGuidanceMode, screen]);

  useEffect(() => {
    if (isTourRunning) return;
    delete query?.step;
    delete query?.action;
    push({ query });
  }, [isTourRunning]);

  useEffect(() => {
    if (!guided) return;
    setTimeout(() => {
      handleGuideMe();
    }, 500);
  }, [guided]);

  useEffect(() => {
    return () => {
      stopTour(); // this is necessary to prevent a clashing tour (if user presses back in the middle of a running tour)
    };
  }, []);

  if (isConfirm)
    return (
      <>
        <CreateVaConfirmationPage
          defaultValue={defaultValue}
          isInput={isInput}
          reset={reset}
          screen={screen}
          setScreen={setScreen}
        />
        <ConfirmationModalJack
          title="Go back to the previous page?"
          text="By quitting, you will lose all virtual account data that you’ve filled here."
          buttonTextRight="Yes, remove"
          buttonTextLeft="Keep editing"
          img={backModalImg}
          modal={isOpen}
          onClickLeft={() => {
            if (isOpen)
              eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
                activity_type: "create_virtual_account",
                page_title: "input_and_confirm",
              });
            toggle();
          }}
          onClickClose={() => {
            if (isOpen)
              eventsTracker(DEMO_CANCEL_CLOSE_PAGE, {
                activity_type: "create_virtual_account",
                page_title: "input_and_confirm",
              });
          }}
          toggle={toggle}
          onClick={() => {
            eventsTracker(DEMO_CONFIRM_CLOSE_PAGE, {
              activity_type: "create_virtual_account",
              page_title: "input_and_confirm",
            });
            push("/virtual-account");
          }}
        />
      </>
    );
  if (isChoose)
    return (
      <Fragment>
        <CreateVaFirstStep handleClick={handleClick} />;
        {!isFirstRender && (
          <CreateTransactionsOnboardModal
            module={VA_MODULE}
            onClick={handleGuideMe}
            onExplore={handleExplore}
          />
        )}
      </Fragment>
    );
  if (isUpload)
    return (
      <Fragment>
        <ImportRecipientCSV
          id={VA_FIFTH_TARGET}
          title="Upload your virtual accounts"
          useFormObj={useFormObj}
          onClick={handleSubmit(onSubmitCSV)}
          handleClick={handleClick}
          onClickDownload={onClickDownload}
          isDisabled={isDisabled}
          arrayDetails={arrayUploadDetails}
          loading={loadingImport}
          module={VA_MODULE}
        />
        <DemoGeneralModal
          isOpen={isOpenRestrict}
          toggle={toggleRestrict}
          pageTitle="create_virtual_account"
        />
      </Fragment>
    );
};
export default CreateVirtualAccountComponent;

export const CreateVaConfirmationPage = ({
  defaultValue = [],
  isInput,
  reset,
  setScreen,
}) => {
  const [currentData, setCurrentData] = useState([]);
  const [checkDuplicate, setCheckDuplicate] = useState(false);

  const duplicatedData = (currentData || []).filter(
    ({ is_duplicated_reference, is_ref_already_used }) =>
      is_duplicated_reference || is_ref_already_used
  );

  const isDuplicateExists = !!duplicatedData.length;

  const createPageTitle = isInput
    ? "Input and confirm your virtual accounts"
    : "Review and confirm your virtual accounts";

  useEffect(() => {
    if (isEmpty(defaultValue)) return;
    setCurrentData((prev) => [...prev, ...defaultValue]);
  }, [defaultValue]);

  useDuplicateCheck({
    currentData,
    checkDuplicate,
    setCurrentData,
    setCheckDuplicate,
  });

  // demo purpose
  const {
    guidanceState: { isGuidanceMode, isTourRunning },
  } = useDemo();
  const { query } = useRouter();

  const dataExceptBank = currentData?.filter((item) => {
    if (shouldIgnore({ item })) {
      return item;
    }
  });
  const isEmptyFirstCase = dataExceptBank?.length == 0;
  const lastRowData = currentData?.[currentData?.length - 1];
  const firstRowData = currentData?.[0];
  const dataToUse = currentData?.length > 1 ? lastRowData : firstRowData; // due to adding new row in ascending order
  const dummyObj = dummyDataVa[0];

  const removeKeys = (obj, keysToRemove) => {
    const newObj = { ...obj };
    for (const key of keysToRemove) {
      delete newObj[key];
    }
    return newObj;
  };

  const cleanData = removeKeys(dataToUse, defaultIgnore); // data without keys to ignore
  const cleanDummy = removeKeys(dummyObj, defaultIgnore);

  const isEqualDummyAndData = isEqual(cleanData, cleanDummy);

  useEffect(() => {
    if (!isGuidanceMode && !isTourRunning) return;
    if (!isInput) return;
    if (isTourRunning && isEmptyFirstCase) {
      setCurrentData((prev) => [...dummyDataVa, ...prev.slice(1)]);
    }
  }, [isInput, isGuidanceMode, currentData, isTourRunning]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: 32,
      }}
    >
      <div style={{ padding: "0px 32px" }}>
        <GothamMedium style={{ ...font24 }}>{createPageTitle}</GothamMedium>
      </div>
      {/* duplicate validation dicopot for demo purpose */}
      {/* {isDuplicateExists && (
        <div style={{ padding: "0px 32px" }}>
          <StatusBannerDecider
            isWarning
            text={
              <SubTextComponent
                text={`You have ${
                  duplicatedData.length || 0
                } duplicated virtual accounts`}
                subText={`We detected virtual accounts with the same reference ID. Please review and edit it before continuing.`}
              />
            }
            customBannerStyle={{
              height: "fit-content",
              alignItems: "flex-start",
            }}
          />
        </div>
      )} */}
      <TabMenu
        style={{ paddingLeft: "32px" }}
        menus={[
          {
            screenName: "All Virtual Account",
            screen: (
              <CreateVaConfirmTable
                currentData={currentData}
                isInput={isInput}
                setCurrentData={setCurrentData}
                setCheckDuplicate={setCheckDuplicate}
                isDuplicateExists={isDuplicateExists}
                reset={reset}
                setScreen={setScreen}
                isEmptyFirstCase={isEmptyFirstCase}
              />
            ),
          },
          // isDuplicateExists && {
          //   screenName: "Duplicated",
          //   screen: (
          //     <CreateVaConfirmTable
          //       currentData={duplicatedData}
          //       setCurrentData={setCurrentData}
          //       setCheckDuplicate={setCheckDuplicate}
          //       isDuplicateExists={isDuplicateExists}
          //       setScreen={setScreen}
          //     />
          //   ),
          //   number: duplicatedData.length,
          //   numberStyle: { backgroundColor: "#F26727" },
          // },
        ]}
      />
    </div>
  );
};

export const CreateVaConfirmTable = ({
  currentData = [],
  isInput,
  isDuplicateExists = false,
  setCurrentData,
  setCheckDuplicate = () => {},
  setScreen,
  isEmptyFirstCase = false,
}) => {
  const [dataMode, setDataMode] = useState("current");
  const { push, query } = useRouter();
  const { runQuery, searchbox, menu } = query;

  const { successSnackBar } = ToasterHook();

  const { isOpen: isUploadModalOpen, toggle: uploadModalToggle } =
    useModalHook();

  const { isOpen: isConfirmModalOpen, toggle: toggleConfirmModal } =
    useModalHook();

  const {
    vaBankOptions: bankOptions,
    loadingVaBankOptions,
    refetchVaBankOptions,
    refetchVaReferenceIds,
  } = useConstants();

  const incompleteCount =
    currentData.filter(({ is_complete }) => !is_complete)?.length || 0;

  const incompleteItemExists = incompleteCount > 0;
  const isFiltered = dataMode === "filtered";
  const duplicatePage = menu === "duplicated";

  const filteredData = currentData?.filter(
    ({ username_display, reference_id }) =>
      username_display?.toLowerCase().includes(searchbox) ||
      reference_id?.toLowerCase().includes(searchbox)
  );

  const filteredDataEmpty = isFiltered && (filteredData || []).length < 1;

  const {
    mutation: mutationCreateVa,
    result,
    loading: loadingCreate,
  } = useMutation({
    url: "/collection_virtual_accounts/bulk_create",
    method: "post",
    resultFormatter: (data) => data?.data?.data,
    afterSuccess: (res) => {
      const createdCount = isArray(res) ? res.length : 1;
      const successMsg =
        createdCount > 1
          ? `${createdCount} Virtual Accounts have been created!`
          : "1 Virtual Account has been created!";
      successSnackBar({ msg: successMsg });
      refetchVaReferenceIds();
      push({
        pathname: "/virtual-account",
        query: { menu: "created", showPopup: true },
      });
      // push("/virtual-account?menu=created&showPopup=true")
    },
  });

  const handleCreate = (data, type) => {
    const dataFormatted = data?.map((item) => {
      const code = bankOptions.find(({ value }) => value === item.bank)?.code;
      return { ...item, code };
    });

    if (type === "bulk") {
      const payload = {
        virtual_accounts: dataFormatted,
      };

      mutationCreateVa(payload);
    }
  };

  const emptyRowObj = {
    reference_id: "",
    bank: "",
    code: "",
    username_display: "",
    amount: "",
    usage_limit: "",
    expiration_date: "",
    trx_expiration_date: "",
    is_lifetime: "",
    is_open: "",
  };

  const handleAddRow = () => {
    eventsTracker(DEMO_ADD_EMPTY_ROW, {
      activity_type: "create_virtual_account",
      page_title: "input_and_confirm",
    });
    setCurrentData((prev) => {
      return [emptyRowObj, ...prev];
    });
  };

  const handleBack = () => {
    setScreen("choose");
  };

  const filterComponent = () => (
    <div
      style={{
        padding: "0px 32px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <TransactionsFilters activeFilters={[]} filters={[]} />
      {!duplicatePage && (
        <div id={VA_FIFTH_TARGET} style={{ display: "flex", gap: 8 }}>
          <ButtonJack
            style={{ padding: 6 }}
            type="outline"
            onClick={() => {
              eventsTracker(DEMO_IMPORT_EXCEL, {
                activity_type: "create_virtual_account",
                page_title: "input_and_confirm",
              });
              uploadModalToggle();
            }}
            leftIcon={
              <JackIcons
                name="addchart"
                fill={jackColors.neutral900}
                style={icon20}
              />
            }
          >
            Import XLSX
          </ButtonJack>
          <ButtonJack
            style={{ padding: 6 }}
            type="outline"
            leftIcon={
              <JackIcons
                name="plus-outline"
                fill={jackColors.neutral900}
                style={icon20}
              />
            }
            onClick={handleAddRow}
          >
            Add Empty Row
          </ButtonJack>
        </div>
      )}
      <UploadCsvModal
        isOpen={isUploadModalOpen}
        toggle={uploadModalToggle}
        setCurrentData={setCurrentData}
        setCheckDuplicate={setCheckDuplicate}
      />
    </div>
  );

  const tbodyRef = useRef();

  const headerTable = [
    {
      label: "Reference ID (Optional)",
      width: 160,
      textAlign: "left",
    },
    {
      label: "Bank Name",
      width: 140,
      textAlign: "left",
    },
    {
      label: "Display Name",
      width: 184,
      textAlign: "left",
    },
    {
      label: "Type",
      width: 160,
      textAlign: "left",
    },
    {
      label: "Amount",
      width: 160,
      textAlign: "left",
    },
    {
      label: "Payment Allowed",
      width: 160,
      textAlign: "left",
    },
    {
      label: "VA Expiry",
      width: 160,
      textAlign: "left",
    },
    {
      label: "Payment Due Date (Optional)",
      width: 180,
      textAlign: "left",
    },
    {
      label: "",
      width: 56,
    },
  ];

  useEffect(() => {
    if (!isInput) return;
    if (currentData.length) return;
    setCurrentData([emptyRowObj]);
  }, [isInput, currentData]);

  useEffect(() => {
    if (!runQuery) return;
    if (!searchbox) {
      setDataMode("current");
    } else {
      setDataMode("filtered");
    }
  }, [runQuery, searchbox]);

  useEffect(() => {
    if (isDuplicateExists) return;
    push({ query: { ...query, menu: "all_virtual_account" } });
  }, [isDuplicateExists]);

  if (filteredDataEmpty)
    return (
      <>
        {filterComponent(false)}
        <EmptyList
          menu="create"
          customImage={emptySearchImg}
          noButton
          ignoreQuery
          doubleSubtext
        />
      </>
    );

  return (
    <>
      {filterComponent(false)}
      <div
        id={VA_FOURTH_TARGET}
        style={{ margin: 32, marginTop: 0, marginBottom: 95 }}
      >
        <TableJackSimple
          isAllowOverflow
          tableStyle={{ tableLayout: "auto" }}
          headerTable={headerTable}
          tbodyRef={tbodyRef}
          bodyComponent={
            <>
              <ViewportList
                viewportRef={tbodyRef}
                items={isFiltered ? filteredData : currentData}
                itemMinSize={100}
                margin={8}
                overscan={10}
              >
                {(item, index) => {
                  return (
                    <VAConfirmTableItem
                      index={index}
                      item={item}
                      key={index}
                      isLast={index === currentData.length - 1}
                      currentData={isFiltered ? filteredData : currentData}
                      setCurrentData={setCurrentData}
                      setCheckDuplicate={setCheckDuplicate}
                      emptyRowObj={emptyRowObj}
                      isInput
                      duplicatePage={duplicatePage}
                      bankOptions={bankOptions}
                      isEmptyFirstCase={isEmptyFirstCase}
                    />
                  );
                }}
              </ViewportList>
              {/* ){loading && <TableBodySkeleton columns={13} />} */}
            </>
          }
        />
      </div>
      {
        <SummarySheet
          array={[
            {
              title: "Number of Virtual Accounts",
              msg: `${currentData.length} Virtual Accounts`,
            },
          ]}
          buttons={[
            <ButtonJack
              leftIcon={<JackIcons name="arrow-back-outline" />}
              type="borderless"
              style={{
                backgroundColor: "transparent",
                border: "1px solid white",
                color: "white",
              }}
              onClick={() => toggleConfirmModal()}
            >
              Back
            </ButtonJack>,
            <ButtonJack
              disabled={
                !currentData.length || isDuplicateExists || incompleteItemExists
              }
              rightIcon={
                <JackIcons
                  name={"arrow-forward"}
                  fill={
                    currentData.length ? jackColors.black34 : jackColors.greyBB
                  }
                />
              }
              isLoading={loadingCreate}
              onClick={() => {
                eventsTracker(DEMO_CLICK_NEXT, {
                  activity_type: "create_virtual_account",
                  page_title: "input_and_confirm",
                });
                handleCreate(currentData, "bulk");
              }}
            >
              Create
            </ButtonJack>,
          ]}
        />
      }
      <ConfirmationModalJack
        title="Go back to the previous page?"
        text="By leaving this page, you will lose all virtual account data that you’ve filled."
        buttonTextRight="Yes, remove"
        buttonTextLeft="Keep editing"
        img={backModalImg}
        modal={isConfirmModalOpen}
        toggle={toggleConfirmModal}
        onClick={() => {
          handleBack();
          eventsTracker(DEMO_CLICK_BACK, {
            activity_type: "create_virtual_account",
            page_title: "input_and_confirm",
          });
        }}
      />
    </>
  );
};

const VAConfirmTableItem = ({
  item,
  index,
  isLast,
  currentData = [],
  emptyRowObj = {},
  duplicatePage = false,
  bankOptions = [],
  onClick = () => {},
  setCurrentData = () => {},
  setCheckDuplicate = () => {},
  isEmptyFirstCase = false,
}) => {
  const itemFormatted = vaFormatter(item);
  const {
    expiryDate,
    referenceId,
    displayName,
    vaNumber,
    bankName,
    status,
    isOpen,
    closedTypeAmount,
    usageLimit,
    trxExpirationDate,
    // TRANSACTION DATA
    amount,
    adminFee = 2500,
    bank_name,
    created_at,
    reference_id,
    state,
  } = itemFormatted;

  const isLifetime = item?.is_lifetime;
  const isDuplicate = item?.is_duplicated_reference;
  const isRefAlreadyUsed = item?.is_ref_already_used;
  const canSetDueDate = item?.can_set_due_date;
  const tempId = item?.temp_id;

  const [hovered, setHovered] = useState(false);
  const [tooltip, setTooltip] = useState("");
  const [inputValue, setInputValue] = useState("");
  const [selectedValue, setSelectedValue] = useState("");

  const expiryType = !!Date.parse(expiryDate) ? "date" : "never";

  const { successSnackBar } = ToasterHook();

  const handleChange = ({ targetName = "name", value }) => {
    // setIsCheckedDuplicated(false);

    setCurrentData((prev) => {
      // const targetIndex = prev.findIndex(({ id: prevId }) => prevId === id);
      prev[index][targetName] = value;
      return prev;
    });
    return setCheckDuplicate(true);
  };

  const handleDeleteRow = (tempId, from) => {
    const fromDuplicate = from === "duplicate";
    const lastRow = currentData?.length === 1;
    setCurrentData((prev) => {
      if (lastRow) return [emptyRowObj];
      const updatedData = prev.filter(({ temp_id }, rowIndex) => {
        if (fromDuplicate) return temp_id !== tempId;
        return rowIndex !== index;
      });
      return updatedData;
    });
    setCheckDuplicate(true);
    successSnackBar({ msg: "1 virtual account has been deleted" });
  };

  const WarningTextComponent = () => {
    return (
      <GothamRegular style={{ ...font12, color: jackColors.neutral800 }}>
        The reference ID is{" "}
        <span>
          <GothamMedium
            style={{
              ...font12,
              display: "inline-block",
              color: jackColors.neutral800,
            }}
          >
            duplicated
          </GothamMedium>
        </span>{" "}
        with other VA(s) in our system. Please review and edit it before
        continuing.
      </GothamRegular>
    );
  };

  const { isOpen: isConfirmModalOpen, toggle } = useModalHook();

  const referenceIdInputRef = useRef();
  const bankNameInputRef = useRef();
  const displayNameInputRef = useRef();
  const typeInputRef = useRef();
  const amountInputRef = useRef();
  const paymentAllowedInputRef = useRef();
  const vaExpiryInputRef = useRef();
  const paymentDueDateInputRef = useRef();

  // const [handleOnChange, setHandleOnChange] = useState(() => {})
  // const injector = (func) => {
  //   setHandleOnChange(func)
  // }

  return (
    <>
      <TransitionTr
        counter={index}
        isQuick
        perPage={15}
        key={index}
        onClick={() => onClick({ ...item, category: "va" })}
        style={{
          borderWidth: isLast ? 0 : 1,
          height: 65,
          backgroundColor: isDuplicate || isRefAlreadyUsed ? "#FFF6E6" : "",
        }}
        onMouseEnter={() => setHovered(true)}
        onMouseLeave={() => setHovered(false)}
      >
        <ModalTextInputTd
          ref={referenceIdInputRef}
          name="reference_id"
          modalType={"text"}
          value={item.reference_id}
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={referenceId}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Input Reference ID"
          innerPlaceholder="E.g., INVOICE-1001"
          helperText="Create a unique ID to identify payments for this VA."
          isDuplicate={isDuplicate || isRefAlreadyUsed}
          warningComponent={
            <>
              <HoverComponent
                index={index}
                hoverPlacement="right"
                hoverText={<WarningTextComponent />}
                textAlign="left"
                style={{ width: 310, padding: 12 }}
                hoverComponent={
                  <JackIcons
                    name="info-outline"
                    fill={"#FCB037"}
                    style={{ ...icon20 }}
                  />
                }
              />
            </>
          }
          index={index}
        />
        <ModalSelectInputTd
          ref={bankNameInputRef}
          name="bank"
          modalType={"select"}
          modalStyle={{ width: 320 }}
          value={item.bank}
          tooltip={tooltip}
          inputValue={inputValue}
          selectedValue={selectedValue}
          defaultValue={bankName}
          selectOptions={bankOptions}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          setSelectedValue={setSelectedValue}
          handleChange={handleChange}
          placeholder="Select Bank"
          index={index}
          item={item}
          isEmptyFirstCase={isEmptyFirstCase}
          // injector={injector}
        />
        <ModalTextInputTd
          ref={displayNameInputRef}
          name="username_display"
          modalType={"text"}
          value={item.username_display}
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={displayName}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Enter Display Name"
          innerPlaceholder="Enter Display Name"
          helperText="Our system may add a prefix to this name after account creation."
          containerStyleAux={{ width: 184 }}
          tdStyleAux={{ maxWidth: 184 }}
        />
        <ModalTypeInputTd
          ref={typeInputRef}
          name="is_open"
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={isOpen}
          selectedBank={item.bank}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Select Type"
          isBooleanDefaultValue
          alternativeDefaultValue={<TypeLabel isOpen={isOpen} isOutline />}
        />
        <ModalAmountInputTd
          ref={amountInputRef}
          name="amount"
          modalType={"text"}
          value={item.amount}
          selectedBank={item.bank}
          tooltip={tooltip}
          inputValue={String(inputValue)}
          defaultValue={String(closedTypeAmount)}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="IDR 0"
          innerPlaceholder=""
          helperText="Input amount in IDR"
          isNumberOnly
          isMoneyAmount
          disabled={isOpen}
        />
        <ModalPaymentInputTd
          ref={paymentAllowedInputRef}
          name="usage_limit"
          selectedBank={item.bank}
          isOpen={item.is_open}
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={usageLimit}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Select Payments"
        />
        <ModalExpiryInputTd
          ref={vaExpiryInputRef}
          name="is_lifetime"
          value={item}
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={{ expiryDate, isLifetime }}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Never Expires"
        />
        <ModalDueInputTd
          ref={paymentDueDateInputRef}
          name="trx_expiration_date"
          value={item}
          tooltip={tooltip}
          inputValue={inputValue}
          defaultValue={trxExpirationDate}
          vaExpDate={{ expiryDate, isLifetime } || {}}
          setTooltip={setTooltip}
          setInputValue={setInputValue}
          handleChange={handleChange}
          placeholder="Select date & time"
          canSet={canSetDueDate}
        />
        <td>
          {hovered && (
            <JackIcons
              name={"delete_outline"}
              fill={jackColors.neutral700}
              style={icon20}
              onClick={() => toggle()}
            />
          )}
        </td>
      </TransitionTr>
      <ConfirmationModalJack
        toggle={toggle}
        img={deleteModalImg}
        modal={isConfirmModalOpen}
        title="Delete this virtual account?"
        text="You will remove this virtual account from the list."
        onClick={() =>
          handleDeleteRow(tempId, duplicatePage ? "duplicate" : "")
        }
        buttonTextRight="Delete"
        buttonTextLeft="Back"
      />
    </>
  );
};

const ModalTextInputTd = ({
  ref = { current: null },
  name,
  modalType = "text",
  isNumberOnly = false,
  isMoneyAmount = false,
  value,
  modalStyle,
  tooltip = "",
  inputValue = value,
  defaultValue = "",
  placeholder = "Select",
  innerPlaceholder = "Input text",
  helperText = "Helper text",
  containerStyleAux,
  tdStyleAux,
  isDuplicate = false,
  warningComponent,
  setTooltip = () => {},
  setInputValue = () => {},
  handleChange = () => {},
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");

  const containerStyle = {
    width: 160,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    ...containerStyleAux,
  };

  const handleSubmit = () => {
    if (tooltip !== name) return;
    handleChange({ targetName: tooltip, value: inputValue });
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      handleSubmit();
    },
    dependency: [tooltip, inputValue],
  });

  useCustomKeypress({
    ref: ref,
    key: "Enter",
    action: handleSubmit,
    dependency: [tooltip, inputValue],
  });

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 160,
        ...tdStyleAux,
      }}
      onClick={() => {
        setInputValue(value);
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "408px",
              // height: "72px",
              backgroundColor: "white",
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              padding: "12px 8px 0px 8px",
              ...modalStyle,
            }}
          >
            <TextFieldJackState
              noBorder
              autoFocus
              value={inputValue}
              defaultValue={inputValue}
              style={{
                border: "none",
                marginBottom: "8px",
                width: 320,
              }}
              textFieldStyle={{
                height: "40px",
                width: "100%",
                border: "none",
                borderBottom: `1px solid ${jackColors.neutral600}`,
                borderRadius: "0px",
              }}
              textFieldInputStyle={{ border: "none" }}
              onChange={(value) => setInputValue(value)}
              letterCounterStyle={{
                translate: "12px 10px",
                color: jackColors.neutral600,
              }}
              placeholder={innerPlaceholder}
              helperText={helperText}
            />

            <div
              style={{
                display: "flex",
                height: "100%",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <JackIcons
                isStopBubbling
                name="checkmark-circle-2"
                fill={jackColors.greenB9}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => {
                  handleChange({
                    targetName: tooltip,
                    value: inputValue,
                  });
                  setTooltip("");
                }}
              />
              <JackIcons
                isStopBubbling
                name="close-circle"
                fill={jackColors.neutral900}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => {
                  setTooltip("");
                }}
              />
            </div>
          </div>
        )}
        {isDuplicate && warningComponent}
        <GothamRegular
          woFontColor
          style={{
            color: defaultValue ? jackColors.neutral900 : jackColors.neutral600,
            textAlign: "left",
            marginLeft: isDuplicate ? 10 : 0,
            ...textEllipsis,
          }}
        >
          {defaultValue || placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalSelectInputTd = ({
  ref = { current: null },
  name,
  value,
  modalStyle,
  tooltip = "",
  selectOptions = [],
  selectedValue = null,
  defaultValue = "",
  placeholder = "Select",
  containerStyleAux,
  setTooltip = () => {},
  setSelectedValue = () => {},
  handleChange = () => {},
  index = "",
  item = {},
  isEmptyFirstCase = false,
  // injector = () => {}
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");

  const containerStyle = {
    width: 140,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 8,
    ...containerStyleAux,
  };

  const refButton = useRef(null);

  const { icon, iconSize } =
    selectOptions.find(({ label }) => label === defaultValue) || {};

  const withImage = !!icon && !!iconSize;

  const handleClick = () => {
    setSelectedValue(defaultValue);
    setTooltip("bank");
  };

  const handleOnChange = (e) => {
    setSelectedValue(e.value);
    refButton?.current?.focus();
  };

  // useEffect(() => {
  //   if (!refButton?.current) return;
  //   injector(handleOnChange)
  // }, [refButton?.current])

  const handleSubmit = () => {
    if (tooltip !== name) return;
    handleChange({ targetName: tooltip, value: selectedValue });
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (tooltip !== name) return;
      handleSubmit();
    },
    dependency: selectedValue,
    isOldMethod: true,
  });

  useCustomKeypress({
    ref: ref,
    key: "Enter",
    action: handleSubmit,
    dependency: [tooltip, selectedValue],
  });

  // demo purpose
  const { query } = useRouter();
  const { action, step } = query ?? {};
  const {
    guidanceState: { isTourRunning },
  } = useDemo();

  useEffect(() => {
    const clickFirstRow = () => {
      if (index == 0) handleClick();
    }; // to only open bank modal on the first row
    if (!isTourRunning) return;
    if ((step == 0 && action == "next") || (step == 2 && action == "prev")) {
      clickFirstRow();
    }
  }, [isTourRunning, step, action]);

  return (
    <td
      id={VA_SECOND_TARGET}
      style={{
        cursor: "text",
        maxWidth: 140,
      }}
      onClick={handleClick}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            id={VA_THIRD_TARGET}
            style={{
              position: "absolute",
              width: "408px",
              height: "72px",
              backgroundColor: "white",
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              padding: "12px 8px 0px 8px",
              ...modalStyle,
            }}
            onClick={(e) => e.stopPropagation()}
          >
            <div style={{ width: "100%" }}>
              <MinimalistSelect
                placeholder="Select bank name"
                options={selectOptions}
                onChange={handleOnChange}
                defaultValue={defaultValue}
                value={selectedValue}
                // noArrowDropDown
                noAsterisk
                autoFocus={true}
                menuStyle={{ width: 320 }}
                withIcon
                // required={required}
              />
            </div>
            <div
              style={{
                display: "flex",
                height: "100%",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <JackIcons
                isStopBubbling
                name="checkmark-circle-2"
                fill={jackColors.greenB9}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => {
                  handleSubmit();
                  // handleChange({ targetName: tooltip, value: selectedValue });
                  // setTooltip("");
                }}
              />
              <JackIcons
                isStopBubbling
                name="close-circle"
                fill={jackColors.neutral900}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => {
                  setTooltip("");
                }}
              />
            </div>
          </div>
        )}
        {withImage && <img src={icon} alt="bank-icon" style={iconSize} />}
        <GothamRegular
          woFontColor
          style={{
            color: defaultValue ? jackColors.neutral900 : jackColors.neutral600,
            textAlign: "left",
            ...textEllipsis,
          }}
        >
          {defaultValue || placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalTypeInputTd = ({
  ref = { current: null },
  name,
  modalStyle,
  tooltip = "",
  selectedValue = null,
  selectedBank = "",
  defaultValue = "",
  placeholder = "Select",
  isBooleanDefaultValue = false,
  alternativeDefaultValue = "",
  containerStyleAux,
  setTooltip = () => {},
  handleChange = () => {},
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");

  // hardcode mandiri sementara
  const isMandiri = selectedBank === "Bank Mandiri";

  const containerStyle = {
    width: 160,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    ...containerStyleAux,
  };

  const handleSubmit = (type) => {
    const isOpen = type === "open";
    if (isOpen) {
      handleChange({ targetName: tooltip, value: true });
      handleChange({ targetName: "amount", value: 0 });
    } else {
      handleChange({ targetName: tooltip, value: false });
      handleChange({ targetName: "amount", value: "" });
    }
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (tooltip !== name) return;
      setTooltip("");
    },
    dependency: [selectedValue, tooltip],
  });

  const options = [
    {
      label: "Open Amount",
      subLabel: "Payer can send any amount",
      logo: "wifi_protected_setup",
      value: "open",
      disabled: isMandiri ? true : false, // hardcode mandiri sementara
    },
    {
      label: "Closed Amount",
      subLabel: "Payer send a specific amount of money",
      logo: "lock",
      value: "closed",
      disabled: false,
    },
  ];

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 160,
      }}
      onClick={() => {
        // setSelectedValue(defaultValue);
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "320px",
              height: "fit-content",
              backgroundColor: "white",
              top: -18,
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "12px",
              padding: "8px",
              ...modalStyle,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: `1px solid ${jackColors.neutral500}`,
                padding: 8,
              }}
            >
              <GothamMedium style={{ ...font14 }}>Select type</GothamMedium>
              <JackIcons
                isStopBubbling
                name="close"
                fill={jackColors.neutral900}
                style={{ ...icon20, cursor: "pointer" }}
                onClick={() => {
                  setTooltip("");
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 8,
                padding: 8,
              }}
            >
              {options.map((option) => {
                const isDisabled = option.disabled;
                return (
                  <div
                    className={
                      isDisabled ? "" : styles["option-div-transition"]
                    }
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 6,
                      borderRadius: 4,
                      padding: "8px 8px",
                      cursor: isDisabled ? "not-allowed" : "pointer",
                      backgroundColor:
                        defaultValue === true ? jackColors.neutral500 : "",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (isDisabled) return;
                      handleSubmit(option.value);
                    }}
                  >
                    <JackIcons
                      name={option.logo}
                      fill={
                        isDisabled
                          ? jackColors.neutral600
                          : jackColors.neutral900
                      }
                      style={{ ...icon20 }}
                    />
                    <SubTextComponent
                      text={option.label}
                      subText={option.subLabel}
                      customSubStyle={{
                        ...font12,
                        color: isDisabled
                          ? jackColors.neutral600
                          : jackColors.neutral900,
                      }}
                      customTextStyle={{
                        color: isDisabled
                          ? jackColors.neutral600
                          : jackColors.neutral900,
                      }}
                      isNotBold
                    />
                  </div>
                );
              })}
            </div>
          </div>
        )}
        {isBooleanDefaultValue ? (
          defaultValue !== "" ? (
            alternativeDefaultValue
          ) : (
            <GothamRegular
              style={{
                color: jackColors.neutral600,
                textAlign: "left",
              }}
            >
              {placeholder}
            </GothamRegular>
          )
        ) : (
          <GothamRegular
            woFontColor
            style={{
              color: defaultValue
                ? jackColors.neutral900
                : jackColors.neutral600,
              textAlign: "left",
            }}
          >
            {defaultValue || placeholder}
          </GothamRegular>
        )}
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalAmountInputTd = ({
  ref = { current: null },
  name,
  isNumberOnly = false,
  value,
  selectedBank = "",
  modalStyle,
  tooltip = "",
  inputValue = "",
  defaultValue = "0",
  placeholder = "",
  innerPlaceholder = "Input text",
  helperText = "Helper text",
  disabled = false,
  setTooltip = () => {},
  setInputValue = () => {},
  handleChange = () => {},
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");

  const inputValueFormatted = isNumberOnly
    ? applyRegexOnlyNumbers(inputValue || "")
    : inputValue;

  const { vaBankOptions } = useConstants();
  const itemBank =
    vaBankOptions.find(({ label }) => label === selectedBank) || {};
  const { closed_min_amount, closed_max_amount } = itemBank;

  const errorMsgDecider = () => {
    const inputAmount = Number(inputValueFormatted);
    if (inputAmount < closed_min_amount)
      return `Minimum amount: IDR ${formatCurrencyNoDecimal(
        closed_min_amount
      )}`;
    if (inputAmount > closed_max_amount)
      return `Maximum amount: IDR ${formatCurrencyNoDecimal(
        closed_max_amount
      )}`;
    return null;
  };

  const isError = !!errorMsgDecider();

  const handleSubmit = () => {
    if (tooltip !== name) return;
    if (isError) return null;
    handleChange({ targetName: tooltip, value: Number(inputValueFormatted) });
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (isError) return setTooltip("");
      handleSubmit();
    },
    dependency: [tooltip, inputValueFormatted],
  });

  useCustomKeypress({
    ref: ref,
    key: "Enter",
    action: handleSubmit,
    isError: isError,
    dependency: [tooltip, inputValueFormatted],
  });

  const containerStyle = {
    width: 160,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  };

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 160,
      }}
      onClick={() => {
        if (disabled) return null;
        setInputValue(value);
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "408px",
              // height: "72px",
              backgroundColor: "white",
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: "16px",
              padding: "12px 8px 0px 8px",
              ...modalStyle,
            }}
          >
            <TextFieldJackState
              noBorder
              autoFocus
              value={inputValueFormatted}
              defaultValue={defaultValue || 0}
              style={{
                border: "none",
                marginBottom: "8px",
                width: 320,
              }}
              textFieldStyle={{
                height: "40px",
                width: "100%",
                border: "none",
                borderBottom: `1px solid ${jackColors.neutral600}`,
                borderRadius: "0px",
              }}
              textFieldInputStyle={{ border: "none" }}
              onChange={(value) => setInputValue(value)}
              letterCounterStyle={{
                translate: "12px 10px",
                color: jackColors.neutral600,
              }}
              placeholder={innerPlaceholder}
              helperText={helperText}
              error={errorMsgDecider()}
            />

            <div
              style={{
                display: "flex",
                height: "100%",
                gap: "8px",
                alignItems: "center",
              }}
            >
              <JackIcons
                isStopBubbling
                name="checkmark-circle-2"
                fill={jackColors.greenB9}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => handleSubmit()}
              />
              <JackIcons
                isStopBubbling
                name="close-circle"
                fill={jackColors.neutral900}
                style={{ cursor: "pointer", ...icon24 }}
                onClick={() => {
                  setTooltip("");
                }}
              />
            </div>
          </div>
        )}
        <GothamRegular
          woFontColor
          style={{
            color:
              defaultValue !== ""
                ? jackColors.neutral900
                : jackColors.neutral600,
            textAlign: "left",
            ...textEllipsis,
          }}
        >
          {defaultValue !== ""
            ? `IDR ${formatCurrencyNoDecimal(defaultValue)}`
            : placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalPaymentInputTd = ({
  ref = { current: null },
  name,
  modalStyle,
  tooltip = "",
  selectedBank = "",
  isOpen = false,
  selectedValue = null,
  defaultValue = "",
  secondValueTargetName = "",
  placeholder = "Select",
  optionStyle = {},
  isBooleanDefaultValue = false,
  alternativeDefaultValue = "",
  setTooltip = () => {},
  setSelectedValue = () => {},
  handleChange = () => {},
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");
  const [selected, setSelected] = useState(null);
  const [limit, setLimit] = useState("");

  const limitFormatted = applyRegexOnlyNumbers(limit);

  const { vaBankOptions } = useConstants();
  const itemBank =
    vaBankOptions.find(({ label }) => label === selectedBank) || {};
  const { closed_single, closed_multiple, open_single, open_multiple } =
    itemBank?.payment_config || {};

  const isSingle = selected === "single";
  const isMultiple = selected === "multiple";
  const isUnlimited = selected === "unlimited";

  const optionDisabledDecider = (selectedType, option) => {
    if (isEmpty(itemBank)) return false;
    const openType = selectedType === true;
    const closedType = selectedType === false;

    if (option === "single") {
      if (openType && !open_single) return true;
      if (closedType && !closed_single) return true;
    }

    if (option === "multiple") {
      if (openType && !open_multiple) return true;
      if (closedType && !closed_multiple) return true;
    }

    return false;
  };

  const saveDisabled = !selected || (isMultiple && !limit);

  const containerStyle = {
    width: 160,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  };

  const options = [
    {
      text: "Single Payment",
      subtext: "Only one payment accepted",
      value: "single",
      disabled: optionDisabledDecider(isOpen, "single"),
    },
    // {
    //   text: "Multiple Payments",
    //   subtext: "Set how many payments it can accept",
    //   value: "multiple",
    //   disabled: optionDisabledDecider(isOpen, "multiple"),
    // },
    {
      text: "Unlimited",
      subtext: "Accept unlimited number of payments",
      value: "unlimited",
      disabled: optionDisabledDecider(isOpen, "multiple"),
    },
  ];

  const allowedPaymentDecider = () => {
    if (isSingle) return 1;
    if (isMultiple) return Number(limitFormatted);
    return -1;
  };

  const limitDecider = (lim) => {
    if (lim === "") return "";
    if (lim === -1) return "";
    if (lim === 1) return "";
    return String(lim);
  };

  const handleSubmit = () => {
    if (tooltip !== name) return;
    handleChange({ targetName: tooltip, value: allowedPaymentDecider() });
    setSelected(null);
    setTooltip("");
    setLimit("");
  };

  const handleReset = () => {
    setSelected(null);
    setLimit("");
    handleChange({ targetName: name, value: "" });
    setTooltip("");
  };

  const displayedSuffixDecider = (paymentCount) => {
    const stringCount = String(paymentCount);
    if (stringCount === "-1") return "Unlimited";
    if (stringCount === "1") return "1 Payment";
    return `${stringCount} Payments`;
  };

  const defaultValueMasker = () => {
    if (defaultValue === "") return "";
    if (defaultValue === 1) return "single";
    if (defaultValue === -1) return "unlimited";
    return "multiple";
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (tooltip !== name) return;
      handleReset();
    },
    dependency: [selectedValue, tooltip],
  });

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 160,
      }}
      onClick={(e) => {
        e.stopPropagation();
        setSelected(defaultValueMasker());
        setLimit(limitDecider(defaultValue));
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "320px",
              height: "fit-content",
              backgroundColor: "white",
              top: -18,
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "12px",
              padding: 8,
              ...modalStyle,
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: `1px solid ${jackColors.neutral500}`,
                padding: 8,
              }}
            >
              <GothamMedium style={{ ...font14 }}>
                Select payments allowed
              </GothamMedium>
              <JackIcons
                isStopBubbling
                name="close"
                fill={jackColors.neutral900}
                style={{ ...icon20, cursor: "pointer" }}
                onClick={() => setTooltip("")}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 8,
              }}
            >
              {options.map((option, optionIndex) => {
                const isActive = selected === option.value;
                const isDisabled = option.disabled;
                const RenderComponent = () => {
                  return (
                    <div
                      className={
                        isDisabled ? "" : styles["option-div-transition"]
                      }
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: 16,
                        borderRadius: 4,
                        padding: "8px 8px",
                        cursor: isDisabled ? "not-allowed" : "pointer",
                      }}
                      key={optionIndex}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isDisabled) return null;
                        setSelected(option.value);
                      }}
                    >
                      <CheckBox
                        type="Circle"
                        isActive={isActive && !isDisabled}
                        fillCustom={isDisabled ? jackColors.neutral400 : null}
                      />
                      <SubTextComponent
                        text={option.text}
                        subText={option.subtext}
                        customTextStyle={{
                          color: isDisabled
                            ? jackColors.neutral600
                            : jackColors.neutral900,
                        }}
                        customSubStyle={{
                          ...font12,
                          color: isDisabled
                            ? jackColors.neutral600
                            : jackColors.neutral700,
                        }}
                        isNotBold
                      />
                    </div>
                  );
                };
                if (isDisabled)
                  return (
                    <HoverComponent
                      hoverComponent={<RenderComponent />}
                      hoverPlacement="left"
                      hoverText="Not available for selected bank option."
                      style={{
                        width: 150,
                      }}
                      index={optionIndex}
                      textAlign="left"
                    />
                  );
                return <RenderComponent />;
              })}

              {/* <div
                // className={styles["option-div-transition"]}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 0,
                  padding: "8px 8px",
                  border: `1px solid ${jackColors.neutral500}`,
                  borderRadius: 4,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <div>
                  Max. Limit
                  {isMultiple && <span style={{ color: "red" }}>*</span>}
                </div>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    height: "fit-content",
                  }}
                >
                  <TextFieldJackState
                    style={{ width: "100%", margin: 0 }}
                    value={limitFormatted}
                    onChange={(e) => setLimit(e)}
                    placeholder="Input number"
                    disabled={!isMultiple}
                  />
                </div>
              </div> */}

              <div
                // className={styles["option-div-transition"]}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 14,
                  // border: `1px solid ${jackColors.neutral500}`,
                  borderRadius: 4,
                }}
              >
                <ButtonJack
                  type="outline"
                  disabled={false}
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReset();
                  }}
                >
                  Reset
                </ButtonJack>
                <ButtonJack
                  disabled={saveDisabled}
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  Save
                </ButtonJack>
              </div>
            </div>
          </div>
        )}
        <GothamRegular
          woFontColor
          style={{
            color: defaultValue ? jackColors.neutral900 : jackColors.neutral600,
            textAlign: "left",
            ...textEllipsis,
          }}
        >
          {defaultValue ? displayedSuffixDecider(defaultValue) : placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalExpiryInputTd = ({
  ref = { current: null },
  name,
  modalStyle,
  tooltip = "",
  selectedValue = null,
  defaultValue = "",
  placeholder = "Select",
  setTooltip = () => {},
  handleChange = () => {},
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const { expiryDate, isLifetime } = defaultValue || {};

  const withExpiryDate = selectedType === "will_expire";
  const isDate = !!Date.parse(expiryDate);
  const expiryType = isDate ? "date" : "never";
  const expiryTime = expiryDate
    ? new Date(expiryDate)?.getHours().toString().padStart(2, "0") + ":00"
    : "";

  const containerStyle = {
    width: 160,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  };

  const options = [
    {
      text: "Never Expires",
      subtext: "",
      value: "lifetime",
    },
    {
      text: "Custom Expiry Date",
      subtext: "Set when VA expires",
      value: "will_expire",
    },
  ];

  const disableSaveDecider = () => {
    if (selectedType === "") return true;
    if (withExpiryDate && (!selectedDate || !selectedTime)) return true;
    return false;
  };

  const defaultTypeMask = (val) => {
    if (val === true) return "lifetime";
    if (val === false) return "will_expire";
    return "";
  };

  const handleSubmit = () => {
    if (withExpiryDate) {
      const dateTime = combineDateAndTimeToIso(selectedDate, selectedTime);
      handleChange({ targetName: tooltip, value: false });
      handleChange({ targetName: "expiration_date", value: dateTime });
      handleChange({ targetName: "trx_expiration_date", value: "" });
    } else {
      handleChange({ targetName: tooltip, value: true });
    }
    setTooltip("");
  };

  const handleReset = () => {
    setSelectedType("");
    setSelectedDate("");
    setSelectedTime("");
    handleChange({ targetName: tooltip, value: "" });
    handleChange({ targetName: "expiration_date", value: "" });
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (tooltip !== name) return;
      setTooltip("");
    },
    dependency: [selectedValue, tooltip],
  });

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 160,
      }}
      onClick={() => {
        setSelectedType(defaultTypeMask(isLifetime));
        setSelectedDate(isDate ? new Date(expiryDate) : "");
        setSelectedTime(expiryTime || "");
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "320px",
              height: "fit-content",
              backgroundColor: "white",
              top: -18,
              left: -10,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "12px",
              padding: "8px",
              ...modalStyle,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                borderBottom: `1px solid ${jackColors.neutral500}`,
                padding: 8,
              }}
            >
              <GothamMedium style={{ ...font14 }}>VA Expiry</GothamMedium>
              <JackIcons
                isStopBubbling
                name="close"
                fill={jackColors.neutral900}
                style={{ ...icon20, cursor: "pointer" }}
                onClick={() => {
                  setTooltip("");
                }}
              />
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 8,
                // padding: 8,
              }}
            >
              {options.map((option, optionIndex) => {
                const isActive = selectedType === option.value;
                return (
                  <div
                    key={optionIndex}
                    className={styles["option-div-transition"]}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: 16,
                      borderRadius: 4,
                      padding: "8px 8px",
                      cursor: "pointer",
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedType(option.value);
                    }}
                  >
                    <CheckBox type="Circle" isActive={isActive} />
                    <SubTextComponent
                      text={option.text}
                      subText={option.subtext}
                      customSubStyle={font12}
                      isNotBold
                    />
                  </div>
                );
              })}
              {withExpiryDate && (
                <div
                  // className={styles["option-div-transition"]}
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 0,
                    borderRadius: 4,
                    padding: "8px 8px",
                    border: `1px solid ${jackColors.neutral500}`,
                    borderRadius: 4,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: 9,
                      width: "100%",
                      height: "fit-content",
                    }}
                  >
                    <DateInputJackState
                      label="Date"
                      style={{ width: "100%", margin: 0 }}
                      value={selectedDate}
                      dateFormat="dd/MM/yyyy"
                      minDate={new Date()}
                      placeholder="Select Date"
                      onChange={(date) => {
                        setSelectedDate(date);
                      }}
                      disabled={!withExpiryDate}
                      required
                    />
                    <SelectionJackState
                      divStyle={{ width: "100%", margin: 0, height: 65 }}
                      label={"Time (WIB)"}
                      options={
                        selectedDate
                          ? generateTimeOptionsComplex(selectedDate)
                          : generateTimeOptions()
                      }
                      placeholder="Select Time"
                      value={selectedTime}
                      customComponents={{ DropdownTimeIndicator }}
                      onChange={(e) => setSelectedTime(e)}
                      disabled={!withExpiryDate}
                      required
                    />
                  </div>
                </div>
              )}
              <div
                // className={styles["option-div-transition"]}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 14,
                  borderRadius: 4,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                <ButtonJack
                  type="outline"
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReset();
                  }}
                >
                  Reset
                </ButtonJack>
                <ButtonJack
                  // type="outline"
                  disabled={disableSaveDecider()}
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  Save
                </ButtonJack>
              </div>
            </div>
          </div>
        )}
        <GothamRegular
          woFontColor
          style={{
            color:
              isLifetime !== "" ? jackColors.neutral900 : jackColors.neutral600,
            textAlign: "left",
            ...textEllipsis,
          }}
        >
          {!isLifetime
            ? expiryDateDecider(expiryType, expiryDate)
            : placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

const ModalDueInputTd = ({
  ref = { current: null },
  name,
  value,
  modalStyle,
  tooltip = "",
  selectOptions = [],
  selectedValue = null,
  defaultValue = "",
  vaExpDate = {},
  secondValueTargetName = "",
  placeholder = "Select",
  optionStyle = {},
  isBooleanDefaultValue = false,
  alternativeDefaultValue = "",
  setTooltip = () => {},
  setSelectedValue = () => {},
  handleChange = () => {},
  canSet = true,
}) => {
  const [tooltipInfo, setTooltipInfo] = useState("");
  const [dueDate, setDueDate] = useState(false);
  const [selectedDate, setSelectedDate] = useState("");
  const [selectedTime, setSelectedTime] = useState("");

  const containerStyle = {
    width: 180,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "8px",
  };

  const { expiryDate, isLifetime } = vaExpDate;
  const isDate = !!Date.parse(defaultValue);
  const expiryType = isDate ? "date" : "never";
  const vaExpDateMask = !!Date.parse(expiryDate) ? expiryDate : "";
  const vaExpHour = new Date(vaExpDateMask)?.getHours();
  const isItToday = isToday(new Date(expiryDate));
  const nowHour = new Date().getHours();
  const trxExpHour = defaultValue
    ? new Date(defaultValue)?.getHours().toString().padStart(2, "0") + ":00"
    : "";

  const disableSave = dueDate && (!selectedDate || !selectedTime);

  const handleSubmit = () => {
    if (dueDate) {
      const dateTime = combineDateAndTimeToIso(selectedDate, selectedTime);
      handleChange({ targetName: tooltip, value: dateTime });
    } else {
      handleChange({ targetName: tooltip, value: "" });
    }
    setTooltip("");
  };

  const handleReset = () => {
    setDueDate(false);
    setSelectedDate("");
    setSelectedTime("");
    handleChange({ targetName: tooltip, value: "" });
    setTooltip("");
  };

  useClickOutside({
    ref: ref,
    clickOutside: () => {
      if (tooltip !== name) return;
      setTooltip("");
    },
    dependency: [selectedValue, tooltip],
  });

  return (
    <td
      style={{
        cursor: "text",
        maxWidth: 180,
      }}
      onClick={() => {
        setDueDate(!!defaultValue);
        setSelectedDate(isDate ? new Date(defaultValue) : "");
        setSelectedTime(trxExpHour);
        setTooltip(name);
      }}
      onMouseEnter={() => setTooltipInfo(true)}
      onMouseLeave={() => setTooltipInfo(false)}
    >
      <div
        style={{ ...containerStyle, position: "relative", maxWidth: "100%" }}
      >
        {tooltip === name && (
          <div
            ref={ref}
            style={{
              position: "absolute",
              width: "320px",
              height: "fit-content",
              backgroundColor: "white",
              top: -18,
              left: -150,
              zIndex: 100,
              borderRadius: "4px",
              boxShadow: "0px 8px 16px -4px #16223314",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "space-between",
              gap: "12px",
              padding: "8px",
              ...modalStyle,
            }}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div
              style={{
                width: "100%",
                display: "flex",
                // justifyContent: "space-between",
                flexDirection: "column",
                // alignItems: "center",
                borderBottom: `1px solid ${jackColors.neutral500}`,
                padding: 8,
                gap: 8,
              }}
            >
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  // borderBottom: `1px solid ${jackColors.neutral500}`,
                  // padding: 8,
                  gap: 8,
                }}
              >
                <GothamMedium style={{ ...font14 }}>
                  Payment Due Date
                </GothamMedium>
                <div
                  style={{
                    width: 80,
                    height: 24,
                    backgroundColor: jackColors.neutral400,
                    borderRadius: 4,
                    position: "relative",
                    padding: 2,
                    display: "flex",
                    justifyContent: dueDate ? "flex-end" : "flex-start",
                    gap: 8,
                    cursor: canSet ? "pointer" : "not-allowed",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!canSet) return;
                    setDueDate(!dueDate);
                  }}
                >
                  <div style={{ position: "absolute", left: 10 }}>
                    <GothamMedium
                      style={{
                        ...font14,
                        color: dueDate
                          ? jackColors.neutral600
                          : jackColors.neutral400,
                      }}
                    >
                      Off
                    </GothamMedium>
                  </div>
                  <div style={{ position: "absolute", right: 10 }}>
                    <GothamMedium
                      style={{
                        ...font14,
                        color: dueDate
                          ? jackColors.neutral400
                          : jackColors.neutral600,
                      }}
                    >
                      On
                    </GothamMedium>
                  </div>
                  <div
                    style={{
                      width: 36,
                      heigth: "100%",
                      backgroundColor: canSet
                        ? dueDate
                          ? "#06AC4E"
                          : `${jackColors.black34}`
                        : `${jackColors.neutral700}`,
                      padding: "2px 0px",
                      borderRadius: 4,
                    }}
                  />
                </div>
                <div style={{ marginLeft: "auto" }}>
                  <JackIcons
                    isStopBubbling
                    name="close"
                    fill={jackColors.neutral900}
                    style={{ ...icon20, cursor: "pointer" }}
                    onClick={() => {
                      setTooltip("");
                    }}
                  />
                </div>
              </div>
              <div
                style={{
                  width: "90%",
                }}
              >
                <GothamLight
                  style={{
                    ...font12,
                    color: jackColors.neutral700,
                    lineSpacing: "1px",
                  }}
                >
                  Set the payment cutoff date for transactions linked to this
                  VA.{" "}
                  <span
                    style={{
                      textDecoration: "underline",
                      fontWeight: "bolder",
                    }}
                  >
                    Learn more
                  </span>
                </GothamLight>
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                gap: 8,
              }}
            >
              {dueDate && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 12,
                    width: "100%",
                    height: "fit-content",
                    padding: "0px 8px",
                  }}
                >
                  <DateInputJackState
                    label="Date"
                    style={{ width: "100%", margin: 0 }}
                    value={selectedDate}
                    dateFormat="dd/MM/yyyy"
                    maxDate={new Date(vaExpDateMask)}
                    minDate={new Date()}
                    placeholder={"Select Date"}
                    onChange={(date) => {
                      setSelectedDate(date);
                    }}
                    disabled={!dueDate}
                    forceDropdownTop
                    required
                  />
                  <SelectionJackState
                    divStyle={{ width: "100%", margin: 0, height: 60 }}
                    label={"Time (WIB)"}
                    options={
                      selectedDate
                        ? vaExpDateMask
                          ? generateTimeOptions(
                              isItToday ? nowHour + 1 : 0,
                              vaExpHour
                            )
                          : generateTimeOptionsComplex(selectedDate)
                        : generateTimeOptions()
                    }
                    placeholder="Select Time"
                    value={selectedTime}
                    customComponents={{ DropdownTimeIndicator }}
                    disabled={!dueDate}
                    onChange={(e) => setSelectedTime(e)}
                    required
                  />
                  <GothamLight
                    style={{
                      ...font12,
                      color: jackColors.neutral700,
                    }}
                  >
                    Payment after this date won’t be accepted.
                  </GothamLight>
                </div>
              )}
              <div
                // className={styles["option-div-transition"]}
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: 14,
                  borderRadius: 4,
                }}
              >
                <ButtonJack
                  type="outline"
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleReset();
                  }}
                >
                  Reset
                </ButtonJack>
                <ButtonJack
                  // type="outline"
                  disabled={disableSave}
                  style={{ width: "100%" }}
                  // isLoading={loadingAction}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSubmit();
                  }}
                >
                  Save
                </ButtonJack>
              </div>
            </div>
          </div>
        )}
        <GothamRegular
          woFontColor
          style={{
            color: defaultValue ? jackColors.neutral900 : jackColors.neutral600,
            textAlign: "left",
            ...textEllipsis,
          }}
        >
          {defaultValue
            ? expiryDateDecider(expiryType, defaultValue)
            : placeholder}
        </GothamRegular>
        {tooltipInfo && (
          <JackIcons
            name="edit-outline"
            fill={jackColors.neutral600}
            style={{
              ...icon20,
              marginLeft: 8,
            }}
          />
        )}
      </div>
    </td>
  );
};

export const DropdownTimeIndicator = () => {
  return (
    <JackIcons
      name="clock-outline"
      fill={jackColors.greyBB}
      style={{
        width: 20,
        height: 20,
        marginRight: 10,
      }}
    />
  );
};

export const generateTimeOptions = (from = 0, to = 24) => {
  const options = [];
  for (let hour = from; hour <= to; hour++) {
    const formattedHour = hour.toString().padStart(2, "0");
    const label = `${formattedHour}:00`;
    options.push({ label, value: label });
  }
  return options;
};

export const generateTimeOptionsComplex = (date = new Date(), endTime) => {
  if (!Boolean(date)) return [];

  const isToday = todayChecker(date);
  const defaultOptions = generateTimeOptions(0, 23);
  if (isToday) {
    const currentDate = new Date();
    const hour = currentDate?.getHours();

    if (hour <= 6) return defaultOptions;
    if (hour >= 23) return [];

    return generateTimeOptions(hour + 1, endTime || 23);
  }

  return defaultOptions;
};

export const todayChecker = (date = new Date()) => {
  const targetDate = date?.getDate();
  const today = new Date().getDate();
  if (targetDate === today) return true;
  return false;
};

export const CreateVaFirstStep = ({ handleClick }) => {
  return (
    <div
      // page div
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div
        // middle container div
        style={{
          width: 438,
          height: "fit-content",
          display: "flex",
          flexDirection: "column",
          gap: 32,
        }}
        id={VA_FIRST_TARGET}
      >
        <div
          // title div
          style={{ display: "flex", flexDirection: "column", gap: 8 }}
        >
          <GothamMedium style={{ ...font24 }}>Choose your start!</GothamMedium>
          <GothamLight style={{ ...font14, color: jackColors.neutral800 }}>
            Select how you want to create your Virtual Account.
          </GothamLight>
        </div>
        <OptionsComponent handleClick={handleClick} />
      </div>
    </div>
  );
};

export const OptionsComponent = ({ handleClick }) => {
  const [selected, setSelected] = useState(null);
  const [isHover, setIsHover] = useState(false);
  const isNextDisabled = !selected;

  const handleSelect = (nextStep) => {
    setSelected(nextStep);
  };

  const handleDeselect = () => {
    setSelected(null);
  };

  const selectOptions = [
    {
      text: "Import from XLSX",
      subText: "Upload virtual account details from file",
      logoName: "file-text-outline",
      nextStep: "upload_csv",
    },
    {
      text: "Empty Table",
      subText: "Input virtual accounts details manually",
      logoName: "backup_table",
      nextStep: "empty_table",
    },
  ];

  return (
    <>
      <div
        // options div
        style={{ display: "flex", flexDirection: "column", gap: 16 }}
      >
        {selectOptions?.map((option, index) => {
          const text = option.text;
          const subText = option.subText;
          const logoName = option.logoName;
          const nextStep = option.nextStep;
          const optionSelected = selected === nextStep;
          const isHovered = isHover === index;
          return (
            <div
              style={{
                display: "flex",
                gap: 16,
                justifyContent: "space-between",
                alignItems: "center",
                border: `1px solid ${
                  optionSelected ? jackColors.neutral900 : jackColors.neutral500
                }`,
                borderRadius: 8,
                padding: "8px 16px",
                transition: "all 0.4s ease",
                backgroundColor: isHovered ? "rgba(0, 0, 0, 0.04)" : "white",
                cursor: "pointer",
              }}
              key={index}
              onMouseEnter={() => setIsHover(index)}
              onMouseLeave={() => setIsHover(false)}
              onClick={() =>
                optionSelected ? handleDeselect() : handleSelect(nextStep)
              }
            >
              <div style={{ display: "flex", gap: 16, alignItems: "center" }}>
                <SelectionIcon name={logoName} />
                <SubTextComponent
                  divStyle={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 8,
                  }}
                  text={text}
                  subText={subText}
                  customTextStyle={font14}
                  customSubStyle={font12}
                />
              </div>
              {optionSelected && (
                <JackIcons
                  name="checkmark-circle-2"
                  fill={jackColors.neutral900}
                  style={{ width: 20, height: 20 }}
                />
              )}
            </div>
          );
        })}
      </div>
      <div
        // navigation div
        style={{ display: "flex", justifyContent: "flex-end", gap: 16 }}
      >
        <ButtonJack
          type="outline"
          style={{ height: "40px", padding: 10, minWidth: 0 }}
          leftIcon={
            <JackIcons name="arrow-back" fill={jackColors.neutral900} />
          }
          onClick={() => handleClick({ type: "back" })}
        >
          Back
        </ButtonJack>
        <ButtonJack
          style={{ height: "40px", padding: 10, minWidth: 0 }}
          rightIcon={
            <JackIcons
              name="arrow-forward"
              fill={
                isNextDisabled ? jackColors.neutral600 : jackColors.neutral900
              }
            />
          }
          onClick={() => handleClick({ type: "next", value: selected })}
          disabled={isNextDisabled}
        >
          Next
        </ButtonJack>
      </div>
    </>
  );
};

export const SelectionIcon = ({ name }) => {
  return (
    <div style={{ width: 32 }}>
      <Avatar
        size="medium"
        type="square"
        mainIcon={
          <JackIcons
            name={name}
            fill="white"
            style={{ width: 20, height: 20 }}
          />
        }
      />
    </div>
  );
};

export const useDuplicateCheck = ({
  checkDuplicate = false,
  setCurrentData = () => {},
  setCheckDuplicate = () => {},
}) => {
  const { vaReferenceIds, loadingVaReferenceIds, refetchVaReferenceIds } =
    useConstants();

  useEffect(() => {
    setCurrentData((prev) => {
      return prev.map((item, index) => {
        const { closed_max_amount, closed_min_amount, payment_config, label } =
          useBankDetails(item.bank);

        const isMandiri = label === "Bank Mandiri";

        const {
          trx_expiration_date,
          closed_multiple,
          open_multiple,
          closed_single,
          open_single,
        } = payment_config || {};

        const amount = item.amount;
        const isOpen = item.is_open;
        const isClosed = !isOpen;
        const isTooLow = amount < closed_min_amount;
        const isTooHigh = amount > closed_max_amount;
        const canInputDueDate = trx_expiration_date ?? true;
        const vaExpDate = item.expiration_date;
        const dueDate = item.trx_expiration_date;
        const isLifetime = item.is_lifetime;
        const usageLimit = item.usage_limit;

        const expResetDecider = (targetDate) => {
          if (!targetDate) return false;
          const now = new Date();
          const target = new Date(targetDate);

          if (target < now) return true;
          return false;
        };

        const canSelectMultiple =
          ((isClosed && closed_multiple) || (isOpen && open_multiple)) ?? true;

        const canSelectSingle =
          ((isClosed && closed_single) || (isOpen && open_single)) ?? true;

        const tempId = uniqueId(item.reference_id);
        if (!item.temp_id) {
          item.temp_id = tempId;
        }

        const isDuplicatedReference = !!prev.find(
          ({ reference_id }, indexInner) => {
            if (indexInner === index) return false;
            return (
              item.reference_id &&
              reference_id &&
              item.reference_id === reference_id
            );
          }
        );

        const isAlreadyCreatedRefId = vaReferenceIds?.includes(
          item?.reference_id
        );

        if (isOpen) {
          item.amount = 0;
        }

        if (isClosed && (isTooHigh || isTooLow)) {
          item.amount = "";
        }

        if (
          (!canSelectMultiple && usageLimit === -1) ||
          (!canSelectSingle && usageLimit === 1)
        ) {
          item.usage_limit = "";
        }

        if (!canInputDueDate) {
          item.trx_expiration_date = "";
        }

        if (expResetDecider(vaExpDate)) {
          item.is_lifetime = "";
          item.expiration_date = "";
        }

        if (expResetDecider(dueDate)) {
          item.trx_expiration_date = "";
        }

        if (isLifetime) {
          item.expiration_date = "";
        }

        if (isMandiri && isOpen) {
          item.is_open = "";
        }

        let requiredScore = 0;

        const requiredItems = [
          "bank",
          "username_display",
          "is_open",
          "amount",
          "usage_limit",
          "is_lifetime",
        ];

        requiredItems.forEach((el) => {
          if (!!item[el] || item[el] !== "") requiredScore++;
        });

        return {
          ...item,
          // is_duplicated_reference: isDuplicatedReference, // for demo purpose
          is_duplicated_reference: false,
          is_complete: requiredScore === requiredItems.length ? true : false,
          // is_ref_already_used: isAlreadyCreatedRefId,
          is_ref_already_used: false,
          can_set_due_date: canInputDueDate,
        };
      });
    });

    setCheckDuplicate(false);
  }, [checkDuplicate]);
};
