import { jackColors } from "../../../../../../assets/colors";
import { GothamMedium } from "../../../../../../components/Text";

const NextText = ({ children = null }) => {
  return (
    <GothamMedium className="font12" style={{ color: jackColors.neutral100 }}>
      {children}
    </GothamMedium>
  );
};

export default NextText;
