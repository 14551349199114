import { useEffect } from "react";
import { booleanState } from "../../../../components/Status";
import { useGetAuth } from "../../../../contexts/AuthContext";
import { useConstants } from "../../../../contexts/ConstantsContext/parent";
import { fetch } from "../../../../tools/api";
import {
  ancestoryLinesFormatter,
  failedTrxCountDecider,
  invalidFailedTrxCountDecider,
  longestAncestoryLineDecider,
  retryResubmitBooleans,
  retryResubmitWarningTypeDecider,
} from "../helpers";
import useRemindTiming from "./useRemindTiming";
import useTrxReleaser from "./useTrxReleaser";
import {
  BILL,
  LOCAL,
  MIXED_RESUBMITABLE,
  PAYROLL,
  REIMBURSEMENT,
  RESUBMITABLE,
  RETRIABLE,
} from "../constants";

const retryResubmitDataDecider = (module, transaction) => {
  switch (module) {
    case LOCAL:
    case PAYROLL:
      const ancestoryLines = ancestoryLinesFormatter(
        transaction?.local_transactions
      );
      const longestAncestoryLine = longestAncestoryLineDecider(ancestoryLines);
      const longestAncestoryLineLength = longestAncestoryLine.length;
      const failedTrxs = Object.values(ancestoryLines)
        .filter((ancestoryLine) => {
          const trx = ancestoryLine[ancestoryLine.length - 1];
          const { isFailed, isDeclined } = booleanState(trx?.state);
          const isValidErrorCode = [2, 4, 5, 14].includes(trx?.error_code);
          const isFailedLastTrx = isFailed || isDeclined;
          const isLongestAncestoryLine =
            ancestoryLine.length === longestAncestoryLineLength;
          return isFailedLastTrx && isLongestAncestoryLine && isValidErrorCode;
        })
        .map((ancestoryLine) => ancestoryLine[ancestoryLine.length - 1]);

      const total = failedTrxs?.reduce(
        (accumulator, currentValue) => {
          return {
            fee: accumulator?.fee + Number(currentValue?.fee),
            amount: accumulator?.amount + Number(currentValue?.amount),
          };
        },
        { fee: 0, amount: 0 }
      );

      const data = {
        id: transaction?.id,
        fee: total?.fee,
        type: module === LOCAL ? "local" : "payroll",
        totalAmount: total?.amount,
        totalPayment: total?.fee + total?.amount,
        name: transaction?.name,
      };

      if (module === PAYROLL) data.batch_id = transaction?.batch_id;

      return data;

    case REIMBURSEMENT:
      return {
        id: transaction?.id,
        fee: Number(transaction?.fee),
        type: "reimbursement",
        numOfExpense: transaction?.reimbursement_child?.length,
        totalAmount: Number(transaction?.amount),
        totalPayment: Number(transaction?.amount) + Number(transaction?.fee),
        name: transaction?.name,
      };

    case BILL:
      return {
        id: transaction?.id,
        vat: Number(transaction?.tax),
        fee: Number(transaction?.fee),
        type: "bill",
        subtotal: Number(transaction?.amount),
        totalAmount: Number(transaction?.total_amount),
        totalPayment:
          Number(transaction?.total_amount) + Number(transaction?.fee),
        name: `Bill payment to ${transaction?.recipient_name}`,
      };

    default:
      return {};
  }
};

const useRetryResubmitModalPayloads = ({
  module = "",
  transaction = {},
  onClickSeeDetails = () => {},
}) => {
  const retryId = transaction?.retry_id;
  const ancestorId = transaction?.ancestor_id;
  const resubmitPayrollId = transaction?.resubmit_payroll_id;

  const urlDecider = () => {
    switch (module) {
      case LOCAL:
      case PAYROLL:
        return `/local_transaction_batches/${retryId}`;
      case BILL:
        return `/invoice_transactions/${retryId}`;
      case REIMBURSEMENT:
        return `/reimbursements/${retryId}`;
      default:
        return "";
    }
  };

  const { data: resubmittedTrxData, refetch: fetchResubmittedTrxData } = fetch({
    url: urlDecider(),
    woInit: true,
    defaultValue: {},
  });

  useEffect(() => {
    if (retryId) fetchResubmittedTrxData();
  }, [retryId]);

  const isBillOrReimbursement = [BILL, REIMBURSEMENT].includes(module);

  const { user } = useGetAuth();
  const { allUsers } = useConstants();

  const drafter = allUsers?.find(({ id }) => id === transaction?.user_id) ?? {};
  const { releaser } = useTrxReleaser({ trx: transaction });

  const resubmittedAt = resubmittedTrxData?.data?.created_at;

  const isResubmitted = !!retryId;
  const isResubmittedTrx = !!ancestorId;

  const remindTiming = useRemindTiming({
    module,
    trxId: transaction?.id,
  });

  const warningType = retryResubmitWarningTypeDecider({
    module,
    trx: transaction,
  });

  const retryResubmitTrxData = retryResubmitDataDecider(module, transaction);

  const invalidFailedTrxCount = isBillOrReimbursement
    ? 0
    : invalidFailedTrxCountDecider(transaction?.local_transactions);

  const failedTrxCount = isBillOrReimbursement
    ? 1
    : failedTrxCountDecider(transaction?.local_transactions);

  const handleSeeDetails = () => {
    const isPayroll = module === PAYROLL;

    if (isResubmitted) {
      return onClickSeeDetails(isPayroll ? resubmitPayrollId : retryId);
    }

    if (isResubmittedTrx) return onClickSeeDetails(ancestorId);
  };

  const retryResubmitDefaultProps = {
    trx: retryResubmitTrxData,
    trxType: isBillOrReimbursement ? "single" : "batch",
    warningType,
    failedTrxCount,
    invalidFailedTrxCount,
    drafter,
    releaser,
    trxStatus: transaction?.state,
    remindTiming,
    resubmittedAt,
    onSeeDetails: handleSeeDetails,
  };

  const { isDrafter, isReleaser } = retryResubmitBooleans({
    user,
    drafter,
    releaser,
    warningType,
  });

  const { isFailed, isDeclined, isPartialFailed } = booleanState(
    transaction?.state
  );

  const hasWarningType = !!warningType;
  const isFailedTrx = isFailed || isDeclined || isPartialFailed;
  const isResponsible = isDrafter || isReleaser;
  const isActionable = isBillOrReimbursement
    ? transaction?.local_transaction?.is_retriable &&
      !transaction?.local_transaction?.retry_id
    : [RETRIABLE, RESUBMITABLE, MIXED_RESUBMITABLE].includes(
        transaction?.retry_status
      );
  const isRetriedBillOrReimbursement = isBillOrReimbursement
    ? !!transaction?.retry_id
    : false;
  const isResubmittedTrxLocalOrPayroll = isBillOrReimbursement
    ? false
    : isResubmittedTrx;

  const canRetryOrResubmit =
    hasWarningType &&
    isFailedTrx &&
    isResponsible &&
    isActionable &&
    !isResubmitted &&
    !isRetriedBillOrReimbursement &&
    !isResubmittedTrxLocalOrPayroll;

  const canSeeBanner = isBillOrReimbursement
    ? (isResponsible && isFailedTrx && isActionable && hasWarningType) ||
      (isRetriedBillOrReimbursement && resubmittedAt)
    : (isActionable || isResubmitted) &&
      hasWarningType &&
      !isResubmittedTrxLocalOrPayroll;

  return {
    canSeeBanner,
    canRetryOrResubmit,
    retryResubmitDefaultProps,
  };
};

export default useRetryResubmitModalPayloads;
