const CardBenefit = () => {
  return (
    <svg
      width="41"
      height="40"
      viewBox="0 0 41 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M35.97 31.8399H4.52002C4.33002 31.8299 4.19002 31.8299 4.02002 31.7899C2.72002 31.5499 1.77002 30.4199 1.77002 29.0899V10.9099C1.77002 10.1799 2.05002 9.48992 2.57002 8.96992C3.09002 8.44992 3.78002 8.16992 4.51002 8.16992H35.96C37.47 8.16992 38.71 9.39992 38.71 10.9199V29.0999C38.72 30.5999 37.49 31.8399 35.97 31.8399ZM4.52002 30.0099H35.98C36.49 30.0099 36.9 29.5999 36.9 29.0899V10.9099C36.9 10.3999 36.49 9.98992 35.98 9.98992H4.53002C4.28002 9.98992 4.05002 10.0899 3.88002 10.2599C3.71002 10.4299 3.61002 10.6599 3.61002 10.9099V29.0899C3.61002 29.5299 3.93002 29.9099 4.36002 29.9899C4.41002 29.9999 4.47002 29.9999 4.52002 30.0099Z"
        fill="#B3F600"
      />
      <path
        d="M32.5901 27.9409H30.6001C29.4801 27.9409 28.5701 27.0309 28.5701 25.9109C28.5701 24.7909 29.4801 23.8809 30.6001 23.8809H32.5901C33.7101 23.8809 34.6201 24.7909 34.6201 25.9109C34.6201 27.0309 33.7101 27.9409 32.5901 27.9409Z"
        fill="#B3F600"
      />
    </svg>
  );
};

export default CardBenefit;
